import { Data, DocumentSchema } from "@verdi/shared-constants"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { buildFirstDocStepFromDocNode } from "../../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { addDocReferenceInlineNode } from "../documentBody/addDocReference"
import { dispatch } from "../../../../state/store"
import { loadingStatusState } from "../../../../state/loadingStatusSlice"
import { Node } from "@tiptap/pm/model"
import { findChildren } from "prosemirror-utils"
import { generateTitleForDocument } from "../../../../ai/docDrafter/opportunities/generateTitlesForDocument"
import { EditorState } from "prosemirror-state"


const cmdType = GlobalCommandType.addDocFromSelection


/** Creates a new child document with its body containing the selected text of the current document. */
export const addDocFromSelectionCommandDefinition: GlobalCommandDefinition<AddDocFromSelectionCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddDocFromSelectionCommandArgs) => {
    const title = args.commandTitle || "move into new note"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "add",
      } as VerdiCommand

    return toReturn
  },
  triggerCommand: (args: AddDocFromSelectionCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  },
  processCommand: async (
    args: AddDocFromSelectionCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const {
      toast,
      getContextForAi,
      documentRelationsProvider,
    } = services


    if (!documentRelationsProvider) {
      toast.showError("Could not run command.")
      console.error("addDocFromSelection: Document Relations Provider not available")
      return
    }

    const currentDocContext = await getContextForAi()
    const currentDocId = currentDocContext.currentDoc?.id
    if (!currentDocId) {
      toast.showError("Could not run command.")
      console.error("addDocFromSelection: could not detect current document")
      return
    }

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      toast.showError("Could not run command.")
      console.error("addDocFromSelection: Editor not detected for command")
      return
    }

    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (!currentBlockContext) {
      toast.showError("Could not run command.")
      console.error("could not detect current block")
      return
    }

    const selection = editor.view.state.selection
    if (selection.empty) {
      toast.showWarn("Please select some text.")
      console.error("Nothing selected.")
      return
    }

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Creating from selection...",
    }))

    // Q: Move user's selection to include the full line(s) of the selection ???
    //  A: For now, just use the selection as is

    const blocksToCutOver = editor.view.state.doc.cut(selection.from, selection.to)
    let newDocTitle = args.newDocTitle || getTitleFromSelection(blocksToCutOver)
    if (newDocTitle.length > 40) {
      const aiGeneratedTitle = await generateTitleForDocument(newDocTitle)
      if (aiGeneratedTitle.length > 0) {
        newDocTitle = aiGeneratedTitle[0]
      }
    }

    const docBodyNode = getDocBodyNodeConsideringTitle(blocksToCutOver, newDocTitle, editor.view.state)
    let initialStepJson: string | undefined
    if (docBodyNode) {
      const firstDocStep = buildFirstDocStepFromDocNode(docBodyNode)
      initialStepJson = JSON.stringify(firstDocStep.toJSON())
    }

    // console.log("addDocFromSelection ", { docBodyNode, blocksToCutOver, newDocTitle, initialStepJson })

    documentRelationsProvider.createNewDocWithRelation({
      parentDocId: currentDocId,
      initialStepJson,
      currentDocId,
      direction: "currentDocIsTo",
      newDocTitle: newDocTitle,
      newDocType: DocumentSchema.DocumentType.document,
      relationType: Data.DocumentRelationType.referencedBy, // TODO: Decide what the best type is to use
      onComplete: (newDocId: string) => {
        // console.log("AddDocFromSelection: Created new doc with relation", { newDocId })

        // Create a new docReference node in the current doc, replacing the selection with a link to the new doc
        addDocReferenceInlineNode(editor, {
          docId: newDocId,
          textToDisplay: newDocTitle,
        })
        dispatch(loadingStatusState.setDoingAiOnDoc({ isInProgress: false }))
        onProcessingComplete(true)
      },
      onError: (error) => {
        console.error("addDocFromSelection: Error creating new doc with relation", { error })
        dispatch(loadingStatusState.setDoingAiOnDoc({ isInProgress: false }))
        onProcessingComplete(false)
      },
    })

  },
}

export type AddDocFromSelectionCommandArgs = {
  commandTitle?: string
  newDocTitle?: string
}

const getDocBodyNodeConsideringTitle = (node: Node, title: string, state: EditorState) => {

  const allText = node.textBetween(0, node.content.size, " ").trim()
  const textExcludingTitle = allText.replace(title, "").trim()
  if (textExcludingTitle.length === 0) {
    return undefined
  }
  // remove any node that contains the title verbatim ???
  // const bodyContainsTitle = allText.length > textExcludingTitle.length
  // if (!bodyContainsTitle) {
  //   return node
  // }
  // return state.doc.cut(selection.from, selection.to)
  return node
}

const getTitleFromSelection = (node: Node) => {
  let title = ""
  const textNodes = findChildren(node, (child) => child.type.name === "text", true)
  for (const node of textNodes) {
    if (title.length > 10) {
      break
    }
    const titleTrimmed = title.trim()
    title = titleTrimmed + (titleTrimmed.length === 0 ? "" : " ") + node.node.textContent.trim()
  }
  return title
}
