import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { OpenMenuOptions } from "../../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"


const cmdType = GlobalCommandType.showCommandMenu

/** Tries first to open the doc command menu, then falls back to opening the global command menu  */
export const showCommandMenuCommandDefinition: GlobalCommandDefinition<ShowCommandMenuCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowCommandMenuCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Open the command menu',
        searchName: 'open the command menu',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowCommandMenuCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("showCommandMenuCommandDefinition.processCommand()", { args })

    if (!services.servicesForCurrentDoc?.showDocEditorCommandMenuIfAvailable(args?.docCommandMenuOptions)) {
      services.commandMenuProvider.openCommandMenu()
    }

    onProcessingComplete(true)
  },
  triggerCommand: (args?: ShowCommandMenuCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type ShowCommandMenuCommandArgs = {
  docCommandMenuOptions?: OpenMenuOptions
}


