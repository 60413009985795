import { css } from "@emotion/react"
import { DocMetadataState } from "../../state/docMetadataSlice"
import { useAppSelector } from "../../state/storeHooks"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { VerdiIconDebug } from "../../components/icons/VerdiIcons"
import { SubSectionWrapper } from "../opportunityDocument/subMenu/SubSectionWrapper"


export const DocMetadataViewer = () => {


  const summary = useAppSelector(DocMetadataState.getSummary)

  const { experimentalEnabled } = useFeatureFlags()
  if (!experimentalEnabled || !summary) return null


  return (
    <SubSectionWrapper
      title="Doc Metadata"
      titleButton={<VerdiIconDebug />}
    >
      <div css={css`
        font-size: 10px;
        border: 1px solid #99999933;
        overflow: auto;
        max-height: 300px;
        max-width: 300px;
        width: 100%;
        border-radius: 8px;
        margin-top: 4px;
        padding: 8px;
        overflow-y: auto;
      `}>
        <div>
          {summary && (
            <pre>
              {JSON.stringify(summary, null, 2)}
            </pre>
          )}
        </div>
      </div>
    </SubSectionWrapper>
  )

}
