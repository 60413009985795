import { useCallback, useMemo } from "react"
import { css } from "@emotion/react"
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from '@hello-pangea/dnd';
import { KanbanCard, KanbanCardData } from "./KanbanCard"
import { KanbanColumnOption, OpportunityStatusType } from "./KanbanColumnOptions";
import { useColor } from "../../utility-hooks/useColor";
import { DefinitionTipForUser } from "../DefinitionTipForUser";
import { VerdiIconAdd } from "../icons/VerdiIcons";
import { IconButtonTertiary } from "../buttons/IconButtonTertiary";


type KanbanBoardProps = {
  cards: KanbanCardData[],
  columnOptions: KanbanColumnOption[],
  onTitleChange?: (cardId: string, newTitle: string) => void,
  onStatusColumnChange: (cardId: string, newStatusColumnId: string) => void,
  onCardClick: (cardId: string) => void,
  onAddClick: (statusColumnId: OpportunityStatusType) => void,
  onShowInfoBadgeClick: (card: KanbanCardData, rect: DOMRect) => void,
}

export const KanbanBoard = ({
  cards,
  columnOptions,
  onCardClick,
  onAddClick,
  onStatusColumnChange,
  onTitleChange,
  onShowInfoBadgeClick,
}: KanbanBoardProps) => {


  const cardsPerColumn = useMemo(() => {

    const columns: { [key: string]: KanbanCardData[] } = {}

    cards.forEach(card => {
      if (!columns[card.statusColumnId]) {
        columns[card.statusColumnId] = []
      }
      columns[card.statusColumnId].push(card)
    })

    // sort each column by most recent activity
    Object.keys(columns).forEach((key) => {
      columns[key].sort((a, b) => {
        if (!a.lastActivityAt || !b.lastActivityAt) return 0
        return b.lastActivityAt.getTime() - a.lastActivityAt.getTime()
      })
    })

    return columns
  }, [cards])


  const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
    const { source, destination, draggableId } = result;

    const newStatus = destination?.droppableId
    const oldStatus = source.droppableId
    const itemId = draggableId

    if (newStatus === oldStatus) {
      console.log("No change in status, no need to update", { newStatus, oldStatus, itemId, result, provided })
      return
    }
    if (!newStatus) {
      console.warn("No new status, cannot update", { newStatus, oldStatus, itemId, result, provided })
      return
    }
    onStatusColumnChange(itemId, newStatus)

  }, [onStatusColumnChange])


  const columnBgColorDragging = useColor("surfaceSelected")


  return (
    <div css={css`
      display: flex;
      flex-direction: row;
      gap: 8px;
      background: transparent;
    `}>

      <DragDropContext onDragEnd={onDragEnd}>

        {columnOptions.map((columnOption) =>

          <Droppable
            key={columnOption.title}
            droppableId={columnOption.title}
          >
            {(provided, snapshot) => (

              <div ref={provided.innerRef}
                css={css`
                  width: 300px;
                  min-width: 200px;
                  padding: 8px;
                  background-color: ${snapshot.isDraggingOver ? columnBgColorDragging : "transparent"};
                  border-radius: 8px;
                `}
              >
                <h2
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    font-size: 1rem;
                    margin: 0;
                    padding: 0 9px 0 11px;
                    margin-bottom: 8px;
                  `}
                >

                  <div>
                    {!columnOption.tooltip ?

                      <span title={columnOption.tooltip || undefined}>
                        {columnOption.title}
                      </span>
                      :
                      <DefinitionTipForUser
                        word={columnOption.title}
                        definition={columnOption.tooltip || ""}
                      />

                    }
                    <small css={css`
                      margin-left: 8px;
                      opacity: 0.5;
                      font-weight: normal;
                    `}>
                      {cardsPerColumn[columnOption.id]?.length || 0}
                    </small>
                  </div>

                  <IconButtonTertiary
                    aria-label="Add"
                    icon={<VerdiIconAdd />}
                    onClick={() => onAddClick(columnOption.id)}
                  />

                </h2>
                <div css={css`
                  overflow-y: auto;
                  max-height: calc(100vh - 230px);
                `}>


                  {cardsPerColumn[columnOption.id] && cardsPerColumn[columnOption.id].map((cardData, key) =>
                    <Draggable
                      key={cardData.id}
                      draggableId={cardData.id}
                      index={key}>
                      {(providedChild, snapshotChild) => (
                        <div
                          ref={providedChild.innerRef}
                          {...providedChild.draggableProps}
                          {...providedChild.dragHandleProps}
                          css={css`
                          top: auto !important; /** Fixes bad positioning on drag. Alternatively could use a portal */
                          left: auto !important;
                          ${!snapshotChild.isDragging && snapshot.isDraggingOver ? `visibility: hidden;` : ""}
                        `}
                        >
                          <KanbanCard
                            key={key}
                            cardData={cardData}
                            setTitle={() => onTitleChange?.(cardData.id, "new title")}
                            isBeingDragged={snapshotChild.isDragging}
                            onCardClick={onCardClick}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>

    </div>

  )
}
