import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export type SuggestedTextOption = {
  summaryForCommand: string // Show this on the command button
  textToAdd: string // The literal text to add
  behavior: "addChild" | "replace" // How to add it
}

/** A suggestion related to a mark */
export type MarkSuggestion = {
  isLoading?: boolean
  suggestionText: string // Currently key-ing off of this value
  suggestions: SuggestedTextOption[]
}

interface InternalState {
  suggestions: MarkSuggestion[]
  currentId?: string
}

const initialState: InternalState = {
  suggestions: [],
  currentId: undefined,
}

export const suggestionsForMarksSlice = createSlice({
  name: 'suggestionsForMarks',
  initialState,
  reducers: {
    setForSuggestionText: (state, action: PayloadAction<MarkSuggestion>) => {
      const existingIndex = state.suggestions.findIndex(s => s.suggestionText === action.payload.suggestionText)
      if (existingIndex > -1) {
        state.suggestions[existingIndex] = action.payload
      } else {
        state.suggestions.push(action.payload)
      }
    },
    removeForSuggestionText: (state, action: PayloadAction<string>) => {
      state.suggestions = state.suggestions.filter(t => t.suggestionText !== action.payload)
    },
    removeAll: (state) => {
      state.suggestions = []
    },
    /** Sets the current suggestion id, which is keyed off of the suggestionText */
    setCurrentId: (state, action: PayloadAction<string | undefined>) => {
      state.currentId = action.payload
    },
  },
  selectors: {
    getAll: (state) => state.suggestions,
    getCurrent: (state) => state.suggestions.find(s => s.suggestionText === state.currentId),
  }
})
export const SuggestionsForMarksState = {
  ...suggestionsForMarksSlice.actions,
  ...suggestionsForMarksSlice.selectors,
}

export default suggestionsForMarksSlice.reducer
