import { Data } from "@verdi/shared-constants"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu"
import { setAssumptionRelevanceCommandDefinition } from "./setAssumptionRelevance"



const cmdType = GlobalCommandType.setAssumptionRelevanceShowOptions

export const setAssumptionRelevanceShowOptionsCommandDefinition: GlobalCommandDefinition<SetAssumptionRelevanceShowOptionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetAssumptionRelevanceShowOptionsCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Relevance ...',
        description: '',
        searchName: 'relevance',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: SetAssumptionRelevanceShowOptionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const commands = Data.AssumptionRelevanceLevelOptions.filter((option) => option.label !== "").map((option) => (
      setAssumptionRelevanceCommandDefinition.buildMenuItem({ assumption: args.assumption, option, onSelected: args.onSelected })
    ))


    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands,
      searchPlaceholderText: "relevance ...",
      rectOfAnchorElement: args.rect,
      shouldAdjustRectForScrollOffset: false,
    })
    onProcessingComplete(true)
  },
  triggerCommand: (args: SetAssumptionRelevanceShowOptionsCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type SetAssumptionRelevanceShowOptionsCommandArgs = {
  assumption?: Data.AssumptionModel
  rect?: DOMRect
  onSelected?: (option: Data.AssumptionRelevanceLevelOption) => void
}
