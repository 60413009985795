import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


export type DocumentTaskModel = {
  /** TODO: Decide to use this or not. When true, will not attempt to sync (prevents too many syncs) */
  isSyncing?: boolean
  idInDoc: string,
  docId: string,
  titlePlainText: string,
  deletedAt: string | null,
  completedAt: string | null,
}
const fromDto = (dto: Data.DocumentTasks.DocumentTaskDto): DocumentTaskModel => {

  return {
    ...dto,
    idInDoc: dto.idInDoc,
    docId: dto.docId,
    titlePlainText: dto.titlePlainText,
    deletedAt: dto.deletedAt ? dto.deletedAt : null,
    completedAt: dto.completedAt ? dto.completedAt : null,
  }
}

interface IDocTasksState {
  allTasks: DocumentTaskModel[]
  // pendingUpdates: DocumentTaskModel[]
}

/** Store the current doc's summary of metadata */
const initialState: IDocTasksState = {
  allTasks: [],
  // pendingUpdates: [],
}

export const documentTasksSliceSlice = createSlice({
  name: 'documentTasks',
  initialState,
  reducers: {
    setAllTasks: (state, action: PayloadAction<DocumentTaskModel[]>) => {
      state.allTasks = action.payload
    },
    updateOrAddTaskByIdInDoc: (state, action: PayloadAction<{ docId: string, idInDoc: string, fields: Partial<DocumentTaskModel> }>) => {
      const { docId, idInDoc, fields } = action.payload
      const index = state.allTasks.findIndex((task) => task.docId === docId && task.idInDoc === idInDoc)
      if (index !== -1) {
        const existingTask = state.allTasks[index]
        state.allTasks[index] = {
          ...existingTask,
          ...fields,
        }
      } else {
        state.allTasks.push({
          idInDoc,
          titlePlainText: "",
          completedAt: null,
          deletedAt: null,
          docId,
          ...fields,
        })
      }
    },

    // addPendingUpdate: (state, action: PayloadAction<DocumentTaskModel>) => {
    //   state.pendingUpdates.push(action.payload)
    // },
    // removePendingUpdate: (state, action: PayloadAction<DocumentTaskModel>) => {
    //   const index = state.pendingUpdates.findIndex((task) => task.idInDoc === action.payload.idInDoc)
    //   if (index !== -1) {
    //     state.pendingUpdates.splice(index, 1)
    //   }
    // },
  },
  selectors: {
    getAllTasks: (state) => state.allTasks,
    getTaskByIdInDoc: (state, idInDoc: string | undefined) => {
      if (!idInDoc) return undefined
      return state.allTasks.find((task) => task.idInDoc === idInDoc)
    },
    // getPendingUpdates: (state) => state.pendingUpdates,
  }
})

export const DocumentTasksState = {
  ...documentTasksSliceSlice.actions,
  ...documentTasksSliceSlice.selectors,
  fromDto,
}

export default documentTasksSliceSlice.reducer
