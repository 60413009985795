import { Data } from "@verdi/shared-constants"
import { css } from "@emotion/react"
import { showMiniCommandMenuCommandDefinition } from "../../components/commands/commandDefinitions/utils/showMiniCommandMenu"
import { getContextMenuCommandsForAssumption } from "../../components/commands/contextMenu/Assumption"
import { AssumptionRelevanceEditor } from "./AssumptionRelevanceEditor"
import { AssumptionConfidenceEditor } from "./AssumptionConfidenceEditor"
import { useMemo } from "react"
import { getRiskColor } from "./risk/OpportunityRiskBadge"
import { opportunityRiskColorGreen, opportunityRiskColorRed, opportunityRiskColorYellow } from "./risk/OpportunityRiskBar"
import { AssumptionsListItemView } from "./AssumptionsListItemView"
import { AssumptionWithDocBodySnapshot } from "./useAssumptionsProvider"


type Props = {
  assumption: AssumptionWithDocBodySnapshot
  isSelected: boolean
  onClick: (assumption: Data.AssumptionModel) => void
  // mode: "addSuggestion" | "edit"
}
export const AssumptionsListItem = ({
  assumption,
  isSelected,
  onClick,
}: Props) => {

  const riskColor = useMemo(() => {
    const colorId = getRiskColor(assumption)
    if (colorId === "red") return opportunityRiskColorRed
    if (colorId === "yellow") return opportunityRiskColorYellow
    if (colorId === "green") return opportunityRiskColorGreen
    return "transparent"
  }, [assumption])

  const answer = useMemo(() => {
    return assumption.snapshot?.bodyPlainText?.split("\n")?.[0]
      || ""
  }, [assumption.snapshot?.bodyPlainText])

  if (!assumption) {
    console.warn('assumption is bad maybe?', assumption)
    return null
  }

  const title = assumption.question || assumption.mainDoc?.title || "no question"


  return (
    <div>
      <AssumptionsListItemView
        title={title}
        answer={answer}
        isSelected={isSelected}
        onClick={() => onClick(assumption)}
        onSettingsClick={(evt) => {
          evt.preventDefault()
          showMiniCommandMenuCommandDefinition.triggerCommand?.({
            commands: getContextMenuCommandsForAssumption(assumption),
            searchPlaceholderText: "Assumption options",
            rectOfAnchorElement: new DOMRect(evt.clientX, evt.clientY, 0, 0),
            shouldAdjustRectForScrollOffset: false,
          })
        }}
        riskColor={riskColor}
        leftSideChildren={
          <div
            css={css`
              display: flex;
              align-items: center;
          `}>
            <AssumptionConfidenceEditor
              assumption={assumption}
              hideLabel={true}
              size="md"
            />
            <AssumptionRelevanceEditor
              assumption={assumption}
              hideLabel={true}
              size="md"
            />
          </div>
        }
      />
    </div>
  )
}

