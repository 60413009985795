import { useMemo } from "react"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { getCurrentAppState } from "../../state/store"
import { TagsState } from "../../state/TagsSlice"
import { useAppSelector } from "../../state/storeHooks"
import { DocTagsState } from "../../state/docTagsSlice"
import { SelectTagDropdown } from "../tags/SelectTagDropdown"


type Props = {
  document: BasicDocumentDetails,
  landmarkId?: string,
}
export const DocumentTagEditor = ({
  document,
  landmarkId,
}: Props) => {

  const currentDocTags = useAppSelector(s => DocTagsState.getAllForDoc(s, document.id))
  const currentTags = useMemo(() => {
    const allTags = TagsState.getAll(getCurrentAppState())
    const tagIds = currentDocTags.map(dt => dt.tagId)
    const filtered = allTags.filter(t => t.id && tagIds.includes(t.id))
    return filtered
  }, [currentDocTags])


  return (
    <SelectTagDropdown
      data-landmark={landmarkId}
      documentId={document.id}
      currentTags={currentTags}
    />
  )
}
