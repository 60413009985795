import { useEffect, useState } from 'react';
import { SideMenuSection, startingMenuDepth } from './SideMenuSection';
import { useNewQuickNoteCreator } from '../documents/add/useNewQuickNoteCreator';
import { DocumentSchema, Menu } from '@verdi/shared-constants';


type Props = {
  onBeforeNavigate: () => void
  onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  menuItems: Menu.MenuItemData[]
  activeMenuIds: string[]

}
export const SideMenuSectionForNotes = ({
  onBeforeNavigate,
  onAddOpportunityButtonClick,
  menuItems,
  activeMenuIds,
}: Props) => {

  const [topLevelItem, setTopLevelItem] = useState<Menu.MenuItemData>()
  useEffect(() => {

    const rootItem: Menu.MenuItemData = {
      children: menuItems,
      id: "notes",
      title: "Notes",
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: "",
      createdAt: new Date(),
    }

    setTopLevelItem(rootItem)
  }, [menuItems])

  const { createNewQuickNote, isSaving } = useNewQuickNoteCreator({ shouldShowChatOnRedirect: false })

  // Avoid highlighting more than one quick note, given they might be nested in another menu
  // Once this section allows nesting, we might be able to remove this
  const currentNoteId = activeMenuIds[0] || ""

  if (!topLevelItem || menuItems.length === 0) return null
  return (

    <SideMenuSection
      menuItem={topLevelItem}
      menuDepth={startingMenuDepth}
      folderType="quickNote"
      onBeforeNavigate={onBeforeNavigate}
      onAddButtonClick={(menuItem) => createNewQuickNote()}
      onAddOpportunityButtonClick={onAddOpportunityButtonClick}
      isExpandedInitially={window.location.pathname.includes("/notes")}
      addingInProgress={isSaving}
      activeMenuIds={[currentNoteId]}
    />

  )
}
