
import React from "react";
import { DocServicesContext } from "./useRegisterDocServices";


/** Provides context for a specific document, via wrapping docServices context */
export const DocEditorContext = React.createContext<DocServicesContext | null>(null)


type Props = {
  context: DocServicesContext
  children: React.ReactNode
}
export const DocEditorContextWrapper = ({
  context,
  children,
}: Props) => {


  return (
    <DocEditorContext.Provider value={context}>
      {children}
    </DocEditorContext.Provider>
  )
}

