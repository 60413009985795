import { useState } from "react";
import { css } from "@emotion/react";
import { Button } from "@chakra-ui/react";
import { DocServicesContext } from "../../docServices/useRegisterDocServices";
import MarkDebugger from "../../aiEdit/MarkDebugger";


type Props = {
  docServices: DocServicesContext;
};

export const GenerateAiFlowButton = ({ docServices }: Props) => {

  const [shouldShowDebugger, setShouldShowDebugger] = useState(false);

  if (!shouldShowDebugger) {
    return (
      <div
        css={css`
          opacity: 0.1;
          float: right;
        `}
      >
        <Button
          size="xs"
          cursor="default"
          onClick={() => setShouldShowDebugger(true)}
        >
          D
        </Button>
      </div>
    );
  }

  return (
    <div
      css={css`
        border-top: 1px solid rgba(192, 192, 192, 0.3);
        margin-top: 40px;
        padding-top: 10px;
      `}
    >

      <div
        css={css`
          opacity: 0.1;
          float: right;
        `}
      >
        <Button
          size="xs"
          cursor="default"
          onClick={() => setShouldShowDebugger(!shouldShowDebugger)}
        >
          D
        </Button>
      </div>

      {shouldShowDebugger && (
        <div
          css={css`
            margin-top: 20px;
            border: 5px dashed rgba(252, 182, 97, 0.5);
            padding: 20px;
          `}
        >

          <MarkDebugger docServices={docServices} />
        </div>
      )}
    </div>
  );
};
