import { makeFreeTextBlock, TipTapDocument } from "../prosemirrorNodes";
import { Fragment } from "prosemirror-model"
import { schema } from "./"
import * as ProsemirrorNodes from "../prosemirrorNodes"
import { WebsocketEvents } from "..";
import { Step } from "prosemirror-transform"

export const initialDoc = () => ({
  type: "doc",
  content: [makeFreeTextBlock('')]
}) as TipTapDocument

export const createNewDoc = (document: ProsemirrorNodes.TipTapDocument) => {
  const fragment = Fragment.fromJSON(schema, document.content)
  return schema.node("doc", null, fragment)
}

export type StepInfo = Pick<WebsocketEvents.ClientEvents.StepData, 'clientId' | 'step'>

export const incrementallyUpdateSnapshot = (
  docJson: ProsemirrorNodes.TipTapDocument,
  previousVersion: number,
  steps: StepInfo[]
) => {
  const prosemirrorDocument = createNewDoc(docJson)
  let newSnapshotDoc = prosemirrorDocument
  for (const stepJson of steps) {
    const step = Step.fromJSON(schema, stepJson.step)
    const newDocument = step.apply(newSnapshotDoc).doc
    if (!newDocument) {
      throw new Error(`Step created empty document! [${JSON.stringify(stepJson, null, 2)}]`);
    }
    newSnapshotDoc = newDocument
  }
  const newVersion = previousVersion + steps.length
  return { newVersion, newSnapshotDoc }
}

/* TODO: When automating the un-bricking of documents, consider this flow */
// Verify step is bad
// const restoreSinceBeforeBadStep = (documentId: string, badStepNumber: number) => {
//   // get all steps from redis

//   // call verifyStepIsBad()
//   // IF false, return and do nothing

//   // 4. remove keys in Redis for the bad step forward ???
// }

// export const verifyStepIsBad = (documentId: string, badStepNumber: number, steps: StepInfo) => {


//   // 2. validate all steps up until that point are good
//   //  (build a snapshot)
//   // If throws an exception: return "Bad Step mismatch" or something

//   // 3. verify that adding the bad step throws an exception
//   //  IF not exception, return early and do not mess with anything

// }