import { css } from "@emotion/react"
import LoadingSpinner from "../../components/LoadingSpinner"


/** Initial view of the app while it loads data */
export const AppLoadingScreen = () => {

  // During Dev, set this to true and navigate to "/loading" for a preview
  const isOnHomePage = window.location.pathname === "/home"


  return (
    <div css={css`
        height: 100vh;
        display: flex;
        justify-content: center;
        padding-top: 200px;
        font-size: 20px;
      `}>
      {isOnHomePage ?
        <div css={css`
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        `}>
          <h1 css={css`
            margin-bottom: 20px;
          `}>
            Welcome to Verdi
          </h1>
          <p>
            Your AI coach and collaborator in making better decisions
          </p>
          <div css={css`
          margin-top: 30px;
            opacity: 1;
          `}>
            <LoadingSpinner showAfterMsDelay={20} />
          </div>
        </div>
        :
        <LoadingSpinner showAfterMsDelay={2000} />
      }
    </div>
  )
}
