

type DocumentSnapshotBody = {
  type: string
  content: object[]
}

/** Creates a single step from an entire document snapshot body.
 * 
 *  This step should ONLY be applied to empty documents,
 *  otherwise the insert positions (to and from) will brick the document
 */
export const createStepFromDocumentBody = (bodySnapshot: DocumentSnapshotBody) => {
  console.log("extractStepsFromDocumentSnapshot()")

  return {
    stepType: "replace",
    from: 0,
    to: 4,
    slice: {
      content: bodySnapshot.content
    }
  }
}
