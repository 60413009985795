import { useCallback, useRef } from "react"
import { ChatMessage } from "./useChatHistory"
import { AiPersona } from "./AiPersona"
import { DocumentContextForAi } from "../../ai/coach/AiCoachPrompts"
import { ChatRoleType } from "@verdi/shared-constants"
import { continueChat } from "../../ai/coach/actions/continueChat"
import { OpportunityContextForAi } from "../../screens/opportunities/useOpportunityProvider"


type Props = {
  getCompanyContextForPrompt: () => Promise<string>
  getDocumentContextForAi: () => DocumentContextForAi | undefined
  getOpportunityContextForAi: () => Promise<OpportunityContextForAi | undefined>
}
/** Connects the Chat window to the AI Coach */
export const useAiChatBridge = ({
  getCompanyContextForPrompt,
  getDocumentContextForAi,
  getOpportunityContextForAi,
}: Props) => {


  const triggerAiResponseReceivedEvent = useCallback((wasSuccessful: boolean, responseMessage?: ChatMessage) => {
    onAiResponseReceivedRef.current?.(wasSuccessful, responseMessage)
  }, [])

  const setOnAiResponseReceived = (callback: (wasSuccessful: boolean, responseMessage?: ChatMessage) => void) => {
    onAiResponseReceivedRef.current = callback
  }
  const onAiResponseReceivedRef = useRef<(wasSuccessful: boolean, responseMessage?: ChatMessage) => void>()


  const triggerNewChatSession = useCallback((initialMessage: ChatMessage) => {
    onStartNewChatSessionRef.current?.(initialMessage)
  }, [])
  const setOnStartNewChatSession = (callback: (initialMessage: ChatMessage) => void) => {
    onStartNewChatSessionRef.current = callback
  }
  const onStartNewChatSessionRef = useRef<(initialMessage: ChatMessage) => void>()


  const processUserGeneratedChatMessage = useCallback(async (
    message: string,
    recentMessages: ChatMessage[],
    aiPersona: AiPersona,
    companyContext?: string,
    docContext?: DocumentContextForAi,
    opportunityContext?: OpportunityContextForAi
  ) => {
    console.log('aiCoach: making AI request from chat message:', message, recentMessages, aiPersona, companyContext, docContext, opportunityContext)

    const messagesMapped = recentMessages.map(m => ({ content: m.content, role: m.role, createdAt: m.createdAt }))
    const newMessage = { content: message, role: ChatRoleType.user, createdAt: new Date() }
    messagesMapped.push(newMessage)

    try {
      const aiResponseMessage = await continueChat(messagesMapped, aiPersona, companyContext, docContext, opportunityContext)
      triggerAiResponseReceivedEvent(true, aiResponseMessage)
    } catch (e) {
      console.error("aiCoach: error while processing chat message: ", e)
      triggerAiResponseReceivedEvent(false)
    }

  }, [triggerAiResponseReceivedEvent])


  const triggerUserChatMessageReceivedEvent = useCallback(async (message: string, recentMessages: ChatMessage[], aiPersona: AiPersona) => {
    const docContext = getDocumentContextForAi();
    const opportunityContext = await getOpportunityContextForAi();
    const companyContext = await getCompanyContextForPrompt();
    return processUserGeneratedChatMessage(message, recentMessages, aiPersona, companyContext, docContext, opportunityContext)
  }, [getCompanyContextForPrompt, getDocumentContextForAi, getOpportunityContextForAi, processUserGeneratedChatMessage])


  return {
    triggerNewChatSession, // homepage
    setOnStartNewChatSession, // chatView

    /** Call this to trigger a user submitted message to the chat */
    triggerUserChatMessageReceivedEvent,

    /** Registers the main listener for when the AI responds in chat */
    setOnAiResponseReceived,
  }
}
/** Connects the Chat window to the AI Coach */
export type UseAiChatBridge = ReturnType<typeof useAiChatBridge>
