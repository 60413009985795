import { Data } from "@verdi/shared-constants";
import { DocMetadataSummaryDiff } from "../../../ai/documents/DocUpdatedEvent";
import { DocumentTaskModel, DocumentTasksState } from "../../../state/documentTasksSlice";
import { getCurrentAppState } from "../../../state/store";


/** Synchronizes DocumentTask models in the DB, with state of current doc body, 
 * so that users can see all tasks everywhere without having to open all doc bodies at once.
 * 
 *  Note: The TaskCheckboxComponent handles updating for the on checkbox click event.
 * 
 *  Note: There are still gaps with collaborative editing, 
 * where other clients will not see some of these updates.
 */
export const syncTaskCheckboxes = async (summaryDiff: DocMetadataSummaryDiff) => {

  const { current, previous } = summaryDiff
  // get last known state of task checkboxes

  if (current.tasks.length === previous?.tasks.length) {
    return
  }

  const existingTasks = DocumentTasksState.getAllTasks(getCurrentAppState())
    .filter(t => t.docId === current.documentId)
  // const tasksToUpdate:DocumentTaskModel[] = [] // Q: Handle update here or in individual node?

  // TODO: pull from redux instead of previous summary, so we include the "isLoading" flag
  const tasksToAdd: Partial<DocumentTaskModel>[] = []
  current.tasks.forEach(t => {
    if (!existingTasks.find(existingTask => existingTask.idInDoc === t.idInDoc)) {
      tasksToAdd.push(t)
    }
  })

  const tasksToRemove: Partial<DocumentTaskModel>[] = []
  existingTasks.forEach(existingTask => {
    if (!current.tasks.find(t => t.idInDoc === existingTask.idInDoc)) {
      tasksToRemove.push(existingTask)
    }
  })

  console.log("task count changed! ", { tasksToAdd, tasksToRemove })

}

