import { Data } from "@verdi/shared-constants"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { ButtonTertiary } from "../../components/buttons/ButtonTertiary"
import { setAssumptionRelevanceShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/assumption/setAssumptionRelevanceShowOptions"
import { VerdiIconRelevanceLevel } from "../../components/icons/VerdiIcons"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { useCallback, useMemo } from "react"
import { ButtonProps } from "@chakra-ui/react"


type Props = {
  assumption?: Data.AssumptionModel
  level?: Data.AssumptionRelevanceLevel
  hideLabel?: boolean
  onSelected?: (option: Data.AssumptionRelevanceLevelOption) => void
}

export const AssumptionRelevanceEditor = ({
  assumption,
  level,
  hideLabel,
  onSelected,
  ...buttonProps
}: Props & ButtonProps) => {


  const localLevel = assumption?.relevanceLevel || level || 1

  const onClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.stopPropagation()
    setAssumptionRelevanceShowOptionsCommandDefinition.triggerCommand?.({
      assumption,
      rect: evt.currentTarget.getBoundingClientRect(),
      onSelected,
    })
  }, [assumption, onSelected])

  const icon = useMemo(() => {
    return VerdiIconRelevanceLevel({ level: localLevel })
  }, [localLevel])

  if (hideLabel) {
    return <IconButtonTertiary
      aria-label={"relevance level"}
      icon={icon}
      onClick={onClick}
      {...buttonProps}
    />
  }

  return (
    <ButtonTertiary
      onClick={onClick}
      label={Data.AssumptionRelevanceLevelOptions[localLevel].label}
      leftIcon={icon}
      {...buttonProps}
      variant="outline"
      size="xs"
    />
  )
}
