import { GlobalCommandType } from "../../GlobalCommandType"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { DeleteDocCommandArgs } from "./deleteDoc"
import { getUrlForDocument, getUrlForOpportunityAssumptions } from "../../../../routes"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.deleteDocNoConfirm

/** Deletes a given document without showing a confirmation dialog */
export const deleteDocNoConfirmCommandDefinition: GlobalCommandDefinition<DeleteDocCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DeleteDocCommandArgs) => {

    const toReturn =
      {
        globalCommandType: cmdType,
        name: "Delete this note Immediately",
        searchName: "delete this note immediately",
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },

      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: DeleteDocCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // TODO: Run the delete here
    if (!services.documentRelationsProvider) {
      console.warn("deleteDocNoConfirm: No documentRelationsProvider available")
      return
    }

    const { docId, docTitle, docType, redirectAfterDelete, onDeleted, } = args

    const docToDelete = services.menuStructureProvider.getMenuItem(docId)
    if (!docToDelete) {
      console.warn("deleteDocNoConfirm: No document found with id ", docId)
      return
    }
    const parentDocId = docToDelete.parentDocId

    /**
      TODO: How to handle Assumptions and Opportunities???
      If we are simply just deleting the associated document, 
      then the Assumption or Opportunity will still exist.
      Is this ok?
      Since we soft delete documents (Document.deletedAt) then maybe this is ok to not address
      as long as we full join Assumption (or opportunity) to the main doc 
      to where it will fall out of the list if has been soft deleted? 
     */

    services.documentRelationsProvider.deleteDoc(
      docId,
      () => {

        onDeleted?.(docId)
        services.toast.showSuccess("Note deleted successfully")

        if (redirectAfterDelete) {
          let redirectUrl
          if (docType === DocumentSchema.DocumentType.assumption && parentDocId) {
            redirectUrl = getUrlForOpportunityAssumptions(parentDocId)
          } else {
            redirectUrl = parentDocId ? getUrlForDocument(parentDocId) : "/"
          }
          services.router.router.push(redirectUrl)
        }
        onProcessingComplete(true)
      }
    )

  },
  triggerCommand: (args: DeleteDocCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}
