import { cloneDeep } from "lodash";
import { Slice, Fragment, Schema } from "prosemirror-model";
import { EditorView } from "prosemirror-view";

import { ProsemirrorNodes } from "@verdi/shared-constants";

type SliceNode = { attrs?: ProsemirrorNodes.Attributes; content: SliceNode[] };
const attributesToRemove = [
  "id",
  "sourceBlockId",
  "originalBlockId",
  "transcriptTimestamp",
] as Array<keyof ProsemirrorNodes.Attributes>;

const recursivelyRemoveIds = (node: SliceNode) => {
  const nodeCopy = cloneDeep(node);
  if ("attrs" in nodeCopy) {
    for (const attribute of attributesToRemove) {
      delete nodeCopy.attrs?.[attribute];
    }
  }
  if (Array.isArray(nodeCopy.content)) {
    const content = nodeCopy.content.map(recursivelyRemoveIds);
    nodeCopy.content = content;
    return nodeCopy;
  } else {
    return nodeCopy;
  }
};

export const transformPasted = (slice: Slice, schema: Schema) => {
  const content = slice.content;
  const jsonContent = content.toJSON();
  const newJson = jsonContent.map(recursivelyRemoveIds);
  const transformedFragment = Fragment.fromJSON(schema, newJson);
  return new Slice(transformedFragment, slice.openStart, slice.openEnd);
};

export const handlePaste = (
  view: EditorView,
  event: ClipboardEvent,
  slice: Slice
) => {
  const {selection} = view.state;
  const {types = []} = event.clipboardData || {};

  if(types.includes('text/html')){
    const html = event.clipboardData?.getData('text/html');

    /*
      if this is a verdi doc, use built in copy and paste
      TODO: make this check a bit more deterministic. 
      maybe add type="verdi" to nodes to look for instead or some sort of meta attribute that will be picked up by clipboard
      maybe find a way to add a separate type to the clipboard
    */
    if(html?.includes('freeText')){
      return false;
    }
  }

  if(types.includes('text/plain')){
    const text = (event.clipboardData?.getData('text/plain') || '').trim();

    const [firstLine, ...restOfLines] = text.split('\n').map((line) => line.trim()).filter((line) => line !== '');

    const transaction = view.state.tr;

    transaction.delete(selection.from, selection.to);
    transaction.insertText(firstLine);

    if(restOfLines.length > 1){
      transaction.insert(transaction.selection.from, Fragment.fromArray(restOfLines.map((line) => view.state.schema.nodeFromJSON(ProsemirrorNodes.makeFreeTextBlock(line)))));
    }

    view.dispatch(transaction);

    return true
  }
  
  return false; // still let prosemirror handle the paste
};
