/**
 * @generated SignedSource<<8c3c690dfe1c7b375cebfc73ed12c415>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TipTapEditor_document$data = {
  readonly currentSnapshot: {
    readonly body: string;
    readonly version: number;
  };
  readonly description: string | null;
  readonly id: string;
  readonly opportunities: ReadonlyArray<{
    readonly description: string | null;
    readonly title: string | null;
  }>;
  readonly origin: {
    readonly aiPrompts: ReadonlyArray<{
      readonly id: string;
      readonly instructionsForUser: string | null;
    }>;
    readonly id: string;
  } | null;
  readonly templateInfo: {
    readonly id: string;
  } | null;
  readonly title: string | null;
  readonly type: DocumentType;
  readonly " $fragmentSpreads": FragmentRefs<"useTipTapData_document">;
  readonly " $fragmentType": "TipTapEditor_document";
};
export type TipTapEditor_document$key = {
  readonly " $data"?: TipTapEditor_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"TipTapEditor_document">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TipTapEditor_document",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Opportunity",
      "kind": "LinkedField",
      "name": "opportunities",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTipTapData_document"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentSnapshot",
      "kind": "LinkedField",
      "name": "currentSnapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplateInfo",
      "kind": "LinkedField",
      "name": "templateInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "origin",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DocumentAiPrompt",
          "kind": "LinkedField",
          "name": "aiPrompts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instructionsForUser",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "ba040cefa7612aab0212457fab99f32e";

export default node;
