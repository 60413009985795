import { CustomPromptAiCallOptions } from "../../../../ai/prompts/docDrafter/CustomPromptOnDocAiCall"
import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { addDocFromSelectionCommandDefinition } from "../../commandDefinitions/document/addDocFromSelection"
import { runAIPromptOnDocCommandDefinition } from "../../commandDefinitions/documentBody/runAIPromptOnDoc"
import { showCustomAIPromptCommandDefinition } from "../../commandDefinitions/documentBody/showCustomAIPrompt"


export const getCmdsForSelection = (currentSelectText: string) => {

  if (!currentSelectText) {
    return []
  }

  const selectedTextLength = currentSelectText.length

  const commands: VerdiCommand[] = []

  const promptOptions: CustomPromptAiCallOptions = {
    shouldAddNewDocPlaceholders: false,
  }

  if (selectedTextLength > 2) {
    const expandCmd = runAIPromptOnDocCommandDefinition.buildMenuItem?.({
      prompt: `Expand this text: ${currentSelectText}`,
      // IDEA: do not pas prompt here, rely on current selection at time of running command ???
      commandTitle: "Expand selected text",
      promptOptions,
    })
    commands.push(expandCmd)

    const newDocFromSelectionCmd = addDocFromSelectionCommandDefinition.buildMenuItem?.({
    })
    commands.push(newDocFromSelectionCmd)
  }

  if (selectedTextLength > 40) {
    const shorterCmd = runAIPromptOnDocCommandDefinition.buildMenuItem?.({
      prompt: `Make this text shorter: ${currentSelectText}`,
      commandTitle: "Make selected text shorter",
      promptOptions,
    })
    commands.push(shorterCmd)
  }

  const customPromptCmd = showCustomAIPromptCommandDefinition.buildMenuItem?.({
    // rectOfAnchorElement: dom, // IDEA: do not pas rect here, rely on other state ???
    title: "Ask Verdi AI to ..."
  })
  commands.push(customPromptCmd)


  const group: VerdiCommandGroup = {
    title: "Selection",
    commands,
  }
  return [group]


}
