import { AiCallableFunctionType, ApiTypes } from "@verdi/shared-constants";
import { findAndReplaceCommandDefinition } from "../../../components/commands/commandDefinitions/documentBody/findAndReplace";


/** Parses a raw function from an ai response, and triggers the corresponding command */
export const runFunctionFromAiResponse = async (rawFunction: ApiTypes.AiResponseFunctionToCall) => {

  if (!rawFunction || !rawFunction.name) {
    console.warn("runFunctionFromAiResponse: No function found in response")
    return
  }


  switch (rawFunction.name) {

    case AiCallableFunctionType.replaceText: {

      const args = rawFunction.arguments as replaceTextCallableFunctionArgs
      findAndReplaceCommandDefinition.triggerCommand?.({
        mode: "replace",
        textToFind: args.textToReplace,
        textToAdd: args.newText,
      })

      break
    }

    case AiCallableFunctionType.addAsNextLineAfter: {

      const args = rawFunction.arguments as addAsNextLineAfterCallableFunctionArgs
      findAndReplaceCommandDefinition.triggerCommand?.({
        mode: "insertAsNextLineAfter",
        textToFind: args.afterThisText,
        textToAdd: args.newText,
      })

      break
    }
    default:
      console.warn("runFunctionFromAiResponse: Unknown function type ", { rawFunction })

  }



}


type addAsNextLineAfterCallableFunctionArgs = {
  afterThisText: string,
  newText: string,
}
type replaceTextCallableFunctionArgs = {
  textToReplace: string,
  newText: string,
}
