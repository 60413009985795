import { useAppServices } from "../../appServices/useAppServices"
import { ConfirmDialog } from "../../forms/ConfirmDialog"


export const GlobalConfirmDialog = () => {

  const { confirmDialogProvider } = useAppServices()
  const { dialogArgs, disclosure, hideConfirmDialog } = confirmDialogProvider


  if (!dialogArgs) return null

  return (
    <div>
      Global Confirm Dialog
      <ConfirmDialog
        {...dialogArgs}
        disclosure={disclosure}
        onCancel={() => {
          console.log("GlobalConfirmDialog: onCancel")
          dialogArgs?.onCancel?.()
          hideConfirmDialog()
        }}
      />
    </div>
  )
}
