import { css } from "@emotion/react"
import { OpportunityRisk } from "./OpportunityRiskBadge"
import { useMemo } from "react"


type Props = {
  opportunityRisk: OpportunityRisk

}
/** A bar that illustrates the opportunity risk, in technicolor */
export const OpportunityRiskBar = ({
  opportunityRisk,
}: Props) => {


  const percentages = useMemo(() => {
    const { redCount, yellowCount, greenCount, totalCount } = opportunityRisk
    return {
      red: redCount / totalCount * 100,
      yellow: yellowCount / totalCount * 100,
      green: greenCount / totalCount * 100,
    }
  }, [opportunityRisk])


  return (
    <div css={css`
      width: 100%;
      font-size: small;
      color: white;
    `}>

      {opportunityRisk.totalCount === 0 &&
        <div css={css`
          background-color: ${opportunityRiskColorRed};
          width: 100%;
          padding-left: 4px;
        `}>
          Needs Attention
        </div>
      }

      {opportunityRisk.totalCount > 0 &&
        <div css={css`
          width: 100%;
          display: flex;
          flex-direction: row;
          text-align: center;
        `}>
          <div css={css`
            background-color: ${opportunityRiskColorRed};
            width: ${percentages.red}%;
          `}>
            {opportunityRisk.redCount}
          </div>

          <div css={css`
            background-color: ${opportunityRiskColorYellow};
            width: ${percentages.yellow}%;
          `}>
            {opportunityRisk.yellowCount}
          </div>

          <div css={css`
            background-color: ${opportunityRiskColorGreen};
            width: ${percentages.green}%;
          `}>
            {opportunityRisk.greenCount}
          </div>
        </div>
      }

    </div>
  )
}


export const opportunityRiskColorRed = "#C53030"
export const opportunityRiskColorYellow = "#D69E2E"
export const opportunityRiskColorGreen = "#25855A"
