import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { MenuDataGroups, hydrateDocumentMenuStructure } from '../screens/document/organize/MenuStructureManager'
import { Menu } from '@verdi/shared-constants'
import { documentUpdateFields } from '../screens/document/useUpdateDocument'


interface IDocumentsState {
  currentDocId: string | undefined
  menuItemsStructured: MenuDataGroups // To distinguish this early on between the graphQL related Document types, `MenuItemData` was used.
  recentDocumentMenuItems: Menu.MenuItemData[]
}

const initialState: IDocumentsState = {
  currentDocId: undefined,
  menuItemsStructured: {
    opportunitiesWithMixedChildren: [],
    opportunitiesWithOnlyOpportunityChildren: [],
    quickNotes: [],
    rootLevelDocs: [],
    flatMenuItems: [],
  },
  recentDocumentMenuItems: [],
}

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setCurrentDocId: (state, action: PayloadAction<string | undefined>) => {
      state.currentDocId = action.payload
    },

    setMenuItemsStructured: (state, action: PayloadAction<MenuDataGroups>) => {
      state.menuItemsStructured = action.payload
    },
    addMenuItem: (state, action: PayloadAction<Menu.MenuItemData>) => {

      const flatItems = [...state.menuItemsStructured.flatMenuItems, action.payload]
      state.menuItemsStructured = hydrateDocumentMenuStructure(flatItems)
    },
    updateMenuItem: (state, action: PayloadAction<{ docId: string, fields: documentUpdateFields }>) => {
      const { docId, fields } = action.payload
      const itemToUpdate = state.menuItemsStructured.flatMenuItems.find(item => item.id === docId)
      if (!itemToUpdate) {
        console.warn(`Could not find item with id ${docId}`)
        return
      }

      const updatedDoc = {
        ...itemToUpdate,
        ...fields,
      }

      const itemIndex = state.menuItemsStructured.flatMenuItems.findIndex(item => item.id === docId)
      const flatItems = state.menuItemsStructured.flatMenuItems
      flatItems[itemIndex] = updatedDoc
      state.menuItemsStructured = hydrateDocumentMenuStructure(flatItems)
    },
    removeMenuItem: (state, action: PayloadAction<string>) => {
      const docId = action.payload
      const flatItemsWithoutMe = state.menuItemsStructured.flatMenuItems.filter(item => item.id !== docId)
      state.menuItemsStructured = hydrateDocumentMenuStructure(flatItemsWithoutMe)
    },

    setRecentDocumentMenuItems: (state, action: PayloadAction<Menu.MenuItemData[]>) => {
      state.recentDocumentMenuItems = action.payload
    },
  },
  selectors: {
    getCurrentDocId: (state) => state.currentDocId,
    getMenuItemsStructured: (state) => state.menuItemsStructured,
    getFlatMenuItems: (state) => state.menuItemsStructured.flatMenuItems,
    getRecentDocumentMenuItems: (state) => state.recentDocumentMenuItems,
  }
})


/** Stores the current state of all `Document` data models, as found in the relational DB. (does not include any document body)
 * 
 *  Related to `servicesForCurrentDoc` which handles all services and hooks related to the current document editor. 
 *  See also: TipTapEditor.ts: `const docServices = useRegisterDocServices({ ...`
 * 
 *  Related to `menuStructureProvider`
 */
export const DocumentsState = {
  ...documentsSlice.actions,
  ...documentsSlice.selectors,
}


export default documentsSlice.reducer

