
import { AiCallableFunctionType, ApiTypes, OpenAi } from "@verdi/shared-constants"
import { DocumentIdea } from "../../../state/docIdeasSlice"
import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"


/** Given an Idea, ask the ai how it should be included in a given document's body */
export const applyIdeaToDocAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  idea: DocumentIdea,
) => {

  const allContext: AllContextForAi = await getContextForAi()

  const docContext = allContext.currentDoc
  if (!docContext) {
    console.warn("applyIDeaToDocAiCall: No currentDoc context found")
    return undefined
  }

  const prompt = getPrompt(docContext, idea)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    callableFunctionTypes: [
      AiCallableFunctionType.replaceText,
      AiCallableFunctionType.addAsNextLineAfter,
    ],
    openAIParams: {
      n: 2,
      model: OpenAi.Gpt_4_Latest,
    },
  })

  const rawFunctions = response?.aiResponseJson?.functionsToCall as ApiTypes.AiResponseFunctionToCall[] || []
  console.log("===> applyIDeaToDocAiCall: ", { prompt, rawFunctions })
  if (rawFunctions.length < 1) {
    return undefined
  }

  return rawFunctions[0]
}


const getPrompt = (docContext: DocumentContextForAi, idea: DocumentIdea) => {

  const docBody = docContext.body || ""
  const prompt = `You are an expert product manager and are editing a document. 
You presented the document to an expert product leader, and you are now updating the document based on your conversation.

###
DOCUMENT TITLE: ${docContext.title}
START DOCUMENT BODY:
${docBody}
END DOCUMENT BODY.
###

INSTRUCTIONS: 
Update the document based on this dialogue with the expert product leader:
DIALOGUE:
Expert Product Leader asked: ${idea.question}
You answered: ${idea.answer}

Make this document clearer and more actionable based on the dialogue.
The context will vary. You can insert, edit, or delete content as appropriate.
However, respond only to the specific question asked and answer given. 
Make only a very limited number of changes to the document based on the dialogue.
Preserve the current document as much as possible, and only update the document based on the dialogue.

RESPONSE FORMAT INSTRUCTIONS:
Return only the updated document body.
DO NOT include any introduction, description, or preamble in your response. 
DO NOT include START DOCUMENT BODY or END DOCUMENT BODY in your response.

EXAMPLE 1:
Given an empty document with the title: "Streamline onboarding"
Expert Product Leader asked: "What specific challenges with the current onboarding process?"
You answered: "Too many users bounce before completing onboarding."
A valid response could be to add a section titled "Current onboarding challenges" and describe the problem with users bouncing.

EXAMPLE 2:
Given a partially completed document with the title: "Personalization PRD"
Referring to a portion of the document citing increased user engagement as one of the target outcomes, the expert Product Leader asked: "Why do you think personalization will increase user engagement?"
You answered: "Personalized content is more actionable for users."
A valid response could be to edit the section on target outcomes to include a brief explanation of why personalized content is more actionable.

specify what function should be called, and pass in the appropriate parameters

`
  return prompt
}

// EXAMPLE 3:
// Given the following input:
// DOCUMENT TITLE: Streamline onboarding
// DIALOGUE:
// Expert Product Leader asked: What specific challenges with the current onboarding process?
// You answered: Too many users bounce before completing onboarding.

// A valid response could be:
// # Current onboarding challenges:
// - Too many users bounce before completing onboarding.
// - Users find the onboarding process too long.
// - Users are not guided to the most valuable features.
