import { getSchema } from "@tiptap/core";
import Text from "@tiptap/extension-text";
import * as NodeExtensions from "./nodeExtensions";
export * as NodeExtensions from "./nodeExtensions";
import marks from "./marks";
import { UniqueID } from "./nodeExtensions/tiptap/uniqueId";
export * as VerdiMarks from "./marks";
export { VerdiNodeTypes } from "./nodeExtensions/VerdiNodeTypes"
export {
  initialDoc,
  createNewDoc,
  incrementallyUpdateSnapshot,
} from "./initialDoc";
export type { StepInfo } from "./initialDoc";


const {
  Section,
  Question,
  FreeText,
  Title,
  ConfiguredDocument,
  ContentList,
  InlineSnippet,
  // DocReferenceNode,
  DocReferenceInlineNode,
  PlaceholderInlineNode,
  TaskCheckboxNode,
  VerdiNodeTypes,
} = NodeExtensions;

type SchemaExtensionsConfig = {
  InlineSnippet?: Parameters<typeof InlineSnippet>[0];
  // docReference?: Parameters<typeof DocReferenceNode>[0];
  docReferenceInline?: Parameters<typeof DocReferenceInlineNode>[0];
  placeholderInline?: Parameters<typeof PlaceholderInlineNode>[0];
  taskCheckbox?: Parameters<typeof TaskCheckboxNode>[0];
};

/** Returns the core Prose-mirror extensions shared between front end (`/Web`) and backend (`/Api` + `/WebSockets`) 
 * 
 *  Note: The front end has additional extensions in `extensionsForEditor.ts`
*/
export const schemaExtensions = (config?: SchemaExtensionsConfig) => [
  // required
  Text,
  // nodes
  ConfiguredDocument,
  ContentList,
  Title,
  FreeText,
  Section,
  Question,
  // marks
  ...marks,
  UniqueID.configure({
    types: [
      /** Any types listed here will have a unique `id` attribute added to them on create / transaction 
       * 
       *  They should also include an `id?: string,` attribute in their `Attributes` type
      */
      VerdiNodeTypes.taskCheckbox,
    ],
  }),
  InlineSnippet(config?.InlineSnippet),
  // DocReferenceNode(config?.docReference),
  DocReferenceInlineNode(config?.docReferenceInline),
  PlaceholderInlineNode(config?.placeholderInline),
  TaskCheckboxNode(config?.taskCheckbox),
];

export const schema = getSchema(schemaExtensions({}));
export { createStepFromDocumentBody } from "./snapshots";
export * from "./documentTypes";
export { GLOBAL_MAX_NESTING_LEVEL } from "./GLOBAL_MAX_NESTING_LEVEL";
export {
  getPlainTextFromDocumentNode,
  getPlainTextFromTitleNode,
} from "./getPlainTextFrom";
export * as PlaceholderInlineNodeUtils from "./nodeExtensions/PlaceholderInlineNodeUtils";
