import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { Node } from "@xyflow/react"
import { IdeaNodeWrapperData } from "../../../mindMap/internals/nodeTypes/idea/IdeaNode"
import { dispatch } from "../../../../state/store"
import { MindMapState } from "../../../../state/MindMapSlice"
import { Data, DocumentSchema } from "@verdi/shared-constants"
import { addDocAutoHandleTypeCommandDefinition } from "../document/addDocAutoHandleType"


const cmdType = GlobalCommandType.mindMapNodeSuggestionAccept

/** Might create a document if it does not exist. 
 * 
 * Navigates to something related to a mind map node, such as a document  */
export const mindMapNodeSuggestionAcceptCommandDefinition: GlobalCommandDefinition<MindMapNodeSuggestionAcceptCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: MindMapNodeSuggestionAcceptCommandArgs) => {
    const title = "Navigate from mind map node"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: MindMapNodeSuggestionAcceptCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { node, parentDocId } = args

    const ideaNode = node as IdeaNodeWrapperData
    let docId = ideaNode.data.docId
    if (!docId) {
      // Create a new document
      dispatch(MindMapState.setStatus({ isLoading: true, messageToUser: "Creating a note ..." }))

      const title = ideaNode.data.text
      const tagTitle = ideaNode.data.tags?.[0]?.title
      const docType = tagTitle ? Data.Tags.DefaultTagToDocTypeMapping.find(t => t.title === tagTitle)?.docType : undefined

      addDocAutoHandleTypeCommandDefinition.triggerCommand?.({
        newDocType: docType || DocumentSchema.DocumentType.document,
        newDocTitle: title,
        tagsToAdd: ideaNode.data.tags,
        parentDocId,
        onStatusUpdate: (isLoading, messageToUser) => {
          dispatch(MindMapState.setStatus({ isLoading, messageToUser }))
        },
        onCreated: (docId: string) => {
          dispatch(MindMapState.updateIdeaNodeData({
            id: ideaNode.id,
            data: { docId },
          }))
          dispatch(MindMapState.setStatus({ isLoading: false }))
          args.onCompleted(docId)
          onProcessingComplete(false)
        },
      })

    } else {
      console.warn("MindMapNodeSuggestionAcceptCommand: doc Id already existed")
      dispatch(MindMapState.setStatus({ isLoading: false }))
      onProcessingComplete(true)
    }


  },
  triggerCommand(args) {
    triggerGlobalCommand(cmdType, args)
  },
}

export type MindMapNodeSuggestionAcceptCommandArgs = {
  parentDocId: string | undefined,
  node: Node,
  onCompleted: (newDocId: string) => void,
}
