import { KeyValuePair } from "@verdi/shared-constants";
import { DocumentOutlineEntry } from "../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson";
import { cleanupAiOutputAsKeyValuePairs } from "../../utils/cleanupAiOutput";
import { ConceptFieldDelimiter } from "./useConceptGenerator";


/** Converts key value pairs of Section titles + paragraphs/descriptions
 * into a format that can be converted into document json for prosemirror */
export const convertToDocOutlineEntriesFromKvps = (sectionsWithDescriptions: KeyValuePair[]): DocumentOutlineEntry[] => {
  const steps: DocumentOutlineEntry[] = [];
  for (const part of sectionsWithDescriptions) {
    const childContent = part.value;
    const section: DocumentOutlineEntry = {
      type: "heading",
      content: part.key,
      children: childContent ? [{ type: "text", content: childContent }] : undefined,
    }
    steps.push(section);
  }
  return steps;
};


/** Takes raw text in the form of multiple lines of `section title|section content`
 *  and converts it into a list of document outline entries
 */
export const convertToDocOutlineEntriesFromRawText = (rawText: string): DocumentOutlineEntry[] => {
  const keyValuePairs = cleanupAiOutputAsKeyValuePairs(rawText, ConceptFieldDelimiter);
  return convertToDocOutlineEntriesFromKvps(keyValuePairs);
}
