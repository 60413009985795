import { EditorView } from "@tiptap/pm/view"
import { useCallback, useState } from "react"
import { EditorState } from "prosemirror-state"
import { getSelectedBlockText } from "../utils/getSelectedBlockText"
import { ComboBoxController } from "../../../../components/commands/miniCommandMenu/comboBox/useComboBoxController"


type Props = {
  comboBoxState: ComboBoxController
  openTheMenu: () => void
  closeTheMenu: () => void
  setFocusTo: (target: "editor" | "command-menu") => void
  menuIsOpen: boolean
}

/** Handles using the Slash "/" commands within the DocumentEditor, 
 * as input for the Command Menu (ComboBox) */
export const useDocEditorAsComboBoxInput = ({
  comboBoxState,
  openTheMenu,
  closeTheMenu,
  setFocusTo,
  menuIsOpen,
}: Props) => {


  /** Snapshot of text from the current line, starting from where the slash was entered to the end */
  const [currentLineWhenStartedCmd, setCurrentLineWhenStartedCmd] = useState<string>()

  /** triggered whenever current user presses any key in the editor */
  const onKeyDownForComboBox = useCallback((event: KeyboardEvent, view: EditorView) => {

    const keyIsCommandKey = event.key === COMMAND_CHAR || event.code === "Slash"
    if (keyIsCommandKey) {
      //if it is the only thing on the line, show the command menu
      setTimeout(() => {
        const currentLine = getCurrentLineState(view.state)
        if (currentLine) {
          setCurrentLineWhenStartedCmd(currentLine.textAfter)
        }
        openTheMenu()
        if (!currentLine.textBefore) {
          setTimeout(() => setFocusTo("command-menu"), 1)
        }
      }, 1)
      return false // Return false to Allow the slash to be entered into the editor
    } else if (menuIsOpen) {
      // NOTE: Escape key is handled via `useOnKeyDownCommandMenu()`

      comboBoxState.handleOnKeyDown(event)

      if (event.key === "Enter" && comboBoxState.currentCommandInfo) {
        return true // return true to prevent adding a new line to the doc
      }
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        if (event.shiftKey) return false // Allows the editor to handle shift+arrow when selecting text. Coupled with `useComboBoxController.tsx`
        setFocusTo("command-menu")
        return true // return true to prevent document caret going up and down the doc. 

      } else {

        const currentLine = getCurrentLineState(view.state)

        // If the user has deleted the command char, close the menu
        if (event.key === "Backspace" && !currentLine.textAfter) {
          closeTheMenu()
        }

        // If current line has the command char, use it as the search text
        if (currentLine.hasCommandChar && currentLine.textAfter) {
          const searchText = currentLineWhenStartedCmd ?
            currentLine.textAfter.replace(currentLineWhenStartedCmd, "")
            : currentLine.textAfter
          comboBoxState.setSearchText(searchText)
        }
      }
    }

    return false
  }, [comboBoxState, menuIsOpen, openTheMenu, closeTheMenu, setFocusTo, currentLineWhenStartedCmd])


  return {
    onKeyDownForComboBox,
  }

}


/** The character that will open the command menu when it is typed into an editor. */
export const COMMAND_CHAR = '/'


export const getCurrentLineState = (editorState: EditorState | null | undefined): CommandCharacterOnCurrentLine => {

  if (!editorState) return { hasCommandChar: false }

  const nodeText = getSelectedBlockText(editorState.selection.$anchor)

  if (!nodeText) return { hasCommandChar: false }

  const lastIndex = nodeText.lastIndexOf(COMMAND_CHAR)
  const textAfter = nodeText.substring(lastIndex + 1)
  const textBefore = nodeText.substring(0, lastIndex)

  return {
    hasCommandChar: lastIndex > -1,
    text: nodeText,
    textAfter,
    textBefore,
  }
}

export type CommandCharacterOnCurrentLine = {
  hasCommandChar: boolean
  text?: string
  textAfter?: string
  textBefore?: string
}
