/**
 * @generated SignedSource<<4a1530782b4d753b5aba090bd9d181cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AiPromptFormModal_documentLevelContextAiPrompt$data = {
  readonly dontDo: string | null;
  readonly id: string;
  readonly notesForAdmin: string | null;
  readonly openApiParams: {
    readonly best_of: number | null;
    readonly echo: boolean | null;
    readonly frequency_penalty: number | null;
    readonly logprobs: number | null;
    readonly max_tokens: number | null;
    readonly model: string | null;
    readonly n: number | null;
    readonly presence_penalty: number | null;
    readonly stop: ReadonlyArray<string> | null;
    readonly suffix: string | null;
    readonly temperature: number | null;
    readonly top_p: number | null;
  } | null;
  readonly promptText: string;
  readonly " $fragmentType": "AiPromptFormModal_documentLevelContextAiPrompt";
};
export type AiPromptFormModal_documentLevelContextAiPrompt$key = {
  readonly " $data"?: AiPromptFormModal_documentLevelContextAiPrompt$data;
  readonly " $fragmentSpreads": FragmentRefs<"AiPromptFormModal_documentLevelContextAiPrompt">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AiPromptFormModal_documentLevelContextAiPrompt",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promptText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dontDo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notesForAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OpenAIParams",
      "kind": "LinkedField",
      "name": "openApiParams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "best_of",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "echo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frequency_penalty",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logprobs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "max_tokens",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "model",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "n",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "presence_penalty",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stop",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "suffix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "temperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "top_p",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DocumentAiPrompt",
  "abstractKey": null
};

(node as any).hash = "73356e77854086a710999a777f90ad95";

export default node;
