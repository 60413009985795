import { Checkbox } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { Data } from "@verdi/shared-constants"


type Props = {
  userWithPermission?: Data.User.UserWithPermission
  onPermissionPropertyChanged: (
    userId: string,
    propertyName: keyof Data.UserPermissionForOrg.UserPermissionForOrgModel,
    newPropertyValue: boolean,) => void
}
export const PermissionEditor = ({
  userWithPermission,
  onPermissionPropertyChanged
}: Props) => {

  if (!userWithPermission) {
    return null
  }



  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      gap: 8px;
    `}>
      <Checkbox
        size="sm"
        isChecked={Boolean(userWithPermission?.permission?.canManagePermissions)}
        onChange={(e) => onPermissionPropertyChanged(
          userWithPermission?.user.id,
          "canManagePermissions",
          e.target.checked)}
      >
        Manage Permissions
      </Checkbox>

      <Checkbox
        size="sm"
        isChecked={Boolean(userWithPermission?.permission?.canManageBilling)}
        onChange={(e) => onPermissionPropertyChanged(
          userWithPermission?.user.id,
          "canManageBilling",
          e.target.checked)}
      >
        Billing
      </Checkbox>
    </div>
  )
}
