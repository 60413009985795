import { css } from "@emotion/react"
import { useColor } from "../../utility-hooks/useColor"
import { DocumentIcon } from "../icons/DocumentIcon"
import { OpportunityRisk } from "../../screens/assumptions/risk/OpportunityRiskBadge"
import { OpportunityRiskIcon } from "../../screens/assumptions/risk/OpportunityRiskIcon"
import { KanbanCardBadge } from "./KanbanCardBadge"


export type KanbanCardData = {
  id: string,
  documentId: string,
  title: string,
  lastActivityAt?: Date,
  lastActivityIsRecent?: boolean,
  lastActivitySince?: string,
  lastActivityToolTip?: string,
  createdAt?: Date,
  statusColumnId: string,
  statusText: string,
  childCount?: number,
  childOpportunities: KanbanCardData[],
  risk?: OpportunityRisk,
}

export type KanbanCardProps = {
  setTitle: (title: string) => void,
  cardData: KanbanCardData
  isBeingDragged: boolean,
  onCardClick: (cardId: string) => void,
}
export const KanbanCard = ({
  cardData,
  setTitle,
  isBeingDragged,
  onCardClick,
}: KanbanCardProps) => {


  const borderColor = useColor("surfaceBorder")
  const borderOnDrag = useColor("surfaceBorderActive")
  const backgroundColor = useColor("surfaceBgSecondary")

  const {
    title,
    childCount,
    lastActivityToolTip,
    risk,
  } = cardData


  return (
    <div
      onClick={() => onCardClick(cardData.id)}
      css={css`
        padding: 10px;
        border-radius: 8px;
        border: 1px solid ${isBeingDragged ? borderOnDrag : borderColor};
        background-color: ${backgroundColor};
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.05);
        margin-bottom: 8px;
        margin-right: 8px;
        ${cardData.lastActivityIsRecent ? css`
          animation: thisJustHappened-Bg 10s;
        ` : ""}
    `}>
      <h3
        title={title}
        css={css`
          font-size: 0.9rem;
          line-height: 1.2;
          letter-spacing: 0.5px;
          margin: 0 0 8px 0;
          text-transform: none;
          opacity: 1;
      `}>
        {title || "Untitled"}
      </h3>

      <div>
        <small css={css`
          opacity: 0.7;
        `}>
          {lastActivityToolTip}
        </small>
      </div>

      <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 4px;
        `}>

        <div css={css`
          display: flex;
          gap: 8px;
        `}>

          <KanbanCardBadge
            icon={
              <OpportunityRiskIcon
                opportunityRisk={risk}
              />
            }
            label={`${risk ? risk.totalCount : "?"}`}
            toolTip={"Risk Score"}
          />

          <KanbanCardBadge
            icon={
              <DocumentIcon
                width="15px"
                height="15px"
              />
            }
            label={`${childCount || 0}`}
            toolTip={`${childCount || "no"} document(s)`}
          />

          {/* <div title={lastActivityToolTip}>
            L:{lastActivitySince} T:{lastActivityToolTip}
          </div> */}

        </div>

        <div css={css`
          display: flex;
          gap: 8px;
          align-items: center;
        `}>
          {/* <small>
            {statusText}
          </small> */}
          {/* <Avatar
            size="2xs"
          /> */}
        </div>

      </div>

    </div>
  )
}
