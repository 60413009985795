import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { ReactNode } from "react"


const cmdType = GlobalCommandType.showConfirmDialog


export const showConfirmDialogCommandDefinition: GlobalCommandDefinition<ShowConfirmDialogCommandArgs> = {
  globalCommandType: cmdType,
  /** This command is not typically triggered via a user facing menu item. */
  buildMenuItem: (args: ShowConfirmDialogCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Show Confirm Dialog',
        description: '',
        searchName: 'show confirm dialog',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "debug",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ShowConfirmDialogCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("showConfirmDialogCommandDefinition: processCommand: args", args)
    if (!services.confirmDialogProvider) {
      console.warn("showConfirmDialog services.confirmDialogProvider is undefined")
      return
    }

    services.confirmDialogProvider.showConfirmDialog(args)

    onProcessingComplete(true)

  },
  triggerCommand: (args: ShowConfirmDialogCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ShowConfirmDialogCommandArgs = {
  heading: string,
  dialogBodyText?: string,
  dialogBodyHtml?: ReactNode,
  confirmButtonLabel: string,
  onConfirmSuccess: () => void
  isDestructiveAction?: boolean,
  onCancel?: () => void,
  secondaryButtonOptions?: {
    label: string,
    onClick: () => void,
  }
}
