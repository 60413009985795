import { Mark, MarkConfig } from "@tiptap/core";
import { AISuggestionCommandType } from "../../ai/AISuggestions";
import { VerdiMarkType } from "./VerdiMarksCommon";


const KEY = VerdiMarkType.AiSuggestion;

export type AiSuggestionMarkCommand = {
  commandType: AISuggestionCommandType;
  title: string;
}
export type AiSuggestionMarkAttributes = {
  suggestionText: string;
  commands: AiSuggestionMarkCommand[];
}


const attributes: AiSuggestionMarkAttributes = {
  suggestionText: "",
  commands: [],
};

export type type = {
  type: typeof KEY;
};

const config: MarkConfig = {
  name: KEY,
  addAttributes() {
    return attributes;
  },
  renderHTML(props) {
    const element = document.createElement("span");

    element.style.backgroundColor = "var(--nudge-highlight-bg-color)";
    element.style.padding = "2px";
    element.style.borderRadius = "4px";
    element.setAttribute("ve-mark-type", KEY);
    // If using mark watcher, pack the attributes here, for later unpacking
    element.setAttribute("ve-mark-attrs", JSON.stringify(props.mark.attrs)); // Good for debugging

    element.addEventListener("click", (event: MouseEvent) => {
      if (!event.target || !window) return

      const attrs = props.mark.attrs
      console.log("AiSuggestionMark CLICKED", { attrs, event })
      const newEvent = new CustomEvent(MARK_CLICKED_EVENT_KEY, {
        detail: {
          rect: (event.target as HTMLElement).getBoundingClientRect(),
          attributes: attrs,
          markType: KEY,
        }
      })

      window.dispatchEvent(newEvent)
    })

    return element;
  },
};

export const MARK_CLICKED_EVENT_KEY = "MARK_CLICKED_EVENT_KEY"

export const AiSuggestionMark = Mark.create(config);
