import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { CurrentDocTitleDomElementId } from "../../../../screens/document/DocumentSettingsEditor"


const cmdType = GlobalCommandType.setFocusTo

/** Sets focus to the specified element, if it exists in the DOM */
export const setFocusToCommandDefinition: GlobalCommandDefinition<SetFocusToCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetFocusToCommandArgs) => {
    const title = 'Set focus to the editor'
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "debug",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetFocusToCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    if (args.target === "currentDocTitle") {
      const titleInput = document.querySelector<HTMLInputElement>(`#${CurrentDocTitleDomElementId}`)
      if (titleInput) {
        titleInput.focus()
        onProcessingComplete(true)
        return
      }
    }

    if (args.target === "currentDocEditor") {
      const editor = services.servicesForCurrentDoc?.aiTipTapBridge.getEditor()
      if (!editor) {
        console.warn('setFocusToCommandDefinition: No editor found in servicesForCurrentDoc')
        onProcessingComplete(false)
        return
      }
      editor.commands.focus()
    }

    onProcessingComplete(true)
  },
  triggerCommand: (args?: SetFocusToCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type SetFocusToCommandArgs = {
  target: "currentDocEditor" | "currentDocTitle"
}
