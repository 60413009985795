import { Data, DocumentSchema } from "@verdi/shared-constants";

/** Returns the default relationship type for a given document type */
export const mapDocTypeToRelationType = (docType: DocumentSchema.DocumentType) => {
  const docTypes = DocumentSchema.DocumentType
  const relTypes = Data.DocumentRelationType

  switch (docType) {
    case docTypes.assumption: return relTypes.assumptionOf
    case docTypes.concept: return relTypes.conceptOf
    case docTypes.insight: return relTypes.insightOf
    case docTypes.opportunity: return relTypes.opportunityOf
  }

  if (docTypesOfActions.includes(docType)) {
    return Data.DocumentRelationType.actionOf
  }

  return undefined
}


export const docTypesOfActions = [
  DocumentSchema.DocumentType.interviewGuide,
  DocumentSchema.DocumentType.research,
  DocumentSchema.DocumentType.guide,
  DocumentSchema.DocumentType.interview,
]

