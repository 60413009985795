import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary"
import { css } from "@emotion/react"
import { useColorModeValue } from "@chakra-ui/react"
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary"
import { KeyValuePair } from "@verdi/shared-constants"
import { EditableKeyValuePair } from "../../../screens/documentRelation/common/EditableKeyValuePair"
import { UseContentFramer } from "./useConceptFramer"


type Props = {
  onContinueClick: (conceptsToUse: KeyValuePair[]) => void
  isContinuing: boolean
  conceptFramer: UseContentFramer
}
/** Helps the user review and make changes to the initial concepts for a document */
export const ConceptFramerView = ({
  onContinueClick,
  isContinuing,
  conceptFramer,
}: Props) => {

  const {
    answerSets,
    currentAnswerSetIndex,
    startTheProcess,
    showNextSetOfAnswers,
    removeKeyValuePair,
    updateTitle,
    updateDescription,
    isGettingQuestions,
    isGettingAnswers,
  } = conceptFramer

  const cardBgColor = useColorModeValue("#F9F9F9", "#222")
  const cardBorderColor = useColorModeValue("#DDD", "#555")


  return (
    <div css={css`
      border: 1px solid ${answerSets.length > 0 ? cardBorderColor : "transparent"};
      border-radius: 5px;
    `}>

      <div css={css`
        display: flex;
        flex-direction: row;
        padding: ${answerSets.length > 0 ? 10 : 0}px;
        justify-content: space-between;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: ${answerSets.length > 0 ? cardBgColor : "transparent"};
        border-bottom: 1px solid ${answerSets.length > 0 ? cardBorderColor : "transparent"};
      `}>

        {answerSets.length === 0 &&
          <ButtonPrimary
            label="Help me frame this opportunity"
            onClick={async () => startTheProcess()}
            isLoading={isGettingQuestions || isGettingAnswers}
            loadingText={isGettingQuestions ? "Considering possibilities ..." : "Creating suggestions ..."}
          />
        }
        {answerSets.length > 0 &&
          <div>
            <ButtonSecondary
              label="Change the outline"
              onClick={async () => startTheProcess()}
              isLoading={isGettingQuestions || isGettingAnswers}
              loadingText={isGettingQuestions ? "Thinking some more ..." : "Creating suggestions ..."}
              marginRight={2}
            />

            <ButtonSecondary
              label={`Change the answers (${currentAnswerSetIndex + 1} of ${answerSets.length})`}
              onClick={showNextSetOfAnswers}
              hidden={isGettingQuestions || isGettingAnswers}
              marginRight={2}
            />

          </div>
        }

        {!isGettingAnswers && !isGettingQuestions && answerSets.length > 0 &&

          <div>

            <ButtonPrimary
              label="Use this"
              onClick={() => {
                const conceptsToUse = answerSets[currentAnswerSetIndex]
                onContinueClick(conceptsToUse)
              }}
              isLoading={isContinuing}
            />
          </div>
        }
      </div>
      <div>

        {answerSets.length > 0 && answerSets[currentAnswerSetIndex].map((answer, key) =>

          <EditableKeyValuePair
            key={key}
            title={answer.key}
            description={answer.value}
            remove={() => removeKeyValuePair(key)}
            updateTitle={(value) => updateTitle(key, value)}
            updateDescription={(value) => updateDescription(key, value)}
          />

        )}

      </div>
    </div>
  )
}
