import { CmdKCommand } from "./CmdKCommand"


export const exampleCmdKCommands: CmdKCommand[] = [

  {
    title: "Make a fancy new note",
    itemId: "make-a-fancy-new-note",
    shortcutKeys: ['A'],
    onExecute: () => {
      console.log("Executing make new note...")
    }
  },
  {
    title: "Write all the things ",
    itemId: "write-all-the-things",
    shortcutKeys: ['w'],
    onExecute: () => {
      console.log("Executing Assign to...")
    }
  },
  {
    title: "Assign to...",
    itemId: "assign-to",
    onExecute: () => {
      console.log("Executing Assign to...")
    }
  },
  {
    title: "Change status...",
    itemId: "change-status",
    shortcutKeys: ['S'],
    onExecute: () => {
      console.log("Executing Change status...")
    },
    icon: <ChangeStatusIcon />,
  },
  {
    title: "Change priority...",
    itemId: "change-priority",
    shortcutKeys: ['P'],
    onExecute: () => {
      console.log("Executing Change priority...")
    },
    icon: <ChangePriorityIcon />,
  },
  {
    title: "Change labels...",
    itemId: "change-labels",
    shortcutKeys: ['L'],
    onExecute: () => {
      console.log("Executing Change labels...")
    },
    icon: <ChangeLabelsIcon />,
  },
  {
    title: "A really long command name that should wrap to the next line when it gets too long so let's hope that it does!",
    itemId: "a-really-long-command-name-that-should-wrap-to-the-next-line",
    onExecute: () => {
      console.log("Executing Assign to me")
    }
  },
  {
    title: "Remove label...",
    itemId: "remove-label",
    shortcutKeys: ['⇧', 'L'],
    onExecute: () => {
      console.log("Executing Remove label...")
    },
    icon: <RemoveLabelIcon />,
  },
  {
    title: "Set due date...",
    itemId: "set-due-date",
    shortcutKeys: ['⇧', 'D'],
    onExecute: () => {
      console.log("Executing Set due date...")
    },
    icon: <SetDueDateIcon />,
  },


]


function ChangeStatusIcon() {
  return (
    <svg width="16" height="16" viewBox="-1 -1 15 15" fill="currentColor">
      <path d="M10.5714 7C10.5714 8.97245 8.97245 10.5714 7 10.5714L6.99975 3.42857C8.9722 3.42857 10.5714 5.02755 10.5714 7Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.5C10.0376 12.5 12.5 10.0376 12.5 7C12.5 3.96243 10.0376 1.5 7 1.5C3.96243 1.5 1.5 3.96243 1.5 7C1.5 10.0376 3.96243 12.5 7 12.5ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
      />
    </svg>
  )
}

function ChangePriorityIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
      <rect x="1" y="8" width="3" height="6" rx="1"></rect>
      <rect x="6" y="5" width="3" height="9" rx="1"></rect>
      <rect x="11" y="2" width="3" height="12" rx="1"></rect>
    </svg>
  )
}

function ChangeLabelsIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2105 4C10.6337 4 11.0126 4.18857 11.24 4.48L14 8L11.24 11.52C11.0126 11.8114 10.6337 12 10.2105 12L3.26316 11.9943C2.56842 11.9943 2 11.4857 2 10.8571V5.14286C2 4.51429 2.56842 4.00571 3.26316 4.00571L10.2105 4ZM11.125 9C11.6773 9 12.125 8.55228 12.125 8C12.125 7.44772 11.6773 7 11.125 7C10.5727 7 10.125 7.44772 10.125 8C10.125 8.55228 10.5727 9 11.125 9Z"
      />
    </svg>
  )
}

function RemoveLabelIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2105 4C10.6337 4 11.0126 4.18857 11.24 4.48L14 8L11.24 11.52C11.0126 11.8114 10.6337 12 10.2105 12L3.26316 11.9943C2.56842 11.9943 2 11.4857 2 10.8571V5.14286C2 4.51429 2.56842 4.00571 3.26316 4.00571L10.2105 4ZM11.125 9C11.6773 9 12.125 8.55228 12.125 8C12.125 7.44772 11.6773 7 11.125 7C10.5727 7 10.125 7.44772 10.125 8C10.125 8.55228 10.5727 9 11.125 9Z"
      />
    </svg>
  )
}

function SetDueDateIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 5C15 2.79086 13.2091 1 11 1H5C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H6.25C6.66421 15 7 14.6642 7 14.25C7 13.8358 6.66421 13.5 6.25 13.5H5C3.61929 13.5 2.5 12.3807 2.5 11V6H13.5V6.25C13.5 6.66421 13.8358 7 14.25 7C14.6642 7 15 6.66421 15 6.25V5ZM11.5001 8C11.9143 8 12.2501 8.33579 12.2501 8.75V10.75L14.2501 10.75C14.6643 10.75 15.0001 11.0858 15.0001 11.5C15.0001 11.9142 14.6643 12.25 14.2501 12.25L12.2501 12.25V14.25C12.2501 14.6642 11.9143 15 11.5001 15C11.0859 15 10.7501 14.6642 10.7501 14.25V12.25H8.75C8.33579 12.25 8 11.9142 8 11.5C8 11.0858 8.33579 10.75 8.75 10.75L10.7501 10.75V8.75C10.7501 8.33579 11.0859 8 11.5001 8Z"
      />
    </svg>
  )
}
