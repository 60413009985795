import { css } from "@emotion/react"
import { useAddNewOpportunity } from "../documents/add/globalModal/useAddNewOpportunity"
import { OpportunityTitleInput } from "./OpportunityTitleInput"
import { getUrlForDocument } from "../../routes"
import { useAppServices } from "../../components/appServices/useAppServices"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"



/** Very similar to AddNewOpportunityGlobalModal */
export const AddNewOpportunityFromHome = () => {

  const { router, toast } = useAppServices()

  const {
    createNewOpportunity,
    setOpportunityDescription,
    isCreatingOpportunity,
    opportunityDescription,
  } = useAddNewOpportunity({
    onCreateComplete: (opportunityDocId: string) => {
      const url = getUrlForDocument(opportunityDocId)
      router.router.push(url)
    },
    onError: toast.showError,
  })


  return (
    <div css={css`
      width: 100%;
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: start;
    `}>

      <OpportunityTitleInput
        isDisabled={isCreatingOpportunity}
        title={opportunityDescription}
        onTitleChange={setOpportunityDescription}
      >
        {opportunityDescription.length > 0 &&
          <ButtonPrimary
            label="Create Opportunity"
            marginTop={2}
            onClick={createNewOpportunity}
            isLoading={isCreatingOpportunity}
            isDisabled={isCreatingOpportunity || opportunityDescription.length < 5}
          />
        }
      </OpportunityTitleInput>


    </div>

  )

}
