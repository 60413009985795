import Placeholder from '@tiptap/extension-placeholder'
import './placeholder.css'
import { DocumentSchema } from '@verdi/shared-constants'


/** This placeholder is only on a per line basis, 
 * and does not store anything within the prosemirror document body, 
 * different than other placeholders */
export const ConfiguredPlaceholder = Placeholder.configure({
  showOnlyCurrent: true,
  showOnlyWhenEditable: true,
  includeChildren: true,
  placeholder: (args) => {
    const { editor, pos } = args
    const resolvedPosition = editor.state.doc.resolve(pos)
    const isFirstChild = resolvedPosition.index() === 0
    if (!editor.isFocused) {
      return "Click to edit ..."
    }
    if (isFirstChild) {
      const parent = resolvedPosition.parent
      switch (parent.type.name) {
        case `section`:
          return `Enter a section name ...`
        case `question`:
          return `Enter a question ...`
        case DocumentSchema.VerdiNodeTypes.taskCheckbox:
          return `Enter a task ...`
        case `freeText`:
          return `Start typing here, or press '/' for commands ...`
        default:
          break;
      }
    }
    return 'Enter Text'
  },
})
