import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"


const cmdType = GlobalCommandType.docBodyMarkClearAll


export const docBodyMarkClearAllCommandDefinition: GlobalCommandDefinition<DocBodyMarkClearAllCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DocBodyMarkClearAllCommandArgs) => {
    const title = "Remove highlighted suggestions"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        description: '',
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: DocBodyMarkClearAllCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      console.warn("docBodyMarkClearAll: No doc editor found")
      onProcessingComplete(false)
      return
    }

    editor.commands.clearVerdiMarks([
      editor.state.schema.marks.KnowledgeGraph,
      editor.state.schema.marks.AiSuggestion,
      // editor.state.schema.marks.PlaceholderInline, // Might NOT want to clear these
    ]);

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: DocBodyMarkClearAllCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type DocBodyMarkClearAllCommandArgs = {

}
