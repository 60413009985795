import { useCallback, useMemo } from "react"
import { SelectCommandDropdown } from "../../components/commands/miniCommandMenu/SelectCommandDropdown"
import { setTagsShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/tags/setTagsShowOptions"
import { Tag } from "../../state/TagsSlice"
import { BuiltInColor, getBuiltInColor, getBuiltInColorBg } from "../../utility-hooks/useColor"
import { TagsChangeSet } from "../../state/TagsProvider"


type SelectTagDropdownProps = {
  documentId?: string,
  currentTags: Tag[],
  /** Optionally get notified when complete. See the `setTags.ts` cmd def for the default logic. */
  onTagSelected?: (tagChanges: TagsChangeSet) => void,
}
export const SelectTagDropdown = ({
  documentId,
  currentTags,
  onTagSelected,
}: SelectTagDropdownProps) => {

  const currentTag = currentTags?.[0]

  const summonTheCommandMenu = useCallback((rect: DOMRect) => {

    setTagsShowOptionsCommandDefinition.triggerCommand?.({
      rect,
      documentId,
      currentTag,
      onTagSelected,
    })
  }, [documentId, currentTag, onTagSelected])

  const colors = useMemo(() => {
    return getColors(currentTag?.title)
  }, [currentTag?.title])

  const allTagTitles = currentTags?.map(t => t.title).join(", ") || ""


  return (
    <SelectCommandDropdown
      isDisabled={false}
      title={allTagTitles}
      label={currentTag?.title || ""}
      commandOptions={summonTheCommandMenu}
      onItemSelected={() => { }} // Handled by the `setTags.ts` cmd def
      searchPlaceholderText="choose a framework"
      maxWidth={200}
      size="xs"
      padding="2px 4px"
      // fontSize="0.7rem"
      height="auto"
      minHeight="16px"
      backgroundColor={colors.bg}
      color={colors.color}
    />
  )
}

const getColors = (tagTitle: string | undefined) => {
  const colorInfo = tagTitle ? map[tagTitle] : undefined
  if (colorInfo) {
    return {
      bg: getBuiltInColorBg(colorInfo),
      color: getBuiltInColor(colorInfo),
    }
  }

  return { bg: "inherit", color: "inherit", }
}

type TagTypeToColor = {
  [key in string]: BuiltInColor
}

const map: TagTypeToColor = {
  "Assumption": "yellow",
  "Problem": "orange",
  "Solution": "blue",
  "Outcome": "green",
}
