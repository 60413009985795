import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { getDraftInterviewGuide } from "../../../../ai/suggestions/getDraftInterviewGuide"
import { writeToDocBodyCommandDefinition } from "./writeToDocBody"
import { dispatch } from "../../../../state/store"
import { loadingStatusState } from "../../../../state/loadingStatusSlice"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"


const cmdType = GlobalCommandType.draftInterviewGuide


export const draftInterviewGuideCommandDefinition: GlobalCommandDefinition<DraftInterviewGuideCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DraftInterviewGuideCommandArgs) => {

    const title = "Draft this interview guide"
    const toReturn =
      {
        id: args.id,
        globalCommandType: cmdType,
        name: title,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        searchName: title.toLocaleLowerCase(),
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: DraftInterviewGuideCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Drafting interview guide...",
    }))

    const {
      companyContextProvider,
      getDocumentContextForAi,
      getOpportunityContextForAi,
    } = services

    const companyContext = await companyContextProvider.getCompanyContextForPrompt()
    const opportunityContext = await getOpportunityContextForAi()
    const docContext = getDocumentContextForAi()

    const documentOutline = await getDraftInterviewGuide(companyContext, opportunityContext, docContext)
    console.log("draftInterviewGuideCommandDefinition: documentOutline", { documentOutline })

    // Write to the doc body here
    writeToDocBodyCommandDefinition.triggerCommand?.({
      documentOutline,
      insertPosition: InsertPosition.startOfDocument,
    })

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
    }))

    args.onCompleted?.()
    onProcessingComplete(true)
  },
}


export type DraftInterviewGuideCommandArgs = {
  onCompleted: () => void
  id: string
}

