import { useColorModeValue } from '@chakra-ui/react'
import { css } from '@emotion/react'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay'
import { PageLayoutSplitViewWrapper } from '../../components/pageLayout/pageLayoutSplitViewWrapper'
import { useUrlParams } from '../../utility-hooks/useUrlParams'
import { AddSnippetButton } from './AddSnippetButton'
import { SnippetPageQuery } from './__generated__/SnippetPageQuery.graphql'
import { EditSnippet } from './EditSnippet'
import { useAppServices } from '../../components/appServices/useAppServices'


const queryQL = graphql`
  query SnippetPageQuery{
    snippetConnection(first: 200) {
      __id
      edges {
        node {
          id
          placeholderKey
          textValue
        }
      }
    }
  }
`


export const SnippetPage = () => {


  const appServices = useAppServices()
  const { orgId } = useUrlParams()
  const query = useLazyLoadQuery<SnippetPageQuery>(queryQL, {})
  const snippets = query.snippetConnection.edges


  return (
    <PageLayoutSplitViewWrapper
      appServices={appServices}
      mainMenu={<div></div>}

      mainContent={
        <div css={css`
          padding: 10px;
          min-height: 80vh;
        `}>
          <div css={css`
            margin: 20px;
          `}>
            <h1>
              Snippets
            </h1>
            <div>
              Get your snippets right here!
            </div>
          </div>

          <div>
            {snippets.map((s) =>
              <EditSnippet key={s.node.id}
                id={s.node.id}
                placeholderKey={s.node.placeholderKey}
                textValue={s.node.textValue}
              />
            )}
          </div>

          <AddSnippetButton updateConnectionIds={[query.snippetConnection.__id]} />

        </div>

      } />

  )
}
