

const apiDomain = process.env.REACT_APP_API_DOMAIN


const config: RequestInit = {
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
  credentials: "include",
}

export const makeGetRequestJson = async (endpoint: string) => {
  try {
    const response = await fetch(`${apiDomain}/${endpoint}`, {
      ...config,
      method: "get",
    })
    return response.json()
  } catch (e) {
    // retry once after a delay
    console.warn(`Failed to fetch: "/${endpoint}", retrying shortly...`)
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const response = await fetch(`${apiDomain}/${endpoint}`, {
            ...config,
            method: "get",
          })
          resolve(response.json())
        } catch (e) {
          reject(e)
        }
      }, 3000)
    })
    return promise
  }
}

export const makePostRequestJson = async (endpoint: string, body: any, cancelationToken?: AbortController) => {
  try {
    const response = await fetch(`${apiDomain}/${endpoint}`, {
      ...config,
      method: "post",
      signal: cancelationToken?.signal || undefined,
      body: JSON.stringify(body),
    })
    return response.json()
  } catch (e) {
    return undefined
  }
}

export const makeDeleteRequest = async (endpoint: string) => {
  try {
    const response = await fetch(`${apiDomain}/${endpoint}`, {
      ...config,
      method: "delete",
    })
    return response.status === 200 || response.status === 204
  } catch (e) {
    return false
  }
}
