import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { AtomicNodeInfo } from "../../../../screens/document/tiptapEditor/utils/getAtomicNodeInfo"
import { navigateToCommandDefinition } from "../../commandDefinitions/navigateTo"
import { getUrlForDocument } from "../../../../routes"
import { DocumentSchema } from "@verdi/shared-constants"


export const getCmdsForDocReference = (
  currentAtomicNodeInfo?: AtomicNodeInfo | undefined
): VerdiCommandGroup[] => {

  if (currentAtomicNodeInfo?.nodeType !== DocumentSchema.VerdiNodeTypes.docReferenceInline) {
    return []
  }

  const commands: VerdiCommand[] = []
  const docId = currentAtomicNodeInfo.docReferenceAttrs?.docId
  if (docId) {
    const goToCmd = navigateToCommandDefinition.buildMenuItem?.({
      urlOfPage: getUrlForDocument(docId),
      nameOfPage: "note",
    })
    commands.push(goToCmd)
  }

  if (commands.length === 0) {
    return []
  }

  const group: VerdiCommandGroup = {
    title: "Link ...",
    hideTitle: true,
    commands,
  }

  return [group]

}
