import { KeyValuePair } from "@verdi/shared-constants"
import { ConceptFramerView } from "../../ai/docDrafter/concepts/ConceptFramerView"
import { getFrameActionPrompt } from "../../ai/prompts/assumptions/getFrameActionPrompt"
import { DeRiskingStepOption } from "../deRiskingSteps/DeRiskingStepOptions"
import { useEffect } from "react"
import { useConceptFramer } from "../../ai/docDrafter/concepts/useConceptFramer"


type Props = {
  stepToAdd: DeRiskingStepOption
  assumptions: string[]
  onContinueClick: (conceptsToUse: KeyValuePair[]) => void
  isContinuing: boolean
}
export const FrameANewAction = ({
  stepToAdd,
  assumptions,
  onContinueClick,
  isContinuing,
}: Props) => {


  const getPromptWithContext = async () => {
    console.log("generateAction: step = ", { stepToAdd })

    // generate a concept outline
    const prompt = getFrameActionPrompt(
      assumptions,
      `${stepToAdd.title} ${stepToAdd.description}}`,
    )
    return prompt
  }

  const conceptFramer = useConceptFramer({
    getPromptWithContext,
    isContinuing,
  })


  // Auto start the process when a step is selected
  useEffect(() => {
    if (stepToAdd) {
      conceptFramer.startTheProcess()
    }
  }, [stepToAdd])


  return (

    <ConceptFramerView
      conceptFramer={conceptFramer}
      onContinueClick={onContinueClick}
      isContinuing={isContinuing}
    />

  )
}
