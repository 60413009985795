import { useCallback } from "react"
import { useConceptGenerator } from "./concepts/useConceptGenerator"
import { KeyValuePair } from "@verdi/shared-constants"


export const useActionDrafter = () => {


  const {
    getQuestionsToAsk,
    getSuggestedAnswers,
    isGettingAnswers,
    isGettingQuestions,
  } = useConceptGenerator()


  const generateConcepts = useCallback(async (prompt: string) => {

    const answerSets = await getSuggestedAnswers(prompt)

    return answerSets as KeyValuePair[][]
  }, [getQuestionsToAsk, getSuggestedAnswers])


  return {
    generateConcepts,
    isGettingAnswers,
    isGettingQuestions,
  }

}

