import { ResolvedPos } from "@tiptap/pm/model";
import { ContentNodeWithPos, findParentNodeClosestToPos } from "prosemirror-utils";
import { getRangeForInsertingChildren } from "./getRangeForInsertingChildren";


/** Details about a given section */
export type SectionInfo = {
  title: string
  rawNode: ContentNodeWithPos;
  rangeToReplaceAllChildren: {
    from: number;
    to: number;
  }
  // Potentially add attributes here, like "sectionPrompt" or "placeholderText"
}


/** Gets details about the closest parent that is of node type "section", if it exists */
export const getSectionInfo = (resolvedPos: ResolvedPos) => {

  const parentSectionNode = findParentNodeClosestToPos(resolvedPos, (node) => node.type.name === "section")
  if (!parentSectionNode) {
    return undefined
  }

  const sectionTitleNode = parentSectionNode.node.content.childCount > 0 ? parentSectionNode.node.content.firstChild : undefined
  const title = sectionTitleNode ? sectionTitleNode.textContent : undefined
  const rangeToReplaceAllChildren = getRangeForInsertingChildren(parentSectionNode, "replaceAllChildren");


  return {
    title: title,
    rawNode: parentSectionNode,
    rangeToReplaceAllChildren,
  } as SectionInfo

}
