import { Node } from '@tiptap/core'

const CLASS_NAME = 'contentList'

export const ContentList = Node.create({
  name: CLASS_NAME,
  // the + means at least 1
  // it also means that if there are no children,
  // the wrapping element (contentList) will be removed
  // e.g. if the children are deleted or dragged away
  // https://discuss.prosemirror.net/t/auto-deletion-of-parent-container-upon-moving-children/3558
  content: 'nestableBlock+',
  selectable: false,
  defining: false,
  isolating: false,

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node: any) => typeof node !== 'string' && node.className.includes(CLASS_NAME) && null,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', {
      ...HTMLAttributes,
      class: CLASS_NAME
    }, 0]
  },
})