import { css } from '@emotion/react';
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { AiPromptList_document$key } from './__generated__/AiPromptList_document.graphql';



const fragmentQL = graphql`
  fragment AiPromptList_document on Document {
    aiPrompts {
      id
      promptText
      dontDo
      instructionsForUser
    }
  }
`

type Props = {
  document: AiPromptList_document$key;
}

export function AiPromptList({
  document: documentKey,
}: Props) {

  const document = useFragment(fragmentQL, documentKey)

  return (
    <div>
      <h6>Prompts</h6>
      <ul>
        {
          document.aiPrompts?.filter(p=> p.dontDo?.length).map((p, i) => {
            const description = (p.dontDo|| '').substring(0,11)
            return (
              <li
              css={css`
                cursor: pointer;
                &:hover{
                  opacity: 0.5;
                }
              `}
              key={p.id} >
              {description}
            </li>
            )})
        }
      </ul>
    </div>
  )
}