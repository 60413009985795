import { Mark, MarkConfig } from "@tiptap/core";
import { VerdiMarkType } from "./VerdiMarksCommon";


const KEY = VerdiMarkType.KnowledgeGraph;

const attributes = {
  link: {
    isRequired: true,
    default: null,
    keepOnSplit: true,
  },
};

export type type = {
  type: typeof KEY;
};

const config: MarkConfig = {
  name: KEY,
  addAttributes() {
    return attributes;
  },
  renderHTML({ HTMLAttributes: attrs }) {
    return [
      "a",
      {
        href: attrs.link,
      },
      0,
    ];
  },
};

export default Mark.create(config);
