import { PageLayoutSplitViewWrapper } from "../../../components/pageLayout/pageLayoutSplitViewWrapper";
import { useAppServices } from "../../../components/appServices/useAppServices";
import { PageHeading } from "../../../components/pageLayout/PageHeading";
import { SuspenseErrorBoundary } from "../../../components/SuspenseErrorBoundary";
import { SideMenu } from "../../opportunityDocument/SideMenu";
import { MainViewWrapper } from "../../../components/pageLayout/MainViewWrapper";
import { SectionWrapper } from "../../../components/pageLayout/SectionWrapper";
import { OrgPlanEditor } from "./OrgPlanEditor";
import { VerdiIconBilling } from "../../../components/icons/VerdiIcons";



export default function OrganizationPlansPage() {


  const appServices = useAppServices()
  const {
    pageLayoutState,
    loggedInUserProvider,
    menuStructureProvider,
    opportunityProvider,
  } = appServices

  const orgId = loggedInUserProvider.loggedInUser?.organization.id


  return (
    <PageLayoutSplitViewWrapper
      appServices={appServices}
      mainMenu={
        <SuspenseErrorBoundary>
          <SideMenu
            onBeforeNavigate={pageLayoutState.onBeforeNavigate}
            menuStructureProvider={menuStructureProvider}
            opportunityProvider={opportunityProvider}
          />
        </SuspenseErrorBoundary>
      }
      mainContent={<div>
        <MainViewWrapper>

          <PageHeading
            title="Billing"
            description=""
            iconLeft={<VerdiIconBilling />}
          />


          <SectionWrapper>

            {orgId &&
              <OrgPlanEditor
                orgId={orgId}
              />
            }


          </SectionWrapper>


        </MainViewWrapper>
      </div>
      } />
  );
}
