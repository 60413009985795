import { Alert, AlertTitle, Stack } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { Component, ErrorInfo, ReactNode } from "react";


interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  documentTitle?: string;
}

class DocumentErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    console.log(`DocumentErrorBoundary.getDerivedStateFromError(${error})`)

    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof CorruptedDocumentError) {

    }
    console.error("DocumentErrorBoundary.componentDidCatch(", error, errorInfo);
  }

  public render() {

    if (this.state.hasError) {

      const errorDetails = this.state.error?.message
      console.log("The error message = ", this.state.error)

      return (
        <Stack padding={4}>
          <Alert status='error'
            variant='solid'
            css={css`
            margin-top: 60px;
          `}>
            <div css={css`margin-right: 10px`}>😢 </div>
            <AlertTitle> This document is having a bad day!</AlertTitle>

            {/* <AlertDescription>Restore document to it's last stable state</AlertDescription> */}
          </Alert>
          {this.state.error instanceof CorruptedDocumentError &&
            <div></div>
          }

          <p>
            {errorDetails}
          </p>

        </Stack>
      )
    }

    return this.props.children
  }
}

export default DocumentErrorBoundary


export class CorruptedDocumentError extends Error {
  constructor(message?: string) {
    super(message);
  }
}