/**
 * @generated SignedSource<<956edf6d41d35891f76808645bec5eec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDocumentContextTemplate_document$data = {
  readonly aiPrompts: ReadonlyArray<{
    readonly isDocContext: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"AiPromptPreview_otherBlockLevelPrompts" | "useUpdateAiPromptMutation_documentAiPrompt">;
  }>;
  readonly id: string;
  readonly title: string | null;
  readonly " $fragmentType": "EditDocumentContextTemplate_document";
};
export type EditDocumentContextTemplate_document$key = {
  readonly " $data"?: EditDocumentContextTemplate_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDocumentContextTemplate_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDocumentContextTemplate_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentAiPrompt",
      "kind": "LinkedField",
      "name": "aiPrompts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDocContext",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useUpdateAiPromptMutation_documentAiPrompt"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AiPromptPreview_otherBlockLevelPrompts"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "892e57bf87a7044ef43e41cd968fe30e";

export default node;
