import { css } from '@emotion/react';
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { IconTitleButton } from '../../components/IconTitleButton';
import { DocumentOriginBanner_document$key } from './__generated__/DocumentOriginBanner_document.graphql';

const fragmentQL = graphql`
  fragment DocumentOriginBanner_document on Document {
     origin {
      id
      type
      title
     }
     parentDoc {
      id
      type
      title
     }
  }
`

type Props = {
  document: DocumentOriginBanner_document$key;
  leftPadding: number
}

export function DocumentOriginBanner({
  leftPadding,
  document: documentKey
}: Props) {

  const document = useFragment(fragmentQL, documentKey)

  if (document === null) {
    return null
  }
  const parent = document?.parentDoc || document?.origin
  if (parent === null || parent.type !== 'guide') {
    return null
  }

  return (
    <div css={css`
      padding: 7px;
      padding-bottom: 14px;
      padding-left: ${leftPadding}px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #aaa;
    `}>
      <IconTitleButton
        title={parent.title || ''}
        color={'gray'}
        iconSize={10}
        css={css`
          color: #999;
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
      `}
      />
    </div>
  )
}
