import { css } from '@emotion/react';
import { Alert, AlertIcon, useColorModeValue } from '@chakra-ui/react';
import {
  ReactFlow,
  FitViewOptions,
  DefaultEdgeOptions,
  Controls,
  Background,
  Panel,
  ConnectionLineType,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { MindMapState } from '../../state/MindMapSlice';
import { dispatch } from '../../state/store';
import LoadingSpinner from '../LoadingSpinner';
import { nodeTypes } from './internals/nodeTypes/NodeTypes';
import { VerdiIconGraphHorizontal, VerdiIconGraphVertical } from '../icons/VerdiIcons';
import { IconButtonTertiary } from '../buttons/IconButtonTertiary';
import { useMindMapContext } from './internals/context/useMindMapContext';


const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: false,
  type: ConnectionLineType.SimpleBezier,
};


/** UI Graph that helps the user think through ideas and opportunities 
 * 
 * Must be wrapped in two components: `<ReactFlowProvider><MindMapContextWrapper> <MindMapGraph /> </></>` 
*/
export const MindMapGraph = () => {

  const {
    nodes,
    edges,
    isLoading,
    messageToUser,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onBeforeDelete,
    hasInitialized,
  } = useMindMapContext()

  const colorMode = useColorModeValue("light", "dark");


  return (
    <div css={css`
      width: 100%;
      height: calc(100vh - 210px);
    `}>
      {!hasInitialized &&
        <LoadingSpinner showAfterMsDelay={300} />
      }


      <ReactFlow
        nodes={nodes}
        edges={edges}
        // onInit={onInit}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onBeforeDelete={onBeforeDelete}
        nodesConnectable={false}
        fitView
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        nodeTypes={nodeTypes}
        colorMode={colorMode}
        proOptions={{ hideAttribution: true }}
        css={css`
          opacity: ${hasInitialized ? 1 : 0};
        `}
        minZoom={0.5} // zoom out to see a lot of nodes. 
        maxZoom={4} // how close can we get to a specific node
      >

        <Controls />
        {/* <MiniMap /> */}
        <Background
          gap={12}
          size={1}
          color='#99999966'
        />

        <Panel position="bottom-right">
          <Alert status="warning"
            variant="subtle"
            size="xs">
            <AlertIcon />
            This feature is in development.
          </Alert>
        </Panel>

        <Panel position="top-right"
          css={css`
          display: flex;
          flex-direction: column;
         `}>
          <IconButtonTertiary
            aria-label='Vertical layout'
            size='xs'
            icon={<VerdiIconGraphVertical />}
            onClick={() => dispatch(MindMapState.setDirection("TB"))}
            css={css`
              border: 1px solid #99999944;
              border-radius: 0;
              border-bottom: 0;
            `}
          />
          <IconButtonTertiary
            aria-label='Horizontal layout'
            size='xs'
            icon={<VerdiIconGraphHorizontal />}
            onClick={() => dispatch(MindMapState.setDirection("LR"))}
            css={css`
              border: 1px solid #99999944;
              border-radius: 0;
            `}
          />
        </Panel>

        <Panel position="bottom-center">
          {isLoading && <LoadingSpinner />}
          {messageToUser}
        </Panel>

      </ReactFlow>


    </div>
  );
}
