import { useMemo } from "react";
import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { WhatsNextSuggester } from "../../../../screens/document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/useWhatsNextSuggester";
import { showMoreOptionsCommandDefinition } from "../../commandDefinitions/utils/showMoreOptions";
import { loadingCommandPlaceholderCommandDefinition } from "../../commandDefinitions/utils/loadingCommandPlaceholder";
import { getSuggestionsForWhatIsNextCommandDefinition } from "../../commandDefinitions/document/getSuggestionsForWhatIsNext";


type Props = {
  whatsNextSuggester: WhatsNextSuggester
  experimentalEnabled: boolean
}

export const useNextStepsCommands = ({
  whatsNextSuggester,
  experimentalEnabled,
}: Props) => {

  /** The Top Commands that the AI Suggests */
  const nestStepsCommandGroup: VerdiCommandGroup = useMemo(() => {

    const commandsToShow: VerdiCommand[] = []

    if (whatsNextSuggester.isGeneratingDocLevelSuggestions) {
      commandsToShow.push(loadingCommandPlaceholderCommandDefinition.buildMenuItem({
        loadingMessage: "... Loading Suggestions",
      }))

    } else if (whatsNextSuggester.docCommandSuggestions.length > 0) {

      const amountToShowForWhatsNext = 3
      const cmds = whatsNextSuggester.docCommandSuggestions.length > amountToShowForWhatsNext
        ? whatsNextSuggester.docCommandSuggestions.slice(0, amountToShowForWhatsNext)
        : whatsNextSuggester.docCommandSuggestions
      for (const cmd of cmds) {
        commandsToShow.push(cmd)
      }

      commandsToShow.push(showMoreOptionsCommandDefinition.buildMenuItem({
        commands: whatsNextSuggester.docCommandSuggestions
      }))

      if (experimentalEnabled) {
        commandsToShow.push(getSuggestionsForWhatIsNextCommandDefinition.buildMenuItem({
          lifecycleState: "refresh",
        }))
      }

      // IDEA: Show option to show more commands. On click either launch modal or add to the list?
    } else {

      if (experimentalEnabled) {
        commandsToShow.push(getSuggestionsForWhatIsNextCommandDefinition.buildMenuItem({
          lifecycleState: "load-new",
        }))
      }

    }

    const group: VerdiCommandGroup = {
      title: "Suggested next steps",
      commands: commandsToShow,
    }
    return group
  }, [whatsNextSuggester.docCommandSuggestions, whatsNextSuggester.isGeneratingDocLevelSuggestions]);


  return {
    nestStepsCommandGroup,
  }
}
