import { v4 } from "uuid";


export const generateNodeId = () => {
  return v4()
}

export const generateEdgeId = (fromId: string, toId: string) => {
  return `${fromId}-${toId}`
}
