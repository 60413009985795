
export type OpenAiModelType =
  'gpt-3.5-turbo' |
  'gpt-4o'

export const Gpt_4_Latest = 'gpt-4o'
export const Gpt_3_Latest = 'gpt-3.5-turbo'

/** Warning changing this value may disrupt A TON of AI calls.  */
export const OpenAiModelTypeDefault: OpenAiModelType = "gpt-3.5-turbo" // Hey, be careful out there.
/** Warning changing this value may disrupt A TON of AI calls. ^^  */


/** Whatever the AI returns is also counted towards the token limit.
 *  Roughly 1 token is 4 characters.
 *  Leave 75% of the limit to the response, so times by 3, not 4. 
 *  https://platform.openai.com/docs/models/
 * */

const maxCharLengthForGpt3 = 49000 /** 16385 tokens * 3 = 49,155 characters */
const maxCharLengthForGpt4 = 380000 /** 128,000 tokens * 3 = 384,000 characters */

export const maxLengthOfPromptLogColumn = 65000 // AiGenerationLogEntry.prompt column's max length (MySql TEXT)



/** If needed, will adjust the model based on the size of the prompt + messages + any other OpenAI params */
export const adjustModelBasedOnLength = (model: OpenAiModelType, prompt: string) => {

  if (model === Gpt_3_Latest && prompt.length > maxCharLengthForGpt3) {
    console.log(`adjusted model based on size of prompt (${prompt.length} chars), from ${model} to ${Gpt_4_Latest}`)
    return Gpt_4_Latest
  }

  if (model === Gpt_4_Latest && prompt.length > maxCharLengthForGpt4) {
    console.warn(`content may be too large for ${Gpt_4_Latest} model. Trying it anyways. (${prompt.length} chars)`)
  }

  return model
}

