import { makePostRequestJson } from "../../utility-hooks/fetchUtils"


/** Scrapes a given URL and returns an object of dynamic properties and values */
export const scrapeDataFromUrl = async (urlToScrape: string) => {

  const rawResults = await makePostRequestJson("scraper/ScrapeUrl", { url: urlToScrape })
  let toReturn: any = {}
  const lines = rawResults.split("\n")
  for (const line of lines) {
    const [key, value] = line.split(":")
    if (key && value) {
      toReturn[key] = value.trim()
    }
  }
  return toReturn
}
