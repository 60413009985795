import { useEffect, useState } from 'react';
import { SideMenuSection, startingMenuDepth } from './SideMenuSection';
import { useNewQuickNoteCreator } from '../documents/add/useNewQuickNoteCreator';
import { DocumentSchema, Menu } from '@verdi/shared-constants';


type Props = {
  onBeforeNavigate: () => void
  menuItems: Menu.MenuItemData[]
}
export const SideMenuSectionForRecentDocuments = ({
  onBeforeNavigate,
  menuItems,
}: Props) => {

  const [topLevelItem, setTopLevelItem] = useState<Menu.MenuItemData>()
  
  useEffect(() => {

    const rootItem: Menu.MenuItemData = {
      children: menuItems,
      id: "recentDocuments",
      title: "Recently Viewed",
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: "",
      createdAt: new Date(),
    }

    setTopLevelItem(rootItem)
  }, [menuItems])

  if (!topLevelItem) return null
  if(menuItems.length === 0) return null
  return (

    <SideMenuSection
      menuItem={topLevelItem}
      menuDepth={startingMenuDepth}
      folderType="recentDocs"
      onBeforeNavigate={onBeforeNavigate}
      onAddButtonClick={() => {}}
      onAddOpportunityButtonClick={() => {}}
      isExpandedInitially={false}
      addingInProgress={false}
      activeMenuIds={[""]}
    />

  )
}
