import { Mark, MarkConfig } from "@tiptap/core";
import { VerdiMarkType } from "./VerdiMarksCommon";
import { DocumentSchema } from "../..";


/** Shows that text has been edited by AI
 * 
 *  Use this as a replacement for the attribution mark
 */

const KEY = VerdiMarkType.AiEdited;

export type AiEditedMarkAttributes = {

}
const attributes: AiEditedMarkAttributes = {

};

export type type = {
  type: typeof KEY;
};

const config: MarkConfig = {
  name: KEY,
  addAttributes() {
    return attributes;
  },
  renderHTML(props) {
    const element = document.createElement("span");
    element.className = "aiEdited";

    element.addEventListener("click", (event: MouseEvent) => {
      if (!event.target || !window) return

      const newEvent = new CustomEvent(DocumentSchema.VerdiMarks.MARK_CLICKED_EVENT_KEY, {
        detail: {
          rect: (event.target as HTMLElement).getBoundingClientRect(),
          attributes: props.mark.attrs,
          markType: KEY,
        }
      })

      window.dispatchEvent(newEvent)
    })

    return element;
  },

};

export const AiEditedMark = Mark.create(config);
