import { css } from "@emotion/react"
import { Menu } from "@verdi/shared-constants"
import { SubMenuSectionItem } from "./SubMenuSectionItem"
import { IconButtonTertiary } from "../../../components/buttons/IconButtonTertiary"
import { useRef } from "react"
import { VerdiIconAdd } from "../../../components/icons/VerdiIcons"
import { SubSectionWrapper } from "./SubSectionWrapper"


type Props = {
  sectionTitle: string
  children: Menu.MenuItemData[]
  activeMenuIds: string[]
  onMenuItemClick: (menuItem: Menu.MenuItemData) => void
  onAddButtonClick: (menuItem?: Menu.MenuItemData, buttonRef?: React.RefObject<HTMLDivElement>) => void
  hideChildrenAdd?: boolean
  initialAccordionState: "expanded" | "collapsed"
}
export const SubMenuSection = ({
  sectionTitle,
  children,
  activeMenuIds,
  onAddButtonClick,
  onMenuItemClick,
  hideChildrenAdd,
  initialAccordionState,
}: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null)

  const renderChildren = () => {
    return (<div css={css`
        min-height: 20px;
        margin-left: -8px;
      `}>
      {children.map(menuItem => (
        <div key={menuItem.id}>
          <SubMenuSectionItem
            activeMenuIds={activeMenuIds}
            menuItem={menuItem}
            menuDepth={1}
            onAddButtonClick={hideChildrenAdd ? undefined : onAddButtonClick}
            onMenuItemClick={onMenuItemClick}
          />
        </div>
      ))}
    </div>)
  }


  if (!sectionTitle) {
    return renderChildren()
  }


  return (
    <SubSectionWrapper
      title={sectionTitle}
      initialAccordionState={initialAccordionState}
      titleButton={
        <div ref={buttonRef}>
          <IconButtonTertiary
            aria-label="Add"
            icon={<VerdiIconAdd />}
            opacity={0.5}
            onClick={() => onAddButtonClick(undefined, buttonRef)}
          />
        </div>
      }>

      {renderChildren()}

    </SubSectionWrapper>

  )
}
