import { css } from "@emotion/react"
import { useColor } from "../../utility-hooks/useColor"
import { mediaQueryExtraSmallWidth } from "../../components/pageLayout/styleHelpers"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { VerdiIconShowMore } from "../../components/icons/VerdiIcons"


type Props = {
  title: string
  answer?: string
  isSelected: boolean
  onClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onSettingsClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  riskColor: string
  leftSideChildren: React.ReactNode
  // mode: "addSuggestion" | "edit"
}
/** Used by both editing and adding */
export const AssumptionsListItemView = ({
  title,
  answer,
  isSelected,
  onClick,
  onSettingsClick,
  riskColor,
  leftSideChildren,
}: Props) => {

  const selectBg = useColor("itemSelectedBg")
  const hoverBg = useColor("surfaceOverlay")


  return (
    <div>
      <div
        css={css`
          border-top: 1px solid #99999933;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          background-color: ${isSelected ? selectBg : 'transparent'};
          :hover {
            background-color: ${hoverBg};
          }
        `}
        onClick={onClick}
      // onContextMenu={onSettingsClick}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            line-height: 1.3;
            border-left: 4px solid ${riskColor};
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @container mainStage ${mediaQueryExtraSmallWidth} { 
              text-overflow: auto;
              white-space: normal;
              justify-content: start;
              align-items: start;
            }
        `}>

          {leftSideChildren}


          {/* TODO: formating for unanswered questions etc...  */}

          <div>

            <div
              title={title + " " + answer}
              css={css`
              min-width: 100px;
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              @container mainStage ${mediaQueryExtraSmallWidth} { 
                margin: 9px 0;
                white-space: normal;
            }
          `}>

              {title}

              {answer ?
                <div css={css`
                  font-size: 12px;
                  opacity: 0.5;
                  line-height: 1;
                `}>
                  {answer}
                </div> : null
              }

            </div>



          </div>

        </div>


        {onSettingsClick &&
          <div>
            <IconButtonTertiary
              aria-label={"options"}
              icon={<VerdiIconShowMore />}
              onClick={(evt) => {
                evt.preventDefault()
                evt.stopPropagation()
                onSettingsClick?.(evt as any)
              }}
            />
          </div>
        }

      </div>
    </div>
  )
}

