import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { dispatch } from "../../../../state/store"
import { loadingStatusState } from "../../../../state/loadingStatusSlice"
import { writeToDocBodyCommandDefinition } from "./writeToDocBody"
import { CustomPromptAiCallOptions, makeCustomPromptOnDocAiCall } from "../../../../ai/prompts/docDrafter/CustomPromptOnDocAiCall"
import { writeToDocBodyAsTextCommandDefinition } from "./writeToDocBodyAsText"
import { CurrentBlockContext } from "../../../../screens/document/tiptapEditor/utils/getCurrentBlockContext"


const cmdType = GlobalCommandType.runAIPromptOnDoc

export const runAIPromptOnDocCommandDefinition: GlobalCommandDefinition<RunAIPromptOnDocCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: RunAIPromptOnDocCommandArgs) => {

    const name = args.commandTitle || "Ask AI"
    const toReturn =
      {
        globalCommandType: cmdType,
        name,
        searchName: name.toLowerCase(),
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        iconType: "aiSuggestion",
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: RunAIPromptOnDocCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (!currentBlockContext) {
      services.toast.showError("Could not run prompt. Try again later.")
      console.warn("runAIPromptOnDoc: Could not find current block context")
      args.onComplete?.(false)
      onProcessingComplete(false)
      return
    }

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Applying to doc ...",
    }))

    const {
      prompt,
      shouldDeleteCurrentSelection,
      promptOptions,
    } = args

    // make AI Call
    // TODO: Pass in maxNumLines here and any other options so that the AI returns correctly
    const documentOutline = await makeCustomPromptOnDocAiCall({
      getContextForAi: services.getContextForAi,
      rawPrompt: prompt,
      promptOptions,
    })
    console.log("runAIPromptOnDoc ", { documentOutline })

    if (documentOutline.length > 0) {

      let insertPosition = currentBlockContext.currentMark
        ? InsertPosition.replaceMark
        : InsertPosition.currentCursorSelection

      const hasMoreThanOneLine = documentOutline.length > 1
        || (documentOutline[0].children?.length && documentOutline[0].children.length > 1)

      if (hasMoreThanOneLine) {

        // Prevent accidentally removing the current line if it has text other than the placeholder
        const canReplaceCurrentLine = currentLineIsEmptyOrOnlyHasAPlaceholder(currentBlockContext)
        // console.log("currentLineHasText", { canReplaceCurrentLine })
        insertPosition = canReplaceCurrentLine
          ? InsertPosition.AsSiblingsOrInEmptyLine
          : InsertPosition.AsNextRootNode


        // More than one node, so write out the nodes
        writeToDocBodyCommandDefinition.triggerCommand?.({
          documentOutline,
          insertPosition,
          shouldDeleteCurrentSelection,
        })

      } else {

        // Just write out the raw text, without creating prose-mirror nodes
        writeToDocBodyAsTextCommandDefinition.triggerCommand?.({
          textToWrite: documentOutline[0].content,
          title: "",
          insertPosition,
        })
      }

    } else {
      services.toast.showWarn("Ooops, something went wrong")
    }


    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
      message: "",
    }))



    /** OLD Streaming way (not preferred) */


    // let fullPromptText = args.prompt
    // let insertAt = InsertPosition.endOfDocument
    // let includeDocDetails = true
    // let includeCompanyContext = true


    // if (docContextForAI) {
    //   insertAt = docContextForAI.selectedText
    //     ? InsertPosition.currentCursorSelection
    //     : InsertPosition.endOfCurrentNode

    //   if (insertAt === InsertPosition.currentCursorSelection) {
    //     fullPromptText += `\n\nSelected text to focus on: ${docContextForAI.selectedText}`
    //     includeDocDetails = false
    //     includeCompanyContext = false
    //   }
    // }

    // // TODO: Consider no longer streaming here?
    // await services.servicesForCurrentDoc?.aiCoach.requestDocumentEdit(
    //   {
    //     prompt: fullPromptText,
    //     insertAt,
    //     includeCompanyContext,
    //     includeDocDetails,
    //     includeDocMetadata: false,
    //     includeRelatedContext: false,
    //     isInline: true,
    //     textToAddBefore: " ",
    //   }
    // )

    services.toast.showSuccess("Done")

    args.onComplete?.(true)
    onProcessingComplete(true)
  },
  triggerCommand: (args: RunAIPromptOnDocCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type RunAIPromptOnDocCommandArgs = {
  prompt: string
  commandTitle?: string
  maxNumLines?: number
  shouldDeleteCurrentSelection?: boolean
  promptOptions: CustomPromptAiCallOptions
  onComplete?: (wasSuccessful: boolean) => void
}


const currentLineIsEmptyOrOnlyHasAPlaceholder = (currentBlockContext: CurrentBlockContext) => {

  const currentLineText = currentBlockContext.currentLineText?.trim()
  // console.log("CURRENT LINE: ", { currentLineText })
  if (!currentLineText) return true
  // [[ placeholder ]]
  const currentPlaceholderText = currentBlockContext.currentPlaceholderAttrs?.text.trim()
  // console.log("CURRENT LINE: ", { currentPlaceholderText })

  if (!currentPlaceholderText) return false
  const currentLineNoBrackets = currentLineText.replaceAll("[[", "").replaceAll("]]", "").trim()
  // console.log("CURRENT LINE: ", { currentLineNoBrackets })

  return currentLineNoBrackets === currentPlaceholderText.trim()
}
