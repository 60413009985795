import { makeAiChatRequest } from "../requests/AiApiRequests"
import { AiContextConfig, AllContextForAi } from "../promptUtils/useAllContextForAi"
import { cleanupAiOutputAsStrings } from "../utils/cleanupAiOutput"
import { ApiTypes, Data, DocumentSchema } from "@verdi/shared-constants"
import { FrameworksState } from "../../state/frameworksSlice"
import { getCurrentAppState } from "../../state/store"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"


export const makeFrameworkSuggestionsForDocCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
) => {

  const allContext = await getContextForAi()
  const currentDoc = allContext.currentDoc
  if (!currentDoc) {
    console.warn("makeFrameworkSuggestionsForDocCall: No currentDoc context found")
    return []
  }
  if (!currentDoc.title && !currentDoc.description && currentDoc.body.length < 10) {
    console.warn("makeFrameworkSuggestionsForDocCall: Not enough content to suggest frameworks")
    return []
  }

  const isOpportunity = currentDoc.type === DocumentSchema.DocumentType.opportunity
  const frameworks = FrameworksState.getAllFrameworksByKind(getCurrentAppState(), isOpportunity)

  const frameworksForAi: FrameworkChoiceForAi[] = frameworks.map((f, i) => {
    return {
      tempId: i + 1,
      model: f,
    } as FrameworkChoiceForAi
  })

  const prompt = buildPrompt(currentDoc, frameworksForAi)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    openAIParams: {
      n: 2,
    }
  })

  const responseJson = response?.aiResponseJson as ApiTypes.AiResponseJson
  const rawText = responseJson.content as string || responseJson.contentOptions?.[0] as string || ""
  const tempIds = cleanupAiOutputAsStrings(rawText).map(s => parseInt(s)).filter(s => !isNaN(s))
  const suggestedFrameworks = frameworksForAi
    .filter(f => tempIds.includes(f.tempId))
    .map(f => f.model)

  // console.log("makeFrameworkSuggestionsForDocCall:", { prompt, responseJson, tempIds, suggestedFrameworks })

  return suggestedFrameworks
}

type FrameworkChoiceForAi = {
  tempId: number
  model: Data.FrameworkModel
}


const buildPrompt = (
  currentDoc: DocumentContextForAi,
  frameworkChoices: FrameworkChoiceForAi[],
) => {

  const frameworksList = frameworkChoices.map(c => `${c.tempId}\t${c.model.title}\t${c.model.whenToUse}`).join("\n")

  return `
###
FRAMEWORKS: START:
${frameworksList}
FRAMEWORKS: END:

DOCUMENT TITLE: ${currentDoc.title}
DOCUMENT DESCRIPTION: ${currentDoc.description}

###

Which of these FRAMEWORKS would be most applicable to this DOCUMENT?

Return 3-5 suggestions, separated by new lines.
Return only the numbers from the list that correspond to the frameworks you suggest.

`;
}

