import { Data } from "@verdi/shared-constants";


export const groupByConfidenceLevel = (assumptions: Data.AssumptionModel[]): AssumptionGroup[] => {

  const toReturn: AssumptionGroup[] = [
    {
      label: Data.ConfidenceLevelLabelMap[Data.AssumptionConfidenceLevel.unknown],
      assumptions: assumptions.filter(a => a.confidenceLevel === 1)
    },
    {
      label: Data.ConfidenceLevelLabelMap[Data.AssumptionConfidenceLevel.hypothesis],
      assumptions: assumptions.filter(a => a.confidenceLevel === 2)
    },
    {
      label: Data.ConfidenceLevelLabelMap[Data.AssumptionConfidenceLevel.anecdotal],
      assumptions: assumptions.filter(a => a.confidenceLevel === 3)
    },
    {
      label: Data.ConfidenceLevelLabelMap[Data.AssumptionConfidenceLevel.directional],
      assumptions: assumptions.filter(a => a.confidenceLevel === 4)
    },
    {
      label: Data.ConfidenceLevelLabelMap[Data.AssumptionConfidenceLevel.factual],
      assumptions: assumptions.filter(a => a.confidenceLevel === 5)
    }
  ]

  return toReturn
}

export type AssumptionGroup = {
  label: string,
  assumptions: Data.AssumptionModel[]
}
