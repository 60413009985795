import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure
} from "@chakra-ui/react"
import { useRef } from "react"
import { VerdiIconDelete } from "../../../components/icons/VerdiIcons"


/** If we should ever remove the actual AI prompt in the database:
 *  Things to consider:
 *  + Removing AI prompts in the database would break existing documents 
 *    out there that were copied from this template
 *  + How can the user "Undo" the deletion of the AI prompt?
 */


type Props = {
  aiPromptId: string
  onDeleteConfirmed: () => void
}

/** Confirms to the user that they want to DELETE the given Document AI Prompt */
export const DeleteAiPromptButton = ({ aiPromptId, onDeleteConfirmed }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  return (
    <>
      <IconButton
        aria-label='remove'
        icon={<VerdiIconDelete />}
        onClick={() => {
          onOpen()
        }}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete AI Prompt
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef as any} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={onDeleteConfirmed} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
