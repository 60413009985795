import { useCallback, useEffect, useState } from "react"
import { useUpdateUser } from "../../../../components/appServices/users/useUpdateUser"
import { Data } from "@verdi/shared-constants"
import { LoggedInUserInfo } from "../../../../components/appServices/users/useLoggedInUserProvider"
import { css } from "@emotion/react"
import { OnboardingManager } from "../../../onboarding/useOnboardingManager"
import { FormInputText } from "../../../../components/forms/FormInputText"


type Props = {
  currentUser: LoggedInUserInfo
  onboardingManager: OnboardingManager
}
export const CurrentUserEditor = ({
  currentUser,
  onboardingManager,
}: Props) => {


  const currentUserId = currentUser?.id
  const [name, setName] = useState(currentUser.name ?? '')
  const [roleTitle, setRoleTitle] = useState(currentUser.roleTitle ?? '')
  const [responsibleFor, setResponsibleFor] = useState(currentUser.responsibleFor ?? '')


  const [updateUser] = useUpdateUser()
  const updateField = useCallback((field: keyof Data.User.UserMutableFields, newValue: string) => {
    updateUser({
      id: currentUserId,
      [field]: newValue,
    })
  }, [currentUserId, updateUser])
  const updateName = useCallback((newValue: string) => {
    setName(newValue)
    updateField('name', newValue)
  }, [updateField])
  const updateRoleTitle = useCallback((newValue: string) => {
    setRoleTitle(newValue)
    updateField('roleTitle', newValue)
  }, [updateField])
  const updateResponsibleFor = useCallback((newValue: string) => {
    setResponsibleFor(newValue)
    updateField('responsibleFor', newValue)
  }, [updateField])


  useEffect(() => {
    onboardingManager.updateProgress("userProfilePageAt")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <div css={css`
      display: flex;
      flex-direction: column;
    `}>

      <FormInputText
        label={"Name"}
        value={name}
        onChange={updateName}
        placeholder="first and last"
      />

      <FormInputText
        label={"Role"}
        value={roleTitle}
        onChange={updateRoleTitle}
        placeholder="e.g. Senior Product Manager, Software Engineer, etc."
      />

      <FormInputText
        label={"Focus"}
        value={responsibleFor}
        onChange={updateResponsibleFor}
        placeholder="which experiences, projects, or outcomes are you focused on?"
      />


      {/* <div css={css`
        margin-top: -10px;
      `}>
        <ButtonPrimary
          label="Continue"
          onClick={onContinueOnboardingClicked}
          disabled={!(isOnboarding && roleTitle.length > 0 && responsibleFor.length > 0)}
        />
      </div> */}

    </div>
  )
}
