import History from "@tiptap/extension-history";
import { ConfiguredPlaceholder } from "./placeholder/placeholder";
import { PressTab } from "./events/PressTab";
import { NestingLimit } from "./NestingLimit";
import { PressEnter } from "./events/PressEnter";
import { PressBackspace } from "./events/PressBackspace";
import Focus from "@tiptap/extension-focus";
import Convert from "./Convert";
import "./extensionsCss";
import { DocumentSchema } from "@verdi/shared-constants";
import { clearEditMarksExtension } from "./clearAIEditMarksPlugin";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { InlineSnippetComponent } from "./reactComponents/InlineSnippetComponent";
import { keepSelectionOnBlurExtension } from "./keepSelectionOnBlurPlugin";
import commands from "./commands";
import { DocReferenceInlineComponent } from "./reactComponents/docReference/DocReferenceInlineComponent";
import { TaskCheckboxComponent } from "./reactComponents/TaskCheckboxComponent";
import { PlaceholderInlineComponent } from "./reactComponents/PlaceholderInlineComponent";

const congiguredFocus = Focus.configure({
  className: "focus",
  mode: "deepest",
});

/** Put all extensions for the Web front-end facing Prose-mirror editor here */
export const extensionsForEditor = () => [
  ...requiredExtensionsForEditor(),
  // The following have NO commands or schema extensions
  ConfiguredPlaceholder,
  keepSelectionOnBlurExtension,
  congiguredFocus,

];

/** Any extensions that add to the schema or commands need to be added here */
export const requiredExtensionsForEditor = () => [
  PressTab,
  PressEnter,
  PressBackspace,
  History,
  Convert,
  NestingLimit,
  ...DocumentSchema.schemaExtensions({
    InlineSnippet: {
      addNodeView: () => ReactNodeViewRenderer(InlineSnippetComponent),
    },
    // docReference: {
    //   addNodeView: () => ReactNodeViewRenderer(DocReferenceComponent),
    // },
    docReferenceInline: {
      addNodeView: () => ReactNodeViewRenderer(DocReferenceInlineComponent),
    },
    placeholderInline: {
      addNodeView: () => ReactNodeViewRenderer(PlaceholderInlineComponent),
    },
    taskCheckbox: {
      addNodeView: () => ReactNodeViewRenderer(TaskCheckboxComponent),
    },

  }),
  clearEditMarksExtension,
  ...commands,
];
