import { css } from "@emotion/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { useColor } from "../../utility-hooks/useColor"


type Props = {
  title: string
  description: string
  onClick: () => void
}
export const HomePageQuickAction = ({
  title,
  description,
  onClick,
}: Props) => {

  const borderColor = useColor("surfaceBorder")

  return (
    <div css={css`
      border: 1px solid ${borderColor};
      margin: 0 20px 20px 0;
      width:200px;
      padding: 20px;
    `}>
      <ButtonPrimary
        label={title}
        onClick={onClick}
      />
      <p css={css`
        font-size: 0.9em;
        margin-top: 5px;
      `}>
        {description}
      </p>

    </div>)
}
