import { Switch } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useAppSelector } from "../../state/storeHooks"
import { dispatch } from "../../state/store"
import { AutoAiModeState } from "../../state/AutoAiModeSlice"
import { DefinitionTipForUser } from "../DefinitionTipForUser"
import { DocMetadataState } from "../../state/docMetadataSlice"
import { ButtonSecondary } from "../buttons/ButtonSecondary"
import { VerdiIconArrowBack } from "../icons/VerdiIcons"
import { doNextAutoAiStepCommandDefinition } from "../commands/commandDefinitions/autoAi/doNextAutoAiStep"
import LoadingSpinner from "../LoadingSpinner"


export const AutoAiModeToggle = () => {


  const isInAutoAiMode = useAppSelector(AutoAiModeState.getIsInAutoAiMode)
  const isWorking = useAppSelector(AutoAiModeState.getIsDoingSomething)
  const messageToUser = useAppSelector(AutoAiModeState.getMessageToUser)
  const docSummary = useAppSelector(DocMetadataState.getSummary)

  const fillInPlaceholders = () => {
    doNextAutoAiStepCommandDefinition.triggerCommand?.({
      step: "fillInPlaceholders",
    })
  }


  return (
    <div css={css`
      margin: 4px 0 0 8px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      border: 1px solid #99999922;
      border-radius: 8px;
    `}>
      <div css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      
    `}>
        <Switch
          isChecked={isInAutoAiMode}
          onChange={() => dispatch(AutoAiModeState.setIsInAutoAiMode(!isInAutoAiMode))}
          css={css`
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
        >
          Auto Draft
        </Switch>
        <DefinitionTipForUser
          word="Auto AI"
          definition={"When enabled, the AI can proactively make edits for you."}
          triggerDisplayMode="showHelpIcon"
        />
      </div>

      {isInAutoAiMode &&
        <div css={css`
          padding: 8px 0;
        `}>

          <div css={css`
            display: flex;
            flex-direction: row;
          `}>
            {isWorking &&
              <LoadingSpinner
                size="xs"
              />
            }

            {messageToUser &&
              <div css={css`
                font-size: var(--chakra-fontSizes-xs);
                font-style: italic;
                opacity: 0.8;
                margin: 0 0 4px 4px;
              `}>
                {messageToUser}
              </div>
            }
          </div>

          {!isWorking &&
            <div>
              {Boolean(docSummary?.placeholders.length) &&
                <ButtonSecondary
                  size="xs"
                  leftIcon={<VerdiIconArrowBack />}
                  label={"Continue"}
                  onClick={fillInPlaceholders}
                />
              }
            </div>
          }
        </div>
      }
    </div>
  )
}
