import { Data } from "@verdi/shared-constants"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { kanbanColumnOptionsForOpportunities } from "../../../kanban/KanbanColumnOptions"


const cmdType = GlobalCommandType.updateOpportunity

/** Updates properties of an Opportunity model */
export const updateOpportunityCommandDefinition: GlobalCommandDefinition<UpdateOpportunityCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: UpdateOpportunityCommandArgs) => {

    const { commandName, } = args

    const toReturn =
      {
        globalCommandType: cmdType,
        name: commandName,
        runCommand: () => triggerGlobalCommand(cmdType, args),
        searchName: commandName.toLocaleLowerCase(),

      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: UpdateOpportunityCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { opportunityProvider } = services
    if (!opportunityProvider) {
      console.error("updateOpportunityCommandDefinition: opportunityProvider not found")
      onProcessingComplete(false)
      return
    }

    const { opportunityId, newStatusText, fieldsToUpdate } = args
    let fields: Partial<Data.Opportunities.OpportunityMutableFields> = { ...fieldsToUpdate }

    // Handle opportunity.status updates
    if (newStatusText) {
      const newStatusValue = kanbanColumnOptionsForOpportunities.find((col) => col.title === newStatusText)?.id
      if (!newStatusValue && newStatusValue !== 0) {
        console.error("newStatusValue not found ", { newStatusText, newStatusValue })
        onProcessingComplete(false)
        return
      }
      fields.status = newStatusValue
    }

    // Run the update
    await opportunityProvider.updateOpportunity(opportunityId, {
      lastActivityAt: new Date().toISOString(),
      ...fields,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: UpdateOpportunityCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}


export type UpdateOpportunityCommandArgs = {
  commandName: string
  opportunityId: string
  newStatusText: string
  fieldsToUpdate: Partial<Data.Opportunities.OpportunityMutableFields>
}
