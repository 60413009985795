import { useCallback, useState } from "react"
import { ApiTypes, ChatRoleType, KeyValuePair } from "@verdi/shared-constants"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsKeyValuePairs } from "../../utils/cleanupAiOutput"


export const ConceptFieldDelimiter = "|"

/** Generates a list of concepts for a document */
export const useConceptGenerator = () => {

  const [isGettingQuestions, setIsGettingQuestions] = useState(false)

  const getQuestionsToAsk = useCallback(async (prompt: string) => {
    setIsGettingQuestions(true)

    const message = { role: ChatRoleType.system, content: prompt }
    const results = await makeAiChatRequest({
      messages: [message],
      openAIParams: {
        temperature: 0.9,
      },
    })

    console.log("results", results)
    if (!results) {
      return ""
    }
    const resultsJson = results.aiResponseJson as ApiTypes.AiResponseJson
    setIsGettingQuestions(false)
    return (resultsJson as any).content as string

  }, [setIsGettingQuestions])


  const [isGettingAnswers, setIsGettingAnswers] = useState(false)
  const getSuggestedAnswers = useCallback(async (prompt: string) => {

    setIsGettingAnswers(true)
    const message = { role: ChatRoleType.system, content: prompt }
    const results = await makeAiChatRequest({
      messages: [message],
      openAIParams: {
        temperature: 0.9,
        n: 3,
      },
    })

    if (!results) {
      return []
    }
    const resultsJson = results.aiResponseJson as ApiTypes.AiResponseJson
    const rawSetsOfAnswers = resultsJson.contentOptions || []

    const answerSets: KeyValuePair[][] =
      rawSetsOfAnswers.map((rawSet) => {
        return cleanupAiOutputAsKeyValuePairs(rawSet, ConceptFieldDelimiter)
      })

    setIsGettingAnswers(false)

    return answerSets

  }, [setIsGettingAnswers])



  return {
    /** Generates a list of keys / titles / questions for concepts */
    getQuestionsToAsk,
    /** Generates values / descriptions / answers for concepts */
    getSuggestedAnswers,
    isGettingQuestions,
    isGettingAnswers,
  }
}
