import { DocumentSchema } from "@verdi/shared-constants";


export const tagTitleToDocType = (tagTitle: string): DocumentSchema.DocumentType => {
  const lowercaseTitle = tagTitle.toLowerCase()
  switch (lowercaseTitle) {
    case "opportunity":
    case "problem":
    case "solution":
    case "outcome":
      return DocumentSchema.DocumentType.opportunity
    case "assumption":
      return DocumentSchema.DocumentType.assumption

  }

  return DocumentSchema.DocumentType.document
}
