import { KeyValuePair } from "@verdi/shared-constants"
import { DocumentOutlineEntry, buildFirstDocStepFromOutline } from "../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { convertToDocOutlineEntriesFromKvps } from "./convertToDocOutlineEntries"


export const getInitialDocBodyStepFromConcepts = (framingParts: KeyValuePair[], docDescription?: string) => {

  console.log("getInitialDocBodyStep(): framingParts = ", framingParts)
  const steps: DocumentOutlineEntry[] = []
  if (docDescription) {
    steps.push({ type: "text", content: docDescription })
  }
  const moreSteps = convertToDocOutlineEntriesFromKvps(framingParts)
  steps.push(...moreSteps)

  return buildFirstDocStepFromOutline(steps)
}
