import { useCallback, useState } from "react"
import { DeRiskingStepOption } from "../deRiskingSteps/DeRiskingStepOptions"
import { Data, DocumentSchema, KeyValuePair } from "@verdi/shared-constants"
import { ChooseANewAction } from "./ChooseANewAction"
import { FrameANewAction } from "./FrameANewAction"
import { AccordionWrapper } from "../../components/pageLayout/AccordionWrapper"
import { useAppServices } from "../../components/appServices/useAppServices"
import { ActionListItem } from "./ActionListItem"
import { useAddConceptsToDoc } from "../../ai/docDrafter/concepts/useAddConceptsToDoc"
import { useRouter } from "found"
import { triggerGlobalCommand } from "../../components/commands/triggerGlobalCommand"
import { GlobalCommandType } from "../../components/commands/GlobalCommandType"
import { AddDocAsActionCommandArgs } from "../../components/commands/commandDefinitions/action/addDocAsAction"
import { uniqueId } from "lodash"


type Props = {
  currentDoc: Data.Menu.MenuItemData
  onCreated: (newDocId: string) => void
}
export const NewActionWizard = ({
  currentDoc,
  onCreated,
}: Props) => {

  // Current doc details. Is it an assumption, title, description, docBody if possible
  const { documentRelationsProvider, menuStructureProvider } = useAppServices()
  const { router } = useRouter()


  const [stepToAdd, setStepToAdd] = useState<DeRiskingStepOption | null>(null)
  const stepWasSelected = (step: DeRiskingStepOption) => {
    console.log("stepWasSelected: step = ", step)
    setStepToAdd(step)
    setActiveStep(Step2Frame)
    // Trigger generating the action
  }
  const [activeStep, setActiveStep] = useState<number>(Step1Choose)

  // TODO: Load more context in the future, including other assumptions
  const assumptions = [currentDoc]


  const { addConceptsToDocument } = useAddConceptsToDoc()
  const [isCreatingEverything, setIsCreatingEverything] = useState(false)
  const createActionDoc = useCallback((conceptsToUse: KeyValuePair[]) => {
    console.log("Create Action doc with concepts", { conceptsToUse, currentDoc, stepToAdd })

    setIsCreatingEverything(true)

    triggerGlobalCommand(GlobalCommandType.addDocAsAction, {
      action: stepToAdd,
      conceptsToUse,
      currentDocId: currentDoc.id,
      onCreated: () => {
        setIsCreatingEverything(false)
        // addConceptsToDocument(newDocId, conceptsToUse, () => {
        //   setIsCreatingEverything(false)
        //   onCreated(newDocId)

        // })
      },
      id: uniqueId("addDocAsAction_"),
      onCompleted: () => { },
    } as AddDocAsActionCommandArgs)

  }, [addConceptsToDocument, currentDoc, documentRelationsProvider, router, stepToAdd])


  return (
    <div>
      {/* <div>
        <h2>
          Assumption(s) to test:
        </h2>

        <div css={css`
          padding: 10px 15px;
        `}>
          {currentDoc.title}
        </div>

        <AddRelatedDocument
          currentDocId={currentDoc.id}
          docRelationTypeToUseOnAdd={Data.DocumentRelationType.assumptionOf}
          documentRelationsProvider={documentRelationsProvider}
          flatMenuItems={menuStructureProvider.menuItemsStructured.flatMenuItems}
          preFilter={{ docTypes: [DocumentSchema.DocumentType.assumption] }}
          placeholderText="Include another assumption"
          placeholderTextOnFocus="Search for an assumption"
        />

      </div> */}


      <AccordionWrapper
        isExpanded={activeStep === Step1Choose}
        setIsExpanded={(isExpanded) => setActiveStep(isExpanded ? Step1Choose : Step2Frame)}
        buttonContent={
          <h2>
            1. Choose an action
          </h2>
        } >
        <ChooseANewAction
          currentDoc={currentDoc}
          onAddClick={stepWasSelected}
        />
      </AccordionWrapper>

      {stepToAdd &&
        <ActionListItem
          step={stepToAdd}
        />
      }


      {stepToAdd &&
        <AccordionWrapper
          isExpanded={activeStep === Step2Frame}
          setIsExpanded={(isExpanded) => setActiveStep(isExpanded ? Step2Frame : Step3Guide)}
          buttonContent={
            <h2>
              2. Frame the action
            </h2>
          }>

          <FrameANewAction
            stepToAdd={stepToAdd}
            assumptions={assumptions.map(a => a.title + " " + a.description)}
            onContinueClick={(conceptsToUse: KeyValuePair[]) => createActionDoc(conceptsToUse)}
            isContinuing={isCreatingEverything}
          />

        </AccordionWrapper>
      }

      {/* 
      IDEA: on doc load if doc.type="research" then ask user if they want to make an interview guide? 
      BUT: Some research does not need an interview guide.
      */}

      {/* {true && //activeStep >= Step3Guide &&
        <AccordionWrapper buttonContent={
          <h2>
            3. Generate an interview guide
          </h2>
        }>
          <InterviewGuideFramer
            onQuestionsUpdated={(questions) => console.log("questions", questions)}
          />
        </AccordionWrapper>
      } */}

      {/* 
      <ButtonPrimary
        label="Create"
        isDisabled={activeStep != Step4Review}
      /> */}


    </div>
  )
}

const Step1Choose = 1
const Step2Frame = 2
const Step3Guide = 3
const Step4Review = 4
