import { ApiTypes, CompanyContextCommon } from "@verdi/shared-constants"
import { useCallback } from "react"
import { makeGetRequestJson, makePostRequestJson } from "../../../utility-hooks/fetchUtils"
import { LoggedInUserProvider } from "../../../components/appServices/users/useLoggedInUserProvider"
import { companyAsPromptContext } from "../../../ai/prompts/asPromptContext"
import { useAppSelector } from "../../../state/storeHooks"
import { CompanyContextState } from "../../../state/companyContextSlice"
import { dispatch } from "../../../state/store"


export type CompanyContextData = {
  [key: string]: string
}

export const useCompanyContext = (loggedInUserProvider: LoggedInUserProvider) => {


  // const [companyContext, setCompanyContext] = useState<CompanyContextCommon.MutableFields>()
  const companyContext = useAppSelector(CompanyContextState.getFields)

  const getCompanyContext = useCallback(async () => {
    if (companyContext) return companyContext
    const results: ApiTypes.CompanyContextResponseBody = await makeGetRequestJson("companyContext/current")
    const newContext = results?.fields?.jsonOutline || {}
    dispatch(CompanyContextState.setFields(newContext))
    return { ...newContext }

  }, [companyContext])
  const getCompanyContextForPrompt = useCallback(async () => {
    const c = await getCompanyContext()

    if (!c) {
      return ""
    }

    const companyInfo = await companyAsPromptContext(c)

    const userInfo = loggedInUserProvider.getCurrentUserContextForAiPrompt()

    return companyInfo + userInfo


  }, [getCompanyContext, loggedInUserProvider])

  const updateCompanyContext = useCallback(async (fields: CompanyContextCommon.MutableFields, merge = true) => {
    if (merge) {
      const currentContext = await getCompanyContext()
      await makePostRequestJson("companyContext/current", { fields: { ...currentContext, ...fields } })
      dispatch(CompanyContextState.setFields({ ...currentContext, ...fields }))
    } else {
      await makePostRequestJson("companyContext/current", { fields })
      dispatch(CompanyContextState.setFields(fields))
    }
  }, [getCompanyContext])

  const removeFromCompanyContext = useCallback(async (fields: CompanyContextCommon.MutableFields) => {
    const currentContext = await getCompanyContext()
    for (const [field, _] of Object.entries(fields)) {
      delete currentContext[field]
    }
    await makePostRequestJson("companyContext/current", { fields: { ...currentContext } })
    dispatch(CompanyContextState.setFields({ ...currentContext }))
  }, [getCompanyContext])

  return {
    getCompanyContext,
    getCompanyContextForPrompt,
    updateCompanyContext,
    removeFromCompanyContext,
  }
}

export type UseCompanyContext = ReturnType<typeof useCompanyContext>
