import { Extension } from "@tiptap/react";
import { Mark, MarkType, Node } from "prosemirror-model";
import { TextSelection } from "prosemirror-state";


declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    verdiMarks: {
      setVerdiMark: (from: number, to: number, mark: Mark) => ReturnType;
      setVerdiSearchMark: (search: string, mark: Mark, moveCursorTo?: "endOfFirstMark") => ReturnType;
      clearVerdiMarks: (types: MarkType[]) => ReturnType;
      clearVerdiMark: (from: number, to: number, types: MarkType[]) => ReturnType;
    };
  }
}

function getIndicesOf(searchStr: string, str: string, caseSensitive: boolean) {
  var searchStrLen = searchStr.length;

  if (searchStrLen === 0) {
    return [];
  }

  var startIndex = 0,
    index,
    indices = [];

  if (!caseSensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }

  while ((index = str.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }

  return indices;
}

/** Searches for a specific string and returns all the ranges it finds
 * 
 *  TODO: Move this into a utility function, decoupled from the marks
 */
export function findTextRange(searchText: string, doc: Node) {
  const ranges: { from: number; to: number }[] = [];

  doc.descendants((node: any, pos: number) => {
    if (node.isText) {
      const text = node.text;

      const indices = getIndicesOf(searchText, text, false);

      indices.forEach((index) => {
        ranges.push({ from: pos + index, to: pos + index + searchText.length });
      });
    }
  });

  return ranges;
}

export default Extension.create<{}>({
  name: "verdiMarks",
  addCommands() {
    return {
      setVerdiMark:
        (from, to, mark) =>
          ({ state }) => {
            state.apply(state.tr.addMark(from, to, mark));
            return true;
          },
      setVerdiSearchMark:
        (search, mark, moveCursorTo) =>
          ({ state }) => {
            const transaction = state.tr;

            const ranges = findTextRange(search, state.doc);

            ranges.forEach((range, index) => {
              transaction.addMark(range.from, range.to, mark);
            });

            if (moveCursorTo === "endOfFirstMark" && ranges.length > 0) {
              transaction.setSelection(TextSelection.create(transaction.doc, ranges[0].to, ranges[0].to));
              transaction.scrollIntoView();
            }

            state.apply(transaction);

            return true;
          },
      clearVerdiMarks:
        (types) =>
          ({ state }) => {
            const transaction = state.tr;

            types.forEach((type) =>
              transaction.removeMark(0, state.doc.content.size, type)
            );

            return true;
          },
      clearVerdiMark: // For an individual range or node
        (from, to, types) =>
          ({ state }) => {
            const transaction = state.tr;

            types.forEach((type) =>
              transaction.removeMark(from, to, type)
            );

            return true;
          },
    };
  },
});
