import { Extension } from "@tiptap/react";
import { EditorState } from "prosemirror-state";
import { Fragment, Slice, ResolvedPos } from "prosemirror-model";
import { ReplaceStep } from "prosemirror-transform";

import VeNode from "../../VeNode";
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants";
import { getSelectedBlockText } from "../../utils/getSelectedBlockText";
import { selectionToInsertionEnd } from "../../utils/selectionToInsertionEnd";
import {
  createNodeFromContent,
  CreateNodeFromContentOptions,
} from "../../utils/createNodeFromContent";
import { findParentNestableNodeClosestToPos } from "../../utils/findParentNestableNode";
import { addEmptyChildIfThereAreNone } from "../../utils/addEmptyChildIfThereAreNone";
import runUntil from "../../utils/runUntil";

type ReltiveInsertPosition = "before" | "after";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    PressEnter: {
      /**
       * Comments will be added to the autocomplete.
       */
      enterOnEmptyNode: () => ReturnType;
      enterOnNonEmptyNode: () => ReturnType;
      enterOnCrossNode: () => ReturnType;
      createFreeTextNear: () => ReturnType;
      splitNestedBlocks: () => ReturnType;
      insertNewFreeTextBlock: () => ReturnType;
      insertNewTaskCheckboxBlock: () => ReturnType;
      insertNestableBlockNear: (
        content: any,
        relativeInsertPosition?: ReltiveInsertPosition
      ) => ReturnType;
      replaceNestableBlockNear: (
        content: any,
        options?: CreateNodeFromContentOptions
      ) => ReturnType;
      moveCursorToStartOfBlock: () => ReturnType;
      addEmptyChildIfThereAreNone: () => ReturnType;
    };
  }
}

export const cursorIsAtBeginning = (pos: ResolvedPos) => {
  return pos.parentOffset === 0;
};

const cursorIsAtEnd = (pos: ResolvedPos) => {
  const textLength = getSelectedBlockText(pos)?.length;
  if (typeof textLength === "undefined") {
    return false;
  }
  return pos.parentOffset === textLength;
};

const getDefaultRelativeInsertPosition = (state: EditorState) => {
  const { $head, empty } = state.selection;
  if (!empty) return;
  const parent = findParentNestableNodeClosestToPos($head);
  switch (parent?.node.type.name) {
    case "question":
    case "section":
      return "after" as const;
    default:
      const shouldInsertBefore = cursorIsAtBeginning($head);
      return shouldInsertBefore ? "before" : ("after" as const);
  }
};

const getInsertPositionForResolvedAndRelativeInsertPositions = (
  parentPos: ResolvedPos,
  insertPos: ReltiveInsertPosition
) => {
  switch (insertPos) {
    case "before":
      return parentPos.before();
    case "after":
      return parentPos.after();
  }
};

export const PressEnter = Extension.create<{}>({
  name: "PressEnter",
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        runUntil(
          (result: boolean) => !!result,
          () => editor.commands.enterOnEmptyNode(),
          () => editor.commands.enterOnNonEmptyNode(),
          () => editor.commands.enterOnCrossNode()
        );

        return true;
      },
    };
  },
  addCommands() {
    return {
      enterOnEmptyNode:
        () =>
          ({ state, chain }) => {
            const { empty, $from } = state.selection;

            // If the selection is not empty, then either the node is not empty or we are doing a cross-node operation
            if (!empty) {
              return false;
            }

            const node = VeNode.fromResolvedPosition($from)?.findParentWithType(
              state,
              VeNode.NESTABLE_TYPES
            );

            /* 
            If we can't find the node, then we don't want to apply this operation 
            not really sure why this would happen, its just required for the type safety below this point
          */
            if (!node) {
              return false;
            }

            const parentNode = node.findParent(state);

            if (!parentNode) {
              return false;
            }

            const isEmpty = node.textContent().length < 1;
            const isChild =
              parentNode.type() &&
              ["contentList"].includes(parentNode.type().name);
            const isLastChild = node.index(state) >= parentNode?.childCount() - 1;
            const isOnlyChild = parentNode.childCount() <= 1;

            /*
            if thie child is not empty
            or we are on the document somehow 
            or we are not the last child we don't want to use this event
          */
            if (!isEmpty || !isChild || !isLastChild) {
              return false;
            }

            if (!isOnlyChild) {
              chain().liftNestableBlock();

              return true;
            }

            const nestableParent = node.findNestableParent(state);
            const nestableParentSibling = nestableParent?.findNextSibling(state);

            if (
              !nestableParentSibling ||
              nestableParentSibling.textContent().length > 0
            ) {
              chain().insertNewFreeTextBlock().liftNestableBlock();
            } else {
              chain().moveCursorToAdjacentNode("forwards");
            }

            return true;
          },
      enterOnNonEmptyNode:
        () =>
          ({ state, chain }) => {
            const { empty, $from, $to } = state.selection;

            const fromNode =
              VeNode.fromResolvedPosition($from)?.findNestableParent(state);

            const toNode =
              VeNode.fromResolvedPosition($to)?.findNestableParent(state);

            if (!fromNode || !toNode) {
              return false;
            }

            const isSameNode = fromNode.isEqualTo(toNode);

            /*
           in order for this to be a non-empty and non-crossing selection we need the selection 
           to be empty or the from and to to be the same nodes
          */
            if (!empty && !isSameNode) {
              return false;
            }

            if (fromNode.type().name === DocumentSchema.VerdiNodeTypes.taskCheckbox) {
              chain().insertNewTaskCheckboxBlock().splitNestedBlocks();
            } else {
              chain().insertNewFreeTextBlock().splitNestedBlocks();
            }

            return true;
          },
      enterOnCrossNode:
        () =>
          ({ state, chain }) => {
            const { empty, $from, $to } = state.selection;

            // if the selection is empty it can't be cross node
            if (empty) {
              return false;
            }

            const fromNode =
              VeNode.fromResolvedPosition($from)?.findNestableParent(state);

            const toNode =
              VeNode.fromResolvedPosition($to)?.findNestableParent(state);

            if (!fromNode || !toNode) {
              return false;
            }

            chain().deleteSelection();

            return true;
          },
      insertNewFreeTextBlock:
        () =>
          ({ state, dispatch, chain }) => {
            const { empty, $head } = state.selection;
            if (!empty) return false;
            if (!cursorIsAtBeginning($head) && !cursorIsAtEnd($head)) {
              return false;
            }
            const contentToInsert = ProsemirrorNodes.makeFreeTextBlock("");
            const defaultRelativeInsertPos = cursorIsAtBeginning($head)
              ? "before"
              : undefined;
            if (dispatch) {
              chain().insertNestableBlockNear(
                contentToInsert,
                defaultRelativeInsertPos
              );
            }
            return true;
          },

      insertNewTaskCheckboxBlock:
        () =>
          ({ state, dispatch, chain }) => {

            const { empty, $head } = state.selection;
            if (!empty) return false;

            if (!cursorIsAtBeginning($head) && !cursorIsAtEnd($head)) {
              return false;
            }

            const contentToInsert = ProsemirrorNodes.makeTaskCheckboxBlock("");
            const defaultRelativeInsertPos = cursorIsAtBeginning($head)
              ? "before"
              : undefined;
            if (dispatch) {
              chain().insertNestableBlockNear(
                contentToInsert,
                defaultRelativeInsertPos
              );
            }

            return true;
          },
      insertNestableBlockNear:
        (content, relativeInsertPositionArg) =>
          ({ state, chain, dispatch }) => {

            const { empty, $head } = state.selection;
            if (!empty) return false;

            const parent = findParentNestableNodeClosestToPos($head);
            if (!parent) {
              return false;
            }

            const relativeInsertPosition =
              relativeInsertPositionArg ??
              getDefaultRelativeInsertPosition(state) ??
              "after";
            const parentPos = state.doc.resolve(parent.start);
            let insertPos =
              getInsertPositionForResolvedAndRelativeInsertPositions(
                parentPos,
                relativeInsertPosition
              );
            const updateSelection = relativeInsertPosition !== "before";

            if (dispatch) {
              if (insertPos === 0) {
                chain()
                  .insertContentAt(insertPos, content, {
                    updateSelection,
                  })
                  .selectNodeForward()
                  .scrollIntoView();
              } else {
                chain()
                  .insertContentAt(insertPos, content, {
                    updateSelection,
                  })
                  .scrollIntoView();
              }
            }
            return true;
          },
      replaceNestableBlockNear:
        (content, options) =>
          ({ state, chain, dispatch }) => {
            const { empty, $head } = state.selection;
            if (!empty) return false;
            const parent = findParentNestableNodeClosestToPos($head);
            if (!parent) {
              return false;
            }

            if (dispatch) {
              const nodeContent = createNodeFromContent(
                content,
                state.schema,
                options
              );
              const tr = state.tr;
              dispatch(
                tr
                  .replaceWith(
                    parent.pos,
                    parent.pos + parent.node.nodeSize,
                    nodeContent
                  )
                  .scrollIntoView()
              );
              const updateSelection = true;
              // set cursor at end of inserted content
              if (updateSelection) {
                selectionToInsertionEnd(tr, tr.steps.length - 1, -1);
              }
            }
            return true;
          },
      splitNestedBlocks:
        () =>
          ({ state, dispatch, chain }) => {
            let { $from, $to } = state.selection;
            // TODO: implement splitting (and don't insert new block) when cursor is at end
            // and when we have children
            if (cursorIsAtBeginning($from) || cursorIsAtEnd($from)) {
              return false;
            }

            const splittableParent = findParentNestableNodeClosestToPos($from);
            if (!splittableParent) return false;

            // TODO: if section or question (not text) then add empty child to first block of split

            const text = getSelectedBlockText($from);
            if (!text) return false;

            if (dispatch) {
              const type = splittableParent.node.type;
              const contentListType = state.schema.nodes.contentList;
              const titleType = state.schema.nodes.title;
              const fragment = Fragment.from([
                type.create(null, [titleType.create(), contentListType.create()]),
                type.create(null, [titleType.create()]),
              ]);
              const slice = new Slice(fragment, 2, 2);
              dispatch(
                state.tr
                  .step(new ReplaceStep($from.pos, $to.pos, slice))
                  .scrollIntoView()
              );
            }
            return true;
          },
      addEmptyChildIfThereAreNone:
        () =>
          ({ state, chain, dispatch }) => {
            return addEmptyChildIfThereAreNone(state, chain, dispatch);
          },
    };
  },
});
