import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { addOpportunityShowOptionsCommandDefinition } from "../../commandDefinitions/opportunity/addOpportunityShowOptions"
import { NavigateToCommandArgs, navigateToCommandDefinition } from "../../commandDefinitions/navigateTo"
import { showConfirmDialogCommandDefinition } from "../../commandDefinitions/utils/showConfirmDialog"
import { showMenuItemSearchCommandDefinition } from "../../commandDefinitions/utils/showMenuItemSearch"


/** Provides top level commands that are appropriate to run from anywhere */
export const getCmdsForAnywhere = (shouldIncludeDebug: boolean): VerdiCommandGroup[] => {
  const group: VerdiCommandGroup = {
    title: "",
    commands: getTheCommands(shouldIncludeDebug),
  }
  return [group]
}

const getTheCommands = (shouldIncludeDebug: boolean): VerdiCommand[] => {

  return [
    searchCommand,
    ...navigateToCommands,
    addOpportunityCommand,
    ...getDebugCommands(shouldIncludeDebug),
  ]
}

const addOpportunityCommand = addOpportunityShowOptionsCommandDefinition.buildMenuItem({})
const searchCommand = showMenuItemSearchCommandDefinition.buildMenuItem({})

// Useful file to check routes: web/src/routes/index.tsx
const pagesToAdd: NavigateToCommandArgs[] = [
  { nameOfPage: "Company Profile", urlOfPage: "/organization/context" },
  { nameOfPage: "Team", urlOfPage: "/organization/users" },
  { nameOfPage: "Home", urlOfPage: "/" },
]
const navigateToCommands = pagesToAdd.map(page => navigateToCommandDefinition.buildMenuItem(page))

type UseCommandsForAnywhere = {
  commands: VerdiCommand[]
}

const getDebugCommands = (shouldIncludeDebug: boolean) => {
  if (!shouldIncludeDebug) return []
  const commands: VerdiCommand[] = []

  const showConfirmDialogCommand = showConfirmDialogCommandDefinition.buildMenuItem({
    dialogBodyText: "The body of the dialog",
    confirmButtonLabel: "Confirm button label",
    heading: "The heading",
    onConfirmSuccess: () => console.log("Confirmed"),
  })
  commands.push(showConfirmDialogCommand)

  return commands
}
