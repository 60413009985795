
/** Sometimes the AI returns invalid JSON. 
 * This utility helps mitigate the badness
 *  */
export const sanitizeAiJson = (rawJsonString: string) => {

  // Remove any control characters (like line breaks) that may break the json parser.
  const replaced = rawJsonString.toString().replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "")

  // .replace(/\n/g, "") // GOOD, but only for char 10
  // .replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "") // GOOD
  // .replace(/\x00-\x1f/g, "") // BAD
  // .replace(/[\u0000-\u001F\u007F-\u009F]/g, "") 
  return replaced
}
