import { DeRiskingStepOption, DeRiskingStepOptions } from "../deRiskingSteps/DeRiskingStepOptions"
import { Data } from "@verdi/shared-constants"
import { useEffect, useMemo } from "react"
import { useActionsSuggester } from "../../ai/suggestions/useActionsSuggester"
import { useAppServices } from "../../components/appServices/useAppServices"
import { ActionListItem } from "./ActionListItem"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { VerdiIconRefresh } from "../../components/icons/VerdiIcons"
import { getCurrentAppState } from "../../state/store"


type Props = {
  currentDoc: Data.Menu.MenuItemData
  onAddClick: (stepOption: DeRiskingStepOption) => void
}
export const ChooseANewAction = ({
  currentDoc,
  onAddClick,
}: Props) => {

  const { getDocumentContextForAi } = useAppServices()
  const {
    suggestedItems,
    generateSuggestedActionsForAssumptions,
    isGenerating,
  } = useActionsSuggester({ getDocumentContextForAi })

  const assumptionsState = getCurrentAppState().aiContext.assumptions

  const assumptions = assumptionsState.map(a => a.question + ":" + a.answer)


  const optionsToAdd = useMemo(() => {
    if (suggestedItems?.length > 0) {
      return [
        ...suggestedItems,
        ...DeRiskingStepOptions,
      ]
    }
    return []
  }, [suggestedItems])


  useEffect(() => {
    if (!isGenerating) {
      const docContext = getDocumentContextForAi()
      generateSuggestedActionsForAssumptions(docContext, assumptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDoc.id])


  return (
    <div>

      <div>
        {optionsToAdd.map((step, key) =>
          <ActionListItem
            key={key}
            step={step}
            onAddClick={onAddClick}
          />
        )}
      </div>

      <ButtonPrimary
        label="show more options"
        loadingText="loading options"
        leftIcon={<VerdiIconRefresh />}
        onClick={() => generateSuggestedActionsForAssumptions(getDocumentContextForAi(), assumptions)}
        isLoading={isGenerating}
      />

    </div>
  )
}
