import { Alert, AlertIcon, AlertTitle, IconButton, Spinner, Tooltip, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { ChatMessageView, ChatMessageViewLabelWidth } from "./ChatMessageView"
import { useCallback, useEffect, useState } from "react"
import { UseAiChatBridge } from "./useAiChatBridge"
import { ChatButtonClickArgs, useChatButtonProcessor } from "./useChatButtonProcessor"
import { ChatMessage, useChatHistory } from "./useChatHistory"
import { UserInputForChat, setFocusToChatInput } from "./UserInputForChat"
import { ChatRoleType } from "@verdi/shared-constants"
import { AiPersona, defaultAiPersona } from "./AiPersona"
import { loadAiPersonaFromLocalStorage } from "./AiPersonaSelector"
import { ChatViewSettings } from "./ChatViewSettings"
import { ChatGettingStarted } from "./ChatGettingStarted"
import { PageLayoutState } from "../pageLayout/usePageLayoutState"
import { ButtonPrimary } from "../buttons/ButtonPrimary"
import { VerdiIconArrowsLeft, VerdiIconArrowsRight, VerdiIconRefresh } from "../icons/VerdiIcons"


type Props = {
  aiChatBridge: UseAiChatBridge,
  pageLayoutState: PageLayoutState,
  shouldShowGettingStartedOnFirstLoad?: boolean,
}
export const ChatView = ({
  aiChatBridge,
  pageLayoutState,
  shouldShowGettingStartedOnFirstLoad,
}: Props) => {

  const isLightMode = useColorModeValue(true, false)
  const [aiIsTyping, setAiIsTyping] = useState(false)

  const [aiPersona, setAiPersona] = useState<AiPersona>(defaultAiPersona)
  useEffect(() => {
    const persona = loadAiPersonaFromLocalStorage()
    setAiPersona(persona || defaultAiPersona)
  }, [])

  const chatHistory = useChatHistory()



  const onAiResponseReceived = useCallback((wasSuccessful: boolean, message?: ChatMessage) => {
    console.log("onAiResponseReceived ", message)

    if (wasSuccessful && message) {
      const dynamicJson = message.buttons ? JSON.stringify({ buttons: message.buttons }) : ""
      console.log("onAiResponseReceived dynamicJson: ", dynamicJson)
      chatHistory.addMessage(message.content, message.role, dynamicJson)
      setPendingUserInput("")
    }

    setShouldShowRetryButton(!wasSuccessful)
    setAiIsTyping(false)
  }, [chatHistory])


  const onSubmitButtonClick = useCallback((userInput: string) => {
    setPendingUserInput(userInput)
    chatHistory.addMessage(userInput, ChatRoleType.user)
    setAiIsTyping(true)
    console.log("submitted with aiPersona: ", aiPersona)
    aiChatBridge.triggerUserChatMessageReceivedEvent(userInput, chatHistory.currentSessionMessages, aiPersona)
  }, [aiPersona, aiChatBridge, chatHistory])


  const commandProcessor = useChatButtonProcessor({
    chatHistoryProvider: chatHistory,
    aiChatBridge,
  })

  const scrollToBottom = useCallback(() => {
    const div = document.getElementById("chatScrollPane")
    if (!div) return
    div.scrollTop = div.scrollHeight
  }, [])

  const [isFullScreen, setIsFullScreen] = useState(false)
  useEffect(() => {
    setIsFullScreen(pageLayoutState.rightSideState === "fullscreen")
  }, [pageLayoutState.rightSideState])

  const onButtonClick = useCallback((args: ChatButtonClickArgs) => {
    commandProcessor.processChatButtonClick(args)
  }, [commandProcessor])


  /** Retry on fail */
  const [shouldShowRetryButton, setShouldShowRetryButton] = useState(false)
  const [pendingUserInput, setPendingUserInput] = useState<string>("") /** For Retry, if AI fails to respond */

  const onRetryButtonClick = useCallback(() => {
    setAiIsTyping(true)
    aiChatBridge.triggerUserChatMessageReceivedEvent(pendingUserInput, chatHistory.currentSessionMessages, aiPersona)
  }, [aiChatBridge, pendingUserInput, chatHistory, aiPersona])


  useEffect(() => {
    aiChatBridge.setOnStartNewChatSession((firstMessage: ChatMessage) => {
      if (firstMessage.role === ChatRoleType.user) {
        onSubmitButtonClick(firstMessage.content)
      } else {
        onAiResponseReceived(true, firstMessage)
      }
    })
    aiChatBridge.setOnAiResponseReceived(onAiResponseReceived)
    scrollToBottom()
  }, [aiChatBridge, onSubmitButtonClick, onAiResponseReceived, scrollToBottom])


  useEffect(() => {
    setTimeout(() => {
      setFocusToChatInput()
    })
  }, [pageLayoutState.rightSideState])


  return (
    <div css={css`
      padding: ${isFullScreen ? "40px 20px" : "0 8px 8px"};
      border-radius: 10px;
      background-color: transparent;
      background-color: ${isFullScreen ? (isLightMode ? "white" : "#222222") : "transparent"};
    `}>

      <div css={css`
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
      `}>{pageLayoutState.rightSideState === "visible" &&
          <div>

            {/* <Tooltip label='expand chat' placement='right-start'>
              <IconButton
                aria-label="expand chat"
                icon={<VerdiIconArrowsLeft />}
                variant="ghost"
                backgroundColor={isLightMode ? "white" : "black"}
                size="xs"
                marginLeft={-1}
                onClick={() => pageLayoutState.setRightSideState("fullscreen")}
              />
            </Tooltip> */}

          </div>
        }
        <div css={css`
          display: flex;
          align-items: flex-start;
          width: 100%;
        `}>

          <ChatViewSettings
            loadPastMessages={chatHistory.loadPastMessages}
            hasPastMessagesToLoad={chatHistory.hasPastMessagesToLoad}
            isLoadingMoreChatHistory={chatHistory.isLoadingMoreChatHistory}
            aiPersona={aiPersona}
            onAiPersonaChange={(persona) => setAiPersona(persona)}
            isCompactMode={!isFullScreen}
          />


          {isFullScreen &&

            <Tooltip label='snap to the right' placement='left-start'>
              <IconButton
                aria-label="snap to the right"
                icon={<VerdiIconArrowsRight />}
                variant="ghost"
                backgroundColor={isLightMode ? "white" : "black"}
                size="xs"
                marginRight={-1}
                onClick={() => pageLayoutState.setRightSideState("visible")}
              />
            </Tooltip>

          }
        </div>


      </div>

      {/* Chat messages */}
      <div id="chatScrollPane"
        css={css`
        max-height: calc(100vh - 500px);
        /* min-height: 50px; */
        overflow-y: auto;
        padding-bottom: 20px;
      `}>

        {/* {shouldShowGettingStartedOnFirstLoad &&
          <ChatGettingStarted
            onButtonClick={onButtonClick}
            commandProcessor={commandProcessor}
          />
        } */}

        {chatHistory.currentSessionMessages.map((message, index) => (
          <ChatMessageView
            key={index}
            role={message.role}
            content={message.content}
            createdAt={new Date(message.createdAt)}
            commandProcessor={commandProcessor}
            isLightMode={isLightMode}
            isCompactMode={!isFullScreen}
            isMostRecentMessage={index === chatHistory.currentSessionMessages.length - 1}
            buttons={message.buttons}
            onButtonClick={onButtonClick}
          />
        ))}


        {aiIsTyping &&
          <div css={css`
            height: 40px;
            margin-top: 20px;
            margin-left: ${ChatMessageViewLabelWidth - 15}px;
          `}>
            <Spinner color="purple" size="sm" />
          </div>}

      </div>

      {shouldShowRetryButton &&
        <div css={css`
            margin-left: ${isFullScreen ? ChatMessageViewLabelWidth : 0}px;
            font-size: 0.8rem;
            margin-bottom: 10px;
          `}>
          <Alert status='error'
            padding="5px 10px"
          >
            <AlertIcon />
            <div>
              <AlertTitle>
                Something went wrong
              </AlertTitle>
              <ButtonPrimary
                label="Try again"
                size="xs"
                leftIcon={<VerdiIconRefresh />}
                onClick={() => onRetryButtonClick()}
              />
            </div>
          </Alert>
        </div>
      }

      <div css={css`
        margin-left: ${isFullScreen ? ChatMessageViewLabelWidth : 0}px;
      `}>
        <UserInputForChat
          onSubmitButtonClick={onSubmitButtonClick}
        />
      </div>


    </div >
  )

}
