import { useMemo } from "react"
import { Data, DocumentSchema, Menu } from "@verdi/shared-constants"
import { getUrlForOpportunityAssumptions } from "../../../routes"
import { OpportunityRiskBadge, calculateOpportunityRisk } from "../../assumptions/risk/OpportunityRiskBadge"
import { RouterState } from "found"
import { useAppSelector } from "../../../state/storeHooks"
import { OpportunitiesState } from "../../../state/opportunitiesSlice"
import { OpportunityStatusEditor } from "./OpportunityStatusEditor"
import { DocumentDetailLabel } from "../../documentDetails/DocumentDetailLabel"
import { VerdiCommand } from "../../document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { AssumptionsState } from "../../../state/assumptionsSlice"
import { DocRelationsState } from "../../../state/docRelationsSlice"
import { DocumentsState } from "../../../state/DocumentsSlice"


type Props = {
  currentOpportunityDoc: Menu.MenuItemData
  router: RouterState<any>
}

export const OpportunityDetailsPanel = ({
  currentOpportunityDoc,
  router,
}: Props) => {
  // Current Opportunity
  // Assumptions for current opportunity

  const allOpportunitiesRaw = useAppSelector(OpportunitiesState.getAllOpportunityPartials)
  const opportunity = useMemo(() => {
    if (!currentOpportunityDoc.id) return undefined
    const found = allOpportunitiesRaw.find((o) => o.mainDocId === currentOpportunityDoc.id)
    return found

  }, [allOpportunitiesRaw, currentOpportunityDoc.id])

  const assumptionsMenuItem = useMemo<Menu.MenuItemData>(() => {
    return {
      url: getUrlForOpportunityAssumptions(currentOpportunityDoc.id),
      title: "Assumptions",
      id: "assumptions",
      createdAt: new Date(),
      children: [],
      type: DocumentSchema.DocumentType.assumption,
      isVisibleToOrg: true,
    }
  }, [currentOpportunityDoc.id])


  const allAssumptions = useAppSelector(AssumptionsState.getAllAssumptions)
  const allDocRelations = useAppSelector(DocRelationsState.getAll)
  const allDocs = useAppSelector(DocumentsState.getFlatMenuItems)
  const assumptionIds = useMemo(() => {
    const assumptionRelations = allDocRelations.filter((r) => r.toDocId === currentOpportunityDoc.id
      && r.type === Data.DocumentRelationType.assumptionOf)
    const docIds = [...new Set(assumptionRelations.map(r => r.fromDocId))]

    const existingDocIds = allDocs.filter((d) => docIds.includes(d.id) && !d.deletedAt)
      .map(d => d.id)

    return existingDocIds
  }, [currentOpportunityDoc.id, allDocRelations, allDocs])


  const assumptionDetails = useMemo(() => {
    const assumptions = allAssumptions.filter((a) => a.mainDoc?.id && assumptionIds.includes(a.mainDoc?.id))
    const risk = calculateOpportunityRisk(assumptions)
    return {
      assumptions,
      risk,
    }
  }, [allAssumptions, assumptionIds])


  return (
    <>

      <OpportunityRiskBadge
        opportunityRisk={assumptionDetails.risk}
        landmarkId="opportunityRiskBadge"
        onClick={() => router.router.push(assumptionsMenuItem.url)}
      />

      {opportunity?.id &&
        <DocumentDetailLabel title="Status">
          <OpportunityStatusEditor
            opportunityId={opportunity.id}
            status={opportunity.status}
            onItemSelected={(command: VerdiCommand) => {
              command.runCommand?.()
            }}
            landmarkId="opportunityStatus"
          />
        </DocumentDetailLabel>
      }

    </>
  )
}
