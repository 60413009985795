import { css } from "@emotion/react";
import Editor, { useMonaco } from "@monaco-editor/react";
import { languages } from "monaco-editor";
import { useEffect, useRef } from "react";
import { BuiltInPlaceholderValueMapping } from "@verdi/shared-constants";
import { buildSuggestions, EditorSuggestion } from "./HandlebarsAutocomplete";

export const samplePlaceholderValues: BuiltInPlaceholderValueMapping = {
  companyName: "ACME Co.",
  companyDescription: "company widgets",
  documentBody: "This document has the best content!",
  documentDescription: "Description of the document",
  documentTitle: "This is the title of the document",
  documentType: "Press Release",
  opportunityName: "Follow the latest VC hype trains",
  opportunityDescription: "Explore using AI to generate NFTs of our widgets",
  opportunityDocument:
    "Increase our fundability as a company by Explore using AI to generate NFTs of our widgets",
} as const;

const autocompleteForKey = (
  key: string,
  children: EditorSuggestion[] = []
): EditorSuggestion => {
  return {
    label: key,
    insertText: key,
    description: key,
    children,
  };
};

type SampleData = {
  [key: string]: string | SampleData;
};

const makeAutocompleteFromSampleData = (
  sampleData: BuiltInPlaceholderValueMapping
): EditorSuggestion[] => {
  const keys = Object.keys(sampleData);
  return keys.map((key) => {
    const value = (sampleData as any)[key];
    const children =
      typeof value === "string" ? [] : makeAutocompleteFromSampleData(value);
    return autocompleteForKey(key);
  });
};

const suggestion: EditorSuggestion[] = makeAutocompleteFromSampleData(
  samplePlaceholderValues
);

type Props = {
  value: string;
  readOnly?: boolean;
  onChange?: (text?: string) => void;
};

export const AiPromptIDE = (props: Props) => {
  return (
    <Editor
      css={css`
        margin-bottom: 20px;
        font-size: 100px;
      `}
      options={{
        readOnly: props.readOnly,
        minimap: {
          enabled: false,
        },
        fontSize: 12,
      }}
      value={props.value}
      onChange={"onChange" in props ? props.onChange : undefined}
      height="400px"
      language="handlebars"
      theme={"vs-dark"}
    />
  );
};
