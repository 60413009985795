import { PageLayoutSplitViewWrapper } from "../../../components/pageLayout/pageLayoutSplitViewWrapper"
import { useCallback, useEffect, useState } from "react"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { makeGetRequestJson } from "../../../utility-hooks/fetchUtils"
import { Data } from "@verdi/shared-constants"
import { StripeCustomerList } from "./StripeCustomerList"
import { MainViewWrapper } from "../../../components/pageLayout/MainViewWrapper"
import { LinkUserToStripe } from "./LinkUserToStripe"
import { OrganizationPlanList } from "./OrganizationPlanList"
import { css } from "@emotion/react"


export const StripeAdminPage = () => {

  const appServices = useAppServices()


  const [customers, setCustomers] = useState<Data.StripeShared.StripeCustomer[]>([])
  const [pricedProducts, setPricedProducts] = useState<any[]>([])
  const loadAll = useCallback(async () => {

    Promise.allSettled([
      makeGetRequestJson("stripe/customers"),
      makeGetRequestJson("stripe/pricedProducts"),
    ]).then(([customers, pricedProducts]) => {
      console.log("all settled", { customers, pricedProducts })
      setCustomers((customers as any).value.data)
      setPricedProducts((pricedProducts as any).value as Data.StripeShared.PricedProduct[])
    })

  }, [setCustomers, setPricedProducts])


  const [subscriptions, setSubscriptions] = useState<Dictionary<Data.StripeShared.StripeSubscription[]>>({})
  const loadSubscriptionsForCustomer = useCallback(async (customerId: string) => {
    const subscriptions = await makeGetRequestJson(`stripe/customers/${customerId}/subscriptions`)
    console.log("subscriptions", { subscriptions })
    setSubscriptions(prev => ({ ...prev, [customerId]: subscriptions.data as Data.StripeShared.StripeSubscription[] }))

  }, [setSubscriptions])


  useEffect(() => {
    loadAll()
  }, [])



  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={appServices}
        mainMenu={
          <div>
            Admin Menu
          </div>
        }
        rightSideInitialState={"hidden"}
        mainContent={

          <MainViewWrapper>
            <div css={css`
              display: flex;
              flex-direction: column;
              gap: 30px;
            `}>

              <h1>Stripe Admin Tools</h1>

              <StripeCustomerList
                customers={customers}
                subscriptions={subscriptions}
                loadSubscriptionsForCustomer={loadSubscriptionsForCustomer}
              />

              <LinkUserToStripe
                pricedProducts={pricedProducts}
              />

              <OrganizationPlanList />
            </div>

          </MainViewWrapper>
        }
      />
    </div>
  )
}


type Dictionary<T> = {
  [key: string]: T;
}
