import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { PageLayoutSplitViewWrapper } from '../../components/pageLayout/pageLayoutSplitViewWrapper'
import { useAppServices } from '../../components/appServices/useAppServices'
import { OpportunitiesKanbanBoard } from './OpportunitiesKanbanBoard'
import { SideMenu } from '../opportunityDocument/SideMenu'
import { useEffect } from 'react'
import { MainViewWrapper } from '../../components/pageLayout/MainViewWrapper'
import { PageHeading } from '../../components/pageLayout/PageHeading'
import { KanbanViewToggle } from '../../components/kanban/KanbanViewToggle'
import { css } from '@emotion/react'
import { useAppSelector } from '../../state/storeHooks'
import { OpportunitiesState, OpportunitiesViewMode } from '../../state/opportunitiesSlice'
import { DefinitionTipForUser } from '../../components/DefinitionTipForUser'
import { VerdiIconOpportunity } from '../../components/icons/VerdiIcons'
import { IconWrapper } from '../../components/icons/IconWrapper'


export function OpportunitiesPage() {

  const sharedServicesProvider = useAppServices()
  const {
    pageLayoutState,
    menuStructureProvider,
    opportunityProvider,
  } = sharedServicesProvider

  const viewMode = useAppSelector(OpportunitiesState.getOpportunitiesViewMode)

  useEffect(() => {
    pageLayoutState.setRightSideState("hidden")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={sharedServicesProvider}
        preventScrollAtTopLevel={viewMode === OpportunitiesViewMode.Kanban}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>
        }
        mainContent={
          <MainViewWrapper
            doNotAddMarginToBottom={true}
            maxWidth={1920}
          >

            <PageHeading
              titleNode={
                <span css={css`
                  display: flex;
                  justify-content: space-between;
                `}>
                  <span>
                    <IconWrapper
                      icon={<VerdiIconOpportunity />}
                    />

                    <DefinitionTipForUser
                      word="Opportunities"
                      definition="a problem, solution or idea to be explored at the intersection of customer and business value"
                      pronunciation="op-or-chew-ni-tee"
                      wordType="noun"
                    />
                  </span>
                  <KanbanViewToggle />
                </span>
              }
              description=""
            />

            <div>
              <OpportunitiesKanbanBoard />
            </div>


          </MainViewWrapper>
        } />
    </div >
  )
}
