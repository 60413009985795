import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { TagsChangeSet, TagsProvider } from "../../../../state/TagsProvider";
import { Tag, TagsState } from "../../../../state/TagsSlice";
import { DocTagsState } from "../../../../state/docTagsSlice";
import { getCurrentAppState } from "../../../../state/store";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu";
import { setTagsCommandDefinition } from "./setTags";


const cmdType = GlobalCommandType.setTagsShowOptions


/** Allows user to select tags from a list, that can be attached to objects, such as a Document or a MindMap graph node */
export const setTagsShowOptionsCommandDefinition: GlobalCommandDefinition<SetTagsShowOptionsArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetTagsShowOptionsArgs) => {
    const title = "Set tags ..."
    const toReturn = {
      globalCommandType: cmdType,
      name: title,
      searchName: title.toLowerCase(),
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetTagsShowOptionsArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // TODO: Make this real objects in code, eventually in the DB
    const tags = TagsState.getAll(getCurrentAppState())

    const commands = tags.map(tag => {
      return setTagsCommandDefinition.buildMenuItem({
        tag,
        documentId: args.documentId,
        onTagSelected: args.onTagSelected,
        isCurrentTag: Boolean(args.currentTag?.id) && args.currentTag?.id === tag.id,
      })
    })

    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands: commands,
      searchPlaceholderText: "Select a tag ...",
      rectOfAnchorElement: args.rect,
      shouldAdjustRectForScrollOffset: false,
      commandForNoResults: {
        commandTitle: "Add new tag",
        iconType: "add",
        onCommandSelected: async (searchText) => {
          const newTag = await TagsProvider.addTag({
            title: searchText,
          })

          setTagsCommandDefinition.triggerCommand?.({
            tag: newTag,
            documentId: args.documentId,
            onTagSelected: args.onTagSelected,
            isCurrentTag: false,
          })
        },
      },
      // onCloseCallback: () => {
      //   console.log("On close called back!")
      // }
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: SetTagsShowOptionsArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetTagsShowOptionsArgs = {
  rect?: DOMRect
  /** If specified, will apply the tag to the given document */
  documentId?: string
  onTagSelected?: (tagChanges: TagsChangeSet) => void
  currentTag: Tag | undefined
}
