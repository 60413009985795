import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


interface DocumentFrameworksState {
  allDocumentFrameworks: Data.DocumentFrameworkDto[]
  currentOpportunityFramework?: Data.DocumentFrameworkDto // Specific to opportunity.mainDoc
  currentDocumentFramework?: Data.DocumentFrameworkDto // Specific to document
}

const initialState: DocumentFrameworksState = {
  allDocumentFrameworks: [],
}

export const documentFrameworksSlice = createSlice({
  name: 'documentFrameworks',
  initialState,
  reducers: {
    setAllDocumentFrameworks: (state, action: PayloadAction<Data.DocumentFrameworkDto[]>) => {
      state.allDocumentFrameworks = action.payload || []
    },
    addDocumentFramework: (state, action: PayloadAction<Data.DocumentFrameworkDto>) => {
      state.allDocumentFrameworks = [...state.allDocumentFrameworks, action.payload]
    },
    removeDocumentFramework: (state, action: PayloadAction<{ documentFrameworkId: string }>) => {
      const index = state.allDocumentFrameworks.findIndex(df => df.id === action.payload.documentFrameworkId)
      state.allDocumentFrameworks = state.allDocumentFrameworks.filter(df => df.id !== action.payload.documentFrameworkId)
    },
    removeAllForDocument: (state, action: PayloadAction<{ documentId: string }>) => {
      state.allDocumentFrameworks = state.allDocumentFrameworks.filter(df => df.documentId !== action.payload.documentId)
    },
    setCurrentOpportunityFramework: (state, action: PayloadAction<Data.DocumentFrameworkDto | undefined>) => {
      state.currentOpportunityFramework = action.payload
    },
    setCurrentDocumentFramework: (state, action: PayloadAction<Data.DocumentFrameworkDto | undefined>) => {
      state.currentDocumentFramework = action.payload
    },
  },
  selectors: {
    getCurrentOpportunityFramework: (state) => {
      return state.currentOpportunityFramework
    },
    getCurrentDocumentFramework: (state) => {
      return state.currentDocumentFramework
    },
    getDocumentFrameworksForDoc: (state, documentId: string) => {
      return state.allDocumentFrameworks
        .filter(df => df.documentId === documentId)
    },
  },
})

export const documentFrameworkState = {
  ...documentFrameworksSlice.actions,
  ...documentFrameworksSlice.selectors,
}


export default documentFrameworksSlice.reducer
