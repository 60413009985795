import { useCallback, useMemo } from "react";
import { css } from "@emotion/react";
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import LoadingSpinner from "../../../../../components/LoadingSpinner";
import { useAppSelector } from "../../../../../state/storeHooks";
import { DocumentTaskModel, DocumentTasksState } from "../../../../../state/documentTasksSlice";
import { dispatch } from "../../../../../state/store";
import { getTitleOfBlock } from "../../utils/getDocSummaryOfNodes";
import { DocumentTaskProvider } from "../../../dataSync/DocumentTaskProvider";
import { DocumentsState } from "../../../../../state/DocumentsSlice";


type Props = NodeViewProps;


/** Renders a checkbox element that is magically sync'd with a corresponding DocumentTask in the DB */
export const TaskCheckboxComponent = (props: Props) => {


  const currentDocId = useAppSelector(DocumentsState.getCurrentDocId)
  const idInDoc = useMemo(() => {
    return props.node.attrs.id;
  }, [props.node.attrs.id]);

  const taskData = useAppSelector(state => DocumentTasksState.getTaskByIdInDoc(state, idInDoc));

  const isChecked = useMemo(() => {
    return Boolean(taskData?.completedAt);
  }, [taskData?.completedAt]);


  const onCheckboxChange = useCallback((isNowChecked: boolean) => {

    if (!currentDocId) {
      console.warn("No current doc id set to update task checkbox.")
      return
    }

    const fields: Partial<DocumentTaskModel> = {
      completedAt: isNowChecked ? new Date().toISOString() : null,
      titlePlainText: getTitleOfBlock(props.node),
    }

    dispatch(DocumentTasksState.updateOrAddTaskByIdInDoc({
      docId: currentDocId,
      idInDoc,
      fields,
    }))

    DocumentTaskProvider.upsertTaskCheckbox(
      currentDocId,
      idInDoc,
      fields,
    )

  }, [currentDocId, idInDoc, props.node]);


  return (
    <NodeViewWrapper
      as="div"
      data-doc-id={props.node.attrs.docId}
      css={css`
        display: flex;
        align-items: start;
        justify-content: flex-start;
        margin: 4px 0 4px -8px;
      `}
    >

      <label css={css`
        padding: 6px 6px 0 6px;
        line-height: 1;
      `}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => onCheckboxChange(!isChecked)} />
        {taskData?.isSyncing && <LoadingSpinner size="xs" opacity={0.2} />}
      </label>

      <div css={css`
        width: 100%;
        ${isChecked ? css`opacity: 0.5;` : ""}
      `}>

        <NodeViewContent
          as="div"
        // onKeyPress={() => console.log("changed on key press")}
        />

      </div>

    </NodeViewWrapper>
  );
};
