import { uniqueId } from "lodash"
import { DocumentContextForAi } from "../../../../../../../ai/coach/AiCoachPrompts"
import { draftInterviewGuideCommandDefinition } from "../../../../../../../components/commands/commandDefinitions/documentBody/draftInterviewGuide"
import { SuggestionForDocument } from "../useWhatsNextSuggester"


export const getWhatsNextForInterviewGuide = async (
  onCommandCompleted: (commandId: string) => void,
  docContext?: DocumentContextForAi,
): Promise<SuggestionForDocument[]> => {

  if (!docContext) {
    console.warn("useWhatsNextForInterviewGuide: no doc context")
    return []
  }

  const suggestions: SuggestionForDocument[] = []

  if (docContext.body.length < 300) {
    const commandId = uniqueId("draftInterviewGuide-")
    const draftInterviewGuideCommand = draftInterviewGuideCommandDefinition.buildMenuItem({
      id: commandId,
      onCompleted: () => onCommandCompleted(commandId),
    })
    suggestions.push({
      command: draftInterviewGuideCommand,
    })
  }

  return suggestions
}

