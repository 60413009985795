import { css } from "@emotion/react"
import { AppServices } from "../../components/appServices/useRegisterAppServices"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { DocumentAccessEditor } from "./DocumentAccessEditor"
import { DocumentSchema } from "@verdi/shared-constants"
import { InterviewNotesList } from "../documentRelation/interviewNotes/InterviewNotesList"
import { useMemo } from "react"
import { DocumentDetailLabel } from "./DocumentDetailLabel"
import { DocumentFrameworkEditor } from "../documentFrameworks/DocumentFrameworkEditor"
import { SubSectionWrapper } from "../opportunityDocument/subMenu/SubSectionWrapper"
import { AiContextEditor } from "./AiContextEditor"
import { DocMetadataViewer } from "./DocMetadataViewer"
import { DocumentIdeasPanel } from "../Ideas/DocumentIdeasPanel"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { OpportunityDetailsPanel } from "../opportunities/detailsPanel/OpportunityDetailsPanel"
import { AssumptionDetailsPanel } from "../assumptions/AssumptionDetailsPanel"
import { DocumentDescriptionEditor } from "./DocumentDescriptionEditor"
import { DocumentTagEditor } from "./DocumentTagEditor"


type Props = {
  document: BasicDocumentDetails,
  sharedServicesProvider: AppServices,
  opportunityMainDocId?: string,
  opportunityIdFromRoute?: string,
}

/** Shows relationships and other data about a given document */
export const DocumentDetailsPanel = ({
  document,
  sharedServicesProvider,
  opportunityMainDocId,
  opportunityIdFromRoute,
}: Props) => {

  // const { refs } = useUrlParams()

  const {
    documentRelationsProvider: relationsProvider,
    menuStructureProvider,
    router,
    opportunityProvider,
  } = sharedServicesProvider

  const {
    menuItemsStructured,
  } = menuStructureProvider

  const flatMenuItems = useMemo(() => {
    return menuItemsStructured.flatMenuItems
  }, [menuItemsStructured.flatMenuItems])

  const relatedDocs = useMemo(() => {
    return relationsProvider.getRelationsForDocGrouped(document.id)
  }, [document.id, relationsProvider])

  const childDocs = useMemo(() => {
    return menuStructureProvider.getMenuItem(document.id)?.children || []
  }, [document.id, menuStructureProvider])

  const { experimentalEnabled } = useFeatureFlags()


  return (
    <div>
      <SubSectionWrapper
        title="Note Details"
        shouldHideBorder={true}
        initialAccordionState="expanded"
      >

        <div css={css`
        display: flex;
        flex-direction: column;
        padding-left: 12px;
      `}>

          <DocumentDetailLabel title="Description">
            <DocumentDescriptionEditor
              document={document}
              landmarkId="documentDescription"
            />
          </DocumentDetailLabel>

          <DocumentDetailLabel title="Framework">
            <DocumentFrameworkEditor
              documentId={document.id}
              isForOpportunity={document.type === DocumentSchema.DocumentType.opportunity}
              landmarkId="documentFrameworkEditor"
            />
          </DocumentDetailLabel>

          <DocumentDetailLabel title="Tag">
            <DocumentTagEditor
              document={document}
              landmarkId="documentTagEditor"
            />
          </DocumentDetailLabel>


          <DocumentDetailLabel title="Access">
            <DocumentAccessEditor
              document={document}
              sharedServicesProvider={sharedServicesProvider}
              landmarkId="documentAccessEditor"
            />
          </DocumentDetailLabel>

          {opportunityProvider.currentOpportunityDoc?.id === document.id &&
            <OpportunityDetailsPanel
              currentOpportunityDoc={opportunityProvider.currentOpportunityDoc}
              router={router}
            />
          }


          {experimentalEnabled &&
            <DocumentDetailLabel title="Context">
              <AiContextEditor
                landmarkId="aiContextEditor"
              />
            </DocumentDetailLabel>
          }

          {/* 
        
        TODO: Add stuff here like:

        - Labels
        - Doc Visibility to org
        - Opportunity risk score
        - Actions ???


        */}
          {/* <DocumentActions
          document={document}
          onMenuItemClick={onMenuItemClick}
          childDocs={relatedDocs.toMe.actionDocs.map((action) => action.menuItem)}
        /> */}



          {/* {experimentalEnabled &&
          <AudiencePerspectivesEditor />
        } */}


        </div>

      </SubSectionWrapper>

      {document.type === DocumentSchema.DocumentType.interviewGuide &&
        <InterviewNotesList
          currentDoc={document}
          childDocs={childDocs}
          docsFromMe={relatedDocs.fromMe.allDocs}
          docsToMe={relatedDocs.toMe.allDocs}
          flatMenuItems={flatMenuItems}
          relationsProvider={relationsProvider}
        />
      }

      {document.type === DocumentSchema.DocumentType.assumption &&
        <AssumptionDetailsPanel
          document={document}
        />
      }

      <DocumentIdeasPanel
        currentDoc={document}
      />

      <DocMetadataViewer />

    </div>
  )
} 
