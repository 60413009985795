import { Button, useColorModeValue } from "@chakra-ui/react"
import { VerdiButtonProps } from "./VerdiButtonProps"


export const ButtonPrimary = (props: VerdiButtonProps) => {


  const isLightMode = useColorModeValue(true, false)


  return (
    <Button
      colorScheme="verdiButtonPrimary"
      color="white"
      border={isLightMode ? "1px solid #FFFFFF44" : "1px solid #FFFFFF44"}
      size="sm"
      aria-label={props.hint}
      title={props.hint}
      {...props}
    >
      {props.label}
    </Button>
  )

}
