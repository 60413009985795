import { Alert, AlertDescription, AlertIcon, AlertTitle, UseToastOptions, useToast } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useCallback } from "react"


export const useShowToast = () => {

  const showToast = useToast()

  const showError = useCallback((errorMessage: string, errorDetails?: string) => {
    showToast({
      title: errorMessage,
      description: errorDetails || undefined,
      status: 'error',
      duration: 6000,
      isClosable: true,
    })
  }, [showToast])

  const showWarn = useCallback((errorMessage: string, errorDetails?: string) => {
    showToast({
      title: errorMessage,
      description: errorDetails || undefined,
      status: 'warning',
      duration: 6000,
      isClosable: true,
    })
  }, [showToast])

  const showSuccess = useCallback((title: string, options?: UseToastOptions) => {
    showToast({
      title,
      description: options?.description || undefined,
      status: 'success',
      duration: 6000,
      isClosable: true,
      ...options,
    })
  }, [showToast])

  /** Useful for rendering hyperlinks in the toast, such as "click here to go to the thing you just created" */
  const showToastWithHtml = useCallback((props: RenderToastWithHTMLProps) => {
    showToast({
      status: 'success',
      duration: 10000,
      isClosable: true,
      render: () => renderShowSuccessWithHTML(props),
      ...props.options,
    })
  }, [showToast])


  return {
    showError,
    showSuccess,
    showWarn,
    showToastWithHtml,
  }

}

export type ToastProvider = ReturnType<typeof useShowToast>


export type RenderToastWithHTMLProps = {
  titleHtml: React.ReactNode,
  descriptionHtml?: React.ReactNode,
  options?: UseToastOptions,

}
const renderShowSuccessWithHTML = (props: RenderToastWithHTMLProps) => {

  const { titleHtml, descriptionHtml, options } = props

  return (
    <Alert
      status={options?.status || "success"}
      variant={options?.variant || "solid"}
      borderRadius="md"
      boxShadow="lg"
      css={css`
        & a {
          color: inherit;
          text-decoration: underline;
        }
      `}
    >
      <AlertIcon />

      {titleHtml ? <AlertTitle>{titleHtml}</AlertTitle> : null}

      {titleHtml && descriptionHtml ? <br /> : null}

      {descriptionHtml ? <AlertDescription>{descriptionHtml}</AlertDescription> : null}

    </Alert>
  )

}

