import { DocumentRelationsData } from '../../screens/documentRelation/useDocumentRelationsProvider';


/** Returns a prompt ready list of related document titles */
export const getRelatedDocTitlesForPrompt = (relatedDocs: DocumentRelationsData[]) => {

  if (relatedDocs.length === 0) return ""
  const toReturn = relatedDocs.map(r => r.fromDoc.title)

  return toReturn.join(", ")
}
