import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, useDisclosure } from "@chakra-ui/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { css } from "@emotion/react"
import { Data } from "@verdi/shared-constants"
import { AssumptionConfidenceEditor } from "./AssumptionConfidenceEditor"
import { AssumptionRelevanceEditor } from "./AssumptionRelevanceEditor"
import { AssumptionsDetector } from "./AssumptionsDetector"
import { AssumptionsProvider, CreateAssumptionInput } from "./useAssumptionsProvider"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { getUrlForStandaloneDocument } from "../../routes"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { InputTextWithSuggestions } from "../../components/forms/InputTextWithSuggestions"
import { useAppServices } from "../../components/appServices/useAppServices"
import { VerdiIconArrowBack, VerdiIconArrowForward } from "../../components/icons/VerdiIcons"
import LoadingSpinner from "../../components/LoadingSpinner"



export type SuggestedAssumption = {
  name: string,
  pendingUserReview: boolean,
  frameworkQuestionId?: string
}

type Props = {
  disclosure: ReturnType<typeof useDisclosure>
  assumptionsProvider: AssumptionsProvider
  suggestedAssumptions: SuggestedAssumption[]
  opportunityID: string
  documentId: string
  onClose: () => void
  onCreatedComplete: (assumptionId: string, assumptionName: string, userShouldReview: boolean) => void
  isLoadingInitially: boolean
  showInterruptModal: () => void
  existingAssumptions: Data.AssumptionModel[]
}
export const CreateAssumptionModal = ({
  disclosure,
  assumptionsProvider,
  suggestedAssumptions,
  opportunityID,
  documentId,
  onClose: onCloseCallback,
  onCreatedComplete,
  isLoadingInitially,
  showInterruptModal,
  existingAssumptions,
}: Props) => {


  const appServices = useAppServices()
  const { isOpen } = disclosure
  const [isSaving, setIsSaving] = useState(false);
  const [questionText, setQuestionText] = useState("")
  const [frameworkQuestionId, setFrameworkQuestionId] = useState<string | undefined>(undefined)
  const [answerText, setAnswerText] = useState("")
  const [confidenceLevel, setConfidenceLevel] = useState(Data.AssumptionConfidenceLevel.unknown)
  const [relevanceLevel, setRelevanceLevel] = useState(Data.AssumptionRelevanceLevel.critical)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [createMore, setCreateMore] = useState(true)
  const [numberAnsweredSinceModalOpened, setNumberAnsweredSinceModalOpened] = useState(0)
  const numberRequiredToAnswer = 5

  const [loadingAnswers, setLoadingAnswers] = useState(false)
  const [aiAnswers, setAiAnswers] = useState<string[]>([])


  useEffect(() => {
    if (suggestedAssumptions.length > currentIndex) {
      setQuestionText(suggestedAssumptions[currentIndex].name)
      setFrameworkQuestionId(suggestedAssumptions[currentIndex].frameworkQuestionId)
      setAnswerText("")
    } else if (suggestedAssumptions.length > 0) {
      setCurrentIndex(suggestedAssumptions.length - 1)

    }
    setFocusToAnswerInputText()
  }, [suggestedAssumptions, currentIndex])


  const [questionSinceLastSuggestedAnswers, setQuestionSinceLastSuggestedAnswers] = useState<string>("")
  const suggestAnswers = useCallback(() => {
    console.log("suggestAnswers", { questionText })
    if (!questionText
      || !questionText.trim()
      || questionText === questionSinceLastSuggestedAnswers) { // Question has not changed since last time
      return
    }
    setQuestionSinceLastSuggestedAnswers(questionText)
    setLoadingAnswers(true)
    AssumptionsDetector.getPotentialAnswers(questionText, appServices.getContextForAi)
      .then((answers) => {
        setAiAnswers(answers)
        setLoadingAnswers(false)
      })
  }, [questionText, setAiAnswers, setLoadingAnswers, questionSinceLastSuggestedAnswers])


  const answerInputRef = useRef<HTMLTextAreaElement>(null)
  const setFocusToAnswerInputText = useCallback(() => {
    setTimeout(() => {
      answerInputRef.current?.focus()
    }, 1) // Allow react to catch up from not rendering the input. useTransition did not work :(
  }, [answerInputRef])

  const onAnswerTextInputFocus = useCallback(() => {
    if (answerInputRef.current
      && !loadingAnswers
      && (aiAnswers.length === 0 || questionText !== questionSinceLastSuggestedAnswers)
    ) {
      suggestAnswers()
    }
  }, [loadingAnswers, aiAnswers, suggestAnswers, questionText, questionSinceLastSuggestedAnswers, answerInputRef])


  const clear = useCallback(() => {
    setQuestionText("")
    setFrameworkQuestionId(undefined)
    setAnswerText("")
    setConfidenceLevel(Data.AssumptionConfidenceLevel.unknown)
    setRelevanceLevel(Data.AssumptionRelevanceLevel.critical)
    // setNumberRequiredToAnswer(0)
  }, [])


  const onModalOpen = useCallback(() => {
    setFocusToAnswerInputText()
  }, [setFocusToAnswerInputText])

  const onModalClose = useCallback(() => {
    onCloseCallback()
    setNumberAnsweredSinceModalOpened(0)
  }, [onCloseCallback])


  const onSave = useCallback(() => {
    if (!questionText || questionText.trim() === "") {
      appServices.toast.showError("Please enter a question")
      return
    }

    const newAssumption: CreateAssumptionInput = {
      docTitle: questionText,
      relatedDocumentId: documentId,
      userShouldReview: false,
      assumptionToAdd: {
        confidenceLevel: Boolean(answerText.trim()) && confidenceLevel === 1 ? 2 : confidenceLevel,
        relevanceLevel,
      },
      answerText,
      frameworkQuestionId,
    }

    console.log("newAssumption", { newAssumption })
    setIsSaving(true);
    assumptionsProvider.create(
      newAssumption,
      (newAssumptionDocId: string, assumptionName: string, userShouldReview: boolean) => {
        setIsSaving(false);
        clear();
        onCreatedComplete(newAssumptionDocId, assumptionName, userShouldReview)
        if (!createMore) {
          onModalClose()
        }
        if (numberAnsweredSinceModalOpened >= numberRequiredToAnswer) {
          onModalClose()
          showInterruptModal()
        }
      },
      (error) => { }
    );
    setNumberAnsweredSinceModalOpened(prev => prev + 1)
    // TODO: Break up this monolith of dependency madness!
  }, [questionText, answerText, confidenceLevel, relevanceLevel, documentId, frameworkQuestionId,
    assumptionsProvider, onCreatedComplete, createMore, onModalClose, showInterruptModal, clear,
    numberAnsweredSinceModalOpened, setNumberAnsweredSinceModalOpened, appServices.toast
  ]);


  const onNext = useCallback(() => {
    if (currentIndex < suggestedAssumptions.length - 1) {
      setCurrentIndex(currentIndex + 1)
      setAiAnswers([])
    }
    // TODO: Remove this and just rely on saving new assumptions to trigger
    setNumberAnsweredSinceModalOpened(prev => prev + 1)
  }, [currentIndex, suggestedAssumptions])

  const onPrev = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
      setAiAnswers([])
    }
  }, [currentIndex])


  const goToDraftOpportunity = useCallback(() => {
    const url = getUrlForStandaloneDocument(documentId)
    appServices.router.router.push(url)
    onModalClose()
  }, [onModalClose, documentId, appServices.router.router])


  useEffect(() => {
    if (isOpen) {
      onModalOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (!questionText) {
      setAiAnswers([])
    }
  }, [questionText])


  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth={"800px"}
      >
        <ModalHeader>
          <div>
            <div
              css={css`
              display: flex;
              align-items: center;
            `}
            >
              <IconButtonTertiary
                icon={<VerdiIconArrowBack />}
                aria-label={"previous assumption"}
                onClick={onPrev}
                disabled={currentIndex === 0}
              />
              <span
                css={css`
                  font-size: 12px;
                `}>{currentIndex + 1}/{suggestedAssumptions.length}</span>
              <IconButtonTertiary
                icon={<VerdiIconArrowForward />}
                aria-label={"next assumption"}
                onClick={onNext}
                disabled={currentIndex === suggestedAssumptions.length - 1}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>

          {isLoadingInitially &&
            <div css={css`
              min-height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
            `}>
              <LoadingSpinner
                size="sm"
                label="getting questions to ask ... "
              />
            </div>
          }


          {!isLoadingInitially &&
            <div css={css`
              opacity: ${isSaving ? 0.5 : 1};
            `}>
              <InputTextWithSuggestions
                value={questionText}
                onValueUpdated={setQuestionText}
                placeholder="Question..."
                isLoading={false}
                suggestedValues={[]}
                prefix="Q"
              />

              <div css={css`
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              `}>

                <InputTextWithSuggestions
                  value={answerText}
                  onValueUpdated={setAnswerText}
                  onFocus={onAnswerTextInputFocus}
                  placeholder="Type your answer..."
                  isLoading={loadingAnswers}
                  suggestedValues={aiAnswers}
                  suggestionsLoadingMessage="getting potential answers ..."
                  prefix="A"
                  inputTextRef={answerInputRef}
                />

                <div css={css`
                  display: flex;
                  flex-direction: row;
                  margin-top: 18px;
                  gap: 10px;
                `}>
                  <AssumptionConfidenceEditor
                    level={confidenceLevel}
                    onSelected={(newConfidenceLevel) => setConfidenceLevel(newConfidenceLevel.value)}
                  />
                  <AssumptionRelevanceEditor
                    level={relevanceLevel}
                    onSelected={(newRelevanceLevel) => setRelevanceLevel(newRelevanceLevel.value)}
                  />
                </div>
              </div>
            </div>
          }
        </ModalBody>

        <ModalFooter
          display="flex"
          width="100%"
          justifyContent="space-between"
          gap={2}
        >

          <div css={css`
                font-size: 0.9rem;
          `}>
            {existingAssumptions.length > 2 &&
              <ButtonSecondary
                label="Go Draft the Opportunity"
                marginRight={2}
                onClick={goToDraftOpportunity}
              />
            }
          </div>

          <div
            css={css`
            display: flex;
            align-items: center;
            gap: 10px;
          `}
          >

            <div>
              <span
                css={css`
                font-size: 12px;
                user-select: none;
              `}
              >
                Create More
              </span>
              <Switch
                colorScheme="verdiButtonPrimary"
                isChecked={createMore}
                onChange={(e) => setCreateMore(e.target.checked)}
                size="sm"
                marginLeft={1}
                marginRight={1}
              />
            </div>
            <ButtonPrimary
              label={"Save"}
              onClick={onSave}
              disabled={!questionText || questionText.trim() === ""}
              isLoading={isSaving}
            />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
