import { useDisclosure } from "@chakra-ui/react"
import { useCallback, useRef } from "react"

import { Data } from "@verdi/shared-constants"

import { ConfirmDialog } from "../../components/forms/ConfirmDialog"
import posthog from "../../helpers/posthog"

import { AssumptionsProvider } from "./useAssumptionsProvider"


type Props = {
  assumptionsProvider: AssumptionsProvider,
  onDeleteSuccess?: () => void,
}

export const DeleteAssumptionModal = ({
  assumptionsProvider,
  onDeleteSuccess,
}: Props) => {

  const deleteAssumptionDisclosure = useDisclosure()
  const assumptionPendingDeleteRef = useRef<Data.AssumptionModel>()
  const deleteAssumption = useCallback(async (assumptionToDelete: Data.AssumptionModel) => {
    posthog.capture('deleteAssumption', { flows: ['assumptions'], id: assumptionToDelete.id });
    assumptionPendingDeleteRef.current = assumptionToDelete
    deleteAssumptionDisclosure.onOpen()
  }, [deleteAssumptionDisclosure])

  const deleteAssumptionAfterConfirm = useCallback(async () => {
    const toDelete = assumptionPendingDeleteRef.current
    if (!toDelete) return
    await assumptionsProvider.remove(toDelete)
    posthog.capture('deletedAssumption', { flows: ['assumptions'], id: toDelete.id });
    onDeleteSuccess?.()
  }, [assumptionsProvider, onDeleteSuccess])


  const handleCancelDelete = useCallback(() => {
    const toDelete = assumptionPendingDeleteRef.current
    posthog.capture('cancelAssuptionDelete', { flows: ['assumptions'], id: toDelete?.id });
  }, []);

  return {
    deleteAssumption,
    RenderConfirmDeleteAssumptionDialog: (
      <div>
        <ConfirmDialog
          dialogBodyText="Are you sure you want to permanently delete this assumption?"
          heading="Please confirm"
          disclosure={deleteAssumptionDisclosure}
          onConfirmSuccess={deleteAssumptionAfterConfirm}
          confirmButtonLabel="Continue"
          isDestructiveAction={true}
          onCancel={handleCancelDelete}
        />
      </div>
    )

  }
}
