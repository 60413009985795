import { GlobalCommandType } from "../GlobalCommandType"
import { triggerGlobalCommand } from "../triggerGlobalCommand"
import { VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../GlobalCommandDefinition"
import { AppServices } from "../../appServices/useRegisterAppServices"


const cmdType = GlobalCommandType.navigateTo

/** Navigates to the specified URL */
export const navigateToCommandDefinition: GlobalCommandDefinition<NavigateToCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: NavigateToCommandArgs) => {
    const name = args.excludeNavPrefix ? args.nameOfPage || "" : `Go to ${args.nameOfPage}`
    const toReturn =
      {
        globalCommandType: cmdType,
        name,
        description: '',
        searchName: name.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "navigate",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: NavigateToCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    services.router.router.push(args.urlOfPage)
    onProcessingComplete(true)
  },
  triggerCommand(args) {
    triggerGlobalCommand(cmdType, args)
  },
}

export type NavigateToCommandArgs = {
  nameOfPage: string
  urlOfPage: string
  excludeNavPrefix?: boolean
}


export const navigateToHome = () => {
  navigateToCommandDefinition.triggerCommand?.({
    urlOfPage: "/",
    nameOfPage: "Home",
  })
}

