import { useCallback, useEffect, useState } from "react"
import { AutoResizingInputText } from "./AutoResizingInputText"
import { useThrottle } from "../../utility-hooks/useThrottle"
import { ResponsiveValue, TextareaProps } from "@chakra-ui/react"


type Props = TextareaProps & {
  value: string
  onValueUpdated: (newValue: string) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
  noOfLines?: ResponsiveValue<number> | undefined
  debounceDelay?: number | undefined
  inputTextRef?: React.RefObject<HTMLTextAreaElement>
}
/** Input Text that handles state, debouncing and auto resizing */
export const InputTextWithState = (
  props: Props
) => {

  const {
    value,
    onValueUpdated,
    debounceDelay,
    inputTextRef,
    ...textAreaProps
  } = props

  const [internalValue, setInternalValue] = useState(value)
  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const updateValue = useCallback((newValue: string) => {
    setInternalValue(newValue)
    updateValueDebounced(newValue)
  }, [setInternalValue])

  const updateValueDebounced = useThrottle((newValue: string) => {
    console.log("updated debounce val = ", newValue)
    onValueUpdated(newValue)
  }, debounceDelay || undefined)


  return (
    <AutoResizingInputText
      ref={inputTextRef || undefined}
      {...textAreaProps}
      value={internalValue}
      onChange={(e) => updateValue(e.target.value)}
      noOfLines={props.noOfLines}
    />
  )
}
