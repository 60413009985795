import { FormControl, FormLabel, Switch, SwitchProps, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import React from "react"


type SwitchPrimaryProps = SwitchProps & {
  label: String
  children?: React.ReactNode
}
export const SwitchPrimary = (props: SwitchPrimaryProps) => {

  const { label, children, ...propsToSpread } = props
  const isLightMode = useColorModeValue(true, false)


  return (

    <FormControl display='flex' alignItems='center'
      css={css`
        width: fit-content;
        `}>
      <FormLabel css={css`
        display: flex;
        align-items: center;
        gap: 7px;
        text-transform: none;
        padding: 10px 15px;
        margin: 0;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          background-color: ${isLightMode ?
          "var(--chakra-colors-gray-100)"
          : "var(--chakra-colors-whiteAlpha-200)"};
        }
      `}>
        <Switch
          colorScheme="verdiButtonPrimary"
          {...propsToSpread}
        />
        <span>
          {label}
        </span>

        {props.children !== undefined &&
          <div>
            {props.children}
          </div>
        }
      </FormLabel>
    </FormControl>
  )

}
