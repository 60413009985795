/**
 * @generated SignedSource<<4b3365cc0732edfdcc5eb82eeb52094d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganizationInvitationListQuery$variables = {};
export type OrganizationInvitationListQuery$data = {
  readonly organizationInvitationConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly expiresAt: any;
        readonly id: string;
        readonly invitedEmailAddress: string;
      };
    }>;
  };
};
export type OrganizationInvitationListQuery = {
  response: OrganizationInvitationListQuery$data;
  variables: OrganizationInvitationListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QueryOrganizationInvitationConnection",
    "kind": "LinkedField",
    "name": "organizationInvitationConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryOrganizationInvitationConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationInvitation",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "invitedEmailAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiresAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationInvitationListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationInvitationListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "090382c7680f338cfd8744d71f1e11a3",
    "id": null,
    "metadata": {},
    "name": "OrganizationInvitationListQuery",
    "operationKind": "query",
    "text": "query OrganizationInvitationListQuery {\n  organizationInvitationConnection {\n    edges {\n      node {\n        invitedEmailAddress\n        createdAt\n        expiresAt\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7649066edc08898b3e761e5e3398f953";

export default node;
