import graphql from 'babel-plugin-relay/macro'
import {
  useUpdateOpportunityMutation,
  UpdateOpportunityInput,
  useUpdateOpportunityMutation$data,
} from './__generated__/useUpdateOpportunityMutation.graphql';
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation';

const mutationQL = graphql`
  mutation useUpdateOpportunityMutation (
    $input: UpdateOpportunityInput!
  ) {
   updateOpportunity(input: $input){
    id
    title 
    description 
   }    
  }
`
export function useUpdateOpportunity(
  onCreated?: (response: useUpdateOpportunityMutation$data['updateOpportunity']) => void
) {
  const [commit, isSaving] = useErrorHandledMutation<useUpdateOpportunityMutation>(mutationQL)
  const updateOpportunity = useCallback((
    input: UpdateOpportunityInput
  ) => {
    commit({
      variables: {
        input,
      },
      optimisticResponse: {
        updateOpportunity: {
          title: input.title,
          description: input.description,
          id: input.opportunityId,
        }
      },
      onCompleted: (response) => {
        if (onCreated) {
          onCreated(response.updateOpportunity)
        }
      }
    })
  }, [])
  return [updateOpportunity, isSaving] as const
}
