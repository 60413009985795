
import { useEffect } from 'react';

type KeyDownHandler = (event: KeyboardEvent) => void;

/** Runs a given function on key press, for as long as the component is rendered. 
 * 
 * Note: If rendering many of the same component, such as via map(), use the alternative "onFocus" hook below.
 * */
export function useKeyDownHandler(handler: KeyDownHandler): void {
  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => handler(event)
    window.addEventListener('keydown', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyUp);
    };
  }, [handler]);
}

/** Runs a given function on key press, adding the listeners ONLY when componentHasFocus = true. */
export const useKeyDownHandlerOnFocus = (handler: KeyDownHandler, componentHasFocus: boolean): void => {
  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent) => handler(event)
    if (componentHasFocus) {
      window.addEventListener('keydown', onKeyUp);
    } else {
      window.removeEventListener('keydown', onKeyUp);
    }

    return () => {
      window.removeEventListener('keydown', onKeyUp);
    };

  }, [handler, componentHasFocus]);
}
