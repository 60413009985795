import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { getFillInSectionPrompt } from "../../../../ai/prompts/docDrafter/fillInSectionPrompt"
import { getCurrentAppState } from "../../../../state/store"


const cmdType = GlobalCommandType.fillInSection

/** Fills in the current section */
export const fillInSectionCommandDefinition: GlobalCommandDefinition<FillInSectionCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: FillInSectionCommandArgs) => {

    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Fill-in Current Section',
        searchName: 'fill in current section',
        // maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: FillInSectionCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // JSJ: trust me, you and I both wish this wasn't here, but its necessary,
    // deleting the command phrase was causing an update while we were trying to call this code when using the /command.
    setTimeout(async () => {
      const { servicesForCurrentDoc, companyContextProvider } = services
      if (!servicesForCurrentDoc) {
        console.error("fillInSection: Could not find servicesForCurrentDoc")
        return
      }
      if (!servicesForCurrentDoc?.aiCoach.requestDocumentEdit) {
        console.error("fillInSection: Could not find aiCoach.requestDocumentEdit")
        return
      }
      const { getDocumentContextForAi } = servicesForCurrentDoc
      const docContext = getDocumentContextForAi()
      const companyContext = await companyContextProvider.getCompanyContextForPrompt()
      const futureSectionTitles = getCurrentAppState().suggestionsForDoc.futureSectionTitles || []
      console.log("fillInSection: futureSectionTitles from Redux ", {
        futureSectionTitles
      })

      const prompt = getFillInSectionPrompt(docContext, companyContext, futureSectionTitles)

      await servicesForCurrentDoc.aiCoach.requestDocumentEdit(
        {
          prompt,
          insertAt: InsertPosition.replaceAllChildrenOfSection,
          includeCompanyContext: false,
          includeDocDetails: false,
          includeDocMetadata: false,
          includeRelatedContext: false,
          isInline: true,
        }
      )

      onProcessingComplete(true)
    }, 1000)

  },
}

type FillInSectionCommandArgs = {
  // insertAt: InsertPosition
  // sectionTitle: string
}
