import { DocumentMetadataFromAi } from "@verdi/shared-constants";
import { DynamicPrompt } from "./useAiCoach";

/** Caches various pieces of data from calls that the AiCoach makes */
export class AiCoachCache {
  private static _singletonInstance: AiCoachCache;

  public static get SingletonInstance() {
    if (!this._singletonInstance) {
      this._singletonInstance = new AiCoachCache();
    }
    return this._singletonInstance;
  }

  constructor() {}

  private docMetadataCache = new GenericCache<DocumentMetadataFromAi>();
  public setDocMetadata(documentId: string, data: DocumentMetadataFromAi) {
    this.docMetadataCache.setEntry(documentId, data);
  }
  public getDocMetadata(documentId: string) {
    return this.docMetadataCache.getEntry(documentId);
  }

  private recommendedPromptsCache = new GenericCache<DynamicPrompt[]>();
  public setRecommendedPrompts(documentId: string, data: DynamicPrompt[]) {
    this.recommendedPromptsCache.setEntry(documentId, data);
  }
  public getRecommendedPrompts(documentId: string) {
    return this.recommendedPromptsCache.getEntry(documentId);
  }

  private relatedContextFromOtherDocsCache = new GenericCache<string>();
  public setRelatedContextFromOtherDocs(documentId: string, data: string) {
    this.relatedContextFromOtherDocsCache.setEntry(documentId, data);
  }
  public getRelatedContextFromOtherDocs(documentId: string) {
    return this.relatedContextFromOtherDocsCache.getEntry(documentId);
  }
}

type AiCoachCacheEntry = {
  ttl: number;
  data: any;
};

class GenericCache<T> {
  private mapOfData: Map<string, AiCoachCacheEntry> = new Map();
  private expiresAfterMs: number;

  constructor(expiresAfterMinutes = 5) {
    this.expiresAfterMs = 1000 * 60 * expiresAfterMinutes;
  }

  private getTtl() {
    return Date.now() + this.expiresAfterMs;
  }

  public setEntry(documentId: string, entryData: T) {
    const entry: AiCoachCacheEntry = {
      data: entryData,
      ttl: this.getTtl(),
    };
    this.mapOfData.set(documentId, entry);
  }

  public getEntry(documentId: string): T | undefined {
    const entry = this.mapOfData.get(documentId);
    if (entry && entry.ttl > Date.now()) {
      return entry.data as T;
    }
    return undefined;
  }
}
