import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { writeToDocBodyCommandDefinition } from "./writeToDocBody"
import { DocumentOutlineEntry } from "../../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"


const cmdType = GlobalCommandType.writeToDocBodyDebugger

/** Writes raw text at the current position in the current Prosemirror document  */
export const writeToDocBodyDebuggerCommandDefinition: GlobalCommandDefinition<WriteToDocBodyDebuggerCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: WriteToDocBodyDebuggerCommandArgs) => {
    const title = "Write to doc body debugger"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "debug",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: WriteToDocBodyDebuggerCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.info("writeToDocBodyDebugger()", { args })

    // BUILD YOUR docOutline here
    const documentOutline: DocumentOutlineEntry[] = [
      {
        type: "heading",
        content: "This is a [[Heading 1]] dude!",
        children: [
          { type: "text", content: "This is a paragraph with a [[ placeholder ]] to click on." },
          { type: "text", content: "Yet another sentence." },
          { type: "text", content: "My name is [[ First Name ]] [[ Last Name ]] and I like to [[ favorite activity ]] on Fridays." },

        ],
      },
    ]

    writeToDocBodyCommandDefinition.triggerCommand?.({
      documentOutline,
      insertPosition: InsertPosition.AsNextRootNode,
      shouldMoveCaretToNextNode: true,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: WriteToDocBodyDebuggerCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type WriteToDocBodyDebuggerCommandArgs = {
}
