import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IdeaNodeData } from '../components/mindMap/internals/nodeTypes/idea/IdeaNode'


export type SuggestedIdeaNodeData = {
  id: string
  parentDocId: string
  data: IdeaNodeData
}


interface InternalState {
  suggestions: SuggestedIdeaNodeData[]
}
const initialState: InternalState = {
  suggestions: []
}

/** Stores a copy of node suggestions tied to specific documents, 
 *  so they can be display when a graph is initialized 
 */
export const mindMapSuggestionsSlice = createSlice({
  name: 'mindMapSuggestions',
  initialState,
  reducers: {
    addSuggestions: (state, action: PayloadAction<SuggestedIdeaNodeData[]>) => {
      state.suggestions.push(...action.payload)
    },
    removeSuggestion: (state, action: PayloadAction<{ suggestionId: string }>) => {
      state.suggestions = state.suggestions.filter(s => s.id !== action.payload.suggestionId)
    }
  },
  selectors: {
    getAll: (state) => state.suggestions,
    getSuggestionsForDoc: (state, docId: string) => {
      return state.suggestions.filter(s => s.parentDocId === docId)
    },
  }
})


export const MindMapSuggestionsState = {
  ...mindMapSuggestionsSlice.actions,
  ...mindMapSuggestionsSlice.selectors,
}

export default mindMapSuggestionsSlice.reducer
