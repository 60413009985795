import { Select, SelectProps, useColorModeValue } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ColorValues, ConditionalColorMapMode, DefaultColorValues, getConditionalColorsFromValue } from "./ConditionalColorMapUtils"


type Props = SelectProps & {
  colorMapMode?: ConditionalColorMapMode,
}

export const SelectDropdown = (rawProps: Props) => {

  const { colorMapMode, ...domProps } = rawProps
  const isLightMode = useColorModeValue(true, false)

  const [colors, setColors] = useState<ColorValues>(DefaultColorValues)
  useEffect(() => {
    if (!colorMapMode) return
    const newColors = getConditionalColorsFromValue(domProps.value as string, colorMapMode, isLightMode)
    setColors(newColors)
  }, [colorMapMode, domProps.value, isLightMode])


  return (
    <Select
      borderRadius={20}
      size="sm"
      {...domProps}
      backgroundColor={colors.bg}
    >
      {domProps.children}
    </Select>
  )

}
