import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { makePlaceholderReplacementsAiCall } from "../../../../../ai/prompts/docDrafter/PlaceholderReplacementAiCall"
import { SuggestionsForPlaceholdersState } from "../../../../../state/suggestions/suggestionsForPlaceholdersSlice"
import { dispatch, getCurrentAppState } from "../../../../../state/store"


const cmdType = GlobalCommandType.loadCurrentPlaceholderSuggestions

/** Loads suggestions for the current placeholder */
export const getPlaceholderSuggestionsCommandDefinition: GlobalCommandDefinition<GetPlaceholderSuggestionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: GetPlaceholderSuggestionsCommandArgs) => {
    const title = args.title
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        shouldKeepOpenOnRun: true,
        iconType: args.lifecycleState === "load-new" ? "aiSuggestion" : "refresh",
      } as VerdiCommand

    return toReturn
  },
  triggerCommand: (args: GetPlaceholderSuggestionsCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  },
  processCommand: async (
    args: GetPlaceholderSuggestionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (!currentBlockContext) {
      console.warn('getPlaceholderSuggestions: No currentBlockContext found in servicesForCurrentDoc')
      onProcessingComplete(false)
      return
    }

    const currentPlaceholderText = currentBlockContext.currentPlaceholderAttrs?.text
    if (!currentPlaceholderText || !currentBlockContext.currentPlaceholderAttrs) {
      console.warn('getPlaceholderSuggestions: No currentPlaceholderText found in servicesForCurrentDoc')
      onProcessingComplete(false)
      return
    }

    const attributes = currentBlockContext.currentPlaceholderAttrs
    const { RemoveOnly, LinkToRelatedDoc, } = DocumentSchema.NodeExtensions.PlaceholderBehaviorTypes

    if (attributes.behaviors?.includes(RemoveOnly)) {
      onProcessingComplete(true)
      return
    }

    if (attributes.behaviors?.includes(LinkToRelatedDoc)) {
      // TODO: Decide if we want to load suggested frameworks here
      onProcessingComplete(true)
      return
    }


    if (args.shouldAbortIfAlreadyLoaded) {
      const existing = SuggestionsForPlaceholdersState.getAll(getCurrentAppState())
        .find(s => s.placeholderText === currentPlaceholderText)
      if (existing) {
        onProcessingComplete(true)
        return
      }
    }

    dispatch(SuggestionsForPlaceholdersState.setForPlaceholderText({
      placeholderText: currentPlaceholderText,
      suggestions: [],
      isLoading: true,
    }))

    const suggestions = await makePlaceholderReplacementsAiCall(services.getContextForAi)

    dispatch(SuggestionsForPlaceholdersState.setForPlaceholderText({
      placeholderText: currentPlaceholderText,
      suggestions,
      isLoading: false,
    }))

    onProcessingComplete(true)
  },
}

type GetPlaceholderSuggestionsCommandArgs = {
  title: string
  lifecycleState: "load-new" | "refresh"
  shouldAbortIfAlreadyLoaded?: boolean
}
