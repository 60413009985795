import { Editor, NodeViewProps } from "@tiptap/core";
import { Fragment, Slice } from "@tiptap/pm/model";
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants";
import { EditorView } from "@tiptap/pm/view";
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { writeContentToDocBody } from "./writeToDocBody";
import { InsertPosition } from "../../../../ai/documents/InsertPosition";


const cmdType = GlobalCommandType.addDocReference

/** Inserts a DocReference Node in the current document body at the selection / caret */
export const addDocReferenceCommandDefinition: GlobalCommandDefinition<AddDocReferenceCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddDocReferenceCommandArgs) => {
    const title = args.documentTitle
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "debug",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: AddDocReferenceCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('addDocReference: No editor found in servicesForCurrentDoc')
      return
    }

    addDocReferenceInlineNode(editor, {
      docId: args.documentId,
      textToDisplay: args.documentTitle,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: AddDocReferenceCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type AddDocReferenceCommandArgs = {
  documentId: string,
  documentTitle: string,
}



/** Inserts a doc reference node */
export const addDocReferenceInlineNode = (
  editor: Editor,
  attrs: DocumentSchema.NodeExtensions.DocReferenceInlineNodeAttributeValues,
) => {

  // const shouldAddBlankLineAfter = true
  // const selection = editor.state.selection

  const docReferenceNode = ProsemirrorNodes.makeDocReferenceInlineSection(attrs)

  writeContentToDocBody({
    contentToInsert: docReferenceNode,
    editor,
    insertPosition: InsertPosition.currentCursorSelection,
  })

  writeContentToDocBody({
    contentToInsert: ProsemirrorNodes.makeFreeTextBlock(""), // to ensure there is a blank line after
    editor,
    insertPosition: InsertPosition.AsNextRootNode,
  })
}


/** OLD Inserts a doc reference node */
// export const addDocReferenceNode = (editor: Editor, docType: DocumentSchema.DocumentType, docId?: string) => {

//   const insertSnippet = ProsemirrorNodes.makeDocReferenceSection(
//     "Some text for the doc reference",
//     {
//       docId,
//       docType: docType.toString(),
//     }
//   );
//   const fragment = Fragment.fromJSON(editor.view.state.schema, [
//     insertSnippet,
//     ProsemirrorNodes.makeFreeTextBlock(""), // to ensure there is a blank line after
//   ]);
//   editor.chain().focus().deleteToLastSlash().run();
//   editor
//     .chain()
//     .command(({ tr, editor }) => {
//       const insertPos = editor.state.selection.$head.pos;
//       tr.replace(insertPos, insertPos, new Slice(fragment, 0, 0));
//       // view.dispatch(transaction)
//       return true;
//     })
//     .run();
//   // TODO: How to add blank line after the docReference node?
//   editor.chain().moveCursorToNextNode().run();

//   console.log("Added the docReference node")
// }
