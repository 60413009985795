import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { EditDocumentContextTemplate_document$key } from './__generated__/EditDocumentContextTemplate_document.graphql';
import {
  Button, useDisclosure,
} from '@chakra-ui/react'
import { AiPromptFormModal } from './AiPromptFormModal';
import { css } from '@emotion/react';

const fragmentQL = graphql`
  fragment EditDocumentContextTemplate_document on Document {
     id
     title
     aiPrompts {
      isDocContext
      ...useUpdateAiPromptMutation_documentAiPrompt
      ...AiPromptPreview_otherBlockLevelPrompts
     }
  }
`

type Props = { document: EditDocumentContextTemplate_document$key; }

export function EditDocumentContextTemplate({ document: documentKey }: Props) {
  const disclosure = useDisclosure()
  const document = useFragment(fragmentQL, documentKey)
  const existingContextTemplate = document.aiPrompts.find(prompt => prompt.isDocContext)
  const otherBlockLevelPrompts = document.aiPrompts.filter(prompt => !prompt.isDocContext)
  return (

    <>
      <Button
        css={css`
        ${existingContextTemplate ?
            `background: -webkit-radial-gradient(
              50% 50%,
              circle closest-corner,
              #80c9ff99,
              #bd80ff55
            );`
            : `#80c9ff`}
        `}
        onClick={disclosure.onOpen}
        marginTop={5}
      >
        Edit Doc Context
      </Button>
      <AiPromptFormModal
        formType={existingContextTemplate ? 'update' : 'create'}
        isDocContext={true}
        key={'isDocContext'}
        documentTitle={document.title || ""}
        documentId={document.id}
        aiPrompt={existingContextTemplate}
        disclosure={disclosure}
        otherBlockLevelPrompts={otherBlockLevelPrompts}
      />
    </>

  )
}
