import { Extension } from "@tiptap/react";
import {
  ReplaceAroundStep,
  liftTarget,
} from "prosemirror-transform";
import {
  Slice,
  Fragment,
  NodeRange,
  NodeType,
  ResolvedPos,
} from "prosemirror-model";
import { EditorState, Selection, TextSelection } from "prosemirror-state";

import { findParentNestableNodeClosestToPos } from "../../utils/findParentNestableNode";
import runUntil from "../../utils/runUntil";
import VeNode from "../../VeNode";

import { cursorIsAtBeginning } from "./PressEnter";

const liftChildren = (
  state: EditorState,
  type: NodeType,
  range: NodeRange,
  target: number
) => {
  const tr = state.tr;
  // end of list to add to
  let end = range.end;
  // end of parent list
  let endOfList = range.$to.end(range.depth);
  if (end < endOfList) {
    const newParent = range.$from.node(range.depth + 1);
    const parentIsMissingContentListWrapper =
      newParent.maybeChild(1)?.type.name !== "contentList";

    // There are siblings after the lifted items, which must become
    // children of the last item
    const from = end - (parentIsMissingContentListWrapper ? 1 : 2);
    const to = endOfList;
    const gapFrom = end;
    const gapTo = endOfList;
    const content = Fragment.from(
      type.create(newParent.attrs, range.parent.copy(), newParent.marks)
    );
    const openStart = parentIsMissingContentListWrapper ? 1 : 2;
    const openEnd = 0;
    const slice = new Slice(content, openStart, openEnd);
    const insert = parentIsMissingContentListWrapper ? 1 : 0;
    tr.step(
      new ReplaceAroundStep(from, to, gapFrom, gapTo, slice, insert, true)
    );
    range = new NodeRange(
      tr.doc.resolve(range.$from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    );
  }
  return range;
};

const actuallyLiftNestableBlock = (
  state: EditorState,
  type: NodeType,
  range: NodeRange,
  target: number
) => {
  let range2 = liftChildren(state, type, range, target);
  let { $from, $to, depth } = range2;
  let gapStart = $from.before(depth + 1);
  let gapEnd = $to.after(depth + 1);
  let start = gapStart;
  let end = gapEnd;
  let before = Fragment.empty,
    openStart = 0;
  for (let d = depth, splitting = false; d > target; d--)
    // $from.index(d) > 0) means  kill wrapper if empty (stock prose-mirror code)
    // $from.index(d) > -1) means  kill wrapper if empty (modification in this copy)
    if (splitting || $from.index(d) > 0) {
      // this is literally the only thing that changed (it was a 0)
      splitting = true;
      before = Fragment.from($from.node(d).copy(before));
      openStart++;
    } else {
      start--;
    }
  let after = Fragment.empty,
    openEnd = 0;
  for (let d = depth, splitting = false; d > target; d--)
    if (splitting || $to.after(d + 1) < $to.end(d)) {
      splitting = true;
      after = Fragment.from($to.node(d).copy(after));
      // console.log('after', after.toJSON())
      openEnd++;
    } else {
      end++;
    }
  const fragment = new Slice(before.append(after), openStart, openEnd);
  const insert = before.size - openStart;
  return state.tr.step(
    new ReplaceAroundStep(
      start,
      end,
      gapStart,
      gapEnd,
      new Slice(before.append(after), openStart, openEnd),
      before.size - openStart,
      true
    )
  );
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    PressTab: {
      tabOnSingleNode: () => ReturnType;
      tabOnSiblings: () => ReturnType;
      tabOnMultipleNodes: () => ReturnType;
      liftNestableBlock: () => ReturnType;
      moveCursorToPreviousNode: (onlyIfAtBeginning?: boolean) => ReturnType;
      moveCursorToNextNode: () => ReturnType;
      moveCursorToAdjacentNode: (
        direction: "backwards" | "forwards"
      ) => ReturnType;
      actuallyLiftNestableBlock: () => ReturnType;
    };
  }
}

export const PressTab = Extension.create<{}>({
  name: "PressTab",
  addKeyboardShortcuts() {
    return {
      "Shift-Tab": ({ editor }) => {
        editor.commands.liftNestableBlock();
        return true;
      },
      Tab: ({ editor }) => {
        runUntil(
          (result: boolean) => !!result,
          () => editor.commands.tabOnSingleNode(),
          () => editor.commands.tabOnSiblings()
        );

        return true;
      },
    };
  },
  addCommands() {
    return {
      tabOnSingleNode:
        () =>
        ({ state }) => {
          const { $from, $to } = state.selection;
          const { nodes: nodeTypes} = state.schema;

          const node = VeNode.fromResolvedPosition($from)?.findParentWithType(
            state,
            VeNode.NESTABLE_TYPES
          );

          const toNode = VeNode.fromResolvedPosition($to)?.findParentWithType(
            state,
            VeNode.NESTABLE_TYPES
          );

          // our selection spans multiple nodes so this rule does not apply
          if (!toNode || (!node?.isEqualTo(toNode) && !node?.isAncestorOf(toNode))) {
            return false;
          }

          const previousSibling = node.findPreviousNestableSibling(state);

          if(!previousSibling){
            return false;
          }

          const [title] = previousSibling.findChildrenWithType(state, ['title']);
          const [currentContentList] = previousSibling.findChildrenWithType(state, ['contentList']);

          const {start, end} = node.bounds();

          const fromOffset = $from.pos - start ;
          const length = $to.pos - $from.pos;

          state.apply(state.tr.delete(start, end));

          if(currentContentList){
            state.apply(state.tr.insert(currentContentList.bounds().end -1, node.node()))
          } else {
            state.apply(
              state.tr.insert(
                title.bounds().end,  
                nodeTypes.contentList.create(null, [
                  node.node()
                ])
              )
            )
          }

          const newStart = (currentContentList ? currentContentList.bounds().end - 1  : title.bounds().end + 1) + fromOffset;

          state.apply(state.tr.setSelection(TextSelection.create(state.doc, newStart, newStart + length)))

          return true;
        },
      tabOnSiblings: () => ({state}) => {
        const { $from, $to } = state.selection;
        const { nodes: nodeTypes} = state.schema;

        const fromNode = VeNode.fromResolvedPosition(
          $from
        )?.findParentWithType(state, VeNode.NESTABLE_TYPES);

        const toNode = VeNode.fromResolvedPosition($to)?.findParentWithType(
          state,
          VeNode.NESTABLE_TYPES 
        );

        if (!fromNode || !toNode || !fromNode.isSiblingOf(state, toNode)) {
          return false;
        }

        const previousSibling = fromNode.findPreviousNestableSibling(state);

        if(!previousSibling){
          return false;
        }

        const {start} = fromNode.bounds();
        const {end} = toNode.bounds();

        const fragment = state.doc.slice(start, end);

        const [title] = previousSibling.findChildrenWithType(state, ['title']);
        const [currentContentList] = previousSibling.findChildrenWithType(state, ['contentList']);

        const fromOffset = $from.pos - start;
        const length = $to.pos - $from.pos;

        state.apply(state.tr.delete(start, end));

        if(currentContentList){
          state.apply(state.tr.insert(currentContentList.bounds().end -1, fragment.content))
        } else {
          state.apply(
            state.tr.insert(
              title.bounds().end,  
              nodeTypes.contentList.create(null, 
                fragment.content
              )
            )
          )
        }

        const newStart = (currentContentList ? currentContentList.bounds().end - 1  : title.bounds().end + 1) + fromOffset;

        state.apply(state.tr.setSelection(TextSelection.create(state.doc, newStart, newStart + length)))

        return true;
      },
      liftNestableBlock:
        () =>
        ({ commands, state, dispatch }) => {
          let { $head, $from, $to } = state.selection as Selection;

          const nestedNodeValidParents = ["doc", "contentList"];
          const range = $from.blockRange($to, (node) =>
            nestedNodeValidParents.includes(node.type.name)
          );
          const parent = findParentNestableNodeClosestToPos($head);
          if (!parent) return false;
          if (!range) return false;
          // todo: do we need liftTargetNestableBlock anymore?
          let target = liftTarget(range!);
          if (typeof target !== "number") return false;
          if (dispatch) {
            dispatch(
              actuallyLiftNestableBlock(
                state,
                parent?.node.type,
                range,
                target
              ).scrollIntoView()
            );
          }
          return true;
        },
      moveCursorToAdjacentNode:
        (direction) =>
        ({ state, dispatch }) => {
          const currentCursorPos = state.selection.$anchor;
          let adjacentPosition: ResolvedPos;
          if (direction === "backwards") {
            const beforeCursor = currentCursorPos.before(
              currentCursorPos.depth - 1
            );
            adjacentPosition = state.doc.resolve(beforeCursor);
          } else {
            const afterCursor = currentCursorPos.after(currentCursorPos.depth);
            adjacentPosition = state.doc.resolve(afterCursor);
          }
          if (!adjacentPosition) return false;
          const dir = direction === "backwards" ? -1 : 1;
          const newSelection = Selection.findFrom(adjacentPosition, dir, true);
          // console.log('nearSelection', newSelection?.$head.pos, state.selection.$head.pos, state.selection.$anchor, newSelection)
          if (!newSelection) return false;
          if (dispatch) {
            dispatch(state.tr.setSelection(newSelection));
          }
          return true;
        },
      moveCursorToNextNode:
        () =>
        ({ chain }) => {
          return chain().moveCursorToAdjacentNode("forwards").run();
        },
      moveCursorToPreviousNode:
        (onlyIfAtBeginning = false) =>
        ({ chain }) => {
          if (onlyIfAtBeginning && !cursorIsAtBeginning) return false;
          return chain().moveCursorToAdjacentNode("backwards").run();
        },
    };
  },
});
