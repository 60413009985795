import { Button, Stack, useColorModeValue } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { usePaginationFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useNewDocumentMutation } from "../documents/add/useAddNewDocument"
import { DocumentTemplateSideMenu_query$key } from "./__generated__/DocumentTemplateSideMenu_query.graphql";
import { DocumentTemplateSideMenuItem } from "./DocumentTemplateSideMenuItem";
import { menuItemSelectedBackgroundColors, menuItemSelectedColors } from "../../components/ColorModeValues";
import { useAppServices } from "../../components/appServices/useAppServices";
import { VerdiIconAdd } from "../../components/icons/VerdiIcons";


const fragmentQl = graphql`
fragment DocumentTemplateSideMenu_query on Query 
@refetchable(queryName: "DocumentTemplateSideMenu_PaginationQuery"){
  documentTemplateConnection (
    first: $first
    after: $after
    showUnpublished: true
  ) 
  @connection(key:"DocumentTemplateSideMenuPagination_documentTemplateConnection"){
    __id
    edges {
      node {
        id
        document{
          id
        }
        ...DocumentTemplateSideMenuItem_documentTemplate
      }
    }
  }
}
`

type Props = {
  query: DocumentTemplateSideMenu_query$key
  currentDocumentId: string
}
export function DocumentTemplateSideMenu(props: Props) {

  const appServices = useAppServices()
  const { data, loadNext, hasNext } = usePaginationFragment(fragmentQl, props.query)

  const [copyDocument, isSaving] = useNewDocumentMutation(false, undefined, (response) => {
    if (!response?.createDocument?.id) {
      return console.error("No document id returned from createDocument mutation")
    }
    console.log("response", response)
    window.location.href = `/templates/documents/${response?.createDocument.id}`
  }, [data.documentTemplateConnection.__id])
  const addDocument = () => {
    const templateNumber = data.documentTemplateConnection.edges.length + 1
    copyDocument({
      type: "document",
      createAsTemplate: true,
      description: `Description for template ${templateNumber}`,
      title: `Template ${templateNumber}`
    }, appServices.menuStructureProvider)


  }

  const selectedMenuItemBackground = useColorModeValue(menuItemSelectedBackgroundColors.light, menuItemSelectedBackgroundColors.dark);
  const selectedMenuItemColor = useColorModeValue(menuItemSelectedColors.light, menuItemSelectedColors.dark);


  return (
    <Stack css={css`
      margin-left: 20px;
    `}>

      {data.documentTemplateConnection.edges?.map((item, index) =>
        <DocumentTemplateSideMenuItem
          key={item.node.id}
          item={item.node}
          index={index}
          isSelected={item.node.document.id === props.currentDocumentId}
          selectedMenuItemBackground={selectedMenuItemBackground}
          selectedMenuItemColor={selectedMenuItemColor}
        />

      )}

      <Button onClick={addDocument}
        isLoading={isSaving}
        variant="ghost">
        <VerdiIconAdd boxSize={2} mr={1} />
        Add a new template
      </Button>

    </Stack>
  )

}
