import { Node } from "@tiptap/pm/model"
import { DocumentSchema } from "@verdi/shared-constants"
import { findChildren } from "prosemirror-utils"
import { PlaceholderSuggestionInfo } from "../../state/suggestions/suggestionsForPlaceholdersSlice"


/** Returns all placeholder nodes in the document */
export const getAllPlaceholderNodes = (doc: Node): PlaceholderSuggestionInfo[] => {

  const placeholderType = DocumentSchema.VerdiNodeTypes.PlaceholderInline
  const titleNodes = findChildren(doc, (n) => n.type.name === "title", true)
  const placeholderInfo: PlaceholderSuggestionInfo[] = []

  for (const n of titleNodes) {

    const placeholderNodes = findChildren(n.node, n => n.type.name === placeholderType)
    if (!placeholderNodes) continue
    const lineText = DocumentSchema.getPlainTextFromTitleNode(n.node)

    for (const p of placeholderNodes) {
      const placeholderText = p.node.attrs.text
      placeholderInfo.push({
        placeholderText,
        lineText,
      })
    }
  }

  return placeholderInfo
}


