/**
 * @generated SignedSource<<74710b3d6c9179b29f63e032c06db2fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateOpportunityInput = {
  description?: string | null;
  initialStepJson?: string | null;
  parentDocId?: string | null;
  status?: number | null;
  title?: string | null;
};
export type useCreateOpportunityMutation$variables = {
  input: CreateOpportunityInput;
  updateConnectionIds: ReadonlyArray<string>;
};
export type useCreateOpportunityMutation$data = {
  readonly createOpportunity: {
    readonly description: string | null;
    readonly id: string;
    readonly mainDoc: {
      readonly id: string;
      readonly parentDoc: {
        readonly id: string;
      } | null;
    } | null;
    readonly status: number;
    readonly title: string | null;
  };
};
export type useCreateOpportunityMutation = {
  response: useCreateOpportunityMutation$data;
  variables: useCreateOpportunityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateConnectionIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "Opportunity",
  "kind": "LinkedField",
  "name": "createOpportunity",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "mainDoc",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "parentDoc",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateOpportunityMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateOpportunityMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createOpportunity",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "updateConnectionIds"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "OpportunityEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "e1413696a8e21e5833035940424d7daf",
    "id": null,
    "metadata": {},
    "name": "useCreateOpportunityMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateOpportunityMutation(\n  $input: CreateOpportunityInput!\n) {\n  createOpportunity(input: $input) {\n    id\n    title\n    description\n    status\n    mainDoc {\n      id\n      parentDoc {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1395b4e208b1ee4997e2deff53d3e32";

export default node;
