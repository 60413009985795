import { useMemo } from "react"
import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary"
import { getCurrentOrgPlan, getCurrentOrgPlanHasLoaded } from "../../../state/PlanSlice"
import { useAppSelector } from "../../../state/storeHooks"
import { formatDistance } from "date-fns"
import { css } from "@emotion/react"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { useFeatureFlags } from "../../../utility-hooks/useFeatureFlags"


export const NagUserAboutPlan = () => {

  const { router, pageLayoutState, } = useAppServices()
  const { screenWidthIsSmall } = pageLayoutState


  const hasLoaded = useAppSelector(getCurrentOrgPlanHasLoaded)
  const plan = useAppSelector(getCurrentOrgPlan)

  // TODO: Check if is OrgAdmin that can manage billing

  const planStatusMessage = useMemo(() => {
    if (!hasLoaded) {
      return ""
    }
    const trialHasExpired = plan?.freeTrialExpiresAt && new Date(plan.freeTrialExpiresAt) < new Date()
    if (!plan || !plan?.isActive || trialHasExpired) {
      return "Want more?"
    }
    if (plan?.freeTrialExpiresAt) {
      const daysUntil = formatDistance(new Date(plan.freeTrialExpiresAt), new Date())
      return `${daysUntil} remain on your free trial`
    }

    return ""
  }, [plan, hasLoaded])


  const hasActivePaidPlan = useMemo(() => {
    if (!plan?.paidPlanExpiresAt || !plan.isActive) return false
    const paidPlanExpiresAtDate = new Date(plan?.paidPlanExpiresAt)
    const hasExpired = paidPlanExpiresAtDate.getTime() < (new Date()).getTime()
    return !hasExpired
  }, [plan?.paidPlanExpiresAt])

  if (hasActivePaidPlan || !hasLoaded) return null


  return (
    <div css={css`
      font-size: 0.8em;
      padding: 8px 20px;
    `}>
      <span css={css`
        margin-right: 8px;
      `}>
        {!screenWidthIsSmall && planStatusMessage}
      </span>
      <ButtonSecondary
        data-landmark="upgradePlanButton"
        label={screenWidthIsSmall ? "Upgrade" : "Upgrade your Plan"}
        title={screenWidthIsSmall ? planStatusMessage : undefined}
        size="xs"
        margin={0}
        onClick={() => router.router.push("/organization/plan")}
      />
    </div>
  )
}
