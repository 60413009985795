import { EditorView } from "prosemirror-view";
import {
  AttributeNames,
  getValidPlaceholderName,
  validatePlaceholderName,
} from "@verdi/shared-constants";
import VeNode from "../document/tiptapEditor/VeNode";
import { useCreateSnippet } from "./useCreateSnippet";
import { useCreateSnippetMutation$data } from "./__generated__/useCreateSnippetMutation.graphql";
import { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { css } from "@emotion/react";

const attributeName = AttributeNames.placeholderKey;

type Props = {
  documentId: string;
  documentTitle?: string;
  node: VeNode;
  editorView: EditorView;
};
export const AddSnippetFromDoc = ({
  documentId,
  node,
  editorView,
  documentTitle,
}: Props) => {
  const [placeholderKey, setPlaceholderKey] = useState(
    "doc_documentTitle_{EnterTheSectionTitle}"
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const editorState = editorView.state;

  const toast = useToast();
  useEffect(() => {
    if (documentTitle) {
      const formattedTitle = getValidPlaceholderName(documentTitle).replaceAll(
        "_",
        ""
      );
      setPlaceholderKey(`doc_${formattedTitle}_{EnterTheSectionTitle}`);
    }
  }, []);

  const onClick = () => {
    console.log("clicked");
    try {
      validatePlaceholderName(placeholderKey);
    } catch (err: any) {
      toast({
        title: err.message,
        status: "error",
        isClosable: true,
        position: "top",
      });
      return;
    }
    addAttribute(placeholderKey);
    saveSnippet({
      placeholderKey: placeholderKey,
      textValue: "",
      managedByDocId: documentId,
    });
    onClose();
  };

  const [snippetId, setSnippetId] = useState("");
  const onCreated = (
    response: useCreateSnippetMutation$data["createSnippet"]
  ) => {
    console.log("it was created! ", response);
  };

  const [saveSnippet, isSaving] = useCreateSnippet(onCreated);

  const addAttribute = (id: string) => {
    const transaction = editorState.tr.setNodeAttribute(
      node.pos(),
      attributeName,
      id
    );
    editorView.dispatch(transaction);
    setSnippetId("something");
  };
  const removeSnippet = () => {
    // TODO: First check if it is being used anywhere
    // TODO: Kill it from the DB?
    removeAttribute();
    setSnippetId("");
    onClose();
  };
  const removeAttribute = () => {
    const transaction = editorState.tr.setNodeAttribute(
      node.pos(),
      attributeName,
      null
    );
    editorView.dispatch(transaction);
  };

  const onShowModal = () => {
    onOpen();
  };
  const snippetIdToManage = node.node().attrs[attributeName];

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <button onClick={onShowModal}>Snippet</button>

      {snippetIdToManage && (
        <Modal key={snippetIdToManage} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>PlaceholderKey</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>{snippetIdToManage}</p>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} marginRight={2}>
                Close
              </Button>
              <Button colorScheme="red" onClick={removeSnippet}>
                Remove
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {!snippetIdToManage && (
        <Modal
          key={snippetIdToManage + "-new"}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>PlaceholderKey</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p></p>
              <Input
                border="1px solid red"
                onChange={(evt) => setPlaceholderKey(evt.target.value)}
                value={placeholderKey}
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} marginRight={2}>
                Close
              </Button>
              <Button colorScheme="blue" onClick={onClick}>
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
