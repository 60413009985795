import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { getCurrentAppState } from "../../../../../state/store"
import { AudiencePerspectivesState, allAudiences } from "../../../../../state/suggestions/AudiencePerspectivesSlice"
import { reviewAsAudienceClearAllCommandDefinition } from "./reviewAsAudienceClearAll"
import { reviewAsAudienceCommandDefinition } from "./reviewAsAudience"
import { ShowMiniCommandMenuCommandArgs, showMiniCommandMenuCommandDefinition } from "../../utils/showMiniCommandMenu"


const cmdType = GlobalCommandType.reviewAsAudienceShowOptions


/** Generates inline suggestions from the perspective of a target audience. */
export const reviewAsAudienceShowOptionsCommandDefinition: GlobalCommandDefinition<ReviewAsAudienceShowOptionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ReviewAsAudienceShowOptionsCommandArgs) => {
    const title = `Review from the perspective of ...`
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ReviewAsAudienceShowOptionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const currentAudience = AudiencePerspectivesState.getCurrentAudience(getCurrentAppState())

    const commands = [
      reviewAsAudienceClearAllCommandDefinition.buildMenuItem?.({}),
      ...allAudiences.map(audience => {
        return reviewAsAudienceCommandDefinition.buildMenuItem?.({
          audience,
          isCurrentlySelected: currentAudience?.title === audience.title,
          limitResultsTo: -1,
        })
      })]

    const showMenuArgs: ShowMiniCommandMenuCommandArgs = {
      commands,
      rectOfAnchorElement: args.rect,
      searchPlaceholderText: "Select an audience",
      shouldAdjustRectForScrollOffset: false,
    }
    showMiniCommandMenuCommandDefinition.triggerCommand?.(showMenuArgs)

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: ReviewAsAudienceShowOptionsCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ReviewAsAudienceShowOptionsCommandArgs = {
  rect?: DOMRect
}
