import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { SuggestionsForMarksState } from "../../../../../state/suggestions/suggestionsForMarks"
import { dispatch, getCurrentAppState } from "../../../../../state/store"
import { makeReviewAsAudienceMakeSuggestionsAiCall } from "../../../../../ai/prompts/docDrafter/reviewAsAudienceMakeSuggestionsAiCall"


const cmdType = GlobalCommandType.loadCurrentMarkSuggestions

/** Loads suggestions for the current mark */
export const loadCurrentMarkSuggestionsCommandDefinition: GlobalCommandDefinition<LoadCurrentMarkSuggestionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: LoadCurrentMarkSuggestionsCommandArgs) => {
    const title = args.commandTitle
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        shouldKeepOpenOnRun: true,
        iconType: args.lifecycleState === "load-new" ? "aiSuggestion" : "refresh",
      } as VerdiCommand

    return toReturn
  },
  triggerCommand: (args: LoadCurrentMarkSuggestionsCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  },
  processCommand: async (
    args: LoadCurrentMarkSuggestionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    if (!services.getContextForAi) {
      console.warn('loadCurrentMarkSuggestions: No getContextForAi found in services')
      onProcessingComplete(false)
      return
    }

    // Get the current mark and its attributes
    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    const currentMarkedNode = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()?.currentMark
    const currentMarkSuggestionText = currentBlockContext?.currentMarkSuggestionText
    // console.log('loadCurrentMarkSuggestions', { currentMarkSuggestionText, currentMarkedNode, currentBlockContext })
    if (!currentMarkSuggestionText) {
      console.warn('loadCurrentMarkSuggestions: No currentMarkSuggestionText found')
      onProcessingComplete(false)
      return
    }
    if (!currentMarkedNode) {
      console.warn('loadCurrentMarkSuggestions: No currentMarkedNode found')
      onProcessingComplete(false)
      return
    }

    const textWithinMark = currentMarkedNode.node.node.text || ""

    if (args.shouldAbortIfAlreadyLoaded) {
      const existing = SuggestionsForMarksState.getAll(getCurrentAppState())
        .find(s => s.suggestionText === currentMarkSuggestionText)
      if (existing) {
        onProcessingComplete(true)
        return
      }
    }

    dispatch(SuggestionsForMarksState.setForSuggestionText({
      suggestionText: currentMarkSuggestionText,
      suggestions: [],
      isLoading: true,
    }))

    const results = await makeReviewAsAudienceMakeSuggestionsAiCall(
      services.getContextForAi,
      textWithinMark,
      currentMarkSuggestionText,
    )

    dispatch(SuggestionsForMarksState.setForSuggestionText({
      suggestionText: currentMarkSuggestionText,
      suggestions: results.suggestions || [],
      isLoading: false,
    }))

    onProcessingComplete(true)
  },
}

type LoadCurrentMarkSuggestionsCommandArgs = {
  commandTitle: string
  lifecycleState: "load-new" | "refresh"
  shouldAbortIfAlreadyLoaded?: boolean
}
