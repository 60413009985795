

import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../../utility-hooks/useErrorHandledMutation';
import { UpdateUserInput, useUpdateUserMutation, useUpdateUserMutation$data } from './__generated__/useUpdateUserMutation.graphql';


const mutationQL = graphql`
  mutation useUpdateUserMutation (
    $input: UpdateUserInput!
  ) {
   updateUser(input: $input)
   {
    id
    name
    roleTitle
    responsibleFor
   }    
  }
`
export function useUpdateUser(
  onUpdateComplete?: (response: useUpdateUserMutation$data['updateUser']) => void
) {
  const [commit, isUpdatingUser] = useErrorHandledMutation<useUpdateUserMutation>(mutationQL)
  const updateUser = useCallback((
    input: UpdateUserInput
  ) => {
    commit({
      variables: {
        input,
      },
      optimisticResponse: {
        updateUser: {
          id: input.id,
          name: input.name,
          roleTitle: input.roleTitle,
          responsibleFor: input.responsibleFor,
        }
      },
      onCompleted: (response) => {
        if (onUpdateComplete) {
          onUpdateComplete(response.updateUser)
        }
      }
    })
  }, [])
  return [updateUser, isUpdatingUser] as const
}
