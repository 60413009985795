/**
 * @generated SignedSource<<aaea8ba706a750cb0a84d49d69e7fc52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AiPromptList_document$data = {
  readonly aiPrompts: ReadonlyArray<{
    readonly dontDo: string | null;
    readonly id: string;
    readonly instructionsForUser: string | null;
    readonly promptText: string;
  }>;
  readonly " $fragmentType": "AiPromptList_document";
};
export type AiPromptList_document$key = {
  readonly " $data"?: AiPromptList_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"AiPromptList_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AiPromptList_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentAiPrompt",
      "kind": "LinkedField",
      "name": "aiPrompts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "promptText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dontDo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instructionsForUser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "19f1d33486c42973e09010beb57ba01d";

export default node;
