import { css } from "@emotion/react"
import { KanbanCardBadge } from "./KanbanCardBadge"
import { OpportunityRiskIcon } from "../../screens/assumptions/risk/OpportunityRiskIcon"
import { DocumentIcon } from "../icons/DocumentIcon"
import { VerdiIconShowMore } from "../icons/VerdiIcons"
import { KanbanCardData } from "./KanbanCard"


type Props = {
  cardData: KanbanCardData
  onShowInfoBadgeClick: (card: KanbanCardData, rect: DOMRect) => void,
}
export const KanbanCardBadgeRow = ({
  cardData,
  onShowInfoBadgeClick,
}: Props) => {


  const {
    childCount,
    risk,
  } = cardData


  return (
    <div css={css`
      display: flex;
      gap: 8px;
    `}>

      <KanbanCardBadge
        icon={
          <span></span>
        }
        label={cardData.statusText}
        toolTip={"Status"}
      />

      <KanbanCardBadge
        icon={
          <OpportunityRiskIcon
            opportunityRisk={risk}
          />
        }
        label={`${risk ? risk.totalCount : "?"}`}
        toolTip={"Risk Score"}
      />

      <KanbanCardBadge
        icon={
          <DocumentIcon
            width="15px"
            height="15px"
          />
        }
        label={`${childCount || 0}`}
        toolTip={`${childCount || "no"} document(s)`}
      />

      <KanbanCardBadge
        icon={
          <VerdiIconShowMore
            width="15px"
            height="15px"
          />
        }
        label={""}
        toolTip={"show options"}
        onClick={(rect) => onShowInfoBadgeClick(cardData, rect)}
      />

      {/* <div title={lastActivityToolTip}>
    L:{lastActivitySince} T:{lastActivityToolTip}
  </div> */}

    </div>
  )
}
