import { CompanyContextCommon, Data } from "@verdi/shared-constants"
import { DocumentRelationsData } from '../../screens/documentRelation/useDocumentRelationsProvider'
import { LoggedInUserInfo } from "../../components/appServices/users/useLoggedInUserProvider"


/** Returns a string that can be injected into an AI prompt, 
 *  for providing context about the current user.  
*/
export const userAsPromptContext = (loggedInUser?: LoggedInUserInfo | null) => {

  if (!loggedInUser) return ""
  const { name, roleTitle, responsibleFor } = loggedInUser

  return ""
    + (name ? `The team member who you are helping draft content with: ${name}\n ` : "")
    + (roleTitle ? `Their role title: ${roleTitle}\n ` : "")
    + (responsibleFor ? `They are responsible for: ${responsibleFor}\n ` : "")
    + "\n"
}


/** Returns a string that can be injected into an AI prompt,
 * for providing context about a company.
 */
export const companyAsPromptContext = async (
  companyContext?: CompanyContextCommon.MutableFields
) => {

  if (!companyContext) return ""

  const dynamicFields = Object.entries(companyContext).map(([key, value]) => `${key}: ${value}`)

  const companyInfo = "" + dynamicFields.join('\n') + "\n\n"

  return companyInfo
}


/** Returns a string that can be injected into an AI prompt,
 * for providing concepts related to a document.
 */
export const conceptsAsPromptContext = (relatedDocs?: DocumentRelationsData[], documentId?: string) => {
  if (!relatedDocs || !documentId) return ""
  const docConcepts = relatedDocs
    .filter(d => d.toDoc?.id === documentId
      && d.type === Data.DocumentRelationType.conceptOf
      && Boolean(d.fromDoc?.title?.length) && Boolean(d.fromDoc?.description))
    .map(d => `${d.fromDoc?.title}: ${d.fromDoc?.description}`) || []

  return docConcepts.join("\n")
}
