import { useCallback, useState } from "react"
import { makeGetRequestJson } from "../../../utility-hooks/fetchUtils"


export const useAdminReportsProvider = () => {

  const [isGettingLogEntries, setIsGettingLogEntries] = useState<boolean>(false)
  const getRecentAiGenerationLogEntriesReport = useCallback(async (amountToLoad: number = 10) => {
    setIsGettingLogEntries(true)
    const response = await makeGetRequestJson("admin/reports/recentAiCalls?limit=" + amountToLoad)
    setIsGettingLogEntries(false)
    return response
  }, [])

  return {
    getRecentAiGenerationLogEntriesReport,
    isGettingLogEntries,
  }
}
export type AdminReportsProvider = ReturnType<typeof useAdminReportsProvider>
