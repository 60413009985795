import { Edge, Node, ReactFlowJsonObject } from "@xyflow/react"
import { IdeaNodeWrapperData } from "./nodeTypes/idea/IdeaNode"


/** Returns summarized versions of nodes and edges, for simplifying how the AI interprets them. */
export const getSnapshotForAi = (toObjectResults: ReactFlowJsonObject<Node, Edge>) => {

  const nodes = toObjectResults.nodes.map(getNodeSummaryForAi)
  const edges = toObjectResults.edges.map((e) => {
    return {
      source: e.source,
      target: e.target
    }
  })

  return {
    nodes,
    edges,
  }
}

export const getNodeSummaryForAi = (rawNode: Node) => {

  const ideaNode = rawNode as IdeaNodeWrapperData
  const title = ideaNode?.data?.text || rawNode.data.label
  return {
    id: rawNode.id,
    title,
    tags: ideaNode?.data?.tags || [],
  }
}

export type BrainstormSnapshotForAi = ReturnType<typeof getSnapshotForAi>
export type BrainStormNode = BrainstormSnapshotForAi['nodes'][0]
