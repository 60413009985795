import { mergeAttributes, Node } from '@tiptap/core'


type Config = {
  addNodeView?: NonNullable<Parameters<typeof Node.create>[0]>['addNodeView']
}

export type TaskCheckboxNodeAttributeValues = {
  id?: string, // managed by the UniqueID plugin
}


// See: https://tiptap.dev/docs/editor/api/schema
export const TaskCheckboxNode = (config?: Config) => Node.create({
  name: 'taskCheckbox',
  blockName: "taskCheckbox",
  group: 'nestableBlock',
  content: 'title contentList?',
  selectable: false,
  defining: true,

  // atom: false,
  // isInline: false,
  // isBlock: true,
  // inline: false,

  // content: "(text|taskCheckbox)*",
  // draggable: true,

  addAttributes() {
    return {
      id: { required: false, keepOnSplit: false },
    }
  },

  parseHTML() {
    return [
      { tag: 'task-checkbox' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['task-checkbox', mergeAttributes(HTMLAttributes)]
  },

  addNodeView: config?.addNodeView,

  // This does not work, likely due to our custom schema structure
  // addInputRules() {
  //   return [
  //     wrappingInputRule({
  //       find: /^\s*(\[([( |x])?\])\s$/, // "[ ]" or "[x]"
  //       type: this.type,
  //       getAttributes: match => ({
  //         checked: match[match.length - 1] === 'x',
  //       }),
  //     }),
  //   ]
  // },

  // addKeyboardShortcuts() {
  //   const shortcuts: {
  //     [key: string]: KeyboardShortcutCommand
  //   } = {
  //     Enter: () => {
  //       console.log("ENTER PRESSED IN TaskCheckboxNode")
  //       return this.editor.commands.
  //     },
  //     // 'Shift-Tab': () => {
  //     //   console.log("SHIFT TAB PRESSED IN TaskItem")
  //     //   return this.editor.commands.liftListItem(this.name)
  //     // },
  //   }

  //   // if (!this.options.nested) {
  //   //   return shortcuts
  //   // }

  //   return {
  //     ...shortcuts,
  //     // Tab: () => this.editor.commands.lift(this.name),
  //   }
  // },
})

