import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { DocumentSettingsEditor_document$key } from './__generated__/DocumentSettingsEditor_document.graphql';
import { useCallback, useState, useEffect } from 'react';
import { useThrottle } from '../../utility-hooks/useThrottle';
import { css } from '@emotion/react';
import { useUpdateOpportunity } from '../opportunities/useUpdateOpportunity';
import { Spinner } from '@chakra-ui/react'
import { getUrlForDocument } from '../../routes';
import { mediaQueryExtraSmallWidth } from '../../components/pageLayout/styleHelpers';
import { DocumentSettingsEditorMenu } from './DocumentSettingsEditorMenu';
import { useUpdateDocument } from './useUpdateDocument';
import { useAppServices } from '../../components/appServices/useAppServices';
import { DocumentSettingsTitleEditor } from './DoucmentSettingsTitleEditor';


const fragmentQL = graphql`
  fragment DocumentSettingsEditor_document on Document {
     id
     title
     type
     description
     isVisibleToOrg
     createdByUser {
      id
      name
      email
     }
     opportunities{
      id
      title
      description
      isVisibleToOrg
      mainDoc {
        id
      }
     }
  }
`

type Props = {
  document: DocumentSettingsEditor_document$key
  loggedInUserId?: string
}


export function DocumentSettingsEditor({
  document: documentKey,
  loggedInUserId,
}: Props) {

  const document = useFragment(fragmentQL, documentKey)
  const documentId = document.id
  const opportunity = document.opportunities?.[0]
  const { menuStructureProvider } = useAppServices()

  const { updateDocument, isUpdatingDoc } = useUpdateDocument({ menuStructureProvider })
  const [saveOpportunity, isSavingOpporunity] = useUpdateOpportunity()
  const isSaving = isUpdatingDoc || isSavingOpporunity
  // const documentTitleRef = useRef<HTMLTextAreaElement | null>(null)

  const isMainDocForThisOpportunity = document.opportunities?.find(o => o.mainDoc?.id === documentId)

  const [input, updateInput] = useState<{
    title: string,
    description: string,
    isVisibleToOrg: boolean,
  }>(
    isMainDocForThisOpportunity ? {
      title: opportunity?.title || document.title || '',
      description: opportunity?.description || document.description || '',
      isVisibleToOrg: opportunity?.isVisibleToOrg || document.isVisibleToOrg,
    } : {
      title: document.title || '',
      description: document.description || '',
      isVisibleToOrg: document.isVisibleToOrg,
    }
  )

  const updateDocumentSettings = useThrottle(() => {
    updateDocument(documentId, input)
  })

  const updateOpporunitySettings = useThrottle(() => {
    saveOpportunity({
      opportunityId: opportunity.id,
      ...input,
    })
  })

  const updateInputAndSave = useCallback(async (inputArg: typeof input) => {

    await updateInput(inputArg)
    if (isMainDocForThisOpportunity) {
      updateOpporunitySettings()
    }

    updateDocumentSettings()

  }, [updateInput, updateDocumentSettings, updateOpporunitySettings, isMainDocForThisOpportunity])

  const urlToMainDoc = opportunity?.mainDoc?.id ?
    getUrlForDocument(opportunity.mainDoc.id, opportunity.id)
    : ""


  useEffect(() => {
    window.document.title = input.title ? input.title + " - Verdi" : "Verdi"
  }, [input.title])


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // position: 'relative',
        marginBottom: 16,
      }}
    >

      <div css={css`
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: -16px;  
        @media ${mediaQueryExtraSmallWidth} {
          /* flex-direction: column-reverse; */
        }
      `}>

        <DocumentSettingsTitleEditor
          title={input.title}
          description={input.description}
          onTitleChange={(newTitle) => updateInputAndSave({ ...input, title: newTitle })}
          isOpporunity={Boolean(isMainDocForThisOpportunity)}
        />

        {/* <OpportunitySubTabs
          currentPage="opportunityBrief"
          opportunityMainDocId={documentId}
          displayAs="toggle-button"
        /> */}

        <div css={css`
          display: flex;
          width: 45px;
        `}>
          {isSaving ?
            <Spinner size='sm' margin="12px" opacity={0.2} />
            :
            <div css={css`
              display: flex;
              flex-direction: column;
              align-items: end;
              @media ${mediaQueryExtraSmallWidth} {
                /* flex-direction: row-reverse; */
                width: 100%;
              }
            `}>

              {/* <ShareFeedbackModal documentId={documentId} /> */}

              <DocumentSettingsEditorMenu
                document={document}
                isMainDocForAnOpportunity={Boolean(isMainDocForThisOpportunity)}
                loggedInUserId={loggedInUserId}
                onVisibilityToggle={(isNowVisible) => updateInputAndSave({ ...input, isVisibleToOrg: isNowVisible })}
                opportunityId={opportunity?.id}
                redirectUrlOnDelete={document.type === "quickNote" ? "/home/" : Boolean(isMainDocForThisOpportunity) ? "/" : urlToMainDoc}
              />

            </div>
          }
        </div>


      </div>


      {/* <Textarea
        placeholder='Enter a Description'
        css={css`
          border: none;
          outline: none;
          margin-bottom: 10px;
          padding: 0;
          font-size: 16px;
          background: transparent;
          resize: none;
        `}
        value={input.description || ''}
        onChange={(event) => { updateInputAndSave({ ...input, description: event.target.value }) }}
        ref={documentDescriptionRef}
      /> */}
    </div>
  )
}


export const CurrentDocTitleDomElementId = "currentDocTitleInput"
