import { useCallback } from "react"
import { buildPromptToContinueWritingDoc } from "./AiCoachPrompts"
import { InsertPosition } from "../documents/InsertPosition"
import { DocAiEditOptions, DocAiEditWithAiPromptIdOptions } from "./useAiCoach"
import { UseAiTipTapBridge } from "../documents/useAiTipTapBridge"


export enum AiCommandType {
  ContinueWriting,
  RunUserDefinedPrompt,
  generateFromTemplatedDocAiPrompt,
  writeNextSection,
  showAddDocModal,
  addAction,
  addSection,
}

type Props = {
  aiTipTapBridge: UseAiTipTapBridge,
  requestDocumentEdit: (options: DocAiEditOptions) => void
  requestDocumentEditWithAiPromptId: (options: DocAiEditWithAiPromptIdOptions) => void
}
/** Processes commands triggered by the User */
export const useAiCoachCommandProcessor = ({
  aiTipTapBridge,
  requestDocumentEdit,
  requestDocumentEditWithAiPromptId,
}: Props) => {


  const processCommand = useCallback(async (command: AiCommandType) => {

    console.log("useAiCoachCommandProcessor.processCommand() ", command)

    switch (command) {
      case AiCommandType.ContinueWriting:
        {
          const prompt = buildPromptToContinueWritingDoc()
          // const params: OpenAIParamsType = { suffix }

          await requestDocumentEdit(
            {
              prompt,
              insertAt: InsertPosition.endOfCurrentNode,
              includeCompanyContext: true,
              includeDocDetails: true,
              includeDocMetadata: false,
              includeRelatedContext: false,
              isInline: true,
              textToAddBefore: " ",
              // openApiParams: params,
            }
          )
        }
        break
      case AiCommandType.generateFromTemplatedDocAiPrompt:
        {
          console.log("Got command: generateFromTemplatedDocAiPrompt")
          const context = aiTipTapBridge.getDocumentContextForAi()
          console.log("context = ", context)
          if (context.aiPromptId && context.aiPromptInsertRange) {
            console.log("Has aiPromptId")
            requestDocumentEditWithAiPromptId({
              currentAiPromptId: context.aiPromptId,
              currentAiPromptInsertRange: context.aiPromptInsertRange,
              documentVersion: context.documentVersion,
            })
            return
          }
        }
        break
    }

  }, [aiTipTapBridge.getDocumentContextForAi])

  return {
    processCommand,
  }
}
