/** Raw numerical value used for calculations. For CSS use the "mediaQuery..." variable instead */
export const smallBreakpoint = 800
/** Raw numerical value used for calculations. For CSS use the "mediaQuery..." variable instead */
export const extraSmallBreakpoint = 500
/** Raw numerical value used for calculations. For CSS use the "mediaQuery..." variable instead */
export const largeBreakpoint = 1200

export const mediaQueryLargeWidth = "(min-width: 1200px)"
export const mediaQueryMediumWidth = "(max-width: 1200px)"
export const mediaQuerySmallWidth = `(max-width: ${smallBreakpoint}px)` // "(max-width: 800px)"
export const mediaQueryExtraSmallWidth = `(max-width: ${extraSmallBreakpoint}px)` // "(max-width: 500px)"

