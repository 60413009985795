import React from "react";
import { MindMapContextProps, MindMapServices, useRegisterMindMapContext } from "./useRegisterMindMapContext";


export const MindMapContext = React.createContext<MindMapServices | null>(null)


type Props = MindMapContextProps & {
  children: React.ReactNode
}
export const MindMapContextWrapper = ({ children, ...restOfProps }: Props) => {


  const mindMap = useRegisterMindMapContext(restOfProps)

  return (
    <MindMapContext.Provider value={mindMap}>
      {children}
    </MindMapContext.Provider>
  )
}
