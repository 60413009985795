import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'
import { LocalStorageKeys } from './loaders/LocalStorageKeys'


export enum OpportunitiesViewMode {
  Kanban = "Kanban",
  List = "List",
  MindMap = "MindMap",
}
interface OpportunitiesState {
  /** Contains Opportunity details outside of the Opportunity's mainDoc (Document) */
  opportunityPartials: Data.Opportunities.OpportunityModel[]
  isLoadingOpportunityPartials: boolean
  opportunitiesViewMode: OpportunitiesViewMode
}

const initialState: OpportunitiesState = {
  opportunityPartials: [],
  isLoadingOpportunityPartials: true,
  opportunitiesViewMode: OpportunitiesViewMode.Kanban,
}


export const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState,
  reducers: {
    setAllOpportunityPartials: (state, action: PayloadAction<Data.Opportunities.OpportunityModel[]>) => {
      state.opportunityPartials = action.payload
    },
    setIsLoadingOpportunityPartials: (state, action: PayloadAction<boolean>) => {
      state.isLoadingOpportunityPartials = action.payload
    },
    updateOpportunityPartial: (state, action: PayloadAction<{ id: string, fields: Partial<Data.Opportunities.OpportunityMutableFields> }>) => {
      const { id, fields } = action.payload
      const index = state.opportunityPartials.findIndex((o) => o.id === id)
      if (index === -1) {
        console.error(`updateOpportunityPartial: Opportunity not found for updating: ${id}`, { all: state.opportunityPartials, fields })
      }
      state.opportunityPartials[index] = { ...state.opportunityPartials[index], ...fields }
    },
    addOpportunityPartial: (state, action: PayloadAction<Data.Opportunities.OpportunityModel>) => {
      state.opportunityPartials.push(action.payload)
    },
    setOpportunitiesViewMode: (state, action: PayloadAction<OpportunitiesViewMode>) => {
      state.opportunitiesViewMode = action.payload
      localStorage.setItem(LocalStorageKeys.opportunitiesViewMode, action.payload.toString())
    },
  },
  selectors: {
    getIsLoadingOpportunityPartials: (state) => state.isLoadingOpportunityPartials,
    getAllOpportunityPartials: (state) => state.opportunityPartials,
    getOpportunitiesViewMode: (state) => state.opportunitiesViewMode,
  }
})

const getViewModeFromLocalStorage = () => {
  const savedState = localStorage.getItem(LocalStorageKeys.opportunitiesViewMode)
  if (savedState) {
    const parsedState: OpportunitiesViewMode | undefined = OpportunitiesViewMode[savedState as keyof typeof OpportunitiesViewMode]
    return parsedState
  }
  return undefined
}

export const OpportunitiesState = {
  ...opportunitiesSlice.selectors,
  ...opportunitiesSlice.actions,
  getViewModeFromLocalStorage,
}



export default opportunitiesSlice.reducer
