import { ApiTypes } from "@verdi/shared-constants";
import { getLocalIsoDate } from "../../utility-hooks/jsDateHelpers";
import { makePostRequestJson } from "../../utility-hooks/fetchUtils";


const cancelationTokens: AbortController[] = [];

/** Stops any AI API requests currently running. Useful on navigate. */
export const cancelPendingApiRequests = () => {
  cancelationTokens.forEach((token) => token.abort("Request canceled"));
  cancelationTokens.length = 0;
}
const addCancelationToken = (cancelationToken: AbortController) => {
  cancelationTokens.push(cancelationToken);
}
const removeCancelationToken = (cancelationToken: AbortController) => {
  const index = cancelationTokens.indexOf(cancelationToken);
  if (index > -1) {
    cancelationTokens.splice(index, 1);
  }
}


/** Primary way to make an AI prompt call. Hits `ai/Chat` */
export const makeAiChatRequest = async (
  body: Omit<ApiTypes.RunAiChatRequestBody, "clientLocalDate">,
) => {

  const cancelationToken = new AbortController();
  addCancelationToken(cancelationToken);

  try {

    const bodyToSend: ApiTypes.RunAiChatRequestBody = {
      ...body,
      clientLocalDate: getLocalIsoDate(),
    };

    const response = await makePostRequestJson("ai/Chat", bodyToSend, cancelationToken);
    return response
      ? (response as ApiTypes.RunAiPromptApiResponseBody)
      : undefined;
  } catch (e) {
    throw e
  } finally {
    removeCancelationToken(cancelationToken);
  }
}


/** Before Chat came out this was used to make AI API Call. Hits `ai/RunPrompt` 
 * 
 *  For new calls you likely want to use `makeAiChatRequest()` instead
*/
export const makeAiApiRequest = async (prompt: string, context: string, aiReturnsJson: boolean) => {
  const body: ApiTypes.RunAiPromptApiRequestBody = {
    prompt,
    context,
    aiReturnsJson,
  };

  const response = await makePostRequestJson("ai/RunPrompt", body);
  return response
    ? (response as ApiTypes.RunAiPromptApiResponseBody)
    : undefined;
}



export const requestRelatedContextFromOtherDocsViaApi = async (documentId: string) => {
  return await makePostRequestJson(
    "ai/GetRelatedContextFromOtherDocs",
    { documentId }
  )
}


export const detectDocumentMetadata = async (documentId: string) => {
  return await makePostRequestJson(
    "ai/DetectDocumentMetadata",
    { documentId }
  )
}


export const benchmarkManyAiChatRequests = async (bodies: ApiTypes.RunAiChatRequestBody[], n: number = 50) => {

  const startTime = new Date().getTime()

  const responses = await Promise.allSettled(
    bodies.map(async (body) => {
      return await benchmarkAiChatRequest({
        ...body,
        openAIParams: {
          ...body.openAIParams,
          n,
        }

      })
    })
  )

  const endTime = new Date().getTime()
  const timeTaken = endTime - startTime
  console.debug("benchmarkManyAiChatRequests", { timeTaken, responses })
}



/** Runs an AiChatRequest and outputs details to the console. */
export const benchmarkAiChatRequest = async (body: ApiTypes.RunAiChatRequestBody) => {

  const startTime = new Date().getTime()
  const response = await makeAiChatRequest({
    ...body,
    openAIParams: {
      n: 40,
    }
  })

  const endTime = new Date().getTime()
  const timeTaken = endTime - startTime
  console.debug("benchmarkAiChatRequest", { timeTaken, body, response })

  return response
}
