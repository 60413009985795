import { useMemo } from "react"
import { useWindowResize } from "../../../utility-hooks/useWindowResize"
import { extraSmallBreakpoint, largeBreakpoint, smallBreakpoint } from "../../pageLayout/styleHelpers"


/** Utility hook to get an optimal size of the command menu */
export const useCommandMenuWH = () => {

  const {
    windowWidth,
    windowHeight,
  } = useWindowResize()

  const commandMenuWH = useMemo(() => {
    return getCommandMenuWH(windowWidth, windowHeight)
  }, [windowWidth, windowHeight])

  return commandMenuWH
}

export const getCommandMenuWH = (windowWidth: number, windowHeight: number) => {

  let height = 300
  if (windowHeight < 800) {
    height = Math.min(200, windowHeight - 60)
  } else if (windowHeight > 1200) {
    height = 400
  }

  let width = 300
  if (windowWidth < extraSmallBreakpoint) {
    width = Math.min(300, windowWidth - 60)
  } else if (windowWidth > largeBreakpoint) {
    width = 400
  }
  else if (windowWidth > smallBreakpoint) {
    width = 350
  }


  return {
    width,
    height,
  }
}
