

/** 
 * Defines all placeholders whose values are dynamically set from database models
 */
export type BuiltInPlaceholderValueMapping = {
  companyName?: string
  companyDescription?: string
  documentType?: string
  documentTitle?: string
  documentDescription?: string
  documentBody?: string
  opportunityName?: string
  opportunityDescription?: string
  opportunityDocument?: string
  dontDos?: string[]
  documentLevelContext?: string,
}

type KeysEnum<T> = { [P in keyof Required<T>]: true };
/** This should throw a typescript error if values ever get out of sync
 *  Which should ensure consistency.
 */
const builtInPlaceholderKeys: KeysEnum<BuiltInPlaceholderValueMapping> = {
  companyName: true,
  companyDescription: true,
  documentType: true,
  documentTitle: true,
  documentDescription: true,
  documentBody: true,
  opportunityName: true,
  opportunityDescription: true,
  opportunityDocument: true,
  dontDos: true,
  documentLevelContext: true,
};

export const getListOfAllBuiltInPlaceholders = () => {
  return Object.keys(builtInPlaceholderKeys)
}