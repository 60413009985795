import {
  Avatar,
  Button,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useColorMode,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useCallback, useEffect, useState } from "react";
import { generateAnalyticsId } from "@verdi/shared-constants";
import { FaFlask } from "react-icons/fa";
import { useRouter } from "found";
import { useAppServices } from "./appServices/useAppServices";
import { SwitchPrimary } from "./buttons/SwitchPrimary";
import { useFeatureFlags } from "../utility-hooks/useFeatureFlags";
import { IconButtonPrimary } from "./buttons/IconButtonPrimary";
import { VerdiIconBilling, VerdiIconChat, VerdiIconDarkMode, VerdiIconDebug, VerdiIconLightMode, VerdiIconLogout, VerdiIconReports, VerdiIconSettings, VerdiIconTag, VerdiIconTemplates, VerdiIconUserProfile, VerdiIconUsersManage } from "./icons/VerdiIcons";
import { LoggedInUserInfo } from "./appServices/users/useLoggedInUserProvider";


type Payload = {
  verdiAnalyticsId: string;
  company: string;
  emailPreview: string;
  emailDomain: string;
  isAdmin: boolean;
  isTeamVerdi: boolean;
  shouldIncludeInReports: boolean;
};


const identifyForPostHog = (userId: string, payload: Payload) => {
  const w = window as any;
  if (w.posthog) {
    w.posthog.identify(userId);
    w.posthog.people.set(payload);
  }
};


const initializePendo = (userId: string, payload: Payload, user: LoggedInUserInfo) => {
  const pendo = (window as any)?.pendo
  if (pendo) {

    pendo.initialize({
      visitor: {
        id: userId,
        email: payload.emailPreview,
        firstName: user.name?.split("")?.[0] || "",
      },

      account: {
        id: user.organization.id,
        accountName: user.organization.name,
      }
    });
  }

}



/** Shows currently logged in user */
export const UserInfoBadge = () => {

  const { loggedInUserProvider: loggedInUserInfo } = useAppServices()
  const loggedInUser = loggedInUserInfo.loggedInUser
  const { colorMode, toggleColorMode } = useColorMode()
  const [showYoloModeOption, setShowYoloModeOption] = useState(false)


  useEffect(() => {
    if (!loggedInUser) return;

    const verdiAnalyticsId = generateAnalyticsId(
      loggedInUser.organization.name,
      loggedInUser.email,
      loggedInUser.id
    );
    const email = loggedInUser.email || "";
    const payload: Payload = {
      verdiAnalyticsId,
      company: loggedInUser.organization.name,
      emailPreview: `${email.substring(0, email.indexOf("@"))}...`,
      emailDomain: email.substring(email.indexOf("@") + 1),
      isAdmin: loggedInUser.isAdmin,
      isTeamVerdi:
        loggedInUser.isAdmin ||
        loggedInUser.organization.name.toLowerCase().indexOf("verdi") > -1,
      shouldIncludeInReports: loggedInUser.organization.shouldIncludeInReports,
    };

    identifyForPostHog(verdiAnalyticsId, payload);
    initializePendo(verdiAnalyticsId, payload, loggedInUser)
  }, [loggedInUser])


  const router = useRouter()
  const documentId = router.match.params['documentId']
  const { experimentalEnabled, setShouldShowExperimentalFeatures } = useFeatureFlags()

  const gotToUrl = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, url: string) => {
    evt.preventDefault()
    router.router.push(url)
  }, [router])


  if (!loggedInUser) {
    window.location.href = "/login";
    return <div>Not logged in</div>;
  }


  return (
    <div
      css={css`
        font-size: 0.8em;
        text-align: right;
      `}
    >
      <Popover isLazy>
        <PopoverTrigger>
          <IconButton
            size="sm"
            variant="ghost"
            aria-label=""
            icon={<Avatar name={loggedInUser?.email} size="xs" />}
          ></IconButton>
        </PopoverTrigger>
        <PopoverContent
          left={-2}
          top={-8}
          width="auto"
        >
          <PopoverHeader
            fontSize="1.1em"
            paddingTop={3}
            paddingBottom={5}
            paddingLeft={7}
          >
            <div
              css={css`
                display: flex;
                justify-content: end;
                padding-right: 10px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: end;
                  gap: 6px;
                  line-height: 1;
                `}
              >
                <small>
                  Logged in as
                </small>

                {loggedInUser.name &&
                  <strong>
                    {loggedInUser.name}
                  </strong>
                }

                <small>
                  {loggedInUser.email}
                </small>

              </div>
            </div>
          </PopoverHeader>
          <PopoverBody
            display="flex"
            flexDirection="column"
            gap={1}
            justifyContent="right"
          >
            {loggedInUser?.isAdmin && (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  border-bottom: 1px solid
                    var(--chakra-colors-chakra-border-color);
                `}
              >
                <div css={css`
                  margin-right: 10px;
                `}>
                  Admin Menu
                </div>
                <Link href="/Templates/Documents">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(evt) => gotToUrl(evt, "/Templates/Documents")}
                    rightIcon={<VerdiIconTemplates />}
                  >
                    Edit Templates
                  </Button>
                </Link>

                <Link href="/admin/Reports">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(evt) => gotToUrl(evt, "/admin/Reports")}
                    rightIcon={<VerdiIconReports />}>
                    Reports
                  </Button>
                </Link>

                {loggedInUser.organization.id && (
                  <Link href={`/org/${loggedInUser.organization.id}/snippets`}>
                    <Button
                      variant="ghost"
                      size="sm"
                      rightIcon={<VerdiIconChat />}>
                      Snippets
                    </Button>
                  </Link>
                )}

                {documentId &&
                  <Link href={`/DocumentHistory/${documentId}`}>
                    <Button
                      variant="ghost"
                      size="sm"
                      rightIcon={<VerdiIconDebug />}>
                      Debug Current Doc
                    </Button>
                  </Link>
                }
              </div>
            )}

            <Link href="/userProfile">
              <Button
                variant="ghost"
                size="sm"
                onClick={(evt) => gotToUrl(evt, "/userProfile")}
                rightIcon={<VerdiIconUserProfile />}>
                My Profile
              </Button>
            </Link>

            <Link href="/organization/users">
              <Button
                variant="ghost"
                size="sm"
                onClick={(evt) => gotToUrl(evt, "/organization/users")}
                rightIcon={<VerdiIconUsersManage />}>
                Users
              </Button>
            </Link>

            <Link href="/organization/plan">
              <Button
                variant="ghost"
                size="sm"
                onClick={(evt) => gotToUrl(evt, "/organization/plan")}
                rightIcon={<VerdiIconBilling />}>
                Billing
              </Button>
            </Link>

            <Link href="/tags">
              <Button
                variant="ghost"
                size="sm"
                onClick={(evt) => gotToUrl(evt, "/tags")}
                rightIcon={<VerdiIconTag />}>
                Tags
              </Button>
            </Link>


            <Button
              variant="ghost"
              size="sm"
              justifyContent="right"
              onClick={toggleColorMode}
              rightIcon={colorMode === "light" ? <VerdiIconDarkMode /> : <VerdiIconLightMode />}
            >
              {colorMode === "light" ? (
                <span>use dark mode</span>
              ) : (
                <span>use light mode</span>
              )}
            </Button>


            {showYoloModeOption &&
              <div css={css`
              width: 100%;
              display: flex;
              justify-content: right;
              align-items: center;
            `}>
                <SwitchPrimary
                  label={"Yolo Mode"}
                  isChecked={experimentalEnabled}
                  onChange={(evt) => {
                    setShouldShowExperimentalFeatures(evt.target.checked)
                    window.location.reload()
                  }}
                  title="Get access to experimental features"
                  children={<FaFlask />}
                />

              </div>
            }


          </PopoverBody>
          <PopoverFooter css={css`
            display: flex;
            justify-content: space-between;
          `}>
            <IconButtonPrimary
              aria-label="Show yolo mode"
              css={css`
                opacity: 0.01;
                &:hover {
                  opacity: 0.05;
                }
              `}
              icon={<FaFlask />}
              onClick={() => setShowYoloModeOption(!showYoloModeOption)}
            />
            <Button
              variant="ghost"
              size="sm"
              justifyContent="right"
              rightIcon={<VerdiIconLogout />}
              onClick={() => {
                window.location.href = "/logout";
              }}
            >
              Log out
            </Button>

          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </div>
  );
};
