import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"


const cmdType = GlobalCommandType.showAddFrameworkToDoc

export const showAddFrameworkToDocCommandDefinition: GlobalCommandDefinition<ShowAddFrameworkToDocCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowAddFrameworkToDocCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Add framework sections ...',
        description: '',
        searchName: 'add framework sections',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ShowAddFrameworkToDocCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    services.addNewDocGlobalProvider.openAddNewDocModal(args, "Add framework to current note")
    onProcessingComplete(true)
  },
}

type ShowAddFrameworkToDocCommandArgs = {
  parentDocId: string
}
