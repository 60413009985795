import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface InternalState {
  isLoadingInitialData: boolean
  doingAiOnDoc: LoadingStatus
}
const initialState: InternalState = {
  isLoadingInitialData: true,
  doingAiOnDoc: { isInProgress: false },
}

export const loadingStatusSlice = createSlice({
  name: 'loadingStatus',
  initialState,
  reducers: {
    /** Should only be called in `LoadInitialAppState.ts` */
    setIsLoadingInitialData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingInitialData = action.payload
    },
    /** Can be called by anything that triggers an AI edit to a document */
    setDoingAiOnDoc: (state, action: PayloadAction<LoadingStatus>) => {
      state.doingAiOnDoc = action.payload
    },
  },
  selectors: {
    /** True when loading the base app data, as defined in `LoadInitialAppState.ts` */
    getIsLoadingInitialData: (state) => state.isLoadingInitialData,
    /** True when there is a pending AI call on the current document editor */
    getDoingAiOnDoc: (state) => state.doingAiOnDoc,
  }
})
export const loadingStatusState = {
  ...loadingStatusSlice.actions,
  ...loadingStatusSlice.selectors,
}

export default loadingStatusSlice.reducer

type LoadingStatus = {
  isInProgress: boolean
  message?: string
}
