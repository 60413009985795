import { css } from "@emotion/react"
import { useAppSelector } from "../../state/storeHooks"
import { AutoAiModeState } from "../../state/AutoAiModeSlice"
import LoadingSpinner from "../LoadingSpinner"
import { ButtonTertiary } from "../buttons/ButtonTertiary"
import { dispatch } from "../../state/store"


export const AutoAiModeBanner = () => {


  const isInAutoAiMode = useAppSelector(AutoAiModeState.getIsInAutoAiMode)
  const isWorking = useAppSelector(AutoAiModeState.getIsDoingSomething)
  const messageToUser = useAppSelector(AutoAiModeState.getMessageToUser)


  const onToggleAutoAiMode = () => {
    if (isInAutoAiMode && isWorking) {
      dispatch(AutoAiModeState.setStatus({
        isDoingSomething: true,
        messageToUser: "Stopping ..."
      }))
    }
    dispatch(AutoAiModeState.setIsInAutoAiMode(!isInAutoAiMode))
  }


  return (
    <div css={css`
      padding: 4px 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      font-size: var(--chakra-fontSizes-xs);
      font-style: italic;
      opacity: ${isInAutoAiMode ? "0.8" : "0.5"};
    `}>
      {/* <ButtonTertiary
        label={`Auto drafting is ${isInAutoAiMode ? "ON" : "OFF"}`}
        padding={0}
        size="xs"
        onClick={onToggleAutoAiMode}
      /> */}

      {isWorking &&
        <LoadingSpinner
          size="xs"
          label={messageToUser}
        />
      }

    </div>
  )
}
