import { IconButton, Textarea, useColorModeValue } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useCallback, useState } from "react";
import { useThrottle } from "../../utility-hooks/useThrottle";
import { useUpdateOpportunity } from "./useUpdateOpportunity"
import { VerdiIconEdit } from "../../components/icons/VerdiIcons";



type Props = {
  opportunity: {
    id: string
    title?: string
    description?: string
  }
}

export const OpportunityEditor = ({ opportunity }: Props) => {

  const [input, updateInput] = useState<{
    title: string,
    description: string,
  }>(
    {
      title: opportunity.title || '',
      description: opportunity.description || '',
    }
  )

  const updateOpportunitySettings = useThrottle(() => {
    saveOpportunity({
      opportunityId: opportunity.id,
      ...input,
    })
  })

  const updateInputAndSave = useCallback(async (inputArg: typeof input) => {
    await updateInput(inputArg)
    updateOpportunitySettings()
  }, [updateInput])


  const [saveOpportunity, isSavingOpporunity] = useUpdateOpportunity()
  const settingsBackground = useColorModeValue('#FFF', '#1E1E1E')

  const [inEditMode, setInEditMode] = useState(false)


  return (
    <div>

      {!inEditMode &&

        <h1 css={css`
          display: flex;
          justify-content: space-between;
        `}>
          <span>
            {input.title}
          </span>
          <IconButton
            aria-label="Edit Opportunity"
            onClick={() => setInEditMode(true)}
            icon={<VerdiIconEdit />}
          />
        </h1>

      }

      {inEditMode &&
        <>
          <Textarea
            placeholder="Enter a Title"
            css={css`
          border: 'none';
          border-bottom: 1px solid rgba(128,128,128,0.1);
          font-family: Lato;
          font-size: xx-large;
          font-weight: 700;
          outline: none;
          padding: 0;
          width: 100%;
          background: ${settingsBackground};
          resize: none;
          overflow-y:hidden;
        `}
            value={input.title || ''}
            autoFocus={true}
            onChange={(event) => {
              updateInputAndSave({ ...input, title: event.target.value })
            }}
          />


          <Textarea
            placeholder='Enter a Description'
            css={css`
          border: none;
          outline: none;
          margin-bottom: 10px;
          padding: 0;
          font-size: 16px;
          background: ${settingsBackground};
          resize: none;
          min-height: 50px;
        `}
            value={input.description || ''}
            onChange={(event) => {
              updateInputAndSave({ ...input, description: event.target.value })
            }}
          />
        </>
      }

    </div>
  )
}
