/**
 * @generated SignedSource<<1332d7fdff9cf60dd07660dd74187854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganizationUserListQuery$variables = {};
export type OrganizationUserListQuery$data = {
  readonly organizationUserConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly email: string;
        readonly id: string;
        readonly name: string | null;
        readonly roleTitle: string | null;
      };
    }>;
  };
};
export type OrganizationUserListQuery = {
  response: OrganizationUserListQuery$data;
  variables: OrganizationUserListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QueryOrganizationUserConnection",
    "kind": "LinkedField",
    "name": "organizationUserConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryOrganizationUserConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roleTitle",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationUserListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationUserListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "68e8d86e9724a36a12541c274d011043",
    "id": null,
    "metadata": {},
    "name": "OrganizationUserListQuery",
    "operationKind": "query",
    "text": "query OrganizationUserListQuery {\n  organizationUserConnection {\n    edges {\n      node {\n        id\n        email\n        name\n        roleTitle\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e76e965f4e33aa976311276a557ef253";

export default node;
