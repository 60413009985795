import { AiSuggestions, ApiTypes } from "@verdi/shared-constants"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsMarkSuggestion } from "../../utils/cleanupAiOutput"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { Audience, AudiencePerspectivesState } from "../../../state/suggestions/AudiencePerspectivesSlice"
import { getCurrentAppState } from "../../../state/store"
import { getTargetAudientContext } from "../../promptUtils/getTargetAudientContext"


/** Generates 3 potential options to fill in as an answer to the question or comment within a mark. */
export const makeReviewAsAudienceMakeSuggestionsAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  currentMarkText: string,
  currentQuestionToAnswer: string,
) => {

  const allContext: AllContextForAi = await getContextForAi({
    includeContext: {
      document: {
        bodyAs: "indented-markdown",   // Position within the doc might be important?
      }
    }
  })

  const currentAudience = AudiencePerspectivesState.getCurrentAudience(getCurrentAppState())

  const messageWithAudience: ApiTypes.RunAiChatRequestMessage = {
    role: ApiTypes.ChatRoleType.system,
    content: getTargetAudientContext(currentAudience),
  }

  const promptWithInstructions = getPrompt(currentMarkText, currentQuestionToAnswer, currentAudience)
  console.log("makeReviewAsAudienceMakeSuggestions", { currentAudience, prompt: promptWithInstructions })

  const response = await makeAiChatRequest({
    messages: [
      ...allContext.promptMessages,
      messageWithAudience,
    ],
    prompt: promptWithInstructions,
  })

  const rawText = response?.aiResponseJson.content as string || ""
  console.log("makeReviewAsAudienceMakeSuggestions", { response, rawText })
  const suggestionSet = cleanupAiOutputAsMarkSuggestion(currentQuestionToAnswer, rawText)
  return suggestionSet
}

export const getPrompt = (
  currentMarkText: string,
  currentQuestionToAnswer: string,
  currentAudience?: Audience,
) => {

  const commands = AiSuggestions.AISuggestionCommandOptions
    .filter((cmd) => cmd.id === "gap") // For now, only allow "replace" suggestions
    .map((cmd) => {
      return `{id: "${cmd.id}", description: "${cmd.description}" }`
    })

  const prompt = `
You are editing the current document body to make it more specific for the TARGET AUDIENCE (${currentAudience?.title})'s perspective.
Take on that perspective and answer this question: ${currentQuestionToAnswer}

Consider the details that are most important to that perspective and include them in your response. 
There will be plenty of other ways you may want to respond outside of the perspective. 
For now, focus exclusively on this one perspective and the types of answers they would give.

You should give 3 varied answers to the question.
Future readers of the document will not see the question asked, so reference the question in your answer to help make it make sense in context of the document. 
The answers should be specific text that makes sense to put into the document, not suggestions for what to put in the document. 
Give each answer its own unique 2-5 word "concise answer summary".

Response format:
concise answer summary|answer in a single line or short paragraph
concise answer summary|answer in a single line or short paragraph
concise answer summary|answer in a single line or short paragraph

Example Response:
Training Program Design and Content|Design a comprehensive training program tailored to the specific needs of product teams. Cover the fundamentals of AI, machine learning, and predictive analytics. Provide hands-on training on using AI tools and software for roadmap planning. Incorporate case studies and real-life examples to demonstrate the benefits of AI in roadmap planning. Offer practical exercises and simulations to reinforce learning and application of AI concepts. Include sessions on data interpretation, model evaluation, and decision-making based on AI insights.
Guidance and Support|Assign dedicated AI experts or trainers to mentor and guide product teams throughout the training process. Provide regular support and assistance to address any questions or challenges faced by product teams. Conduct workshops and interactive sessions to discuss specific roadmap planning scenarios and apply AI techniques.
Evaluation and Feedback|Establish a feedback loop to gather input from product teams regarding the effectiveness of the training program.
  `

  return prompt
}

