import { css } from "@emotion/react"
import { ReactNode } from "react"


type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  isDisabled?: boolean
}
export const SectionWrapper = (props: Props) => {

  const { children, isDisabled, ...restOfProps } = props

  return (
    <div css={css`
      margin-bottom: 30px;
      @keyframes fadeIn {
          0% { opacity: 0.3; }
          100% { opacity: 1; }
        }
      ${isDisabled && `
        pointer-events: none;
        opacity: 0.3;
      `}
      ${isDisabled !== undefined && !isDisabled && `
        opacity: 1;
        animation: fadeIn 500ms ease-in;
      `}
    `}
      {...restOfProps}
    >
      {children}
    </div>
  )
}
