import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { OpportunityStatusType } from "../../../kanban/KanbanColumnOptions"


const cmdType = GlobalCommandType.addOpportunityShowOptions

/** Navigates to the specified URL */
export const addOpportunityShowOptionsCommandDefinition: GlobalCommandDefinition<AddOpportunityShowOptionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddOpportunityShowOptionsCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: `Add Opportunity ...`,
        description: '',
        searchName: 'add opportunity',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "add",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: AddOpportunityShowOptionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {
    services.addNewOpportunityGlobalProvider.openAddNewOpportunityGlobalModal(args)
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: AddOpportunityShowOptionsCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type AddOpportunityShowOptionsCommandArgs = {
  parentDocId?: string
  isOnboarding?: boolean
  status?: OpportunityStatusType
  /** When true, will show a loading spinner while it creates an opportunity 
   *  with default options, then navigate to the new opportunity. */
  shouldAutoAddWithDefaults?: boolean
}
