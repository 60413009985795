import { AssumptionSummary } from "../../../screens/assumptions/useAssumptionsProvider"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItem } from "../../utils/cleanupAiOutput"
import { buildMessageForAllAssumptions } from "./makeAssumptionQuestionsAiCall"


/** Returns a list of answered questions from the given context */
export const makeFindAnsweredAssumptionsAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  const allContext: AllContextForAi = await getContextForAi()

  const existingQuestionAndAnswers = allContext.currentOpportunity?.assumptions || []
  const questionsToExclude = [...existingQuestionAndAnswers]
  const messageForAllAssumptions = buildMessageForAllAssumptions(questionsToExclude)

  const docBody = allContext.currentDoc?.body || allContext.currentOpportunity?.bodyPlainText || ""
  const prompt = getPrompt(docBody)

  const response = await makeAiChatRequest({
    prompt,
    messages: [
      ...allContext.promptMessages,
      ...messageForAllAssumptions
    ],
  })

  const rawString = response?.aiResponseJson.content as string || ""
  return getAssumptionSummaries(rawString)
}


export const getPrompt = (docBody: string) => {
  return `Your job is to detect underlying assumptions from documents. 
You do this by calling out the verbatim text the assumption is associated with, 
then detecting questions and answers that are being asked and answered from the text. 

### DOCUMENT BODY START
${docBody}
### DOCUMENT BODY END

Every answer has to have grounding in the document. Do not make up any answers.
Do not include a preamble to your answer.
Partial sentence answers are great, keep the answers as concise as possible. 
Each answer should be specific only to the corresponding question.

In your response, return all the questions and the answers you find in the document(s) in this format: 
The assumption being made, in the form of a question | the answer to the question

EXAMPLE 1:
IF the document says: Customers prioritize ease of use and scalability
THEN a valid response could be:
What do customers prioritize? | Customers prioritize ease of use and scalability

  `
  // TODO: Consider including existing assumptions more explicitly here ???
}

// In your response, return all the questions and the answers you find in the document(s) in this format: 
// verbatim text from the document | question this poses | answer to the question

const getAssumptionSummaries = (rawText: string) => {
  let rawLines = rawText.split("\n")
  const summaries: AssumptionSummary[] = []
  for (const line of rawLines) {
    const parts = line.split("|").map(l => cleanupAiOutputItem(l))
    if (parts.length < 2) {
      continue
    }
    summaries.push({
      // docTextToHighlight: parts[0],
      question: parts[0],
      answer: parts[1],
    })
  }
  return summaries
}
