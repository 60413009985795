import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants"
import { DocumentOutlineEntry } from "../../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { ChainedCommands, Content, Editor, Mark } from "@tiptap/core"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { getNextRootNodeInsertPosition } from "../../../../screens/document/tiptapEditor/utils/getNextRootNodeInsertPosition"
import { showDocEditorCommandMenuCommandDefinition } from "../utils/showDocEditorCommandMenu"
import { AutoDismissMode } from "../../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"
import { getChildInsertPosition } from "../../../../screens/document/tiptapEditor/utils/getChildInsertPosition"


const cmdType = GlobalCommandType.writeToDocBody

/** Writes a DocumentOutline to the current Prosemirror document, 
 *  
 *  This handles converting to Prosemirror nodes.
 */
export const writeToDocBodyCommandDefinition: GlobalCommandDefinition<WriteToDocBodyCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: WriteToDocBodyCommandArgs) => {
    const toReturn =
      { // This command is typically not shown in a menu
        globalCommandType: cmdType,
        name: 'Write to doc body',
        searchName: 'write to doc body',
        maxNestingLevel: 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: WriteToDocBodyCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("writeToDocBody()", { args })
    const {
      documentOutline,
      insertPosition,
      shouldMoveCaretToNextNode,
      shouldDeleteCurrentSelection,
    } = args

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('writeToDocBody: No editor found in servicesForCurrentDoc')
      return
    }

    // services.servicesForCurrentDoc?.editor.commands.setVerdiMark

    const insertJSONContent = ProsemirrorNodes.makeContentList([])

    for (const entry of documentOutline) {
      const nodeToAdd = prosemirrorNodeFromDocOutlineEntry(entry)
      insertJSONContent.content.push(nodeToAdd)
    }
    console.log("writeToDocBody: inserting content", { insertJSONContent })

    writeContentToDocBody({
      editor,
      contentToInsert: insertJSONContent,
      insertPosition,
      shouldMoveCaretToNextNode,
      shouldDeleteCurrentSelection,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: WriteToDocBodyCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type WriteToDocBodyCommandArgs = {
  documentOutline: DocumentOutlineEntry[]
  insertPosition: InsertPosition
  shouldMoveCaretToNextNode?: boolean
  shouldDeleteSlashCommand?: boolean
  /** If true, will remove everything currently selected before writing content. 
 * 
 * This is independent of the InsertPosition; however, if `insertPosition===currentCursorSelection` this flag would just be redundant. */
  shouldDeleteCurrentSelection?: boolean
}


export const prosemirrorNodeFromDocOutlineEntry = (entry: DocumentOutlineEntry): ProsemirrorNodes.NestableBlock => {

  const children: ProsemirrorNodes.NestableBlock[] | undefined = entry.children
    ? entry.children.map(prosemirrorNodeFromDocOutlineEntry)
    : undefined

  if (entry.type === "heading") {
    return ProsemirrorNodes.makeBlockOfType("section", entry.content, children)
  } else if (entry.type === "question") {
    return ProsemirrorNodes.makeQuestionBlock(entry.content, children, undefined)
  } else if (entry.type === "checkbox") {
    return ProsemirrorNodes.makeTaskCheckboxBlock(entry.content, children, undefined)
  }

  return ProsemirrorNodes.makeBlockOfType("freeText", entry.content, children)
}

export type WriteContentToDocBodyArgs = {
  contentToInsert: Content
  editor: Editor
  insertPosition: InsertPosition
  shouldMoveCaretToNextNode?: boolean
  shouldAutoOpenCommandMenu?: boolean
  /** If true, will try to delete any command text that starts with a slash, if any exists. */
  shouldDeleteSlashCommand?: boolean
  /** If true, will remove everything currently selected before writing content. 
   * 
   * This is independent of the InsertPosition; however, if `insertPosition===currentCursorSelection` this flag would just be redundant. */
  shouldDeleteCurrentSelection?: boolean
}

export const writeContentToDocBody = (args: WriteContentToDocBodyArgs) => {

  const {
    contentToInsert,
    editor,
    insertPosition,
    shouldMoveCaretToNextNode,
    shouldAutoOpenCommandMenu,
    shouldDeleteSlashCommand,
    shouldDeleteCurrentSelection,
  } = args

  console.log("writeContentToDocBody()", { insertPosition, contentToInsert })

  const commandChain: ChainedCommands = editor.chain()

  //TODO: Figure out how to add the AI edited mark to the content
  // const AIMarkJson = {
  //   type: "attribution",
  //   attrs: { isgenerated: "true", isconfirmed: "false" },
  // } as const;
  // // const AIMark = Mark.fromJSON(DocumentSchema.schema, AIMarkJson);
  // commandChain.setMark(AIMarkJson.type, AIMarkJson.attrs)

  // This does not seem to work, but it should.
  // commandChain
  //   .setMark(
  //     DocumentSchema.VerdiMarks.VerdiMarkType.Attribution,
  //     { isgenerated: "true", isconfirmed: "false" })
  //   .insertContent(contentToInsert)
  //   .run()

  if (shouldDeleteSlashCommand) {
    commandChain.deleteToLastSlash()
  }

  if (shouldDeleteCurrentSelection) {
    commandChain.deleteSelection()
  }

  if (insertPosition === InsertPosition.startOfDocument) {

    // TODO: Figure out how to insert at the REAL START of doc, instead of inside the first free text
    editor.commands.focus("start")
    commandChain
      .insertContentAt(0, contentToInsert)

  } else if (insertPosition === InsertPosition.endOfDocument) {

    const endOfDocPos = editor.state.doc.content.size
    editor.commands.focus("end")
    commandChain
      .insertContentAt(endOfDocPos, contentToInsert)

  } else if (insertPosition === InsertPosition.currentCursorSelection) {

    commandChain
      .deleteSelection()
      // .setMark(AIMarkJson.type, AIMarkJson.attrs)
      .insertContent(contentToInsert)

  } else if (insertPosition === InsertPosition.replaceAllContent) {

    commandChain
      .clearContent()
      .insertContentAt(0, contentToInsert)

  } else if (insertPosition === InsertPosition.replaceMark) {

    commandChain
      .extendMarkRange(DocumentSchema.VerdiMarks.VerdiMarkType.AiSuggestion)
      .extendMarkRange(DocumentSchema.VerdiMarks.VerdiMarkType.PlaceholderInline)
      .unsetAllMarks()
      // .unsetMark(DocumentSchema.VerdiMarks.VerdiMarkType.AiSuggestion)
      // .unsetMark(DocumentSchema.VerdiMarks.VerdiMarkType.PlaceholderInline)
      // .setMark(DocumentSchema.VerdiMarks.VerdiMarkType.Attribution)
      .insertContent(contentToInsert)

  } else if (insertPosition === InsertPosition.replaceMarkThenAddChild) {

    commandChain
      .extendMarkRange(DocumentSchema.VerdiMarks.VerdiMarkType.AiSuggestion)
      .extendMarkRange(DocumentSchema.VerdiMarks.VerdiMarkType.PlaceholderInline)
      .unsetAllMarks()
      .moveCursorToNextNode()
      .insertNewFreeTextBlock()
      .moveCursorToPreviousNode()
      .tabOnSingleNode()
      .insertContent(contentToInsert)

  } else if (insertPosition === InsertPosition.AsSiblingsOrInEmptyLine) {

    commandChain
      .selectParentNode() // Go up a couple levels closer to the ContentList, so siblings can be added.
      .selectParentNode() // Not sure if this might mess things up in some cases
      .insertContent(contentToInsert)

  } else if (insertPosition === InsertPosition.AsNextRootNode) {

    const nextRootNodeInsertPos = getNextRootNodeInsertPosition(editor.state, editor.state.selection.to)
    commandChain
      .insertContentAt(nextRootNodeInsertPos, contentToInsert)

  } else if (insertPosition === InsertPosition.AsLastChildOfCurrentNode) {

    const childInsertPos = getChildInsertPosition(editor.state, editor.state.selection.to, "lastChild")
    if (childInsertPos) {
      commandChain
        .insertContentAt(childInsertPos, contentToInsert)
    } else {
      // Fallback to inserting as next root node
      const nextRootNodeInsertPos = getNextRootNodeInsertPosition(editor.state, editor.state.selection.to)
      commandChain
        .insertContentAt(nextRootNodeInsertPos, contentToInsert)
    }

  } else {

    commandChain
      .moveCursorToNextNode()
      .insertContent(contentToInsert)
  }

  if (shouldMoveCaretToNextNode) {
    // TODO: Consider adding a new command that smartly checks for children vs siblings vs uncles to move to.
    commandChain.moveCursorToNextNode()
  }

  commandChain.scrollIntoView() // Ensures the new content is visible
  commandChain.run()

  if (shouldAutoOpenCommandMenu) {

    setTimeout(() => {
      showDocEditorCommandMenuCommandDefinition.triggerCommand?.({
        onlyShowIfCurrentLineIsBlank: true,
        docCommandMenuOptions: {
          autoDismissMode: AutoDismissMode.whenLineNoLongerBlank,
        }
      })
      /** TODO: Make this setTimeout not needed
       * Looks like currentBlockContext is stale when the CM is opened
       * Resulting in the line suggestions not reflecting that the current block type is now a "section"
       */
    }, 1000)

  }


}
