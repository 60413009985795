import { useCallback } from "react";
import { useAppDispatch } from "../storeHooks";
import { makeGetRequestJson } from "../../utility-hooks/fetchUtils";
import { ApiTypes, Data } from "@verdi/shared-constants";
import { AssumptionsDetector } from "../../screens/assumptions/AssumptionsDetector"
import { SuggestedAssumption } from "../../screens/assumptions/CreateAssumptionModal";
import { AssumptionsState } from "../assumptionsSlice";
import { DocumentFrameworksProvider } from "../../screens/documentFrameworks/DocumentFrameworksProvider";
import { DocBodySnapshotsState } from "../docBodySnapshotsSlice";
import { AiContextConfig, AllContextForAi } from "../../ai/promptUtils/useAllContextForAi";


export const useAssumptionLoader = (
  documentId: string | undefined,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  const dispatch = useAppDispatch()

  const loadSuggestions = useCallback((existingSuggestions: SuggestedAssumption[]) => {
    if (!documentId) return
    dispatch(AssumptionsState.setSuggestionsLoading(true))
    let existingQuestions: string[] = []
    let dedupedFrameworkQuestions: SuggestedAssumption[] = []
    Promise.allSettled([
      makeGetRequestJson(`documents/${documentId}/related?type=assumption`),
      DocumentFrameworksProvider.getFrameworkQuestionsForDoc(documentId),
    ])
      .then(([assumptions, frameworkQuestions]) => {

        const assumptionBodySnapshots = ((assumptions as any).value as ApiTypes.DocumentSnapshotResponseBody[])
        dispatch(DocBodySnapshotsState.addOrUpdateMany(assumptionBodySnapshots))

        existingQuestions = assumptionBodySnapshots
          .map(a => a.title).concat(existingSuggestions.map(s => s.name))

        dedupedFrameworkQuestions = ((frameworkQuestions as any).value as Data.FrameworkQuestionModel[])
          .filter(fq => !existingQuestions.includes(fq.title)).map(fq => {
            return {
              name: fq.title,
              pendingUserReview: true,
              frameworkQuestionId: fq.id,
            }
          })
        existingQuestions.push(...dedupedFrameworkQuestions.map(fq => fq.name))

        return AssumptionsDetector.detectAssumptions(existingQuestions, getContextForAi)
      })
      .then((suggestions: string[]) => {

        const newSuggestions: SuggestedAssumption[] = existingSuggestions.concat(dedupedFrameworkQuestions).concat(suggestions.filter(s => !existingQuestions.includes(s))
          .map(s => {
            return {
              name: s,
              pendingUserReview: true,
            }
          }))

        dispatch(AssumptionsState.setSuggestedAssumptions({ targetDocId: documentId, assumptions: newSuggestions, loading: false }))
      })
  }, [dispatch, documentId, getContextForAi])

  return { loadSuggestions }

}
