const apiDomain = process.env.REACT_APP_API_DOMAIN
const inviteUrl = () => `${apiDomain}/organization/invite`
const joinUrl = () => `${apiDomain}/organization/join`

export const inviteUserToOrganization = async (email: string) => {
  const response = await (await fetch(
    inviteUrl(), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({
      email
    })
  })).json()
  return response
}

export const validateInvitation = async (token: string) => {
  const response = await (await fetch(
    joinUrl(), {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include",
    body: JSON.stringify({
      token
    })
  })).json()
  return response
}
