import { Data } from "@verdi/shared-constants"
import { makePostRequestJson } from "../../../utility-hooks/fetchUtils"


export const DocumentTaskProvider = {

  upsertTaskCheckbox: async (docId: string, idInDoc: string, fields: Partial<Data.DocumentTasks.DocumentTaskMutableFields>) => {
    const { titlePlainText, ...otherFields } = fields
    const body: Data.DocumentTasks.DocumentTaskDto = {
      ...otherFields,
      titlePlainText: titlePlainText || "",
      docId,
      idInDoc,
    }
    return makePostRequestJson("documentTaskUpsert", body)
  },

}
