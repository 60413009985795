import axios from "axios";
import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay/hooks";
import { commitLocalUpdate, Environment } from "react-relay";
import { networkQuery } from "./__generated__/networkQuery.graphql";
import { Buffer } from "buffer";
import { ApiTypes } from "@verdi/shared-constants";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const mediaDomain = process.env.REACT_APP_MEDIA_DOMAIN;
const websocketDomain = process.env.REACT_APP_WEBSOCKET_DOMAIN;
console.log({ websocketDomain });

const websocketUrl = () => `${websocketDomain}`;
const baseMediaUrl = () => `${mediaDomain}`;
const baseUrl = () => `${apiDomain}`;
const loginUrl = () => `${baseUrl()}/login`;
const registerUrl = () => `${baseUrl()}/register`;
const logoutUrl = () => `${baseUrl()}/logout`;
const googleAuthUrl = () => `${baseUrl()}/googleOAuth`;

const googleClientId = () => process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

export type RegistrationOrgInfoClientSide =
  | {
    orgInviteToken: string;
  }
  | {
    newOrgName: string;
  };

const login = async (
  username: string,
  password: string,
  inviteCode?: string
) => {
  const response = await (
    await fetch(loginUrl(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        username,
        password,
        inviteCode,
      }),
    })
  ).json();
  return response;
};


const register = async (args: ApiTypes.UserRegistrationRequestBody) => {

  const response = await fetch(registerUrl(), {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...args
    }),
  });
  const result = await response.json();
  if (!response.ok || result.error) {
    const error =
      result.error?.errors.join("") || result.error || response.statusText;
    throw new Error(error);
  }
  return result;
};

export const sendPasswordResetRequest = async (email: string) => {
  const response = await axios.post(`${baseUrl()}/reset-password-request`, {
    email,
  });

  if (response.status < 200 || response.status > 299) {
    throw new Error(response.statusText);
  }

  const { data } = response;

  if (!data.success) {
    throw new Error(data.error.message);
  }

  return true;
};

export const sendPasswordReset = async (
  email: string,
  token: string,
  password: string
) => {
  const response = await axios.post(`${baseUrl()}/reset-password`, {
    email,
    token,
    password,
  });

  if (response.status < 200 || response.status > 299) {
    throw new Error(response.statusText);
  }

  const { data } = response;

  if (!data.success) {
    throw new Error(data.error.message);
  }

  return true;
};

const loggedInUserQuery = graphql`
  query networkQuery {
    loggedInUser {
      id
      email
    }
  }
`;

type User = NonNullable<networkQuery["response"]["loggedInUser"]>;

const checkLoginStatus = async (environment: Environment) => {
  try {
    const response = await fetchQuery<networkQuery>(
      environment,
      loggedInUserQuery,
      {}
    ).toPromise();
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();
      const authStatus = root.getOrCreateLinkedRecord(
        "authenticationStatus",
        "AuthenticationStatus"
      );
      const user = authStatus.getOrCreateLinkedRecord("user", "User");
      authStatus.setValue(true, "isLoggedIn");
      let fieldName: keyof User;
      for (fieldName in response?.loggedInUser) {
        user.setValue(response?.loggedInUser?.[fieldName], fieldName);
      }
    });
  } catch (error) {
    console.warn(error);
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();
      const authStatus = root.getOrCreateLinkedRecord(
        "authenticationStatus",
        "AuthenticationStatus"
      );
      authStatus.setValue(null, "user");
      authStatus.setValue(false, "isLoggedIn");
    });
  }
};

const getOrganization = async (orgId: any) => {
  const response = await fetch(
    `${baseUrl()}/organization/${Buffer.from(orgId.data).toString("hex")}`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );
  const result = await response.json();
  if (!response.ok || result.error) {
    const error =
      result.error?.errors.join("") || result.error || response.statusText;
    throw new Error(error);
  }
  return result;
};

const getOrganizationInvite = async (token: string) => {
  const response = await fetch(
    `${baseUrl()}/organization/invitation/${token}`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  );
  const result = await response.json();
  if (!response.ok || result.error) {
    const error =
      result.error?.errors.join("") || result.error || response.statusText;
    throw new Error(error);
  }
  return result;
};

export {
  checkLoginStatus,
  login,
  register,
  baseUrl,
  loginUrl,
  logoutUrl,
  baseMediaUrl,
  googleAuthUrl,
  googleClientId,
  websocketUrl,
  getOrganization,
  getOrganizationInvite,
};
