/**
 * @generated SignedSource<<ab09ed4bc8e1412a74fa9acbbe1c5048>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
export type DeleteDocumentInput = {
  documentId: string;
  shouldHardDelete: boolean;
};
export type useDeleteDocumentMutation$variables = {
  connectionIds: ReadonlyArray<string>;
  input: DeleteDocumentInput;
};
export type useDeleteDocumentMutation$data = {
  readonly deleteDocument: {
    readonly id: string;
    readonly opportunities: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
    }>;
  } | null;
};
export type useDeleteDocumentMutation$rawResponse = {
  readonly deleteDocument: {
    readonly id: string;
    readonly opportunities: ReadonlyArray<{
      readonly documents: ReadonlyArray<{
        readonly childDocs: ReadonlyArray<{
          readonly id: string;
          readonly isVisibleToOrg: boolean;
          readonly title: string | null;
          readonly type: DocumentType;
        }> | null;
        readonly id: string;
        readonly isVisibleToOrg: boolean;
        readonly title: string | null;
        readonly type: DocumentType;
      }>;
      readonly id: string;
    }>;
  } | null;
};
export type useDeleteDocumentMutation = {
  rawResponse: useDeleteDocumentMutation$rawResponse;
  response: useDeleteDocumentMutation$data;
  variables: useDeleteDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectionIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVisibleToOrg",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteDocumentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "deleteDocument",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OpportunityDocumentSideMenu_opportunity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDeleteDocumentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "deleteDocument",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connectionIds"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeChildrenAtTheRoot",
                    "value": true
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "childDocs",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "documents(excludeChildrenAtTheRoot:true)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7eb62292d16861a84c411d6989487628",
    "id": null,
    "metadata": {},
    "name": "useDeleteDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteDocumentMutation(\n  $input: DeleteDocumentInput!\n) {\n  deleteDocument(input: $input) {\n    id\n    opportunities {\n      ...OpportunityDocumentSideMenu_opportunity\n      id\n    }\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  documents(excludeChildrenAtTheRoot: true) {\n    id\n    title\n    type\n    isVisibleToOrg\n    childDocs {\n      id\n      title\n      type\n      isVisibleToOrg\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "173a370722a38c87583a21df827fbb9f";

export default node;
