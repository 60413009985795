import { css } from "@emotion/react";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { OrganizationInvitationListQuery } from "./__generated__/OrganizationInvitationListQuery.graphql";
import { inviteUserToOrganization } from "./network";
import { Stack, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Tag } from "@chakra-ui/react";
import { useState } from "react";
import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary";


const queryQL = graphql`
  query OrganizationInvitationListQuery {
    organizationInvitationConnection {
      edges {
        node {
          invitedEmailAddress
          createdAt
          expiresAt
          id
        }
      }
    }
  }
`;

export function OrganizationInvitationList() {
  const query = useLazyLoadQuery<OrganizationInvitationListQuery>(queryQL, {})

  const getInvitationStatus = (expiration: string) => {
    const date = new Date();
    const expirationDate = new Date(expiration);
    if (date > expirationDate) return 'expired'
    return 'pending'
  }

  const invitationStatusTag = (status: string) => {
    if (status === "expired") {
      return <Tag colorScheme="red">Expired</Tag>
    }
    return <Tag colorScheme="yellow">Pending</Tag>
  }


  const [hasResentInviteToTheseEmails, setHasResentInviteToTheseEmails] = useState<string[]>([])
  const resendInvite = async (userEmail: string) => {
    if (hasResentInviteToTheseEmails.includes(userEmail)) return;
    setHasResentInviteToTheseEmails([...hasResentInviteToTheseEmails, userEmail])
    try {
      await inviteUserToOrganization(userEmail);
    } catch (error) {
      console.log("error", error);
    }
    return;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Stack
        spacing={4}
        css={css`
          width: 100%;
        `}
      >
        <Stack
          css={css`
            -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1);
          `}
        >
          <TableContainer>
            <Table variant="simple" css={css`
              & td {
                padding: 10px;
              }
            `}>
              <Thead>
                <Tr>
                  <Th>Date Sent</Th>
                  <Th>Email</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {query.organizationInvitationConnection.edges.map(
                  (invitation, index) => {
                    const invitationStatus = getInvitationStatus(invitation.node.expiresAt)
                    return (
                      <Tr key={index}>
                        <Td>{new Date(invitation.node.createdAt).toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' })}</Td>
                        <Td>{invitation.node.invitedEmailAddress}</Td>
                        <Td>{invitationStatusTag(invitationStatus)}</Td>
                        <Td>
                          <ButtonSecondary
                            label="Resend Invite"
                            size="xs"
                            onClick={() => resendInvite(invitation.node.invitedEmailAddress)}
                            disabled={hasResentInviteToTheseEmails.includes(invitation.node.invitedEmailAddress)}
                            title={hasResentInviteToTheseEmails.includes(invitation.node.invitedEmailAddress) ? "Invite has already been resent" : "Resend invite"}
                          />
                        </Td>
                      </Tr>
                    )
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  );
}
