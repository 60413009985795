import { GlobalCommandType } from "../GlobalCommandType"


type ShortcutKeyForCommand = {
  key: string
  metaKey: boolean
  ctrlKey: boolean
  commandType: GlobalCommandType
}

/** Centralized place to put all global keyboard shortcuts */
export const allKeyboardShortcuts: ShortcutKeyForCommand[] = [
  {
    key: 'k',
    metaKey: true,
    ctrlKey: false,
    commandType: GlobalCommandType.showCommandMenu,
  },
  {
    key: ' ',
    ctrlKey: true,
    metaKey: false,
    commandType: GlobalCommandType.showCommandMenu,
  },
  {
    key: '/',
    metaKey: true,
    ctrlKey: false,
    commandType: GlobalCommandType.showCommandMenu,
  }
]

/** Returns a command type for the provided keyboard event, if one exists */
export const getCommandTypeFromKeyboardShortcut = (event: KeyboardEvent): GlobalCommandType | undefined => {
  const { key, metaKey, ctrlKey } = event

  const matchingShortcut = allKeyboardShortcuts.find(shortcut => shortcut.key === key && shortcut.metaKey === metaKey && shortcut.ctrlKey === ctrlKey)
  return matchingShortcut?.commandType
}
