
import { CommandMenuSection } from "../../../../screens/document/tiptapEditor/commandMenu/commandList/CommandMenuSection";
import { CommandMenuSectionItem } from "../../../../screens/document/tiptapEditor/commandMenu/commandList/CommandMenuSectionItem";
import { isOnMacOS } from "../../../../utility-hooks/isOnMacOS";
import { ComboBoxController } from "./useComboBoxController";
import { ComboBoxInput } from "./ComboBoxInput";
import { useMemo } from "react";
import { uniqueId } from "lodash";
import { css } from "@emotion/react";
import { renderIconOfType, renderIconOnCommandMenuItem } from "../renderIconOnCommandMenuItem";


const isOnAMac = isOnMacOS();


type Props = {
  comboBoxState: ComboBoxController,
  hasExternalInput?: boolean,
  shouldConstrainLargeText?: boolean,
  maxWidth?: number,
  maxHeight?: number,
}

/** Displays a list of commands along with an input text for filtering */
export const ComboBox = ({
  comboBoxState,
  hasExternalInput,
  shouldConstrainLargeText,
  maxWidth,
  maxHeight,
}: Props) => {


  const {
    filteredCommandGroups,
    searchText,
    setSearchText,
    handleOnKeyDown,
    currentCommandInfo,
    selectCommandByIdInList,
    shouldSelectOnItemHover,
    comboBoxRef,
    noResultsCommand,
    runNoResultsCommand,
    isLoading,
  } = comboBoxState


  /** This makes the element.scrollIntoView() functionality possible  */
  const parentDomSelectorId = useMemo(() => {
    return uniqueId("command-menu-combo-Box-")
  }, [])


  return (
    <div>
      {!hasExternalInput &&
        <ComboBoxInput
          searchText={searchText}
          setSearchText={setSearchText}
          handleOnKeyDown={handleOnKeyDown}
          isLoading={isLoading}
        />
      }
      <div
        ref={comboBoxRef}
        id={parentDomSelectorId}
        onFocus={() => console.log("comboBox has focus")}
        onBlur={() => console.log("comboBox lost focus")}
        css={css`
          padding: 8px;
          margin: 0;
          max-width: ${maxWidth || 450}px;
          overflow-y: auto;
          border-radius: 8px;
          max-height: ${maxHeight || 200}px;
      `}
      >
        <div>
          {filteredCommandGroups.length > 0 && filteredCommandGroups.map((group, groupIndex) =>
            <CommandMenuSection
              key={group.idInList}
              title={group.title || ""}
              hideTitle={group.hideTitle}
              indexInDom={groupIndex}
            >
              {
                group.commands.map((command) =>
                  <CommandMenuSectionItem
                    parentDomSelectorId={parentDomSelectorId}
                    onClick={() => comboBoxState.runCommandAndSetCurrent(command)}
                    onMouseEnter={() => {
                      if (shouldSelectOnItemHover) {
                        selectCommandByIdInList(command.idInList)
                      }
                    }}
                    key={command.name + command.id}
                    title={command.name}
                    description={command.description}
                    commandType={command.aiCommandType}
                    disabled={false}
                    isSelected={command.idInList === currentCommandInfo?.idInList}
                    shortcutKeys={isOnAMac ? command.keyboardShortcutMac : command.keyboardShortcutWin}
                    icon={renderIconOnCommandMenuItem(command)}
                    isLoading={command.isLoading}
                    shouldConstrainLargeText={shouldConstrainLargeText}
                    isSubtle={command.isSubtle}
                  />
                )
              }
            </CommandMenuSection>

          )}

          {!filteredCommandGroups.length && searchText && noResultsCommand &&
            <div>
              <CommandMenuSection
                title={"No results"}
              >
                <CommandMenuSectionItem
                  parentDomSelectorId={parentDomSelectorId}
                  onClick={() => runNoResultsCommand()}
                  title={noResultsCommand?.commandTitle + ": " + searchText}
                  isSelected={true}
                  shouldConstrainLargeText={shouldConstrainLargeText}
                  icon={renderIconOfType(noResultsCommand?.iconType)}
                  isLoading={isLoading}
                />
              </CommandMenuSection>
            </div>
          }
        </div>
      </div>

    </div>
  )
}

