import { DocumentSchema } from "@verdi/shared-constants"
import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { MarkResolved } from "../../../../screens/document/tiptapEditor/utils/findCurrentMarkDetails"
import { docBodyMarkClearCurrentCommandDefinition } from "../../commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearCurrent"
import { replaceMarkWithTextCommandDefinition } from "../../commandDefinitions/documentBody/inlineDocSuggestions/replaceMarkWithText"
import { MarkSuggestion } from "../../../../state/suggestions/suggestionsForMarks"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { loadCurrentMarkSuggestionsCommandDefinition } from "../../commandDefinitions/documentBody/inlineDocSuggestions/loadCurrentMarkSuggestions"
import { loadingCommandPlaceholderCommandDefinition } from "../../commandDefinitions/utils/loadingCommandPlaceholder"


/** This behaves a little differently than other getCmds() functions in that it
 *  returns groups of any commands that are embedded / serialized 
 *  inside marks of the currently marked node, if any 
 * 
 *  see useCurrentMarkState() for more context
*/
export const getCmdsForMark = (
  hasCurrentMark: boolean,
  currentMarkSuggestion: MarkSuggestion | undefined,
  suggestionText: string | undefined,
): VerdiCommandGroup[] | undefined => {

  if (!hasCurrentMark) {
    return []
  }

  const commands = getMarkCommands(currentMarkSuggestion)
  const commandForDismiss = docBodyMarkClearCurrentCommandDefinition.buildMenuItem?.({})

  const commandGroup: VerdiCommandGroup = {
    title: suggestionText || "Suggestions",
    commands: [
      ...commands,
      commandForDismiss
    ],
  }

  return [commandGroup]
}

export const getCurrentMarkSuggestionText = (currentMark: MarkResolved | undefined) => {
  if (!currentMark) {
    return undefined
  }

  const attributesOfMarks = currentMark.node.node.marks.map(m => m.attrs as DocumentSchema.VerdiMarks.AiSuggestionMarkAttributes)
  const suggestionTexts = attributesOfMarks
    .filter(a => a.suggestionText && a.suggestionText.length > 0)
    .map(a => a.suggestionText)

  return suggestionTexts?.[0] || undefined

}

const getMarkCommands = (
  currentMarkSuggestion: MarkSuggestion | undefined,
): VerdiCommand[] => {

  if (!currentMarkSuggestion) {
    return [
      loadCurrentMarkSuggestionsCommandDefinition.buildMenuItem?.({
        commandTitle: "Get suggestions ...",
        lifecycleState: "load-new",
        shouldAbortIfAlreadyLoaded: false, // force a load
      })
    ]
  }

  const commands: VerdiCommand[] = []

  if (currentMarkSuggestion.isLoading) {
    commands.push(loadingCommandPlaceholderCommandDefinition.buildMenuItem({
      loadingMessage: "loading suggestions ...",
    }))
  } else if (currentMarkSuggestion.suggestions && currentMarkSuggestion.suggestions.length > 0) {

    for (const replacement of currentMarkSuggestion.suggestions) {

      commands.push(replaceMarkWithTextCommandDefinition.buildMenuItem?.({
        commandTitle: "Add line about: " + replacement.summaryForCommand,
        textToWrite: replacement.textToAdd,
        InsertPosition: InsertPosition.replaceMarkThenAddChild,
      }))
    }

    commands.push(loadCurrentMarkSuggestionsCommandDefinition.buildMenuItem?.({
      commandTitle: "Refresh ...",
      lifecycleState: "refresh",
      shouldAbortIfAlreadyLoaded: false,
    }))

  } else {

    commands.push(loadCurrentMarkSuggestionsCommandDefinition.buildMenuItem?.({
      commandTitle: "Get suggestions ...",
      lifecycleState: "load-new",
      shouldAbortIfAlreadyLoaded: true,
    }))
  }

  return commands
}
