import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { writeContentToDocBody } from "./writeToDocBody"
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants"
import { Content } from "@tiptap/core"


const cmdType = GlobalCommandType.writeToDocBodyAsText

/** Writes raw text at the current position in the current Prosemirror document  */
export const writeToDocBodyAsTextCommandDefinition: GlobalCommandDefinition<WriteToDocBodyAsTextCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: WriteToDocBodyAsTextCommandArgs) => {
    const title = args.title
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: WriteToDocBodyAsTextCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("writeToDocBodyAsText()", { args })

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('writeToDocBodyAsText: No editor found in servicesForCurrentDoc')
      return
    }

    const { textToWrite } = args

    let contentToInsert: Content = textToWrite
    const hasSpecialMarkup = DocumentSchema.PlaceholderInlineNodeUtils.containsPlaceholderMarkup(textToWrite)
    if (hasSpecialMarkup) {
      contentToInsert = ProsemirrorNodes.makeTitleBlockContent(textToWrite)
    }


    writeContentToDocBody({
      editor,
      contentToInsert,
      insertPosition: args.insertPosition,
      shouldMoveCaretToNextNode: args.shouldMoveCursorToNextNode,
      shouldAutoOpenCommandMenu: args.shouldAutoOpenCommandMenu,
      shouldDeleteSlashCommand: args.shouldDeleteSlashCommand,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: WriteToDocBodyAsTextCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type WriteToDocBodyAsTextCommandArgs = {
  title: string
  textToWrite: string
  insertPosition: InsertPosition,
  shouldMoveCursorToNextNode?: boolean,
  shouldAutoOpenCommandMenu?: boolean,
  shouldDeleteSlashCommand?: boolean,
}
