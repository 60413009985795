import { Edge, EdgeChange, Node, NodeChange, ReactFlowProvider } from "@xyflow/react"
import { MindMapGraph } from "./MindMapGraph"
import { useCallback, useEffect, useMemo } from "react"
import { dispatch } from "../../state/store"
import { MindMapData, MindMapState } from "../../state/MindMapSlice"
import { css } from "@emotion/react"
import { useAppServices } from "../appServices/useAppServices"
import { IdeaNodeWrapperData } from "./internals/nodeTypes/idea/IdeaNode"
import { NodeTypeIds } from "./internals/nodeTypes/NodeTypes"
import { MindMapContextWrapper } from "./internals/context/MindMapContext"
import { DocRelationsState, getRelationsToDocId } from "../../state/docRelationsSlice"
import { useAppSelector } from "../../state/storeHooks"
import { MindMapDataConverter } from "./MindMapDataConverter"
import { DocTagDictionary, DocTagsState } from "../../state/docTagsSlice"
import { TagsState } from "../../state/TagsSlice"
import { NewNodeConfig } from "./internals/nodeTypes/addNew/NewNodeMenuList"
import { DocumentMutableFields } from "../../screens/document/useUpdateDocument"
import { MindMapSuggestionsState, SuggestedIdeaNodeData } from "../../state/MindMapSuggestionsSlice"
import { Data } from "@verdi/shared-constants"
import { deleteDocNoConfirmCommandDefinition } from "../commands/commandDefinitions/document/deleteDocNoConfirm"


type Props = {
  documentId: string
}

/** UI Graph that helps the user think through ideas within a specific document / opportunity */
export const DocumentMindMapGraph = ({
  documentId,
}: Props) => {


  // TODO: Consolidate this file with GlobalMindMapGraph.tsx
  const { toast } = useAppServices()
  const allDocTags = useAppSelector(DocTagsState.getAll)
  const allTags = useAppSelector(TagsState.getAll)

  const docTagsDictionary = useMemo<DocTagDictionary>(() => {

    const toReturn = allDocTags.reduce((acc, docTagMap) => {
      const docId = docTagMap.docId
      const tag = allTags.find(tag => tag.id === docTagMap.tagId)
      if (!tag) return acc
      if (!acc[docId]) {
        acc[docId] = []
      }
      acc[docId].push(tag)
      return acc
    }, {} as DocTagDictionary)

    return toReturn

  }, [allDocTags, allTags])


  const { menuStructureProvider, documentRelationsProvider } = useAppServices()
  const allDocRelations = useAppSelector(DocRelationsState.getAll)
  const allSuggestedNodes = useAppSelector(MindMapSuggestionsState.getAll)



  useEffect(() => {
    /** Set graph data only once, on initial render,
     *  Updates should be handled via dual writing graph data + document relational data (`onChanges()`)
     *  This is mainly to avoid fighting with ReactFlow's internal state and layout updates.
     */
    const dataForGraph = buildInitialGraphData(
      documentId,
      allDocRelations,
      menuStructureProvider.menuItemsStructured.flatMenuItems,
      docTagsDictionary,
      allSuggestedNodes,
    )
    console.log("useEffect for doc graph data", { dataForGraph })
    dispatch(MindMapState.setNodes(dataForGraph.nodes))
    dispatch(MindMapState.setEdges(dataForGraph.edges))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  // onChangedNodes: (changes: NodeChange<Node>[]) => void
  const onChangedNodes = useCallback((changes: NodeChange<Node>[], affectedNodes: Node[]) => {
    console.log("DocMMG: onChangedNodes", { changes, affectedNodes })
    changes.forEach(change => {
      const nodeId = (change as any).id
      const rawNode = affectedNodes.find(n => n.id === nodeId)
      const nodeType = rawNode?.type
      if (nodeType === NodeTypeIds.idea) {
        const node = rawNode as IdeaNodeWrapperData
        if (node.data.docId) {
          // TODO: compare changes then update title, type, etc
          // TODO: Need to debounce
          // TODO: Need to ensure local state is updated
          switch (change.type) {
            case "replace": {
              const changes: DocumentMutableFields = {
                title: node.data.text,
              }
              documentRelationsProvider.updateDocumentDebounced(node.data.docId, changes)
              break
            }
            case "remove": {
              deleteDocNoConfirmCommandDefinition.triggerCommand?.({
                docId: node.data.docId,
                docTitle: node.data.text,
                docType: node.data.docType,
                redirectAfterDelete: false,
              })
            }
          }

        }
        else {
          // No doc id, it is a node suggestion
          // IF we are just editing the current graph state, do we even need to do anything here ???
          switch (change.type) {
            case "remove": {
              dispatch(MindMapSuggestionsState.removeSuggestion({ suggestionId: node.id }))
              break
            }
          }

          // if(change.type = "add"){
          //   // Save the suggestion
          //   dispatch(MindMapSuggestionsState.addSuggestions(node.data.text))
          // }
        }
      }
    })

  }, [])

  // onChangedEdges: (changes: EdgeChange<Edge>[]) => void
  const onChangedEdges = useCallback((changes: EdgeChange<Edge>[]) => {
    console.log("DocMMG: onChangedEdges", { changes })
    // changes.map(change => {
    //   // Determine if both nodes have a docId
    //   // If so, update the edge
    //   // If not, do nothing
    //   // Complications: a grandparent => parent => child, where the parent does not exists
    //   // One option is to have a flat hierarchy, where all documents are direct children of the opportunity
    //   // But in the graph view they are nested ???
    // })

  }, [])


  const onAddNew = useCallback((options: NewNodeConfig) => {
    console.log("On add new clicked (docMindMap)", { options })

  }, [])



  return (
    <ReactFlowProvider>
      <MindMapContextWrapper
        onAddNew={onAddNew}
        onChangedEdges={onChangedEdges}
        onChangedNodes={onChangedNodes}
      >
        <div css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}>

          <MindMapGraph />

        </div>
      </MindMapContextWrapper>
    </ReactFlowProvider>
  )
}



const buildInitialGraphData = (
  documentId: string,
  allDocRelations: Data.DocumentRelationDto[],
  flatMenuItems: Data.Menu.MenuItemData[],
  docTagsDictionary: DocTagDictionary,
  allSuggestedNodes: SuggestedIdeaNodeData[],
) => {

  const docRelationsForDoc = getRelationsToDocId(documentId, allDocRelations, 1)
  const currentDoc = flatMenuItems.find(item => item.id === documentId)
  if (!currentDoc) return {
    nodes: [],
    edges: []
  } as MindMapData

  const relatedDocIds = docRelationsForDoc.map(rel => rel.fromDocId)
  const relatedDocs = flatMenuItems.filter(item => relatedDocIds.includes(item.id))
  const combinedDocs = [currentDoc, ...relatedDocs]

  return MindMapDataConverter.convertMenuStructure(combinedDocs, docTagsDictionary, [currentDoc.id], allSuggestedNodes)
}
