import { Data } from "@verdi/shared-constants"
import { AssumptionsListItem } from "./AssumptionsListItem"
import { css } from "@emotion/react"
import { Divider } from "@chakra-ui/react"
import { useColor } from "../../utility-hooks/useColor"


type Props = {
  label: string
  assumptions: Data.AssumptionModel[]
  currentAssumptionId?: string
  onClick: (assumption: Data.AssumptionModel) => void
  onFocus?: (id: string) => void
  onBlur?: (a: Data.AssumptionModel) => void
}


export const AssumptionListGroup = ({
  label,
  assumptions,
  currentAssumptionId,
  onClick,
}: Props) => {


  const headerColor = useColor("surfaceBg")

  if (assumptions.length === 0) {
    return null
  }

  return (
    <div>
      <Divider marginBottom={0} />
      <div
        css={css`
          display: flex;
          gap: 8px;
          align-items: center;
          font-size: 10px;
          padding-top: 8px;
          padding-left: 8px;
          padding-bottom: 8px;
          background-color: ${headerColor};
        `}
      >
        <span
          css={css`
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.4px;
          `}
        >{label}</span>
        <span
          css={css`
            color: #696F79;
          `}
        >{assumptions.length}</span>
      </div>
      <div >
        {assumptions.map((a, key) =>
          <AssumptionsListItem
            key={key}
            assumption={a}
            isSelected={a.id === currentAssumptionId}
            onClick={() => { onClick(a) }}
          />
        )}
      </div>
    </div>
  )
}
