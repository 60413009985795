import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { dispatch, getCurrentAppState } from "../../../../state/store"
import { AutoAiModeState } from "../../../../state/AutoAiModeSlice"
import { DocMetadataState } from "../../../../state/docMetadataSlice"
import { addFrameworkAiStep } from "./steps/addFrameworkAiStep"
import { AutoAiStepOutcomeType } from "./AutoAiStepOutcomeType"
import { fillInNextPlaceholderAiStep } from "./steps/fillInNextPlaceholderAiStep"
import { documentFrameworkState } from "../../../../state/documentFrameworksSlice"


const cmdType = GlobalCommandType.doNextAutoAiStep

export const doNextAutoAiStepCommandDefinition: GlobalCommandDefinition<DoNextAutoAiStepCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DoNextAutoAiStepCommandArgs) => {
    const title = "Do next auto AI step ..."
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: DoNextAutoAiStepCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    if (shouldStop()) {
      console.log("doNextAutoAiStepCommandDefinition: Auto AI mode is off, skipping")
      onProcessingComplete(true)
      return
    }


    const docFromAi = (await services.getContextForAi()).currentDoc
    const docFromServices = services.servicesForCurrentDoc?.document
    const docSummary = DocMetadataState.getSummary(getCurrentAppState())
    const docFramework = documentFrameworkState.getCurrentDocumentFramework(getCurrentAppState())
    console.log("doNextAutoAiStepCommandDefinition: docSummary", { docSummary, docFromAi, docFromServices })

    let stepToTake = args.step
    if (stepToTake === "auto") {
      if (!docSummary || !docSummary.bodyHasText || !docFramework) {
        stepToTake = "draftAStartingPoint"
      } else if (docSummary.placeholders.length > 0) {
        stepToTake = "fillInPlaceholders"
      } else {
        console.info("doNextAutoAiStepCommandDefinition: No steps to take.")
      }
    }

    if (stepToTake === "draftAStartingPoint") {
      // Auto apply a framework if no framework is selected
      const results = await addFrameworkAiStep(docSummary, services.getContextForAi, shouldStop)
      if (results === AutoAiStepOutcomeType.StoppedByUser) {
        dispatch(AutoAiModeState.setStatus({ isDoingSomething: false, }))
      }
      onProcessingComplete(true)
      return
    }
    if (stepToTake === "fillInPlaceholders") {
      // Fill in placeholders
      console.log("doNextAutoAiStepCommandDefinition: fillInPlaceholders")
      const results = await fillInNextPlaceholderAiStep(docSummary, services.getContextForAi, shouldStop)
      dispatch(AutoAiModeState.setStatus({ isDoingSomething: false, }))
      if (results === AutoAiStepOutcomeType.StoppedByUser) {
        dispatch(AutoAiModeState.setStatus({ isDoingSomething: false, }))
        onProcessingComplete(true)
        return
      }
    }

    onProcessingComplete(true)
  },
  triggerCommand: (args: DoNextAutoAiStepCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type DoNextAutoAiStepCommandArgs = {
  step: "auto" | "draftAStartingPoint" | "fillInPlaceholders"
}

const shouldStop = () => {
  return AutoAiModeState.getIsInAutoAiMode(getCurrentAppState()) ? false : true
}

/** 
 *  WHEN TO TRIGGER THIS COMMAND:
 * 
 *  + On document page load (when all is ready)
 *  + On edit of doc title (On ENTER key press)
 * 
 */


