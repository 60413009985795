import { Data, DocumentSchema, Menu } from "@verdi/shared-constants"
import { NewInterviewNotesModal } from "./NewInterviewNotesModal"
import { DocumentRelationsProvider } from "../useDocumentRelationsProvider"
import { useEffect, useState } from "react"
import { DocumentReference } from "../../../components/DocumentReference"
import { RelatedMenuItemData } from "../data/groupRelatedDocs"
import { BasicDocumentDetails } from "../../document/BasicDocumentDetails"
import { SubSectionWrapper } from "../../opportunityDocument/subMenu/SubSectionWrapper"


type Props = {
  currentDoc: BasicDocumentDetails | Menu.MenuItemData,
  childDocs: Menu.MenuItemData[],
  flatMenuItems: Data.Menu.MenuItemData[]
  relationsProvider: DocumentRelationsProvider,
  docsFromMe: RelatedMenuItemData[]
  docsToMe: RelatedMenuItemData[]
}
export const InterviewNotesList = ({
  currentDoc,
  childDocs,
  flatMenuItems,
  relationsProvider,
  docsFromMe,
  docsToMe,
}: Props) => {

  // const relatedDocs = useMemo(() => {
  //   return relationsProvider.getRelationsForDocGrouped(
  //     currentDoc.id,
  //     relationsProvider.documentRelations,
  //     flatMenuItems,
  //   )
  // }, [currentDoc.id, flatMenuItems, relationsProvider])

  // const createInterviewNote = useCallback(async (newDocTitle: string) => {
  //   const args: CreateNewDocWithRelationArgs = {
  //     currentDocId: currentDoc.id,
  //     direction: "currentDocIsTo",
  //     newDocTitle,
  //     newDocType: DocumentSchema.DocumentType.interviewNotes,
  //     parentDocId: currentDoc.id,
  //     relationType: Data.DocumentRelationType.actionOf, // DEBATE: is there a better relation type?
  //     onError: (e) => {
  //       console.error("Failed to create interview note: ", e)
  //     }
  //   }

  //   console.log("createInterviewNote: args ", args)
  //   // await relationsProvider.createNewDocWithRelation(args)

  // }, [currentDoc.id])

  const [interviewNoteChildren, setInterviewNoteChildren] = useState<Data.Menu.MenuItemData[]>([])
  const [interviewNotesRelatedToMe, setInterviewNotesRelatedToMe] = useState<RelatedMenuItemData[]>([])
  const [interviewNotesRelatedFromMe, setInterviewNotesRelatedFromMe] = useState<RelatedMenuItemData[]>([])
  useEffect(() => {
    const fromMe = docsFromMe.filter(doc => doc.menuItem.type === DocumentSchema.DocumentType.interviewNotes)
    setInterviewNotesRelatedFromMe(fromMe)
    const toMe = docsToMe.filter(doc => doc.menuItem.type === DocumentSchema.DocumentType.interviewNotes)
    setInterviewNotesRelatedToMe(toMe)

    const idsAlreadyShowing = toMe.map(doc => doc.menuItem.id)
    const interviews = childDocs.filter(child =>
      child.type === DocumentSchema.DocumentType.interviewNotes &&
      !idsAlreadyShowing.includes(child.id)
    )
    setInterviewNoteChildren(interviews)

  }, [currentDoc, docsFromMe, docsToMe])



  // const updateTitle = useCallback(async (docId: string, newTitle: string) => {
  //   console.log("updateTitle: docId, newTitle ", docId, newTitle)
  //   setInterviewNoteChildren(interviewNoteChildren.map(interview => {
  //     if (interview.id === docId) {
  //       return {
  //         ...interview,
  //         title: newTitle,
  //       }
  //     }
  //     return interview
  //   }))
  //   await relationsProvider.updateDocTitle(docId, newTitle)
  // }, [setInterviewNoteChildren, relationsProvider, interviewNoteChildren])


  return (
    <SubSectionWrapper
      title="Interviews"
      titleButton={
        <NewInterviewNotesModal
          addButtonText="New Interview"
          guideId={currentDoc.id}
          guideTitle={currentDoc.title || ""}
          opportunityId={""}
          shouldNavigateToNewDoc={false}
        />
      }
      initialAccordionState="expanded"
    >

      {/* {interviewNoteChildren.map((d, key) =>
        <DocumentReference
          key={key}
          title={"1 " + d.title}
          url={d.url}
          intendedUserAction="remove"
          onRemoveClick={() => console.log("DELETE the document, and relationship")}

        />
      )} */}
      {interviewNotesRelatedToMe.map((d, key) =>
        // <Link href={d.menuItem.url}>
        //   {d.menuItem.title}
        // </Link>
        // <SubMenuButton
        //   title={d.menuItem.title}
        //   onClick={() => console.log("Navigate to interview notes")}
        // />
        <DocumentReference
          key={key}
          title={d.menuItem.title}
          url={d.menuItem.url}
          onRemoveClick={() => console.log("Remove relationship")}
        />
      )}
      {/* {interviewNotesRelatedFromMe.map((d, key) =>
        <DocumentReference
          key={key}
          title={"3 " + d.menuItem.title}
          subTitle={d.menuItem.type}
          url={d.menuItem.url}
          intendedUserAction="remove"
          onRemoveClick={() => console.log("Remove relationship")}
        />
      )} */}


    </SubSectionWrapper>
  )
}

// const preFilterForSearch: AddRelatedDocumentFilterOptions = {
//   docTypes: [
//     DocumentSchema.DocumentType.interviewNotes,
//   ],
// }
