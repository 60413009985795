import { Data } from "@verdi/shared-constants"
import { ButtonTertiary } from "../../components/buttons/ButtonTertiary"
import { VerdiIconConfidenceLevel, VerdiIconRelevanceLevel } from "../../components/icons/VerdiIcons"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { useCallback, useEffect, useState } from "react"
import { setAssumptionConfidenceShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/assumption/setAssumptionConfidenceShowOptions"
import { ButtonProps } from "@chakra-ui/react"



/* if assumption is provided, it will be preferred over level */
type Props = {
  assumption?: Data.AssumptionModel
  level?: Data.AssumptionConfidenceLevel
  hideLabel?: boolean
  onSelected?: (option: Data.AssumptionConfidenceLevelOption) => void
}

export const AssumptionConfidenceEditor = ({
  assumption,
  level,
  hideLabel,
  onSelected,
  ...buttonProps
}: Props & ButtonProps) => {

  const localLevel = assumption?.confidenceLevel || level || 1

  const onClick = useCallback((evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.stopPropagation()
    setAssumptionConfidenceShowOptionsCommandDefinition.triggerCommand?.({
      assumption,
      rect: evt.currentTarget.getBoundingClientRect(),
      onSelected
    })
  }, [assumption, onSelected])

  if (hideLabel) {
    const icon = VerdiIconConfidenceLevel({ level: localLevel })
    return (
      <IconButtonTertiary
        aria-label={Data.AssumptionConfidenceLevelOptions[localLevel].label}
        icon={icon}
        onClick={onClick}
        {...buttonProps}
      />
    )
  }

  return (
    <ButtonTertiary
      onClick={onClick}
      label={Data.AssumptionConfidenceLevelOptions[localLevel].label}
      leftIcon={VerdiIconConfidenceLevel({ level: localLevel })}
      variant="outline"
      size="xs"
      {...buttonProps}
    />
  )
}
