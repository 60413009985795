import { useCallback, useState } from "react"
import { AddNewDocGlobalProvider } from "../documents/add/globalModal/useAddNewDocGlobalProvider"
import { CmdKCommandList } from "../../components/commands/commandMenu/cmdk/CmdKCommandList"
import { CmdKCommand } from "../../components/commands/commandMenu/cmdk/CmdKCommand"
import { Data } from "@verdi/shared-constants"


type Props = {
  addNewDocProvider: AddNewDocGlobalProvider
}
export const DocumentFrameworksList = ({
  addNewDocProvider,
}: Props) => {

  const {
    selectedDocFramework,
    setSelectedDocFramework,
  } = addNewDocProvider


  // TODO: Decide to keep this or make it work
  const frameworks: Data.FrameworkModel[] = []


  const onItemClick = useCallback((docFramework: Data.FrameworkModel, isAlreadySelected: boolean) => {
    console.log("onItemClick", { docFramework, selectedDocFramework })
    if (isAlreadySelected) {
      setSelectedDocFramework(undefined)
    } else {
      setSelectedDocFramework(docFramework)
    }
  }, [])

  const cmdKCommands: CmdKCommand[] = frameworks.map(f => ({
    itemId: f.id,
    name: f.title,
    title: f.title,
    onExecute: () => onItemClick(f, f.id === selectedDocFramework?.id),
  }))

  const [searchText, setSearchText] = useState("")


  return (
    <div>
      <CmdKCommandList
        commands={cmdKCommands}
        searchText={searchText}
        setSearchText={setSearchText}
        onCurrentSelectionChange={(itemId => console.log("GOT a thingy ", itemId))}
        searchPlaceholderText="Search for a framework ..."
      />
      {/* <div>
        {documentFrameworks.map((f, key) =>
          <DocumentFrameworkListItem
            key={key}
            documentFramework={f}
            onClick={() => onItemClick(f, f.id === selectedDocFramework?.id)}
            isSelected={f.id === selectedDocFramework?.id}
          />
        )}
      </div> */}
    </div>
  )

}
