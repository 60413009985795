import { VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { doNextAutoAiStepCommandDefinition } from "../../commandDefinitions/autoAi/doNextAutoAiStep"
import { addDocReferenceCommandDefinition } from "../../commandDefinitions/documentBody/addDocReference"
import { addPlaceholderCommandDefinition } from "../../commandDefinitions/documentBody/placeholders/addPlaceholder"
import { loadPlaceholderSuggestionsCommandDefinition } from "../../commandDefinitions/documentBody/placeholders/loadPlaceholderSuggestions"
import { writeToDocBodyDebuggerCommandDefinition } from "../../commandDefinitions/documentBody/writeToDocBodyDebugger"
import { applyFrameworkToDocBodyCommandDefinition } from "../../commandDefinitions/framework/applyFrameworkToDocBody"
import { suggestDocFrameworkCommandDefinition } from "../../commandDefinitions/framework/suggestDocFramework"


export const getCmdsForDebug = () => {

  return [{
    title: "Debug",
    commands: [
      addPlaceholderCommandDefinition.buildMenuItem?.({}),
      applyFrameworkToDocBodyCommandDefinition.buildMenuItem?.({}),
      writeToDocBodyDebuggerCommandDefinition.buildMenuItem?.({}),
      loadPlaceholderSuggestionsCommandDefinition.buildMenuItem?.({}),
      addDocReferenceCommandDefinition.buildMenuItem?.({
        documentTitle: "Stink Reference text",
        documentId: "RG9jdW1lbnQ6MWVlZTE4MzNlMDlhNjk1MDk3ZTgyZjdhZmUxZGI5MGY=",
      }),
      addDocReferenceCommandDefinition.buildMenuItem?.({
        documentTitle: "Limited Reference text",
        documentId: "RG9jdW1lbnQ6MWVlZTE4MzViMjkxNmIxMDk3ZTgyZjdhZmUxZGI5MGY=",
      }),
      addDocReferenceCommandDefinition.buildMenuItem?.({
        documentTitle: "New prod dev Reference text",
        documentId: "RG9jdW1lbnQ6MWVlZTI0OWIzMjQzNmE5MDhmMjM4ZjgwYzFjN2E5OTk=",
      }),
      suggestDocFrameworkCommandDefinition.buildMenuItem?.({}),
      doNextAutoAiStepCommandDefinition.buildMenuItem?.({
        step: "draftAStartingPoint",
      }),
    ]
  }] as VerdiCommandGroup[]
}
