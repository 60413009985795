import { useColorModeValue } from "@chakra-ui/react"


export const useColor = (styleName: StandardStyles) => {

  const lightOrDark = useColorModeValue("light", "dark")

  return `var(--chakra-colors-${styleName}-${lightOrDark})`
}


type StandardStyles =
  "surfaceBg" |
  "surfaceBgSecondary" |
  "surfaceOverlay" |
  "surfaceOverlayBorder" |
  "surfaceBorder" |
  "surfaceBorderActive" |
  "surfaceSelected" |
  "surfaceHotkey" |
  "surfaceHotkeyBorder" |
  "textPrimary" |
  "textSecondary" |
  "textIconSecondary" |
  "itemActiveBg" |
  "itemSelectedBg" |
  "itemCompleted";


/** Abstracted color values that can be connected to data, supported by css vars found in `App.css` */
export type BuiltInColor = "red" | "orange" | "yellow" | "green" | "teal" | "blue";


export const getBuiltInColor = (color: BuiltInColor) => {
  return `var(--built-in-color-${color})`
}

export const getBuiltInColorBg = (color: BuiltInColor) => {
  return `var(--built-in-color-${color}-bg)`
}
