
export const generateAnalyticsId = (company: string, email: string, userId: string) => {

  let parts = []
  parts.push(prepareValue(company))
  parts.push(prepareValue(email))
  parts.push(userId)

  return parts.join('-')
}

const prepareValue = (rawValue: string) => {
  const value = rawValue.replace(/[^a-z0-9]/gi, '').toLowerCase()
  return value.length > 3 ? value.substring(0, 3) : value
}
