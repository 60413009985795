import { useDisclosure } from "@chakra-ui/react"
import { Data } from "@verdi/shared-constants"
import { useCallback, useState } from "react"


/** @deprecated Need to replace this with the global command menu
 * 
 *  Controls the global "Add Document" Modal */
export const useAddNewDocGlobalProvider = () => {

  const disclosure = useDisclosure()
  const [modalTitle, setModalTitle] = useState<string>("")

  const openAddNewDocModal = useCallback((args: { parentDocId: string }, modalTitle: string) => {
    console.log("openAddNewDocModal() ", { disclosure, args })
    setModalTitle(modalTitle)
    disclosure.onOpen()

  }, [disclosure, setModalTitle])

  const [selectedDocFramework, setSelectedDocFramework] = useState<Data.FrameworkModel>()


  return {
    openAddNewDocModal,
    disclosure,
    selectedDocFramework,
    setSelectedDocFramework,
    modalTitle,
  }
}


export type AddNewDocGlobalProvider = ReturnType<typeof useAddNewDocGlobalProvider>
