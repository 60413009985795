import { useCallback, useEffect, useState } from "react"
import { css } from "@emotion/react"
import { useColorModeValue } from "@chakra-ui/react"
import { AiSuggestionButtonList } from "../../components/aiComponents/AiSuggestionButtonList"
import { AutoResizingInputText } from "../../components/forms/AutoResizingInputText"
import { CurrentDocTitleDomElementId } from "./DocumentSettingsEditor"
import { dispatch } from "../../state/store"
import { getSuggestedOpportunitiesAiCall } from "../../ai/prompts/opportunity/getSuggestedOpportunitiesAiCall"
import { mediaQuerySmallWidth } from "../../components/pageLayout/styleHelpers"
import { PlaceholderHint } from "./tiptapEditor/PlaceholderHint"
import { selectedNodeStyles } from "../../components/ColorModeValues"
import { setFocusToCommandDefinition } from "../../components/commands/commandDefinitions/utils/setFocusTo"
import { SuggestionsForDocTitleState } from "../../state/suggestions/suggestionsForDocTitleSlice"
import { useAppSelector } from "../../state/storeHooks"
import { useAppServices } from "../../components/appServices/useAppServices"
import { useComboBoxController } from "../../components/commands/miniCommandMenu/comboBox/useComboBoxController"
import { getDocIdeasFromAiCoachCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/getDocIdeasFromAiCoach"
import { VerdiIconDocument, VerdiIconOpportunity } from "../../components/icons/VerdiIcons"
import { hideDocCommandMenuCommandDefinition } from "../../components/commands/commandDefinitions/utils/hideDocCommandMenu"
import { generateTitleForDocument } from "../../ai/docDrafter/opportunities/generateTitlesForDocument"
import { doNextAutoAiStepCommandDefinition } from "../../components/commands/commandDefinitions/autoAi/doNextAutoAiStep"


type Props = {
  title: string
  description: string | undefined
  onTitleChange: (newTitle: string) => void
  isOpporunity: boolean
}

export const DocumentSettingsTitleEditor = ({
  title,
  description,
  onTitleChange,
  isOpporunity,
}: Props) => {

  const { getContextForAi } = useAppServices()

  const selectedNodeStyle = useColorModeValue(selectedNodeStyles.light, selectedNodeStyles.dark)

  const comboBoxState = useComboBoxController({
    onClose: () => { },
    onRunCommand: (command) => {
      onTitleChange(command.name)
      onReadyToMoveOn()
    },
    mode: "show-all-no-filter",
  })

  const onReadyToMoveOn = useCallback(() => {
    setFocusToCommandDefinition.triggerCommand?.({
      target: "currentDocEditor"
    })
    getDocIdeasFromAiCoachCommandDefinition.triggerCommand?.({
      mode: "loadFromCacheIfExists",
    })
    doNextAutoAiStepCommandDefinition.triggerCommand?.({
      step: "draftAStartingPoint",
    })

  }, [])

  const onChange = useCallback((newValue: string) => {
    onTitleChange(newValue)
    comboBoxState.setSearchText(newValue)
  }, [comboBoxState, onTitleChange])


  // Handle "Press ENTER when done" flow
  const [inputHasFocus, setInputHasFocus] = useState(false)
  const [initialTitleOnFocus, setInitialTitleOnFocus] = useState('')

  const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>((event) => {

    if (event.key === 'Enter') {
      event.preventDefault() // do not add a line break
      if (!comboBoxState.searchText) { // Only select if empty text, preserve any user entered text
        comboBoxState.handleOnKeyDown(event as any as KeyboardEvent)
      }
      onReadyToMoveOn()
    } else {

      comboBoxState.handleOnKeyDown(event as any as KeyboardEvent)
    }

  }, [comboBoxState, onReadyToMoveOn])

  const shouldShowPressEnterHint = Boolean(
    inputHasFocus
    && !!title
    && title !== initialTitleOnFocus
  )


  // Suggested Titles
  const suggestedTitles = useAppSelector(SuggestionsForDocTitleState.getTitles)
  const suggestedTitlesLoading = useAppSelector(SuggestionsForDocTitleState.getIsLoading)
  const failedTooManyTimes = useAppSelector(SuggestionsForDocTitleState.getFailedTooManyTimes)

  useEffect(() => {
    if (failedTooManyTimes) {// Prevent endless loop if failed to load
      return
    }
    if (inputHasFocus
      && !title
      && suggestedTitles.length === 0
      && !suggestedTitlesLoading) {

      loadSuggestedTitles()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHasFocus, title, suggestedTitles, suggestedTitlesLoading, failedTooManyTimes])

  const loadSuggestedTitles = useCallback(async () => {
    dispatch(SuggestionsForDocTitleState.setIsLoading(true))

    try {

      const suggestedTitles = description
        ? await generateTitleForDocument(description, 6)
        : await getSuggestedOpportunitiesAiCall(getContextForAi)

      if (suggestedTitles.length === 0) {
        dispatch(SuggestionsForDocTitleState.incrementFailedAttemptCount())
      }

      dispatch(SuggestionsForDocTitleState.setTitles(suggestedTitles))
    } catch {
      console.warn("Failed to suggest titles")
      dispatch(SuggestionsForDocTitleState.incrementFailedAttemptCount())
    }

    dispatch(SuggestionsForDocTitleState.setIsLoading(false))

  }, [getContextForAi, description])


  useEffect(() => {
    if (inputHasFocus) {
      hideDocCommandMenuCommandDefinition.triggerCommand?.({
        options: {
          shouldSetFocusToEditor: false,
        }
      })
    }
  }, [inputHasFocus])

  return (
    <div css={css`
      width: 100%;
      display: flex;
      flex-direction: column;
    `}>

      <div css={css`
      width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
      `}>

        <div css={css`
          position: relative;
        `}>
          <div css={css`
            position: absolute;
            top: 6px;
            left: 2px;
            opacity: 0.25;
            display: flex;
            align-items: center;
            float: left;
          `}>
            {isOpporunity
              ? <VerdiIconOpportunity boxSize={30} />
              : <VerdiIconDocument boxSize={30} />
            }
          </div>
        </div>

        <AutoResizingInputText
          // ref={documentTitleRef}
          id={CurrentDocTitleDomElementId}
          placeholder="Enter a Title ..."
          css={css`
            border: 'none';
            border-color: transparent;
            border-radius: 4px;
            font-size: 1.6rem;
            font-weight: 700;
            outline: none;
            padding: 0 8px 0 34px;
            margin-left: -8px;
            background: transparent;
            width: 100%;
            resize: none;
            overflow-y: hidden;
            font-family: var(--chakra-fonts-body);
            line-height: var(--chakra-lineHeights-base);
            &:focus {
              outline: none;
              background-color: ${selectedNodeStyle.bg};
              border-color: transparent;
            }
            &:hover , &:focus-visible {
              outline: none;
              border-color: transparent;
              box-shadow: none;
            }
            @media ${mediaQuerySmallWidth} {
              padding-top: 10px;
              font-size: x-large;
              line-height: 1;
            }
          `}
          value={title || ''}
          autoFocus={false}
          onKeyDown={onKeyDown}
          onChange={(event) => onChange(event.target.value)}
          onFocus={() => {
            setInitialTitleOnFocus(title)
            setInputHasFocus(true)
          }}
          onBlur={() => {
            setTimeout(() => { // Delay to allow for click on suggestion in AiSuggestionButtonList
              setInputHasFocus(false)
              setInitialTitleOnFocus('')
            }, 100)
          }}
        />
      </div>

      <div css={css`
        margin-left: 4px;
        margin-top: -4px;
        width: 100%;
      `}>
        <PlaceholderHint
          isVisible={shouldShowPressEnterHint}
          text='Press ENTER when done.'
          size="xs"
        // align="right"
        />
      </div>

      {inputHasFocus && title.length === 0 &&
        <AiSuggestionButtonList
          comboBoxState={comboBoxState}
          isLoading={suggestedTitlesLoading}
          loadingMessage="Thinking ..."
          suggestionNames={suggestedTitles}
          selectedSuggestionName={title}
          shouldFloat={true}
        />
      }

    </div>
  )

}
