import { css } from "@emotion/react"
import { ReactNode } from "react"
import { useColor } from "../../utility-hooks/useColor"
import { useColorModeValue } from "@chakra-ui/react"


export type KanbanCardBadgeProps = {
  icon: ReactNode,
  label: string,
  toolTip: string,
  onClick?: (rect: DOMRect) => void,
}

export const KanbanCardBadge = ({
  icon,
  label,
  toolTip,
  onClick,
}: KanbanCardBadgeProps) => {


  const iconBoxBg = useColor("surfaceBg")
  const borderColor = useColorModeValue("#00000022", "#FFFFFF44")


  return (
    <div title={toolTip}
      onClick={(evt) => {
        if (!onClick) return
        evt.preventDefault()
        evt.stopPropagation()
        const rect = (evt.target as HTMLElement).getBoundingClientRect()
        onClick?.(rect)
      }}
      css={css`
        display: flex;
        gap: 4px;
        border: 1px solid ${borderColor};
        border-radius: 4px;
        font-size: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        background-color: ${iconBoxBg};
        ${onClick ? css`
          cursor: pointer;
          &:hover {
            background: var(--chakra-colors-gray-100);
          }
        ` : ""}
    `}>

      <div>
        {icon}
      </div>

      <div>
        {label}
      </div>

    </div>
  )
}
