
/** TODO: Put all known commands here. IE keyboard commands, AI commands, chat commands, etc */
export enum GlobalCommandType {
  addOpportunityShowOptions = "addOpportunityShowOptions",
  addDocAsAction = "addDocAsAction",
  addDocAutoHandleType = "addDocAutoHandleType",
  addDocFromSelection = "addDocFromSelection",
  addDocReference = "addDocReference",
  addDocShowOptions = "addDocShowOptions",
  addDocWithFramework = "addDocWithFramework",
  addOpportunity = "addOpportunity",
  addPlaceholder = "addPlaceholder",
  addSectionWithAi = "addSectionWithAi",
  addSectionWithAiShowMore = "addSectionWithAiShowMore",
  aiContextShowOptions = "aiContextShowOptions",
  applyFrameworkToDocBody = "applyFrameworkToDocBody",
  applyIdeaToDoc = "applyIdeaToDoc",
  autoDraftFullDoc = "autoDraftFullDoc",
  autoDraftFullDocConfirm = "autoDraftFullDocConfirm",
  continueWriting = "continueWriting",
  continueWritingFromTemplatedAiPrompt = "continueWritingFromTemplatedAiPrompt",
  deleteDoc = "deleteDoc",
  deleteDocNoConfirm = "deleteDocNoConfirm",
  docBodyMarkClearAll = "docBodyMarkClearAll",
  docBodyMarkClearCurrent = "docBodyMarkClearCurrent",
  docBodyMarkCreate = "docBodyMarkCreate",
  doNextAutoAiStep = "doNextAutoAiStep",
  draftInterviewGuide = "draftInterviewGuide",
  draftResearchPlan = "draftResearchPlan",
  fillInSection = "fillInSection",
  findAndReplace = "findAndReplace",
  formatNodeAsSection = "formatNodeAsSection",
  formatNodeAsTaskCheckbox = "formatNodeAsTaskCheckbox",
  formatNodeAsText = "formatNodeAsText",
  formatNodeAsQuestion = "formatNodeAsQuestion",
  getDocIdeasFromAiCoach = "getDocIdeasFromAiCoach",
  getSuggestionsForWhatIsNext = "getSuggestionsForWhatIsNext",
  hideDocCommandMenu = "hideDocCommandMenu",
  inlineDocSuggestionsGenerate = "inlineDocSuggestionsGenerate",
  loadPlaceholderSuggestions = "loadPlaceholderSuggestions",
  loadCurrentMarkSuggestions = "loadCurrentMarkSuggestions",
  loadCurrentPlaceholderSuggestions = "loadCurrentPlaceholderSuggestions",
  loadingCommandPlaceholder = "loadingCommandPlaceholder",
  mindMapNodeSuggestionAccept = "mindMapNodeSuggestionAccept",
  moveDocToParent = "moveDocToParent",
  moveSelectionTo = "moveSelectionTo",
  navigateTo = "navigateTo",
  navigateToAddAssumption = "navigateToAddAssumption",
  removePlaceholder = "removePlaceholder",
  replaceMarkWithText = "replaceMarkWithText",
  replacePlaceholderWith = "replacePlaceholderWith",
  reviewAsAudience = "reviewAsAudience",
  reviewAsAudienceClearAll = "reviewAsAudienceClearAll",
  reviewAsAudienceShowOptions = "reviewAsAudienceShowOptions",
  runAIPromptOnDoc = "runAIPromptOnDoc",
  setAssumptionConfidence = "setAssumptionConfidence",
  setAssumptionConfidenceShowOptions = "setAssumptionConfidenceShowOptions",
  setAssumptionRelevance = "setAssumptionRelevance",
  setAssumptionRelevanceShowOptions = "setAssumptionRelevanceShowOptions",
  setDocAccess = "setDocAccess",
  setDocAccessShowOptions = "setDocAccessShowOptions",
  setDocFramework = "setDocFramework",
  setDocFrameworkNone = "setDocFrameworkNone",
  setDocFrameworkShowOptions = "setDocFrameworkShowOptions",
  setFocusTo = "setFocusTo",
  setTags = "setTags",
  setTagsShowOptions = "setTagsShowOptions",
  showAddAction = "showAddAction",
  showAddFrameworkToDoc = "showAddFrameworkToDoc",
  showAddDocModal = "showAddDocModal",
  showChat = "showChat",
  showCommandMenu = "showCommandMenu",
  showConfirmDialog = "showConfirmDialog",
  showCustomAIPrompt = "showCustomAIPrompt",
  showDocEditorCommandMenu = "showDocEditorCommandMenu",
  showDocInfoCommandMenu = "showDocInfoCommandMenu",
  showMenuItemSearch = "showMenuItemSearch",
  showMiniCommandMenu = "showMiniCommandMenu",
  showMoreOptions = "showMoreOptions",
  showMoveDocToParent = "showMoveDocToParent",
  startNewMindMap = "startNewMindMap",
  suggestDocFramework = "suggestDocFramework",
  createQuickNote = "createQuickNote",
  createOpportunity = "createOpportunity",
  exploreIdeaWithAi = "exploreIdeaWithAi",
  updateOpportunity = "updateOpportunity",
  workOnGoal = "workOnGoal",
  writeToDocBody = "writeToDocBody",
  writeToDocBodyAsText = "writeToDocBodyAsText",
  writeToDocBodyDebugger = "writeToDocBodyDebugger",
}
