import { Button, useColorModeValue } from "@chakra-ui/react"
import { VerdiButtonProps } from "./VerdiButtonProps"


export const ButtonTertiary = (props: VerdiButtonProps) => {


  const isLightMode = useColorModeValue(true, false)


  return (
    <Button
      colorScheme="verdiButtonTertiary"
      color={isLightMode ? "#222222" : "white"}
      size="sm"
      aria-label={props.hint}
      title={props.hint}
      _hover={{
        opacity: 0.7,
      }}
      {...props}
    >
      {props.label || props.children}
    </Button>
  )

}
