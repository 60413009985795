import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface IAiContextConfigState {
  isShowingModal: boolean
  isLoading: boolean
}

const initialState: IAiContextConfigState = {
  isShowingModal: false,
  isLoading: false,
}

export const aiContextConfigSlice = createSlice({
  name: 'aiContextConfig',
  initialState,
  reducers: {
    setIsShowingModal: (state, action: PayloadAction<boolean>) => {
      state.isShowingModal = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
  selectors: {
    getIsShowingModal: (state) => state.isShowingModal,
    getIsLoading: (state) => state.isLoading,
  }
})

export const AiContextConfigState = {
  ...aiContextConfigSlice.actions,
  ...aiContextConfigSlice.selectors
}

export default aiContextConfigSlice.reducer
