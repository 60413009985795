import { Command } from "cmdk"
import { CmdKCommand } from "./CmdKCommand"
import { css } from "@emotion/react"
import { useColor } from "../../../../utility-hooks/useColor"
import { CommandMenuSectionItemContent } from "../../../../screens/document/tiptapEditor/commandMenu/commandList/CommandMenuSectionItemContent"


type Props = {
  command: CmdKCommand
  onAfterExecute?: () => void
}

export const CmdKCommandListItem = ({
  command,
  onAfterExecute,
}: Props) => {

  const {
    itemId,
    title,
    shortcutKeys,
    onExecute,
    icon,
    isLoading,
  } = command


  const selectBgColor = useColor("itemSelectedBg")


  return (
    <Command.Item
      key={title}
      value={title}
      onSelect={(...args) => {
        onExecute?.()
        onAfterExecute?.()
      }}
      css={css`
        justify-content: space-between;
        &[data-selected="true"] {
        background-color: ${selectBgColor};
        }
      `}
    >
      <CommandMenuSectionItemContent
        title={title}
        icon={icon}
        shortcutKeys={shortcutKeys}
        isLoading={isLoading}
      />

    </Command.Item>
  )

}
