import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { dispatch } from "../../../../state/store"
import { loadingStatusState } from "../../../../state/loadingStatusSlice"
import { DocumentIdea } from "../../../../state/docIdeasSlice"
import { applyIdeaToDocAiCall } from "../../../../ai/prompts/ideas/applyIdeaToDocAiCall"
import { runFunctionFromAiResponse } from "../../../../ai/prompts/ideas/runFunctionFromAiResponse"


const cmdType = GlobalCommandType.applyIdeaToDoc


/** Dynamically adds a conceptual idea to the current document body, 
 * which may include replacing text or adding new lines */
export const applyIdeaToDocCommandDefinition: GlobalCommandDefinition<ApplyIdeaToDocCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ApplyIdeaToDocCommandArgs) => {
    const title = "Apply idea to document"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        description: '',
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ApplyIdeaToDocCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { ideaToApply } = args
    if (!ideaToApply) {
      console.warn("applyIdeaToDoc: No idea to apply")
      args.onComplete?.()
      onProcessingComplete(false)
      return
    }

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Applying to document ...",
    }))

    const functionToRun = await applyIdeaToDocAiCall(services.getContextForAi, ideaToApply)
    if (functionToRun) {
      runFunctionFromAiResponse(functionToRun)
    }

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
      message: "",
    }))

    args.onComplete?.()
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: ApplyIdeaToDocCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ApplyIdeaToDocCommandArgs = {
  ideaToApply: DocumentIdea
  onComplete?: () => void
}
