import { DocumentSchema, Menu } from "@verdi/shared-constants"
import { makeGetRequestJson } from "../../../utility-hooks/fetchUtils"
import { getUrlForDocument, getUrlForStandaloneDocument } from "../../../routes"


export const MAX_MENU_DEPTH = 10

export class MenuStructureManager {

  private static _singletonInstance: MenuStructureManager
  public static getSingletonInstance(): MenuStructureManager {
    if (!this._singletonInstance) {
      this._singletonInstance = new MenuStructureManager()
    }
    return this._singletonInstance
  }


  /** Load all menu items from the api */
  public async loadMenuData() {
    if (this.isLoadingData) return false
    this.isLoadingData = true
    const response = await makeGetRequestJson("documentMenu")
    const processed = processMenuData(response)

    this.isLoadingData = false
    return processed
  }
  private isLoadingData = false


  /** Gets a document from the api */
  public async getDocument(docId: string) {
    const response = await makeGetRequestJson(`documentMenu/${docId}`) as Menu.RawMenuItemData
    const newDoc = processRawMenuItemData(response)
    return newDoc
  }


  /** Facilitates updating a menu item in an array */
  public static replaceMenuItem(updatedDoc: Menu.MenuItemData, flatMenuItems: Menu.MenuItemData[]) {
    const itemIndex = flatMenuItems.findIndex(item => item.id === updatedDoc.id)
    if (itemIndex < 0) {
      console.warn(`Could not find item with id ${updatedDoc.id}`)
      return flatMenuItems
    }
    return [...flatMenuItems.slice(0, itemIndex), updatedDoc, ...flatMenuItems.slice(itemIndex + 1)]

  }

}


export type MenuDataGroups = {
  quickNotes: Menu.MenuItemData[],
  opportunitiesWithMixedChildren: Menu.MenuItemData[],
  opportunitiesWithOnlyOpportunityChildren: Menu.MenuItemData[],
  rootLevelDocs: Menu.MenuItemData[],
  flatMenuItems: Menu.MenuItemData[],
}

export const processMenuData = (items: Menu.RawMenuItemData[]): MenuDataGroups => {

  const flatMenuItems = items.map(processRawMenuItemData)
  const theMenu = hydrateDocumentMenuStructure(flatMenuItems)
  return theMenu
}

export const processRawMenuItemData = (item: Menu.RawMenuItemData): Menu.MenuItemData => {

  const { id,
    title,
    description,
    type,
    parentDocId,
    opportunityId,
    createdByUserId,
    isVisibleToOrg,
    createdAt,
    deletedAt,
  } = item

  return {
    id,
    title,
    description,
    parentDocId,
    createdByUserId,
    isVisibleToOrg,
    children: [],
    type: type as any as DocumentSchema.DocumentType,
    createdAt,
    deletedAt,
    opportunityId,
    url: opportunityId ? getUrlForDocument(id, opportunityId) : getUrlForStandaloneDocument(id),
  } as Menu.MenuItemData
}


export const typesToExcludeFromNestedMenu = [
  // DocumentSchema.DocumentType.assumption,
  DocumentSchema.DocumentType.insight,
  DocumentSchema.DocumentType.concept,
]


export const hydrateDocumentMenuStructure = (flatMenuItemsInput: Menu.MenuItemData[]) => {

  // Avoid mutable object weirdness by returning a clone
  const flatMenuItems = JSON.parse(JSON.stringify(flatMenuItemsInput)) as Menu.MenuItemData[]
  flatMenuItems.forEach(item => item.children = [])

  const rootLevelDocs = flatMenuItems.filter(item => !item.parentDocId)
  const childDocs = flatMenuItems
    .filter(item =>
      item.parentDocId
      && !typesToExcludeFromNestedMenu.includes(item.type))

  // nest children under parents
  childDocs.forEach(child => {
    const parent = flatMenuItems.find(root => root.id === child.parentDocId)
    if (parent) {
      parent?.children.push(child)
      // IDEA: Could put reference to parent here
    }
  })
  const opportunitiesWithMixedChildren = rootLevelDocs.filter(doc => doc.type === DocumentSchema.DocumentType.opportunity)
  const opportunitiesWithOnlyOpportunityChildren = filterToOnlyOpportunities(opportunitiesWithMixedChildren)

  const quickNotes = rootLevelDocs
    .filter(doc => doc.type === DocumentSchema.DocumentType.quickNote)
    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)


  const toReturn = {
    quickNotes,
    opportunitiesWithMixedChildren,
    opportunitiesWithOnlyOpportunityChildren,
    rootLevelDocs,
    flatMenuItems,
  } as MenuDataGroups


  // Temp fix: On create new opportunity, the type does not get set with graphQL.
  if (flatMenuItems.some(item => item.type === undefined)) {
    const itemsWithUndefinedType = flatMenuItems.filter(item => item.type === undefined)
    toReturn.opportunitiesWithOnlyOpportunityChildren.push(...itemsWithUndefinedType)
  }

  return toReturn
}

/** Returns only opportunities. Any child items will also only be opportunities */
const filterToOnlyOpportunities = (menuItems: Menu.MenuItemData[]) => {
  const onlyOpportunities = menuItems.filter(item => item.type === DocumentSchema.DocumentType.opportunity)
    .map(item => {
      const newItem = { ...item }
      newItem.children = filterToOnlyOpportunities(item.children)
      return newItem
    })
  return onlyOpportunities

}
