import { EditorView } from "prosemirror-view";
import { findAttributeOnNodeTree } from "./findAttributeOnNodeTree";
import {
  ContentNodeWithPos,
  findParentNodeClosestToPos,
} from "prosemirror-utils";
import VeNode from "../VeNode";
import { AttributeNames, DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants";
import { EditorState } from "prosemirror-state";
import { SectionInfo, getSectionInfo } from "./getSectionInfo";
import { ResolvedPos } from "@tiptap/pm/model";
import { MarkResolved, findCurrentMarkDetailsFromResolvedPos } from "./findCurrentMarkDetails";
import { AtomicNodeInfo, getAtomicNodeInfo } from "./getAtomicNodeInfo";
import { getCurrentMarkSuggestionText } from "../../../../components/commands/commandMenuData/getCmds/getCmdsForMark";


export type DomPositionRect = {
  top: number;
  left: number;
  right: number;
  width: number;
  height: number;
}

export type SelectionRange = {
  from: number;
  to: number;
};

export type CurrentBlockContext = {
  view: EditorView;
  node: ContentNodeWithPos;
  veNode?: VeNode;
  domElement?: HTMLBaseElement;
  /** dom rect of the current block / node, according to prosemirror */
  domPositionBlock?: DomPositionRect;
  /** dom rect of the current caret / selection, according to the browser */
  domPositionCaret?: DomPositionRect;
  aiPromptId?: string;
  aiPromptInsertRange: SelectionRange;
  currentSelectionRange?: SelectionRange;
  currentSelectText?: string;
  currentLineText?: string;
  endOfNodePosition?: number;
  nearestNestableNodeType?: ProsemirrorNodes.NestableBlockType;
  editorHasFocus?: boolean;
  /** The closest parent with node type of "section" */
  parentSectionInfo?: SectionInfo;
  resolvedPos?: ResolvedPos;
  currentMark?: MarkResolved;
  currentPlaceholderAttrs?: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes;
  currentMarkSuggestionText?: string;
  /** Includes attributes of special child nodes, such Placeholders and DocReference Nodes */
  currentAtomicNodeInfo?: AtomicNodeInfo
  docIsEmpty: boolean;
};

export const getCurrentBlockContext = (
  view: EditorView,
  position: number,
  window: Window
): CurrentBlockContext | undefined => {

  const resolvedPos = view.state.doc.resolve(position);
  const currentNode = findParentNodeClosestToPos(resolvedPos, (node) => true);
  if (!currentNode) {
    return undefined;
  }
  const specificNode = VeNode.fromPosition(view.state, position)
  const currentAtomicNodeInfo = getAtomicNodeInfo(currentNode, resolvedPos)
  const currentPlaceholderAttrs = currentAtomicNodeInfo?.placeholderAttrs

  let currentLineText = DocumentSchema.getPlainTextFromTitleNode(currentNode.node)

  const {
    attributeValue: aiPromptId,
    insertRange: aiPromptInsertRange,
    // nodeWithAttribute: nodeWithAiPrompt,
    nearestNestableNodeType,
  } = findAttributeOnNodeTree(view, position, AttributeNames.aiPromptId);

  const selection = view.state.selection;
  let selectedText = "";
  let selectedRange: SelectionRange | undefined;
  if (!selection.empty) {
    selectedText = view.state.selection
      .content()
      .content.textBetween(
        0,
        view.state.selection.content().content.size,
        "\n"
      );
    selectedRange = { from: selection.from, to: selection.to };
    // console.log(`selectedRange = ${selectedRange}, selectedText = ${selectedText}`)
  }

  // Here just for debugging. This is called in TipTapEditor.
  // getPosAtEndOfCurrentNode(view.state, currentNode.pos)

  // let veNode
  // const selection = view.state.selection
  // if (selection.empty) {
  //   const theNode = VeNode
  //     .fromResolvedPosition(selection.$anchor)
  //     ?.findNestableParent(view.state)

  //   const nodeText = getSelectedBlockText(view.state.selection.$anchor)
  //   const nodePos = view.state.selection.$anchor.posAtIndex(0)
  //   veNode = VeNode.fromResolvedPosition(view.state.selection.$anchor)
  // }

  // console.log("the bounds = ", veNode?.bounds)

  const domAtPos = view.domAtPos(currentNode.pos)?.node;
  const currentDomElement = domAtPos as HTMLBaseElement;
  const targetDomElement =
    currentDomElement.closest("[aipromptid]") || currentDomElement;

  const { domPositionBlock, domPositionCaret } = getDomRects(targetDomElement)
  const currentMark = findCurrentMarkDetailsFromResolvedPos(view.state, resolvedPos)
  const currentMarkSuggestionText = currentMark ? getCurrentMarkSuggestionText(currentMark) : undefined

  const docIsEmpty = view.state.doc.textContent.trim() === ""

  return {
    view,
    // veNode,
    aiPromptId,
    aiPromptInsertRange,
    domElement: currentDomElement,
    node: currentNode,
    domPositionBlock: domPositionBlock,
    domPositionCaret,
    currentSelectionRange: selectedRange,
    currentSelectText: selectedText,
    nearestNestableNodeType,
    parentSectionInfo: getSectionInfo(resolvedPos),
    resolvedPos,
    currentMark,
    currentMarkSuggestionText,
    currentLineText,
    currentPlaceholderAttrs,
    currentAtomicNodeInfo,
    docIsEmpty,
  };
};

/** Gets the dom rects of both the block (typically a section) and the caret/selection position */
const getDomRects = (DomElementOfBlock: Element): DomRects => {
  const toReturn: DomRects = {}

  if (DomElementOfBlock) {
    let blockRect = DomElementOfBlock.getBoundingClientRect();

    toReturn.domPositionBlock = {
      top: blockRect.top + window.scrollY,
      left: blockRect.left + window.scrollX,
      right: blockRect.right,
      width: blockRect.width,
      height: blockRect.height,
    };
  }

  var selection = window.getSelection()
  if (selection) {
    try {
      var getRange = selection.getRangeAt(0)
      const selectionRect = getRange.getBoundingClientRect()
      if (selectionRect.top !== 0 && selectionRect.left !== 0) {
        // Only set if position is not 0,0
        // when 0s, it can't not find the element position. Potentially is `display: none`
        toReturn.domPositionCaret = {
          top: selectionRect.top + window.scrollY,
          left: selectionRect.left + window.scrollX,
          right: selectionRect.right,
          width: selectionRect.width,
          height: selectionRect.height,
        }
      }
    } catch { }
  }

  return toReturn
}
type DomRects = {
  domPositionBlock?: DomPositionRect
  domPositionCaret?: DomPositionRect
}



export const getPosAtEndOfCurrentNode = (
  state: EditorState,
  position: number
) => {
  const resolvedPos = state.doc.resolve(position);
  // const nodey = resolvedPos.node(resolvedPos.depth)
  // console.log("nodey.type.name = ", nodey.type.name)
  const endOfNodePosition = resolvedPos.end(resolvedPos.depth);

  // const veNode = VeNode.fromResolvedPosition(state.selection.$anchor)
  // let endPosition
  // if (veNode) {
  //   const parent = veNode.findParentWithType(state, ["freeText"])
  //   if (parent) {
  //     const titleResolvedPos = state.doc.resolve(parent.pos())
  //     const end = titleResolvedPos.end(titleResolvedPos.depth)
  //     endPosition = end - 1
  //   }
  // }

  return endOfNodePosition;
};

// const getBlockType = (currentNode: ContentNodeWithPos, view: EditorView) => {
//   const parentContainerPos = currentNode.start - 1;
//   const parentContainer = findParentNodeClosestToPos(
//     view.state.doc.resolve(parentContainerPos),
//     (node) => true
//   );

//   // freeText | section | question <= blockType (from the parent container)
//   // title                       <= currentNode
//   // text

//   return parentContainer?.node.type.name || "";
// };
