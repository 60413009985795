import { css } from "@emotion/react";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { Stack, Table, Tbody, Tr, Td, TableContainer, Avatar } from "@chakra-ui/react";
import { OrganizationUserListQuery } from "./__generated__/OrganizationUserListQuery.graphql";
import { useAppSelector } from "../../../state/storeHooks";
import { getCurrentOrgPlan } from "../../../state/PlanSlice";
import { getAllUsersAndPermissions, setAllUserPermissions, setAllUsers, updateUserPermission } from "../../../state/currentOrgSlice";
import { useCallback, useEffect, useMemo } from "react";
import { dispatch } from "../../../state/store";
import { ApiTypes, Data } from "@verdi/shared-constants";
import { makeGetRequestJson, makePostRequestJson } from "../../../utility-hooks/fetchUtils";
import { PermissionEditor } from "./PermissionEditor";
import { useAppServices } from "../../../components/appServices/useAppServices";
import { useFeatureFlags } from "../../../utility-hooks/useFeatureFlags";



const queryQL = graphql`
  query OrganizationUserListQuery {
    organizationUserConnection {
      edges {
        node {
          id
          email
          name
          roleTitle
        }
      }
    }
  }
`
type Props = {
  currentUserId?: string
}

export function OrganizationUserList({
  currentUserId
}: Props) {

  const { loggedInUserProvider, toast } = useAppServices()

  // TODO: Remove this graphQL query in favor of express endpoint
  const query = useLazyLoadQuery<OrganizationUserListQuery>(queryQL, {})

  const plan = useAppSelector(getCurrentOrgPlan)
  const usersAndPermissions: Data.User.UserWithPermission[] = useAppSelector(getAllUsersAndPermissions)

  const orgId = useMemo(() => {
    return loggedInUserProvider?.loggedInUser?.organization.id
  }, [loggedInUserProvider])

  const { experimentalEnabled } = useFeatureFlags()


  useEffect(() => {
    Promise.allSettled([
      makeGetRequestJson("allUsersInCurrentOrg"),
      makeGetRequestJson("AllUserPermissionsForOrg"),
    ]).then((results) => {
      const [usersResults, permissionsResults] = results
      console.log("users", results)

      dispatch(setAllUsers((usersResults as any).value as Data.User.UserBasicInfo[]))
      dispatch(setAllUserPermissions((permissionsResults as any).value as Data.UserPermissionForOrg.UserPermissionForOrgModel[]))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onPermissionPropertyChanged = useCallback(async (
    userId: string,
    propertyName: keyof Data.UserPermissionForOrg.UserPermissionForOrgModel,
    newPropertyValue: boolean,
  ) => {

    if (!orgId) {
      console.warn("No orgId locally")
      return
    }
    const changes: Partial<Data.UserPermissionForOrg.UserPermissionForOrgModel> = {
      [propertyName]: newPropertyValue
    }
    const body: ApiTypes.UserPermissionForOrgUpsertBody = {
      orgId,
      userId,
      fields: changes,
    }
    try {
      const results = (await makePostRequestJson("UpdateUserPermissionForOrg", body)) as Data.UserPermissionForOrg.UserPermissionForOrgModel
      dispatch(updateUserPermission({ userId, orgId, changes }))
    } catch (e) {
      console.error("Error updating user permission", { e })
      toast.showError(`Error: ${e}`)
    }
  }, [orgId, toast, dispatch])

  if (!orgId) {
    return null
  }



  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Stack
        spacing={4}
        css={css`
          width: 100%;
        `}
      >

        <Stack
          css={css`
            -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1);
          `}
        >
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                {query.organizationUserConnection.edges.map(
                  (edge, index) => {
                    const user = edge.node
                    return (
                      <Tr key={index}>
                        <Td css={css`
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: space-between;
                          gap: 10px;

                        `}>
                          <div css={css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;
                          `}>
                            <div>
                              <Avatar name={user.name || user.email} size="sm" />
                            </div>
                            <div>
                              <strong>
                                {user.name}
                                {user.id === currentUserId &&
                                  <small css={css`
                                    padding-left: 4px;
                                    opacity: 0.7;
                                  `}>
                                    (You)
                                  </small>
                                }
                              </strong>
                              <p>
                                {user.roleTitle}
                              </p>
                              <small>
                                {user.email}
                              </small>

                            </div>
                          </div>

                          {experimentalEnabled &&
                            <PermissionEditor
                              userWithPermission={usersAndPermissions.find(p => p.user.id === user.id) as Data.User.UserWithPermission}
                              onPermissionPropertyChanged={onPermissionPropertyChanged}
                            />
                          }

                        </Td>
                      </Tr>
                    )
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  );
}
