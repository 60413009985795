import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react'
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation'
import { CreateChatMessageInput, useCreateChatMessageMutation } from './__generated__/useCreateChatMessageMutation.graphql'
import { UseMutationConfig } from 'react-relay'


const mutationQL = graphql`
  mutation useCreateChatMessageMutation (
    $input: CreateChatMessageInput!
    $updateConnectionIds: [ID!]!
  ) {
    createChatMessage(input: $input) 
    @appendNode(connections: $updateConnectionIds, edgeTypeName: "ChatMessageEdge")
    {
      id
      role
      message
      createdAt
      dynamicJsonRawString
    }
  }
`

export type OnNewDocumentMutationCompleted = UseMutationConfig<useCreateChatMessageMutation>['onCompleted']


export const useCreateChatMessage = (
  updateConnectionIds: string[] = [],
  onCompleted?: OnNewDocumentMutationCompleted,
) => {

  const [commit, isSaving] = useErrorHandledMutation<useCreateChatMessageMutation>(mutationQL)
  const createChatMessage = useCallback((input: CreateChatMessageInput) => {
    commit({
      variables: {
        input,
        updateConnectionIds,
      },
      onCompleted,
    })
  }, [
    updateConnectionIds,
    commit,
  ])

  return { createChatMessage, isSaving }
}
