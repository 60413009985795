import { Extension } from "@tiptap/core";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import { Plugin } from "prosemirror-state";

const keepSelectionOnBlurPlugin = new Plugin({
  props: {
    decorations(state) {
      const selection = state.selection;
      const selectionDecoration = Decoration.inline(selection.from, selection.to, {
        class: 'text-selected-by-user',
        nodeName: "span"
      });
      return DecorationSet.create(state.doc, [selectionDecoration]);
    },
  }
})

/** By default when blur happens to a document, the current text selection disappears */
export const keepSelectionOnBlurExtension = Extension.create({
  name: 'keepSelectionOnBlur',
  addProseMirrorPlugins() {
    return [
      keepSelectionOnBlurPlugin,
    ]
  },
})
