import { Button, ButtonProps } from "@chakra-ui/react"
import { VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { CommandForNoResultsOptions, ShowMiniCommandMenuCommandArgs, showMiniCommandMenuCommandDefinition } from "../commandDefinitions/utils/showMiniCommandMenu"
import { useCallback, useRef } from "react"
import { css } from "@emotion/react"


export type SelectCommandDropdownProps = ButtonProps & {
  label: string
  /** Specify either an array of commands that will be automatically injected into a pop up CM
   * 
   *  Or a callback function that includes the dom rect for custom handling */
  commandOptions: ((rect: DOMRect) => void) | VerdiCommand[]
  commandForNoResults?: CommandForNoResultsOptions
  onItemSelected: (command: VerdiCommand) => void
  searchPlaceholderText: string
  maxWidth?: string | number
  shouldHighlightAsNudge?: boolean
  tooltip?: string
}


/** Similar to a Select (drop down input), but utilizes the mini command menu for the list */
export const SelectCommandDropdown = (props: SelectCommandDropdownProps) => {

  const {
    label,
    commandOptions,
    commandForNoResults,
    onItemSelected,
    searchPlaceholderText,
    shouldHighlightAsNudge,
    tooltip,
    ...buttonProps
  } = props


  const ref = useRef<HTMLDivElement>(null)

  const showMiniCommandMenu = useCallback((commandOptions: VerdiCommand[]) => {
    const rect = ref.current?.getBoundingClientRect()
    if (!rect) return

    const args: ShowMiniCommandMenuCommandArgs = {
      commands: commandOptions,
      commandForNoResults,
      rectOfAnchorElement: rect,
      onItemSelected,
      searchPlaceholderText,
      onCloseCallback: () => {
        const button = ref.current?.firstElementChild as HTMLButtonElement
        if (button) {
          button.focus()
        }
      },
      shouldAdjustRectForScrollOffset: false,
    }
    showMiniCommandMenuCommandDefinition.triggerCommand?.(args)
  }, [onItemSelected, searchPlaceholderText, commandForNoResults, ref])

  const onClick = useCallback(() => {

    if (Array.isArray(commandOptions)) {
      // Automatically handle the pop up CM
      showMiniCommandMenu(commandOptions)
      return
    }

    // Pass handling along to parent
    const rect = ref.current?.getBoundingClientRect()
    if (!rect) return
    commandOptions(rect)

  }, [commandOptions, showMiniCommandMenu])


  return (
    <div
      ref={ref}
      title={tooltip}
      css={css`
        overflow: hidden;
        width: 100%;
      `}
    >
      <Button
        variant="outline"
        size="sm"
        aria-label={label}
        backgroundColor={shouldHighlightAsNudge ? "var(--nudge-highlight-bg-color)" : "inherit"}
        {...buttonProps}
        onClick={onClick}
        maxWidth={props.maxWidth || "100%"}
        textOverflow={"ellipsis"}
        overflow={"hidden"}
        justifyContent={"flex-start"}
      >
        {props.label}
      </Button>
    </div>
  )
}
