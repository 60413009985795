import { Data } from "@verdi/shared-constants"
import { MindMapData, MindMapModel, MindMapState } from "./MindMapSlice"
import { makePostRequestJson } from "../utility-hooks/fetchUtils"
import { dispatch } from "./store"


const fromMindMapDto = (dto: Data.MindMap.MindMapDto): MindMapModel => {

  const graphData = JSON.parse(dto.graphDataJsonString || "{}") as MindMapData
  return {
    id: dto.id,
    title: dto.title,
    graphData,
  }
}

const createMindMap = async (title: string, graphData: MindMapData) => {

  const body: Data.MindMap.MindMapMutableFields = {
    title,
    graphDataJsonString: JSON.stringify(graphData),
  }
  const results = await makePostRequestJson("MindMapAdd", body) as Data.MindMap.MindMapDto
  const newModel = fromMindMapDto(results)

  dispatch(MindMapState.addMindMap(newModel))

  return fromMindMapDto(results)
}


export const MindMapProvider = {
  fromMindMapDto,
  createMindMap,

}
