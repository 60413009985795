import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails";
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { setDocAccessCommandDefinition } from "./setDocAccess";
import { showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu";

const cmdType = GlobalCommandType.setDocAccessShowOptions



export const setDocAccessShowOptionsCommandDefinition: GlobalCommandDefinition<SetDocAccessShowOptionsArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetDocAccessShowOptionsArgs) => {
    const toReturn = {
      globalCommandType: cmdType,
      name: 'Set document access ...',
      description: '',
      searchName: 'set document access',
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetDocAccessShowOptionsArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // This feels like a hack
    const document = services.menuStructureProvider.getMenuItem(args.document.id)
    if (!document) {
      onProcessingComplete(false)
      return
    }


    const command = setDocAccessCommandDefinition.buildMenuItem({
      document: args.document,
      isVisibleToOrg: !document.isVisibleToOrg,
      name: document.isVisibleToOrg ? "Set visible only to me" : "Set visible to my org",
      description: '',
      searchName: document.isVisibleToOrg ? "set visible only to me" : "set visible to my org"
    })

    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands: [command],
      title: "Select Access Level",
      searchPlaceholderText: "search for access level",
      rectOfAnchorElement: args.rect,
      shouldAdjustRectForScrollOffset: false,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: SetDocAccessShowOptionsArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetDocAccessShowOptionsArgs = {
  document: BasicDocumentDetails
  rect?: DOMRect
}
