import { useDisclosure } from "@chakra-ui/react"
import { useCallback, useMemo, useState } from "react"
import { runAIPromptOnDocCommandDefinition } from "../commandDefinitions/documentBody/runAIPromptOnDoc"
import { ShowCustomAIPromptCommandArgs } from "../commandDefinitions/documentBody/showCustomAIPrompt"
import { CustomPromptAiCallOptions } from "../../../ai/prompts/docDrafter/CustomPromptOnDocAiCall"



export const useCustomAIPromptModalProvider = () => {
  const disclosure = useDisclosure()
  const [args, setArgs] = useState<ShowCustomAIPromptCommandArgs>()


  const openCustomPrompt = useCallback((args: ShowCustomAIPromptCommandArgs) => {
    setArgs(args)
    disclosure.onOpen()
  }, [disclosure])

  const closeCustomPrompt = useCallback(() => {
    setArgs(undefined)
    disclosure.onClose()
  }, [disclosure])

  const submitCustomPrompt = useCallback((
    prompt: string,
    promptOptions: CustomPromptAiCallOptions
  ) => {
    runAIPromptOnDocCommandDefinition.triggerCommand?.({
      prompt,
      promptOptions,
    })
    disclosure.onClose()
  }, [disclosure, args])

  const customPromptIsOpen = useMemo(() => {
    return Boolean(args)
  }, [args])

  return {
    customPromptArgs: args,
    disclosure,
    openCustomPrompt,
    closeCustomPrompt,
    submitCustomPrompt,
    customPromptIsOpen,
  }
}
