import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react';
import { useMutation } from 'react-relay/hooks'
import { useDeleteDocumentMutation } from './__generated__/useDeleteDocumentMutation.graphql';
import { MenuStructureForAllDocsProvider } from './organize/useGetMenuStructureForAllDocs';


const mutationQL = graphql`
  mutation useDeleteDocumentMutation (
    $input: DeleteDocumentInput!
    $connectionIds: [ID!]!
  ) @raw_response_type {
    deleteDocument(input: $input){
      id @deleteEdge(connections: $connectionIds)
      opportunities {
        ...OpportunityDocumentSideMenu_opportunity
      }
    }
  }
`

export function useDeleteDocument(
  menuStructureProvider: MenuStructureForAllDocsProvider,
  connectionIds: string[] = [],
) {

  const [commit, isSaving] = useMutation<useDeleteDocumentMutation>(mutationQL)
  const deleteDocument = useCallback((documentId: string, onCompleted?: () => void, shouldHardDelete: boolean = false) => {

    commit({
      variables: {
        input: {
          documentId,
          shouldHardDelete,
        },
        connectionIds,
      },
      onCompleted: (response) => {
        menuStructureProvider.onDocumentDeleted(documentId)
          .then(() => {
            if (onCompleted) {
              onCompleted()
            }
          })
      }
    })
  }, [commit, connectionIds, menuStructureProvider,])
  return [deleteDocument, isSaving] as const
}
