import { PlaceholderBehaviorTypes, PlaceholderInlineNodeAttributes, PlaceholderSummary } from "./PlaceholderInlineNode"
import { Node } from "@tiptap/pm/model";
import { VerdiNodeTypes } from "./VerdiNodeTypes";



enum knownPlaceholderKeys {
  maxLines = "maxLines",
  instructions = "instructions",
  behaviors = "behaviors",
}

/** Takes a placeholder string, such as 
 * 
 * `"placeholder text | maxLines=0 | instructions=do something" `
 * 
 * and converts it into attributes for a PlaceholderInlineNode */
export const parsePlaceholderAttributesFromMarkup = (rawTextMarkup: string): PlaceholderInlineNodeAttributes => {
  const parts = rawTextMarkup.split("|")

  const attrs: PlaceholderInlineNodeAttributes = {
    text: "",
  }

  for (let part of parts) {
    const trimmedPart = part.trim()
    if (trimmedPart.length === 0) {
      continue
    }
    const subParts = trimmedPart.split("=")
    if (subParts.length === 1) {
      attrs.text = trimmedPart
      continue
    }
    const key = subParts[0].trim()
    if (key === knownPlaceholderKeys.maxLines) {
      attrs.maxLines = parseInt(subParts[1].trim())
      continue
    }
    if (key === knownPlaceholderKeys.instructions) {
      attrs.instructions = subParts[1].trim()
      continue
    }
    if (key === knownPlaceholderKeys.behaviors) {
      attrs.behaviors = subParts[1].split(",").map(b => b.trim() as PlaceholderBehaviorTypes)
      continue
    }
  }

  return attrs
}

export const wrapInPlaceholderMarkup = (rawText: string) => {
  if (!rawText) {
    return ""
  }
  return `[[ ${rawText} ]]`
}

// 
export const containsPlaceholderMarkup = (rawText: string) => {
  return /\[\[.*\]\]/.test(rawText); // "[[" and "]]" are the delimiters for a placeholder
}


export const parseAsSummary = (node: Node) => {

  const attrs = node.attrs as PlaceholderInlineNodeAttributes
  if (attrs) {
    return {
      text: attrs.text,
      maxLines: attrs.maxLines,
      instructions: attrs.instructions,
      behaviors: attrs.behaviors?.map(b => b.toString()) || [],
    } as PlaceholderSummary
  }
  return undefined

}

export const findPlaceholderNodes = (rootDocNote: Node, searchText?: string) => {

  const toReturn: FindPlaceholderResults[] = []
  rootDocNote.descendants((node, pos) => {

    if (node.type.name == VerdiNodeTypes.PlaceholderInline) {
      const summary = parseAsSummary(node)
      if (summary && (!searchText || summary.text.includes(searchText))) {
        toReturn.push({
          node,
          pos,
          summary,
        })
      }
    }
    return true // .descendants() - Returning false will limit it only to the root level sections
  })

  return toReturn
}
export type FindPlaceholderResults = {
  node: Node
  pos: number
  summary: PlaceholderSummary
}
