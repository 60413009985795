import { useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { VerdiLogo } from "../logo/VerdiLogo"
import { VerdiIconPanelLeftClosed, VerdiIconPanelLeftOpen } from "../icons/VerdiIcons"
import { IconButtonTertiary } from "../buttons/IconButtonTertiary"
import { RouterState } from "found"



type Props = {
  menuIsExpanded: boolean
  toggleMenuExpand: () => void
  router: RouterState<any>
}
export const TopLeftMenu = ({
  menuIsExpanded,
  toggleMenuExpand,
  router,
}: Props) => {

  const isLightMode = useColorModeValue(true, false)

  // ckground: var(--chakra-colors-verdiButtonPrimary-200);
  return (
    <div css={css`
        display: flex;
        align-items: center;
      `}>
      <IconButtonTertiary
        aria-label="Show / hide menu"
        onClick={toggleMenuExpand}
        icon={menuIsExpanded ? <VerdiIconPanelLeftOpen /> : <VerdiIconPanelLeftClosed />}
        marginLeft={menuIsExpanded ? 0 : 3}
        marginRight={0}
        size="sm"
      />
      <a href="/"
        onClick={(e) => {
          e.preventDefault()
          router.router.push("/")
        }}
        css={css`
          width: 100px;
        `}>
        <VerdiLogo />
      </a>
    </div>
  )
}
