import { useEffect, useState } from 'react';
import { SideMenuSection, startingMenuDepth } from './SideMenuSection';
import { useNewQuickNoteCreator } from '../documents/add/useNewQuickNoteCreator';
import { Menu } from '@verdi/shared-constants';
import { DocumentSchema } from '@verdi/shared-constants';


type Props = {
  onBeforeNavigate: () => void
  onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  menuItems: Menu.MenuItemData[]
  activeMenuIds: string[]
}
export const SideMenuSectionForAll = ({
  onBeforeNavigate,
  onAddOpportunityButtonClick,
  menuItems,
  activeMenuIds,
}: Props) => {

  const [topLevelItem, setTopLevelItem] = useState<Menu.MenuItemData>()

  useEffect(() => {

    const rootItem: Menu.MenuItemData = {
      children: menuItems,
      id: "allDocs",
      title: "All Docs",
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: "",
      createdAt: new Date(),
    }

    setTopLevelItem(rootItem)
  }, [menuItems])

  const { createNewQuickNote, isSaving } = useNewQuickNoteCreator({ shouldShowChatOnRedirect: false })


  if (!topLevelItem) return null
  return (

    <SideMenuSection
      menuItem={topLevelItem}
      menuDepth={startingMenuDepth}
      folderType="anything"
      onBeforeNavigate={onBeforeNavigate}
      isExpandedInitially={window.location.pathname.includes("/notes")}
      onAddButtonClick={(menuItem) => createNewQuickNote()}
      onAddOpportunityButtonClick={onAddOpportunityButtonClick}
      addingInProgress={isSaving}
      activeMenuIds={activeMenuIds}
    />

  )
}

