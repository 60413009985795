import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails";
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";

const cmdType = GlobalCommandType.setDocAccess



export const setDocAccessCommandDefinition: GlobalCommandDefinition<SetDocAccessArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetDocAccessArgs) => {
    const toReturn = {
      globalCommandType: cmdType,
      name: args.name,
      description: args.description,
      searchName: args.searchName,
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetDocAccessArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {
    services.documentRelationsProvider.updateIsVisibleToOrg(args.document.id, args.isVisibleToOrg)
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: SetDocAccessArgs,
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetDocAccessArgs = {
  document: BasicDocumentDetails
  isVisibleToOrg: boolean
  name: string
  description: string
  searchName: string
}
