import { css } from "@emotion/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { ProgressPrimary } from "../../components/buttons/ProgressPrimary"
import { AllOnboardingSteps, OnboardingStepIds, totalOnboardingSteps } from "./OnboardingStep"
import { OnboardingManager } from "./useOnboardingManager"
import { useColor } from "../../utility-hooks/useColor"
import { useCallback, useMemo } from "react"
import { showConfirmDialogCommandDefinition } from "../../components/commands/commandDefinitions/utils/showConfirmDialog"
import { formInputLabelWidth } from "../../components/forms/FormInputText"


type Props = {
  onboardingManager: OnboardingManager
  maxInnerWidth: number
  mediaSmallHeight: number
  mediaTallHeight: number
  onLastStepComplete: () => void
  lastStepIsValid: boolean
  isLoading: boolean
  hasOpportunityDescription: boolean
  setFocusToOpportunityDescription: () => void
}
export const OnboardingProgress = ({
  onboardingManager,
  maxInnerWidth,
  mediaSmallHeight,
  mediaTallHeight,
  onLastStepComplete,
  lastStepIsValid,
  isLoading,
  hasOpportunityDescription,
  setFocusToOpportunityDescription,
}: Props) => {

  const {
    currentStepIndex,
    gotoNextStep,
    gotoPreviousStep,
  } = onboardingManager


  const progressBg = useColor("surfaceBgSecondary")

  const nextButtonPressed = useCallback(() => {

    // if (currentStepIndex === OnboardingStepIds.OpportunityInfo && !hasOpportunityDescription) {
    //   showConfirmDialogCommandDefinition.triggerCommand?.({
    //     confirmButtonLabel: "Describe an opportunity",
    //     heading: "Before we proceed...",
    //     dialogBodyHtml: getNoOpportunityHTML(),
    //     dialogBodyText: " Would you like to describe an opportunity?\n\nThis will help you to better understand the capabilities of Verdi.",
    //     secondaryButtonOptions: {
    //       label: "Skip",
    //       onClick: () => {
    //         gotoNextStep()
    //       }
    //     },
    //     onConfirmSuccess: () => {
    //       setFocusToOpportunityDescription()
    //     },
    //   })
    //   return
    // }

    gotoNextStep()

  }, [currentStepIndex, gotoNextStep, hasOpportunityDescription, setFocusToOpportunityDescription])

  const nextButtonLabel = useMemo(() => {
    if (currentStepIndex === totalOnboardingSteps - 1 && hasOpportunityDescription) {
      return "Create Opportunity"
    }
    else return "Next"
  }, [currentStepIndex, hasOpportunityDescription])

  const maxWidth = 490 // maxInnerWidth - (formInputLabelWidth * 2)


  return (

    <div css={css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          border-top: 1px solid #99999933;
          background-color: ${progressBg};

        `}>

      <ProgressPrimary
        width="100%"
        currentProgress={currentStepIndex + 1}
        totalProgress={totalOnboardingSteps}
      />


      <div css={css`
        max-width: ${maxWidth}px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 200px;
        padding: 0 12px;
        height: 120px;

        @media (max-height: ${mediaSmallHeight}px) {
          height: 60px;
          font-size: small;
        }
        @media (min-height: ${mediaTallHeight}px) {
          margin-bottom: 100px;
          font-size: large;
        }
      `}>


        <div css={css`
          width: 100%;
          margin-right: 12px;
        `}>
          {currentStepIndex > 0 &&
            <>
              <span css={css`
                font-weight: bold;
                margin-right: 8px;
                opacity: 0.7;
              `}>
                Step {currentStepIndex} of {totalOnboardingSteps - 1}
                {/* : {AllOnboardingSteps[currentStepIndex].title} */}
              </span>
              {/* <span css={css`
                @media (max-height: ${mediaSmallHeight}px) {
                  display: none;
                }
              `}>
                {AllOnboardingSteps[currentStepIndex].description}
              </span> */}


              {/* {AllOnboardingSteps.map((step, index) =>
            <div key={index}
              css={css`
              opacity: ${index === currentStepIndex ? 1 : 0.4};
            `}
            >
              {step.title}
            </div>
          )} */}
            </>
          }
        </div>

        <div css={css`
          display: flex;
          align-items: center;
          gap: 8px;
        `}>
          <ButtonSecondary
            label={"Back"}
            size="sm"
            onClick={gotoPreviousStep}
            visibility={currentStepIndex === 0 ? "hidden" : "visible"}
            opacity={0.7}
            isDisabled={currentStepIndex === 0}
          />
          <ButtonPrimary
            label={nextButtonLabel}
            onClick={currentStepIndex === totalOnboardingSteps - 1 ? onLastStepComplete : nextButtonPressed}
            size="md"
            isLoading={isLoading}
            isDisabled={currentStepIndex === totalOnboardingSteps - 1 && !lastStepIsValid}
          // TODO: If last step navigate to home?
          />
        </div>

      </div>
    </div>

  )
}


const getNoOpportunityHTML = () => {

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 20px 0;
    `}>
      <p>Would you like to describe an opportunity?</p>
      <p>This will help showcase what Verdi can do for you.</p>
    </div>
  )
}
