import { css } from "@emotion/react"
import { DocumentOutlineEntry } from "../../../document/tiptapEditor/utils/buildFirstDocStepFromJson"


type Props = {
  docOutline?: DocumentOutlineEntry[]
}

export const DocOutlinePreview = ({
  docOutline,
}: Props) => {


  if (!docOutline) return null

  console.log("the outline: ", docOutline)


  return (
    <div css={css`
      border:1px solid #99999933;
      padding: 5px 10px 15px 10px;
    `}>
      {docOutline?.map((item, index) =>
        <div key={index}>
          {item.type === "heading"
            ?
            <div css={css`
            font-size: 0.8em;
              font-weight: bold;
              margin-top: 5px;
            `}>
              {item.content}
            </div>
            :
            <div css={css`
              font-size: 0.6em;
            `}>
              {item.content}
            </div>
          }

        </div>

      )}
    </div>
  )
}
