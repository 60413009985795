import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { Button, useColorModeValue } from '@chakra-ui/react'
import { PageLayoutSplitViewWrapper } from '../../components/pageLayout/pageLayoutSplitViewWrapper'
import { SideMenu } from '../opportunityDocument/SideMenu'
import { css } from '@emotion/react'
import { usePageLayoutState } from '../../components/pageLayout/usePageLayoutState'
import { useAppServices } from '../../components/appServices/useAppServices'
import { ButtonPrimary } from '../../components/buttons/ButtonPrimary'
import { ButtonSecondary } from '../../components/buttons/ButtonSecondary'
import { ButtonTertiary } from '../../components/buttons/ButtonTertiary'
import { SectionWrapper } from '../../components/pageLayout/SectionWrapper'
import { PageHeading } from '../../components/pageLayout/PageHeading'
import { IconButtonPrimary } from '../../components/buttons/IconButtonPrimary'
import { RepeatIcon } from '@chakra-ui/icons'
import { IconButtonSecondary } from '../../components/buttons/IconButtonSecondary'
import { IconButtonTertiary } from '../../components/buttons/IconButtonTertiary'
import { AccordionWrapper } from '../../components/pageLayout/AccordionWrapper'
import { CmdKCommandList } from '../../components/commands/commandMenu/cmdk/CmdKCommandList'
import { exampleCmdKCommands } from '../../components/commands/commandMenu/cmdk/cmdKExampleCommands'
import { InputTextWithState } from '../../components/forms/InputTextWithState'
import { useState } from 'react'
import { VerdiIconAdd, allVerdiIcons } from '../../components/icons/VerdiIcons'
import { AiSuggestionButtonList } from '../../components/aiComponents/AiSuggestionButtonList'
import { MainViewWrapper } from '../../components/pageLayout/MainViewWrapper'
import { OpportunityRiskIconSample } from './OpportunityRiskIconSample'
import { ComboBoxExample } from '../../components/commands/miniCommandMenu/comboBox/examples/ComboBoxExample'
import { ComboBoxExternalInputExample } from '../../components/commands/miniCommandMenu/comboBox/examples/ComboBoxExternalInputExample'


export const StylesGuidePage = () => {


  const appServices = useAppServices()
  const {
    menuStructureProvider,
    opportunityProvider,
  } = appServices

  const colorSchemesToTest = [
    "verdiButtonPrimary",
    "verdiButtonSecondary",
    "verdiButtonTertiary",
    "verdiUserAction",
    "verdiAiAction",
    "verdiSubmit",
    "blue",
    "purple"
  ]
  const colorsNumbersToTest = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

  const pageLayoutState = usePageLayoutState()

  const lightOrDark = useColorModeValue("light", "dark")
  const bgColors = useColorModeValue(
    ["#FFF", "#EEE", "#DDD", "#CCC"],
    ["#000", "#111", "#222", "#333"],)

  const uniformStyleNames =
    ["surfaceBg",
      "surfaceBgSecondary",
      "surfaceOverlay",
      "surfaceOverlayBorder",
      "surfaceBorder",
      "surfaceSelected",
      "surfaceHotkey",
      "surfaceHotkeyBorder",
      "textPrimary",
      "textSecondary",
      "textIconSecondary",
      "itemActiveBg",
      "itemSelectedBg",
    ]

  const [searchText, setSearchText] = useState("")


  const [aiSuggestionSelection, setAiSuggestionSelection] = useState("")

  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={appServices}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={() => { }}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        mainContent={

          <MainViewWrapper>
            <div>
              <PageHeading
                title="Page Heading Title"
                description="Page Heading Description"
              />

              <h1>Components</h1>

              <div css={css`
                border: 1px solid red;
                padding: 40px;
              `}>
                <h2>Combo Box (custom built)</h2>

                <ComboBoxExample />

                <ComboBoxExternalInputExample />

              </div>


              <h2>
                Opportunity Risk Icon
              </h2>
              <OpportunityRiskIconSample />


              <h2>
                Ai Suggestion Button List
              </h2>
              {/* <AiSuggestionButtonList
                isLoading={false}
                suggestionNames={[
                  "s.Join CommunityBorrow Against Crypto TodayThe Lending Protocol allows anyone to borrow against their crypto. Buying and selling the tokenized debt enables fixed-rate lending and borrowing — something much needed in decentralized finance",
                  "l capture it.-ChainLinkGodTokenized Real-World Assets: Scaling DeFi to a Global LevelStay Updated PreviousHIP 6 — Liquidity Bootstrapping Program30 November 2023Hifi 2024 Roadmap16 November 2023Hifi 2023 — A Look Back1",
                  "TodayThe Lending Protocol allows anyone to borrow against their crypto. Buying and selling the tokenized debt enables fixed-rate lending and borrowing — something much needed in decentralized finance",
                  "Real-World Assets: Scaling DeFi to a Global LevelStay Updated PreviousHIP 6 — Liquidity Bootstrapping Program30 November 2023Hifi 2024 Roadmap16 November 2023Hifi 2023 — A Look Back1"
                ]}
                selectedSuggestionName={aiSuggestionSelection}
                onSuggestionClick={(suggestionName) => setAiSuggestionSelection(suggestionName)}
                buttonSize="xs"
              /> */}

              <div css={css`
              margin-bottom: 40px;
            `}>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
                <p>A paragraph of text</p>
              </div>


              <AccordionWrapper
                buttonContent={
                  <h3>Icons</h3>
                }
              >
                {allVerdiIcons.map((icon, key) =>
                  <div key={key}
                    css={css`
                  display: flex;
                  gap: 8px;
                  margin-bottom: 8px;
                `}>
                    <ButtonPrimary
                      label='Words'
                      title="ButtonPrimary"
                      leftIcon={icon.renderIcon({})}
                    />
                    <ButtonSecondary
                      label='And'
                      title="ButtonSecondary"
                      leftIcon={icon.renderIcon({})}
                    />
                    <ButtonTertiary
                      label='Things'
                      title="ButtonTertiary"
                      leftIcon={icon.renderIcon({})}
                    />
                    <IconButtonPrimary
                      aria-label={icon.name}
                      title="IconButtonPrimary"
                      icon={icon.renderIcon({})}
                    />
                    <IconButtonSecondary
                      aria-label={icon.name}
                      title="IconButtonSecondary"
                      icon={icon.renderIcon({})}
                    />
                    <IconButtonTertiary
                      aria-label={icon.name}
                      title="IconButtonTertiary"
                      icon={icon.renderIcon({})}
                    />
                    <span css={css`
                    padding: 0px;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px dashed #99999966;
                  `}
                      title="Icon Only"
                    >
                      {icon.renderIcon({})}
                    </span>

                    {icon.name}

                  </div>
                )}

              </AccordionWrapper>


              <AccordionWrapper
                buttonContent={
                  <h3>
                    Buttons
                  </h3>
                }
              >
                {bgColors.map((bgColor, index) =>
                  <div
                    key={index}
                    css={css`
                    padding: 10px;
                    background-color: ${bgColor};
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                `}>
                    <ButtonPrimary
                      label="Button Primary"
                    />

                    <ButtonSecondary
                      label="Button Secondary"
                    />

                    <ButtonTertiary
                      label="Button Tertiary"
                    />

                    <IconButtonPrimary
                      aria-label='icon button primary'
                      icon={<RepeatIcon />}
                    />

                    <IconButtonSecondary
                      aria-label='icon button primary'
                      icon={<RepeatIcon />}
                    />

                    <IconButtonTertiary
                      aria-label='icon button primary'
                      icon={<RepeatIcon />}
                    />

                    <IconButtonPrimary
                      aria-label='icon button primary'
                      icon={<VerdiIconAdd />}
                    />

                    <IconButtonSecondary
                      aria-label='icon button primary'
                      icon={<VerdiIconAdd />}
                    />

                    <IconButtonTertiary
                      aria-label='icon button primary'
                      icon={<VerdiIconAdd />}
                    />
                  </div>

                )}

              </AccordionWrapper>

              <AccordionWrapper
                buttonContent={
                  <h3>
                    Colors
                  </h3>
                }
              >

                <h4>uniformStyleNames</h4>

                <div>
                  {uniformStyleNames.map((styleName, index) =>
                    <div key={index}
                      css={css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 10px;
                  `}>
                      <div
                        css={css`
                        width: 20px;
                        height: 20px;
                        background-color: var(--chakra-colors-${styleName}-${lightOrDark});
                    `}>
                      </div>
                      <div>
                        {styleName}
                      </div>
                    </div>
                  )}

                </div>

                <h4>Raw colors</h4>

                <div css={css`
              display: flex;
              flex-direction: row;
              overflow-x: scroll;
            `}>


                  {colorSchemesToTest.map((colorScheme, schemeIndex) =>
                    <div key={schemeIndex}
                      css={css`
                    width: 200px;
                    margin-right: 10px;
                  `}>

                      <Button
                        colorScheme={colorScheme}
                      >
                        {colorScheme}
                      </Button>

                      {colorsNumbersToTest.map((colorNumber, numberIndex) => {
                        const combinedName = `${colorScheme}-${colorNumber}`
                        const cssVar = `--chakra-colors-${combinedName}`
                        return (
                          <div key={numberIndex}
                            css={css`
                        width: 100%;
                        height: 50px;
                        background-color: var(${cssVar});
                      `}>
                            {combinedName}
                          </div>
                        )
                      }
                      )}

                    </div>
                  )}
                </div>

              </AccordionWrapper>

              {[1, 2, 3].map((section, index) =>
                <SectionWrapper key={index}>
                  Section Wrapper {section}
                </SectionWrapper>
              )}

              <AccordionWrapper
                buttonContent={
                  <h3>CmdK Combo Box</h3>
                }
              >

                <SectionWrapper>
                  <h2>
                    CmdK Command List with External Input Text (not working yet)
                  </h2>
                  <InputTextWithState
                    value={"a"}
                    onValueUpdated={(newValue) => console.log("new value ", newValue)}
                  />
                  <CmdKCommandList
                    onCurrentSelectionChange={(itemId) => console.log("GOT IT ", itemId)}
                    commands={exampleCmdKCommands}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </SectionWrapper>


                <SectionWrapper >
                  <h2>
                    CmdK Command List
                  </h2>
                  <CmdKCommandList
                    onCurrentSelectionChange={(itemId) => console.log("GOT IT ", itemId)}
                    commands={exampleCmdKCommands}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </SectionWrapper>
              </AccordionWrapper>
            </div>
          </MainViewWrapper>
        } />

    </div >
  )
}
