import { AiCallableFunctionType, ChatRoleType } from "@verdi/shared-constants"
import { ChatMessage } from "../../../components/chat/useChatHistory"
import { GlobalCommandType } from "../../../components/commands/GlobalCommandType"
import { AiPersona } from "../../../components/chat/AiPersona"
import { DocumentContextForAi } from "../AiCoachPrompts"
import { getContinueChatPrompt } from "../../prompts/continueChatPrompts"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { conceptsAsPromptContext } from "../../prompts/asPromptContext"
import { OpportunityContextForAi } from "../../../screens/opportunities/useOpportunityProvider"
import { buildOpportunityContextForPrompt } from "../../promptUtils/buildOpportunityContextForPrompt"


type RawMessageResults = {
  content: string,
  role: string,
  functionToCall: {
    name: string,
    arguments: string
  }
}

export const continueChat = async (
  messages: ChatMessage[],
  aiPersona: AiPersona,
  companyContext?: string,
  docContext?: DocumentContextForAi,
  opportunityContext?: OpportunityContextForAi,
) => {

  const messagesToSend = buildTheMessageList(aiPersona, companyContext, docContext, messages, opportunityContext)
  console.log('continueChat: ', { messagesToSend })

  const start = new Date().getTime()
  const results = await makeAiChatRequest({
    messages: messagesToSend,
    openAIParams: {
      temperature: 0,
    },
    callableFunctionTypes: [
      AiCallableFunctionType.create_document,
      AiCallableFunctionType.create_opportunity,
      AiCallableFunctionType.get_current_weather,
    ],
  })
  const timeTaken = new Date().getTime() - start
  console.log(`continueChat: time: ${timeTaken}ms. response:`, { r: results?.aiResponseJson })

  const buttons = []
  console.log('continueChat: raw aiResponseJson  = ', results?.aiResponseJson)
  const messageRaw = results?.aiResponseJson as RawMessageResults
  console.log('continueChat: messageRawSanitized = ',
    {
      raw: results?.aiResponseJson,
      sanitized: messageRaw,
      areEqual: JSON.stringify(results?.aiResponseJson) === JSON.stringify(messageRaw)
    }
  )

  if (messageRaw?.functionToCall?.name) {

    let parsedArguments: any
    if (messageRaw?.functionToCall?.arguments) {
      parsedArguments = JSON.parse(messageRaw.functionToCall.arguments)
    }

    console.log('continueChat: parsedArguments = ', parsedArguments)
    const button = {
      title: getButtonName(messageRaw?.functionToCall?.name || ""),
      commandType: getCommandType(messageRaw?.functionToCall?.name || ""),
      arguments: parsedArguments,
    }
    buttons.push(button)
  }
  let content = messageRaw.content || ""
  if (!content) {
    if (buttons.length > 0) {
      content = "Press the button to continue"
    }
  }

  const toReturn: ChatMessage = {
    content,
    role: ChatRoleType.assistant,
    createdAt: new Date(),
    buttons,
  }

  return toReturn
}

const getButtonName = (functionType: AiCallableFunctionType | string) => {
  switch (functionType) {
    case "create_document": return "Create a note";
    case "create_opportunity": return "Create an Opportunity";

    default: return functionType.toString()
  }
}
const getCommandType = (functionType: AiCallableFunctionType | string) => {
  switch (functionType) {
    case "create_document": return GlobalCommandType.createQuickNote;
    case "create_opportunity": return GlobalCommandType.createOpportunity;
    default: return undefined
  }
}

const buildTheMessageList = (
  aiPersona: AiPersona,
  companyContext: string | undefined,
  docContext: DocumentContextForAi | undefined,
  messages: ChatMessage[],
  opportunityContext?: OpportunityContextForAi,
) => {

  const messagesMapped: { role: ChatRoleType, content: string }[] = []

  const systemInstruction = getContinueChatPrompt(aiPersona.type)
  messagesMapped.push({ role: ChatRoleType.system, content: systemInstruction })


  const docBody = docContext?.body || ""
  const docConcepts = conceptsAsPromptContext(docContext?.relatedDocs, docContext?.id)
  if (docBody.length > 5 || docConcepts.length > 5) {
    const context = `When responding to the user, here is the document you are looking at. This context can help 
inform your answers. REMEMBER TO STAY VERY CONCISE AND IN CHARACTER WITH THE INSTRUCTIONS. 
DOCUMENT CONTEXT: 
${docContext?.title ? `Document Title: ${docContext?.title}` : ""}
${docContext?.description ? `Document Description: ${docContext?.description}` : ""}
Document Body: ${docBody}
${docConcepts}
`
    messagesMapped.push({ role: ChatRoleType.system, content: context })
  }

  if (opportunityContext) {
    const opportunityContextText = buildOpportunityContextForPrompt(opportunityContext)
    if (opportunityContextText.length > 0) {
      const context = `This is the opportunity that the user is currently working under.
      ${opportunityContextText}`
      messagesMapped.push({ role: ChatRoleType.system, content: context })
    }
  }


  if (companyContext && companyContext.length > 5) {
    const context = `In your conversations, follow the INSTRUCTIONS. You have access to the user's company information to inform your responses. 
    If the user mentions "my company" or similar, this is the context to use. 
    COMPANY CONTEXT: ${companyContext}`
    messagesMapped.push({ role: ChatRoleType.system, content: context })
  }

  messagesMapped.push(...messages.map(m => ({ role: m.role, content: m.content })))

  console.log('continueChat: messages to send = ', messagesMapped)

  return messagesMapped
}
