
/** Important: Avoid changing the string values of these enums 
 *  as it could break document schema for all existing documents in the database
 */
export enum VerdiNodeTypes {
  PlaceholderInline = "placeholderInline",
  docReferenceInline = "docReferenceInline",
  taskCheckbox = "taskCheckbox",
  // TODO: Add others, like "section", "freeText", "docReference" etc ...
}
