import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { Editor } from "@tiptap/core"


const cmdType = GlobalCommandType.docBodyMarkCreate


export const docBodyMarkCreateCommandDefinition: GlobalCommandDefinition<DocBodyMarkCreateCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DocBodyMarkCreateCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: `Create Mark`,
        description: '',
        searchName: 'create mark',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: DocBodyMarkCreateCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      console.warn("docBodyMarkCreate: No doc editor found")
      onProcessingComplete(false)
      return
    }

    const { markType, markStrategy, range, textToHighlight } = args
    const { state: editorState, commands: editorCommands } = editor;

    const mark = editorState.schema.marks[markType].create(args.attributes);

    if (markStrategy === "search") {

      // TODO: Decide how to handle multiple matches
      editorCommands.setVerdiSearchMark(
        textToHighlight || "",
        mark,
        args.shouldMoveCaretPosition ? "endOfFirstMark" : undefined,
      )

    } else if (markStrategy === "range") {

      const from = range?.from || 0
      const to = range?.to || 0
      editorCommands.setVerdiMark(from, to, mark)

    } else {
      console.warn("docBodyMarkCreate: unknown mark strategy")
    }

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: DocBodyMarkCreateCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type DocBodyMarkCreateCommandArgs = {
  markType: "KnowledgeGraph" | "AiSuggestion" | "PlaceholderInline"
  markStrategy: "search" | "range"
  attributes: DocumentSchema.VerdiMarks.AiSuggestionMarkAttributes
  textToHighlight?: string
  range?: {
    from: number, to: number
  }
  shouldMoveCaretPosition?: boolean
}


/** Adds a highlight to specific text, dismissed when clicked. */
export const addMarkAsAiEdit = (editor: Editor, textToHighlight: string) => {

  const markType = DocumentSchema.VerdiMarks.VerdiMarkType.AiEdited
  const mark = editor.state.schema.marks[markType].create();

  editor.commands.setVerdiSearchMark(
    textToHighlight,
    mark
  );
}

export const clearAllAiEditMarks = (editor: Editor) => {

  editor.commands.clearVerdiMarks([
    editor.state.schema.marks.AiEdited,
  ]);
}

// export const addAttributionMarkAt = (editor: Editor, from: number, to: number) => {

//   const markType = DocumentSchema.VerdiMarks.VerdiMarkType.Attribution
//   const attrs: DocumentSchema.VerdiMarks.AttributionMarkAttrs = {
//     isgenerated: "true",
//   }
//   const mark = editor.state.schema.marks[markType].create(attrs);

//   editor.commands.setVerdiMark(from, to, mark);
// }
