import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { ShowMiniCommandMenuCommandArgs, showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu"
import { DocumentSchema } from "@verdi/shared-constants"
import { moveDocToParentCommandDefinition } from "./moveDocToParent"
import { clipText } from "../../utils/clipText"


const cmdType = GlobalCommandType.showMoveDocToParent

/** Shows a list of potential parent docs that a user can select to move the current doc under */
export const showMoveDocToParentCommandDefinition: GlobalCommandDefinition<ShowMoveDocToParentCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowMoveDocToParentCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Move this note ...',
        searchName: 'move this note to parent',
        runCommand: () => {
          console.log("showMoveDocToParent runCommand:", { args })
          triggerGlobalCommand(cmdType, args)
        },
        shouldKeepOpenOnRun: true,
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ShowMoveDocToParentCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {
    console.log("showMoveDocToParentCommandDefinition: processCommand: args", args)

    if (!services.menuStructureProvider.menuItemsStructured) {
      console.warn("showMoveDocToParent: menuItemsStructured not available")
      return
    }

    const currentDoc = services.menuStructureProvider.getMenuItem(args.documentId)
    let parentTitle: string | undefined
    if (currentDoc?.parentDocId) {
      const parentDoc = services.menuStructureProvider.getMenuItem(currentDoc?.parentDocId)
      parentTitle = parentDoc?.title
    }

    const buildSubCommand = moveDocToParentCommandDefinition.buildMenuItem

    const menuItems = services.menuStructureProvider.menuItemsStructured.flatMenuItems
    const commands = menuItems
      .filter(doc =>
        doc.type === DocumentSchema.DocumentType.opportunity
        && doc.title
        && doc.id !== args.documentId
        && doc.id !== currentDoc?.parentDocId
      )
      .map(menuItem =>
        buildSubCommand({
          childDocId: args.documentId,
          newParentDocId: menuItem.id,
          newParentTitle: menuItem.title,
          onCompleted: () => {
            console.log("showMoveDocToParent: subCommand onCompleted")
          }
        })
      )

    const docTitleClipped = clipText(currentDoc?.title, 20)
    const parentTitleClipped = clipText(parentTitle, 20)

    const options: ShowMiniCommandMenuCommandArgs = {
      commands,
      title: docTitleClipped ? `Move "${docTitleClipped}"` : "Move Note",
      subTitle: parentTitleClipped ? `from "${parentTitleClipped}"` : undefined,
      rectOfAnchorElement: args.rect,
      searchPlaceholderText: "Select a new parent",
      shouldAdjustRectForScrollOffset: false,
    }

    showMiniCommandMenuCommandDefinition.triggerCommand?.(options)

    onProcessingComplete(true)
  },
}

export type ShowMoveDocToParentCommandArgs = {
  documentId: string
  rect?: DOMRect
}
