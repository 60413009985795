import { useColorModeValue } from "@chakra-ui/react"
import { AiPersona, allAiPersonas, defaultAiPersona } from "./AiPersona"
import { css } from "@emotion/react"
import { ChatMessageViewLabelWidth } from "./ChatMessageView"
import { SelectDropdown } from "../forms/SelectDropdown"


const localStorageKeyAiPersona = "AiPersonaType"
export const loadAiPersonaFromLocalStorage = (): AiPersona => {
  const type = localStorage.getItem(localStorageKeyAiPersona)
  const persona = allAiPersonas.find((persona) => persona.type === type)
  return persona || defaultAiPersona
}

type Props = {
  aiPersona: AiPersona,
  setAiPersona: (aiPersona: AiPersona) => void,
  rightSideButtons: React.ReactNode,
  isCompactMode: boolean,
}

export const AiPersonaSelector = ({
  aiPersona,
  setAiPersona,
  rightSideButtons,
  isCompactMode,
}: Props) => {

  const isLightMode = useColorModeValue(true, false)


  return (
    <div css={css`
      width: 100%;
      display: flex;
      flex-direction: ${isCompactMode ? "column" : "row"};
    `}>
      <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          `}>

        <small css={css`
          width: ${isCompactMode ? "auto" : ChatMessageViewLabelWidth + "px"};
          text-align: ${isCompactMode ? "left" : "right"};
          padding: ${isCompactMode ? "13px 0 0" : "6px 5px 5px"} ;
          text-transform: uppercase;
          opacity: 0.5;
          font-size: x-small;
        `}>
          AI Voice
        </small>
        {isCompactMode &&
          <div>
            {rightSideButtons}
          </div>
        }
      </div>

      <SelectDropdown
        size="sm"
        title="Change which AI you are chatting with"
        onChange={(e) => {
          const selectedPersona = allAiPersonas.find((persona) => persona.type === e.target.value)
          setAiPersona(selectedPersona!)
          localStorage.setItem(localStorageKeyAiPersona, selectedPersona?.type || "")
        }}
        value={aiPersona.type}
        backgroundColor={isLightMode ? "white" : "gray.800"}
      >
        {allAiPersonas.map((persona, index) =>
          <option key={index}
            value={persona.type}>
            {persona.displayName}
          </option>
        )}
      </SelectDropdown>
      {!isCompactMode &&
        <div>
          {rightSideButtons}
        </div>
      }

    </div>
  )
}
