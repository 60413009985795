import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { findCurrentMarkDetails } from "../../../../../screens/document/tiptapEditor/utils/findCurrentMarkDetails"


const cmdType = GlobalCommandType.docBodyMarkClearCurrent


export const docBodyMarkClearCurrentCommandDefinition: GlobalCommandDefinition<DocBodyMarkClearCurrentCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DocBodyMarkClearCurrentCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: `Remove highlight`,
        description: 'Clears the highlight around this text and removes suggestions',
        searchName: 'remove highlight',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "dismiss",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: DocBodyMarkClearCurrentCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      console.warn("docBodyMarkClearCurrent: No doc editor found")
      onProcessingComplete(false)
      return
    }

    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (!currentBlockContext) {
      console.warn("docBodyMarkClearCurrent: No currentBlockContext found")
      onProcessingComplete(false)
      return
    }
    const currentMark = findCurrentMarkDetails(editor, currentBlockContext)
    if (!currentMark) {
      console.warn("docBodyMarkClearCurrent: No resolvedPos found")
      onProcessingComplete(false)
      return
    }

    const { from, to } = currentMark
    editor.commands.clearVerdiMark(from, to, [
      editor.state.schema.marks.AiEdited,
      editor.state.schema.marks.AiSuggestion,
      editor.state.schema.marks.KnowledgeGraph,
      editor.state.schema.marks.PlaceholderInline,
    ])

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: DocBodyMarkClearCurrentCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type DocBodyMarkClearCurrentCommandArgs = {

}
