import graphql from 'babel-plugin-relay/macro'
import { useEffect, useState } from 'react';
import { useMutation, useFragment, UseMutationConfig } from 'react-relay/hooks'
import { useUpdateAiPromptMutation_documentAiPrompt$key } from './__generated__/useUpdateAiPromptMutation_documentAiPrompt.graphql';
import { useUpdateAiPromptMutation, UpdateDocumentAiPromptInput } from './__generated__/useUpdateAiPromptMutation.graphql';
import { deleteNonRelevantOpenAIKeys, DocumentAiPromptInputForm, setInputOpenApiParamsValueForInputState } from './setOpenAIParamsValue';

const mutationQL = graphql`
  mutation useUpdateAiPromptMutation (
    $input: UpdateDocumentAiPromptInput!
  ) @raw_response_type {
    updateDocumentAiPrompt(input: $input){
      id
      ...useUpdateAiPromptMutation_documentAiPrompt
    }
  }
`

const fragmentQL = graphql`
  fragment useUpdateAiPromptMutation_documentAiPrompt on DocumentAiPrompt {
    id
    promptText
    dontDo
    notesForAdmin
    openApiParams {
      best_of
      echo
      frequency_penalty
      logprobs
      max_tokens
      model
      n
      presence_penalty
      stop
      suffix
      temperature
      top_p
    }
  }
`

type UpdateDocumentAiPromptInputForm = Partial<UpdateDocumentAiPromptInput>
type Props = {
  aiPrompt?: useUpdateAiPromptMutation_documentAiPrompt$key
  onCompleted?: UseMutationConfig<useUpdateAiPromptMutation>['onCompleted']
};

export function useUpdateAiPrompt({
  onCompleted,
  aiPrompt: aiPromptKey,
}: Props) {

  const aiPrompt = useFragment(fragmentQL, aiPromptKey!)
  const [input, setInput] = useState<DocumentAiPromptInputForm>({
    ...aiPrompt,
    openApiParams: aiPrompt?.openApiParams ? deleteNonRelevantOpenAIKeys(aiPrompt.openApiParams) : null
  })
  const [commit, isSaving] = useMutation<useUpdateAiPromptMutation>(mutationQL)
  const [isValid, setIsValid] = useState(false)

  const setInputValue = <T extends keyof DocumentAiPromptInputForm>(key: T, value: DocumentAiPromptInputForm[T]) => {
    setInput({ ...input, [key]: value })
  }

  const setInputOpenApiParamsValue = setInputOpenApiParamsValueForInputState(input, setInput)

  useEffect(() => {
    if (input?.promptText?.length) {
      setIsValid(true)
    }
  }, [input])

  const save = () => {
    if (!isValid) return
    commit({
      variables: {
        input: {
          ...input,
          id: aiPrompt.id,
        }
      },
      onCompleted: (response, errors) => {
        onCompleted?.(response, errors)
      },
    })
  }
  return ({
    input,
    setInputValue,
    setInputOpenApiParamsValue,
    save,
    isSaving,
    isValid,
  })
}