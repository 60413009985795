import { css } from "@emotion/react"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { useCallback, useEffect, useState } from "react"
import { useAppServices } from "../../components/appServices/useAppServices"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { ButtonTertiary } from "../../components/buttons/ButtonTertiary"
import { AutoResizingInputText } from "../../components/forms/AutoResizingInputText"
import { Button } from "@chakra-ui/react"


export type Props = {
  document: BasicDocumentDetails,
  landmarkId?: string,
}

export const DocumentDescriptionEditor = ({
  document,
  landmarkId,
}: Props) => {


  const [isExpanded, setIsExpanded] = useState(false)
  const [editedDescription, setEditedDescription] = useState(document.description || "")
  const [isSaving, setIsSaving] = useState(false)

  const { documentRelationsProvider } = useAppServices()

  const saveDescription = useCallback(() => {
    setIsSaving(true)
    documentRelationsProvider.updateDocDescription(document.id, editedDescription)
    setIsSaving(false)
    setIsExpanded(false)
  }, [documentRelationsProvider, document.id, editedDescription])


  useEffect(() => {
    setEditedDescription(document.description || "")
  }, [document.description])


  return (
    <div
      title={editedDescription || undefined}
      data-landmark={landmarkId}
    >
      {!isExpanded &&
        <div
          css={css`
            overflow: hidden;
            width: 100%;
          `}
        >
          <Button
            variant="outline"
            size="xs"
            aria-label={document.description || undefined}
            onClick={() => setIsExpanded(true)}
            maxWidth={150}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            justifyContent={"flex-start"}
          >
            {document.description}
          </Button>
        </div>
      }

      {isExpanded &&
        <div css={css`
          margin-bottom: 20px;
        `}>
          <AutoResizingInputText
            css={css`
            width: 100%;
            height: 100px;
            font-size: 0.7rem;
            padding: 2px 4px;
            margin-bottom: 4px;
          `}
            value={editedDescription || ""}
            onChange={e => {
              setEditedDescription(e.target.value)
            }}
          />
          <div>
            <ButtonTertiary
              size="xs"
              label="Cancel"
              onClick={() => setIsExpanded(false)}
            />
            <ButtonPrimary
              size="xs"
              label="Save"
              onClick={saveDescription}
              isLoading={isSaving}
              isDisabled={document.description === editedDescription}
            />
          </div>
        </div>
      }

    </div>
  )

}
