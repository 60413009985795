import { useDisclosure } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ShowMiniCommandMenuCommandArgs, showMiniCommandMenuCommandDefinition } from "../commandDefinitions/utils/showMiniCommandMenu"
import { useFeatureFlags } from "../../../utility-hooks/useFeatureFlags"
import { VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { getCmdsForAnywhere } from "../commandMenuData/getCmds/getCmdsForAnywhere"

export const useCommandMenuProvider = () => {

  const { experimentalEnabled } = useFeatureFlags()
  const disclosure = useDisclosure()


  const commandsForAnywhere = useMemo(() => {
    return getCmdsForAnywhere(experimentalEnabled)
  }, [experimentalEnabled])


  /** Opens the top level Command Menu */
  const openCommandMenu = useCallback(() => {

    const args: ShowMiniCommandMenuCommandArgs = {
      commands: commandsForAnywhere.map(c => c.commands).flat(),
      rectOfAnchorElement: undefined,
      shouldAdjustRectForScrollOffset: false,
      searchPlaceholderText: "Search commands",
    }

    showMiniCommandMenuCommandDefinition.triggerCommand?.(args)

  }, [disclosure])


  /** MINI COMMAND MENU */
  const [miniCommandMenuArgs, setMiniCommandMenuArgs] = useState<ShowMiniCommandMenuCommandArgs>()
  const [miniCommands, setMiniCommands] = useState<VerdiCommand[]>()
  const [miniCommandsLoading, setMiniCommandsLoading] = useState<Boolean>(false)

  const openMiniCommandMenu = useCallback((args: ShowMiniCommandMenuCommandArgs) => {
    if (args.commands.some(c => !c.name || !c.searchName)) {
      console.error(
        "openMiniCommandMenu: At least one command does not have a name or search property. \n" +
        "This may result in breaking the CmdK functionality, producing more errors. \n" +
        "To fix try filtering out commands with null names and search properties.", { args })
    }
    setMiniCommandMenuArgs(args)
  }, [setMiniCommandMenuArgs])

  const closeMiniCommandMenu = useCallback(() => {
    miniCommandMenuArgs?.onCloseCallback?.()
    setMiniCommandMenuArgs(undefined)
  }, [setMiniCommandMenuArgs, miniCommandMenuArgs])
  const miniCommandMenuIsOpen = useMemo(() => {
    return Boolean(miniCommandMenuArgs)
  }, [miniCommandMenuArgs])


  useEffect(() => {
    if (miniCommandMenuArgs?.loadCommands) {
      setMiniCommandsLoading(true)
      const controller = new AbortController()
      const signal = controller.signal
      const p = loadCommands(miniCommandMenuArgs.loadCommands, signal)
        .then((commands) => {
          setMiniCommands(commands)
          setMiniCommandsLoading(false)
        }).catch(() => {
          // This is hear to catch the abort signal
          // TODO: put other error handling logic here in case something else goes wrong
        })
      return () => {
        // triggers an error in the promise
        controller.abort()
      }
    } else {
      setMiniCommandsLoading(false)
      setMiniCommands(miniCommandMenuArgs?.commands)
    }
  }, [miniCommandMenuArgs?.commands, miniCommandMenuArgs?.loadCommands])


  return {
    commands: commandsForAnywhere,
    openCommandMenu,
    disclosure,
    openMiniCommandMenu,
    closeMiniCommandMenu,
    miniCommandMenuIsOpen,
    miniCommandMenuArgs: {
      ...miniCommandMenuArgs,
      commands: miniCommands,
      isLoading: miniCommandsLoading
    },
  }

}

async function loadCommands(commandsPromise: () => Promise<VerdiCommand[]>, signal: AbortSignal): Promise<VerdiCommand[]> {
  return new Promise(async (resolve, reject) => {
    if (signal.aborted) {
      reject(signal.reason)
    }
    signal.addEventListener("abort", () => {
      reject(signal.reason)
    })
    commandsPromise()
      .then((value) => {
        resolve(value)
      })
  })
}

export type CommandMenuProvider = ReturnType<typeof useCommandMenuProvider>
