import { useCallback, useState } from "react"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { DeRiskingStepOption } from "../../screens/deRiskingSteps/DeRiskingStepOptions"
import { getSuggestedActionsForAssumptions } from "./getSuggestedActions"


type Props = {
  getDocumentContextForAi: () => DocumentContextForAi | undefined,
}

export const useActionsSuggester = ({
  getDocumentContextForAi,
}: Props) => {


  const [isGenerating, setIsGenerating] = useState(false)
  const [suggestedItems, setSuggestedItems] = useState<DeRiskingStepOption[]>([])
  const updateSuggestedItem = useCallback((updatedItem: DeRiskingStepOption) => {
    setSuggestedItems(prev => prev.map(s => s.id === updatedItem.id ? updatedItem : s))
  }, [setSuggestedItems])

  const removeSuggestedItem = useCallback((itemToRemove: DeRiskingStepOption) => {
    setSuggestedItems(prev => prev.filter(s => s.id !== itemToRemove.id))
  }, [setSuggestedItems])

  const addSuggestedItem = useCallback((item: DeRiskingStepOption) => {
    setSuggestedItems(prev => [...prev, item])
  }, [setSuggestedItems])


  const generateSuggestedActionsForAssumptions = useCallback(async (
    docContext: DocumentContextForAi | undefined,
    assumptions: string[],
    resultCount: number = 3
  ) => {

    setIsGenerating(true)
    setSuggestedItems([])

    const results = await getSuggestedActionsForAssumptions(docContext, assumptions, resultCount)
    if (results.length > 0) {
      setSuggestedItems(results)
    }
    setIsGenerating(false)
    return
  }, [setIsGenerating, setSuggestedItems])


  return {
    generateSuggestedActionsForAssumptions,
    isGenerating,
    suggestedItems,
    updateSuggestedItem,
    removeSuggestedItem,
    addSuggestedItem,
  }
}

export type ActionsSuggester = ReturnType<typeof useActionsSuggester>


