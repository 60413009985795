import { useCallback, useEffect, useRef, useState } from "react";
import { AiSubscription } from "../utils/useAiSubscription";
import { DocumentMetadataFromAi } from "@verdi/shared-constants";
import { AiCoachCache } from "./aiCoachCache";
import { OpenAi } from "@verdi/shared-constants";
import {
  AiCommandType,
  useAiCoachCommandProcessor,
} from "./useAiCoachCommandProcessor";
import { UseAiTipTapBridge } from "../documents/useAiTipTapBridge";
import { InsertPosition } from "../documents/InsertPosition";
import { useAppServices } from "../../components/appServices/useAppServices";
import { cancelPendingApiRequests, detectDocumentMetadata, requestRelatedContextFromOtherDocsViaApi } from "../requests/AiApiRequests";
import { buildDocContextForPrompt } from "../promptUtils/buildDocContextForPrompt";


const secondsToWaitBetweenNudges = 10;
const nudgeInterval = secondsToWaitBetweenNudges * 1000;
const attemptsUntilStoppingTheNudges = 2;

export type DynamicPrompt = {
  promptTitle: string;
  prompt: string;
  hasBeenAdded?: boolean;
  hasBeenRejected?: boolean;
};

export interface DocAiEditOptions {
  prompt: string;
  insertAt?: InsertPosition;
  includeDocDetails?: boolean;
  includeDocMetadata?: boolean;
  includeRelatedContext?: boolean;
  includeCompanyContext?: boolean;
  sectionTitle?: string;
  isInline?: boolean;
  textToAddBefore?: string;
  openApiParams?: OpenAi.OpenAIParamsType;
}

export interface DocAiEditWithAiPromptIdOptions {
  documentVersion: number;
  currentAiPromptId: string;
  currentAiPromptInsertRange: { from: number; to: number };
}

type Props = {
  documentId: string
  aiTipTapBridge: UseAiTipTapBridge
  aiSubscription: AiSubscription
  aiCoachCache: AiCoachCache
  getCompanyContextForPrompt: () => Promise<string>
};
/** Facilitates the back and forth between the AI and the user */
export const useAiCoach = ({
  documentId,
  aiTipTapBridge,
  aiSubscription,
  aiCoachCache,
  getCompanyContextForPrompt,
}: Props) => {
  const {
    streamingIsInProgress,
    requestRelatedContextFromOtherDocs,
    relatedContextFromOtherDocs,
  } = aiSubscription;

  const { companyContextProvider } = useAppServices()
  const { getCompanyContext } = companyContextProvider;

  const onDocumentFirstLoaded = useCallback(async () => {
    // await startTheNudgingFlow(); // TODO: Uncomment this before pushing
    await getCompanyContext()
  }, [getCompanyContext]);

  // Fixes local dev issue running this twice
  const lastDocIdLoadedRef = useRef("");
  useEffect(() => {
    if (lastDocIdLoadedRef.current === documentId) return;
    lastDocIdLoadedRef.current = documentId;

    // Cancel any pending API requests
    cancelPendingApiRequests();
    onDocumentFirstLoaded();
  }, [documentId, cancelPendingApiRequests, onDocumentFirstLoaded]);

  /** Related Context from other docs */

  const [isGettingRelatedContext, setIsGettingRelatedContext] = useState(false);
  const loadRelatedContextFromOtherDocs = useCallback(
    async (useApi: boolean, shouldCheckCache = false) => {
      setIsGettingRelatedContext(true);

      if (shouldCheckCache) {
        const cachedResults =
          aiCoachCache.getRelatedContextFromOtherDocs(documentId);
        if (cachedResults) {
          setIsGettingRelatedContext(false);
          return cachedResults;
        }
      }

      if (useApi) {
        const results = await requestRelatedContextFromOtherDocsViaApi(
          documentId
        );
        setIsGettingRelatedContext(false);
        aiCoachCache.setRelatedContextFromOtherDocs(documentId, results);
        return results;
      }

      await requestRelatedContextFromOtherDocs(documentId);
    },
    [aiCoachCache, documentId, requestRelatedContextFromOtherDocs, requestRelatedContextFromOtherDocsViaApi]
  );
  useEffect(() => {
    if (relatedContextFromOtherDocs) {
      // specifically for when websocket returns a value
      setIsGettingRelatedContext(false);
      aiCoachCache.setRelatedContextFromOtherDocs(
        documentId,
        relatedContextFromOtherDocs
      );
    }
  }, [relatedContextFromOtherDocs, aiCoachCache, documentId]);

  /** Document Details, such as title, description and body */

  const [documentDetailsForPrompt, setDocumentDetailsForPrompt] =
    useState<string>("");
  const buildDocumentDetailsForPrompt = useCallback(
    (ignoreCurrentInsertPos: boolean) => {
      const document = aiTipTapBridge.getDocumentContextForAi();
      const { detailsForPrompt } = buildDocContextForPrompt(
        document,
        ignoreCurrentInsertPos
      );
      setDocumentDetailsForPrompt(detailsForPrompt);
      return detailsForPrompt;
    }, [document, aiTipTapBridge, aiSubscription.relatedContextFromOtherDocs,]);

  /** Document Metadata */

  const [isDetectingDocMetadata, setIsDetectingDocMetadata] = useState(false);
  const docMetadataRef = useRef<DocumentMetadataFromAi | undefined>();
  const detectDocMetadata = useCallback(
    async (shouldCheckCache = false) => {
      setIsDetectingDocMetadata(true);

      if (shouldCheckCache) {
        const cachedResults = aiCoachCache.getDocMetadata(documentId);
        if (cachedResults) {
          docMetadataRef.current = cachedResults;
          setIsDetectingDocMetadata(false);
          return cachedResults;
        }
      }

      const docMetadataResponse = await detectDocumentMetadata(documentId);
      if (!docMetadataResponse) return;
      let metadata;
      try {
        metadata = JSON.parse(docMetadataResponse) as DocumentMetadataFromAi;
        docMetadataRef.current = metadata;
        aiCoachCache.setDocMetadata(documentId, metadata);
      } catch (e) {
        console.warn(
          "error parsing doc metadata: ",
          e,
          "\n Bad metadata: ",
          docMetadataResponse
        );
      }
      setIsDetectingDocMetadata(false);
      return metadata;
    },
    [document, documentDetailsForPrompt, docMetadataRef, aiCoachCache, documentId, detectDocumentMetadata]
  );


  /** True when any AI background task is running */
  const [isThinking, setIsThinking] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [isRunningTheNudgingFlow, setIsRunningTheNudgingFlow] = useState(false);

  const startTheNudgingFlow = useCallback(async () => {
    setIsThinking(true);
    setIsRunningTheNudgingFlow(true);

    // TODO: once we improve our ability to run multiple tasks at once, we can run these in parallel
    const shouldCheckCache = true;
    await Promise.all([
      loadRelatedContextFromOtherDocs(true, shouldCheckCache),
      detectDocMetadata(shouldCheckCache),
    ]);

    await evaluateIfNudgeIsNeeded(0, shouldCheckCache);
    setIsThinking(false);
    setHasInitialized(true);
  }, [relatedContextFromOtherDocs, docMetadataRef, documentDetailsForPrompt, loadRelatedContextFromOtherDocs, detectDocMetadata]);

  const evaluateIfNudgeIsNeeded = useCallback(
    async (timesAttempted: number, shouldCheckCache = false) => {
      if (!docMetadataRef.current) {
        console.log(
          `No metadata was found, waiting ${secondsToWaitBetweenNudges} seconds`
        );
        if (timesAttempted > attemptsUntilStoppingTheNudges) {
          console.log("Will stop trying for now.");
          return;
        }

        // TODO: Handle on navigate to another document, stop the timer

        setTimeout(async () => {
          if (isThinking) return; // Already doing something
          setIsThinking(true);
          const newGoalForDoc = await detectDocMetadata();

          await evaluateIfNudgeIsNeeded(timesAttempted + 1);
          setIsThinking(false);
        }, nudgeInterval);
        return;
      }
    },
    [relatedContextFromOtherDocs, docMetadataRef, documentDetailsForPrompt]
  );

  const requestDocumentEdit = useCallback(
    async ({
      prompt,
      includeDocDetails = true,
      includeDocMetadata = true,
      includeRelatedContext = true,
      includeCompanyContext = true,
      insertAt = InsertPosition.endOfDocument,
      sectionTitle,
      isInline,
      textToAddBefore,
      openApiParams,
    }: DocAiEditOptions) => {
      console.log(
        "aiCoach.requestDocumentEdit() ",
        includeDocDetails,
        includeRelatedContext
      );

      let promptContext = "";
      if (includeCompanyContext) {
        const companyContext = await getCompanyContextForPrompt();
        if (companyContext) {
          promptContext += `\n\nInformation about the user's company: ${companyContext}`;
        }
      }
      if (includeDocDetails) {
        const ignoreCurrentInsertPos =
          insertAt === InsertPosition.endOfDocument;
        promptContext += `\n\n${buildDocumentDetailsForPrompt(
          ignoreCurrentInsertPos
        )}`; // always call this fresh
      }
      if (includeDocMetadata && docMetadataRef.current) {
        promptContext += `\n\nAdditional metadata: ${JSON.stringify(
          docMetadataRef.current
        )}\n`;
      }
      if (includeRelatedContext) {
        promptContext += `\n${relatedContextFromOtherDocs}`;
      }
      const context = aiTipTapBridge.getDocumentContextForAi();
      const completePrompt = `${promptContext ? `###Given the following context:\n${promptContext}\n###\n\n` : ""
        }INSTRUCTIONS:\n${prompt}`;

      console.log("completePrompt = ", { completePrompt });

      await aiSubscription.requestDocumentAiEdit(
        completePrompt,
        context,
        insertAt,
        sectionTitle,
        isInline,
        textToAddBefore,
        openApiParams
      );
    },
    []
  );

  const requestAiResponse = useCallback(
    async (
      prompt: string,
      includeDocContext: boolean,
      includeRelatedContext: boolean
    ) => {
      console.log(
        "aiCoach.requestAiResponse() ",
        includeDocContext,
        includeRelatedContext
      );

      const completePrompt = prompt;
      if (includeDocContext) prompt += documentDetailsForPrompt;
      if (includeRelatedContext) prompt += relatedContextFromOtherDocs;

      await aiSubscription.requestAiJsonStream(
        completePrompt,
        (parseJsonLine) => {
          console.log("parseJsonLine = \n", parseJsonLine);
        },
        (entireJsonResponse) => {
          console.log("entireJsonResponse = \n", entireJsonResponse);
        }
      );
    },
    []
  );

  const { processCommand: processCommandWithTheProcessor } =
    useAiCoachCommandProcessor({
      aiTipTapBridge,
      requestDocumentEdit,
      requestDocumentEditWithAiPromptId:
        aiSubscription.requestDocumentEditWithAiPromptId,
    });

  const processCommand = useCallback(async (command: AiCommandType) => {
    console.log("aiCoach.processCommand() ", command);
    await processCommandWithTheProcessor(command);
  }, []);

  return {
    hasInitialized,
    isThinking,
    onDocumentFirstLoaded,

    requestDocumentEdit,
    requestAiResponse,

    detectDocMetadata,
    isDetectingDocMetadata,
    docMetadataRef,

    streamingIsInProgress,

    loadRelatedContextFromOtherDocs,
    isGettingRelatedContext,

    buildDocumentDetailsForPrompt,
    currentDocContext: documentDetailsForPrompt,
    relatedContextFromOtherDocs,

    startTheNudgingFlow,
    isRunningTheNudgingFlow,

    processCommand,
  };
};

export type UseAiCoach = ReturnType<typeof useAiCoach>;
