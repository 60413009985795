import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Textarea,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { getValidPlaceholderName } from "@verdi/shared-constants";
import { CreateSnippetInput } from "./__generated__/useCreateSnippetMutation.graphql";

type Props = {
  placeholderKey: string;
  textValue: string;
  onChange?: (input: CreateSnippetInput) => void;
};

export const SnippetRow = (props: Props) => {
  useEffect(() => {
    setPlaceholderKey(props.placeholderKey);
    setTextValue(props.textValue);
  }, []);

  const [placeholderKey, setPlaceholderKey] = useState("");
  const [textValue, setTextValue] = useState("");

  const formatPlaceholder = (updatedPlaceholder: string) => {
    const formatted = getValidPlaceholderName(updatedPlaceholder);

    setPlaceholderKey(formatted);
    if (props.onChange)
      props.onChange({ placeholderKey: formatted, textValue });
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex: 2 1;
        font-weight: bold;
        padding: 5px;
        margin-bottom: 10px;
        @media (max-width: 800px) {
          flex-direction: column;
        }
      `}
    >
      <InputGroup>
        <InputLeftAddon
          children="{{"
          paddingRight={1}
          background="none"
          border="none"
          fontWeight="bold"
          opacity={0.8}
        />

        <Input
          placeholder="placeholder"
          value={placeholderKey}
          onChange={(evt) => formatPlaceholder(evt.target.value)}
          paddingLeft={1}
          paddingRight={1}
          css={css`
            width: 100%;
            min-width: 30px;
            font-weight: bold;
          `}
        />

        <InputRightAddon
          children="}}"
          paddingLeft={1}
          background="none"
          border="none"
          fontWeight="bold"
          opacity={0.8}
        />
      </InputGroup>

      <InputGroup marginRight={4}>
        <Textarea
          placeholder="text value"
          value={textValue}
          onChange={(evt) => {
            setTextValue(evt.target.value);
            if (props.onChange)
              props.onChange({ placeholderKey, textValue: evt.target.value });
          }}
          css={css`
            min-height: 60px;
          `}
        />
      </InputGroup>
    </div>
  );
};
