import { useState, useEffect } from "react";
import { Link, useRouter } from "found";
import {
  menuBackgroundColors,
  documentBackgroundColors,
} from "../../../components/ColorModeValues";
import useParams from "found/useParams";
import { validateInvitation } from "./network";
import { Alert, AlertIcon, Box, Button, Flex, Stack, useColorModeValue, } from "@chakra-ui/react";
import { VerdiLogo } from "../../../components/logo/VerdiLogo";

export const OrganizationValidateUser = () => {
  const [userValidated, setUserValidated] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [organizationName, setOrganizationNae] = useState<string>("");
  const { token } = useParams();
  const { router } = useRouter();

  const validateUser = async () => {
    try {
      const response = await validateInvitation(token);

      if (response.error) {
        setErrorMessage(response.error)
        return;
      }

      if (response) {
        if (response.newUser)
          console.log('invite response: ', response)
        const redirectUrl = `/register?orgInviteToken=${encodeURIComponent(token)}` +
          `&organizationName=${encodeURIComponent(response.organizationName)}`
          + `&invitedEmailAddress=${encodeURIComponent(response.invitedEmailAddress)}`

        router.push(redirectUrl)
      }
    } catch (error) {
      console.log("error", error);
      setValidationError(true);
    }
    return;
  };

  useEffect(() => {
    validateUser();
  }, [token]);

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor={useColorModeValue(
        menuBackgroundColors.light,
        menuBackgroundColors.dark
      )}
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "375px" }}>
          <Stack
            spacing={4}
            p="1rem"
            backgroundColor={useColorModeValue(
              documentBackgroundColors.light,
              documentBackgroundColors.dark
            )}
            boxShadow="sm"
            borderRadius="lg"
            py="25"
          >
            <div
              style={{
                margin: `12px`,
                marginLeft: `33px`,
                maxWidth: 275,
              }}>
              <VerdiLogo />
            </div>
            {userValidated ? (
              <>
                <Alert status={userValidated ? "success" : "error"}>
                  <AlertIcon />
                  {!validationError
                    ? `You have successfully been added to ${organizationName}`
                    : `There was an error adding you to ${organizationName}`}
                </Alert>
                <Link
                  as={Button}
                  to={{
                    pathname: "/",
                  }}
                  activePropName="active"
                >
                  Continue to Verdi
                </Link>
              </>
            ) : (
              <div>
                {errorMessage ? errorMessage : "Validating..."}
              </div>
            )}
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};
