import { useAppSelector } from "../../state/storeHooks"
import { css } from "@emotion/react"
import { DocumentSchema } from "@verdi/shared-constants"
import { DocMetadataState } from "../../state/docMetadataSlice"
import { ButtonPrimary } from "../buttons/ButtonPrimary"
import { autoDraftFullDocCommandDefinition } from "../commands/commandDefinitions/documentBody/autoDraftFullDoc"
import { setDocFrameworkShowOptionsCommandDefinition } from "../commands/commandDefinitions/framework/setDocFrameworkShowOptions"
import { VerdiIconAiSuggestions } from "../icons/VerdiIcons"


type Props = {
  docType: DocumentSchema.DocumentType
  editorAreaHasFocus: boolean
}
export const AiDocSuggestionsList = ({
  docType,
  editorAreaHasFocus,
}: Props) => {


  const summary = useAppSelector(DocMetadataState.getSummary)
  const isDoingAiOnDoc = useAppSelector(s => s.loadingStatus.doingAiOnDoc)


  if (!summary || summary?.bodyHasText || isDoingAiOnDoc.isInProgress) {
    return null
  }


  return (
    <div css={css`
      position: relative;
      min-height: 20px;
      width: calc(100% - 20px);
    `}>
      <div css={css`
        position: absolute;
        top: 10px;
        left: 0;
      `}>
        <div css={css`
          display: flex;
          flex-direction: row;
          gap: 8px;
        `}>
          {/* <ButtonPrimary
            leftIcon={<VerdiIconAiSuggestions />}
            label="Auto Draft"
            onClick={() => autoDraftFullDocCommandDefinition.triggerCommand?.({})}
          />

          <ButtonPrimary
            label="Select a framework"
            onClick={(evt) => setDocFrameworkShowOptionsCommandDefinition.triggerCommand?.({
              documentId: summary.documentId,
              isForOpportunity: docType === DocumentSchema.DocumentType.opportunity,
              shouldAutoApplyToDocBody: true,
              rect: evt.currentTarget.getBoundingClientRect(),
            })}
          /> */}
        </div>
      </div>
    </div>
  )
}

const suggestions: string[] = [
  "Auto-draft this doc",
]
