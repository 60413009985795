import { DocumentSchema } from ".."


export type AssumptionModel = AssumptionMutableFields & {

  id: string,
  frameworkQuestionId?: string,

  mainDoc?: {
    id: string,
    title: string | null,
    type: DocumentSchema.DocumentType,
  }

}

export type AssumptionMutableFields = {
  question?: string,
  answer?: string,
  relevanceLevel: AssumptionRelevanceLevel
  confidenceLevel: AssumptionConfidenceLevel
}


/* How relevant is this assumption */
export enum AssumptionRelevanceLevel {
  unset = 0,
  agnostic = 1,
  niceToHave = 2,
  valuable = 3,
  important = 4,
  critical = 5,
}
export type AssumptionRelevanceLevelOption = {
  value: AssumptionRelevanceLevel,
  label: string,
  description: string,
}
export const AssumptionRelevanceLevelOptions: AssumptionRelevanceLevelOption[] = [
  {
    value: AssumptionRelevanceLevel.unset,
    label: "",
    description: ""
  },
  {
    value: AssumptionRelevanceLevel.agnostic,
    label: "Agnostic",
    description: "One of many likely possibilities	"
  },
  {
    value: AssumptionRelevanceLevel.niceToHave,
    label: "Nice to have",
    description: "Helpful, yet with other possibilities"
  },
  {
    value: AssumptionRelevanceLevel.valuable,
    label: "Valuable",
    description: "Inconvenient if not true; some alternatives, but might be costly / have moderate implications for other elements"
  },
  {
    value: AssumptionRelevanceLevel.important,
    label: "Important",
    description: "(a) must be mostly true, or (b) alternatives would be expensive or would have significant implications to other elements"
  },
  {
    value: AssumptionRelevanceLevel.critical,
    label: "Critical",
    description: "Must be true to the full extent stated for any incarnation of this idea to work"
  },
]


/** How confident are we in understanding this assumption */
export enum AssumptionConfidenceLevel {
  unset = 0,
  unknown = 1,
  hypothesis = 2,
  anecdotal = 3,
  directional = 4,
  factual = 5,
}

/** How Confidence level should be displayed to the user */
export const ConfidenceLevelLabelMap = {
  [AssumptionConfidenceLevel.unknown]: "Unknown",
  [AssumptionConfidenceLevel.hypothesis]: "Hypothesis",
  [AssumptionConfidenceLevel.anecdotal]: "Anecdotal",
  [AssumptionConfidenceLevel.directional]: "Directional",
  [AssumptionConfidenceLevel.factual]: "Factual",
}

export type AssumptionConfidenceLevelOption = {
  value: AssumptionConfidenceLevel,
  label: string,
  description: string,
}
export const AssumptionConfidenceLevelOptions: AssumptionConfidenceLevelOption[] = [
  {
    value: AssumptionConfidenceLevel.unset,
    label: "",
    description: ""
  },
  {
    value: AssumptionConfidenceLevel.unknown,
    label: ConfidenceLevelLabelMap[AssumptionConfidenceLevel.unknown],
    description: "We have no idea"
  },
  {
    value: AssumptionConfidenceLevel.hypothesis,
    label: ConfidenceLevelLabelMap[AssumptionConfidenceLevel.hypothesis],
    description: "Your gut says it's true"
  },
  {
    value: AssumptionConfidenceLevel.anecdotal,
    label: ConfidenceLevelLabelMap[AssumptionConfidenceLevel.anecdotal],
    description: "Some evidence it might be true(e.g., it sometimes is true)"
  },
  {
    value: AssumptionConfidenceLevel.directional,
    label: ConfidenceLevelLabelMap[AssumptionConfidenceLevel.directional],
    description: "Not fully validated, but some anecdotal, proxy, or directional evidence that it is, and no significant reason to doubt it"
  },
  {
    value: AssumptionConfidenceLevel.factual,
    label: ConfidenceLevelLabelMap[AssumptionConfidenceLevel.factual],
    description: "It is true to the full extent stated"
  },
]
