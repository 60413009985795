import { useCallback } from "react"
import { SelectCommandDropdown } from "../../components/commands/miniCommandMenu/SelectCommandDropdown"
import { aiContextShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/aiContext/aiContextShowOptions"
import { useAppSelector } from "../../state/storeHooks"
import { CompanyContextState } from "../../state/companyContextSlice"
import { getHasEnoughCompanyContext } from "../documents/add/globalModal/AiContextGlobalModal"
import { VerdiIconDebug } from "../../components/icons/VerdiIcons"


export type Props = {
  landmarkId?: string,
}

export const AiContextEditor = ({
  landmarkId,
}: Props) => {


  const onDropDownOpen = useCallback((rect: DOMRect) => {
    aiContextShowOptionsCommandDefinition.triggerCommand?.({
    })
  }, [])

  const companyContext = useAppSelector(CompanyContextState.getFields)
  const companyContextString = JSON.stringify(companyContext)
  const isMissingCompany = !getHasEnoughCompanyContext(companyContextString)


  return (
    <SelectCommandDropdown
      data-landmark={landmarkId}
      size="xs"
      label={"View"}
      leftIcon={<VerdiIconDebug />}
      searchPlaceholderText="choose a framework"
      commandOptions={onDropDownOpen}
      onItemSelected={(command) => console.log("ai context ", { command })}
      maxWidth={150}
      shouldHighlightAsNudge={isMissingCompany}
      tooltip={isMissingCompany ? "Could use more company context" : undefined}
    />
  )
}
