import { Textarea, TextareaProps, forwardRef } from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";


export const AutoResizingInputText = forwardRef<TextareaProps, "textarea">((props: TextareaProps, ref) => {
  return (
    <Textarea
      resize="none"
      minHeight="unset"
      overflow="hidden"
      width="100%"
      ref={ref}
      minRows={1}
      as={ResizeTextarea}
      {...props}
    />
  )
})

