import { css } from "@emotion/react"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { OnboardingPopupMessage } from "../OnboardingPopupMessage"
import { OnboardingStepIds } from "../OnboardingStep"
import { useColorModeValue } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { PageHeading } from "../../../components/pageLayout/PageHeading"


export const InitialOnboardingScreen = () => {


  const { loggedInUserProvider, onboardingManager } = useAppServices()

  const squiggleImageUrl = `/images/design-squiggle-${useColorModeValue("light", "dark")}.jpg`

  const [visiblePhrases, setVisiblePhrases] = useState<PhaseToShow[]>([])
  // TODO: increment frame every 2 seconds


  /** Delay showing of messages */
  const [messageCountToShow, setMessageCountToShow] = useState(0)
  const intervalId = useRef<number>();

  useEffect(() => {
    if (messageCountToShow <= phrasesToShow.length) {
      intervalId.current = window.setInterval(() => {
        console.log("did interval")

        const incremented = messageCountToShow + 1
        setMessageCountToShow(incremented)
        setVisiblePhrases(phrasesToShow.slice(0, incremented))
        if (incremented > phrasesToShow.length) {
          clearInterval(intervalId.current)
          console.log("ending the madness")
          setTimeout(() => {
            setIsShowingEndMessage(true)
          }, 1000)
        }
      }, 500)
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [messageCountToShow]);
  const [isShowingEndMessage, setIsShowingEndMessage] = useState(false)

  const gradientColors = useColorModeValue(
    ["#0068b2", "#5600b2"],
    ["#80c9ff", "#bd80ff"])

  const userName = loggedInUserProvider.loggedInUser?.name


  return (
    <div>
      <PageHeading
        title="Welcome to Verdi"
        description="Your AI guide to help you discover and develop new opportunities"
      />

      <div css={css`
        padding: 10px;
        width: 100%;
        height: auto;
        max-width: 1200px;
        position: relative;
        color: green;
        margin: 20px 0;
       
        & > div {
          position: absolute;
          color: red;
          background: -webkit-radial-gradient(
            50% 100%,
            circle closest-corner,
            ${gradientColors[0]},
            ${gradientColors[1]}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        @keyframes fadeIn {
          0% { opacity: 0; }
          100% { opacity: 1; }
        }
        @keyframes fadeInThenLeave {
          0% { opacity: 0; }
          5% { opacity: 1; }
          70% { opacity: 1; }
          100% { opacity: 0; }
        }
        @keyframes victoryDanceSpinning {
          0% { 
            transform:rotate(0deg) scale(1.2);
           }
           50% { 
            transform: scale(0.8);
           }
          100% { 
            transform:rotate(360deg) scale(1.2);
           }
        }
        @media (max-width: 420px) {
        font-size: x-small;
      }
      `}>
        <img src={squiggleImageUrl} alt="design squiggle" />

        <div css={css`
          top: 20px;
          left: 20px;
        `}>
          The discovery process is not linear. It is a squiggle ...
        </div>

        {visiblePhrases.map((phrase, key) =>
          <div key={key}
            css={css`
              top: ${phrase.top};
              left: ${phrase.left};
              animation: fadeIn 500ms ease-in;
            `}>
            <div css={css`
            `}>
              {phrase.name}
            </div>
            {key < visiblePhrases.length &&
              <div css={css`
                position: absolute;
                top: ${key % 2 === 0 ? 30 : -30}px;
                width: 100%;
                display: flex;
                justify-content: center;
                animation: fadeInThenLeave 2s linear;
                opacity: 0;
              `}>
                <img src="/images/verdi-robot.svg" alt="verdi robot" />
              </div>
            }
          </div>
        )}

        {isShowingEndMessage &&
          <div css={css`
          position: absolute;
          top: 50%;
          right: 2%;
          `}>
            <img css={css`
              position: absolute;
              top: calc(50% - 12px);
              left: calc(50% - 12px);
            `}
              src="/images/verdi-robot.svg"
              alt="verdi robot"
            />
            <img css={css`
              opacity: 0.3;
              animation: victoryDanceSpinning 10s infinite linear;
            `}
              src="/images/verdi-robot-celebrate.svg"
              alt="verdi robot" />
          </div>
        }

      </div>



      <div css={css`
        opacity: 0.5;
        margin: 20px 10px;
        line-height: 1;
        font-size: x-small;
      `}>
        Image source: The Process of Design Squiggle by Damien Newman,
        <a href="https://thedesignsquiggle.com/" target="_blank" rel="noreferrer">
          thedesignsquiggle.com
        </a>
      </div>

      {isShowingEndMessage &&
        <OnboardingPopupMessage
          stepId={OnboardingStepIds.Welcome}
          buttonText="Let's Get started!"
          buttonAction={() => {
            onboardingManager.onboardingDisclosure.onClose()
          }}
          buttonActionUrl="/organization/context"
          buttonProgressKey="squigglePageAt"
        >
          <p>
            Hi{userName ? " " + userName : ""}! I’m @Verdi
          </p>
          <p>
            An AI guide that will help you with all of these steps and more!
          </p>
        </OnboardingPopupMessage>
      }
    </div>
  )
}

type PhaseToShow = {
  name: string
  top: string
  left: string
}

const phrasesToShow: PhaseToShow[] = [
  { name: "Problem Definition", top: "20%", left: "10%" },
  { name: "Assumptions Mapping", top: "70%", left: "15%" },
  { name: "Research", top: "30%", left: "30%" },
  { name: "Insights", top: "60%", left: "45%" },
  { name: "Solutions", top: "50%", left: "60%" },
]
