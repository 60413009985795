import { Node } from "@tiptap/pm/model"
import { PlaceholderInlineNodeAttributes } from "./nodeExtensions"


/** Extracts raw text from the entire document body
 * 
 *  Ideally use this instead of built in prose-mirror methods, 
 *  as it can handle placeholder nodes and other things that 
 *  are specific to our current schema, or that we might add in the future
 */
export const getPlainTextFromDocumentNode = (node: Node) => {
  // TODO: Make this account for placeholder nodes
  // IDEA: Could look at how `getDocAsIndentedMarkdown` traverses the document,
  // Maybe even combine some of the logic with this process.
  return node.textBetween(0, node.nodeSize - 2, '\n')
}


/** Extracts the current line text, including from placeholder nodes
 * 
 *  Typically a node of type "Title" (Title.ts) should be passed here, 
 *  given that it can have children of type "text" and "placeholderInline"
 */
export const getPlainTextFromTitleNode = (titleNode: Node) => {

  const lineParts: string[] = []
  titleNode.descendants((childNode, pos) => {
    if (childNode.type.name === "text") {
      lineParts.push(childNode.textContent)
    } else if (childNode.type.name === "placeholderInline") {
      lineParts.push(renderPlaceholderNodeAsText(childNode.attrs as PlaceholderInlineNodeAttributes))
    }
  })
  return lineParts.join("")
}

const renderPlaceholderNodeAsText = (nodeAttrs: PlaceholderInlineNodeAttributes) => {
  return `[[ ${nodeAttrs.text} ]]`
}

