import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DocMetadataSummary } from '../ai/documents/DocUpdatedEvent'


interface IDocMetadataState {
  summary?: DocMetadataSummary
}

/** Store the current doc's summary of metadata */
const initialState: IDocMetadataState = {
  summary: undefined
}

export const docMetadataSlice = createSlice({
  name: 'docMetadata',
  initialState,
  reducers: {
    setSummary: (state, action: PayloadAction<DocMetadataSummary | undefined>) => {
      state.summary = action.payload
    },
    clearSummary: (state) => {
      state.summary = undefined
    },
  },
  selectors: {
    getSummary: (state) => state.summary,
  }
})

export const DocMetadataState = {
  ...docMetadataSlice.actions,
  ...docMetadataSlice.selectors,
}

export default docMetadataSlice.reducer
