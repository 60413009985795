import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { getPlaceholderSuggestionsCommandDefinition } from "../../commandDefinitions/documentBody/placeholders/loadCurrentPlaceholderSuggestions"
import { loadingCommandPlaceholderCommandDefinition } from "../../commandDefinitions/utils/loadingCommandPlaceholder"
import { ReplacePlaceholderWithCommandArgs, replacePlaceholderWithCommandDefinition } from "../../commandDefinitions/documentBody/replacePlaceholderWith"
import { PlaceholderSuggestionInfo } from "../../../../state/suggestions/suggestionsForPlaceholdersSlice"
import { RunAIPromptOnDocCommandArgs, runAIPromptOnDocCommandDefinition } from "../../commandDefinitions/documentBody/runAIPromptOnDoc"
import { DocumentSchema } from "@verdi/shared-constants"
import { removePlaceholderCommandDefinition } from "../../commandDefinitions/documentBody/placeholders/removePlaceholder"
import { addDocFromSelectionCommandDefinition } from "../../commandDefinitions/document/addDocFromSelection"


export const getCmdsForPlaceholder = (
  currentPlaceholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes | undefined,
  allSuggestions: PlaceholderSuggestionInfo[] | undefined,
): VerdiCommandGroup[] => {

  if (!currentPlaceholderAttrs || !currentPlaceholderAttrs.text) {
    return []
  }

  const { RemoveOnly } = DocumentSchema.NodeExtensions.PlaceholderBehaviorTypes
  if (currentPlaceholderAttrs.behaviors?.includes(RemoveOnly)) {
    return [{
      title: "",
      hideTitle: true,
      commands: [removePlaceholderCommandDefinition.buildMenuItem({
        mode: "deletePlaceholder",
      })],
    } as VerdiCommandGroup]
  }

  const commands: VerdiCommand[] = getCommandsBasedOnBehavior(currentPlaceholderAttrs, allSuggestions)

  const group: VerdiCommandGroup = {
    title: "Replace with ...",
    commands,
  }

  return [group]
}


const getCommandsBasedOnBehavior = (
  currentPlaceholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes,
  allSuggestions: PlaceholderSuggestionInfo[] | undefined,
): VerdiCommand[] => {


  const commands: VerdiCommand[] = []
  const suggestionInfo = allSuggestions?.find(s => s.placeholderText === currentPlaceholderAttrs.text)

  if (currentPlaceholderAttrs.behaviors?.includes(DocumentSchema.NodeExtensions.PlaceholderBehaviorTypes.LinkToRelatedDoc)) {

    commands.push(addDocFromSelectionCommandDefinition.buildMenuItem?.({
      commandTitle: `Add Note > "${currentPlaceholderAttrs.text.substring(0, 30)}"`,
      newDocTitle: currentPlaceholderAttrs.text,
    }))

    commands.push(removePlaceholderCommandDefinition.buildMenuItem?.({
      mode: "dismissPlaceholder",
    }))

    return commands
  }

  if (suggestionInfo?.isLoading) {

    commands.push(loadingCommandPlaceholderCommandDefinition.buildMenuItem({
      loadingMessage: "loading suggestions ...",
    }))

  } else if (suggestionInfo?.suggestions && suggestionInfo.suggestions.length > 0) {

    for (const replacement of suggestionInfo.suggestions) {

      if (replacement.replaceWithMode === "text") {
        commands.push(replacePlaceholderWithCommandDefinition.buildMenuItem?.(
          replacement.args as ReplacePlaceholderWithCommandArgs
        ))
      } else if (replacement.replaceWithMode === "customAiPrompt") {
        commands.push(runAIPromptOnDocCommandDefinition.buildMenuItem?.(
          replacement.args as RunAIPromptOnDocCommandArgs
        ))
      }
    }
    commands.push(getPlaceholderSuggestionsCommandDefinition.buildMenuItem?.({
      title: "Refresh ...",
      lifecycleState: "refresh",
    }))

  } else {
    commands.push(getPlaceholderSuggestionsCommandDefinition.buildMenuItem?.({
      title: "Get suggestions ...",
      lifecycleState: "load-new",
    }))
  }

  return commands
}
