import { css } from "@emotion/react"
import { RouterState } from "found"
import { VerdiLogo } from "../../components/logo/VerdiLogo"
import { useColorMode } from "@chakra-ui/react"
import { VerdiIconClose, VerdiIconDarkMode, VerdiIconLightMode } from "../../components/icons/VerdiIcons"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { navigateToHome } from "../../components/commands/commandDefinitions/navigateTo"


type Props = {
  router: RouterState<any>
}
export const OnboardingPageHeader = ({
  router,
}: Props) => {


  const { colorMode, toggleColorMode } = useColorMode()


  return (

    <div css={css`
        border-bottom: 1px solid #99999922;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}>
      <a href="/"
        onClick={(e) => {
          e.preventDefault()
          router.router.push("/")
        }}
        css={css`
                width: 100px;
                margin-left: 4px;
                display: inline-block;
              `}>
        <VerdiLogo />
      </a>

      <div>

        <IconButtonTertiary
          aria-label="Change dark / light mode"
          icon={colorMode === "light" ? <VerdiIconDarkMode /> : <VerdiIconLightMode />}
          onClick={toggleColorMode}
          marginRight={1}
        />

        <IconButtonTertiary
          aria-label="Leave onboarding"
          icon={<VerdiIconClose />}
          onClick={() => navigateToHome()}
          opacity={0.3}
        />
      </div>
    </div>

  )
}
