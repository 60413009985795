import { ButtonPrimary } from "../buttons/ButtonPrimary"


type Props = {
  toggleCommandMenu: (targetElement?: HTMLBaseElement) => void
  isExpanded: boolean
}
export const MinimizedFloater = ({ toggleCommandMenu, isExpanded }: Props) => {

  return (
    <ButtonPrimary
      label="/"
      title="Show commands"
      aria-label="generate AI"
      size="xs"
      height="20px"
      padding={0}
      marginLeft={40}
      onClick={(evt) => {
        toggleCommandMenu((evt.currentTarget as any) as HTMLBaseElement || undefined)
      }}
      // colorScheme={isExpanded ? "grey" : "verdiButtonPrimary"}
      // color={"white"}
      borderWidth={0}
    // variant="solid"
    // fontWeight="bold"
    // textAlign="center"
    >
      {isExpanded
        ? <span>
          &nbsp;
        </span>
        :
        <span>/</span>
      }
    </ButtonPrimary>
  )
}
