import { AiEditedMark } from "./AiEdited.mark";
import { AiSuggestionMark } from "./AiSuggestion.mark";
import KnowledgeGraphMark from "./KnowledgeGraph.mark";
import { PlaceholderInlineMarkInstance } from "./PlaceholderInline.mark";
import { attribution } from "./attribution";

const marks = [
  AiEditedMark,
  AiSuggestionMark,
  KnowledgeGraphMark,
  attribution,
  PlaceholderInlineMarkInstance,
];
export default marks;

export { VerdiMarkType } from "./VerdiMarksCommon";

export {
  AiEditedMark,
} from "./AiEdited.mark";
export {
  AiSuggestionMarkAttributes,
  MARK_CLICKED_EVENT_KEY,
  AiSuggestionMarkCommand,
} from "./AiSuggestion.mark";

export {
  PlaceholderInlineMarkAttributes,
  PlaceholderInlineMarkInstance,
  PlaceholderInlineMarkType,
  createNewPlaceholderInlineMark,
} from "./PlaceholderInline.mark"

