import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'
import { Tag } from './TagsSlice'


type DocumentTag = Data.DocumentTags.DocumentTagDto

interface InternalState {
  docTags: DocumentTag[]
}
const initialState: InternalState = {
  docTags: [],
}

export const docTagsSlice = createSlice({
  name: 'docTags',
  initialState,
  reducers: {
    setAll: (state, action: PayloadAction<DocumentTag[]>) => {
      state.docTags = action.payload
    },
    add: (state, action: PayloadAction<DocumentTag>) => {
      state.docTags.push(action.payload)
    },
    remove: (state, action: PayloadAction<DocumentTag>) => {
      const { docId, tagId } = action.payload
      state.docTags = state.docTags.filter(df => !(df.docId === docId && df.tagId === tagId))
    },
    removeAllForTag: (state, action: PayloadAction<{ tagId: string }>) => {
      const tagId = action.payload.tagId
      state.docTags = state.docTags.filter(df => df.tagId !== tagId)
    }
  },
  selectors: {
    getAll: (state) => state.docTags,
    getAllForDoc: (state, docId: string) => {
      return state.docTags.filter(df => df.docId === docId)
    }
  }
})
export const DocTagsState = {
  ...docTagsSlice.actions,
  ...docTagsSlice.selectors,
}

export default docTagsSlice.reducer


/** Maps a documentId to an array of tags */
export type DocTagDictionary = {
  [docId: string]: Tag[]
}
