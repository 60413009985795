import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { prosemirrorNodeFromDocOutlineEntry, writeContentToDocBody, writeToDocBodyCommandDefinition } from "./writeToDocBody"
import { findTextRange } from "../../../../screens/document/tiptapEditor/extensions/commands/verdiMarks"
import { addMarkAsAiEdit } from "./inlineDocSuggestions/docBodyMarkCreate"
import { cleanupAiOutputItem } from "../../../../ai/utils/cleanupAiOutput"
import { DocumentOutlineEntry } from "../../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { Editor } from "@tiptap/core"


const cmdType = GlobalCommandType.findAndReplace

/** Finds text, then writes new text to the current document body */
export const findAndReplaceCommandDefinition: GlobalCommandDefinition<FindAndReplaceCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: FindAndReplaceCommandArgs) => {
    const title = "Find and replace"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: FindAndReplaceCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('findAndReplace: No editor found in servicesForCurrentDoc')
      return
    }

    const textToInsert = cleanupAiOutputItem(args.textToAdd)

    // Get the position of the target text to find
    const ranges = findTextRange(args.textToFind, editor.state.doc)
    console.log("findAndReplace", { args, ranges, contentToInsert: textToInsert })
    const range = ranges[0] || undefined


    if (args.mode === "replace" && range) {

      console.log("findAndReplace: replacing text")
      editor.commands.setTextSelection(range)
      writeContentToDocBody({ // Simply write raw text to the document
        editor,
        insertPosition: InsertPosition.currentCursorSelection,
        contentToInsert: textToInsert,
      })

      addMarkAsAiEdit(editor, textToInsert)

      onProcessingComplete(true)
      return
    }

    // These cases require more than just plain text
    const documentOutline: DocumentOutlineEntry[] = []
    documentOutline.push({
      type: "text",
      content: textToInsert,
    })
    // const nodeToAdd = prosemirrorNodeFromDocOutlineEntry(documentOutline[0])

    if (args.mode === "insertAsNextLineAfter" && range) {

      const nodeToAdd = prosemirrorNodeFromDocOutlineEntry(documentOutline[0])
      writeContentToDocBody({
        editor,
        contentToInsert: nodeToAdd,
        insertPosition: InsertPosition.AsLastChildOfCurrentNode,
      })

    } else {

      // Just append to end of document if all else fails
      writeToDocBodyCommandDefinition.triggerCommand?.({
        documentOutline,
        insertPosition: InsertPosition.endOfDocument,
      })
    }


    addMarkAsAiEdit(editor, textToInsert)


    // editor.commands.setVerdiMark
    // const mark = editorState.schema.marks[markType].create(args.attributes);
    //   editorCommands.setVerdiMark(from, to, mark);
    // docBodyMarkCreateCommandDefinition.triggerCommand?.({
    //   markType: "AiSuggestion",
    //   attributes: { suggestionType: "findAndReplace" },
    //   markStrategy: "range",
    // })

    // writeContentToDocBody({
    //   editor,
    //   contentToInsert: args.textToAdd,
    //   insertPosition: InsertPosition.currentCursorSelection,
    // })
    args.onCompleted?.()
    onProcessingComplete(true)
  },
  triggerCommand: (args: FindAndReplaceCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type FindAndReplaceCommandArgs = {
  textToFind: string
  textToAdd: string
  mode: "replace" | "insertAsNextLineAfter"
  onCompleted?: () => void
}



const insertAsNextLineAfter = (editor: Editor, range: { from: number; to: number }, contentToInsert: string) => {
  console.log("insertAsNextLineAfter")


}
