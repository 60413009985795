import { useDisclosure } from "@chakra-ui/react"
import { useCallback, useState } from "react"
import { AddOpportunityShowOptionsCommandArgs } from "../../../../components/commands/commandDefinitions/opportunity/addOpportunityShowOptions"


export const useAddNewOpportunityGlobalProvider = () => {
  const disclosure = useDisclosure()
  const [modalArgs, setModalArgs] = useState<AddOpportunityShowOptionsCommandArgs>()

  const openAddNewOpportunityGlobalModal = useCallback((args?: AddOpportunityShowOptionsCommandArgs) => {
    setModalArgs(args)
    disclosure.onOpen()
  }, [disclosure, setModalArgs])


  return {
    disclosure,
    modalArgs,
    openAddNewOpportunityGlobalModal,
  }
}

export type AddNewOpportunityGlobalProvider = ReturnType<typeof useAddNewOpportunityGlobalProvider>
