import { forwardRef, useEffect, useMemo, useRef, } from 'react'
import { css } from '@emotion/react'
import { CircularProgress, useColorModeValue } from '@chakra-ui/react'
import { Editor } from '@tiptap/core'
import { EditorView } from '@tiptap/pm/view'
import { useColor } from '../../../../utility-hooks/useColor'
import { ComboBoxController } from '../../../../components/commands/miniCommandMenu/comboBox/useComboBoxController'
import { ComboBox } from '../../../../components/commands/miniCommandMenu/comboBox/ComboBox'


export type CommandMenuKeyRef = {
  onKeyDown: (event: KeyboardEvent, view: EditorView) => boolean
}

type Props = {
  menuIsOpen: boolean
  closeMenu: (wasDismissed: boolean) => void
  editor?: Editor | null
  comboBoxState: ComboBoxController
  isLoading?: boolean
  maxWidth: number,
  maxHeight: number,
}

const CommandMenuContentUnforwarded: React.ForwardRefRenderFunction<CommandMenuKeyRef, Props> =
  ({
    menuIsOpen,
    closeMenu,
    editor,
    comboBoxState,
    isLoading,
    maxWidth,
    maxHeight,
  }: Props, ref) => {

    const menuWrapperRef = useRef<HTMLDivElement | null>(null)
    const { searchText } = comboBoxState

    // When menu opens up, scroll to the top
    useEffect(() => {
      if (menuIsOpen && menuWrapperRef.current) {
        menuWrapperRef.current.scroll({ top: 0 })
      }
    }, [menuWrapperRef, menuIsOpen])


    const thereIsAtLeastOneCommandToShow = useMemo(() => {
      return comboBoxState.filteredCommandGroups.some(cg => cg.commands.length > 0)
    }, [comboBoxState.filteredCommandGroups])


    // Auto close the CM if there are no commands to show
    useEffect(() => {
      if (searchText.length > 10 && !thereIsAtLeastOneCommandToShow) {
        console.info("Auto closing the command menu because there are no commands to show")
        closeMenu(true)
      }
      //--eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, thereIsAtLeastOneCommandToShow, closeMenu])



    const backgroundColor = useColor("surfaceBg")
    const borderColor = useColor("surfaceBorder")
    const dropShadowColor = useColorModeValue('rgba(0,0,0,0.1)', 'rgba(0,0,0, 0.5)')


    // const onCloseClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((evt) => {
    //   closeMenu(true)
    //   evt.preventDefault()
    // }, [closeMenu])


    if (!editor) return null


    return (
      <div
        /* used in items to find the element to check visibility */
        ref={menuWrapperRef}
        className='theme-toggle'
        css={css`
          position: relative;
          width: 100%;
          padding: 0;
          max-width: 450px;
          /* overflow-y: auto; */
          background-color: ${backgroundColor};
          border: 1px solid ${borderColor};
          border-radius: 8px;
          box-shadow: 1px 1px 8px 4px ${dropShadowColor};
          /* max-height: 200px; */
      `}>
        {/* <div css={css`
            position: absolute;
            top: 0;
            right: 16px;
          `}>
          <IconButton
            aria-label='close'
            title="close"
            variant="ghost"
            icon={<VerdiIconClose />}
            opacity={0.3}
            size="sm"
            onClick={onCloseClick}
          />
        </div> */}

        <div css={css`
          padding: 0 4px 4px;
        `}>

          {
            isLoading &&
            <CircularProgress
              size={5}
              isIndeterminate
              css={css`
              top: 15px;
              right: 15px;
              position: absolute;
          `}
            />
          }


          {!thereIsAtLeastOneCommandToShow &&
            <div css={css`
              padding: 8px;
              font-size: small;
            `}>
              no matching commands for <strong>'{searchText}'</strong>
            </div>
          }

          <ComboBox
            comboBoxState={comboBoxState}
            hasExternalInput={true}
            shouldConstrainLargeText={true}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
          />


        </div>
      </div>
    )
  }

export const CommandMenuContent = forwardRef(CommandMenuContentUnforwarded)
