import { useRouter } from "found";
import {
  ComponentProps,
  useCallback,
  useRef,
  useState,
} from "react";
import { DocumentSettingsEditor } from "./DocumentSettingsEditor";

import { css } from "@emotion/react";
import { DocumentOriginBanner } from "./DocumentOriginBanner";
import "./debug-css.css";
import {
  ZoomDetailsForDocument,
  ZoomTranscriptForDocument,
} from "../zoom/ZoomDetailsForDocument";
import { useUrlParams } from "../../utility-hooks/useUrlParams";
import { Collaborators } from "./Collaborators";
import { useDocumentSubscription } from "../../components/documentSubscriptions/useDocumentSubscription";
import { ProsemirrorNodes } from "@verdi/shared-constants";
import { TiptapEditor } from "./tiptapEditor/TipTapEditor";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import DocumentErrorBoundary from "./DocumentErrorBoundary";
import { mediaQuerySmallWidth } from "../../components/pageLayout/styleHelpers";
import { generateClientId } from "./tiptapEditor/utils/clientIdGenerator";
import { PageLayoutState } from "../../components/pageLayout/usePageLayoutState";
import { DocumentEditorQueryData, useDocumentEditorDataLoader } from "./useDocumentEditorDataLoader";
import { AppServices } from "../../components/appServices/useRegisterAppServices";
import { OpportunitySubTabs } from "../opportunities/OpportunitySubTabs";
import { useAppServices } from "../../components/appServices/useAppServices";
import { AutoAiModeBanner } from "../../components/aiComponents/AutoAiModeBanner";


const mainEditorPadding = 0;
const idkWhyFudge = -2;
type EditorProps = {
  pageLayoutState: PageLayoutState;
  documentId?: string;
  handleClick?: ComponentProps<typeof TiptapEditor>["handleClick"];
  onCurrentBlockContextChange?: (args: any) => void;
  sharedServicesProvider: AppServices
};

type UnSuspendedProps = {
  response: DocumentEditorQueryData;
  pageLayoutState: PageLayoutState;
  documentId: string;
  handleClick?: ComponentProps<typeof TiptapEditor>["handleClick"];
  onCurrentBlockContextChange?: (args: any) => void;
  sharedServicesProvider: AppServices
};

function UnSuspendedDocumentEditor({
  response,
  pageLayoutState,
  documentId,
  handleClick,
  onCurrentBlockContextChange,
  sharedServicesProvider,
}: UnSuspendedProps) {
  const [tipTapData, setTipTapData] = useState<
    ProsemirrorNodes.TipTapDocument | undefined
  >(undefined);
  const [selectedZoomTimestamp, setSelectedZoomTimestamp] = useState<string>();
  const document = response.node;
  const [autoFocusTitle] = useState(!document?.title?.length);
  const scrollToTranscriptRef = useRef<ZoomTranscriptForDocument>(null);
  const { debug: shouldDebug, zoom: showZoom } = useUrlParams();

  if (!response?.loggedInUser?.id) {
    throw new Error(`Please log in to edit documents`);
  }
  const clientId = generateClientId(response?.loggedInUser?.id);

  const { toast } = useAppServices()

  const tellMessageToUser = useCallback((message: string) => {
    toast.showSuccess(message, { duration: 3000 })
  }, [toast]);

  const documentSubscription = useDocumentSubscription(
    clientId,
    documentId,
    tellMessageToUser
  );

  if (!document) throw new Error(`No document found for id`);

  const documentDescriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const onShiftTabFromEditor = () => {
    documentDescriptionRef.current?.focus();
  };
  const loggedInUserId = response.loggedInUser?.id;

  return (
    <>
      <Modal
        isOpen={
          !shouldDebug &&
          Boolean(documentSubscription?.mustRefreshMessage?.length)
        }
        onClose={() => { }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>🫠 Opps..it broke 🫠</ModalHeader>
          <ModalBody>
            <p>{documentSubscription?.mustRefreshMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DocumentOriginBanner
        document={document}
        leftPadding={mainEditorPadding + idkWhyFudge}
      />
      <div
        css={css`
          width: 100%;
          max-width: 100%;
          margin: auto;
          margin-top: 0;
          display: flex;
          flex-direction: row;
          @media ${mediaQuerySmallWidth} {
            padding: 0;
          }
        `}
      >
        <div
          className={shouldDebug ? "debug" : ""}
          css={css`
            flex: 1;
            padding: ${mainEditorPadding}px;
            min-width: 0;
            padding-bottom: 0;
            @media ${mediaQuerySmallWidth} {
              padding: 0;
            }
          `}
        >
          <DocumentSettingsEditor
            document={document}
            loggedInUserId={loggedInUserId}
          />


          {document.type === "opportunity" &&
            <div css={css`
              margin-bottom: 20px;
            `}>
              <OpportunitySubTabs
                currentPage="opportunityBrief"
                opportunityMainDocId={document.id}
                displayAs="tabs"
              />
            </div>
          }

          <div css={css`
            position: relative;
          `}>
            <div css={css`
              position: absolute;
              top: -12px;
              left: -20px;
            `}>
              <AutoAiModeBanner />
            </div>
          </div>


          {typeof documentSubscription === "undefined" ? null : (
            <div
              css={css`
                padding: 20px;
              `}
            >
              <TiptapEditor
                key={document.id + document.currentSnapshot?.version}
                pageLayoutState={pageLayoutState}
                query={response}
                document={document}
                setTipTapData={setTipTapData}
                showDevTools={shouldDebug}
                selectedZoomTimestamp={selectedZoomTimestamp}
                scrollToTranscriptRef={scrollToTranscriptRef}
                documentSubscription={documentSubscription}
                onShiftTab={onShiftTabFromEditor}
                handleClick={handleClick}
                onCurrentBlockContextChange={onCurrentBlockContextChange}
                sharedServicesProvider={sharedServicesProvider}
              />
            </div>
          )}
        </div>
        {showZoom && document && document.type === "interview" && (
          <ZoomDetailsForDocument
            document={document}
            ref={scrollToTranscriptRef}
          />
        )}
      </div>
      {typeof documentSubscription !== "undefined" && shouldDebug && (
        <Collaborators
          documentId={document.id}
          subscriberIds={documentSubscription.subscriberIds}
        />
      )}
    </>
  );
}

export const UnSuspendedDocumentEditorQuery = (props: EditorProps) => {
  const router = useRouter();
  const documentId = props.documentId || router.match.params["documentId"];

  const { response } = useDocumentEditorDataLoader(documentId)
  if (!response) return null
  const document = response.node;
  if (!document) return null;


  return (
    <UnSuspendedDocumentEditor
      key={document.id + document.currentSnapshot?.version}
      {...props}
      documentId={documentId}
      response={response}
    />
  );
};

export const DocumentEditor = (props: EditorProps) => (
  <DocumentErrorBoundary>
    <UnSuspendedDocumentEditorQuery {...props} />
  </DocumentErrorBoundary>
);
