import { Node } from "@tiptap/pm/model"
import { DocumentSchema } from "@verdi/shared-constants"
import { findChildren } from "prosemirror-utils"


/** Returns the document body with hierarchy represented as indents (tabs/spaces) and some very limited markdown
 * 
 *  @param doc The document node
 *  @param insertPosition Use -1 to not insert anything. The position in the document where the AI should insert text. 
 */
export const getDocAsIndentedMarkdown = (doc: Node, insertPosition: number = -1): BodyAsStringParts => {

  const textNodes = findChildren(doc, (n) => n.type.name === "section" || n.type.name === "freeText", true)
  const lines: LineInfo[] = []

  for (const n of textNodes) {
    /** IMPORTANT: Some Title nodes may not have any child text nodes
     *  relying just on `n.type.name === "text"` would skip many blank lines
     *  and will often result in the [[Insert text here]] placeholder 
     *  NOT being inserted, which is really bad for AI prompts
     * 
     *  Node type Hierarchy:
     *  - `section` or `freeText`
     *    - `title`
     *      - `text` -This may not exist, typical state for blank lines
     *  */
    const titleNode = findChildren(n.node, n => n.type.name === "title")
    if (!titleNode) continue
    const textNode = findChildren(n.node, n => n.type.name === "text")
    const hasTextNode = textNode && textNode.length > 0
    const rawPos = n.pos + titleNode[0].pos + (hasTextNode ? textNode[0].pos : 0)
    const resolvedPos = doc.resolve(rawPos)
    const lineText = titleNode.length > 0 ? DocumentSchema.getPlainTextFromTitleNode(titleNode[0].node) : ""

    let linePosition: "before" | "after" | "current"
    const start = resolvedPos.start()
    const end = resolvedPos.end()
    const depth = resolvedPos.depth

    if (start < insertPosition && end > insertPosition) {
      linePosition = "current"
    } else if (start < insertPosition) {
      linePosition = "before"
    } else {
      linePosition = "after"
    }

    const line: LineInfo = {
      rawPos,
      start,
      end,
      rangeLength: end - start,
      depth,
      linePosition,
      lineText,
      markdown: convertNodeToMarkdown(n.node, depth, lineText)
    }

    // if (end < 100) {
    //   console.log("getDocAsMarkdown() line = ", { titleNode, textNode, line, lineText: lineText.substring(0, 10), insertPosition })
    // }

    lines.push(line)

  }

  /** There potentially can be more than one "current line" due to nesting
 *    So we take extra care to select the line with the most specific range. */
  const potentialCurrentLines = lines
    .filter(l => l.linePosition === "current")
    .sort((a, b) => a.rangeLength - b.rangeLength)

  // console.log("getDocAsMarkdown() potentialCurrentLines = ", { potentialCurrentLines })

  let currentLine = ""
  let currentLineWithPlaceholder = ""
  if (potentialCurrentLines.length > 0) {
    const currentLineInfo = potentialCurrentLines[0]
    currentLine = currentLineInfo.markdown
    currentLineWithPlaceholder = currentLineInfo.markdown + insertionPointPlaceholder
    currentLineInfo.markdown = currentLineWithPlaceholder
  }

  // Convert the lines to markdown
  const markdownLinesString = lines.map(l => l.markdown).join("\n")

  // console.info("getDocAsMarkdown() markdownLines = ", markdownLinesString)

  return {
    allLines: markdownLinesString,
    currentLine,
    currentLineWithPlaceholder,
  }
}

const convertNodeToMarkdown = (node: Node, depth: number, lineText: string): string => {

  /** since sometimes a title node does not have a child text node, we might need to minus 1 to make the indents correct */
  const indentDepth = depth + (depth % 2 === 1 ? -1 : 0)
  const indent = indentDepth * 2 // Current doc schema nests by 2. Markdown likes 4 spaces per tab.
  const spaces = " ".repeat(indent)

  if (node.type.name === "section") {

    const headingLevel = depth / 2 + 1
    const hashes = "#".repeat(headingLevel)
    return spaces + hashes + " " + lineText

  } else if (node.type.name === "freeText") {
    return spaces + lineText
  }

  return ""
}


export type BodyAsStringParts = {
  allLines: string;
  currentLine: string;
  currentLineWithPlaceholder: string;
};


export const insertionPointPlaceholder = "[[SUGGEST TEXT FOR RIGHT HERE]]"


type LineInfo = {
  rawPos: number,
  start: number,
  end: number,
  rangeLength: number,
  depth: number,
  linePosition: "before" | "after" | "current",
  lineText: string,
  markdown: string,
}
