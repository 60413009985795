import { useCallback, useEffect, useState } from "react"

const localStorageKey = "users_recent_documents"
const accessRecordMaxSize = 10

export interface DocumentAccessRecord {
  id: string,
  timestamp: string
}

export const useRecentDocumentsProvider = () => {
  const [recentDocumentHistory, setRecentDocumentHistory] = useState<DocumentAccessRecord[]>([])

  useEffect(()=>{
    loadRecentDocuments()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const loadRecentDocuments = useCallback(()=>{
    const recentDocs: DocumentAccessRecord[] = []
    const rawLocalStorageString = localStorage.getItem(localStorageKey)
    if(rawLocalStorageString) { 
      const accessRecords = JSON.parse(rawLocalStorageString) as DocumentAccessRecord[]
      recentDocs.push(...accessRecords)
    }
    setRecentDocumentHistory(recentDocs)
  },[])

  const addRecentDocument = useCallback((id:string)=>{
    const accessDate = new Date()
    const newDocumentAccess = {
      id,
      timestamp: accessDate.toISOString()
    }

    let newAccessRecords = [newDocumentAccess]

    const rawLocalStorageString = localStorage.getItem(localStorageKey)
    if(rawLocalStorageString) {
      const accessRecords = JSON.parse(rawLocalStorageString) as DocumentAccessRecord[]
      newAccessRecords = newAccessRecords.concat(accessRecords.filter((ar)=> ar.id !== id)).slice(0,accessRecordMaxSize)
    }
    localStorage.setItem(localStorageKey, JSON.stringify(newAccessRecords))
    loadRecentDocuments()
  },[loadRecentDocuments])

  return {
    recentDocumentHistory,
    addRecentDocument
  }

}

export type RecentDocumentsProvider = ReturnType<typeof useRecentDocumentsProvider>