import { useState, useEffect } from "react";
import { useColorModeValue } from "@chakra-ui/react";
import { inviteUserToOrganization } from "./network";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  Alert,
  AlertIcon,
  Text,
} from "@chakra-ui/react";
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary";
import { VerdiIconAdd } from "../../../components/icons/VerdiIcons";

export function OrganizationAddUserModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userEmail, setUserEmail] = useState<string>("");
  const [userInvited, setUserInvited] = useState<boolean>(false);
  const [validationToken, setValidationToken] = useState<string>("");
  const [urlCopied, setUrlCopied] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const modalBackground = useColorModeValue("#FFF", "#181818");

  const inviteUser = async () => {
    try {
      const response = await inviteUserToOrganization(userEmail);
      if (response) {
        setUserInvited(true);
        if (response.error) {
          setError(response.error);
          return;
        }
        setValidationToken(response.token);
      }
    } catch (error: any) {
      setError(error.message);
    }
    return;
  };

  const copyValidationUrl = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/organization/join/${validationToken}`
    );
    setUrlCopied(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (urlCopied) setUrlCopied(false);
    }, 1000);

    return () => clearInterval(interval);
  }, [urlCopied]);

  const isLightMode = useColorModeValue(true, false);


  return (
    <>
      <ButtonPrimary
        label="Invite a teammate"
        onClick={onOpen}
        leftIcon={<VerdiIconAdd />}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setUserEmail("");
          setUserInvited(false);
          setValidationToken("");
          setError("");
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent background={modalBackground}>
          <ModalHeader>Invite a teammate to Verdi</ModalHeader>
          <ModalCloseButton />
          {userInvited ? (
            <>
              <ModalBody>
                <Stack>
                  {error ? (
                    <Alert status="error">{error}</Alert>
                  ) : (
                    <>
                      <Alert status="success">
                        <AlertIcon />
                        An email was sent to {userEmail} inviting them to your
                        organization.
                      </Alert>
                      <Button onClick={copyValidationUrl}>
                        {urlCopied ? (
                          <Text>Url Copied</Text>
                        ) : (
                          <Text>Copy Invite Link</Text>
                        )}
                      </Button>
                    </>
                  )}
                </Stack>
              </ModalBody>
              <ModalFooter>
                {/* <Button
                  mr={3}
                  onClick={() => {
                    setUserEmail("");
                    setUserInvited(false);
                  }}
                  variant="ghost"
                >
                  Add Another User
                </Button> */}
                <Button
                  colorScheme="verdiButtonSecondary"
                  color={isLightMode ? "black" : "white"}
                  onClick={() => {
                    window.location.reload()
                    // setUserInvited(false);
                    // setUserEmail("");
                    // onClose();
                  }}
                >
                  Close
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                <Stack spacing={3}>
                  <p>
                    When this person accepts the invite, they can start collaborating with your team.
                  </p>
                  <Input
                    value={userEmail}
                    onChange={(event) => setUserEmail(event.target.value)}
                    placeholder="User Email"
                  />
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="verdiButtonSecondary"
                  color={isLightMode ? "black" : "white"}
                  marginRight={3}
                  onClick={onClose}
                  variant="solid">
                  Cancel
                </Button>
                <Button
                  colorScheme="verdiButtonPrimary"
                  color="white"
                  onClick={() => {
                    inviteUser();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
