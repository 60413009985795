import { useMemo } from "react"
import { kanbanColumnOptionsForOpportunities } from "../../../components/kanban/KanbanColumnOptions"
import { SelectCommandDropdown } from "../../../components/commands/miniCommandMenu/SelectCommandDropdown"
import { VerdiCommand } from "../../document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { updateOpportunityCommandDefinition } from "../../../components/commands/commandDefinitions/opportunity/updateOpportunity"


type Props = {
  opportunityId?: string
  status: number
  onItemSelected: (command: VerdiCommand) => void
  landmarkId?: string,
}
export const OpportunityStatusEditor = ({
  opportunityId,
  status,
  onItemSelected,
  landmarkId,
}: Props) => {


  const statusText = useMemo(() => {
    return kanbanColumnOptionsForOpportunities.find(o => o.id === status)?.title || "-"
  }, [status, kanbanColumnOptionsForOpportunities])


  const commands = useMemo<VerdiCommand[]>(() => {

    if (!opportunityId) {
      return kanbanColumnOptionsForOpportunities.map(f => {
        const command: VerdiCommand = {
          id: f.id.toString(),
          name: f.title,
          searchName: f.title.toLowerCase(),
        }
        return command
      })
    }

    return kanbanColumnOptionsForOpportunities.map(f => {
      const command = updateOpportunityCommandDefinition.buildMenuItem({
        opportunityId,
        newStatusText: f.title,
        commandName: f.title,
        fieldsToUpdate: {},
      })
      return command
    })

  }, [kanbanColumnOptionsForOpportunities])


  return (
    <div>
      <SelectCommandDropdown
        data-landmark={landmarkId}
        isDisabled={false}
        size="xs"
        label={opportunityId ? statusText : `Status: ${statusText}`}
        commandOptions={commands}
        onItemSelected={onItemSelected}
        searchPlaceholderText="choose a phase"
        maxWidth={200}
      />
    </div>
  )
}
