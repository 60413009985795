import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { writeContentToDocBody } from "./writeToDocBody"
import { addMarkAsAiEdit, clearAllAiEditMarks } from "./inlineDocSuggestions/docBodyMarkCreate"


const cmdType = GlobalCommandType.replacePlaceholderWith

/** Writes raw text, replacing the current placeholder node in the current Prosemirror document  */
export const replacePlaceholderWithCommandDefinition: GlobalCommandDefinition<ReplacePlaceholderWithCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ReplacePlaceholderWithCommandArgs) => {
    const title = args.commandTitle
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ReplacePlaceholderWithCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('replacePlaceholderWith: No editor found in servicesForCurrentDoc')
      return
    }

    writeContentToDocBody({
      editor,
      contentToInsert: args.textToWrite,
      insertPosition: args.insertPosition,
      shouldMoveCaretToNextNode: args.shouldMoveCursorToNextNode,
      shouldAutoOpenCommandMenu: args.shouldAutoOpenCommandMenu,
      shouldDeleteCurrentSelection: true,
    })

    clearAllAiEditMarks(editor)
    addMarkAsAiEdit(editor, args.textToWrite)

    onProcessingComplete(true)
  },
  triggerCommand: (args: ReplacePlaceholderWithCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ReplacePlaceholderWithCommandArgs = {
  commandTitle: string
  textToWrite: string
  insertPosition: InsertPosition,
  shouldMoveCursorToNextNode?: boolean,
  shouldAutoOpenCommandMenu?: boolean,
}
