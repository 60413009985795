import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


interface PlansState {
  currentOrgPlanHasLoaded: boolean
  currentOrgPlan?: Data.OrganizationPlan.OrganizationPlanModel
  paymentDetailsHaveLoaded: boolean
  paymentLink?: Data.StripePaymentLink.StripePaymentLinkModel
  stripeSubscriptionRaw?: Data.StripeShared.StripeSubscriptionRaw
  planOptions: Data.OrganizationPlanOption.OrganizationPlanOptionModel[]
}

const initialState: PlansState = {
  currentOrgPlanHasLoaded: false,
  paymentDetailsHaveLoaded: false,
  planOptions: [],
}

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setCurrentOrgPlan: (state, action: PayloadAction<Data.OrganizationPlan.OrganizationPlanModel>) => {
      state.currentOrgPlan = action.payload
      state.currentOrgPlanHasLoaded = true
    },
    setPaymentLink: (state, action: PayloadAction<Data.StripePaymentLink.StripePaymentLinkModel>) => {
      state.paymentLink = action.payload
    },
    setStripeSubscriptionRaw: (state, action: PayloadAction<Data.StripeShared.StripeSubscriptionRaw>) => {
      state.stripeSubscriptionRaw = action.payload
    },
    setPlanOptions: (state, action: PayloadAction<Data.OrganizationPlanOption.OrganizationPlanOptionModel[]>) => {
      state.planOptions = action.payload
    },
    setPaymentDetailsHaveLoaded: (state, action: PayloadAction<boolean>) => {
      state.paymentDetailsHaveLoaded = action.payload
    },
  },
  selectors: {
    getCurrentOrgPlan: (state) => state.currentOrgPlan,
    getPaymentLink: (state) => state.paymentLink,
    getStripeSubscriptionRaw: (state) => state.stripeSubscriptionRaw,
    getCurrentOrgPlanHasLoaded: (state) => state.currentOrgPlanHasLoaded,
    getPlanOptions: (state) => state.planOptions,
    getPaymentDetailsHaveLoaded: (state) => state.paymentDetailsHaveLoaded,
  }
})

export const {
  setCurrentOrgPlan,
  setPaymentLink,
  setPlanOptions,
  setStripeSubscriptionRaw,
  setPaymentDetailsHaveLoaded,
} = plansSlice.actions
export const {
  getCurrentOrgPlan,
  getCurrentOrgPlanHasLoaded,
  getPaymentLink,
  getStripeSubscriptionRaw,
  getPlanOptions,
  getPaymentDetailsHaveLoaded,
} = plansSlice.selectors


export default plansSlice.reducer
