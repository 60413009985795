import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


interface IDocRelationsState {
  items: Data.DocumentRelationDto[]
}

/** IMPORTANT: Currently the state of docRelations is managed by graphQL. 
 * 
 * Look at useDocumentRelationsProvider.tsx for more info, as this Slice is incomplete. */
const initialState: IDocRelationsState = {
  items: [],
}

export const docRelationsSlice = createSlice({
  name: 'docRelations',
  initialState,
  reducers: {
    setAll: (state, action: PayloadAction<Data.DocumentRelationDto[]>) => {
      state.items = action.payload
    },
    add: (state, action: PayloadAction<Data.DocumentRelationDto>) => {
      state.items.push(action.payload)
    },
    remove: (state, action: PayloadAction<{ id: string }>) => {
      state.items = state.items.filter((i) => i.id !== action.payload.id)
    },
  },
  selectors: {
    getAll: (state) => state.items,
    getAllForDocId: (state, docId: string) => {
      return state.items.filter(i => i.fromDocId === docId || i.toDocId === docId)
    },
  }
})

export const DocRelationsState = {
  ...docRelationsSlice.actions,
  ...docRelationsSlice.selectors,
}

// export const selectCount = (state: RootState) => state.docRelations.items.length

export default docRelationsSlice.reducer


/** Returns a flat array of docRelations that connect to the given docId, up to a certain level deep.
 * 
 *  Similar to MenuStructureManager.hydrateDocumentMenuStructure. */
export const getRelationsToDocId = (
  docId: string,
  allDocRelations: Data.DocumentRelationDto[],
  levelsDeep: number = 3,
) => {
  const relations = getRelatedTo(docId, allDocRelations, levelsDeep)
  return relations
}

/** Recursively gets docRelations in the conceptual direction of "child" nodes, aka things found within an opportunity or doc. */
const getRelatedTo = (docId: string, allDocRelations: Data.DocumentRelationDto[], levelsDeep: number): Data.DocumentRelationDto[] => {
  console.log("levelsDeep", levelsDeep)
  const relations = allDocRelations.filter(i => i.toDocId === docId)
  if (levelsDeep > 0) {
    const nextLevel = relations.flatMap(i => {
      return getRelatedTo(i.toDocId, allDocRelations, levelsDeep - 1)
    })
    return relations.concat(nextLevel)
  }
  return relations
}


