import { css } from "@emotion/react"
import { InputTextWithState } from "../../../components/forms/InputTextWithState"
import { IconButtonRemove } from "../../../components/buttons/IconButtonRemove"
import { useColorModeValue } from "@chakra-ui/react"
import { mediaQuerySmallWidth } from "../../../components/pageLayout/styleHelpers"


type Props = {
  title: string, // Key
  description: string, // Value
  placeholderTitle?: string,
  placeholderDescription?: string,
  remove?: () => void,
  updateTitle: (newValue: string) => void,
  updateDescription: (newValue: string) => void,
  hideButton?: boolean
}
/** Similar to an `EditableListItem` but adds a description and styling */
export const EditableKeyValuePair = ({
  title,
  description,
  placeholderTitle,
  placeholderDescription,
  remove = ()=>{},
  updateTitle,
  updateDescription,
  hideButton
}: Props) => {


  const isLightMode = useColorModeValue(true, false)

  return (
    <div
      css={css`
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    & button {
      opacity: 0;
    }
    &:hover button {
      opacity: 1;
    }
    
  `}>
      <div css={css`
      display:flex;
      flex-direction: row;
      & > textarea {
        border-color: transparent;
      }
      @media (${mediaQuerySmallWidth}) {
        flex-direction: column;
      }
    `}>
        <InputTextWithState
          css={css`
          width: 115px;
          margin-right: 5px;
          text-transform: uppercase;
          font-size: 0.8rem;
          line-height: 1.1;
          text-align: right;
          padding: 8px;
          @media (${mediaQuerySmallWidth}) {
            text-align: left;
            margin-bottom: 8px;
          }
          `}
          backgroundColor={isLightMode ? "white" : "black"}
          value={title}
          onValueUpdated={(value) => updateTitle(value)}
          placeholder={placeholderTitle}
        />

        <InputTextWithState
          css={css`
            font-size: 0.8rem;
            line-height: 1.1;
          `}
          backgroundColor={isLightMode ? "white" : "black"}
          value={description || ""}
          onValueUpdated={(value) => updateDescription(value)}
          placeholder={placeholderDescription}
        />
      </div>
      {!hideButton && <IconButtonRemove
        aria-label="Remove"
        onClick={() => remove()}
      />}

    </div>
  )
}
