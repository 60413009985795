import { useEffect, useMemo } from "react"
import { useComboBoxController } from "../useComboBoxController"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { showMoreOptionsCommandDefinition } from "../../../commandDefinitions/utils/showMoreOptions"
import { addOpportunityShowOptionsCommandDefinition } from "../../../commandDefinitions/opportunity/addOpportunityShowOptions"
import { addDocShowOptionsCommandDefinition } from "../../../commandDefinitions/document/addDocShowOptions"
import { ComboBox } from "../ComboBox"
import { Input } from "@chakra-ui/react"


/** Example of how to use an external input with the "ComboBox" component, viewable on the Styles page */
export const ComboBoxExternalInputExample = () => {

  const comboBoxState = useComboBoxController({
    onClose: () => { console.log("did close") },
    onRunCommand: (command) => { console.log("Should now run this command (ComboBoxExternalInputExample)", { command }) },
    mode: "search-and-filter",
  })

  const commands: VerdiCommand[] = useMemo(() => {
    return [
      showMoreOptionsCommandDefinition.buildMenuItem({
        commands: [],
      }),
      addOpportunityShowOptionsCommandDefinition.buildMenuItem({}),
      addDocShowOptionsCommandDefinition.buildMenuItem({
        parentDocId: "asdf",
      }),
    ]
  }, [])


  useEffect(() => {
    comboBoxState.setCommandsInGroups([{
      title: "Some group title",
      commands
    }])
    // eslint-disable-next-line
  }, [])


  return (
    <div>
      <h3>Combo box with external input</h3>

      <Input
        value={comboBoxState.searchText}
        onChange={(evt) => comboBoxState.setSearchText(evt.target.value)}
        onKeyDown={(evt) => comboBoxState.handleOnKeyDown(evt as any)}
      />

      <ComboBox
        comboBoxState={comboBoxState}
        hasExternalInput={true}
      />
    </div>
  )
}
