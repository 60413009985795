import { KeyValuePair } from "@verdi/shared-constants"
import { DocBodyMarkCreateCommandArgs } from "../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkCreate"
import { markdownToDocOutline } from "./markdownToDocOutline"
import { MarkSuggestion } from "../../state/suggestions/suggestionsForMarks"

export type CleanupAiOutputOptions = {
  keepEmptyLines?: boolean
  keepDuplicates?: boolean
}

export const cleanupAiOutputAsStrings = (rawString: string, options?: CleanupAiOutputOptions) => {
  const lines = rawString.split("\n").filter(a => a.length > 0) ?? []
  let cleanSuggestions = lines.map(cleanupAiOutputItem)
  if (!options?.keepEmptyLines) {
    cleanSuggestions = cleanSuggestions.filter(a => a.length > 0)
  }
  if (!options?.keepDuplicates) {
    cleanSuggestions = Array.from(new Set(cleanSuggestions))
  }
  return cleanSuggestions
}

export const cleanupAiOutputAsKeyValuePairs = (rawString: string, fieldDelimiter: string) => {
  const rawLines = rawString.split("\n")
  const noEmptyLines = rawLines.filter((line) => line.trim().length > 0)
  const keyValuePairs = noEmptyLines.map((line) => {
    const [key, value] = line.split(fieldDelimiter)
    return { key: key.trim(), value: value ? value.trim() : "" } as KeyValuePair
  })
  const noCrappyLines = keyValuePairs.filter((pair) => pair.key.length > 0 && pair.value.length > 0)
  if (noCrappyLines.length !== keyValuePairs.length) {
    console.warn("cleanupAiOutputAsKeyValuePairs: Some bad lines removed: ", keyValuePairs, noCrappyLines)
  }
  return noCrappyLines
}

/** Assumes the `rawString` arg is in a VERY BASIC markdown format */
export const cleanupAiOutputAsDocumentOutline = (rawString: string) => {
  return markdownToDocOutline(rawString)
}


/** Assumes each line is in the format of: 
 * 
 * `commandType|text to highlight|text to replace it with` */
export const cleanupAiOutputAsDocBodyMarkCreateCommandArgs = (rawString: string) => {
  const rawLines = rawString.split("\n")
  const noEmptyLines = rawLines.filter((line) => line.trim().length > 0)
  const toReturn: DocBodyMarkCreateCommandArgs[] = []
  for (const line of noEmptyLines) {
    const parts = line.split("|")
    if (parts.length < 3) {
      continue
    }

    const commandType = parts[0].trim()
    const textToHighlight = removeMarkdownHeadingCharsIfAny(parts[1].trim())
    const suggestionText = parts[2].trim()
    toReturn.push({
      markType: "AiSuggestion",
      markStrategy: "search",
      textToHighlight,
      attributes: {
        suggestionText,
        commands: [
          // { commandType: commandType as any, title: suggestionText } // TODO: Remove commands here
        ]
      },
    })
  }
  return toReturn
}

/** Assumes each line is in the format of: 
 * 
 * `summary of command|literal text to add to the document` */
export const cleanupAiOutputAsMarkSuggestion = (suggestionText: string, rawString: string) => {
  const rawLines = rawString.split("\n")
  const noEmptyLines = rawLines.filter((line) => line.trim().length > 0)
  const toReturn: MarkSuggestion = {
    suggestionText,
    suggestions: [],
  }
  for (const line of noEmptyLines) {
    const parts = line.split("|")

    if (parts.length < 2) {
      continue
    }

    const summaryForCommand = removeMarkdownHeadingCharsIfAny(parts[0].trim())
    const textToAdd = parts[1].trim()
    toReturn.suggestions?.push({
      behavior: "addChild",
      summaryForCommand,
      textToAdd,
    })
  }
  return toReturn
}

const removeMarkdownHeadingCharsIfAny = (line: string) => {
  return line.replace(/^#+\s*/, "")
}

/** Removes any of these "[ ]", "[x]", "[X]" */
export const removeCheckboxChars = (line: string) => {
  return line.replace(/^\[ \]|\[x\]|\[X\]/, "").trim()
}

/** Remove bullets, numbered list characters and more. */
export const cleanupAiOutputItem = (rawString: string) => {

  // Regex can be harder to follow. Check out the test cases for a better understanding.
  return rawString
    .replace(/\\?"/g, "") // Remove all occurrences of \"
    .replace(/^(\d+\.\s+|[-\s*•#]+|\s+:|$)|(:\s*$)/gm, "") // Remove bullets, numbered list characters, hashes, and colons
    .trim(); // Trim leading and trailing whitespace

}

export const cleanupAiOutputItemsShortestFirst = (rawResults: string[]) => {
  const cleanResults = rawResults.map(cleanupAiOutputItem)
  const lowercaseResults = cleanResults.map(r => r.toLowerCase())
  // Filter out any duplicates, ignoring case, but returning the original case
  const uniqueResults = [...new Set(lowercaseResults)].map(r => cleanResults[lowercaseResults.indexOf(r)])
  const shortestFirst = uniqueResults.sort((a, b) => a.length - b.length)
  return shortestFirst
}
