import { OpenAi, OpenAiModelType } from "@verdi/shared-constants"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsDocumentOutline } from "../../utils/cleanupAiOutput"


/** Generates a full document outline */
export const makeAutoDraftFullDocAiCall = async (getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>) => {

  const allContext: AllContextForAi = await getContextForAi()

  const docContext = allContext.currentDoc
  if (!docContext) {
    console.warn("makeAutoDraftFullDocAiCall: No currentDoc context found")
    return []
  }

  // TODO: Get the framework for the document, and use it to draft the document
  // allContext.currentDoc?.framework

  const prompt = autoDraftFullDocPrompt()

  console.log("makeAutoDraftFullDocAiCall", { prompt })

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    openAIParams: {
      model: OpenAi.Gpt_4_Latest,
    }
  })

  const rawText = response?.aiResponseJson.content as string || ""
  const docOutline = cleanupAiOutputAsDocumentOutline(rawText)
  console.log("makeAutoDraftFullDocAiCall", { rawText, docOutline })

  return docOutline
}


const autoDraftFullDocPrompt = () => {

  const prompt = `
INSTRUCTIONS: 
Write a concise document based on the opportunity, the current document, the current document's framework context given and inferred intent of the current document. 
Include all the information you'd expect to see in this type of document. 
DO NOT include a title for the document in your response.
If you want to include a placeholder in the document, represent the placeholder with double square brackets around the text with a space before and after the text [[ like this ]].

RESPONSE FORMAT RULES:
Return your response as plain text, with the following rules:
  for each line:
    If it is a section heading, start the line with a #.
    If it is text that should be nested within a section, start the line with a -

EXAMPLE OUTPUT 1:
Verdi aims to enhance project workflow optimization, improve user experience, and expand market reach through strategic alliances by 
integrating its advanced AI coaching technology with future partners Jira and Trello.

# Desired Outcomes
- Strategic Partnerships: To develop alliances with [[ Jira, Trello, Aha ]], and other potential partners to broaden market reach.
- Enhanced Project Management: Utilize Verdi's AI to improve task management and workflow capabilities within [[ Jira and Trello ]].
- Market Expansion: Increase market share and customer acquisition through these strategic alliances.

# Target Audience
- Project managers and teams using Jira and Trello.
- Organizations seeking enhanced efficiency in project management.

In conclusion, we are trying to be successful by doing these things.

EXAMPLE OUTPUT 2

# Desired Outcomes
- Strategic Partnerships: To develop alliances with Jira, Trello, Aha, and other potential partners to broaden market reach.
- Enhanced Project Management: Utilize Verdi's AI to improve task management and workflow capabilities within Jira and Trello.
- Market Expansion: Increase market share and customer acquisition through these strategic alliances.

# Target Audience
- Project managers and teams using Jira and Trello.
- Organizations seeking enhanced efficiency in project management.

# Partnership Details
- Partners: Jira, Trello, Aha, and others.
- Benefits: Mutual market expansion, joint marketing, and co-branding initiatives.
- Terms: Collaborative promotion of integrated AI coaching, resource sharing, and market development.
- Success Metrics: Measured by increased market share and customer acquisition.

# Features and Benefits
- Personalized guidance and recommendations for project workflow optimization.
- Integration with Jira's task management and workflow capabilities.
- Simplified project mapping from start to finish, with AI-driven recommendations enhancing efficiency and decision-making.

# Technical Specifications
- Custom AI algorithms tailored for project management optimization.
- API-based integration with Jira and Trello platforms.

# Risks and Mitigations
- Ensuring seamless integration with existing features of Jira and Trello.
- User acceptance testing and iterative feedback loops to refine the integration.

`
  return prompt
}

// const autoDraftFullDocForOpportunityPromptNEW = () => {

//   const prompt = `
// INSTRUCTIONS: 
// Write a very concise opportunity brief for the opportunity. 
// Include all the information you'd expect to see in an opportunity, use hypothesized information where needed. 
// You can also use the "Questions about this opportunity that we have already answered" context to fill in information where relevant.
// DO NOT include a title for the opportunity brief in your response.

// EXAMPLE opportunity brief starts after this colon:
// # Opportunity
// Verdi aims to enhance project workflow optimization, improve user experience, and expand market reach through strategic alliances by 
// integrating its advanced AI coaching technology with future partners Jira and Trello.

// `
//   return prompt
// }
