import { useCallback, useRef, useState } from "react";
import { WebsocketEvents } from "@verdi/shared-constants";

function splitOnFirst(str: string, delimiter: string) {
  const index = str.indexOf(delimiter);
  if (index === -1) {
    return [str];
  } else {
    const firstChunk = str.substring(0, index);
    const secondChunk = str.replace(firstChunk + delimiter, "");
    return [firstChunk, secondChunk];
  }
}

function parseJSONIfValid(text: string) {
  try {
    return JSON.parse(text);
  } catch (e) {
    return false;
  }
}

type Props = {
  onLineReceived: (parsedJsonLine: JSON) => void;
  onEntireResponseReceived?: (entireJsonResponse: string) => void;
};
/** Returns valid JSON lines from a streaming AI request */
export const useAiJsonStreamer = ({
  onLineReceived,
  onEntireResponseReceived,
}: Props) => {
  const unfinishedJsonLine = useRef("");
  const entireResponse = useRef("");
  const [inFlight, setInFlight] = useState(false);

  /** Detects valid lines, removes empty lines, and returns any partial lines */
  const processLines = useCallback(() => {
    const lines = unfinishedJsonLine.current.split("\n");
    const partialLines: string[] = [];
    console.log("processLines: ", unfinishedJsonLine.current);

    for (var i = 0; i < lines.length; i++) {
      const currentLine = lines[i];
      const validLine = parseJSONIfValid(currentLine);
      if (validLine) {
        onLineReceived(validLine);
        continue;
      }
      const isEmptyLine = currentLine.length === 0;
      const isNotLastLine = i < lines.length - 1;
      if (isEmptyLine && isNotLastLine) {
        continue; // let this empty line drop out
      }
      // at this point it's a partial line, add it back in for review next time this is called
      partialLines.push(currentLine);
    }

    return partialLines.join("\n");
  }, [unfinishedJsonLine, onLineReceived]);

  const processAiRequestChunk = useCallback(
    async (incomingChunk: string) => {
      entireResponse.current += incomingChunk;

      const combinedJson = unfinishedJsonLine.current + incomingChunk;
      const hasMultipleLines = combinedJson.indexOf("\n") > -1;
      if (hasMultipleLines) {
        unfinishedJsonLine.current = processLines();
      } else {
        unfinishedJsonLine.current = combinedJson;
      }
    },
    [unfinishedJsonLine, entireResponse]
  );

  const processAiRequestComplete = useCallback(async () => {
    console.log("streamIsComplete()");

    processLines(); // Pick up any remaining lines

    console.log(`streamIsComplete:
    unfinishedJsonLine.current: ${unfinishedJsonLine.current}
    entireResponse.current: ${entireResponse.current} `);

    onEntireResponseReceived?.(entireResponse.current);

    unfinishedJsonLine.current = "";
    entireResponse.current = "";
  }, []);

  return {
    processAiRequestChunk,
    processAiRequestComplete,
    inFlight,
  };
};
