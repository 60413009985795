import { useMemo } from "react"
import { useAppSelector } from "../../../../state/storeHooks"
import { MarkSuggestion, SuggestionsForMarksState } from "../../../../state/suggestions/suggestionsForMarks"
import { getCmdsForMark } from "./getCmdsForMark"


type Props = {
  hasCurrentMark: boolean
  currentMarkSuggestionText: string | undefined
}

export const useMarkCommands = ({
  hasCurrentMark,
  currentMarkSuggestionText,
}: Props) => {

  // const currentMarkSuggestion = useAppSelector(SuggestionsForMarksState.getCurrent)
  const allMarkSuggestions: MarkSuggestion[] = useAppSelector(SuggestionsForMarksState.getAll)
  const currentMarkSuggestion = allMarkSuggestions.find(s => s.suggestionText === currentMarkSuggestionText)
  const currentMarkCommands = useMemo(() => {
    return getCmdsForMark(hasCurrentMark, currentMarkSuggestion, currentMarkSuggestionText) || []
  }, [currentMarkSuggestion, currentMarkSuggestionText])


  return {
    currentMarkCommands
  }
}
