import { css } from "@emotion/react"
import { DocumentIdea } from "../../state/docIdeasSlice"
import { AutoResizingInputText } from "../../components/forms/AutoResizingInputText"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react"
import { VerdiIconArrowBack, VerdiIconCheck, VerdiIconClose } from "../../components/icons/VerdiIcons"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import LoadingSpinner from "../../components/LoadingSpinner"
import { CommandStub } from "../document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/buildCommandsFromSuggestions"
import { ComboBox } from "../../components/commands/miniCommandMenu/comboBox/ComboBox"
import { useComboBoxController } from "../../components/commands/miniCommandMenu/comboBox/useComboBoxController"
import { useEffect, useRef, useState } from "react"
import { VerdiCommand, VerdiCommandGroup } from "../document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { ButtonTertiary } from "../../components/buttons/ButtonTertiary"
import { Data } from "@verdi/shared-constants"
import { useColor } from "../../utility-hooks/useColor"


type Props = {
  ideaData: DocumentIdea,
  isExpanded: boolean,
  isFadedToBackground: boolean,
  onTitleClick: () => void,
  onAnswerUpdate: (newAnswer: string) => void,
  onCommandStubClick: (commandStub: CommandStub<any>) => void,
  onApplyToDocumentClick: () => void,
  onDismiss: () => void
}
export const IdeaCard = ({
  ideaData,
  isExpanded,
  isFadedToBackground,
  onTitleClick,
  onAnswerUpdate,
  onCommandStubClick,
  onApplyToDocumentClick,
  onDismiss,
}: Props) => {


  const [isShowingMore, setIsShowingMore] = useState(false)

  const comboBoxController = useComboBoxController({
    mode: "show-all-no-filter",
    onRunCommand: (command) => onAnswerUpdate(command.name || ""),
    onClose: (wasDismissed) => { },
  })

  useEffect(() => {
    // const groups: VerdiCommandGroup[] = []
    if (!ideaData.commands) {
      comboBoxController.setCommandsInGroups([])
      return
    }
    const commands: VerdiCommand[] = ideaData.commands.map(command => {
      const title = command.buttonText
      return {
        name: title,
        searchName: title?.toLowerCase() || "",
      } as VerdiCommand
    })
    const group: VerdiCommandGroup = {
      title: "",
      hideTitle: true,
      commands,
    }
    comboBoxController.setCommandsInGroups([group])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideaData.commands, isShowingMore])


  useEffect(() => {
    comboBoxController.setSearchText(ideaData.answer || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideaData.answer])

  const inputTextRef = useRef<HTMLTextAreaElement>(null)
  const backgroundColor = useColor("surfaceBgSecondary")


  return (
    <div>
      <Accordion allowMultiple
        index={[isExpanded ? 0 : -1]}
        css={css`
          border: 1px solid #99999933;
          border-radius: 10px;
          background-color: ${!ideaData.status ? `var(--nudge-highlight-bg-color)` : backgroundColor};
          color: ${ideaData.status === Data.DocumentIdeas.DocumentIdeaStatus.executed ? "green" : "inherit"};
          font-size: 0.9rem;
          line-height:1.2;
          ${isFadedToBackground ? css`
            opacity: 0.5;
            /* filter: blur(1px); */
          ` : css`
            opacity: 1;
            border: 1px solid #99999922;
            /* filter: blur(1px); */
          `}
          margin-bottom: ${isExpanded ? 8 : 0}px;
        `}
      >
        <AccordionItem
          border="none">
          <h2 css={css`
                display: flex;
                direction: row;
              `}>
            <AccordionButton
              onClick={() => {
                onTitleClick()
                setTimeout(() => {
                  inputTextRef.current?.focus?.()
                }, 100) // To let animation finish
              }}
              css={css`
                padding: 6px 4px;
                display: flex;
                direction: row;
                justify-content: start;
                align-items: start;
                text-align: left;
                font-size: 0.9rem;
              `}
            >


              {ideaData.isApplying ?
                <LoadingSpinner
                  size="xs"
                  marginRight="2px"
                />
                :
                <>
                  {ideaData.status === Data.DocumentIdeas.DocumentIdeaStatus.executed ?
                    <VerdiIconCheck
                      margin="2px 2px 0 1px"
                    />
                    :
                    <AccordionIcon opacity={isExpanded ? 0.5 : 1} />
                  }
                </>
              }

              <span css={css`
                opacity: ${isExpanded && ideaData.title ? 0.5 : 1};
                line-height: 1.2;
              `}>
                {ideaData.title || ideaData.question}
              </span>

            </AccordionButton>
            <IconButtonTertiary
              aria-label="Dismiss"
              title="Dismiss"
              size="xs"
              icon={<VerdiIconClose />}
              onClick={onDismiss}
              opacity={isExpanded ? 0.5 : 1}
            />
          </h2>
          <AccordionPanel
            css={css`
            padding: 0 8px 4px;
          `}>

            <div>

              {Boolean(ideaData.title) &&
                <div>
                  {ideaData.question}
                </div>
              }

              <div css={css`
                display: flex;
                justify-content: space-between;
              `}>
                <div>
                  {isShowingMore ?
                    <ButtonSecondary
                      size="xs"
                      label="Add to document"
                      leftIcon={<VerdiIconArrowBack />}
                      onClick={() => onApplyToDocumentClick()}
                      isLoading={ideaData.isApplying}
                      marginTop={2}
                      marginBottom={1}
                    />
                    :
                    <div>
                      <ButtonPrimary
                        size="xs"
                        label="Add to document"
                        leftIcon={<VerdiIconArrowBack />}
                        onClick={() => onApplyToDocumentClick()}
                        isLoading={ideaData.isApplying}
                        marginTop={2}
                        marginBottom={1}
                      />
                      {/* Add paragraph | Add heading | ... */}
                    </div>
                  }
                </div>
                <ButtonTertiary
                  size="xs"
                  label={isShowingMore ? "hide ..." : "Options ..."}
                  opacity={0.5}
                  onClick={() => setIsShowingMore(!isShowingMore)}
                  marginTop={2}
                  marginBottom={1}
                />
              </div>

              {isShowingMore &&
                <div>

                  {/* <div css={css`
                  width: auto;
                  border: 1px solid blue;
                  position: absolute;
                  right: 4px;
                  top: 4px;
                  cursor: pointer;
                `}>
                  <IconButtonSecondary
                    aria-label="Apply to document"
                    title="Apply to document"
                    icon={<VerdiIconAiSuggestions />}
                    css={css`
 
                    border: 1px solid red;
                  `}
                    onClick={() => console.log("Apply to document")}
                  />
                </div> */}

                  <AutoResizingInputText
                    ref={inputTextRef}
                    size="sm"
                    placeholder="Answer here ..."
                    padding="4px 4px 4px 12px"
                    borderRadius={4}
                    marginTop={2}
                    value={ideaData.answer}
                    onKeyDown={(evt) => comboBoxController.handleOnKeyDown(evt as any)}
                    onChange={(evt) => {
                      onAnswerUpdate(evt.target.value)
                      comboBoxController.setSearchText(evt.target.value)
                    }}
                  />

                  {ideaData.isLoadingCommands &&
                    <div css={css`
                    padding: 32px 0;
                  `}>
                      <LoadingSpinner
                        label="finding answers..."
                        size="xs"
                      />
                    </div>
                  }


                  {/* {Boolean(ideaData.answer) &&
                    <ButtonPrimary
                      size="xs"
                      label="Apply to document"
                      leftIcon={<VerdiIconArrowBack />}
                      onClick={() => onApplyToDocumentClick()}
                      isLoading={ideaData.isApplying}
                      marginTop={2}
                      marginBottom={1}
                    />
                  } */}

                  {!ideaData.isLoadingCommands && !ideaData.answer &&
                    <div>
                      <ComboBox
                        comboBoxState={comboBoxController}
                        hasExternalInput={true}
                      />
                    </div>
                  }

                  {!ideaData.isLoadingCommands && ideaData.answer &&
                    <ButtonPrimary
                      size="xs"
                      label="Apply answer"
                      title="Apply answer to document"
                      leftIcon={<VerdiIconArrowBack />}
                      onClick={() => onApplyToDocumentClick()}
                      isLoading={ideaData.isApplying}
                      marginTop={2}
                      marginBottom={1}
                    />

                  }


                </div>

              }



            </div>

          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
