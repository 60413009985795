import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { dispatch } from "../../../../../state/store"
import { docBodyMarkClearAllCommandDefinition } from "./docBodyMarkClearAll"
import { AudiencePerspectivesState } from "../../../../../state/suggestions/AudiencePerspectivesSlice"
import { noneMenuItem } from "../../../miniCommandMenu/NoneMenuItem"


const cmdType = GlobalCommandType.reviewAsAudienceClearAll


/** Removes all inline suggestions and clears the current target audience. */
export const reviewAsAudienceClearAllCommandDefinition: GlobalCommandDefinition<ReviewAsAudienceClearAllCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ReviewAsAudienceClearAllCommandArgs) => {
    const title = noneMenuItem.name
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: noneMenuItem.searchName,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ReviewAsAudienceClearAllCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    dispatch(AudiencePerspectivesState.setCurrentAudience(undefined))

    docBodyMarkClearAllCommandDefinition.triggerCommand?.({})

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: ReviewAsAudienceClearAllCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ReviewAsAudienceClearAllCommandArgs = {

}
