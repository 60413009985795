import { useWebSocket } from "../../components/useWebSocket";
import { useCallback, useRef, useState } from "react";
import { WebsocketEvents } from "@verdi/shared-constants";
import { useAiJsonStreamer } from "./useAiJsonStreamer";
import { DocumentSubscription } from "../../components/documentSubscriptions/useDocumentSubscription";
import { DocumentContextForAi } from "../coach/AiCoachPrompts";
import { InsertPosition } from "../documents/InsertPosition";
import { OpenAi } from "@verdi/shared-constants";
import { DocAiEditWithAiPromptIdOptions } from "../coach/useAiCoach";
import { getLocalIsoDate } from "../../utility-hooks/jsDateHelpers";


const key = "userHasGeneratedAiAtLeastOnce";
export const userHasSummonedTheAiAtLeastOnce = () => {
  return Boolean(localStorage.getItem(key));
};
const recordThatUserHasSummonedTheAi = () => {
  localStorage.setItem(key, "they sure did!");
};

const apiDomain = process.env.REACT_APP_API_DOMAIN;


/**
   Streams responses from the AI via web sockets.

   If streaming is not required, consider using `AiApiRequest` instead
*/
export const useAiSubscription = () => {
  const [streamingIsInProgress, setStreamingIsInProgress] = useState(false);
  const onJsonStreamLineReceivedCallbackRef =
    useRef<(parsedJsonLine: JSON) => void>();
  const onJsonStreamCompleteCallbackRef =
    useRef<(entireJsonResponse: string) => void>();

  const onJsonStreamLineReceived = useCallback((parsedJsonLine: JSON) => {
    // console.log("onJsonStreamLineReceived: ", parsedJsonLine)
    onJsonStreamLineReceivedCallbackRef?.current?.(parsedJsonLine);
  }, []);

  const onJsonStreamComplete = useCallback((entireJsonResponse: string) => {
    console.log("onJsonStreamComplete ", entireJsonResponse);
    onJsonStreamCompleteCallbackRef?.current?.(entireJsonResponse);
    // Remove refs
    onJsonStreamLineReceivedCallbackRef.current = undefined;
    onJsonStreamCompleteCallbackRef.current = undefined;
  }, []);

  const { processAiRequestChunk, processAiRequestComplete } = useAiJsonStreamer(
    {
      onLineReceived: onJsonStreamLineReceived,
      onEntireResponseReceived: onJsonStreamComplete,
    }
  );

  type ClientEvent = {
    eventPayload?: WebsocketEvents.ClientEvents.ClientEventType;
  };

  const [relatedContextFromOtherDocs, setRelatedContextFromOtherDocs] =
    useState("");

  const processWebsocketMessage = useCallback(
    async (message: ClientEvent) => {
      switch (message.eventPayload?.eventName) {
        case "AI_REQUEST_CHUNK":
          await processAiRequestChunk(message.eventPayload.payload.body);
          break;

        case "AI_REQUEST_COMPLETE":
          processAiRequestComplete();

          console.log("COMPLETE() ");

          await setStreamingIsInProgress(false);
          break;

        case "GET_DYNAMIC_PROMPT_CONTEXT_COMPLETE":
          // console.log("Context came back: ", message.eventPayload.payload)
          setRelatedContextFromOtherDocs(
            message.eventPayload.payload.dynamicPromptContext
          );

          break;
      }
    },
    [processAiRequestChunk, processAiRequestComplete]
  );

  const { sendMessage } = useWebSocket({
    onMessage: processWebsocketMessage,
  });

  const requestAiJsonStream = useCallback(
    async (
      prompt: string,
      callOnJsonStreamLineReceived?: (parsedJsonLine: JSON) => void,
      callOnJsonStreamComplete?: (entireJsonResponse: string) => void,
      openAIParams?: OpenAi.OpenAIParamsType
    ) => {
      if (callOnJsonStreamLineReceived) {
        onJsonStreamLineReceivedCallbackRef.current =
          callOnJsonStreamLineReceived;
      }
      if (callOnJsonStreamComplete) {
        onJsonStreamCompleteCallbackRef.current = callOnJsonStreamComplete;
      }

      sendMessage({
        eventName: "REQUEST_AI_RESPONSE",
        args: {
          prompt,
          openAIParams,
          clientLocalDate: getLocalIsoDate(),
        },
      });
      setStreamingIsInProgress(true);
    },
    []
  );

  const requestRelatedContextFromOtherDocs = useCallback(
    async (documentId: string) => {
      sendMessage({
        eventName: "REQUEST_RELATED_CONTEXT_FROM_OTHER_DOCS",
        args: {
          documentId,
        },
      });
    },
    []
  );

  const requestDocumentAiEdit = useCallback(
    (
      fullPrompt: string,
      documentContext: DocumentContextForAi,
      insertAt: InsertPosition,
      sectionTitle?: string,
      isInline?: boolean,
      textToAddBefore?: string,
      openApiParams?: OpenAi.OpenAIParamsType
    ) => {
      let updatedInsertPosition;


      let insertRange;
      if (updatedInsertPosition) {

        insertRange = updatedInsertPosition;

      } else if (insertAt === InsertPosition.AsNextRootNode
        && documentContext.rangeAtNextRootNodeInsert) {

        insertRange = documentContext.rangeAtNextRootNodeInsert;

      } else if (insertAt === InsertPosition.endOfCurrentNode
        && documentContext.rangeAtEndOfCurrentNode) {

        insertRange = documentContext.rangeAtEndOfCurrentNode;

      } else if (insertAt === InsertPosition.replaceAllChildrenOfSection
        && documentContext.parentSectionInfo?.rangeToReplaceAllChildren) {

        insertRange = documentContext.parentSectionInfo?.rangeToReplaceAllChildren;

      } else if (insertAt === InsertPosition.currentCursorSelection
        && documentContext.rangeAtCurrentSelection) {

        insertRange = documentContext.rangeAtCurrentSelection;

      } else {
        insertRange = documentContext.rangeAtEndOfDocument;
      }

      if (!documentSubscriptionRef.current) {
        console.warn(
          "There is no current documentSubscription, cannot request AI edit."
        );
        return;
      }

      documentSubscriptionRef.current?.makeAIEditRequest(
        documentContext.documentVersion,
        fullPrompt,
        insertRange,
        openApiParams,
        sectionTitle,
        isInline,
        textToAddBefore
      );
    },
    []
  );

  const requestDocumentEditWithAiPromptId = ({
    documentVersion,
    currentAiPromptId,
    currentAiPromptInsertRange,
  }: DocAiEditWithAiPromptIdOptions) => {
    console.log("requestDocumentEditWithAiPromptId() ");
    if (!currentAiPromptId) {
      console.info("No AI prompt currently selected");
      return;
    }
    // console.log("currentAiPromptId = ", currentAiPromptId,
    //    ", currentAiPromptInsertRange = ", currentAiPromptInsertRange)

    if (!documentSubscriptionRef.current) {
      console.warn(
        "There is no current documentSubscription, cannot request document edit with AI prompt ID."
      );
      return;
    }

    if (documentSubscriptionRef.current?.isLockedForAiEditing) {
      console.info("AI is already editing document, ignoring CMD+Enter.");
      return;
    }

    documentSubscriptionRef.current?.makeAIEditRequestForBlock(
      documentVersion,
      currentAiPromptId,
      currentAiPromptInsertRange
    );
  };


  const documentSubscriptionRef = useRef<DocumentSubscription | undefined>()
  const setDocumentSubscription = useCallback((documentSubscription: DocumentSubscription) => {
    documentSubscriptionRef.current = documentSubscription
  }, [])

  return {
    setDocumentSubscription,
    requestAiJsonStream,
    requestDocumentAiEdit,
    requestDocumentEditWithAiPromptId,
    streamingIsInProgress,
    requestRelatedContextFromOtherDocs,
    relatedContextFromOtherDocs,
  };
};

/**
   Streams responses from the AI via web sockets.

   If streaming is not required, consider using `AiApiRequest` instead
*/
export type AiSubscription = ReturnType<typeof useAiSubscription>;
