import { IconButton, IconButtonProps, useColorModeValue } from "@chakra-ui/react"


export const IconButtonPrimary = (props: IconButtonProps) => {


  return (
    <IconButton
      colorScheme="verdiButtonPrimary"
      color="white"
      size="sm"
      {...props}
    />
  )

}
