import { css } from "@emotion/react"
import { ReactNode } from "react"
import { useUrlParams } from "../utility-hooks/useUrlParams"

type Props = {
  children: ReactNode | ReactNode[]
}

const topButtonCss = css`
  text-transform: uppercase;
  color: #888;
  font-weight: 600;
  font-size: 18px;
  padding: 5px 10px;
`

export const RouteWrapPublic = (props: Props) => {

  const { zoom: showZoom } = useUrlParams()

  return (
    <div className="App">
      <header css={css`
        width: 100%;
        height: 55px;
        padding: 0px 25px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        display: none;
        border-bottom:2px rgba(220,220,220,1) solid;
      `
      }>
        <a
          href="/"
          css={topButtonCss}
        >
          home
        </a>
        <div css={css`
          display: flex;
          flex-direction: 'row';
          align-items: 'center';
        `}>
          <div
            id={`loading-corner`}
            css={css`
              display: flex;
              justify-content: 'center';
              align-items: 'center';
              margin-right: 20px;
            `}
          >
          </div>
          {
            !showZoom ? null :
              <a
                href='/zoom/meetings'
                css={topButtonCss}
              >
                Connect Zoom
              </a>
          }
          <a
            href='/logout'
            css={topButtonCss}
          >
            logout
          </a>
        </div>
      </header>
      <div css={css`
        flex: 1;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100vh;
      `}>
        {props.children}
      </div>
    </div >
  )
}
