import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { css, useTheme } from "@emotion/react"
import { DefinitionTipForUser } from "../../components/DefinitionTipForUser"
import { useColor } from "../../utility-hooks/useColor"


type Props = {
  disclosure: ReturnType<typeof useDisclosure>
  onClose: () => void
  onStart: () => void
}
export const AssumptionInterruptModal = ({
  disclosure,
  onClose: onClose,
  onStart,
}: Props) => {


  const { isOpen } = disclosure

  const borderColor = useColor("surfaceOverlayBorder")

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth={"730px"}
      >
        <ModalHeader>
          <div>
            <span>{"Great start into assumptions!✨ Choose what’s next."}</span>
          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: 14px;
            `}
          >
            <div
              css={css`
                width: 342px;
                border: 1px solid ${borderColor};
                border-radius: 10px;
                padding-left: 25px;
                padding-top: 20px;
                padding-bottom: 30px;
                padding-right: 15px;
              `}
            >
              <div>
                <h2> Craft your Opportunity Brief</h2>
              </div>
              <div
                css={css`
                  margin-top: 18px;
                  margin-bottom: 18px;
                `}
              >
                <span>With the context you've provided, have Verdi draft documents.</span>
              </div>
              <ButtonPrimary
                label={"Craft an Opportunity"}
                onClick={onStart}
              />
            </div>
            <div
              css={css`
              width: 342px;
              border: 1px solid ${borderColor};
              border-radius: 10px;
              padding-left: 25px;
              padding-top: 20px;
              padding-bottom: 30px;
              padding-right: 15px;
            `}
            >
              <div>
                <h2>Keep Going</h2>
              </div>
              <div
                css={css`
                margin-top: 18px;
                margin-bottom: 18px;
              `}
              >
                <span>Keep answering questions to build further context about this opportunity</span>
              </div>
              <ButtonPrimary
                label={"Answer more questions"}
                onClick={onClose}
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


