import { Accordion, AccordionButton, AccordionItem, AccordionPanel, IconButton, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { sideMenuItemStyles } from "../../../components/ColorModeValues"
import { Menu } from "@verdi/shared-constants"
import { SubMenuButton } from "./SubMenuButton"
import { VerdiIconAdd, VerdiIconChevronDown, VerdiIconChevronRight } from "../../../components/icons/VerdiIcons"


export const startingMenuDepth = 1
export type FolderType = "opportunity" | "quickNote" | "anything" | "recentDocs"


type Props = {
  menuItem: Menu.MenuItemData
  onAddButtonClick?: (menuItem: Menu.MenuItemData, ref?: React.RefObject<HTMLDivElement>) => void
  onMenuItemClick: (menuItem: Menu.MenuItemData) => void
  menuDepth: number
  activeMenuIds: string[]
  // folderType: FolderType
  // childReactNode?: React.ReactNode
  // isExpandedInitially: boolean
  // onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  // addingInProgress: boolean
}

export const SubMenuSectionItem = ({
  menuItem,
  onAddButtonClick,
  onMenuItemClick,
  menuDepth,
  activeMenuIds,
  // folderType,
  // childReactNode,
  // isExpandedInitially,
  // onAddOpportunityButtonClick,
  // addingInProgress,
}: Props) => {

  const isLightMode = useColorModeValue(true, false)
  const menuFontColorActive = useColorModeValue('black', 'white')
  const menuItemStyles = useColorModeValue(sideMenuItemStyles.light, sideMenuItemStyles.dark)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentMenuItemIsInPath = activeMenuIds.includes(menuItem.id)
    const childrenAreInPath = menuItem.children.findIndex(i => activeMenuIds.includes(i.id)) > -1
    setIsExpanded(currentMenuItemIsInPath || childrenAreInPath)
    setIsHighlighted(activeMenuIds[0] === menuItem.id)

  }, [activeMenuIds, menuItem.id, menuItem.children, setIsHighlighted])


  const toggleMenuItem = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])


  if (menuDepth > Menu.maxNestableLevels) {
    return null
  }


  return (
    <nav
      css={css`
      margin-left: 5px;
      width: calc(100% - 5px);
      display: flex;
      flex-direction: column;
      padding:0;
      /* margin-top: -4px; */
      ${menuDepth > 1 ? `border-left: 1px solid ${isLightMode ? "#EEE" : "#222"};` : ``} 
    `}>

      <Accordion allowMultiple
        marginBottom={0}
        index={[isExpanded ? 0 : -1]}
      >

        <AccordionItem
          border='none'
          width="100%">

          <div css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              &:hover .show-on-hover {
                opacity: 1;
              }
              border-radius: 5px;
              padding: 4px 8px;
              ${isHighlighted ? `
                background-color: ${menuItemStyles.selected.bg};
              ` : ``}
            `}>


            <AccordionButton
              color='grey'
              _expanded={{ color: menuFontColorActive }}
              padding={1}
              onClick={(e) => {
                toggleMenuItem()
              }}
              width="20px"
            >
              <div css={css`
                    width: 18px;
                    `}>
                {menuItem.children.length > 0 &&
                  <>
                    {isExpanded ?
                      <VerdiIconChevronDown opacity={0.5} />
                      :
                      <VerdiIconChevronRight opacity={0.5} />
                    }
                  </>
                }
              </div>

            </AccordionButton>

            <div css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: calc(100% - 20px);
              font-size: 0.9em;
            `}>

              <SubMenuButton
                title={menuItem.title}
                onClick={() => onMenuItemClick(menuItem)}
              />

              {onAddButtonClick &&
                <div ref={buttonRef}>
                  <IconButton
                    className="show-on-hover"
                    aria-label="Add"
                    icon={<VerdiIconAdd />}
                    size="xs"
                    variant="ghost"
                    backgroundColor="transparent"
                    marginRight={0}
                    opacity={isExpanded ? 1 : 0}
                    title="Add"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (menuItem) {
                        onAddButtonClick?.(menuItem, buttonRef)
                      }
                    }}
                  />
                </div>
              }

            </div>

          </div>

          {/* Children */}
          <AccordionPanel
            paddingInline={0}
            padding={0}
            marginLeft={0}>

            {menuItem.children && menuItem.children.map((child, index) =>
              <div key={index}>
                <SubMenuSectionItem
                  activeMenuIds={activeMenuIds}
                  menuItem={child}
                  menuDepth={menuDepth + 1}
                  onAddButtonClick={onAddButtonClick}
                  onMenuItemClick={onMenuItemClick}
                />
              </div>
            )}
          </AccordionPanel>
        </AccordionItem>


      </Accordion>

    </nav >
  )
}
