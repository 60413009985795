import { AiCallableFunctionType, Data, DocumentSchema } from ".."
import { CompanyContextCommon } from "../data"
import { OpenAIParamsType } from "../openAi"


export type RunAiPromptApiRequestBody = {
  prompt: string
  context: string
  aiReturnsJson: boolean
  openAIParams?: OpenAIParamsType
}

export type RunAiPromptApiResponseBody = {
  aiResponseJson?: AiResponseJson | any
  aiResponseString?: string
}

export type AiResponseJson = {
  content?: string
  /** When the AI returns more than one option (by specifying the OpenAI param of 'n' > 1), 
   * then this will be filled instead of the singular "content" field */
  contentOptions?: string[]
  functionsToCall?: AiResponseFunctionToCall[]
}

export type AiResponseFunctionToCall = {
  name: string
  arguments?: any
}

export enum ChatRoleType {
  system = "system",
  user = "user",
  assistant = "assistant",
  function = "function",
}

export type RunAiChatRequestBody = {
  messages: RunAiChatRequestMessage[]
  callableFunctionTypes?: AiCallableFunctionType[]
  clientLocalDate: string
  prompt?: string
  context?: string
  openAIParams?: OpenAIParamsType
}
export type RunAiChatRequestMessage = {
  role: ChatRoleType
  content: string
}

export type UserRegistrationRequestBody = {
  username: string,
  password: string,
  name: string,
  orgInviteToken?: string,
  newOrgName?: string
}

export type GoogleOAuthRequestBody = {
  googleIdToken: string
  orgInviteToken?: string,
  newOrgName?: string
}

export type ScrapeUrlRequestBody = {
  url: string
}


export type CompanyContextUpdateRequestBody = {
  fields: CompanyContextCommon.MutableFields
}
export type CompanyContextResponseBody = {
  fields: CompanyContextCommon.Model
}

export type AssumptionUpdateRequestBody = {
  fields: Partial<Data.AssumptionMutableFields>
  mainDocId?: string,
  frameworkQuestionId?: string,
}

export type DeRiskingStepUpdateRequestBody = {
  fields: Partial<Data.DeRiskingStepMutableFields>
  mainDocTitle?: string,
  mainDocDescription?: string,
  mainDocType?: DocumentSchema.DocumentType,
  mainDocInitialStepJson?: string,
  assumptionIdsToAdd?: string[],
  assumptionIdsToRemove?: string[],
  opportunityIdsToAdd?: string[],
  opportunityIdsToRemove?: string[],
}

export type DocumentRelationUpdateRequestBody = {
  fromDocId: string,
  toDocId: string,
  type: Data.DocumentRelationType,
}

export type DocumentSnapshotResponseBody = {
  documentId: string
  title: string
  version: number
  bodyPlainText: string
  bodyJson?: string
}

export type DocumentTasksResponseBody = {
  tasks: Data.DocumentTasks.DocumentTaskDto[]
}

export type DocumentSnapshotRequestBody = {
  documentIds: string[]
}

export type DocumentFrameworkAddRequestBody = {
  documentId: string,
  frameworkId: string,
}
export type DocumentFrameworkRemoveRequestBody = {
  documentFrameworkId: string,
}

export type LinkStripeAccountRequestBody = {
  emailAddress: string
  stripePriceId: string
  trialPeriodDays: number
}

export type UserPermissionForOrgUpsertBody = {
  userId: string
  orgId: string
  fields: Partial<Data.UserPermissionForOrg.UserPermissionForOrgMutableFields>
}

export type MyStripePaymentLinkRequestBody = {
  stripePriceId: string
}

export type CreateOpportunityRequestBody = {
  fields: Data.Opportunities.OpportunityMutableFields
  document: {
    title?: string
    description?: string
  }
  initialStepJson?: string | null
  parentDocId?: string
}

export type UpdateOpportunityRequestBody = {
  opportunityId: string
  fields: Partial<Data.Opportunities.OpportunityMutableFields>
}
