import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import { OpenAi } from '@verdi/shared-constants'


type Props = {
  value?: string | null
  onChange: (text: string) => void
  documentContextValue: string | undefined | null
  defaultValue: string | undefined | null
}

// TODO: Check if this file is still being used.
const options = [
  OpenAi.Gpt_4_Latest,
  OpenAi.Gpt_3_Latest,
]

export const OpenAiModelSelect = ({
  value,
  onChange,
  documentContextValue,
  defaultValue,
}: Props) => {

  const placeholder = typeof documentContextValue === 'string' ? `doc value: ${documentContextValue}` : `default: ${defaultValue}`

  return (
    <FormControl m={2}>
      <FormLabel>{'model'}</FormLabel>
      <Select
        value={value ?? ''}
        onChange={event => onChange(event.target.value)}
        placeholder={placeholder}
      >
        {
          options.map(o =>
            <option value={o} key={o}>{o}</option>
          )
        }

      </Select>
    </FormControl>
  )
}
