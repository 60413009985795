import { useCallback, useState } from "react"
import { AutoResizingInputText } from "../../../components/forms/AutoResizingInputText"
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary"
import { ApiTypes, Data } from "@verdi/shared-constants"
import { SelectDropdown } from "../../../components/forms/SelectDropdown"
import { makePostRequestJson } from "../../../utility-hooks/fetchUtils"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { css } from "@emotion/react"


type Props = {
  pricedProducts: Data.StripeShared.PricedProduct[]
}
export const LinkUserToStripe = ({
  pricedProducts,
}: Props) => {

  const { toast } = useAppServices()


  const [emailAddress, setEmailAddress] = useState("")
  const [stripePriceId, setStripePriceId] = useState<string | undefined>(undefined)
  const [trialPeriodDays, setTrialPeriodDays] = useState<number>(0)


  const linkAccount = useCallback(async () => {
    console.log("linkAccount", { email: emailAddress })
    if (!emailAddress) {
      toast.showError("Please enter an email address")
      return
    }
    if (!stripePriceId) {
      toast.showError("Please select a stripe product")
      return
    }


    // Hit api endpoint to link account
    const body: ApiTypes.LinkStripeAccountRequestBody = {
      emailAddress: emailAddress,
      stripePriceId,
      trialPeriodDays,
    }
    const results = await makePostRequestJson("stripe/LinkStripeAccount", body)
    console.log("results", { results })
    if (results.error) {
      toast.showError(`${results.error} (api declined your request)`)
      return
    } else {
      toast.showSuccess("Account linked successfully")
    }


  }, [emailAddress, stripePriceId, trialPeriodDays])


  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    `}>
      <h2>Link Account</h2>
      <p>
        ONLY FOR USER ACCOUNTS THAT DO NOT HAVE A CONNECTION.
      </p>
      <div>
        <label>Email Address</label>
        <AutoResizingInputText
          value={emailAddress}
          placeholder="Email address of Verdi user"
          onChange={(e) => setEmailAddress(e.target.value)}
        />
      </div>
      <div>
        <label>Stripe Priced Product </label>
        <SelectDropdown
          placeholder="Select ..."
          value={stripePriceId}
          onChange={(e) => setStripePriceId(e.target.value)}
        >
          {pricedProducts.map(p =>
            <option key={p.priceId} value={p.priceId}>
              {p.productName} -
              ${p.dollarAmount} - {p.priceId}
            </option>
          )}
        </SelectDropdown>
      </div>
      <div>
        <label>Free Trial Period</label>
        <SelectDropdown
          placeholder="Select ..."
          value={trialPeriodDays}
          onChange={(e) => setTrialPeriodDays(Number(e.target.value))}
        >
          <option value={0}>(No Free Trial)</option>
          <option value={1}>1 Day</option>
          <option value={7}>7 Days</option>
          <option value={14}>14 Days</option>
          <option value={30}>30 Days</option>
          <option value={60}>60 Days</option>
          <option value={90}>90 Days</option>
        </SelectDropdown>
      </div>
      <ButtonPrimary
        label="Link Account"
        onClick={linkAccount}
        marginTop={4}
      />
    </div>
  )
}
