import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useLoggedInUserProviderQuery } from "./__generated__/useLoggedInUserProviderQuery.graphql";
import { useCallback } from "react";
import { userAsPromptContext } from "../../../ai/prompts/asPromptContext";


const queryQL = graphql`
  query useLoggedInUserProviderQuery {
    loggedInUser {
      id
      email
      name
      isAdmin
      organization {
        id
        name
        shouldIncludeInReports
      }
      roleTitle
      responsibleFor
    }
  }
`;


/** Loads info about the current user. */
export const useLoggedInUserProvider = () => {

  const { loggedInUser } = useLazyLoadQuery<useLoggedInUserProviderQuery>(queryQL, {});


  const getCurrentUserContextForAiPrompt = useCallback(() => {
    return userAsPromptContext(loggedInUser)
  }, [loggedInUser]);


  return {
    loggedInUser,
    getCurrentUserContextForAiPrompt,
  }

}
export type LoggedInUserProvider = ReturnType<typeof useLoggedInUserProvider>;


export type LoggedInUserInfo = {
  id: string;
  name: string | null;
  organization: {
    id: string;
    name: string;
    shouldIncludeInReports: boolean;
  };
  email: string;
  isAdmin: boolean;
  roleTitle: string | null;
  responsibleFor: string | null;
}
