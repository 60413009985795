/**
 * Runs fns in order until the result of one returns true from the predicate
 */
const runUntil = (predicate: Function, ...fns: Function[]) => {
  for (const fn of fns) {
    if (predicate(fn())) {
      return true;
    }
  }

  return true;
};

export default runUntil;
