import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { getCurrentAppState } from "../../../../state/store"
import { writeToDocBodyCommandDefinition } from "../documentBody/writeToDocBody"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { cleanupAiOutputAsDocumentOutline } from "../../../../ai/utils/cleanupAiOutput"
import { FrameworksState } from "../../../../state/frameworksSlice"
import { documentFrameworkState } from "../../../../state/documentFrameworksSlice"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.applyFrameworkToDocBody


/** Adds prose-mirror nodes from the current framework doc template, to the current doc body
 * 
 *  TODO: Decide if this command should be merged with `AutoDraftFullDoc`
 *  
 *  TODO: In the future ask the AI to auto draft first, for certain frameworks
 */
export const applyFrameworkToDocBodyCommandDefinition: GlobalCommandDefinition<ApplyFrameworkToDocBodyCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ApplyFrameworkToDocBodyCommandArgs) => {
    const title = `Apply framework to doc body`
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        description: '',
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ApplyFrameworkToDocBodyCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      console.warn("applyFrameworkToDocBody: Could not find editor")
      onProcessingComplete(false)
      return
    }

    let rawMarkdownTemplate = ""
    const docFramework = documentFrameworkState.getCurrentDocumentFramework(getCurrentAppState())
    if (docFramework?.frameworkId) {
      const framework = FrameworksState.getFrameworkById(getCurrentAppState(), docFramework.frameworkId)
      if (framework) {
        rawMarkdownTemplate = framework.docTemplates.find(t => !t.isExample)?.bodyMarkdown || ""
      }
    }

    if (!rawMarkdownTemplate) {
      services.toast.showWarn("No template to apply.")
      onProcessingComplete(false)
      return
    }


    let insertPosition = InsertPosition.startOfDocument
    let shouldMoveCaretToNextNode = false
    let endingPlaceholder = ""

    const docIsEmpty = editor.state.doc.textContent.trim() === ""
    if (docIsEmpty) {
      shouldMoveCaretToNextNode = true
    } else {
      const behaviors = DocumentSchema.NodeExtensions.PlaceholderBehaviorTypes.RemoveOnly
      endingPlaceholder = `\n[[ -- end of framework template -- | behaviors=${behaviors}]]`
    }

    const rawTemplate = rawMarkdownTemplate + endingPlaceholder
    const documentOutline = cleanupAiOutputAsDocumentOutline(rawTemplate)

    writeToDocBodyCommandDefinition.triggerCommand?.({
      documentOutline,
      insertPosition,
      shouldMoveCaretToNextNode,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: ApplyFrameworkToDocBodyCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ApplyFrameworkToDocBodyCommandArgs = {

}
