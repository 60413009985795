import { Node } from '@tiptap/core'
import { VerdiNodeTypes } from './VerdiNodeTypes'


type Config = {
  addNodeView?: NonNullable<Parameters<typeof Node.create>[0]>['addNodeView']
}

// ReplaceWithBehavior
export enum PlaceholderBehaviorTypes {

  /** IMPLICIT DEFAULT. Shows suggestions of text to replace with */
  SuggestReplacements = "SuggestReplacements",

  /** Does not show suggestions or other options.
   * 
   *  @WARNING: DO NOT CHANGE THESE STRING VALUES: Unless you plan to update all documents in redis. 
   */
  RemoveOnly = "RemoveOnly",

  /** Suggests adding a document reference
   * 
   *  @WARNING: DO NOT CHANGE THESE STRING VALUES: Unless you plan to update all documents in redis. 
   */
  LinkToRelatedDoc = "LinkToRelatedDoc",

}

export type PlaceholderInlineNodeAttributes = {
  text: string,
  maxLines?: number,
  instructions?: string,
  behaviors?: PlaceholderBehaviorTypes[],
}
const name = VerdiNodeTypes.PlaceholderInline

export type PlaceholderSummary = {
  text: string,
  maxLines?: number,
  instructions?: string,
  behaviors?: string[], // Maps to PlaceholderBehaviorTypes[], since redux can't serialize enums :(
}

/** A Placeholder that actually persists in the document markup
 * 
 *  Useful docs about TipTap Node Options: https://tiptap.dev/docs/editor/api/schema#inline
 */
export const PlaceholderInlineNode = (config?: Config) => Node.create({
  blockName: name,
  name,
  atom: true, // allows up/down arrows to select this node (with no directly editable prosemirror content)
  group: 'inline',
  inline: true,
  selectable: true,
  // content: "(text|placeholder)*",
  // content: 'title contentList?',
  // content: 'text*',
  // selectable: true,
  // isolating: true,
  // leafText: (node) => node.attrs.text,
  // draggable: true,
  // renderText: ({ node }) => {
  //   return renderPlaceholderNodeAsText(node.attrs as PlaceholderInlineNodeAttributes)
  // },
  addAttributes() {
    return {
      text: { required: true, keepOnSplit: false },
      maxLines: { required: false },
      instructions: { required: false },
      behaviors: { default: [], isRequired: false },
    }
  },

  parseHTML() {
    return [
      { tag: 'placeholder-block' },
    ]
  },

  renderHTML({ HTMLAttributes }) {

    const element = document.createElement("span");
    element.classList.add("placeholder-inline");
    element.innerText = HTMLAttributes.text;

    return element;
  },

  addNodeView: config?.addNodeView,
})
