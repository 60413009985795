/** IMPORTANT: DO NOT CHANGE THE VALUES OF THESE
 *  There are many documents that have them as the current value
 *  Changing these values will break those documents
 */

/** Enables AI generation for any element with this attribute set */
export const aiPromptId = "aiPromptId"
/** For Snippets that are managed by a document */
export const placeholderKey = "placeholderKey"

export type KnowTypes = "aiPromptId" | "placeholderKey"

