import { useCallback, useEffect, useMemo } from "react"
import { useDisclosure } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { Data, DocumentSchema } from "@verdi/shared-constants"
import posthog from "../../helpers/posthog"
import { getUrlForDocument } from "../../routes"
import { DeleteAssumptionModal } from "./DeleteAssumptionModal"
import { AssumptionWithDocBodySnapshot, AssumptionsProvider } from "./useAssumptionsProvider"
import { useAppServices } from "../../components/appServices/useAppServices"
import { RelatedMenuItemData } from "../documentRelation/data/groupRelatedDocs"
import { VerdiIconAdd } from "../../components/icons/VerdiIcons"
import { CreateAssumptionModal, SuggestedAssumption } from "./CreateAssumptionModal"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { AssumptionListGroup } from "./AssumptionListGroup"
import { AssumptionIntroModal } from "./AssumptionIntroModal"
import { useAssumptionLoader } from "../../state/loaders/LoadAssumptions"
import { useAppDispatch, useAppSelector } from "../../state/storeHooks"
import { AssumptionInterruptModal } from "./AssumptionInterruptModal"
import { AssumptionsState } from "../../state/assumptionsSlice"
import { EmptyStateMessage } from "../../components/EmptyStateMessage"
import { groupByConfidenceLevel } from "./groupByConfidenceLevel"
import { SuggestedAssumptionsList } from "./SuggestedAssumptionsList"
import { DocBodySnapshotsState } from "../../state/docBodySnapshotsSlice"
import { AssumptionsDetector } from "./AssumptionsDetector"


/** Allows for automatically showing the add assumptions modal upon navigating to the page */
export const autoShowAddAssumptionModalLocalStorageKey = "autoShowAddAssumptionModal"

type Props = {
  assumptionsProvider: AssumptionsProvider,
  docsRelatedToMe: RelatedMenuItemData[]
  currentDocType?: DocumentSchema.DocumentType,
  documentId?: string,
  opportunityId?: string,
}
export const AssumptionsList = ({
  assumptionsProvider,
  docsRelatedToMe,
  currentDocType,
  documentId,
  opportunityId,
}: Props) => {

  const {
    router,
    getContextForAi,
  } = useAppServices()

  const dispatch = useAppDispatch()
  const { loadSuggestions } = useAssumptionLoader(documentId, getContextForAi)
  const showEditDisclosure = useDisclosure()

  const suggestedAssumptions = useAppSelector(s => AssumptionsState.getSuggestedAssumptions(s, documentId))
  const loadingSuggestedAssumptions = useAppSelector(AssumptionsState.getIsLoadingSuggestedAssumptions)
  const allAssumptions = useAppSelector(AssumptionsState.getAllAssumptions)
  const allDocSnapshots = useAppSelector(DocBodySnapshotsState.getAll)

  const assumptions = useMemo(() => {
    if (!documentId) return []
    const toReturn: AssumptionWithDocBodySnapshot[] = []
    for (const relatedDoc of docsRelatedToMe) {
      const mainDocId = relatedDoc.menuItem.id
      const assumption = allAssumptions.find(a => a.mainDoc?.id === mainDocId)
      if (assumption) {
        const docBodySnapshot = allDocSnapshots.find(s => s.documentId === mainDocId)
        toReturn.push({ ...assumption, snapshot: docBodySnapshot })
      }
    }
    return toReturn
  }, [documentId, allAssumptions, docsRelatedToMe, allDocSnapshots])

  // console.log("assumptions = ", { assumptions, allDocSnapshots })

  const groupedAssumptions = useMemo(() => {
    return groupByConfidenceLevel(assumptions)
  }, [assumptions])


  // /** Delete */
  const { RenderConfirmDeleteAssumptionDialog } = DeleteAssumptionModal({ assumptionsProvider })


  const showInterruptModalDisclosure = useDisclosure()


  // /** Go To */
  const handleGoToAssumption = useCallback((assumption: Data.AssumptionModel) => {
    posthog.capture('goToAssumption', { flows: ['assumptions'], id: assumption.id })
    router.router.push(getUrlForDocument(assumption.mainDoc?.id || ''))
  }, [router.router]);


  const closeAssumptionEditor = useCallback(() => {
    showEditDisclosure.onClose()
  }, [showEditDisclosure])

  const addNewAssumption = useCallback(() => {
    showEditDisclosure.onOpen()
  }, [showEditDisclosure])

  const showInfoModalDisclosure = useDisclosure()

  const startFromInfoModal = useCallback(() => {
    showInfoModalDisclosure.onClose()
    addNewAssumption()
  }, [showInfoModalDisclosure, addNewAssumption])

  useEffect(() => {
    if (localStorage.getItem(autoShowAddAssumptionModalLocalStorageKey) === "true") {
      localStorage.removeItem(autoShowAddAssumptionModalLocalStorageKey)
      showInfoModalDisclosure.onOpen()
    }
  }, [showInfoModalDisclosure])


  const continueFromInterruptModal = useCallback(() => {
    showInterruptModalDisclosure.onClose()
    showEditDisclosure.onOpen()
  }, [showEditDisclosure, showInterruptModalDisclosure])

  const goToOpportunityDocument = useCallback(() => {
    if (!documentId) return
    router.router.push(getUrlForDocument(documentId))
  }, [documentId, router.router])


  const answeredSuggestions = useAppSelector((s) => AssumptionsState.getSuggestedAnsweredAssumptions(s, documentId))
  const isLoadingAnsweredSuggestions = useAppSelector(AssumptionsState.getIsLoadingSuggestedAnsweredAssumptions)

  const loadUnansweredSuggestionsIfNeeded = useCallback((existingSuggestions: SuggestedAssumption[]) => {
    if (suggestedAssumptions && suggestedAssumptions.length < 5) {
      loadSuggestions(existingSuggestions)
    }
  }, [loadSuggestions, suggestedAssumptions])

  const loadInitialSuggestions = useCallback((documentId: string) => {
    if (documentId) {
      if (isLoadingAnsweredSuggestions || answeredSuggestions.length > 0) {
        // Only load unanswered (if any)
        loadUnansweredSuggestionsIfNeeded([])
        return
      }

      // Load any answered assumptions first
      AssumptionsDetector.detectAnsweredAssumptions(documentId, getContextForAi)
        .then(() => {
          // Then load unanswered suggestions
          loadUnansweredSuggestionsIfNeeded([])
        })
    }
  }, [getContextForAi, answeredSuggestions, isLoadingAnsweredSuggestions, loadUnansweredSuggestionsIfNeeded])


  // Q: Can we move this into the "Add New Assumption" modal?
  const onCreatedComplete = useCallback((
    newAssumptionDocId: string,
    assumptionName: string,
    userShouldReview: boolean,
  ) => {
    if (!documentId) {
      console.warn("Could not remove suggested assumption from the list. No document id")
      return
    }
    dispatch(AssumptionsState.removeSuggestedAssumption({ targetDocId: documentId, title: assumptionName }))
    dispatch(AssumptionsState.setSuggestionsLoading(false))
    loadUnansweredSuggestionsIfNeeded(suggestedAssumptions?.filter(a => a.name !== assumptionName))

  }, [suggestedAssumptions, dispatch, documentId, loadUnansweredSuggestionsIfNeeded])



  // Eager load the suggested questions
  useEffect(() => {

    loadInitialSuggestions(documentId || "")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId])


  return (
    <div>
      {
        assumptions.length === 0 &&
        <EmptyStateMessage
          title="No assumptions yet..."
          onButtonClick={showInfoModalDisclosure.onOpen}
          buttonLabel="Add an Assumption">
          <p>
            Click the button to start adding assumptions and see them populate here.
          </p>
        </EmptyStateMessage>
      }


      {
        assumptions.length > 0 &&
        <>
          <div css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;
          `}>
            <IconButtonTertiary
              icon={<VerdiIconAdd />}
              aria-label="add assumption"
              onClick={addNewAssumption}
            />
          </div>


          {groupedAssumptions.map((group, key) =>
            <AssumptionListGroup
              key={key}
              label={group.label}
              assumptions={group.assumptions}
              onClick={handleGoToAssumption}
            />
          )}

        </>
      }

      <SuggestedAssumptionsList
        documentId={documentId}
        answeredSuggestions={answeredSuggestions}
        isLoading={isLoadingAnsweredSuggestions}
      />


      {RenderConfirmDeleteAssumptionDialog}

      <CreateAssumptionModal
        assumptionsProvider={assumptionsProvider}
        disclosure={showEditDisclosure}
        opportunityID={opportunityId || ""}
        documentId={documentId || ""}
        onClose={closeAssumptionEditor}
        suggestedAssumptions={suggestedAssumptions}
        onCreatedComplete={onCreatedComplete}
        isLoadingInitially={loadingSuggestedAssumptions}
        showInterruptModal={showInterruptModalDisclosure.onOpen}
        existingAssumptions={assumptions}
      />
      <AssumptionIntroModal
        disclosure={showInfoModalDisclosure}
        onClose={showInfoModalDisclosure.onClose}
        onStart={startFromInfoModal}
      />
      <AssumptionInterruptModal
        disclosure={showInterruptModalDisclosure}
        onClose={continueFromInterruptModal}
        onStart={goToOpportunityDocument}
      />

    </div >
  )
}
