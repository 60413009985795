import { css } from "@emotion/react"
import { MenuStructureForAllDocsProvider } from '../document/organize/useGetMenuStructureForAllDocs';
import { SideMenuSectionForNotes } from "./SideMenuSectionForNotes";
import { SideMenuSectionForAll } from "./SideMenuSectionForAll";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Menu } from "@verdi/shared-constants";
import { ROOT_OPPORTUNITY_MENU_ID, SideMenuSectionForOpportunitiesV2 } from "./SideMenuSectionForOpportunitiesV2";
import { useUrlParams } from "../../utility-hooks/useUrlParams";
import { SideMenuSectionForCompany } from "./SideMenuSectionForCompany";
import { useRouter } from "found";
import { SideMenuSectionForRecentDocuments } from "./SideMenuSectionForRecentDocuments";
import { addOpportunityShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/opportunity/addOpportunityShowOptions";
import { SideMenuSectionForSingleOpportunity } from "./subMenu/SideMenuSectionForSingleOpportunity";
import { MenuItemSearchButton } from "../../components/search/MenuItemSearchButton";
import { OpportunityProvider } from "../opportunities/useOpportunityProvider";
import { allOpportunitiesRoutePath } from "../../routes";


type Props = {
  onBeforeNavigate: () => void
  menuStructureProvider: MenuStructureForAllDocsProvider
  opportunityProvider: OpportunityProvider
}
export function SideMenu({
  onBeforeNavigate,
  menuStructureProvider,
  opportunityProvider,
}: Props) {


  const { menuItemsStructured, recentDocumentMenuItems } = menuStructureProvider


  // Highlight the active menu items
  const router = useRouter()
  const documentId = router.match.params['documentId']
  const [activeMenuIds, setActiveMenuIds] = useState<string[]>([])
  useEffect(() => {
    const activeDocIds: string[] = []
    if (documentId) {
      const currentDoc = menuItemsStructured?.flatMenuItems?.find(item => item.id === documentId)
      if (currentDoc) {
        activeDocIds.push(documentId)
        const parents = Menu.getMenuItemHierarchy(currentDoc, menuItemsStructured.flatMenuItems)
        activeDocIds.push(...parents.map(p => p.id))
      }
    }
    setActiveMenuIds(activeDocIds)
  }, [documentId, menuItemsStructured.flatMenuItems])


  const showAddOpportunityModal = useCallback((menuItem: Menu.MenuItemData) => {
    const parentDocId = menuItem.id === ROOT_OPPORTUNITY_MENU_ID ? undefined : menuItem.id
    addOpportunityShowOptionsCommandDefinition.triggerCommand?.({
      parentDocId,
      // shouldAutoAddWithDefaults: true,
    })
  }, [])

  const { fullMenu } = useUrlParams()


  const [isShowingOpportunitySubMenu, setIsShowingOpportunitySubMenu] = useState(false)
  const currentOpportunity = useMemo(() => {
    if (!documentId) {
      setIsShowingOpportunitySubMenu(false)
      return undefined
    }
    const opportunity = opportunityProvider.currentOpportunityDoc
    setIsShowingOpportunitySubMenu(true)
    return opportunity
  }, [documentId, opportunityProvider.currentOpportunityDoc])


  // const { experimentalEnabled } = useFeatureFlags()
  const preferOpportunitySubMenu = true // !experimentalEnabled


  return (
    <nav
      css={css`
      margin-left: -5px;
      width: calc(100% - 5px);
      display: flex;
      flex-direction: column;
    `}>

      {!(preferOpportunitySubMenu && isShowingOpportunitySubMenu) &&
        <div>

          <div css={css`
            margin-left: 13px;
            width: inherit;
          `}>
            <MenuItemSearchButton />
          </div>

          <div css={css`
            margin-left: 4px;
            gap: 4px;
            display: flex;
            flex-direction: column;
          `}>
            <SideMenuSectionForCompany
              onBeforeNavigate={onBeforeNavigate}
            />

            {/* <SideMenuSectionForTeam
              onBeforeNavigate={onBeforeNavigate}
            /> */}
          </div>


          <SideMenuSectionForOpportunitiesV2
            onBeforeNavigate={onBeforeNavigate}
            onAddOpportunityButtonClick={showAddOpportunityModal}
            opportunities={menuItemsStructured.opportunitiesWithOnlyOpportunityChildren}
            activeMenuIds={activeMenuIds}
          />


          <SideMenuSectionForNotes
            onBeforeNavigate={onBeforeNavigate}
            onAddOpportunityButtonClick={showAddOpportunityModal}
            menuItems={menuItemsStructured.quickNotes}
            activeMenuIds={activeMenuIds}

          />

          <SideMenuSectionForRecentDocuments
            onBeforeNavigate={onBeforeNavigate}
            menuItems={recentDocumentMenuItems}
          />

          {fullMenu &&
            <SideMenuSectionForAll
              onBeforeNavigate={onBeforeNavigate}
              onAddOpportunityButtonClick={showAddOpportunityModal}
              menuItems={menuItemsStructured.rootLevelDocs}
              activeMenuIds={activeMenuIds}
            />
          }

        </div>
      }


      {preferOpportunitySubMenu && isShowingOpportunitySubMenu &&
        <div css={css`
          margin-left: 5px;
        `}>

          <SideMenuSectionForSingleOpportunity
            opportunity={currentOpportunity}
            onBeforeNavigate={() => {
              setIsShowingOpportunitySubMenu(true)
              onBeforeNavigate()
            }}
            onBackButtonClick={() => {
              console.log("Back pressed")
              setIsShowingOpportunitySubMenu(false)
              router.router.push(allOpportunitiesRoutePath)
            }}
            onAddOpportunityButtonClick={showAddOpportunityModal}
            menuStructureProvider={menuStructureProvider}
            activeMenuIds={activeMenuIds}
          />
        </div>
      }

    </nav>
  )
}
