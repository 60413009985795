import { useCallback, forwardRef, useRef, useState, useImperativeHandle, useEffect, useMemo } from "react"
import { Editor } from "@tiptap/core"
import { EditorState } from "prosemirror-state"
import { createPortal } from "react-dom"
import { css } from "@emotion/react"
import { SuspenseErrorBoundary } from "../../../../components/SuspenseErrorBoundary"
import { UseAiCoach } from "../../../../ai/coach/useAiCoach"
import { isOnMacOS } from "../../../../utility-hooks/isOnMacOS"
import { AiCommandType } from "../../../../ai/coach/useAiCoachCommandProcessor"
import { useDocServices } from "../../docServices/useDocServices"
import { CurrentBlockContext, DomPositionRect } from "../utils/getCurrentBlockContext"
import { CommandMenuContent, CommandMenuKeyRef } from "./CommandMenuContent"
import './CommandMenu.css'
import { extraSmallBreakpoint } from "../../../../components/pageLayout/styleHelpers"
import { EditorView } from "@tiptap/pm/view"
import { useComboBoxController } from "../../../../components/commands/miniCommandMenu/comboBox/useComboBoxController"
import { getCurrentLineState, useDocEditorAsComboBoxInput } from "./useDocEditorAsComboBoxInput"
import { useCommandMenuWH } from "../../../../components/commands/miniCommandMenu/useCommandMenuWH"
import { useAppServices } from "../../../../components/appServices/useAppServices"
import { CommandsForDocumentProvider, useCommandsForDocument } from "../../../../components/commands/commandMenuData/useCommandsForDocument"
import { docBodyMarkClearCurrentCommandDefinition } from "../../../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearCurrent"
import { CommandListLimitTo } from "../../../../components/commands/commandMenuData/CommandListLimitTo"


const isOnAMac = isOnMacOS()

const underTextYOffset = 10

/** Positioning offsets when on smaller screens */
const mobileOffsets = {
  top: 60,  // Make sure it does not overlap the top bar when open
  left: 35, // Make sure it does not overlap the "/" Slash button when open
}

type Props = {
  editor: Editor | null | undefined
  aiCoach: UseAiCoach
  currentBlockContext?: CurrentBlockContext
  onOpenCommandMenu: () => void
  onCloseCommandMenu: () => void
  mouseIsDown: boolean
}

/** Controls the position and visibility of the document level command menu */
export type CommandMenuRef = CommandMenuKeyRef & {
  onEditorUpdate: (newState: EditorState, oldState?: EditorState) => void
  toggleCommandMenu: (options: OpenMenuOptions) => void
  openCommandMenu: (options?: OpenMenuOptions) => void
  closeCommandMenu: (options?: CloseMenuOptions) => void
  menuIsOpen: boolean
  mouseIsDown: boolean
}

export const CommandMenuWrapperUnforwarded: React.ForwardRefRenderFunction<CommandMenuRef, Props> = ({
  editor,
  aiCoach,
  currentBlockContext,
  onOpenCommandMenu,
  onCloseCommandMenu,
  mouseIsDown,
}, ref) => {

  const lastOpenPos = useRef<{
    nodePos: number
    slashPos: number
  }>()

  const { getContextForAi } = useAppServices()
  const { whatsNextSuggester, document } = useDocServices()


  const setFocusTo = useCallback((target: "editor" | "command-menu") => {
    if (target === "editor") {
      editor?.view.focus()
      editor?.commands.focus()
    } else if (target === "command-menu") {
      const elem = window.document.querySelector('.command-menu input') as HTMLInputElement
      if (!elem) return
      elem.focus()
    }
  }, [editor])

  const [menuIsOpen, setMenuIsOpen] = useState(false)


  // For the ComboBox based command menu
  const comboBoxState = useComboBoxController({
    onClose: (wasDismissed) => {
      closeMenu({ wasDismissed })
    },
    onRunCommand: (command) => {
      if (!command.shouldKeepOpenOnRun) {
        closeMenu({ wasDismissed: true })
      }
      if (!command?.runCommand) {
        console.warn("Selected commands is missing a runCommand() function. ", { command })
        return
      }
      command?.runCommand?.()
    },
    mode: "search-and-filter",
  })


  // START: COMMANDS
  const {
    allApplicableCommands,
  }: CommandsForDocumentProvider = useCommandsForDocument({
    document,
    editor,
    aiCoach,
    whatsNextSuggester,
    currentBlockContext,
    menuIsOpen,
    openMenu: (options) => openMenu(options),
    getContextForAi,
  })

  useEffect(() => {
    comboBoxState.setCommandsInGroups(allApplicableCommands)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allApplicableCommands])

  // END: COMMANDS


  const { onKeyDownForComboBox } = useDocEditorAsComboBoxInput({
    comboBoxState,
    openTheMenu: () => openMenu({}),
    closeTheMenu: () => closeMenu({ wasDismissed: true }),
    setFocusTo,
    menuIsOpen,
  })


  //** Used to position the CM over something besides the current caret or current block in the document, (such as the Slash button) */
  const [targetElement, setTargetElement] = useState<HTMLBaseElement>()

  /** Determines where the command menu should be positioned on the dom */
  const targetRect = useMemo(() => {

    let specifiedRect = targetElement?.getBoundingClientRect() as DomPositionRect
    if (specifiedRect) {
      // adjust for the scroll position
      return {
        ...specifiedRect,
        top: specifiedRect.top + window.scrollY,
        left: specifiedRect.left + window.scrollX,
      }
    }
    // // This doesn't seem to work as well as I'd like
    // if (currentBlockContext?.currentMark) {
    //   return currentMarkDomRect
    // }
    return currentBlockContext?.domPositionCaret
      || currentBlockContext?.domPositionBlock
  }, [targetElement, currentBlockContext?.domPositionCaret, currentBlockContext?.domPositionBlock])


  const entirePlaceholderIsSelected = useMemo(() => {
    const selection = currentBlockContext?.currentSelectionRange
    if (!selection) return false
    const currentMark = currentBlockContext?.currentMark
    if (!currentMark) return false
    return selection.from === currentMark.from && selection.to === currentMark.to
  }, [currentBlockContext?.currentMark, currentBlockContext?.currentSelectionRange])


  // Auto show/hide menu when there is selected text
  useEffect(() => {
    if (!menuIsOpen && currentBlockContext?.currentSelectText
      && !mouseIsDown
      && !entirePlaceholderIsSelected) {
      openMenu({
        limitTo: CommandListLimitTo.showLocalOnly,
      })
    } else if (menuIsOpen && !currentBlockContext?.currentSelectText
      && !currentBlockContext?.docIsEmpty) {
      console.log("no text selected, closing the menu")
      closeMenu({ wasDismissed: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBlockContext?.currentSelectText, mouseIsDown, entirePlaceholderIsSelected])

  // Auto dismiss mode, controls what additional triggers will close the command menu
  const [autoDismissMode, setAutoDismissMode] = useState<AutoDismissMode | undefined>()
  useEffect(() => {
    if (autoDismissMode === AutoDismissMode.whenLineNoLongerBlank
      && currentBlockContext?.currentLineText) {
      console.log("Auto dismissing doc CM because line is no longer blank.")

      setAutoDismissMode(undefined)
      closeMenu({ wasDismissed: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDismissMode, currentBlockContext?.currentLineText])


  // // When doc is empty (Blank State) Show the CM
  // useEffect(() => {
  //   if (editorAreaHasFocus, currentBlockContext?.docIsEmpty && window.innerWidth > extraSmallBreakpoint) {
  //     openMenu({
  //       autoDismissMode: AutoDismissMode.whenLineNoLongerBlank,
  //       limitTo: CommandListLimitTo.showLocalOnly,
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentBlockContext?.docIsEmpty])


  const closeMenu = useCallback((options?: CloseMenuOptions) => {

    const { wasDismissed, shouldSetFocusToEditor } = { ...defaultCloseMenuOptions, ...options }
    lastOpenPos.current = undefined
    setMenuIsOpen(false)
    setTargetElement(undefined)
    if (editor && !wasDismissed) {
      removeSlashIfAny(editor)
    }
    comboBoxState.reset()
    if (shouldSetFocusToEditor) {
      setFocusTo("editor")
    }
    onCloseCommandMenu?.()
  }, [setMenuIsOpen, setFocusTo, editor, onCloseCommandMenu, setTargetElement, comboBoxState])


  const openMenu = useCallback((options: OpenMenuOptions) => {
    console.log("openMenu()", { options })
    const { targetElement, searchText, setFocusToTarget, autoDismissMode, limitTo } = options
    comboBoxState.reset()
    comboBoxState.setSearchText(searchText || '')
    setTargetElement(targetElement)
    setMenuIsOpen(true)
    setAutoDismissMode(autoDismissMode)
    comboBoxState.setNumOfGroupsVisible(limitTo === CommandListLimitTo.showLocalOnly ? 1 : -1)
    if (setFocusToTarget) {
      setTimeout(() => setFocusTo(setFocusToTarget), 1)
    }
    onInitialOpen()
    onOpenCommandMenu?.()
  }, [setMenuIsOpen, onOpenCommandMenu, setFocusTo, setTargetElement, comboBoxState, setAutoDismissMode])


  const hasBeenOpenedRef = useRef(false)
  const onInitialOpen = useCallback(() => {
    if (hasBeenOpenedRef.current === true) return
    hasBeenOpenedRef.current = true

    // Put logic for initial open here
    whatsNextSuggester.generateDocumentLevelSuggestions()

    // we can run placeholder commands here?

  }, [whatsNextSuggester, hasBeenOpenedRef])


  const menuWrapperRef = useRef<HTMLDivElement>()
  const commandKeyRef = useRef<CommandMenuKeyRef>()




  const positionTheMenu = useCallback((rect: DomPositionRect) => {
    if (!menuWrapperRef.current) return

    const el = menuWrapperRef.current
    let top, left
    if (window.innerWidth < extraSmallBreakpoint) { // When mobile, keep a standard positioning
      top = window.scrollY + mobileOffsets.top
      left = window.scrollX + mobileOffsets.left
      el.style.right = "20px"
    } else {
      top = rect.top + rect.height + underTextYOffset //+ window.scrollY
      left = rect.left // + window.scrollX

      if (top + el.clientHeight > window.innerHeight + window.scrollY) {
        top = rect.top - el.clientHeight - 10
      }
      if (left + el.clientWidth > window.innerWidth) {
        left = rect.left - el.clientWidth
      }

    }
    el.style.top = `${top}px`
    el.style.left = `${left}px`

  }, [])


  useEffect(() => {
    if (menuWrapperRef.current && targetRect) {
      positionTheMenu(targetRect)
    }
  }, [targetRect, positionTheMenu, menuWrapperRef])


  /** triggered whenever current user presses any key in the editor */
  const onKeyDown = useCallback((event: KeyboardEvent, view: EditorView) => {

    /** Handle Keyboard events and shortcuts here */

    if (event.ctrlKey && event.key === ' ' && !((isOnAMac && event.metaKey) || (!isOnAMac && event.ctrlKey))) {
      // TODO: Something else needs to get triggered here (maybe position?)
      if (currentBlockContext) {
        openMenu({ setFocusToTarget: "command-menu" })
      }
      return true
    }

    // the CMD ENTER thingy
    if (event.key === 'Enter' && ((isOnAMac && event.metaKey) || (!isOnAMac && event.ctrlKey))) {
      if (currentBlockContext?.aiPromptId) {
        // TODO: Upgrade to new commands
        aiCoach.processCommand(AiCommandType.generateFromTemplatedDocAiPrompt)
      } else {
        // TODO: Upgrade to new commands
        aiCoach.processCommand(AiCommandType.ContinueWriting)
      }
      return true
    }

    const comboBoxHandledTheEvent = onKeyDownForComboBox(event, view)
    if (comboBoxHandledTheEvent) return true

    if (entirePlaceholderIsSelected) {
      docBodyMarkClearCurrentCommandDefinition.triggerCommand?.({})
    }

    return commandKeyRef.current?.onKeyDown(event, view) || false
  }, [openMenu, commandKeyRef, currentBlockContext, onKeyDownForComboBox, aiCoach, entirePlaceholderIsSelected])


  /** Triggered on every change to the editor, including from the AI and other human collaborators */
  const onEditorUpdate = useCallback((state: EditorState, oldState: EditorState | undefined) => {
    /*  IDEA: Use this to determine how to filter the command menu
        IF on a child of a section, show those child options
        IF on a section title, show section options
        IF not in a section, show the default options
        etc */
  }, [])


  /** These options are available outside of this component */
  useImperativeHandle(
    ref,
    () => {
      return {
        toggleCommandMenu: (options: OpenMenuOptions) => {
          if (menuIsOpen) {
            closeMenu({ wasDismissed: true })
          } else {
            openMenu(options)
          }
        },
        openCommandMenu: (options?: OpenMenuOptions) => {
          openMenu(options || {})
        },
        closeCommandMenu: (options?: CloseMenuOptions) => {
          closeMenu(options)
        },
        menuIsOpen,
        onKeyDown,
        onEditorUpdate,
        mouseIsDown,
      }
    },
    [menuIsOpen, openMenu, closeMenu, onKeyDown, onEditorUpdate, mouseIsDown],
  )

  const commandMenuWH = useCommandMenuWH()


  if (!editor) return null


  return createPortal(
    <div
      className="command-menu"
      ref={ref => menuWrapperRef.current = ref || undefined}
      style={{
        display: menuIsOpen ? 'block' : 'none',
        width: commandMenuWH.width,
      }}
    >
      <SuspenseErrorBoundary>
        <div css={css`
          display: flex;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        `}>
          <CommandMenuContent
            // key={`cm-${comboBoxState.searchText}`}
            ref={ref => commandKeyRef.current = ref || undefined}
            menuIsOpen={menuIsOpen}
            closeMenu={(wasDismissed: boolean) => closeMenu({ wasDismissed })}
            editor={editor}
            comboBoxState={comboBoxState}
            maxWidth={commandMenuWH.width}
            maxHeight={commandMenuWH.height}
          />

        </div>
      </SuspenseErrorBoundary>
    </div>,
    window.document.body
  )
}


export const CommandMenuWrapper = forwardRef(CommandMenuWrapperUnforwarded)


/** Cleans up the "/" that triggered the command menu opening */
const removeSlashIfAny = (editor: Editor) => {

  // const nodeText = getSelectedBlockText(editor.state.selection.$anchor)
  if (getCurrentLineState(editor.state).hasCommandChar) {
    editor
      .chain()
      .deleteToLastSlash()
      .run();
  }
}


export type OpenMenuOptions = {
  targetElement?: HTMLBaseElement
  searchText?: string
  setFocusToTarget?: "editor" | "command-menu"
  autoDismissMode?: AutoDismissMode
  limitTo?: CommandListLimitTo
}

export type CloseMenuOptions = {
  /** If false, will remove any text after the slash */
  wasDismissed?: boolean,
  shouldSetFocusToEditor?: boolean,
}
const defaultCloseMenuOptions: CloseMenuOptions = {
  shouldSetFocusToEditor: true,
  wasDismissed: true, // Changing this may effect a handful of behaviors. Be careful.
}

export enum AutoDismissMode {
  whenLineNoLongerBlank,
}
