import { useMemo } from "react";
import { VerdiCommandGroup } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { CurrentBlockContext } from "../../../screens/document/tiptapEditor/utils/getCurrentBlockContext";
import { WhatsNextSuggester } from "../../../screens/document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/useWhatsNextSuggester";
import { UseAiCoach } from "../../../ai/coach/useAiCoach";
import { getCmdsForBlock } from "./getCmds/getCmdsForBlock";
import { BasicDocumentDetails } from "../../../screens/document/BasicDocumentDetails";
import { getCmdsForDoc } from "./getCmds/getCmdsForDoc";
import { OpenMenuOptions } from "../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper";
import { getCmdsForSelection } from "./getCmds/getCmdsForSelection";
import { useLineSuggestionCommands } from "./getCmds/useLineSuggestionCommands";
import { getCmdsForAnywhere } from "./getCmds/getCmdsForAnywhere";
import { useFeatureFlags } from "../../../utility-hooks/useFeatureFlags";
import { AiContextConfig, AllContextForAi } from "../../../ai/promptUtils/useAllContextForAi";
import { useFrameworkSuggestionCommands } from "./getCmds/useFrameworkSuggestionCommands";
import { useNextStepsCommands } from "./getCmds/useNextStepsCommands";
import { Editor } from "@tiptap/core";
import { useAppSelector } from "../../../state/storeHooks";
import { documentFrameworkState } from "../../../state/documentFrameworksSlice";
import { getCmdsForBlankDoc } from "./getCmds/getCmdsForBlankDoc";
import { getCmdsForPlaceholder } from "./getCmds/getCmdsForPlaceholder";
import { SuggestionsForPlaceholdersState } from "../../../state/suggestions/suggestionsForPlaceholdersSlice";
import { getCmdsForDocReference } from "./getCmds/getCmdsForDocReference";
import { useMarkCommands } from "./getCmds/useMarkCommands";
import { getCmdsForDebug } from "./getCmds/getCmdsForDebug";


type Props = {
  document: BasicDocumentDetails
  editor: Editor | null | undefined
  currentBlockContext?: CurrentBlockContext
  whatsNextSuggester: WhatsNextSuggester
  aiCoach: UseAiCoach
  menuIsOpen: boolean
  openMenu: (options: OpenMenuOptions) => void
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
}

/** Manages the list of all commands used for an active document */
export const useCommandsForDocument = ({
  document,
  editor,
  currentBlockContext,
  whatsNextSuggester,
  aiCoach,
  menuIsOpen,
  openMenu,
  getContextForAi,
}: Props) => {


  const { experimentalEnabled } = useFeatureFlags()

  const currentDocFrameworks = useAppSelector(
    state => documentFrameworkState.getDocumentFrameworksForDoc(state, document.id))
  const hasFramework = useMemo(() => currentDocFrameworks.length > 0, [currentDocFrameworks])
  const docIsEmpty = currentBlockContext?.docIsEmpty || false


  // BEGIN THE LONG LIST OF COMMANDS

  const blankDocCommands = useMemo<VerdiCommandGroup[]>(() => {
    return getCmdsForBlankDoc({
      document,
      docIsEmpty,
      hasFramework,
    })
  }, [docIsEmpty, document, hasFramework])


  const { missingFrameworkCommandGroups } = useFrameworkSuggestionCommands(document, hasFramework)


  /** The Top Commands that the AI Suggests */
  const { nestStepsCommandGroup } = useNextStepsCommands({ whatsNextSuggester, experimentalEnabled })


  const { currentMarkCommands } = useMarkCommands({
    hasCurrentMark: Boolean(currentBlockContext?.currentMark),
    currentMarkSuggestionText: currentBlockContext?.currentMarkSuggestionText,
  })


  const placeholderReplacements = useAppSelector(SuggestionsForPlaceholdersState.getAll)
  const placeholderCommands = useMemo(() => {
    return getCmdsForPlaceholder(currentBlockContext?.currentPlaceholderAttrs, placeholderReplacements)
  }, [currentBlockContext?.currentPlaceholderAttrs, placeholderReplacements])


  const docReferenceCommands = useMemo(() => {
    return getCmdsForDocReference(currentBlockContext?.currentAtomicNodeInfo)
  }, [currentBlockContext?.currentAtomicNodeInfo])

  const commandsForSelection = useMemo(() => {
    if (!currentBlockContext?.currentSelectText) return []
    return getCmdsForSelection(currentBlockContext?.currentSelectText)
  }, [currentBlockContext?.currentSelectText])


  const { currentLineSuggestionCommandGroup } = useLineSuggestionCommands({
    currentBlockContext,
    getContextForAi,
    menuIsOpen,
  })


  const blockCommands: VerdiCommandGroup[] = useMemo(() => {
    return getCmdsForBlock()
  }, [])


  const docCommands = useMemo(() => {
    return getCmdsForDoc(document)
  }, [document])


  const commandsForAnywhere = useMemo(() => {
    return getCmdsForAnywhere(experimentalEnabled)
  }, [experimentalEnabled])


  const docDebugCommandsGroup = useMemo(() => {
    if (!experimentalEnabled) return []
    return getCmdsForDebug()

  }, [experimentalEnabled])


  const allAvailableDocCommandsGrouped: VerdiCommandGroup[] = useMemo(() => {
    return [
      nestStepsCommandGroup,
      ...blockCommands,
      ...docCommands,
      ...docDebugCommandsGroup,
    ]
  }, [nestStepsCommandGroup, blockCommands, docCommands, docDebugCommandsGroup])



  const allApplicableCommands = useMemo<VerdiCommandGroup[]>(() => {

    const commandsToList: VerdiCommandGroup[] = []

    if (blankDocCommands.length > 0) {
      commandsToList.push(...blankDocCommands)
    }

    if (placeholderCommands.length > 0) {
      commandsToList.push(...placeholderCommands)
    }

    if (docReferenceCommands.length > 0) {
      commandsToList.push(...docReferenceCommands)
    }

    if (commandsForSelection.length > 0) {
      commandsToList.push(...commandsForSelection)
    }

    if (currentMarkCommands) {
      commandsToList.push(...currentMarkCommands)
    }

    if (missingFrameworkCommandGroups.length > 0) {
      commandsToList.push(...missingFrameworkCommandGroups)
    }

    if (currentLineSuggestionCommandGroup) {
      commandsToList.push(currentLineSuggestionCommandGroup)
    }

    // Add the rest of the commands
    commandsToList.push(...allAvailableDocCommandsGrouped)

    // Add high level commands
    commandsToList.push(...commandsForAnywhere)

    return commandsToList
  }, [
    // TODO: Any changes to this triggers an endless re-render. 
    // NEED TO FIX
    blankDocCommands,
    currentMarkCommands,
    docReferenceCommands,
    currentLineSuggestionCommandGroup,
    commandsForSelection,
    allAvailableDocCommandsGrouped,
    commandsForAnywhere,
    placeholderCommands,
    // limitTo,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // missingFrameworkCommandGroups,
    // openMenu,
  ])




  // END: COMMANDS


  return {
    /** All commands that can currently be ran, in groups. */
    allApplicableCommands,
  }

}


export type CommandsForDocumentProvider = ReturnType<typeof useCommandsForDocument>
