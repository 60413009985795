import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'
import { SuggestedAssumption } from '../screens/assumptions/CreateAssumptionModal'
import { AssumptionSummary } from '../screens/assumptions/useAssumptionsProvider'


interface IAssumptionsState {
  /** Assumptions that are officially connected to the opportunity and persisted in the db */
  assumptions: Data.AssumptionModel[]
  // suggestions: AssumptionSuggestions[]
  isLoadingAssumptions: boolean
  /** Pending assumptions as shown in the "add assumption modal." Typically gaps that need to be identified */
  suggestedAssumptions: { [targetDocId: string]: SuggestedAssumption[] }
  isLoadingSuggestedAssumptions: boolean
  /** Auto-detected questions with ANSWERS from the document body or other context. 
   * Pending acceptance by the user before being persisted to the sb */
  suggestedAnsweredAssumptions: { [targetDocId: string]: AssumptionSummary[] }
  isLoadingSuggestedAnsweredAssumptions: boolean
}

const initialState: IAssumptionsState = {
  assumptions: [],
  isLoadingAssumptions: false,
  suggestedAssumptions: {},
  isLoadingSuggestedAssumptions: false,
  suggestedAnsweredAssumptions: {},
  isLoadingSuggestedAnsweredAssumptions: false,
}

/** Need to add update / add support */
export const assumptionsSlice = createSlice({
  name: 'assumptions',
  initialState,
  reducers: {
    setAllAssumptions: (state, action: PayloadAction<{ assumptions: Data.AssumptionModel[], loading: boolean }>) => {
      state.assumptions = action.payload.assumptions
      state.isLoadingAssumptions = action.payload.loading
    },
    addAssumption: (state, action: PayloadAction<Data.AssumptionModel>) => {
      state.assumptions.push(action.payload)
    },
    updateAssumption: (state, action: PayloadAction<{ id: string, fields: Partial<Data.AssumptionMutableFields> }>) => {
      const { id, fields } = action.payload
      const index = state.assumptions.findIndex((o) => o.id === id)
      if (index === -1) {
        console.error(`updateAssumption: Assumption not found for updating: ${id}`, { all: state.assumptions, fields })
      }
      state.assumptions[index] = { ...state.assumptions[index], ...fields }
    },
    removeAssumption: (state, action: PayloadAction<{ id: string }>) => {
      state.assumptions = state.assumptions.filter((o) => o.id !== action.payload.id)
    },


    setSuggestedAssumptions: (state, action: PayloadAction<{ targetDocId: string, assumptions: SuggestedAssumption[], loading: boolean }>) => {
      const { targetDocId, assumptions, loading } = action.payload
      state.suggestedAssumptions[targetDocId] = assumptions
      state.isLoadingSuggestedAssumptions = loading
    },
    setSuggestionsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSuggestedAssumptions = action.payload
    },
    removeSuggestedAssumption: (state, action: PayloadAction<{ targetDocId: string, title: string }>) => {
      const { targetDocId, title } = action.payload
      state.suggestedAssumptions[targetDocId] = state.suggestedAssumptions[targetDocId]?.filter((a) => a.name !== title)
    },


    setSuggestedAnsweredAssumptions: (state, action: PayloadAction<{ targetDocId: string, answeredAssumptions: AssumptionSummary[], isLoading: boolean }>) => {
      const { targetDocId, answeredAssumptions, isLoading } = action.payload
      state.suggestedAnsweredAssumptions[targetDocId] = answeredAssumptions
      state.isLoadingSuggestedAnsweredAssumptions = isLoading
    },
    removeSuggestedAnsweredAssumption: (state, action: PayloadAction<{ targetDocId: string, assumption: AssumptionSummary }>) => {
      const { targetDocId, assumption } = action.payload
      state.suggestedAnsweredAssumptions[targetDocId] =
        state.suggestedAnsweredAssumptions[targetDocId]?.filter((a) => a.question !== assumption.question)
    },
    updateSuggestedAnsweredAssumption: (state, action: PayloadAction<{ targetDocId: string, assumption: AssumptionSummary }>) => {
      const { targetDocId, assumption } = action.payload
      const index = state.suggestedAnsweredAssumptions[targetDocId]?.findIndex((a) => a.question === assumption.question) || -1
      if (index > -1) {
        state.suggestedAnsweredAssumptions[targetDocId][index] = assumption
      }
    },
  },
  selectors: {
    getSuggestedAssumptions: (state, targetDocId: string | undefined) => {
      if (targetDocId && state.suggestedAssumptions.hasOwnProperty(targetDocId)) {
        return state.suggestedAssumptions[targetDocId]
      }
      return []
    },
    getSuggestedAnsweredAssumptions: (state, targetDocId: string | undefined) => {
      if (targetDocId && state.suggestedAnsweredAssumptions.hasOwnProperty(targetDocId)) {
        return state.suggestedAnsweredAssumptions[targetDocId]
      }
      return []
    },
    getIsLoadingSuggestedAssumptions: (state) => state.isLoadingSuggestedAssumptions,
    getAllAssumptions: (state) => state.assumptions,
    getIsLoadingSuggestedAnsweredAssumptions: (state) => state.isLoadingSuggestedAnsweredAssumptions,
  },
})

export const AssumptionsState = {
  ...assumptionsSlice.actions,
  ...assumptionsSlice.selectors,
}

export default assumptionsSlice.reducer
