import { useCallback, useEffect, useRef, useState } from "react"
import { CurrentBlockContext } from "../../../utils/getCurrentBlockContext"
import { BasicDocumentDetails } from "../../../../BasicDocumentDetails"
import { VerdiCommand } from "../VerdiCommand"
import { DocumentSchema } from "@verdi/shared-constants"
import { getWhatsNextForAssumption } from "./forDocs/getWhatsNextForAssumption"
import { DocumentContextForAi } from "../../../../../../ai/coach/AiCoachPrompts"
import { getWhatsNextForOpportunity } from "./forDocs/getWhatsNextForOpportunity"
import { DocContextIncludeArgs } from "../../../../../../ai/documents/useAiTipTapBridge"
import { DocMetadataSummary, DocUpdatedCustomEvent, DocUpdatedEvent } from "../../../../../../ai/documents/DocUpdatedEvent"
import { useThrottle } from "../../../../../../utility-hooks/useThrottle"
import { useAppDispatch, useAppSelector } from "../../../../../../state/storeHooks"
import { getWhatsNextForInterviewGuide } from "./forDocs/getWhatsNextForInterviewGuide"
import { getWhatsNextForResearch } from "./forDocs/getWhatsNextForResearch"
import { SuggestionsForDocState } from "../../../../../../state/suggestions/suggestionsForDocSlice"
import { AiContextConfig, AllContextForAi } from "../../../../../../ai/promptUtils/useAllContextForAi"
import { getNextSectionTitleCommands } from "../../../../../../ai/suggestions/makeNextSectionTitleCommands"
import { getDocIdeasFromAiCoachCommandDefinition } from "../../../../../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/getDocIdeasFromAiCoach"


/** When these many characters change from the document body, a refresh of suggestions may be triggered, */
const deltaOfCharsToTriggerSuggestions = 500


type Props = {
  document: BasicDocumentDetails
  currentBlockContext?: CurrentBlockContext
  getDocumentContextForAi: (include?: DocContextIncludeArgs) => DocumentContextForAi | undefined
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
}
/** Facilitates deciding what suggestions to show to the user next, in the command menu */
export const useWhatsNextSuggester = ({
  document,
  currentBlockContext,
  getContextForAi,
  getDocumentContextForAi,
}: Props) => {

  const dispatch = useAppDispatch()

  const isGeneratingDocLevelSuggestions = useAppSelector(SuggestionsForDocState.getIsGenerating)
  const [docCommandSuggestions, setDocCommandSuggestions] = useState<VerdiCommand[]>([])

  const onCommandCompleted = useCallback((commandIdToRemove: string, sectionTitleToRemove?: string) => {
    if (!commandIdToRemove) return
    // Remove the command from the list
    setDocCommandSuggestions(prev => prev.filter(a => a.id !== commandIdToRemove))
    if (sectionTitleToRemove) {
      dispatch(SuggestionsForDocState.removeFutureSectionTitle(sectionTitleToRemove))
    }
  }, [setDocCommandSuggestions, dispatch])


  const generateDocumentLevelSuggestions = useCallback(async () => {
    if (!document) return []

    let contextForAi = await getContextForAi({
      includeContext: {
        document: {
          include: true,
          sectionTitles: true,
        }
      }
    })

    // TEMP: Sometimes the doc context is not available from the `getContextForAi()` call
    // TODO: This is ugly, make it work to where this is no longer necessary.
    if (!contextForAi.currentDoc) {
      console.warn("generateDocumentLevelSuggestions: AUTO CORRECTING docCONTEXT missing :(")
      contextForAi.currentDoc = getDocumentContextForAi({
        sectionTitles: true,
      })
    }

    console.log("generateDocumentLevelSuggestions", { contextForAi, currentBlockContext })


    const docContext = contextForAi.currentDoc
    if (!docContext) {
      console.warn("generateDocumentLevelSuggestions: No doc context")
      return
    }

    dispatch(SuggestionsForDocState.setIsGenerating(true))

    const promises: Promise<SuggestionForDocument[]>[] = []
    let suggestions: SuggestionForDocument[] = []

    const isForOpportunity = document.type === DocumentSchema.DocumentType.opportunity
    promises.push(getNextSectionTitleCommands(
      contextForAi,
      onCommandCompleted,
      isForOpportunity,
    ))

    switch (document.type) {
      case DocumentSchema.DocumentType.opportunity:
        promises.push(getWhatsNextForOpportunity(onCommandCompleted, contextForAi))
        break;

      case DocumentSchema.DocumentType.assumption:
        promises.push(getWhatsNextForAssumption(onCommandCompleted, docContext))
        break;

      case DocumentSchema.DocumentType.interviewGuide:
        promises.push(getWhatsNextForInterviewGuide(onCommandCompleted, docContext))
        break;

      case DocumentSchema.DocumentType.research:
        promises.push(getWhatsNextForResearch(onCommandCompleted, docContext))
        break;
    }


    // Run all in parallel due to a need for speed 🏎️ 💨 🏁
    const promiseResults = await Promise.allSettled(promises)
    promiseResults.forEach(p => {
      if (p.status === "fulfilled") {
        const suggestion = p.value
        suggestions.push(...suggestion)
      }
    })


    const titlesForFutureSections = suggestions
      .map(s => s.sectionTitle || "")
      .filter(s => s.length > 0)
    console.log("titlesForFutureSections", { titlesForFutureSections, typeSpecificSuggestions: suggestions })
    const uniqueSectionTitles = Array.from(new Set(titlesForFutureSections))
    dispatch(SuggestionsForDocState.setFutureSectionTitles(uniqueSectionTitles))

    // IDEA: IF there are any suggestions, append a "show more suggestions" action at the bottom
    const combinedSuggestions = [...suggestions.map(s => s.command)]
    console.log("combinedSuggestions", combinedSuggestions)
    setDocCommandSuggestions(prev => [...combinedSuggestions, ...prev])

    dispatch(SuggestionsForDocState.setIsGenerating(false))

  }, [document, setDocCommandSuggestions, onCommandCompleted, dispatch, getContextForAi, getDocumentContextForAi, currentBlockContext])



  // Auto triggering suggestions when the document is updated
  const lastDocMetadata = useRef<DocMetadataSummary>()
  const handleDocUpdatedDebounced = useThrottle((evt: DocUpdatedCustomEvent) => {
    autoSuggestIfNeeded(evt)
  }, 5000)
  /** Note: DocUpdatedEvent uses addEventListener, so we need to use *refs* for any state it references. */
  const handleDocUpdated = useCallback((evt: DocUpdatedCustomEvent) => {
    handleDocUpdatedDebounced(evt)
  }, [handleDocUpdatedDebounced])
  const autoSuggestIfNeeded = useCallback((evt: DocUpdatedCustomEvent) => {

    if (isGeneratingDocLevelSuggestions) {
      console.log(" SUGGESTIONS ALREADY IN PROGRESS.")
      return
    }

    const currentSummary = evt.detail.current

    if (!lastDocMetadata.current) { //set it initially
      console.log(" Setting initial lastDocMetadata")
      lastDocMetadata.current = currentSummary
      return
    }
    const { documentId, lengthOfBody } = currentSummary
    if (lastDocMetadata.current.documentId !== documentId) {
      console.log(" documentId doesn't match")
      lastDocMetadata.current = currentSummary
      return
    }

    const sectionCount = currentSummary.sectionTitles.length
    if (sectionCount !== lastDocMetadata.current.sectionTitles.length) {
      console.log(` sectionCount changed from ${lastDocMetadata.current.sectionTitles.length} to ${sectionCount} `)
      generateDocumentLevelSuggestions()
      lastDocMetadata.current = currentSummary
      return
    }

    const charCountAddedSinceLastTime = Math.abs(lengthOfBody - lastDocMetadata.current.lengthOfBody)
    console.log(` charCountAddedSinceLastTime ${charCountAddedSinceLastTime} / ${deltaOfCharsToTriggerSuggestions}`)


    if (charCountAddedSinceLastTime > deltaOfCharsToTriggerSuggestions) {
      console.log(" WILL NOW TRIGGER MORE SUGGESTIONS")
      generateDocumentLevelSuggestions()
      getDocIdeasFromAiCoachCommandDefinition.triggerCommand?.({
        mode: "alwaysHitTheApi",
      })
      lastDocMetadata.current = currentSummary
      return
    }


  }, [isGeneratingDocLevelSuggestions, lastDocMetadata, generateDocumentLevelSuggestions])


  useEffect(() => {
    DocUpdatedEvent.addEventListener(handleDocUpdated)
    return () => {
      DocUpdatedEvent.removeEventListener(handleDocUpdated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    generateDocumentLevelSuggestions,
    docCommandSuggestions,
    isGeneratingDocLevelSuggestions,
  }
}

export type WhatsNextSuggester = ReturnType<typeof useWhatsNextSuggester>

export type SuggestionForDocument = {
  command: VerdiCommand
  sectionTitle?: string
}
