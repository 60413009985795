import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { AiPromptIDE } from "./AiPromptIDE";
import { AiPromptPreview } from "./AiPromptPreview";
import { useCreateAiPrompt } from "./useCreateAiPrompt";
import { useUpdateAiPrompt } from "./useUpdateAiPrompt";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { AiPromptPreview_otherBlockLevelPrompts$key } from "./__generated__/AiPromptPreview_otherBlockLevelPrompts.graphql";
import { AiPromptFormModal_documentLevelContextAiPrompt$key } from "./__generated__/AiPromptFormModal_documentLevelContextAiPrompt.graphql";
import { OpenAi } from "@verdi/shared-constants";
import { OpenAiModelSelect } from "./OpenAiModelSelect";

const paramsUrl = `https://platform.openai.com/docs/api-reference/completions/create#completions/create-model`;

const fragmentQL = graphql`
  fragment AiPromptFormModal_documentLevelContextAiPrompt on DocumentAiPrompt {
    id
    promptText
    dontDo
    notesForAdmin
    openApiParams {
      best_of
      echo
      frequency_penalty
      logprobs
      max_tokens
      model
      n
      presence_penalty
      stop
      suffix
      temperature
      top_p
    }
  }
`;

const m = 2;
const NumberFormControl = ({
  onChange,
  value,
  label,
  documentContextValue,
  defaultValue,
}: {
  onChange: (x: number) => void;
  value: number | undefined | null;
  label: string;
  documentContextValue: number | undefined | null;
  defaultValue: number | undefined | null;
}) => {
  const placeholder =
    typeof documentContextValue === "number"
      ? `doc value: ${documentContextValue}`
      : `default: ${defaultValue}`;
  return (
    <GridItem w="100%">
      <FormControl m={m}>
        <FormLabel size="xs">{label}</FormLabel>
        <NumberInput
          size="sm"
          value={value ?? undefined}
          onChange={(event, value) => onChange(value)}
        >
          <NumberInputField placeholder={placeholder} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </GridItem>
  );
};

type SharedProps = {
  onSaveComplete?: (id: string) => void;
  disclosure: ReturnType<typeof useDisclosure>;
  documentTitle: string;
  documentId: string;
  isDocContext?: boolean;
  docContextPrompt?: AiPromptFormModal_documentLevelContextAiPrompt$key;
  otherBlockLevelPrompts: AiPromptPreview_otherBlockLevelPrompts$key;
};

type Props = SharedProps &
  (
    | {
      formType: "create";
    }
    | {
      formType: "update";
      aiPrompt: Parameters<typeof useUpdateAiPrompt>[0]["aiPrompt"];
    }
  );

export const AiPromptFormModal = (props: Props) => {
  const isUpdate = "aiPrompt" in props && props.aiPrompt;

  const { onSaveComplete, disclosure } = props;
  const { isOpen, onOpen, onClose } = disclosure;
  const create = useCreateAiPrompt({
    documentId: props.documentId,
    initialInputValue: {
      isDocContext: props.isDocContext,
    },
    onCompleted: (response) => {
      onSaveComplete?.(response.createDocumentAiPrompt.id);
      onClose();
    },
  });
  const update = useUpdateAiPrompt({
    aiPrompt: isUpdate ? props.aiPrompt : undefined,
    onCompleted: (response) => {
      onSaveComplete?.(response.updateDocumentAiPrompt.id);
      onClose();
    },
  });
  const docLevelContextPrompt = useFragment(
    fragmentQL,
    props.docContextPrompt ?? null
  );
  const formControls = isUpdate ? update : create;

  const {
    input,
    save,
    setInputValue,
    setInputOpenApiParamsValue,
    isSaving,
    isValid,
  } = formControls;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent maxWidth="95vw" maxHeight="90vh">
          <ModalHeader>
            {props.isDocContext ? "Document " : "Block"} Level AI Prompt - "
            {props.documentTitle}""
          </ModalHeader>
          <ModalBody
            css={css`
              overflow-x: auto;
            `}
          >
            <FormControl isRequired m={m}>
              <FormLabel>Prompt Text</FormLabel>
              <div
                css={css`
                  display: flex;
                  flex-direction: "column";
                  gap: 15px;
                  width: 100%;
                `}
              >
                <AiPromptIDE
                  value={input.promptText || ""}
                  onChange={(value) => {
                    setInputValue("promptText", value);
                  }}
                />
                <AiPromptPreview
                  documentLevelContextTemplateText={
                    docLevelContextPrompt?.promptText
                  }
                  templateText={input.promptText ?? ""}
                  otherBlockLevelPrompts={props.otherBlockLevelPrompts}
                />
              </div>
            </FormControl>

            <Stack direction="row">
              <Stack direction="column" width="50%" marginRight={m}>
                <FormControl marginLeft={m}>
                  <label>Notes (for admin)</label>
                  <Textarea
                    noOfLines={10}
                    height={200}
                    size="xs"
                    backgroundColor="#222"
                    color="#ddd"
                    overflowY="auto"
                    value={input.notesForAdmin || ""}
                    onChange={(event) => {
                      setInputValue("notesForAdmin", event.target.value);
                    }}
                    placeholder={`Notes for admin`}
                  />
                </FormControl>

                <FormControl m={m}>
                  <Input
                    value={input.dontDo || ""}
                    onChange={(event) => {
                      setInputValue("dontDo", event.target.value);
                    }}
                    placeholder={`Don't Do Prompt`}
                  />
                </FormControl>
              </Stack>

              <Stack direction="column" width="50%" m={m}>
                <label>
                  Open AI Params &nbsp;
                  <a target="_blank" href={paramsUrl} rel="noreferrer">
                    help
                  </a>
                </label>

                <Grid templateColumns="repeat(3, 1fr)" gap={1} rowGap={0}>
                  <OpenAiModelSelect
                    value={input.openApiParams?.model}
                    onChange={(model) => {
                      setInputOpenApiParamsValue("model", model);
                    }}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.model
                    }
                    defaultValue={OpenAi.openAiParamDefaults.model}
                  />
                  <NumberFormControl
                    label={`best_of`}
                    onChange={(num) =>
                      setInputOpenApiParamsValue("best_of", num)
                    }
                    value={input.openApiParams?.best_of}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.best_of
                    }
                    defaultValue={OpenAi.openAiParamDefaults.best_of}
                  />

                  {/* <NumberFormControl
            label={`echo`}
            onChange={num => setInputOpenApiParamsValue('echo', num)}
            value={input.openApiParams?.echo}
            documentContextValue={docContextPrompt?.openApiParams?.echo}
            defaultValue={OpenAi.openAiParamDefaults.echo}
          /> */}
                  <NumberFormControl
                    label={`frequency_penalty`}
                    onChange={(num) =>
                      setInputOpenApiParamsValue("frequency_penalty", num)
                    }
                    value={input.openApiParams?.frequency_penalty}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.frequency_penalty
                    }
                    defaultValue={OpenAi.openAiParamDefaults.frequency_penalty}
                  />
                  <NumberFormControl
                    label={`logprobs`}
                    onChange={(num) =>
                      setInputOpenApiParamsValue("logprobs", num)
                    }
                    value={input.openApiParams?.logprobs}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.logprobs
                    }
                    defaultValue={OpenAi.openAiParamDefaults.logprobs}
                  />
                  <NumberFormControl
                    label={`max_tokens`}
                    onChange={(num) =>
                      setInputOpenApiParamsValue("max_tokens", num)
                    }
                    value={input.openApiParams?.max_tokens}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.max_tokens
                    }
                    defaultValue={OpenAi.openAiParamDefaults.max_tokens}
                  />
                  {/* <NumberFormControl
            label={`model`}
            onChange={num => setInputOpenApiParamsValue('model', num)}
            value={input.openApiParams?.model}
            documentContextValue={docContextPrompt?.openApiParams?.model}
            defaultValue={OpenAi.openAiParamDefaults.model}
          /> */}
                  <NumberFormControl
                    label={`n`}
                    onChange={(num) => setInputOpenApiParamsValue("n", num)}
                    value={input.openApiParams?.n}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.n
                    }
                    defaultValue={OpenAi.openAiParamDefaults.n}
                  />
                  <NumberFormControl
                    label={`presence_penalty`}
                    onChange={(num) =>
                      setInputOpenApiParamsValue("presence_penalty", num)
                    }
                    value={input.openApiParams?.presence_penalty}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.presence_penalty
                    }
                    defaultValue={OpenAi.openAiParamDefaults.presence_penalty}
                  />
                  {/* <NumberFormControl
            label={`stop`}
            onChange={num => setInputOpenApiParamsValue('stop', num)}
            value={input.openApiParams?.stop}
            documentContextValue={openApiParams?.stop}
            defaultValue={OpenAi.openAiParamDefaults.stop}
          /> */}
                  {/* <NumberFormControl
            label={`suffix`}
            onChange={num => setInputOpenApiParamsValue('suffix', num)}
            value={input.openApiParams?.suffix}
            documentContextValue={openApiParams?.suffix}
            defaultValue={OpenAi.openAiParamDefaults.suffix}
          /> */}
                  <NumberFormControl
                    label={`temperature`}
                    onChange={(num) =>
                      setInputOpenApiParamsValue("temperature", num)
                    }
                    value={input.openApiParams?.temperature}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.temperature
                    }
                    defaultValue={OpenAi.openAiParamDefaults.temperature}
                  />
                  <NumberFormControl
                    label={`top_p`}
                    onChange={(num) => setInputOpenApiParamsValue("top_p", num)}
                    value={input.openApiParams?.top_p}
                    documentContextValue={
                      docLevelContextPrompt?.openApiParams?.top_p
                    }
                    defaultValue={OpenAi.openAiParamDefaults.top_p}
                  />
                </Grid>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="grey" variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={isSaving}
              disabled={!isValid}
              colorScheme="green"
              mr={3}
              onClick={save}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
