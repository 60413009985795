
/** Returns a new string will all invalid characters swapped out */
export const getValidPlaceholderName = (rawPlaceholder: string) => {

  // Replace all non-word characters with underscores
  const invalidChars = /[^a-zA-Z0-9_$]/g
  const sanitized = rawPlaceholder.replace(invalidChars, '_')

  // First character should be a letter or underscore
  const validPlaceholderNameRegex = /[a-zA-Z_]/
  let results = sanitized.charAt(0).match(validPlaceholderNameRegex) ? sanitized.charAt(0) : '_'

  // Remaining characters should be letters, digits, or underscores
  results += sanitized.substr(1).replace(/[^a-zA-Z0-9_]/g, '_')

  if (results === '_') return ""

  return results
}
