import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { dispatch } from "../../../../state/store"
import { loadingStatusState } from "../../../../state/loadingStatusSlice"
import { writeContentToDocBody } from "./writeToDocBody"
import { ProsemirrorNodes } from "@verdi/shared-constants"
import { showDocEditorCommandMenuCommandDefinition } from "../utils/showDocEditorCommandMenu"
import { AutoDismissMode } from "../../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"
import { SuggestionsForDocState } from "../../../../state/suggestions/suggestionsForDocSlice"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.addSectionWithAi

export const addSectionWithAiCommandDefinition: GlobalCommandDefinition<AddSectionWithAiCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddSectionWithAiCommandArgs) => {

    const { id, sectionTitle } = args
    const name = `Add Section > ${sectionTitle}`

    const toReturn =
      {
        id,
        globalCommandType: cmdType,
        name,
        searchName: name.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: args.isSuggestionFromAi ? "aiSuggestion" : undefined
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: AddSectionWithAiCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // const aiCoach = services.servicesForCurrentDoc?.aiCoach
    // if (!aiCoach) {
    //   console.warn("addSectionWithAi: Could not find aiCoach")
    //   return
    // }

    const docContext = services.servicesForCurrentDoc?.getDocumentContextForAi()
    if (!docContext) {
      console.warn("addSectionWithAi: Could not find docContext")
      return
    }

    const opportunityContext = await services.getOpportunityContextForAi()
    if (!opportunityContext) {
      console.warn("addSectionWithAi: Could not find opportunityContext")
      return
    }

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      console.warn("addSectionWithAi: Could not find doc editor")
      return
    }

    // const { onCompleted, sectionTitle } = args
    // let promptWithoutContext = ""

    // const futureSectionTitles = getCurrentAppState().suggestionsForDoc.futureSectionTitles || []
    // console.log("addSectionWithAi: futureSectionTitles from Redux ", {
    //   futureSectionTitles
    // })
    // promptWithoutContext = getPromptForAddingSectionToOpportunity(sectionTitle, futureSectionTitles, opportunityContext ? buildOpportunityContextForPrompt(opportunityContext, false) : "")


    // Set is doing AI on doc
    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Adding section ...",
    }))
    const childText = args.placeholderText
      ? DocumentSchema.PlaceholderInlineNodeUtils.wrapInPlaceholderMarkup(args.placeholderText) : ""
    const childLine = ProsemirrorNodes.makeBlockOfType("freeText", childText)
    const sectionContent = ProsemirrorNodes.makeSectionBlock(args.sectionTitle, [childLine], undefined);

    // add section title including with a blank child
    writeContentToDocBody({
      editor,
      contentToInsert: sectionContent,
      insertPosition: InsertPosition.AsNextRootNode,
      shouldMoveCaretToNextNode: false,
      shouldAutoOpenCommandMenu: true,
    })

    dispatch(SuggestionsForDocState.removeFutureSectionTitle(args.sectionTitle))

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
      message: "",
    }))

    setTimeout(() => {
      showDocEditorCommandMenuCommandDefinition.triggerCommand?.({
        onlyShowIfCurrentLineIsBlank: true,
        docCommandMenuOptions: {
          autoDismissMode: AutoDismissMode.whenLineNoLongerBlank,
        }
      })
      /** TODO: Make this setTimeout not needed
       * Looks like currentBlockContext is stale when the CM is opened
       * Resulting in the line suggestions not reflecting that the current block type is now a "section"
       */
    }, 1000)

    args.onCompleted?.()
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: AddSectionWithAiCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type AddSectionWithAiCommandArgs = {
  id: string
  sectionTitle: string
  placeholderText?: string
  /** Allows passing in a specific prompt, bypassing the internally defined one */
  onCompleted?: () => void
  isSuggestionFromAi: boolean
}
