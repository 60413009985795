import { Data, DocumentSchema } from "@verdi/shared-constants"
import { getUrlForDocument } from "../../../../routes"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { buildFirstDocStepFromRawMarkdown } from "../../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { DocumentFrameworksProvider } from "../../../../screens/documentFrameworks/DocumentFrameworksProvider"


const cmdType = GlobalCommandType.addDocWithFramework


export const addDocWithFrameworkCommandDefinition: GlobalCommandDefinition<AddDocWithFrameworkCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddDocWithFrameworkCommandArgs) => {
    const { cmdTitle, docTitle, framework } = args
    const name = cmdTitle ? cmdTitle : docTitle || "Add new note ..."
    const toReturn =
      {
        id: args.id,
        globalCommandType: cmdType,
        name: name,
        searchName: name.toLowerCase(),
        tooltip: framework?.description || undefined,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  triggerCommand: (args: AddDocWithFrameworkCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  },
  processCommand: (
    args: AddDocWithFrameworkCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const {
      docTitle,
      docDescription,
      framework,
    } = args

    const {
      toast: toastProvider,
      getDocumentContextForAi,
      documentRelationsProvider,
    } = services

    let parentDocId = args.parentDocId
    if (!parentDocId) {
      const currentDocContext = getDocumentContextForAi()
      parentDocId = currentDocContext?.id
      if (!parentDocId) {
        toastProvider.showError("could not detect current document")
        return
      }
    }

    let initialStepJson: string | undefined
    let docType = DocumentSchema.DocumentType.document
    if (framework) {
      if (!DocumentFrameworksProvider) {
        console.error("Document Frameworks Provider not available")
        toastProvider.showError("Could not add note.")
        return
      }
      const template = getTemplateFromFramework(framework)
      if (template) {
        initialStepJson = template.initialStepJson
        if (template.docType) {
          docType = template.docType
        }
      }
    }

    documentRelationsProvider.createNewDoc({
      type: docType,
      title: docTitle || framework?.title || undefined,
      description: docDescription || framework?.description || undefined,
      parentDocId,
      initialStepJson,
    },
      (error) => {
        console.error("On create error", { error })
        toastProvider.showError("Could not create document")
      },
      async (newDocId) => {
        console.log("On create complete (no relation added)")
        if (!newDocId) {
          toastProvider.showError("Could not create document")
          return
        }

        if (framework) {
          await DocumentFrameworksProvider.setFrameworkForDocument(newDocId, framework)
        }

        toastProvider.showSuccess("Note created!")
        const url = getUrlForDocument(newDocId)
        console.log("redirect url = ", url)
        services.router.router.push(url)
      })

    args.onCompleted?.()
    onProcessingComplete(true)

    // TODO: Cleanup the suggestion state, so this same command gets removed from the "suggested" items array ???
  },
}

export type AddDocWithFrameworkCommandArgs = {
  cmdTitle?: string
  docTitle?: string
  docDescription?: string
  framework?: Data.FrameworkModel
  parentDocId?: string
  id: string
  /** optional callback when command is executed. 
   * 
   * IDEALLY we remove this and let this command update redux state directly */
  onCompleted?: () => void
}


export const getTemplateFromFramework = (framework: Data.FrameworkModel) => {

  if (!framework) return undefined

  let initialStepJson: string | undefined
  const template = framework.docTemplates?.find(t => !t.isExample)
  const bodyMarkdown = template?.bodyMarkdown
  initialStepJson = bodyMarkdown ? buildFirstDocStepFromRawMarkdown(bodyMarkdown) : undefined

  return {
    initialStepJson,
    docType: template?.docType || undefined,
  }
}
