import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react"
import { useRef, useCallback } from "react"
import { ShowConfirmDialogCommandArgs } from "../commands/commandDefinitions/utils/showConfirmDialog"
import { ButtonSecondary } from "../buttons/ButtonSecondary"
import { ButtonPrimary } from "../buttons/ButtonPrimary"


type Props = ShowConfirmDialogCommandArgs & {
  disclosure: ReturnType<typeof useDisclosure>,
}
/** Shows a confirmation dialog modal. You must pass in a disclosure object and call it's `onOpen()` function */
export const ConfirmDialog = ({
  heading,
  dialogBodyText,
  dialogBodyHtml,
  confirmButtonLabel,
  isDestructiveAction,
  disclosure,
  onConfirmSuccess,
  onCancel,
  secondaryButtonOptions,
}: Props) => {

  const { isOpen, onClose } = disclosure
  const cancelRef = useRef<any>()

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {heading}
            </AlertDialogHeader>
            <AlertDialogCloseButton
              onClick={handleClose}
            />

            <AlertDialogBody>
              {dialogBodyHtml || dialogBodyText}
            </AlertDialogBody>

            <AlertDialogFooter>

              {secondaryButtonOptions ?
                <ButtonSecondary
                  label={secondaryButtonOptions.label}
                  onClick={() => {
                    handleClose()
                    secondaryButtonOptions.onClick()
                  }}
                />
                :
                <Button ref={cancelRef}
                  onClick={() => {
                    onCancel?.()
                    handleClose()
                  }}
                  size="xs"
                >
                  Cancel
                </Button>
              }

              <ButtonPrimary
                label={confirmButtonLabel}
                colorScheme={isDestructiveAction ? 'red' : 'verdiButtonPrimary'}
                size="sm"
                onClick={() => {
                  handleClose()
                  onConfirmSuccess()
                }}
                marginLeft={3} />

            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

