import { useCallback, useEffect, useRef, useState } from "react";
import { ProsemirrorNodes, WebsocketEvents } from "@verdi/shared-constants";
import { documentSubscriptionsMap } from "./documentSusbcriptionMap";
import { useFetchSteps } from "./useFetchSteps";
import { useWebSocket } from "../useWebSocket";
import { useUrlParams } from "../../utility-hooks/useUrlParams";
import { getLocalIsoDate } from "../../utility-hooks/jsDateHelpers";

export type ClientSideOpenAIParams =
  WebsocketEvents.ServerEvents.RequestAIEdit["args"]["openAIParams"];

const IS_DEV_MODE =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

type ClientEvent = {
  eventPayload?: WebsocketEvents.ClientEvents.ClientEventType;
};

export type StepPayload = Pick<
  WebsocketEvents.ClientEvents.DocumentSteps["payload"],
  "documentVersion" | "stepData"
>;
export type NewStepCallback = (newSteps: StepPayload) => void;
type StepArg =
  WebsocketEvents.ServerEvents.ReceiveDocumentSteps["args"]["steps"][number];

export type SendSteps = (
  steps: StepArg[],
  documentVersion: number,
  clientId: string
) => void;

export const useDocumentSubscription = (
  clientId: string,
  documentId?: string,
  tellMessageToUser?: (message: string) => void
) => {
  const [onNewStepCallbacks, setOnNewStepCallbacks] = useState<
    NewStepCallback[]
  >([]);
  const [mustRefreshMessage, setMustRefreshMessage] = useState<string>();
  const [isLockedForAiEditing, setIsLockedForAiEditing] = useState(false);
  const [initialDoc, setInitialDoc] = useState<{
    version: number;
    body: ProsemirrorNodes.TipTapDocument;
  }>();
  const fetchSteps = useFetchSteps();
  const { aiFlow } = useUrlParams();

  // https://stackoverflow.com/a/71982736
  // Ref used to ignore the first load so we don't load twice
  const ignoreTheInitialMountRef = useRef(IS_DEV_MODE);

  const filter = useCallback(
    (payload: MessageEvent<any>) => {
      const event = payload as ClientEvent;
      if (event.eventPayload && "documentId" in event.eventPayload) {
        return event.eventPayload.documentId === documentId;
      }
      // TODO: allow this to filter as false, but now we need to get clientId, for example (inside useWebSocket)
      return true;
    },
    [documentId]
  );

  const onNewSteps: NewStepCallback = (newStepPayload) => {
    onNewStepCallbacks.forEach((callback) => {
      callback(newStepPayload);
    });
  };

  const setDocumentStepsFromGraphQL = useCallback(
    async (
      documentId: string,
      fromStepNumber: number,
      toStepNumber: number,
      documentVersion: number,
      documentBody?: ProsemirrorNodes.TipTapDocument | undefined
    ) => {
      const response = await fetchSteps(
        documentId,
        fromStepNumber,
        toStepNumber
      );
      const stepData: StepPayload["stepData"] =
        response.documentStepConnection.edges.map((edge) => ({
          clientId: edge.node.clientId,
          step: JSON.parse(edge.node.body),
          userId: edge.node.user!.id,
        }));
      const payload: StepPayload = {
        documentVersion,
        stepData,
      };
      if (documentBody) {
        await setInitialDoc({
          version: documentVersion,
          body: documentBody,
        });
      }
      onNewSteps(payload);
    },
    [onNewSteps, setInitialDoc, fetchSteps]
  );

  const processWebsocketMessage = useCallback(
    async (message: ClientEvent) => {
      if (!message || !message.eventPayload) return;
      // only respond to messages for this document
      if (
        "documentId" in message.eventPayload &&
        message.eventPayload.documentId === documentId
      )
        return;

      switch (message.eventPayload?.eventName) {
        case "DOCUMENT_SUBSCRIBER_LIST":
          setSubscriberIds(message.eventPayload.payload.clientIds);
          break;

        case "DOCUMENT_STEPS":
          onNewSteps(message.eventPayload.payload);
          break;

        case "PAGE_REFRESH_REQUIRED":
          setMustRefreshMessage(message.eventPayload.payload.message);
          break;

        case "GET_STEPS_NOT_FROM_WEBSOCKET": {
          const {
            fromStepNumber,
            toStepNumber,
            documentId: serverDocumentId,
            documentVersion,
            documentBody,
          } = message.eventPayload.payload;
          if (serverDocumentId !== documentId) {
            throw new Error(`Document Ids should match!`);
          }
          console.warn(
            "Payload too big for websocket, now fetching from graphql"
          );
          await setDocumentStepsFromGraphQL(
            documentId,
            fromStepNumber,
            toStepNumber,
            documentVersion,
            documentBody
          );
          break;
        }

        case "DOCUMENT_IS_NO_LONGER_LOCKED_FOR_AI_EDITING":
          console.log("DOCUMENT_IS_NO_LONGER_LOCKED_FOR_AI_EDITING");
          setIsLockedForAiEditing(false);
          tellMessageToUser?.("Edit complete");

          break;

        default:
          break;
      }
    },
    [documentId, setMustRefreshMessage, onNewSteps, setMustRefreshMessage]
  );

  const { sendMessage, lastMessageSentAt } = useWebSocket({
    onMessage: processWebsocketMessage,
  });

  const documentIdRef = useRef(documentId);
  if (documentIdRef.current !== documentId)
    // this is because the filtering on the above websocket.
    throw new Error(
      `Changing documentIds in a subscription hook is not supported old:${documentIdRef.current} new:${documentId}`
    );

  const [subscriberIds, setSubscriberIds] = useState([] as string[]);

  const sendSubscribeMessage = useCallback(
    (documentId: string) => {
      sendMessage({
        eventName: "SUBSCRIBE_TO_DOCUMENTS",
        args: {
          documentIds: [documentId],
          clientLocalDate: getLocalIsoDate(),
        },
      });
    },
    [documentId]
  );

  const sendUnsubscribeMessage = useCallback(
    (documentId: string) => {
      sendMessage({
        eventName: "UNSUBSCRIBE_FROM_DOCUMENTS",
        args: {
          documentIds: [documentId],
        },
      });
    },
    [documentId]
  );

  useEffect(() => {
    if (ignoreTheInitialMountRef.current) {
      ignoreTheInitialMountRef.current = false;
      return;
    }
    if (!documentId) return;

    let subscriptionCallbacks = documentSubscriptionsMap[documentId];
    if (!subscriptionCallbacks) {
      subscriptionCallbacks = 0;
      sendSubscribeMessage(documentId);
    }
    documentSubscriptionsMap[documentId] = subscriptionCallbacks + 1;
    return () => {
      if (!documentId) return;
      if (!documentSubscriptionsMap[documentId]) return;
      documentSubscriptionsMap[documentId] -= 1;
      if (!documentSubscriptionsMap[documentId]) {
        delete documentSubscriptionsMap[documentId];
        sendUnsubscribeMessage(documentId);
      }
    };
  }, [documentId]);

  const sendSteps: SendSteps = useCallback(
    (steps: StepArg[], documentVersion: number, clientId: string) => {
      if (!documentId) return;
      const event: WebsocketEvents.ServerEvents.ReceiveDocumentSteps = {
        eventName: "RECEIVE_DOCUMENT_STEPS",
        args: {
          documentId,
          steps,
          documentVersion,
          clientId,
        },
      };
      sendMessage(event);
    },
    [sendMessage, documentId, clientId]
  );

  const requestStepsSinceVersion = useCallback(
    (documentVersion: number) => {
      if (!documentId) return;
      const event: WebsocketEvents.ServerEvents.SendDocumentStepsSinceVersion =
      {
        eventName: "SEND_DOCUMENT_STEPS_SINCE_VERSION",
        args: {
          documentId,
          documentVersion,
        },
      };
      sendMessage(event);
    },
    [sendMessage, documentId]
  );

  const makeAIEditRequest = useCallback(
    (
      documentVersion: number,
      prompt: string,
      range: { from: number; to: number },
      openAIParams?: ClientSideOpenAIParams,
      sectionTitle?: string,
      isInline?: boolean,
      textToAddBefore?: string
    ) => {
      if (!documentId) return;
      const event: WebsocketEvents.ServerEvents.RequestAIEdit = {
        eventName: "REQUEST_AI_EDIT",
        args: {
          documentId,
          documentVersion,
          prompt,
          range,
          openAIParams,
          sectionTitle,
          isInline,
          clientId,
          clientLocalDate: getLocalIsoDate(),
          textToAddBefore,
        },
      };

      console.log(event);
      setIsLockedForAiEditing(true);
      try {
        sendMessage(event);
      } catch (_e) {
        setIsLockedForAiEditing(false);
      }
    },
    [sendMessage, documentId, clientId]
  );

  const makeAIEditRequestForBlock = useCallback(
    (
      documentVersion: number,
      documentAiPromptId: string,
      range: { from: number; to: number }
    ) => {
      if (!documentId) return;
      const event: WebsocketEvents.ServerEvents.RequestEditForDocumentAIPrompt =
      {
        eventName: "REQUEST_EDIT_FOR_DOCUMENT_AI_PROMPT",
        args: {
          documentId,
          documentVersion,
          range,
          documentAiPromptId,
          includeExternalContext: aiFlow,
          clientId,
          clientLocalDate: getLocalIsoDate(),
        },
      };

      console.log("sending event ", event);
      setIsLockedForAiEditing(true);
      try {
        sendMessage(event);
      } catch (_e) {
        setIsLockedForAiEditing(false);
      }
    },
    [sendMessage, documentId, clientId]
  );

  const ensureWebsocketIsNotStale = useCallback((documentVersion: number, clientId: string) => {
    console.log("ensureWebsocketIsNotStale() ", { documentVersion, clientId, lastMessageSentAt })
    if (!lastMessageSentAt) {
      console.info("ensureWebsocketIsNotStale: lastMessageSentAt is not set")
      return
    }
    const timeSinceLastMessage = Date.now() - lastMessageSentAt;
    const staleConnectionThreshold = 1000 * 60 * 5; // 5 minutes
    if (timeSinceLastMessage > staleConnectionThreshold) {
      console.info("ensureWebsocketIsNotStale: connection might be stale. ", { timeSinceLastMessage, staleConnectionThreshold })
      return true
    }

    // FOR current debugging, just send this up on demand, ignoring the timeSince
    console.info("ensureWebsocketIsNotStale: ", { timeSinceLastMessage, staleConnectionThreshold })
    sendSteps([], documentVersion, clientId)
  }, [lastMessageSentAt, sendSteps]);


  if (!documentId) return undefined;

  const registerOnStepCallback = (onNewStepCallback: NewStepCallback) => {
    setOnNewStepCallbacks([...onNewStepCallbacks, onNewStepCallback]);
  };

  const deregisterOnStepCallback = (onNewStepCallback: NewStepCallback) => {
    const callbackIndex = onNewStepCallbacks.indexOf(onNewStepCallback);
    if (callbackIndex !== -1) {
      const newArray = [
        ...onNewStepCallbacks.slice(0, callbackIndex),
        ...onNewStepCallbacks.slice(callbackIndex + 1),
      ];
      setOnNewStepCallbacks(newArray);
    }
  };


  return {
    mustRefreshMessage,
    subscriberIds,
    sendSteps,
    registerOnStepCallback,
    deregisterOnStepCallback,
    initialDoc,
    requestStepsSinceVersion,
    makeAIEditRequest,
    makeAIEditRequestForBlock,
    isLockedForAiEditing,
    ensureWebsocketIsNotStale,
  };
};

export type DocumentSubscription = ReturnType<typeof useDocumentSubscription>;
