import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"


const cmdType = GlobalCommandType.getSuggestionsForWhatIsNext

export const getSuggestionsForWhatIsNextCommandDefinition: GlobalCommandDefinition<GetSuggestionsForWhatIsNextCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: GetSuggestionsForWhatIsNextCommandArgs) => {
    const title = args.lifecycleState === "load-new" ? "get suggestions ..." : "Refresh suggestions ..."
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "debug", // "refresh",
        shouldKeepOpenOnRun: true,
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: GetSuggestionsForWhatIsNextCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("getSuggestionsForWhatIsNext: YAY")

    const generateSuggestions = services.servicesForCurrentDoc?.whatsNextSuggester?.generateDocumentLevelSuggestions
    if (!generateSuggestions) {
      console.warn("getSuggestionsForWhatIsNext: Could not find generateSuggestions")
      return
    }

    await generateSuggestions()

    onProcessingComplete(true)
  },
  triggerCommand: (args: GetSuggestionsForWhatIsNextCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type GetSuggestionsForWhatIsNextCommandArgs = {
  lifecycleState: "load-new" | "refresh"
}
