import React from "react";
import { AppServices, useRegisterAppServices } from "./useRegisterAppServices";


export const AppServicesContext = React.createContext<AppServices | null>(null)


type Props = {
  children: React.ReactNode
}
export const AppServicesWrapper = ({ children }: Props) => {


  const appServices = useRegisterAppServices()


  return (
    <AppServicesContext.Provider value={appServices}>
      {children}
    </AppServicesContext.Provider>
  )
}
