import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu";
import { FrameworksState } from "../../../../state/frameworksSlice";
import { getCurrentAppState } from "../../../../state/store";
import { setDocFrameworkNoneCommandDefinition } from "./setDocFrameworkNone";
import { setDocFrameworkCommandDefinition } from "./setDocFramework";
import { documentFrameworkState } from "../../../../state/documentFrameworksSlice";

const cmdType = GlobalCommandType.setDocFrameworkShowOptions


export const setDocFrameworkShowOptionsCommandDefinition: GlobalCommandDefinition<SetDocFrameworkShowOptionsArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetDocFrameworkShowOptionsArgs) => {
    const toReturn = {
      globalCommandType: cmdType,
      name: 'Select a framework ...',
      description: '',
      searchName: 'select a framework',
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetDocFrameworkShowOptionsArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { documentId, rect, shouldAutoApplyToDocBody } = args

    if (!documentId) {
      console.warn("setDocFrameworkShowOptions: No documentId")
      onProcessingComplete(false)
      return
    }

    const currentDocFrameworkIds = documentFrameworkState
      .getDocumentFrameworksForDoc(getCurrentAppState(), documentId)
      .map(f => f.frameworkId)

    const commands: VerdiCommand[] = []
    const noneCommand = setDocFrameworkNoneCommandDefinition.buildMenuItem({
      documentId,
      isCurrentValue: currentDocFrameworkIds.length === 0,
    })
    commands.push(noneCommand)

    const frameworks = FrameworksState.getAllFrameworksByKind(getCurrentAppState(), args.isForOpportunity)

    for (const framework of frameworks) {
      const isCurrentValue = currentDocFrameworkIds.includes(framework.id)
      const command = setDocFrameworkCommandDefinition.buildMenuItem({
        documentId,
        framework,
        isCurrentValue,
        shouldAutoApplyToDocBody,
      })
      commands.push(command)
    }

    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands,
      searchPlaceholderText: "Choose a framework ...",
      rectOfAnchorElement: rect,
      shouldAdjustRectForScrollOffset: false,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: SetDocFrameworkShowOptionsArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetDocFrameworkShowOptionsArgs = {
  documentId: string
  isForOpportunity: boolean
  rect?: DOMRect
  shouldAutoApplyToDocBody: boolean
}
