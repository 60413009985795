import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"


const cmdType = GlobalCommandType.addSectionWithAiShowMore

export const addSectionWithAiShowMoreCommandDefinition: GlobalCommandDefinition<AddSectionWithAiShowMoreCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddSectionWithAiShowMoreCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: "Add section ...",
        description: "More options",
        searchName: "add new section",
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: AddSectionWithAiShowMoreCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // IDEA: Format block as section (if needed), then run the line suggestion command ???

    args.doRefresh()
    onProcessingComplete(true)
  },
}

type AddSectionWithAiShowMoreCommandArgs = {
  doRefresh: () => Promise<void>
}
