import { ApiTypes } from "@verdi/shared-constants"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItem } from "../../utils/cleanupAiOutput"


/** Determines if an opportunity is a Problem, Solution, Outcome or other tag type related to an opportunity */
export const detectOpportunitySubTypeAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  description: string,
) => {

  const allContext: AllContextForAi = await getContextForAi()
  const prompt = getPrompt(description)


  try {
    const response = await makeAiChatRequest({
      prompt,
      messages: allContext.promptMessages,
      openAIParams: {
        n: 50,
        temperature: 0,
      }
    })

    const responseJson = response?.aiResponseJson as ApiTypes.AiResponseJson
    const rawString = responseJson.content || responseJson.contentOptions?.[0] || ""
    let rawAnswers = rawString.split("\n").map(l => cleanupAiOutputItem(l)).filter(l => l.trim().length > 0)
    console.log("detectOpportunitySubTypeAiCall: response = ", { rawAnswers, response })
    return rawAnswers[0] || ""

  } catch (error) {
    console.error("detectOpportunitySubType: error = ", error)
  }
  return ""
}


export const getPrompt = (
  description: string,
) => {

  const prompt = `
You are a product expert and whose job is to think up new opportunities for a company to pursue.

We are building out an opportunity solution tree. 
We are classifying each node in the tree as a specific type, as follows.
Outcome: A goal, objective or target end state that the other things in the tree will impact. Typically includes a metric.
Problem: A customer problem or paint point that we might want to solve.
Solution: A potential solution that addresses a problem, such as creating a new product or feature.
Assumption: An assumption that we are making about the product or market.
Other: Other types of nodes that don't fit into the above categories.

We have a node titled "${description}" 
that we are trying to classify as a specific type.


EXAMPLE 1:
If the node is titled "Increase revenue by 10%"
then a valid response would be
Outcome

EXAMPLE 2:
If the node is titled "Add a new onboarding tutorial"
then a valid response would be
Solution

EXAMPLE 3:
If the node is titled "Users are confused by the onboarding process"
then a valid response would be
Opportunity

EXAMPLE 4:
If the node is titled "Users will pay for the product"
then a valid response would be
Assumption

EXAMPLE 5:
If the node is titled "We just want to keep busy doing stuff"
then a valid response would be
Other
  `

  return prompt
}

