import { IconButton } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { Menu } from "@verdi/shared-constants"
import { FolderType, startingMenuDepth } from "./SideMenuSection"
import { MenuBuilderAdd } from "./menuBuilder/MenuBuilderAdd"
import { NewInterviewNotesModal } from "../documentRelation/interviewNotes/NewInterviewNotesModal"
import { VerdiIconAdd } from "../../components/icons/VerdiIcons"


type Props = {
  menuItem: Menu.MenuItemData
  menuDepth: number
  folderType: FolderType
  isExpanded: boolean
  addingInProgress: boolean
  onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  onAddQuickNoteButtonClick: (menuItem: Menu.MenuItemData) => void
}
export const SideMenuSectionAddButton = ({
  menuItem,
  menuDepth,
  folderType,
  isExpanded,
  addingInProgress,
  onAddOpportunityButtonClick,
  onAddQuickNoteButtonClick,
}: Props) => {

  if (menuDepth === startingMenuDepth) {
    switch (folderType) {

      case "opportunity":
        return renderAddButton({
          title: "Add Opportunity",
          menuItem,
          addingInProgress,
          isExpanded,
          onAddButtonClick: onAddOpportunityButtonClick
        })

      case "quickNote":
        return renderAddButton({
          title: "Add Quick Note",
          menuItem,
          addingInProgress,
          isExpanded,
          onAddButtonClick: onAddQuickNoteButtonClick
        })

      default:
        return (
          <div title="Add Pages" css={css` opacity: 0; `}>
            <MenuBuilderAdd
              parentDocId={menuDepth > 1 ? menuItem.id : undefined}
              opportunityId={menuItem.opportunityId}
            />
          </div>
        )
    }
  }

  if (folderType === "quickNote" && menuItem.type === "quickNote") {
    // Prevent adding child notes to quick notes, for now
    return null
  }

  if (menuItem.type === "guide" || menuItem.type === "interviewGuide") {
    return (
      <div css={css`
              opacity: 0;
            `}>
        <NewInterviewNotesModal
          opportunityId={menuItem.opportunityId || ""}
          guideId={menuItem.id}
          guideTitle={menuItem.title || ""}
          shouldNavigateToNewDoc={true}
        />
      </div>
    )
  }

  return (
    <div title="Add Pages"
      css={css` opacity: 0; `}>
      <MenuBuilderAdd
        parentDocId={menuDepth > 1 ? menuItem.id : undefined}
        opportunityId={menuItem.opportunityId}
      />
    </div>
  )
}

interface RootAddButtonProps extends Partial<Props> {
  title: string,
  menuItem: Menu.MenuItemData
  isExpanded: boolean
  addingInProgress: boolean
  onAddButtonClick: (menuItem: Menu.MenuItemData) => void
}

const renderAddButton = ({
  title,
  menuItem,
  addingInProgress,
  isExpanded,
  onAddButtonClick,
}: RootAddButtonProps) => {
  return (
    <IconButton
      aria-label="Add"
      icon={<VerdiIconAdd />}
      size="sm"
      isLoading={addingInProgress}
      variant="ghost"
      backgroundColor="transparent"
      marginRight={2}
      opacity={isExpanded ? 1 : 0}
      title={title}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (menuItem) {
          onAddButtonClick?.(menuItem)
        }
      }}
    />
  )
}

