// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { StyleFunctionProps, defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';
import { buttonTheme } from './theme/Button';
import { modalTheme } from './theme/Modal';
import { popoverTheme } from './theme/Popover';
import { tabsTheme } from './theme/Tabs';
import { switchTheme } from './theme/Switch';


// TODO: Move these to own file etc...


export const verdiChakraTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    verdiBlue: {
      50: "#E8E9FF",
      100: "#C5C7EF",
      200: "#9C9EE6",
      300: "#9C9EE6",
      400: "#686ED5",
      500: "#383DC9",
      600: "#292EAE",
      700: "#292EAE",
      800: "#232799",
      900: "#1A1E75",
    },
    greyCool: {
      100: "#E3E4EF",
      200: "#858697",
      550: "#45465B",
      600: "#38394B",
      650: "#3B3B4E",
      700: "#323340",
      800: "#252630",
      850: "#20212D",
      900: "#000000",
    },
    greyWarm: {
      25: "#FCFCFC",
      50: "#ECE",
      100: "#DFE1E4",
      200: "#D8D8D8",
      300: "#6C6F75",
      400: "#3D4148",
    },
    /** TODO: Move these all over to pure CSS variables  */
    /** var(--chakra-colors-surfaceBg-light); */
    mainStageBg: {
      light: "#FFF",
      dark: "#121217",
    },
    surfaceBg: {
      light: "#F9F9F9",
      dark: "#191A22",
    },
    surfaceBgSecondary: {
      light: "#f1f1f1",
      dark: "#292929",
    },
    surfaceOverlay: {
      light: "#FCFCFC",
      dark: "#252630",
    },
    surfaceOverlayBorder: {
      light: "#6C6F75",
      dark: "#858697",
    },
    surfaceBorder: {
      light: "#DFE1E4",
      dark: "#38394B",
    },
    surfaceBorderActive: {
      light: "#d3d4d5",
      dark: "#6f7080",
    },
    surfaceSelected: {
      light: "#ECECED",
      dark: "#323340",
    },
    surfaceHotkey: {
      light: "#FEFFFE",
      dark: "#3B3B4E",
    },
    surfaceHotkeyBorder: {
      light: "#D8D8D8",
      dark: "#45465B",
    },
    textPrimary: {
      light: "#000000",
      dark: "#FEFFFE",
    },
    textSecondary: {
      light: "#3D4148",
      dark: "#E3E4EF",
    },
    textIconSecondary: {
      light: "#6C6F75",
      dark: "#858697",
    },
    itemActiveBg: {
      light: "#292EAE",
      dark: "#292EAE",
    },
    itemSelectedBg: {
      light: "#d0d2fd",
      dark: "#323340",
    },
    itemCompleted: {
      light: "#25855A",
      dark: "#25855A",
    },
    placeholderTextColor: {
      light: "var(--placeholder-text-color)",
      dark: "var(--placeholder-text-color)",
    },
    verdiSubmit: {
      50: "#E7E5FF", // light mode. button:hover - ghost
      100: "#BDB8FF", // light mode active (mouse down) - ghost
      200: "#5A4CFF", // dark mode. button solid + outline (bordery thing)
      300: "#695CFF", // dark mode. button:hover
      400: "#3E2EFF", // dark mode. button:active
      500: "#0385FC", // light mode. button solid
      600: "#026ACA", // light mode. button:hover - solid
      700: "#025097", // light mode. button:active
      800: "#013565",
      900: "#011B32"
    },
    verdiAiAction: {
      "50": "#F2E5FF",
      "100": "#DAB8FF",
      "200": "#C28AFF",
      "300": "#AB5CFF",
      "400": "#932EFF",
      "500": "#7B00FF",
      "600": "#6200CC",
      "700": "#4A0099",
      "800": "#310066",
      "900": "#190033"
    },
    verdiUserAction: { // Primary
      "50": "#E5F4FF",
      "100": "#B8E1FF",
      "200": "#0094FF", // "200": "#8ACEFF",
      "300": "#5CBBFF",
      "400": "#2EA7FF",
      "500": "#0094FF",
      "600": "#0076CC",
      "700": "#005999",
      "800": "#003B66",
      "900": "#001E33"
    },
    verdiButtonPrimary: {
      "50": "#6A6FDC", // light mode hover - ghost
      "100": "#B5B7EE", // light mode active (mouse down) - ghost
      "200": "#383DC9",
      "300": "#292EAE",
      "400": "#1D217C",
      "500": "#3F45D2",
      "600": "#7276ce",
      "700": "#B5B7EE",
      "800": "cyan",
      "900": "magenta"
    },
    verdiButtonSecondary: {
      "50": "#F2E5FF",// light mode. button:hover - ghost
      "100": "#d5b8f2",// light mode. active (mouse down) - ghost
      "200": "#66677385",// dark mode. button solid + outline (bordery thing)
      "300": "#1D217C",// dark mode. button:hover
      "400": "#171A64",// dark mode. button:active
      "500": "#EDEDED",// light mode. button solid
      "600": "#CDCFF3",// light mode. button:hover - solid
      "700": "#B5B7EE",// light mode. button:active
      "800": "cyan",
      "900": "magenta"
    },
    verdiButtonTertiary: {
      50: "#6A6FDC33",  // light mode. button:hover - ghost
      100: "#B5B7EE33", // light mode. active (mouse down) - ghost
      200: "transparent", // dark mode. button solid + outline (bordery thing)
      300: "#292EAE66", // dark mode. button:hover
      400: "#1D217C66", // dark mode. button:active
      500: "transparent", // light mode. button solid
      600: "transparent", // light mode. button:hover - solid
      700: "#B5B7EE33", // light mode. button:active
      800: "#013565",
      900: "#011B32"
    },
    verdiButtonSecondaryHighlighted: {
      "50": "red",
      "100": "#d5b8f2",
      "200": "#171A64",
      "300": "#0C0D32",
      "400": "#171A64",
      "500": "#CDCFF3",
      "600": "#CDCFF3",
      "700": "#B5B7EE",
      "800": "cyan",
      "900": "magenta"
    },
  },

  styles: {
    global: {
      body: {
        fontSize: 16,
        bg: mode('#FFFFFF', '#0A0A0A'),
        color: mode('#333', '#FFF'),
      },
      h1: {
        fontSize: "1.6rem",
        fontWeight: "700"
      },
      h2: {
        fontSize: "1.2rem",
        fontWeight: "700"
      },
      h3: {
        fontSize: "1.1rem",
        textTransform: 'uppercase',
        fontWeight: "700",
        opacity: 0.7,
        letterSpacing: '0.1rem',
        fontFamily: "var(--chakra-fonts-heading)",
      },
      h6: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      ul: {
        marginLeft: 5,
      },
      ol: {
        marginLeft: 5,
      },
      'div[role=main]': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      // styles for the `a`
      a: {
        color: mode('#686ED5', '#686ED5'),
        _hover: {
          opacity: 0.8,
          textDecoration: "underline",
        },
      },
      label: {
        fontweight: 'bold',
        fontSize: 'sm',
      },
      textarea: {
        _placeholder: {
          fontStyle: "italic",
          color: "placeholderTextColor"
        }
      }
    },
  },
  components: {
    Divider: {
      // style object for base or default style
      baseStyle: {
        border: "1px solid",
        color: mode("#cbd5e0", "#424242"),
        marginBottom: 1,
        opacity: 1,
      },
    },
    Switch: switchTheme,
    Spinner: {
      baseStyle: {
        opacity: 0.3,
      },
    },
    Button: buttonTheme,
    Modal: modalTheme,
    Popover: popoverTheme,
    Tabs: tabsTheme,
    // styles for different sizes ("sm", "md", "lg")
    // sizes: {},
    // // styles for different visual variants ("outline", "solid")
    // variants: {},
    // // default values for 'size', 'variant' and 'colorScheme'
    // defaultProps: {
    //   size: '',
    //   variant: '',
    //   colorScheme: '',
    // },
    // Textarea: {
    //   baseStyle: {
    //     backgroundColor: 'red', // does not work
    //     textTransform: 'uppercase', // but this works ???
    //   },
    // },
  },

})


