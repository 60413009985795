import { useCallback } from "react"
import { AutoResizingInputText } from "../../../forms/AutoResizingInputText"
import LoadingSpinner from "../../../LoadingSpinner"
import { css } from "@emotion/react"


type Props = {
  searchText: string
  setSearchText: (val: string) => void
  handleOnKeyDown: (event: KeyboardEvent) => void
  isLoading?: boolean
}
/** The default input form element for a ComboBox. */
export const ComboBoxInput = ({
  searchText,
  setSearchText,
  handleOnKeyDown,
  isLoading,
}: Props) => {


  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    handleOnKeyDown((event as any) as KeyboardEvent)
    if (event.key === "Enter") {
      event.preventDefault() // TODO: prevent multi line input
      return true
    }
  }, [handleOnKeyDown])


  return (
    <div css={css`
      position: relative;
    `}>
      <AutoResizingInputText
        onChange={(evt) => setSearchText(evt.target.value)}
        value={searchText}
        onKeyDown={onKeyDown}
        noOfLines={1}
        disabled={isLoading}
      />
      {isLoading &&
        <div css={css`
          position: absolute;
          right: 10px;
          top: 10px;
        `}>
          <LoadingSpinner
            size="xs"
          />
        </div>
      }
    </div>
  )
}
