import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { showConfirmDialogCommandDefinition } from "../utils/showConfirmDialog"
import { getCurrentAppState } from "../../../../state/store"
import { navigateToAddAssumptionCommandDefinition } from "../navigateToAddAssumption"
import { autoDraftFullDocCommandDefinition } from "./autoDraftFullDoc"
import { documentFrameworkState } from "../../../../state/documentFrameworksSlice"
import { setDocFrameworkShowOptionsCommandDefinition } from "../framework/setDocFrameworkShowOptions"


const cmdType = GlobalCommandType.autoDraftFullDocConfirm

/** auto drafts the document but asks the user to fill in any gaps first */
export const autoDraftFullDocConfirmCommandDefinition: GlobalCommandDefinition<AutoDraftFullDocConfirmCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AutoDraftFullDocConfirmCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: `Auto-draft entire document ...`,
        description: '',
        searchName: 'auto draft entire document',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: AutoDraftFullDocConfirmCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const currentDoc = services.servicesForCurrentDoc?.document
    if (!currentDoc) {
      services.toast.showError("Could not auto draft at this time. Please try again later.")
      console.warn("AutoDraftFullDocConfirm: Could not find currentDoc")
      return
    }
    const opportunityContext = await services.getOpportunityContextForAi()
    const isForOpportunity = opportunityContext?.opportunityMainDocId === currentDoc.id


    // Check for a framework
    const currentDocFrameworks = documentFrameworkState.getDocumentFrameworksForDoc(getCurrentAppState(), currentDoc.id)
    if (currentDocFrameworks.length === 0) {
      showConfirmDialogCommandDefinition.triggerCommand?.({
        heading: "Improve results?",
        dialogBodyText: `You will get better results if you choose a framework for this document.`,
        confirmButtonLabel: "Choose a framework",
        onConfirmSuccess: () => {
          setDocFrameworkShowOptionsCommandDefinition.triggerCommand?.({
            documentId: currentDoc.id,
            isForOpportunity,
            shouldAutoApplyToDocBody: true,
          })
        },
        isDestructiveAction: false,
        secondaryButtonOptions: {
          label: "Auto-draft now",
          onClick: () => {
            autoDraftFullDocCommandDefinition.triggerCommand?.({})
          }
        }
      })
      onProcessingComplete(true)
      return
    }


    // Check for assumptions
    const hasNoAssumptions = getCurrentAppState().aiContext.assumptions.length === 0

    if (opportunityContext?.opportunityMainDocId && hasNoAssumptions) {
      showConfirmDialogCommandDefinition.triggerCommand?.({
        heading: "Improve results?",
        dialogBodyText: `You will get better results if you answer some questions first.`,
        confirmButtonLabel: "Answer questions",
        onConfirmSuccess: () => {
          navigateToAddAssumptionCommandDefinition.triggerCommand?.({
            opportunityMainDocId: opportunityContext?.opportunityMainDocId,
          })

        },
        isDestructiveAction: false,
        secondaryButtonOptions: {
          label: "Auto-draft now",
          onClick: () => {
            autoDraftFullDocCommandDefinition.triggerCommand?.({})
          }
        }
      })
      onProcessingComplete(true)
      return
    }

    autoDraftFullDocCommandDefinition.triggerCommand?.({})
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: AutoDraftFullDocConfirmCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type AutoDraftFullDocConfirmCommandArgs = {

}
