import { CircularProgress, CircularProgressProps } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useEffect, useState } from "react"

type Props = CircularProgressProps & {
  showAfterMsDelay?: number
  label?: string
  size?: string | number
}

const LoadingSpinner = (props: Props) => {

  const { showAfterMsDelay, label, size, ...propsToSpread } = props

  if (size) {
    // How does Chakra not have these sizes built in?
    let newSize = size
    switch (size) {
      case "xs": newSize = 4; break;
      case "sm": newSize = 6; break;
      case "md": newSize = 8; break;
      case "lg": newSize = 10; break;
    }
    (propsToSpread as any).size = newSize
  }

  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    const delayShowing = setTimeout(() => {
      setIsVisible(true)
    }, props.showAfterMsDelay)

    return () => clearTimeout(delayShowing)
  }, [])

  return <div>
    {isVisible &&
      <div css={css`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
      `}>
        <CircularProgress
          isIndeterminate
          title="Loading"
          color="verdiButtonPrimary.50"
          {...propsToSpread}
        />
        {label &&
          <div css={css`
            font-style: italic;
            opacity: 0.7;
          `}>
            {label}
          </div>
        }
      </div>
    }
  </div>
}

export default LoadingSpinner
