import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { getWaysToTestAssumptionsPrompt } from "../prompts/assumptions/getWaysToTestAssumptionsPrompt"
import { makeAiChatRequest } from "../requests/AiApiRequests"
import { uniqueId } from "lodash"
import { DeRiskingStepOption, SuggestedActionType } from "../../screens/deRiskingSteps/DeRiskingStepOptions"
import { Data, DocumentSchema } from "@verdi/shared-constants"
import { VerdiCommand } from "../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { addDocAsActionCommandDefinition, addDocAsActionPrefix } from "../../components/commands/commandDefinitions/action/addDocAsAction"


export const getSuggestedActionCommandsForAssumptions = async (
  onCommandComplete: (commandId: string, suggestedTitleToRemove: string) => void,
  docContext: DocumentContextForAi,
  resultCount: number,
  includePrefix: boolean) => {

  const assumptions: string[] = []
  if (docContext.type === DocumentSchema.DocumentType.assumption
    && docContext.title) {
    assumptions.push(docContext.title)
  }

  docContext.relatedDocs.filter(r => r.type === Data.DocumentRelationType.assumptionOf)
    .forEach(a => {
      if (!a.fromDoc?.title) return
      assumptions.push(a.fromDoc.title)
    })

  const actions = await getSuggestedActionsForAssumptions(docContext, assumptions, resultCount)
  console.log("getWhatsNextForAssumption: actions", actions)

  const actionCommands: VerdiCommand[] = actions
    .map(a => {
      const commandId = uniqueId("suggestedAction-")
      return addDocAsActionCommandDefinition.buildMenuItem({
        action: a,
        currentDocId: docContext.id,
        conceptsToUse: [],
        prefix: includePrefix ? addDocAsActionPrefix : undefined,
        id: commandId,
        onCompleted: () => {
          onCommandComplete(commandId, a.title)
        },
        isSuggestionFromAi: true,
      })
    }
    )

  // TODO: Add Section commands?

  return actionCommands
}


export const getSuggestedActionsForAssumptions = async (
  docContext: DocumentContextForAi | undefined,
  assumptions: string[],
  resultCount: number,
) => {

  const prompt = getWaysToTestAssumptionsPrompt(docContext, assumptions, resultCount)
  console.log("detectActions: prompt = ", { prompt })

  try {
    const response = await makeAiChatRequest({
      prompt,
      messages: [],
    })
    console.log("detectActions: response = ", { response })
    const rawText = response?.aiResponseJson.content as string || ""
    const lines = rawText.split("\n")
    const toReturn: DeRiskingStepOption[] = []
    for (const line of lines) {
      const [type, title, description] = line.split("|")
      if (!type || !title) continue
      const suggestedActionType = type.trim() as SuggestedActionType
      const docType = suggestedActionType === SuggestedActionType.Interview
        ? DocumentSchema.DocumentType.interviewGuide
        : DocumentSchema.DocumentType.document

      const suggestedAction: DeRiskingStepOption = {
        id: uniqueId("temp-"),
        description: description?.trim(),
        documentTypeToCreate: docType,
        title: title.trim(),
        suggestedActionType,
      }
      toReturn.push(suggestedAction)
    }
    return toReturn

  } catch (error) {
    console.error("detectActions: error = ", error)
    return []
  }

}
