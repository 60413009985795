import { Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue } from '@chakra-ui/react'
import { css } from '@emotion/react'
import graphql from 'babel-plugin-relay/macro'
import { useCallback, useRef, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { MenuBuilderAddQuery } from './__generated__/MenuBuilderAddQuery.graphql'
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary'
import { ButtonSecondary } from '../../../components/buttons/ButtonSecondary'
import { NewDocumentButton } from '../../documents/add/NewDocumentButton'
import { DocumentSchema } from '@verdi/shared-constants'
import { addDocShowOptionsCommandDefinition } from '../../../components/commands/commandDefinitions/document/addDocShowOptions'
import { VerdiIconAdd } from '../../../components/icons/VerdiIcons'


const queryQL = graphql`
query MenuBuilderAddQuery {
  documentTemplateConnection(first:100) {
    edges {
      node {
        id
        title
        document {
          id
          title
          description
          type
        }
        isPublished
      }
    }
  }
}`


type Props = {
  parentDocId?: string
  opportunityId?: string
  buttonText?: string
}

export const MenuBuilderAdd = ({ parentDocId, opportunityId, buttonText }: Props) => {

  const query = useLazyLoadQuery<MenuBuilderAddQuery>(queryQL, {})
  const ref = useRef<HTMLDivElement>(null)

  const templates = query.documentTemplateConnection.edges
    .map(t => t.node)
    .filter(t => t.isPublished)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const onClose = () => {
    setModalIsOpen(false)
  }
  const isLightMode = useColorModeValue(true, false)


  const onAdd = useCallback(() => {
    const rect = ref.current?.getBoundingClientRect()
    if (!parentDocId) {
      console.warn("MenuBuilderAdd: No Parent Doc Id")
      return
    }
    addDocShowOptionsCommandDefinition.triggerCommand?.({
      parentDocId,
      rect
    })
  }, [parentDocId, ref])


  return (
    <div
      ref={ref}
    >

      {buttonText ?
        <ButtonSecondary
          label="Add Pages"
          onClick={onAdd}
          leftIcon={<VerdiIconAdd />}
        />
        :
        <IconButton
          aria-label='Add Pages'
          variant="ghost"
          size="sm"
          icon={<VerdiIconAdd />}
          onClick={onAdd}
        />
      }

      <Flex direction="column"
        alignItems="baseline"
        gap={1}
        width="100%"
        maxWidth={400}
      >

        <Modal
          isOpen={modalIsOpen}
          onClose={onClose}
          scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              css={css`
            border-bottom: 1px solid #ddd;
            `}
            >Add Pages</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div css={css`
                overflow-y: auto;
              `}>
                {
                  templates.map((template, i) =>
                    <div
                      key={i}
                      title="Click to add"
                    >
                      <NewDocumentButton
                        type={template.document.type as DocumentSchema.DocumentType}
                        buttonText={template.title || template.document.title || template.document.type || " ERROR "}
                        title={template.document.title}
                        description={template.document.description}
                        createFromDocId={template.document.id}
                        opportunityId={opportunityId}
                        parentDocId={parentDocId}
                      />
                    </div>
                  )
                }

              </div>

            </ModalBody>

            <ModalFooter
              css={css`
             border-top: 1px solid #ddd;
             `}>
              <ButtonPrimary
                label="Done"
                onClick={onClose}
                colorScheme="verdiButtonPrimary"
                color="white"
              />
            </ModalFooter>
          </ModalContent>
        </Modal>


      </Flex>

    </div>
  )
}
