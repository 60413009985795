import { Divider, useDisclosure } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { CompanyContextData } from "./useCompanyContext"
import { useThrottle } from "../../../utility-hooks/useThrottle"
import { FormInputText, formInputLabelWidth } from "../../../components/forms/FormInputText"
import { posthogCallback } from '../../../helpers/posthog';
import { css } from "@emotion/react"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { UrlScraperInput } from "../../../components/urlScraper/UrlScraperInput"
import { CompanyContextItemAdd } from "./CompanyContextItemAdd"
import { IconButtonRemove } from "../../../components/buttons/IconButtonRemove"
import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary"
import { ConfirmDialog } from "../../../components/forms/ConfirmDialog"
import { OnboardingManager } from "../../onboarding/useOnboardingManager"


type Props = {
  onboardingManager: OnboardingManager
}
export const CompanyContextEditor = ({
  onboardingManager,
}: Props) => {

  const { companyContextProvider } = useAppServices()
  const {
    getCompanyContext,
    updateCompanyContext,
    removeFromCompanyContext,
  } = companyContextProvider

  const [companyContext, setCompanyContext] = useState<CompanyContextData>({
  })

  const [shouldShowOtherFields, setShouldShowOtherFields] = useState(false)
  const [shouldShowAddItemField, setShouldShowAddItemField] = useState(false)
  const [hasLoadedCompanyContext, setHasLoadedCompanyContext] = useState(false)
  useEffect(() => {
    getCompanyContext()
      .then((fields) => {
        if (!fields || (!fields.websiteUrl && !fields.name)) {
          setShouldEmphasizeScrapeButton(true)
          return
        }
        setShouldShowOtherFields(true)
        setCompanyContext({
          ...fields
        })
      }).finally(() => {
        setHasLoadedCompanyContext(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [fieldCurrentlyBeingSaved, setFieldCurrentlyBeingSaved] = useState("")
  const [fieldToBeDeleted, setFieldToBeDeleted] = useState("")
  const debouncedUpdateCompanyContext = useThrottle(async (fieldName: string, value: string) => {
    await updateCompanyContext({ [fieldName]: value })
    setFieldCurrentlyBeingSaved("")
  }, 1000)
  const updateField = useCallback(async (fieldName: string, value: string) => {
    setFieldCurrentlyBeingSaved(fieldName)
    setCompanyContext((prev) => ({
      ...prev,
      [fieldName]: value
    }))
    await debouncedUpdateCompanyContext(fieldName, value)

  }, [debouncedUpdateCompanyContext])

  const confirmDeleteFieldDisclosure = useDisclosure()

  const removeField = useCallback((fieldName: string) => {
    setFieldToBeDeleted(fieldName)
    confirmDeleteFieldDisclosure.onOpen()
  }, [confirmDeleteFieldDisclosure])

  const removeFieldAfterConfirm = useCallback(async (fieldName: string) => {
    posthogCallback(undefined, 'deleteField', { flows: ['companyContext'] })
    setCompanyContext((prev) => {
      delete prev[fieldName]
      return { ...prev }
    })
    await removeFromCompanyContext({ [fieldName]: "" })
  }, [removeFromCompanyContext])


  const hasExistingValues = useMemo(() => {
    const keys = Object.keys(companyContext).filter(k => k !== "websiteUrl")
    if (keys.length > 0) return true
    return false
  }, [companyContext])


  const [shouldEmphasizeScrapeButton, setShouldEmphasizeScrapeButton] = useState(false)

  const onScrapeComplete = useCallback(async (wasSuccessful: boolean, results: any) => {
    console.log("Scrape complete: ", wasSuccessful, { results })
    setShouldEmphasizeScrapeButton(false)
    if (!wasSuccessful) return

    setCompanyContext(prev => {
      return {
        ...results,
      }
    })
    await updateCompanyContext({ ...results }, false)
    setShouldShowOtherFields(true)

  }, [updateCompanyContext])

  const otherFields = useMemo(() => {
    const nameField = companyContext.name
    const fields = Object.entries(companyContext).filter(([key, _]) => key !== "websiteUrl" && key !== "name").sort()
    if (nameField) {
      return [["name", nameField]].concat(fields)
    } else {
      return fields
    }
  }, [companyContext])


  const contextKeys = useMemo(() => {
    return Object.keys(companyContext)
  }, [companyContext])

  const addNewContextItem = useCallback((name: string, value: string) => {
    updateField(name, value)
  }, [updateField])


  useEffect(() => {
    onboardingManager.updateProgress("companyPageAt")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (!hasLoadedCompanyContext) return null

  return (
    <div>

      <UrlScraperInput
        placeholderText="Enter your company's marketing website URL here"
        hasExistingValues={hasExistingValues}
        onScrapeComplete={onScrapeComplete}
        onUpdateUrl={(value) => updateField("websiteUrl", value)}
        urlToScrapeInitialValue={companyContext.websiteUrl}
        shouldEmphasizeScrapeButton={shouldEmphasizeScrapeButton}
      />

      <Divider marginTop={4} marginBottom={4} />

      <div
        css={css`
          font-size:0.9rem;
          margin-bottom: 8px;
          @media (min-width: 800px) {
            margin-left: 111px;
          }
        `}
      >
        {!shouldShowOtherFields &&
          <ButtonSecondary
            label="I don’t have a website"
            onClick={() => {
              if (otherFields.length === 0) {
                updateField("name", "your company name here")
                updateField("Mission", "")
                updateField("Target Market", "")
              }
              setShouldShowOtherFields(true)
              setShouldShowAddItemField(true)
            }}
          />
        }

        {shouldShowOtherFields &&
          <p>
            Add / edit details about your company here
          </p>
        }

      </div>


      {shouldShowOtherFields && otherFields.map(([name, initialValue], key) => (
        <div
          key={key}
          css={css`
        display: flex;
        //align-items: top;
        div:first-of-type {
          flex-grow: 1;
        }
        & button {
          opacity: 0;
        }
        &:hover button {
          opacity: 1;
        }
      `}>
          <FormInputText
            label={convertCamelCaseToTitleCase(name)}
            value={initialValue}
            onChange={(value) => updateField(name, value)}
            onFocus={posthogCallback(undefined, 'fieldFocus', { field: name, flows: ['companyContext'] })}
          />
          <IconButtonRemove aria-label={"Delete Field"} onClick={() => { removeField(name) }} />
        </div>
      ))}

      {shouldShowOtherFields && !shouldShowAddItemField && (
        <ButtonSecondary
          css={css`
            @media (min-width: 800px) {
              margin-left: ${formInputLabelWidth}px;
            }
          `}
          label={"Add New Item"}
          onClick={() => { setShouldShowAddItemField(true) }}
        />
      )}

      {shouldShowOtherFields && shouldShowAddItemField && (
        <CompanyContextItemAdd
          id="1"
          existingFields={contextKeys}
          onDeleteClick={() => { setShouldShowAddItemField(false) }}
          onAddClick={(name, value) => addNewContextItem(name, value)}
        />
      )}


      <ConfirmDialog
        dialogBodyText="This will permanently remove this field from the company context. Are you sure you want to continue?"
        heading="Delete Field?"
        disclosure={confirmDeleteFieldDisclosure}
        onConfirmSuccess={() => { removeFieldAfterConfirm(fieldToBeDeleted) }}
        confirmButtonLabel="Continue"
        isDestructiveAction={true}
        onCancel={posthogCallback(undefined, 'deleteCancel', { flows: ['companyContext'] })}
      />

    </div>


  )
}

function convertCamelCaseToTitleCase(input: string): string {
  const inputWithSpaces = input.replace(/([A-Z])/g, " $1");
  const inputWithSpacesWithFirstCap = inputWithSpaces.charAt(0).toUpperCase() + inputWithSpaces.slice(1)
  return inputWithSpacesWithFirstCap
}
