import { Heading } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { documentListCss, headingStyle } from '../documentListStyles'
import { GuideConnection } from './GuideConnection'
import { GuideConnection_query$key } from './__generated__/GuideConnection_query.graphql'


type Props = {
  queryFragment: GuideConnection_query$key
}

export function GuideList(props: Props) {

  return (
    <div css={documentListCss}>
      <div
        css={css`
          ${headingStyle}
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Heading css={css`
          color: #999;
          padding-left: 40px;
        `}>
          Interviews
        </Heading>
      </div>
      <GuideConnection query={props.queryFragment} />
    </div>
  )
}
