import { DocSummaryOfNodes } from "../../screens/document/tiptapEditor/utils/getDocSummaryOfNodes"


/** The key for to listen to doc Updated events */
const DOC_UPDATED_EVENT = "verdiGlobalDocUpdated"

export const DocUpdatedEvent = {
  triggerDocUpdated: (summaryDiff: DocMetadataSummaryDiff) => {
    const event = new CustomEvent(DOC_UPDATED_EVENT, {
      detail: summaryDiff
    })
    window.dispatchEvent(event)
  },
  addEventListener: (listener: (evt: DocUpdatedCustomEvent) => void) => {
    window.addEventListener(DOC_UPDATED_EVENT, listener as EventListenerOrEventListenerObject)
  },
  removeEventListener: (listener: (evt: DocUpdatedCustomEvent) => void) => {
    window.removeEventListener(DOC_UPDATED_EVENT, listener as EventListenerOrEventListenerObject)
  }
}


export type DocUpdatedCustomEvent = CustomEvent<DocMetadataSummary> & {
  detail: DocMetadataSummaryDiff
}

export type DocMetadataSummaryDiff = {
  current: DocMetadataSummary
  previous?: DocMetadataSummary
}

export type DocMetadataSummary = DocSummaryOfNodes & {
  documentId: string
  bodyHasText: boolean
  lengthOfBody: number
  timeStamp: string
}
