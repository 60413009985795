import { PageLayoutSplitViewWrapper } from "../../../components/pageLayout/pageLayoutSplitViewWrapper";
import { OrganizationInvitationList } from "./OrganizationInvitationList";
import { OrganizationUserList } from "./OrganizationUserList";
import { OrganizationAddUserModal } from "./OrganizationAddUserModal";
import { useAppServices } from "../../../components/appServices/useAppServices";
import { useEffect } from "react";
import { PageHeading } from "../../../components/pageLayout/PageHeading";
import { SuspenseErrorBoundary } from "../../../components/SuspenseErrorBoundary";
import { SideMenu } from "../../opportunityDocument/SideMenu";
import { MainViewWrapper } from "../../../components/pageLayout/MainViewWrapper";
import { css } from "@emotion/react";
import { SectionWrapper } from "../../../components/pageLayout/SectionWrapper";
import { VerdiIconUsersManage } from "../../../components/icons/VerdiIcons";


type Props = {
  organizationId?: string
}
export default function OrganizationUsersPage(props: Props) {


  const appServices = useAppServices()
  const {
    pageLayoutState,
    loggedInUserProvider,
    menuStructureProvider,
    opportunityProvider,
  } = appServices


  useEffect(() => {
    pageLayoutState.setRightSideState("hidden")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <PageLayoutSplitViewWrapper
      appServices={appServices}
      mainMenu={
        <SuspenseErrorBoundary>
          <SideMenu
            onBeforeNavigate={pageLayoutState.onBeforeNavigate}
            menuStructureProvider={menuStructureProvider}
            opportunityProvider={opportunityProvider}
          />
        </SuspenseErrorBoundary>
      }
      mainContent={<div>
        <MainViewWrapper>

          <PageHeading
            title="Users"
            description="Manage who is a part of your organization."
            iconLeft={<VerdiIconUsersManage />}
          />


          <SectionWrapper>
            <h2>
              Team Members
            </h2>

            <OrganizationUserList
              currentUserId={loggedInUserProvider.loggedInUser?.id}
            />

          </SectionWrapper>


          <SectionWrapper>
            <h2>
              Invitations
            </h2>

            <OrganizationInvitationList />
            <div css={css`
              margin-top: 5px;
            `}>
              <OrganizationAddUserModal />
            </div>
          </SectionWrapper>

        </MainViewWrapper>
      </div>
      } />
  );
}
