import { Edge } from "@xyflow/react";
import { IdeaNodeWrapperData } from "./nodeTypes/idea/IdeaNode";
import { NodeTypeIds } from "./nodeTypes/NodeTypes";
import { generateEdgeId, generateNodeId } from "./utils/utils";
import { MindMapData } from "../../../state/MindMapSlice";
import { Tag, TagType } from "../../../state/TagsSlice";


/** Builds a skeleton starting point of a mind map */
export const buildStartingPoint = (description: string, primaryTag: Tag | undefined) => {

  const tagType = primaryTag?.tagType
  const outcomeNode = buildIdeaNode("Outcome", "Add an outcome ...", tagType === "Outcome" ? description : undefined)
  const problemNode1 = buildIdeaNode("Problem", "Add a Problem ...", tagType === "Problem" ? description : undefined)
  const problemNode2 = buildIdeaNode("Problem", "Add a Problem ...")
  const solutionNode1 = buildIdeaNode("Solution", "Add a Solution ...", tagType === "Solution" ? description : undefined)
  const solutionNode2 = buildIdeaNode("Solution", "Add a Solution ...")

  const nodes = [outcomeNode, problemNode1, problemNode2, solutionNode1, solutionNode2]


  const edges = [
    buildEdge(outcomeNode.id, problemNode1.id),
    buildEdge(outcomeNode.id, problemNode2.id),
    buildEdge(problemNode1.id, solutionNode1.id),
    buildEdge(problemNode1.id, solutionNode2.id),
  ]

  return {
    nodes,
    edges,
  } as MindMapData
}


const buildIdeaNode = (tagType: TagType, placeholderText: string, text?: string) => {
  return {
    id: generateNodeId(),
    type: NodeTypeIds.idea,
    data: {
      text: text || '', placeholderText,
      primaryTag: { title: tagType.toString(), tagType: tagType }
    },
    position: { x: 0, y: 0 },
  } as IdeaNodeWrapperData
}

const buildEdge = (sourceId: string, targetId: string) => {
  return {
    id: generateEdgeId(sourceId, targetId),
    source: sourceId,
    target: targetId,
  } as Edge
}
