import { css } from "@emotion/react"
import { OpportunityTitleInput } from "../../opportunities/OpportunityTitleInput"


type Props = {
  setOpportunityDescription: React.Dispatch<React.SetStateAction<string>>
  opportunityDescription: string
  opportunityDescriptionInputRef: React.RefObject<HTMLTextAreaElement>
}
export const OnboardingStepOpportunity = ({
  opportunityDescription,
  setOpportunityDescription,
  opportunityDescriptionInputRef,
}: Props) => {

  opportunityDescriptionInputRef.current?.focus()

  return (
    <div css={css`
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    `}>
      <OpportunityTitleInput
        isDisabled={false}
        title={opportunityDescription}
        onTitleChange={setOpportunityDescription}
        opportunityDescriptionInputRef={opportunityDescriptionInputRef}
      />

    </div>

  )
}


