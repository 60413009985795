import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'
import { Data } from '@verdi/shared-constants'
import { AssumptionSummary } from '../screens/assumptions/useAssumptionsProvider'


interface AiContextState {
  items: Data.DocumentRelationDto[]
  assumptions: AssumptionSummary[]
}

const initialState: AiContextState = {
  items: [],
  assumptions: []
}

export const aiContextSlice = createSlice({
  name: 'aiContext',
  initialState,
  reducers: {
    setAssumptions: (state, action: PayloadAction<AssumptionSummary[]>) => {
      state.assumptions = action.payload
    }
  },
})

export const { setAssumptions } = aiContextSlice.actions

export default aiContextSlice.reducer