import { useCallback, useState } from "react"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { cleanupAiOutputAsStrings } from "../utils/cleanupAiOutput"
import { makeAiChatRequest } from "../requests/AiApiRequests"


type Props = {
  getDocumentContextForAi: () => DocumentContextForAi | undefined,
  getRawPrompt: (additionalContext: string) => string,
}
/** Given a raw ai prompt, will POST to the AI and return an array of suggestions (string) 
 * 
 * Suggested use: Wrap this in another hook to provide reusable base functionality. 
 * 
 * See "useWorkflowSuggester.tsx" for an example.
 * 
 * For returning a KeyValuePair list (instead of a string list), consider using "useConceptGenerator.tsx"
*/
export const useAiSuggester = ({
  getDocumentContextForAi,
  getRawPrompt,
}: Props) => {


  const [isGenerating, setIsGenerating] = useState(false)
  const [suggestedItems, setSuggestedItems] = useState<string[]>([])

  const generateSuggestionsForCurrentDoc = useCallback(async () => {
    console.log("generateSuggestionsForCurrentDoc")
    setIsGenerating(true)

    const documentContext = getDocumentContextForAi()
    if (!documentContext || !documentContext?.body) {
      console.warn("no doc context detected. Will not make any suggestions.")
      setIsGenerating(false)
      return []
    }
    console.log("generateSuggestionsForCurrentDoc: documentContext = ", documentContext?.body)

    return await generateSuggestions(documentContext.body)

  }, [getDocumentContextForAi])


  const generateSuggestions = useCallback(async (additionalContext: string) => {
    console.log("generateSuggestions: additionalContext = ", additionalContext)
    setIsGenerating(true)
    const prompt = getRawPrompt(additionalContext)

    try {
      const response = await makeAiChatRequest({
        prompt,
        messages: [],
      })

      console.log("detectSuggestions: response = ", response)
      const rawText = response?.aiResponseJson.content as string || ""
      const cleanSuggestions = cleanupAiOutputAsStrings(rawText)

      setSuggestedItems(() => [...cleanSuggestions])
      setIsGenerating(false)
      return cleanSuggestions

    } catch (error) {
      console.error("detectInsights: error = ", error)
      setIsGenerating(false)
      return []
    }

  }, [getDocumentContextForAi])


  const updateSuggestedItem = useCallback((oldValue: string, newValue: string) => {
    setSuggestedItems(prev => prev.map(a => a === oldValue ? newValue : a))
  }, [setSuggestedItems])

  const removeSuggestedItem = useCallback((itemName: string) => {
    setSuggestedItems(prev => prev.filter(a => a !== itemName))

  }, [setSuggestedItems])

  const addItem = useCallback((itemName: string) => {
    setSuggestedItems(prev => [...prev, itemName])
  }, [setSuggestedItems])


  return {
    generateSuggestionsForCurrentDoc,
    generateSuggestions,
    isGeneratingSuggestions: isGenerating,
    suggestedItems,
    updateSuggestedItem,
    removeSuggestedItem,
    addItem,
  }
}
export type AiSuggestionsGenerator = ReturnType<typeof useAiSuggester>
