import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CompanyContextCommon } from '@verdi/shared-constants'


interface ICompanyContextState {
  companyFields: CompanyContextCommon.MutableFields | undefined
}

const initialState: ICompanyContextState = {
  companyFields: undefined,
}

export const companyContextSlice = createSlice({
  name: 'companyContext',
  initialState,
  reducers: {
    setFields: (state, action: PayloadAction<CompanyContextCommon.MutableFields | undefined>) => {
      state.companyFields = action.payload
    },
  },
  selectors: {
    getFields: (state: ICompanyContextState) => state.companyFields,
  }
})

export const CompanyContextState = {
  ...companyContextSlice.actions,
  ...companyContextSlice.selectors,
}

export default companyContextSlice.reducer
