import { ApiTypes } from "@verdi/shared-constants"
import { BrainStormNode, BrainstormSnapshotForAi } from "../../../components/mindMap/internals/getSnapshotForAi"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsKeyValuePairs } from "../../utils/cleanupAiOutput"
import { TagType } from "../../../state/TagsSlice"


/** Asks the AI for a few nodes to add to the given graph snapshot */
export const getSuggestedNodesInGraphAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  snapshot: BrainstormSnapshotForAi,
  targetNode: BrainStormNode,
  tagTypes: TagType[],
) => {

  const allContext: AllContextForAi = await getContextForAi()
  const prompt = getPrompt(snapshot, targetNode, tagTypes)


  try {
    const response = await makeAiChatRequest({
      prompt,
      messages: allContext.promptMessages,
      openAIParams: {
        n: 2,
      }
    })

    const responseJson = response?.aiResponseJson as ApiTypes.AiResponseJson
    const rawString = responseJson.content || responseJson.contentOptions?.[0] || ""
    let rawAnswers = cleanupAiOutputAsKeyValuePairs(rawString, "|")
    console.log("getSuggestedNodesInGraphAiCall: rawAnswers = ", { rawAnswers, rawString, responseJson })

    return rawAnswers

  } catch (error) {
    console.error("detectAnswers: error = ", error)
  }
  return []
}


export const getPrompt = (
  snapshot: BrainstormSnapshotForAi,
  targetNode: BrainStormNode,
  tagTypes: TagType[],
) => {

  const typeInfo = buildTagTypeDescriptions(tagTypes)


  const prompt = `
You are a product expert and whose job is to think up new opportunities for a company to pursue.
Within each opportunity there are many different types of ideas that can be explored that we need to account for.

We have a graph of ideas that that we are using for brainstorming.
This graph can have the following different idea types of nodes in it:
${typeInfo.descriptions.join("\n")}

The graph contains the following data: 

Nodes:
${snapshot.nodes.map(nodeToDOTString).join("\n")}

Edges:
${snapshot.edges.map(e => `${e.source} -> ${e.target}`).join("\n")}

The CURRENT NODE we are targeting is:
${nodeToDOTString(targetNode)}

INSTRUCTIONS:
Return 2 very concise ideas.
that should be added as child nodes to the CURRENT NODE.
Nodes can have different idea types, such as Outcome, Problem, Solution, Assumption.
Only return the concise ideas in your output, each idea on its own line, 
similar to the example ideas listed below.
The ideas should be written in sentence fragments.

RESPONSE FORMAT:
Return each idea on its own line. Each line should be in the following format:
ideaType|Concise idea text

Example ideas:
${typeInfo.examples.join("\n")}
  `

  return prompt
}


const nodeToDOTString = (node: BrainStormNode) => {
  const parts: string[] = []
  if (node.title) parts.push(`label="${node.title}"`)
  const ideaType = node.tags?.[0]?.tagType || node.tags?.[0]?.title
  if (ideaType) parts.push(`ideaType="${ideaType}"`)
  return `${node.id} [${parts.join(", ")}]`

}


/** @argument tagTypes - if none specified will include all types */
const buildTagTypeDescriptions = (tagTypes: TagType[]) => {

  const parts = tagTypes.length > 0
    ? promptPartsPerTagType.filter(p => p.tagType && tagTypes.includes(p.tagType))
    : promptPartsPerTagType

  const descriptions = parts.map(t => {
    if (!parts) return ""
    return `${t.tagType}: ${t.specificInstruction}`
  })

  const examples = parts.map(part => {
    if (!parts) return ""
    return part.examples.map(example => `${part.tagType}|${example}`).join("\n")
  })

  return {
    descriptions,
    examples
  }
}


type PromptPartsPerIdeaType = {
  tagType?: TagType
  specificInstruction: string
  examples: string[]
}


const promptPartsPerTagType: PromptPartsPerIdeaType[] = [
  {
    tagType: "Outcome",
    specificInstruction: "Meaningful outcomes that could be directly tied to the CURRENT NODE",
    examples: [
      "Increase revenue by 10%",
      "Reduce churn by 2%",
      "Increase customer retention",
    ]
  },
  {
    tagType: "Problem",
    specificInstruction: "Aka Problems to solve. Problem statements that likely exist and are critical to understand related to the CURRENT NODE",
    examples: [
      "Users are confused by the onboarding process",
      "Users are not able to find the product they are looking for",
      "Users are not able to complete the checkout process",
    ]
  },
  {
    tagType: "Solution",
    specificInstruction: "Potential solutions that may help solve the problem",
    examples: [
      "Add a new onboarding tutorial",
      "Add a search bar to the site",
      "Add a one-click checkout button",
    ]
  },
  {
    tagType: "Assumption",
    specificInstruction: "Assumptions that need to be validated in order to better understand the problem and solution space",
    examples: [
      "Users will pay for the product",
      "Users will find the product valuable",
      "Users will be able to use the product",
    ]
  },
]
