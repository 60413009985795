import { SnippetRow } from "./SnippetRow"
import { useUpdateSnippet } from "./useUpdateSnippet"
import { CreateSnippetInput } from "./__generated__/useCreateSnippetMutation.graphql"
import { Spinner } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useThrottle } from "../../utility-hooks/useThrottle"


type Props = {
  id: string,
  placeholderKey: string,
  textValue: string
}
export const EditSnippet = ({ id, placeholderKey, textValue }: Props) => {

  const onChange = useThrottle((input: CreateSnippetInput) => {
    makeTheUpdate(input)
  })

  const makeTheUpdate = (input: CreateSnippetInput) => {
    console.log("onChange ", input)
    const updates = {
      ...input,
      id
    }

    update(updates)
  }

  const [update, isSaving] = useUpdateSnippet()


  return (
    <div css={css`
      display: flex;
    `}>
      <SnippetRow
        placeholderKey={placeholderKey}
        textValue={textValue}
        onChange={onChange}
      />
      <div css={css`
        width: 70px;
      `}>
        {isSaving &&
          <Spinner size='sm' margin="12px" opacity={0.2} />
        }
      </div>
    </div>
  )

}
