import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { useReactNodeViewSnippet_query$key } from './__generated__/useReactNodeViewSnippet_query.graphql';
import { useReactNodeViewContext } from './ReactNodeViewContext';
import { useEffect, useMemo } from 'react';

const fragmentQL = graphql`
  fragment useReactNodeViewSnippet_query on Query {
     snippetConnection{
      edges{
        node{
          id
          placeholderKey
          textValue
        }
      }
     }
  }
`

type Props = {
  snippetId: string
}

export function useReactNodeViewSnippet({ snippetId }: Props) {
  const { queryKey } = useReactNodeViewContext()
  const query = useFragment(fragmentQL, queryKey as useReactNodeViewSnippet_query$key | null)

  const snippet = useMemo(() => {
    const snippetEdge = query?.snippetConnection.edges.find(({ node: snippet }) =>
      snippet.id === snippetId
    )
    return snippetEdge?.node
  }, [snippetId, query])

  return { snippet }
}