/**
 * @generated SignedSource<<c3e67ea78fe9d767f7a10eb27fc2bbad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateDocumentAiPromptInput = {
  documentId: string;
  dontDo?: string | null;
  instructionsForUser?: string | null;
  isDocContext?: boolean | null;
  notesForAdmin?: string | null;
  openApiParams?: OpenAIParamsInput | null;
  promptText: string;
};
export type OpenAIParamsInput = {
  best_of?: number | null;
  echo?: boolean | null;
  frequency_penalty?: number | null;
  logprobs?: number | null;
  max_tokens?: number | null;
  model?: string | null;
  n?: number | null;
  presence_penalty?: number | null;
  stop?: ReadonlyArray<string> | null;
  suffix?: string | null;
  temperature?: number | null;
  top_p?: number | null;
};
export type useCreateAiPromptMutation$variables = {
  input: CreateDocumentAiPromptInput;
};
export type useCreateAiPromptMutation$data = {
  readonly createDocumentAiPrompt: {
    readonly document: {
      readonly aiPrompts: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"EditDocumentContextTemplate_document">;
    };
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"useUpdateAiPromptMutation_documentAiPrompt">;
  };
};
export type useCreateAiPromptMutation$rawResponse = {
  readonly createDocumentAiPrompt: {
    readonly document: {
      readonly aiPrompts: ReadonlyArray<{
        readonly dontDo: string | null;
        readonly id: string;
        readonly isDocContext: boolean;
        readonly notesForAdmin: string | null;
        readonly openApiParams: {
          readonly best_of: number | null;
          readonly echo: boolean | null;
          readonly frequency_penalty: number | null;
          readonly logprobs: number | null;
          readonly max_tokens: number | null;
          readonly model: string | null;
          readonly n: number | null;
          readonly presence_penalty: number | null;
          readonly stop: ReadonlyArray<string> | null;
          readonly suffix: string | null;
          readonly temperature: number | null;
          readonly top_p: number | null;
        } | null;
        readonly promptText: string;
      }>;
      readonly id: string;
      readonly title: string | null;
    };
    readonly dontDo: string | null;
    readonly id: string;
    readonly notesForAdmin: string | null;
    readonly openApiParams: {
      readonly best_of: number | null;
      readonly echo: boolean | null;
      readonly frequency_penalty: number | null;
      readonly logprobs: number | null;
      readonly max_tokens: number | null;
      readonly model: string | null;
      readonly n: number | null;
      readonly presence_penalty: number | null;
      readonly stop: ReadonlyArray<string> | null;
      readonly suffix: string | null;
      readonly temperature: number | null;
      readonly top_p: number | null;
    } | null;
    readonly promptText: string;
  };
};
export type useCreateAiPromptMutation = {
  rawResponse: useCreateAiPromptMutation$rawResponse;
  response: useCreateAiPromptMutation$data;
  variables: useCreateAiPromptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promptText",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dontDo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notesForAdmin",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OpenAIParams",
  "kind": "LinkedField",
  "name": "openApiParams",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "best_of",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "echo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency_penalty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logprobs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "max_tokens",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "n",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presence_penalty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "temperature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "top_p",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateAiPromptMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DocumentAiPrompt",
        "kind": "LinkedField",
        "name": "createDocumentAiPrompt",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useUpdateAiPromptMutation_documentAiPrompt"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditDocumentContextTemplate_document"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentAiPrompt",
                "kind": "LinkedField",
                "name": "aiPrompts",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateAiPromptMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DocumentAiPrompt",
        "kind": "LinkedField",
        "name": "createDocumentAiPrompt",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentAiPrompt",
                "kind": "LinkedField",
                "name": "aiPrompts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDocContext",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bebc1801de8a693cdcf932e7c7536c3b",
    "id": null,
    "metadata": {},
    "name": "useCreateAiPromptMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateAiPromptMutation(\n  $input: CreateDocumentAiPromptInput!\n) {\n  createDocumentAiPrompt(input: $input) {\n    id\n    ...useUpdateAiPromptMutation_documentAiPrompt\n    document {\n      ...EditDocumentContextTemplate_document\n      aiPrompts {\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment AiPromptPreview_otherBlockLevelPrompts on DocumentAiPrompt {\n  dontDo\n}\n\nfragment EditDocumentContextTemplate_document on Document {\n  id\n  title\n  aiPrompts {\n    isDocContext\n    ...useUpdateAiPromptMutation_documentAiPrompt\n    ...AiPromptPreview_otherBlockLevelPrompts\n    id\n  }\n}\n\nfragment useUpdateAiPromptMutation_documentAiPrompt on DocumentAiPrompt {\n  id\n  promptText\n  dontDo\n  notesForAdmin\n  openApiParams {\n    best_of\n    echo\n    frequency_penalty\n    logprobs\n    max_tokens\n    model\n    n\n    presence_penalty\n    stop\n    suffix\n    temperature\n    top_p\n  }\n}\n"
  }
};
})();

(node as any).hash = "092aabfd61ed1a30fd55df907a0bc3b5";

export default node;
