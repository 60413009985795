

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface InternalState {
  isInAutoAiMode: boolean
  isDoingSomething: boolean
  messageToUser: string
}
const initialState: InternalState = {
  isInAutoAiMode: true,
  isDoingSomething: false,
  messageToUser: "",
}

export const autoAiModeSlice = createSlice({
  name: 'autoAiMode',
  initialState,
  reducers: {
    /** Auto triggers various AI calls when true */
    setIsInAutoAiMode: (state, action: PayloadAction<boolean>) => {
      state.isInAutoAiMode = action.payload
      localStorage.setItem('autoAiMode', action.payload ? "true" : "false")
    },
    loadFromLocalStorage: (state) => {
      const savedState = localStorage.getItem('autoAiMode')
      if (savedState === "false") {
        state.isInAutoAiMode = false
      }
    },
    setStatus: (state, action: PayloadAction<{ isDoingSomething: boolean, messageToUser?: string }>) => {
      state.isDoingSomething = action.payload.isDoingSomething
      state.messageToUser = action.payload.messageToUser || ""
    },
  },
  selectors: {
    /** True when the app is in "Auto" mode */
    getIsInAutoAiMode: (state) => state.isInAutoAiMode,
    /** True when the app is currently doing something */
    getIsDoingSomething: (state) => state.isDoingSomething,
    /** Message to show to the user when the app is doing something */
    getMessageToUser: (state) => state.messageToUser,
  }
})
export const AutoAiModeState = {
  ...autoAiModeSlice.actions,
  ...autoAiModeSlice.selectors,
}

export default autoAiModeSlice.reducer
