import { ApiTypes, Data } from "@verdi/shared-constants"
import { makeGetRequestJson } from "../../utility-hooks/fetchUtils"
import { setCurrentOrgPlan } from "../PlanSlice"
import { dispatch } from "../store"
import { DocRelationsState } from "../docRelationsSlice"
import { AssumptionsState } from "../assumptionsSlice"
import { loadingStatusState } from "../loadingStatusSlice"
import { CompanyContextState } from "../companyContextSlice"
import { OpportunitiesState } from "../opportunitiesSlice"
import { DocumentTasksState } from "../documentTasksSlice"
import { AutoAiModeState } from "../AutoAiModeSlice"
import { MindMapState } from "../MindMapSlice"
import { MindMapProvider } from "../MindMapProvider"
import { TagsProvider } from "../TagsProvider"


/** On app load, loads multiple data sets in parallel and updates local redux state. */
export const LoadInitialAppState = async () => {

  Promise.allSettled([
    makeGetRequestJson("MyOrganizationPlan"),
    makeGetRequestJson("opportunities"),
    makeGetRequestJson("documentRelations"),
    makeGetRequestJson("assumptions"),
    makeGetRequestJson("companyContext/current"),
    makeGetRequestJson("documentTasksForOrg"),
    makeGetRequestJson("MindMapsForOrg"),
    TagsProvider.loadAllTagsForOrg(),
    // ^^ Put more requests here

    // TODO: Consider batching these into multiple chunks, 
    // to keep the number of concurrent requests down

  ]).then((results) => {
    const [
      planResults,
      opportunityPartialsResults,
      docRelationsResults,
      assumptionsResults,
      companyContextResults,
      documentTasksResults,
      mindMapsResults,
      _tagsResults,
    ] = results

    const plan = (planResults as any).value as Data.OrganizationPlan.OrganizationPlanModel
    dispatch(setCurrentOrgPlan(plan))

    const opportunityPartials = (opportunityPartialsResults as any).value as Data.Opportunities.OpportunityModel[]
    dispatch(OpportunitiesState.setAllOpportunityPartials(opportunityPartials))
    dispatch(OpportunitiesState.setIsLoadingOpportunityPartials(false))

    const docRelations = (docRelationsResults as any).value as Data.DocumentRelationDto[]
    dispatch(DocRelationsState.setAll(docRelations))

    const assumptions = (assumptionsResults as any).value as Data.AssumptionModel[]
    dispatch(AssumptionsState.setAllAssumptions({ assumptions, loading: false }))

    const companyContextDto = (companyContextResults as any).value as ApiTypes.CompanyContextResponseBody
    const companyContext = companyContextDto?.fields?.jsonOutline || {}
    dispatch(CompanyContextState.setFields(companyContext))

    const documentTasksDto = (documentTasksResults as any).value as ApiTypes.DocumentTasksResponseBody
    const docTaskModels = documentTasksDto.tasks.map((dto) => DocumentTasksState.fromDto(dto))
    dispatch(DocumentTasksState.setAllTasks(docTaskModels || []))

    const mindMapDtos = (mindMapsResults as any).value as Data.MindMap.MindMapDto[]
    const mindMaps = mindMapDtos.map(MindMapProvider.fromMindMapDto)
    dispatch(MindMapState.setMindMaps(mindMaps))

    // ^^ Put more dispatches here


    // Load any Local Storage data here
    dispatch(AutoAiModeState.loadFromLocalStorage())


    dispatch(loadingStatusState.setIsLoadingInitialData(false))
  })
}
