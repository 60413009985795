import { OpenAiModelTypeDefault } from "./OpenAiModelType";
import { OpenAIParamsType } from "./openAIParams";

export const openAiParamDefaults: Required<OpenAIParamsType> = {
  model: OpenAiModelTypeDefault,
  max_tokens: 2000,
  suffix: null,
  temperature: 1,
  top_p: 1,
  n: 1,
  logprobs: null,
  echo: false,
  stop: null,
  presence_penalty: 0,
  frequency_penalty: 0,
  best_of: 1,
  logit_bias: null,
}
