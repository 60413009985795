import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { dispatch } from "../../../../state/store"
import { AiContextConfigState } from "../../../../state/aiContextConfigSlice"


const cmdType = GlobalCommandType.aiContextShowOptions

export const aiContextShowOptionsCommandDefinition: GlobalCommandDefinition<AiContextShowOptionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AiContextShowOptionsCommandArgs) => {
    const title = "Show Ai Context"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: AiContextShowOptionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    dispatch(AiContextConfigState.setIsShowingModal(true))

    onProcessingComplete(true)
  },
  triggerCommand: (args: AiContextShowOptionsCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type AiContextShowOptionsCommandArgs = {

}
