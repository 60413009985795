import React, { useEffect, useMemo } from 'react'
import { Command } from 'cmdk'
import "./styles.css"
import { CmdKCommand } from './CmdKCommand'
import { CmdKCommandListItem } from './CmdKCommandListItem'
import { css } from '@emotion/react'
import { IconButton } from '@chakra-ui/react'
import { VerdiIconClose } from '../../../icons/VerdiIcons'


type Props = {
  onCurrentSelectionChange: (itemId: string) => void
  commands: CmdKCommand[]
  searchText: string
  setSearchText: (query: string) => void
  searchPlaceholderText?: string
  noResultsText?: string
  onKeyPress?: (e: React.KeyboardEvent) => void
  onAfterExecute?: () => void
  maxHeight?: number
  hideSearchInput?: boolean
}

export const CmdKCommandList = ({
  onCurrentSelectionChange,
  commands,
  searchText,
  setSearchText,
  searchPlaceholderText,
  noResultsText,
  onKeyPress,
  onAfterExecute,
  maxHeight,
  hideSearchInput,
}: Props) => {

  const [selectedValue, setSelectedValue] = React.useState('assign to me')

  // console.log("CmdKCommandList: selectedValue", selectedValue)

  useEffect(() => {
    // IDEA: Return the actual command item, not just the string id?
    // commands.find(c => c.itemId === selectedValue)
    onCurrentSelectionChange(selectedValue)

  }, [selectedValue])

  const clearSearchButtonVisible = useMemo(() => {
    return searchText.length > 0
  }, [searchText])


  return (
    <div
      className="commandMenu"
      css={css`
        max-height: ${maxHeight || 300}px;
      `}
    >
      <Command
        css={css`
        max-width: 640px;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
      `}
        label='Some label for the menu'
        value={selectedValue}
        onValueChange={setSelectedValue}
      // onKeyDown={(e: React.KeyboardEvent) => {
      //   console.log("onKeyDown ", { e })
      // }}
      // filter={(value, search) => {
      //   if (value.includes(search)) return 1
      //   return 0
      // }}
      >

        {/* <div>Optional heading</div> */}
        <div>
          {!hideSearchInput &&
            <Command.Input
              autoFocus
              placeholder={searchPlaceholderText || "Type a command or search..."}
              value={searchText}
              onValueChange={setSearchText}
              onKeyDown={(e: React.KeyboardEvent) => onKeyPress?.(e)}
            />
          }
          {clearSearchButtonVisible && (
            <IconButton
              icon={<VerdiIconClose />}
              size={"xs"}
              onClick={() => { setSearchText("") }}
              css={css`
                position: absolute;
                right: 0;
                margin-top: 5px;
                margin-right: 10px;
              `}
              aria-label={'clear search'} />
          )}
        </div>
        <Command.List>
          <Command.Empty> {noResultsText || "No results found."}</Command.Empty>

          {commands.map((command, index) =>
            <CmdKCommandListItem
              key={index}
              onAfterExecute={onAfterExecute}
              command={command} />
          )}


          {/* <Command.Group heading="Letters">
              <Command.Item>a</Command.Item>
              <Command.Item>b</Command.Item>
              <Command.Separator />
              <Command.Item>c</Command.Item>
            </Command.Group>


            <Command.Item
              key={"just-a-test"}
              value={"value-of-test"}
              accessKey='T'
              onSelect={(...args) => console.log("onSelect ", { args })}

            >
              Hey man just custom crap here
            </Command.Item> */}

        </Command.List>
      </Command>
    </div>
  )
}
