import { css } from "@emotion/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { VerdiIconArrowForward } from "../../components/icons/VerdiIcons"
import { useColor } from "../../utility-hooks/useColor"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"


export const StripePaymentCompletePage = () => {


  const bgColor = useColor("surfaceBg")
  const border = useColor("surfaceBorder")


  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      margin: 40px 20px;
    `}>
      <div css={css`
        border: 1px solid ${border};
        background-color: ${bgColor};
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}>
        <h1>You are all set!</h1>
        <p>
          You have successfully set up your payment details.
        </p>
        <p>
          If you have any questions, please contact Verdi support
        </p>
        <p css={css`
          text-align: right;
          margin-top: 12px;
          padding-top: 16px;
          border-top: 1px solid #99999933;
          display: flex;
          justify-content: space-between;
        `}>
          <ButtonSecondary
            label="Review your plan"
            onClick={() => window.location.href = "/organization/plan"}
          />
          <ButtonPrimary
            label="Start using Verdi"
            rightIcon={<VerdiIconArrowForward />}
            onClick={() => window.location.href = "/home"}
          />
        </p>
      </div>
    </div>
  )
}
