import { Data } from "@verdi/shared-constants";


export const buildDocFrameworkContextForPrompt = (framework: Data.FrameworkModel) => {

  let exampleBodiesPrompt = ""
  const exampleBodies = framework.docTemplates.filter(t => t.isExample)
    .map((b, i) => {
      const exampleTitle = `Example ${i + 1}`
      return `START ${exampleTitle}:\n${b.bodyMarkdown}\nEND ${exampleTitle}`
    })
  if (exampleBodies.length > 0) {
    exampleBodiesPrompt = `Examples of documents that can be created using this framework:
${exampleBodies.join("\n")}
    `
  }

  let templateBodiesPrompt = ""
  const templateBodies = framework.docTemplates.filter(t => !t.isExample)
    .map((b, i) => {
      const templateTitle = `Document Template ${i + 1}`
      return `START ${templateTitle}:\n${b.bodyMarkdown}\nEND ${templateTitle}`
    })
  if (templateBodies.length > 0) {
    templateBodiesPrompt = `This template is being used to guide how the document evolves:
${templateBodies.join("\n")}
    `
  }


  const prompt = `In this context, a framework is helping the user to follow a template or structure to create a document. 
This framework is also used to ensure certain concepts are covered in the document.
Title: ${framework.title}
Description: ${framework.description}
When to use: ${framework.whenToUse}
${exampleBodiesPrompt}
${templateBodiesPrompt}
`

  return prompt
}
