import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { DocumentSchema } from "@verdi/shared-constants";
import { SuggestionsForPlaceholdersState } from "../../../../../state/suggestions/suggestionsForPlaceholdersSlice";
import { useMemo } from "react";
import { getCurrentAppState } from "../../../../../state/store";
import { useAppSelector } from "../../../../../state/storeHooks";
import { css } from "@emotion/react";


type Props = NodeViewProps;

/** Renders a placeholder within the editor. */
export const PlaceholderInlineComponent = (props: Props) => {


  const { text } = props.node.attrs as DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes

  const suggestions = useAppSelector(s => SuggestionsForPlaceholdersState.getForPlaceholderText(s, text))
  // const suggestions = useAppSelector(SuggestionsForPlaceholdersState.getAll)

  // const suggestions = useMemo(() => {
  //   console.log("PlaceholderInlineComponent: text changed")
  //   return SuggestionsForPlaceholdersState.getForPlaceholderText(getCurrentAppState(), text)
  // }, [text])


  return (
    <NodeViewWrapper
      as="span"
      className="placeholder-inline"
      css={css`
     ${suggestions?.isLoading ? css`
        animation: placeholderLoadingPulse 2s infinite;
      ` : ""}
    `}
    >
      {/* <span css={css`
        float: right;
      `}>
        <LoadingSpinner
          size="xs"
        />
      </span>
      <span css={css`
        position: relative;
        border: 1px solid green;
      `}>
        <span css={css`
          position: absolute;
          border: 1px solid red;
          right: 0;
        `}>
          DUDE {suggestions?.isLoading && "Loading..."}
        </span>
      </span> */}


      {text}

      {/* <NodeViewContent as="span" className="content" onKeyPress={() => console.log("changed")} /> */}


    </NodeViewWrapper>
  );
};
