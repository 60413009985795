
export enum AiPersonaType {
  Generic = "Generic",
  EngineeringLead = "EngineeringLead",
  ProductDesigner = "ProductDesigner",
  ProductMarketer = "ProductMarketer",
  ProductManagementLead = "ProductManagementLead",
  CustomerSuccessRep = "CustomerSuccessRep",
  SalesRep = "SalesRep",
  UXResearcher = "UXResearcher",
  ProductAnalyst = "ProductAnalyst",
  FunKramer = "FunKramer",
  FunYoda = "FunYoda",
  FunDumbledore = "FunDumbledore",
  RawChatGPT = "RawChatGPT",
}

export type AiPersona = {
  type: AiPersonaType
  displayName: string
}

export const defaultAiPersona: AiPersona = {
  type: AiPersonaType.Generic,
  displayName: "Product Manager",
}

export const allAiPersonas: AiPersona[] = [
  defaultAiPersona,
  {
    type: AiPersonaType.ProductDesigner,
    displayName: "Product Designer",
  },
  {
    type: AiPersonaType.EngineeringLead,
    displayName: "Engineer",
  },
  // {
  //   type: AiPersonaType.ProductMarketer,
  //   displayName: "Product Marketer",
  // },
  // {
  //   type: AiPersonaType.ProductManagementLead,
  //   displayName: "Product Management Lead",
  // },
  // {
  //   type: AiPersonaType.CustomerSuccessRep,
  //   displayName: "Customer Success Rep",
  // },
  // {
  //   type: AiPersonaType.SalesRep,
  //   displayName: "Sales Rep",
  // },
  // {
  //   type: AiPersonaType.UXResearcher,
  //   displayName: "UX Researcher",
  // },
  // {
  //   type: AiPersonaType.ProductAnalyst,
  //   displayName: "Product Analyst",
  // },
  // {
  //   type: AiPersonaType.FunDumbledore,
  //   displayName: "Albus Dumbledore",
  // },
  // {
  //   type: AiPersonaType.FunKramer,
  //   displayName: "Kramer",
  // },
  {
    type: AiPersonaType.FunYoda,
    displayName: "Yoda",
  },
  {
    type: AiPersonaType.RawChatGPT,
    displayName: "Raw Chat GPT",
  },
]
