/**
 * @generated SignedSource<<6ae473b27cb5774a3f6ef64be2a23f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useFetchStepsQuery$variables = {
  after: string;
  documentId: string;
  first: number;
};
export type useFetchStepsQuery$data = {
  readonly documentStepConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly body: string;
        readonly clientId: string;
        readonly id: string;
        readonly redisId: string;
        readonly stepNumber: number;
        readonly user: {
          readonly id: string;
        } | null;
      };
    }>;
  };
};
export type useFetchStepsQuery = {
  response: useFetchStepsQuery$data;
  variables: useFetchStepsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      }
    ],
    "concreteType": "QueryDocumentStepConnection",
    "kind": "LinkedField",
    "name": "documentStepConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryDocumentStepConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentStep",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "redisId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stepNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useFetchStepsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useFetchStepsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "7761b9cffaaf7311484d8935fe2d34f8",
    "id": null,
    "metadata": {},
    "name": "useFetchStepsQuery",
    "operationKind": "query",
    "text": "query useFetchStepsQuery(\n  $documentId: ID!\n  $after: String!\n  $first: Int!\n) {\n  documentStepConnection(documentId: $documentId, after: $after, first: $first) {\n    edges {\n      node {\n        id\n        body\n        redisId\n        clientId\n        stepNumber\n        user {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "318ae23784453211345e7c9b4c5066a2";

export default node;
