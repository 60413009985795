import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.removePlaceholder

/** Removes the currently selected placeholder */
export const removePlaceholderCommandDefinition: GlobalCommandDefinition<RemovePlaceholderCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: RemovePlaceholderCommandArgs) => {
    const title = args.mode === "deletePlaceholder" ? "Delete Placeholder" : "Dismiss Placeholder"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: args.mode === "deletePlaceholder" ? "remove" : "dismiss",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: RemovePlaceholderCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('removePlaceholder: No editor found in servicesForCurrentDoc')
      return
    }

    const currentPlaceholder = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()?.currentPlaceholderAttrs
    if (!currentPlaceholder) {
      onProcessingComplete(false)
      console.warn('removePlaceholder: No currentPlaceholder found in servicesForCurrentDoc')
      return
    }

    if (args.mode === "dismissPlaceholder") {
      editor
        .chain()
        .insertContent(currentPlaceholder.text) // Assumes that current placeholder is selected
        .run();

    } else {
      editor
        .chain()
        .deleteSelection()
        .run();
    }

    onProcessingComplete(true)
  },
}

type RemovePlaceholderCommandArgs = {
  mode: "deletePlaceholder" | "dismissPlaceholder"
}
