import { DocumentSchema } from "@verdi/shared-constants";
import { ProsemirrorNodes } from "@verdi/shared-constants";
import { EditorState } from "prosemirror-state";
import { ReplaceStep } from "prosemirror-transform";
import { Slice } from "prosemirror-model";
import { sanitizeAiJson } from "../../../../ai/utils/sanitizeAiJson";
import { cleanupAiOutputAsDocumentOutline } from "../../../../ai/utils/cleanupAiOutput";
import { prosemirrorNodeFromDocOutlineEntry } from "../../../../components/commands/commandDefinitions/documentBody/writeToDocBody";
import { Node } from "prosemirror-model";


export type DocumentOutlineEntry = {
  type: "heading" | "text" | "question" | "checkbox",
  content: string,
  children?: DocumentOutlineEntry[],
}

export const buildFirstDocStepFromRawJsonOutline = (rawJsonString: string) => {

  try {
    const sanitized = sanitizeAiJson(rawJsonString)
    const parsedOutline = sanitized ? JSON.parse(sanitized) as DocumentOutlineEntry[] : []
    console.log("process the body = ", rawJsonString)
    console.log("parsedOutline = ", parsedOutline)

    const step = buildFirstDocStepFromOutline(parsedOutline)

    return JSON.stringify(step)

  } catch (e) {
    console.error("tryBuildFirstDocStepFromRawJsonOutline ", e)
  }
  return undefined
}


export const buildFirstDocStepFromRawMarkdown = (rawString: string) => {

  try {
    const docOutline = cleanupAiOutputAsDocumentOutline(rawString)
    const step = buildFirstDocStepFromOutline(docOutline)
    return JSON.stringify(step)

  } catch (e) {
    console.error("buildFirstDocStepFromRawMarkdown ", e)
  }
  return undefined
}


const blockFactory = (outlineType: string) => {
  if (outlineType === 'heading') {
    return ProsemirrorNodes.makeSectionBlock;
  }

  return ProsemirrorNodes.makeFreeTextBlock;
}

export const buildFirstDocStepFromOutline = (docOutline: DocumentOutlineEntry[]) => {

  let state = EditorState.create({
    doc: DocumentSchema.createNewDoc(DocumentSchema.initialDoc()),
  });

  for (let i = 0; i < docOutline.length; i += 1) {
    const { [i]: line } = docOutline;

    const blockJSON = prosemirrorNodeFromDocOutlineEntry(line)
    const block = state.schema.nodeFromJSON(blockJSON);

    const transaction = state.tr;

    // if its the first line we want to replace the old content
    if (i <= 0) {
      transaction.replaceWith(0, transaction.doc.content.size, block)
    }
    // if its not the first line we want to put this new content at the end
    else {
      transaction.insert(transaction.doc.content.size, block);
    }

    state = state.apply(transaction);
  }

  const theFragment = state.doc.content
  const slice = new Slice(theFragment, 0, 0)

  /*
    This replace step really needs to have context of what the document looks like.
    we are using 0,4 as the replace range, but the document may not be a blank document.
    This works for now-ish but eventually we will want to have context of the target document
    and where it needs to be inserted. This is super fragile right now
  */
  return new ReplaceStep(0, 4, slice)
}


/** Creates a step for a blank doc, using the provided block's content
 * 
 *  @param block The block to use as the content for the new doc. Typically is of type "doc"
 */
export const buildFirstDocStepFromDocNode = (block: Node) => {

  const slice = new Slice(block.content, 0, 0)
  return new ReplaceStep(0, 4, slice)
}
