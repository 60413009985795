import { useColorModeValue } from "@chakra-ui/react";


/** Adds a class so that CSS beyond chakra can know if it is currently dark mode or light mode
 * 
 *  Especially useful for CSS within the tiptap editor
 */
export const LightDarkModeClassNameProvider = ({ children }: { children: React.ReactNode }) => {

  const classNameForDarkModeToggle = useColorModeValue(
    "lightTheme",
    "darkTheme"
  );

  return (
    <div className={classNameForDarkModeToggle}>
      {children}
    </div>
  )

}
