import { css } from "@emotion/react"
import { AssumptionsState } from "../../state/assumptionsSlice"
import { useCallback } from "react"
import { useAppServices } from "../../components/appServices/useAppServices"
import { dispatch } from "../../state/store"
import LoadingSpinner from "../../components/LoadingSpinner"
import { AssumptionsListItemView } from "./AssumptionsListItemView"
import { VerdiIconAdd, VerdiIconRefresh } from "../../components/icons/VerdiIcons"
import { IconButtonTertiary } from "../../components/buttons/IconButtonTertiary"
import { AssumptionSummary, CreateAssumptionInput } from "./useAssumptionsProvider"
import { Data } from "@verdi/shared-constants"
import { AssumptionsDetector } from "./AssumptionsDetector"
import { getDocBodySnapshotFromCache } from "../../state/loaders/getDocBodySnapshotFromCache"


type Props = {
  documentId?: string,
  answeredSuggestions: AssumptionSummary[],
  isLoading: boolean,
}
export const SuggestedAssumptionsList = ({
  documentId,
  answeredSuggestions,
  isLoading,
}: Props) => {

  const {
    getContextForAi,
    toast,
    assumptionsProvider,
  } = useAppServices()


  const detectAnsweredAssumptions = useCallback(async () => {
    if (!documentId) {
      console.warn("Could not detect answered assumptions. No related document id.")
      return
    }
    return AssumptionsDetector.detectAnsweredAssumptions(documentId, getContextForAi)
  }, [documentId, getContextForAi]);


  const addNewAssumption = useCallback((assumption: AssumptionSummary) => {
    if (!assumption.question) {
      toast.showError("Could not add this assumption.")
      return
    }
    if (assumption.isLoading) {
      console.info("Assumption is already being added.")
      return
    }
    if (!documentId) {
      toast.showError("Could not add this assumption.")
      console.warn("Could not add this assumption. No related document id.")
      return
    }

    const newAssumption: CreateAssumptionInput = {
      docTitle: assumption.question,
      relatedDocumentId: documentId,
      userShouldReview: false,
      assumptionToAdd: {
        confidenceLevel: Boolean(assumption.answer.trim())
          ? Data.AssumptionConfidenceLevel.anecdotal
          : Data.AssumptionConfidenceLevel.hypothesis,
        relevanceLevel: Data.AssumptionRelevanceLevel.valuable,
      },
      answerText: assumption.answer,
      frameworkQuestionId: undefined,
    }

    dispatch(AssumptionsState.updateSuggestedAnsweredAssumption({
      targetDocId: documentId,
      assumption: { ...assumption, isLoading: true },
    }))

    assumptionsProvider.create(
      newAssumption,
      (newAssumptionDocId: string, assumptionName: string, userShouldReview: boolean) => {

        dispatch(AssumptionsState.removeSuggestedAnsweredAssumption({ targetDocId: documentId, assumption }))
        toast.showSuccess("Assumption added.")

        // Ensure the answer is showing for the new assumption on the list
        getDocBodySnapshotFromCache(newAssumptionDocId)

      },
      (error) => {
        toast.showError("Could not add this assumption.")
        console.error('Could not add this assumption.', { error })
        dispatch(AssumptionsState.updateSuggestedAnsweredAssumption({
          targetDocId: documentId,
          assumption: { ...assumption, isLoading: false },
        }))
      }
    );

  }, [assumptionsProvider, toast, documentId]);


  return (
    <div css={css`
      margin-top: 20px;
    `}>


      {isLoading &&
        <LoadingSpinner
          size="sm"
          label="Scanning document for assumptions..."
        />
      }


      {!isLoading &&
        <p>
          Suggested assumptions
          <IconButtonTertiary
            aria-label="Detect answered assumptions"
            icon={<VerdiIconRefresh />}
            size="sm"
            onClick={detectAnsweredAssumptions}
            opacity={0.5}
          />
        </p>
      }



      {answeredSuggestions.length > 0 &&
        <div>
          <p></p>
          {answeredSuggestions.map((assumption, key) =>
            <div key={key}
              css={css`
              & button {
                opacity: 0.3;
                transition: opacity 0.2s;
              }
              &:hover button {
                opacity: 1;
              }
    
            `}
            >
              <AssumptionsListItemView
                title={assumption.question}
                answer={assumption.answer}
                isSelected={false}
                onClick={() => addNewAssumption(assumption)}
                riskColor="transparent"
                leftSideChildren={
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      padding: 4px;
                  `}>
                    <IconButtonTertiary
                      aria-label="Add"
                      size="sm"
                      icon={<VerdiIconAdd />}
                      isLoading={assumption.isLoading}
                    // Currently clicking anywhere on the row will add
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
      }

    </div>

  )
}
