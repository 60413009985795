import { css } from "@emotion/react"
import { useEffect, useRef } from "react"
import { AiCommandType } from "../../../../../ai/coach/useAiCoachCommandProcessor"
import { useColor } from "../../../../../utility-hooks/useColor"
import { CommandMenuSectionItemContent } from "./CommandMenuSectionItemContent"


type Props = {
  isSelected: boolean
  commandType?: AiCommandType,
  title: string
  icon?: React.ReactNode
  onClick: () => void
  onMouseEnter?: () => void
  shortcutKeys?: string[]
  description?: string
  disabled?: boolean
  isLoading?: boolean
  /** Required so that element.ScrollIntoView() will work */
  parentDomSelectorId: string
  shouldConstrainLargeText?: boolean
  /** If true the appearance of this item will be less noticeable */
  isSubtle?: boolean
}

const isElementVisible = (element: HTMLLIElement, parentCommandMenuId: string) => {
  const rect = element.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  // todo: get the right parent
  const container = document.getElementById(parentCommandMenuId)
  const parentRect = container?.getBoundingClientRect()
  if (!parentRect || typeof parentRect === 'undefined') {
    console.warn(`Parent with id ${parentCommandMenuId} not found!`)
    return
  }
  const isVisible = (elemTop >= 0) && elemTop > parentRect.top && (elemBottom <= parentRect.bottom);
  return isVisible;
}

export const CommandMenuSectionItem = ({
  isSelected,
  commandType,
  title,
  icon,
  description,
  disabled,
  onClick,
  onMouseEnter,
  shortcutKeys,
  isLoading,
  parentDomSelectorId: parentCommandMenuId,
  shouldConstrainLargeText,
  isSubtle,
}: Props) => {

  const activeBg = useColor("itemSelectedBg")

  const backgroundColor = isSelected ? activeBg : undefined
  const ref = useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (isSelected && ref.current) {
      const element = ref.current
      const isVisible = isElementVisible(element, parentCommandMenuId)
      if (!isVisible) {
        element.scrollIntoView({
          // Don't change behavior, or it will be weird
          behavior: "smooth",
          block: 'nearest',
        })
      }
    }
  }, [isSelected, parentCommandMenuId])


  let commandTypeText = ""
  if (commandType === AiCommandType.addAction) commandTypeText = "+ Action"
  // else if (commandType === AiCommandType.addSection) commandTypeText = "+ Section"

  const shouldTruncate = shouldConstrainLargeText && !isSelected


  return (
    <li
      ref={ref}
      onClick={onClick}
      css={css`
      background: ${backgroundColor};
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 10px;
      font-size: 0.8em;
      line-height: 1.3;
      cursor: ${isLoading ? "not-allowed" : "pointer"};
      &:hover{
        opacity: 0.75;
      }
      ${isSubtle && !isSelected && css`
        opacity: 0.4;
      `}
    `}
    >
      <CommandMenuSectionItemContent
        title={title}
        icon={icon}
        commandTypeText={commandTypeText}
        shortcutKeys={shortcutKeys}
        description={description}
        disabled={disabled}
        isLoading={isLoading}
        onMouseEnter={onMouseEnter}
        shouldTruncate={shouldTruncate}
      />

    </li >
  )
}
