/**
 * @generated SignedSource<<3bf27c0668a55737452dca23cc4f7839>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpportunityDocumentPageQuery$variables = {
  opportunityId: string;
  shouldSkip: boolean;
};
export type OpportunityDocumentPageQuery$data = {
  readonly node?: {
    readonly description?: string | null;
    readonly mainDoc?: {
      readonly id: string;
    } | null;
    readonly title?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
  } | null;
};
export type OpportunityDocumentPageQuery = {
  response: OpportunityDocumentPageQuery$data;
  variables: OpportunityDocumentPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "opportunityId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldSkip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "opportunityId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Document",
  "kind": "LinkedField",
  "name": "mainDoc",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVisibleToOrg",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OpportunityDocumentPageQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "OpportunityDocumentSideMenu_opportunity"
                  }
                ],
                "type": "Opportunity",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OpportunityDocumentPageQuery",
    "selections": [
      {
        "condition": "shouldSkip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "excludeChildrenAtTheRoot",
                        "value": true
                      }
                    ],
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Document",
                        "kind": "LinkedField",
                        "name": "childDocs",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "documents(excludeChildrenAtTheRoot:true)"
                  }
                ],
                "type": "Opportunity",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "c78507219015be46810d9f4886cfa78c",
    "id": null,
    "metadata": {},
    "name": "OpportunityDocumentPageQuery",
    "operationKind": "query",
    "text": "query OpportunityDocumentPageQuery(\n  $opportunityId: ID!\n  $shouldSkip: Boolean!\n) {\n  node(id: $opportunityId) @skip(if: $shouldSkip) {\n    __typename\n    ... on Opportunity {\n      title\n      description\n      mainDoc {\n        id\n      }\n      ...OpportunityDocumentSideMenu_opportunity\n    }\n    id\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  documents(excludeChildrenAtTheRoot: true) {\n    id\n    title\n    type\n    isVisibleToOrg\n    childDocs {\n      id\n      title\n      type\n      isVisibleToOrg\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b134e4bba18b6b35c822d6e4a6b97cd4";

export default node;
