import { css } from "@emotion/react"

type Props = {
  onClick: () => void
}
export const LightDismissalOverlay = ({ onClick }: Props) => {

  return (
    <div onClick={onClick}
      css={css`
        width: 100%;
        height: 100vh;
        background-color: #99999999;
        position: absolute;
        top: 0;
      `}>
    </div>
  )
}
