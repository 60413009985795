import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsStrings } from "../../utils/cleanupAiOutput"


/** Detects what phrases should become a document reference 
 *  to allow a user to "go deeper" on
 * */
export const makeDocReferencePlaceholdersAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  bodyTextToParse: string,
) => {

  const allContext: AllContextForAi = await getContextForAi()

  const docContext = allContext.currentDoc
  if (!docContext) {
    console.warn("makeAutoDraftFullDocAiCall: No currentDoc context found")
    return []
  }

  const prompt = getPrompt(bodyTextToParse)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    openAIParams: {
      temperature: 0.2,
    }
  })

  const rawText = response?.aiResponseJson.content as string || ""
  const phrasesToLink = cleanupAiOutputAsStrings(rawText)
  return phrasesToLink
}


const getPrompt = (bodyTextToParse: string) => {

  const prompt = `
###
TARGET TEXT:
${bodyTextToParse}
###

INSTRUCTIONS: 
Given the TARGET TEXT, identify what phrases should become a link to another document to allow a user to "go deeper" on.
Only highligh phrases that would make a good document title.
Avoid starting phrases with verbs.

Typical links to other documents include:
interview guide
market research
customer survey

RESPONSE FORMAT RULES:
Return each phrase that should become a link to a related document on a new line.
Do not add any additional text to your response.

EXAMPLE 1:
IF THE INPUT IS:
Identify potential project management software partners.
Draft an interview guide to gather further feedback.
Conduct market research to understand their integration capabilities.
Analyze potential impact and benefits of the integration.
THEN a valid response could be:
potential project management software partners
interview guide
market research
potential impact and benefits of the integration

EXAMPLE 2:
IF THE INPUT IS:
Define the key metrics or indicators that will be used to measure the validity of the assumption.
Conduct market research and gather relevant data to gain insights and understanding.
Analyze the data collected to assess the viability and potential impact of the assumption.
Conduct a customer survey to gather feedback and opinions on the assumption.
Communicate the results and insights to stakeholders to guide decision-making processes.
THEN a valid response could be:
key metrics
market research
viability and potential impact
customer survey

EXAMPLE 3:
IF THE INPUT IS:
Conduct a review of the current website design 
Identify pain points and usability issues 
Gather feedback from existing customers 
Analyze industry best practices for website design 
Develop a plan for website redesign 
Implement changes based on feedback and analysis
Test website changes for usability and effectiveness 
Monitor website analytics and user behavior 
Gather feedback on the updated website design 
Evaluate the impact of redesign on user experience and satisfaction 
THEN a valid response could be:
review of the current website design
pain points and usability issues
feedback from existing customers
plan for website redesign
feedback on the updated website design
the impact of redesign
`
  return prompt
}
