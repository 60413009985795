import { useMediaQuery } from "@chakra-ui/react";

import { useCallback, useEffect, useState } from "react";
import { mediaQueryLargeWidth, mediaQuerySmallWidth } from "./styleHelpers";


export const usePageLayoutState = () => {

  const [menuIsExpanded, setMenuIsExpanded] = useState(true)
  const [screenWidthIsSmall] = useMediaQuery(mediaQuerySmallWidth)
  const [screenWidthIsLarge] = useMediaQuery(mediaQueryLargeWidth)

  // Important: IF you set the default NOT to hidden, then the chat window will be visible on any page after the home page
  const [rightSideState, setRightSideState] = useState<'hidden' | 'visible' | 'fullscreen'>('hidden')

  const [isNavigating, setIsNavigating] = useState(false)
  const onBeforeNavigate = () => {
    setIsNavigating(true)
    if (screenWidthIsSmall) {
      dismissBothMenus()
    }
    setTimeout(() => {
      setIsNavigating(false) // TODO: Find a better way to dismiss menu when on mobile
    }, 0)
  }


  const dismissBothMenus = useCallback(() => {
    setMenuIsExpanded(false)
    setRightSideState("hidden")
  }, [setMenuIsExpanded, setRightSideState])


  /** Auto show/hide side menus based on screen width */
  useEffect(() => {
    setMenuIsExpanded(!screenWidthIsSmall)
    if (screenWidthIsSmall) {
      setRightSideState("hidden")
    }
  }, [screenWidthIsSmall])

  useEffect(() => {
    if (screenWidthIsLarge) {
      setRightSideState("visible")
    }
  }, [screenWidthIsLarge])


  return {
    screenWidthIsSmall,
    menuIsExpanded,
    setMenuIsExpanded,
    rightSideState,
    setRightSideState,
    dismissBothMenus,
    isNavigating,
    onBeforeNavigate,
  }

}

/** Shares state of the high level components of the page, 
 *  like the nav menu or chat window, to child compoenets 
 */
export type PageLayoutState = ReturnType<typeof usePageLayoutState>
