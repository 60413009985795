import { Data } from "@verdi/shared-constants"
import { Edge } from "@xyflow/react"
import { MindMapData } from "../../state/MindMapSlice"
import { WrappedGraphEdge, WrappedGraphNode } from "./internals/nodeTypes/NodeTypes"
import { IdeaNodeWrapperData } from "./internals/nodeTypes/idea/IdeaNode"
import { DocTagDictionary } from "../../state/docTagsSlice"
import { SuggestedIdeaNodeData } from "../../state/MindMapSuggestionsSlice"
import { buildChildNodeAndEdge } from "./internals/context/helpers/useCreateChildNodes"


/** Converts a menu structure to nodes and edges for a MindMapGraph (ReactFlow) */
const convertMenuStructure = (
  menuItems: Data.Menu.MenuItemData[],
  docTagsDictionary: DocTagDictionary,
  selectedDocIds: string[],
  suggestedNodes: SuggestedIdeaNodeData[],
): MindMapData => {

  const suggestedNodesByDocId = suggestedNodes.reduce((acc, s) => {
    if (!acc[s.parentDocId]) {
      acc[s.parentDocId] = []
    }
    acc[s.parentDocId].push(s)
    return acc
  }
    , {} as Record<string, SuggestedIdeaNodeData[]>)

  const nodes: WrappedGraphNode[] = []
  const edges: Edge[] = []
  menuItems.forEach(item => {
    const results = convertMenuItem(item, docTagsDictionary, selectedDocIds, suggestedNodesByDocId)
    nodes.push(...results.nodes)
    edges.push(...results.edges)
  })

  // filter out any duplicate nodes and edges (based on id)
  const uniqueNodes = nodes.filter((node, index, self) =>
    index === self.findIndex((t) => (t.id === node.id))
  )
  const uniqueEdges = edges.filter((edge, index, self) =>
    index === self.findIndex((t) => (t.id === edge.id))
  )

  return { nodes: uniqueNodes, edges: uniqueEdges }
}

const convertMenuItem = (
  item: Data.Menu.MenuItemData,
  allTags: DocTagDictionary,
  selectedDocIds: string[],
  suggestedNodesByDocId: Record<string, SuggestedIdeaNodeData[]>
): MindMapData => {

  const nodes: WrappedGraphNode[] = []
  const edges: WrappedGraphEdge[] = []

  const tags = allTags[item.id] || []
  const isSelected = selectedDocIds.includes(item.id)

  nodes.push({
    id: item.id,
    data: {
      text: item.title,
      docId: item.id,
      docType: item.type,
      tags: tags,
    },
    position: { x: 0, y: 0 },
    type: "idea",
    selected: isSelected,
  } as IdeaNodeWrapperData)

  if (item.children) {
    item.children.forEach(child => {
      edges.push({
        id: `${item.id}-${child.id}`,
        source: item.id,
        target: child.id,
      })
      const childResults = convertMenuItem(child, allTags, selectedDocIds, suggestedNodesByDocId)
      nodes.push(...childResults.nodes)
      edges.push(...childResults.edges)
    })
  }

  // Add any suggested child nodes
  const suggestedChildren = suggestedNodesByDocId[item.id] || []
  if (suggestedChildren.length > 0) {
    suggestedChildren.forEach(suggestedChild => {
      const { node, edge } = buildChildNodeAndEdge(item.id, suggestedChild.data)
      nodes.push(node)
      edges.push(edge)
    })
  }

  // if (isSelected) {
  //   // Here is where you can add placeholder nodes for adding children, if we wanted to
  // }

  return { nodes, edges }
}


export const MindMapDataConverter = {
  convertMenuStructure,
  convertMenuItem
}
