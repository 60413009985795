
/** Ensure these match the Prisma DocumentType enum */
export enum DocumentType {
  document = "document",
  quickNote = "quickNote",

  outcome = "outcome",
  opportunity = "opportunity",
  assumption = "assumption",
  insight = "insight",
  concept = "concept",

  research = "research",
  interviewGuide = "interviewGuide",
  interviewNotes = "interviewNotes",

  /** @deprecated Use "research" instead */
  researchInitiative = "researchInitiative",
  /** @deprecated Use "interviewGuide" instead */
  guide = "guide",
  /** @deprecated Use "interviewNotes" instead */
  interview = "interview",
  /** @deprecated Use "research" instead */
  initiative = "initiative", // Might not be needed?
  /** @deprecated No custom logic needed. Use "document" instead. */
  pressRelease = "pressRelease",
  /** @deprecated No custom logic needed. Use "document" instead. */
  prd = "prd",
  /** @deprecated No custom logic needed. Use "document" instead. */
  faq = "faq",
}

export const getAllDocumentTypes = () => {
  return Object.values(DocumentType)
}
