import { Edge, NodeTypes } from "@xyflow/react";
import { IdeaNodeView, IdeaNodeWrapperData } from "./idea/IdeaNode";
import PlaceholderNode, { PlaceholderWrapperData } from "./placeholder/PlaceholderNode";
import AddNewNode, { AddNewNodeWrapperData } from "./addNew/AddNewNode";


/** Configures which kinds of nodes to display */
export const nodeTypes: NodeTypes = {
  idea: IdeaNodeView,
  placeholder: PlaceholderNode,
  addNew: AddNewNode,
};

/** All custom node types should be added here */
export type WrappedGraphNode = IdeaNodeWrapperData | PlaceholderWrapperData | AddNewNodeWrapperData
export type WrappedGraphNodeData = WrappedGraphNode['data']

/** All Custom Edge types should be added here */
export type WrappedGraphEdge = Edge & {
  // type: string
  // data: any
}


export const NodeTypeIds = {
  idea: 'idea',
  placeholder: 'placeholder',
  addNew: 'addNew',
}

