import { DocumentOutlineEntry } from "../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { OpportunityContextForAi } from "../../screens/opportunities/useOpportunityProvider"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { getDraftAnInterviewGuidePrompt } from "../prompts/docDrafter/interviewGuidePrompts"
import { makeAiChatRequest } from "../requests/AiApiRequests"
import { cleanupAiOutputAsStrings } from "../utils/cleanupAiOutput"


export const getDraftInterviewGuide = async (
  companyContext: string,
  opportunityContext?: OpportunityContextForAi,
  docContext?: DocumentContextForAi,
) => {

  const prompt = getDraftAnInterviewGuidePrompt(
    companyContext,
    opportunityContext,
    docContext,
  )

  const response = await makeAiChatRequest({
    prompt,
    messages: [],
  })

  const rawText = response?.aiResponseJson.content as string || ""
  const lines = cleanupAiOutputAsStrings(rawText, {
    keepDuplicates: true,
    keepEmptyLines: true,
  })
  console.log("draftInterviewGuide", { rawText, lines })

  const toReturn: DocumentOutlineEntry[] = []
  for (const line of lines) {
    toReturn.push({
      content: line,
      type: line.includes("?") ? "question" : "heading",
    })
  }

  return toReturn
}
