/**
 * @generated SignedSource<<b6b18da87c79f4bf47774910cc1ce03f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
export type UpdateDocumentTemplateInfoInput = {
  documentTemplateInfoId: string;
  documentType: DocumentType;
  isPublished: boolean;
  title: string;
};
export type useUpdateDocumentTemplateInfoMutation$variables = {
  input: UpdateDocumentTemplateInfoInput;
};
export type useUpdateDocumentTemplateInfoMutation$data = {
  readonly updateDocumentTemplateInfo: {
    readonly id: string;
    readonly isPublished: boolean;
    readonly title: string | null;
  } | null;
};
export type useUpdateDocumentTemplateInfoMutation = {
  response: useUpdateDocumentTemplateInfoMutation$data;
  variables: useUpdateDocumentTemplateInfoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DocumentTemplateInfo",
    "kind": "LinkedField",
    "name": "updateDocumentTemplateInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPublished",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateDocumentTemplateInfoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateDocumentTemplateInfoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "80af2e48561cf331cdc5b6e33cba4265",
    "id": null,
    "metadata": {},
    "name": "useUpdateDocumentTemplateInfoMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateDocumentTemplateInfoMutation(\n  $input: UpdateDocumentTemplateInfoInput!\n) {\n  updateDocumentTemplateInfo(input: $input) {\n    id\n    title\n    isPublished\n  }\n}\n"
  }
};
})();

(node as any).hash = "905c3c183f654528268edb9d683f70b6";

export default node;
