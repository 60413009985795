import { Attributes, NodeConfig, PasteRule } from "@tiptap/core"


/* Shared behaviors for all block types */

type Config = Partial<NodeConfig<any, any>>


// TODO: Can we remove all of these useless defaults ???

export const defaultAttributes = {
  // id: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: false,
  // },
  // originalBlockId: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: false,
  // },
  // sourceBlockId: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: false,
  // },
  // transcriptTimestamp: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: true,
  // },
  // aiPromptId: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: false,
  // },
  // aiPromptShortId: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: false,
  // },
  // placeholderKey: {
  //   isRequired: false,
  //   default: null,
  //   keepOnSplit: false,
  // },
}


export const nestableBlockDefaultConfig = ({
  blockName,
  tagName,
  attributes,
}: {
  blockName: string
  tagName?: string
  attributes?: Attributes
}) => {
  const tag = tagName ?? blockName
  const config: Config = {
    name: blockName,
    group: 'nestableBlock',
    content: 'title contentList?',
    selectable: false,
    defining: true,

    addAttributes: () => {
      return {
        ...defaultAttributes,
        ...attributes,
      }
    },
    parseHTML() {
      return [
        {
          tag,
          // getAttrs: node => typeof node !== 'string' && node.className.includes(name) && null,
        },
      ]
    },
    addPasteRules() {
      return [
        new PasteRule({
          find: config.find,
          handler: ({ state, range, match }) => {
            console.log(state, range, match)
          },
        })
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return [tag, {
        ...HTMLAttributes,
        class: blockName,
      }, 0]
    },
    // Just React all of it
    // addNodeView() {
    //   return ReactNodeViewRenderer(AIWrappedComponent)
    // },
    // Your code goes here.
  }
  return config
}


