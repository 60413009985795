import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.addPlaceholder

/** For debugging purposes, adds a placeholder */
export const addPlaceholderCommandDefinition: GlobalCommandDefinition<AddPlaceholderCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddPlaceholderCommandArgs) => {
    const title = "Add Placeholder"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "debug",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: AddPlaceholderCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('addPlaceholder: No editor found in servicesForCurrentDoc')
      return
    }

    console.log("addPlaceholderCommandDefinition: Making placeholder")
    const placeholderNode = ProsemirrorNodes.makePlaceholderInlineSection("the text", {
      text: "Displaying this text"
    })

    console.log("addPlaceholderCommandDefinition: Running command chain", { placeholderNode })

    editor
      .chain()
      .insertContent(placeholderNode)
      .run();

    console.log("addPlaceholderCommandDefinition: Completed")
    onProcessingComplete(true)
  },
}

type AddPlaceholderCommandArgs = {
}
