import { GlobalCommandType } from "../GlobalCommandType"
import { triggerGlobalCommand } from "../triggerGlobalCommand"
import { VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../GlobalCommandDefinition"
import { AppServices } from "../../appServices/useRegisterAppServices"
import { autoShowAddAssumptionModalLocalStorageKey } from "../../../screens/assumptions/AssumptionsList"
import { getUrlForOpportunityAssumptions } from "../../../routes"


const cmdType = GlobalCommandType.navigateToAddAssumption

/** Navigates to the "Assumptions" page and opens up the "Add" dialog box */
export const navigateToAddAssumptionCommandDefinition: GlobalCommandDefinition<NavigateToAddAssumptionCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: NavigateToAddAssumptionCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: "Add Assumption",
        description: '',
        searchName: 'add assumption',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: NavigateToAddAssumptionCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("Add assumption cmd def, processCommand ", { args })
    localStorage.setItem(autoShowAddAssumptionModalLocalStorageKey, "true")
    const url = getUrlForOpportunityAssumptions(args.opportunityMainDocId)
    services.router.router.push(url)

    onProcessingComplete(true)
  },
  triggerCommand(args) {
    triggerGlobalCommand(cmdType, args)
  },
}

export type NavigateToAddAssumptionCommandArgs = {
  opportunityMainDocId: string
}
