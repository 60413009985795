
/** To support input elements that change color based on their value */

export type ConditionalColorMapMode = "none" | "lowIsGood" | "highIsGood"

export type ColorValues = {
  border: string
  bg: string
}
export const DefaultColorValues: ColorValues = {
  border: "inherit",
  bg: "inherit",
}



// TODO: Consider using the "variant" property with styles in the theme?
const colorMapLowIsGoodLight: { [key: string]: ColorValues } = {
  ["1"]: { border: "#00B23B", bg: "#CCFFDD" }, // Green
  ["2"]: { border: "#00B23B", bg: "#CCFFDD" },
  ["3"]: { border: "#FF9F40", bg: "#FFE5CC" }, // yellow
  ["4"]: { border: "#FF9F40", bg: "#FFE5CC" },
  ["5"]: { border: "#CC0023", bg: "#FFCCD5" }, // Red
}

const colorMapHighIsGoodLight: { [key: string]: ColorValues } = {
  ["1"]: { border: "#CC0023", bg: "#FFCCD5" }, // Red
  ["2"]: { border: "#FF9F40", bg: "#FFE5CC" }, // Yellow
  ["3"]: { border: "#FF9F40", bg: "#FFE5CC" },
  ["4"]: { border: "#00B23B", bg: "#CCFFDD" }, // Green
  ["5"]: { border: "#00B23B", bg: "#CCFFDD" },
}
const colorMapLowIsGoodDark: { [key: string]: ColorValues } = {
  ["1"]: { border: "#16D956", bg: "#003311" }, // Green
  ["2"]: { border: "#16D956", bg: "#003311" },
  ["3"]: { border: "#FF9F40", bg: "#331A00" }, // Yellow
  ["4"]: { border: "#FF9F40", bg: "#331A00" },
  ["5"]: { border: "#FF5A76", bg: "#330009" }, // Red
}

const colorMapHighIsGoodDark: { [key: string]: ColorValues } = {
  ["1"]: { border: "#FF5A76", bg: "#330009" }, // Red
  ["2"]: { border: "#FF9F40", bg: "#331A00" },
  ["3"]: { border: "#FF9F40", bg: "#331A00" }, // Yellow
  ["4"]: { border: "#16D956", bg: "#003311" },
  ["5"]: { border: "#16D956", bg: "#003311" }, // Green
}

export const getConditionalColorsFromValue = (value: string, colorMapMode: ConditionalColorMapMode, isLightMode: boolean) => {

  const colorMap = isLightMode ?
    colorMapMode === "lowIsGood" ? colorMapLowIsGoodLight : colorMapHighIsGoodLight
    :
    colorMapMode === "lowIsGood" ? colorMapLowIsGoodDark : colorMapHighIsGoodDark
  const toReturn = colorMap[value]
  if (toReturn) return toReturn

  return DefaultColorValues
}
