import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNewQuickNoteCreator } from '../../documents/add/useNewQuickNoteCreator';
import { Menu } from '@verdi/shared-constants';
import { DocumentSchema } from '@verdi/shared-constants';
import { MenuStructureForAllDocsProvider } from '../../document/organize/useGetMenuStructureForAllDocs';
import { css } from '@emotion/react';
import { ButtonTertiary } from '../../../components/buttons/ButtonTertiary';
import { useRouter } from 'found';
import { SubMenuSection } from './SubMenuSection';
import { useFeatureFlags } from '../../../utility-hooks/useFeatureFlags';
import { addDocShowOptionsCommandDefinition } from '../../../components/commands/commandDefinitions/document/addDocShowOptions';
import { VerdiIconArrowBack, VerdiIconArrowUp } from '../../../components/icons/VerdiIcons';
import { navigateToAddAssumptionCommandDefinition } from '../../../components/commands/commandDefinitions/navigateToAddAssumption';
import { getUrlForOpportunityAssumptions } from '../../../routes';


type Props = {
  onBeforeNavigate: () => void
  onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  menuStructureProvider: MenuStructureForAllDocsProvider
  activeMenuIds: string[]
  opportunity: Menu.MenuItemData | undefined
  onBackButtonClick: () => void
}
export const SideMenuSectionForSingleOpportunity = ({
  onBeforeNavigate,
  onAddOpportunityButtonClick,
  menuStructureProvider,
  activeMenuIds,
  opportunity,
  onBackButtonClick,
}: Props) => {

  const [menuData, setMenuData] = useState<MenuData>()
  const currentDocId = activeMenuIds[0]
  const router = useRouter()
  const { experimentalEnabled } = useFeatureFlags()


  useEffect(() => {

    if (!opportunity) {
      setMenuData(undefined)
      return
    }

    const breadCrumbParents = Menu.getMenuItemHierarchy(opportunity, menuStructureProvider.menuItemsStructured.flatMenuItems).reverse()

    const subOpportunitiesSection: Menu.MenuItemData = {
      children: opportunity.children.filter(c => c.type === DocumentSchema.DocumentType.opportunity),
      id: opportunity.id,
      title: opportunity.title,
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: opportunity.url,
      createdAt: new Date(),
    }

    const assumptionsSection: Menu.MenuItemData = {
      children: opportunity.children.filter(c => c.type === DocumentSchema.DocumentType.assumption),
      id: "currentOpportunityAssumptions",
      title: "Assumptions",
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: getUrlForOpportunityAssumptions(opportunity.id),
      createdAt: new Date(),
    }

    const childNotesSection: Menu.MenuItemData = {
      children: filterOutSpecialDocTypes(opportunity.children),
      id: "currentOpportunity",
      title: "Related notes",
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: "",
      createdAt: new Date(),
    }

    setMenuData({
      breadCrumbParents,
      subOpportunitiesSection,
      childNotesSection,
      assumptionsSection,
    })
  }, [opportunity, menuStructureProvider.menuItemsStructured, currentDocId])

  const { createNewQuickNote, isSaving } = useNewQuickNoteCreator({ shouldShowChatOnRedirect: false })

  const onMenuItemClick = useCallback((menuItem: Menu.MenuItemData) => {
    onBeforeNavigate()
    console.log("onMenuItemClick() ", menuItem.url)
    router.router.push(menuItem.url)
  }, [router, onBeforeNavigate])


  const opportunityWithoutChildren = useMemo<Menu.MenuItemData | undefined>(() => {
    if (!opportunity) return undefined
    const opportunityNoChildren: Menu.MenuItemData = {
      ...opportunity,
      children: [],
    }
    return opportunityNoChildren
  }, [opportunity])


  if (!opportunity || !menuData) return null


  const { childNotesSection, subOpportunitiesSection, assumptionsSection, breadCrumbParents } = menuData


  return (
    <div>

      <div>

        {breadCrumbParents.length === 0 &&
          <ButtonTertiary
            label='All Opportunities'
            leftIcon={<VerdiIconArrowBack />}
            marginLeft={-0.5}
            fontWeight="var(--chakra-fontWeights-semibold)"
            opacity={0.7}
            onClick={onBackButtonClick}
          />
        }

        {breadCrumbParents.length > 0 &&
          <div>
            {/* For now just take the immediate parent */}
            {breadCrumbParents.slice(breadCrumbParents.length - 1).map((parent, i) => {
              return (
                <div key={parent.id}
                  css={css`
                    margin-left: -2px;
                    overflow: hidden;
                    width: 250px;
                  `}
                >
                  <ButtonTertiary
                    label={parent.title}
                    leftIcon={<VerdiIconArrowUp />}
                    marginLeft={0}
                    fontWeight="var(--chakra-fontWeights-semibold)"
                    opacity={0.7}
                    onClick={() => router.router.push(parent.url)}
                  />
                </div>
              )
            })}
          </div>
        }
      </div>

      <div css={css`
      margin-left: 12px;
        
      `}>



        {/* <div css={css`
          margin-left: -8px;
          padding: 4px;
        `}>
          {opportunityWithoutChildren &&
            <SubMenuSectionItem
              activeMenuIds={[currentDocId]}
              menuDepth={0}
              menuItem={opportunityWithoutChildren}
              onMenuItemClick={onMenuItemClick}

            />
          }
        </div> */}


        <SubMenuSection
          sectionTitle=""
          children={[subOpportunitiesSection]}
          activeMenuIds={activeMenuIds}
          initialAccordionState='expanded'
          onMenuItemClick={onMenuItemClick}
          onAddButtonClick={(menuItem) => {
            console.log("Add button clicked for Opportunity ", { menuItem })
            const parentOpportunity = menuItem || opportunity
            console.log("parentOpportunity ", { parentOpportunity })
            onAddOpportunityButtonClick(parentOpportunity)
          }}
        />


        <SubMenuSection
          sectionTitle="Notes"
          children={childNotesSection.children}
          activeMenuIds={activeMenuIds}
          initialAccordionState='expanded'
          onMenuItemClick={onMenuItemClick}
          onAddButtonClick={(menuItem, ref) => {
            console.log("Add button clicked for note ", { menuItem })
            const parentDoc = menuItem || opportunity
            console.log("parentDoc ", { parentDoc })
            addDocShowOptionsCommandDefinition.triggerCommand?.({
              parentDocId: parentDoc.id,
              rect: ref?.current?.getBoundingClientRect()
            })
          }}
        />


        <SubMenuSection
          sectionTitle={assumptionsSection.title}
          children={assumptionsSection.children}
          activeMenuIds={activeMenuIds}
          initialAccordionState='collapsed'
          onMenuItemClick={onMenuItemClick}
          onAddButtonClick={(menuItem) => {
            const parentOpportunity = menuItem || opportunity
            navigateToAddAssumptionCommandDefinition.triggerCommand?.({
              opportunityMainDocId: parentOpportunity.id,
            })
          }}
          hideChildrenAdd={true}
        />



        {/* {experimentalEnabled &&
          <div>

            <div css={css`
            margin-top: 80px; 
            padding-left: 12px;
            font-style: italic;
            opacity: 0.5;
          `}>
              Main menu (for reference)
            </div>

            <div css={css`
              border-top: 1px solid #99999922;
            `}>
              <SideMenuSection
                menuItem={subOpportunitiesSection}
                menuDepth={startingMenuDepth}
                folderType="anything"
                onBeforeNavigate={onBeforeNavigate}
                isExpandedInitially={false}
                onAddButtonClick={(menuItem) => createNewQuickNote()}
                onAddOpportunityButtonClick={onAddOpportunityButtonClick}
                addingInProgress={isSaving}
                activeMenuIds={activeMenuIds}
              />
            </div>

            <div css={css`
              border-top: 1px solid #99999922;
            `}>
              <SideMenuSection
                menuItem={childNotesSection}
                menuDepth={startingMenuDepth}
                folderType="anything"
                onBeforeNavigate={onBeforeNavigate}
                isExpandedInitially={false}
                onAddButtonClick={(menuItem) => createNewQuickNote()}
                onAddOpportunityButtonClick={onAddOpportunityButtonClick}
                addingInProgress={isSaving}
                activeMenuIds={activeMenuIds}
              />
            </div>

          </div>
        } */}

      </div>
    </div>
  )
}

type MenuData = {
  breadCrumbParents: Menu.MenuItemData[]
  subOpportunitiesSection: Menu.MenuItemData
  assumptionsSection: Menu.MenuItemData
  childNotesSection: Menu.MenuItemData
}

export const filterOutSpecialDocTypes = (menuItems: Menu.MenuItemData[]): Menu.MenuItemData[] => {
  return menuItems.filter(c => c.type !== DocumentSchema.DocumentType.opportunity && c.type !== DocumentSchema.DocumentType.assumption)
}
