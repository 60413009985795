import { useContext } from "react"
import { DocEditorContext } from "./DocEditorContext"


/** Consumes context and services for a specific document.
 * 
 *  See `DocServicesContext` for more info
 */
export const useDocServices = () => {

  const docEditorContext = useContext(DocEditorContext)
  if (!docEditorContext) {
    throw new Error("docEditorContext is undefined")
  }

  return docEditorContext
}
