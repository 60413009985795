import { IconButton, useDisclosure } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { EditorView } from "prosemirror-view";
import VeNode from "../../document/tiptapEditor/VeNode";
import { AiPromptFormModal } from "./AiPromptFormModal";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { EditAiPromptOptionsMenu_document$key } from "./__generated__/EditAiPromptOptionsMenu_document.graphql";
import { DeleteAiPromptButton } from "./DeleteAiPromptButton";
import { AttributeNames } from "@verdi/shared-constants";
import { AddSnippetFromDoc } from "../../snippet/AddSnippetFromDoc";
import { VerdiIconAdd, VerdiIconEdit } from "../../../components/icons/VerdiIcons";

const buttonCss = css`
  margin: 3px;
`;
const aiPromptIdAttributeName = AttributeNames.aiPromptId;

const fragmentQL = graphql`
  fragment EditAiPromptOptionsMenu_document on Document {
    id
    title
    aiPrompts {
      id
      isDocContext
      ...useUpdateAiPromptMutation_documentAiPrompt
      ...AiPromptFormModal_documentLevelContextAiPrompt
      ...AiPromptPreview_otherBlockLevelPrompts
    }
  }
`;

export type AIPromptMenuSelection = {
  yPos: number;
  node: VeNode;
  editorView: EditorView;
};

type Props = {
  selection?: AIPromptMenuSelection;
  document: EditAiPromptOptionsMenu_document$key;
};

export const EditAiPromptOptionsMenu = ({
  selection,
  document: documentKey,
}: Props) => {
  const document = useFragment(fragmentQL, documentKey);
  const disclosure = useDisclosure();

  if (!selection) return null;

  const { yPos, node, editorView } = selection;
  const thisPromptId = node.node().attrs[aiPromptIdAttributeName];
  const existingPrompt = document.aiPrompts.find(
    (prompt) => prompt.id === thisPromptId
  );
  const docContextPrompt = document.aiPrompts.find(
    (prompt) => prompt.isDocContext
  );
  const otherBlockLevelPrompts = document.aiPrompts.filter(
    (prompt) => !prompt.isDocContext && prompt.id !== thisPromptId
  );
  const hasPrompt = Boolean(existingPrompt);
  const editorState = editorView.state;

  const removePromptId = () => {
    const transaction = editorState.tr.setNodeAttribute(
      node.pos(),
      aiPromptIdAttributeName,
      null
    );
    editorView.dispatch(transaction);
  };

  const addPromptId = (id: string) => {
    const transaction = editorState.tr.setNodeAttribute(
      node.pos(),
      aiPromptIdAttributeName,
      id
    );
    editorView.dispatch(transaction);
  };

  const updatePrompt = () => {
    disclosure.onOpen();
  };

  const createPrompt = updatePrompt;

  return (
    <div
      css={css`
        position: absolute;
        top: ${yPos}px;
        right: 240px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
      `}
    >
      {!hasPrompt && (
        <IconButton
          aria-label="Add"
          icon={<VerdiIconAdd />}
          css={buttonCss}
          onClick={createPrompt}
        />
      )}

      {hasPrompt && (
        <>
          <IconButton
            aria-label="edit"
            icon={<VerdiIconEdit />}
            css={buttonCss}
            onClick={updatePrompt}
          />
          <DeleteAiPromptButton
            aiPromptId={""}
            onDeleteConfirmed={() => {
              removePromptId();
            }}
          />
        </>
      )}

      <AddSnippetFromDoc
        documentId={document.id}
        documentTitle={document.title || undefined}
        editorView={editorView}
        node={node}
      />

      <AiPromptFormModal
        key={!hasPrompt ? "create" : existingPrompt?.id}
        formType={hasPrompt ? "update" : "create"}
        documentTitle={document.title || ""}
        documentId={document.id}
        aiPrompt={existingPrompt}
        docContextPrompt={docContextPrompt}
        onSaveComplete={addPromptId}
        disclosure={disclosure}
        otherBlockLevelPrompts={otherBlockLevelPrompts}
      />
    </div>
  );
};
