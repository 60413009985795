import { useCallback, useEffect, useState } from "react"
import { makeGetRequestJson } from "../../../utility-hooks/fetchUtils"
import { Data } from "@verdi/shared-constants"
import { format } from "date-fns"
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { css } from "@emotion/react"


export const OrganizationPlanList = () => {


  const [allPlans, setAllPlans] = useState<Data.OrganizationPlan.OrganizationPlanModel[]>([])

  const loadAll = useCallback(async () => {
    const plans = await makeGetRequestJson("OrganizationPlans")
    setAllPlans(plans)
  }, [setAllPlans])

  useEffect(() => {
    loadAll()
  }, [])


  return (
    <div>
      <h2>
        Plans
      </h2>
      <TableContainer>
        <Table variant="striped" size="sm" >
          <Thead>
            <Tr>
              <Th> Organization </Th>
              <Th> Seats </Th>
              <Th> Free Trial Expires </Th>
              <Th> Paid Plan Expires </Th>
            </Tr>
          </Thead>
          <Tbody>
            {allPlans.map((p, key) =>
              <Tr key={key}
                css={css`
                  ${p.isActive ? "" : `
                    & > td {
                      font-style: italic;
                      opacity: 0.7;
                    }`}
              `}>
                <Td> {p.organization.name} {p.isActive ? "" : <small> (Inactive)</small>} </Td>
                <Td> {p.numberOfPaidSeats} </Td>
                <Td> {p.freeTrialExpiresAt ? format(new Date(p.freeTrialExpiresAt), "do MMM, yyyy") : "-"} </Td>
                <Td> {p.paidPlanExpiresAt ? format(new Date(p.paidPlanExpiresAt), "do MMM, yyyy") : "-"} </Td>
                {/* <Td> {p.isActive ? "Active" : "Inactive"} </Td> */}
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  )
}
