import { useCallback, useState } from "react"

export const useFeatureFlags = () => {

  const [experimentalEnabled, setExperimentalEnabled] = useState(localStorage.getItem(experimentalFeaturesKeyName) === "true")
  const setShouldShowExperimentalFeatures = useCallback((shouldShow: boolean) => {
    localStorage.setItem(experimentalFeaturesKeyName, shouldShow ? "true" : "false")
    setExperimentalEnabled(shouldShow)
  }, [setExperimentalEnabled])


  return {
    experimentalEnabled,
    setShouldShowExperimentalFeatures
  }
}

const experimentalFeaturesKeyName = "yolo"
