import { Tooltip, useColorModeValue } from "@chakra-ui/react"
import { VerdiIconPanelRightClosed, VerdiIconPanelRightOpen } from "../icons/VerdiIcons"
import { IconButtonTertiary } from "../buttons/IconButtonTertiary"


type Props = {
  isShowingChat: boolean
  toggleChat: () => void
}
export const ToggleChatButton = ({ isShowingChat, toggleChat }: Props) => {


  const isLightMode = useColorModeValue(true, false)


  return (
    <IconButtonTertiary
      aria-label="Show / hide chat"
      title="Show / hide chat"
      icon={isShowingChat ? <VerdiIconPanelRightOpen /> : <VerdiIconPanelRightClosed />}
      size="sm"
      marginRight={2}
      onClick={() => toggleChat()}
    />
  )
}
