import { DocumentContextForAi } from "../../../../../../../ai/coach/AiCoachPrompts"
import { getSuggestedActionCommandsForAssumptions } from "../../../../../../../ai/suggestions/getSuggestedActions"
import { SuggestionForDocument } from "../useWhatsNextSuggester"


export const getWhatsNextForAssumption = async (
  onCommandCompleted: (commandId: string) => void,
  docContext?: DocumentContextForAi
): Promise<SuggestionForDocument[]> => {

  console.log("getWhatsNextForAssumption()")

  if (!docContext) {
    console.warn("useWhatsNextForAssumption: no doc context")
    return []
  }
  if (!docContext.title) {
    console.warn("useWhatsNextForAssumption: no doc title")
    return []
  }

  // Get Actions to add
  // IDEA: Check current actions they have first?

  const actionCommands = getSuggestedActionCommandsForAssumptions(onCommandCompleted, docContext, 3, true)

  // TODO: Add Section commands?
  // Also set Section title for these ones, when returning

  return (await actionCommands).map(a => ({ command: a }))

}
