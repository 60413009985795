import { GlobalCommandType } from "../GlobalCommandType"
import { triggerGlobalCommand } from "../triggerGlobalCommand"
import { VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../GlobalCommandDefinition"
import { AppServices } from "../../appServices/useRegisterAppServices"
import { ChatMessage } from "../../chat/useChatHistory"


const cmdType = GlobalCommandType.showChat

export const showChatCommandDefinition: GlobalCommandDefinition<ShowChatCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowChatCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Chat with Verdi Ai ...',
        description: '',
        searchName: 'chat',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ShowChatCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    services.pageLayoutState.setRightSideState("visible")
    if (args.initialMessage) {
      services.aiChatBridge.triggerNewChatSession(args.initialMessage)
    }
    onProcessingComplete(true)
  },
  triggerCommand: (args: ShowChatCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type ShowChatCommandArgs = {
  initialMessage?: ChatMessage
}
