import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { useAppServices } from "../../../../components/appServices/useAppServices"
import { css } from "@emotion/react"
import { ButtonSecondary } from "../../../../components/buttons/ButtonSecondary"
import { ButtonPrimary } from "../../../../components/buttons/ButtonPrimary"
import { DocumentFrameworksList } from "../../../documentFrameworks/DocumentFrameworksList"
import { AddNewDocView } from "./AddNewDocView"
import { mediaQuerySmallWidth } from "../../../../components/pageLayout/styleHelpers"
import { useCallback, useState } from "react"
import { GlobalCommandType } from "../../../../components/commands/GlobalCommandType"
import { triggerGlobalCommand } from "../../../../components/commands/triggerGlobalCommand"


/** Allows adding a new document via the Enchiridion, from anywhere in the app */
export const AddNewDocGlobalModal = () => {


  const { addNewDocGlobalProvider } = useAppServices()

  const {
    disclosure,
    selectedDocFramework,
    modalTitle,
  } = addNewDocGlobalProvider


  const [isAdding, setIsAdding] = useState(false)
  const addClicked = useCallback(async () => {
    if (!selectedDocFramework) {
      console.warn("No framework selected, can't add doc")
      return
    }
    // TODO: distinguish between Adding a new doc, and adding sections to the current doc
    setIsAdding(true)
    const args = {
      docFramework: selectedDocFramework,
    }
    triggerGlobalCommand(GlobalCommandType.addDocWithFramework, args)

  }, [selectedDocFramework])


  return (
    <div>
      <Modal
        onClose={disclosure.onClose}
        isOpen={disclosure.isOpen}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>

          <ModalCloseButton
            onClick={disclosure.onClose}
          />

          <ModalHeader>
            {modalTitle || "Add Note"}
          </ModalHeader>

          <ModalBody>
            <div css={css`
              display: flex;
              width: 100%;
            `}>
              <div css={css`
              border-radius: 5px;
              padding: 10px;
              min-width: 100px;
              min-height: 200px;
              max-height: calc(100vh - 300px);
              overflow-y: auto;
              width: 50%;
              @media ${mediaQuerySmallWidth} {
                width: 100%;
                }
            `}>

                <DocumentFrameworksList
                  addNewDocProvider={addNewDocGlobalProvider}
                />

              </div>
              <div css={css`
                width:50%;
                @media ${mediaQuerySmallWidth} {
                  display: none;
                }
              `}>
                <AddNewDocView
                  addNewDocProvider={addNewDocGlobalProvider}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <ButtonSecondary
              label="Cancel"
              onClick={disclosure.onClose}
              marginRight={2}
            />
            <ButtonPrimary
              label="Add"
              onClick={() => addClicked()}
              isDisabled={!selectedDocFramework || isAdding}
              isLoading={isAdding}
            />
          </ModalFooter>

        </ModalContent>
      </Modal>


    </div>
  )
}
