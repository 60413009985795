import { websocketUrl } from "../auth/network";
import { WebsocketEvents } from "@verdi/shared-constants";
import { useCallback, useEffect, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

type ClientEvent = {
  eventPayload?: WebsocketEvents.ClientEvents.ClientEventType;
};

type Args = {
  onMessage?: (message: ClientEvent) => void;
};

let reconnectingWebsocket: ReconnectingWebSocket;

export const useWebSocket = ({ onMessage }: Args) => {
  if (!reconnectingWebsocket) {
    reconnectingWebsocket = new ReconnectingWebSocket(
      websocketUrl(),
      [],
      {
        connectionTimeout: 9 * 60 * 1000, // 9 minutes
      }
    )
  }

  useEffect(() => {
    if (onMessage) {
      const onMessageCallback = (message: any) => {
        const clientEvent = JSON.parse(message.data) as ClientEvent;
        onMessage(clientEvent);
      };
      reconnectingWebsocket.addEventListener("message", onMessageCallback);
      return () => {
        reconnectingWebsocket.removeEventListener("message", onMessageCallback);
      };
    }
  }, [onMessage]);

  const sendMessage = useCallback(
    (outgoingMessage: WebsocketEvents.ServerEvents.ServerEventType) => {
      // console.log(`sending msg: ${outgoingMessage.eventName} ${JSON.stringify(outgoingMessage.args)}`)
      reconnectingWebsocket.send(
        JSON.stringify({ eventBody: outgoingMessage })
      );
      setLastMessageSentAt(Date.now());
    },
    []
  );

  const [lastMessageSentAt, setLastMessageSentAt] = useState<number>(0);

  return {
    sendMessage,
    lastMessageSentAt,
  };
};
