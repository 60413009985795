import { useCallback, useMemo, useState } from "react"
import { useKeyDownHandlerOnFocus } from "../../../components/floatingBlockContext/useKeyDownHandler"
import { css } from "@emotion/react"
import { IconButtonPrimary } from "../../../components/buttons/IconButtonPrimary"
import { Text } from "@chakra-ui/react"
import { EditableKeyValuePair } from "../../documentRelation/common/EditableKeyValuePair"
import { VerdiIconAdd, VerdiIconClose } from "../../../components/icons/VerdiIcons"


type Props = {
  id: string
  existingFields: string[]
  onAddClick: (companyContextItemName: string, companyContextItemDescription: string) => void
  onDeleteClick: (id: string) => void
}

export const CompanyContextItemAdd = ({
  id,
  existingFields,
  onAddClick,
  onDeleteClick
}: Props) => {

  const [companyContextItemName, setCompanyContextItemName] = useState("")
  const [companyContextItemDescription, setCompanyContextItemDescription] = useState("")
  const [itemNameError, setTitleError] = useState("")
  const [showItemNameError, setShowTitleError] = useState(false)

  const validateItemName = useCallback((value: string) => {
    if (value.trim().length === 0) {
      setShowTitleError(false)
    } else if (value.trim().match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
      setShowTitleError(false)
    } else {
      setTitleError("Invalid Name: May not contain numbers or special symbols")
      setShowTitleError(true)
    }
    if (existingFields.includes(convertTitleCaseToCamelCase(value.trim()))) {
      setTitleError("Invalid Name: This name already exists")
      setShowTitleError(true)
    }
    setCompanyContextItemName(value)
  }, [])

  const addButtonEnabled = useMemo(() => {
    return companyContextItemName.trim().length > 0 && !showItemNameError
  }, [companyContextItemName, showItemNameError])

  const [componentIsFocused, setComponentIsFocused] = useState(false)

  useKeyDownHandlerOnFocus((evt) => {
    if (evt.key === "Enter" && componentIsFocused) {
      onAddClick(companyContextItemName, companyContextItemDescription)
      evt.preventDefault()
    }
  }, componentIsFocused)

  const onSubmit = useCallback((name: string, value: string) => {
    if (name.trim() === "") {
      return
    }
    setCompanyContextItemName("")
    setCompanyContextItemDescription("")
    onAddClick(convertTitleCaseToCamelCase(name.trim()), value.trim())
    onDeleteClick(id)
  }, [])

  return (
    <div>
      <div
        css={css`
      border-radius: 10px;
      display: flex;
      align-items: top;
      margin: 5px;
      div:first-of-type {
        flex-grow: 1;
      }
      `}>
        <EditableKeyValuePair
          title={companyContextItemName}
          description={companyContextItemDescription}
          updateTitle={validateItemName}
          placeholderTitle="Name"
          updateDescription={setCompanyContextItemDescription}
          placeholderDescription="Description"
          hideButton
        />
        <IconButtonPrimary
          css={css`
            margin-left: 5px;
            `}
          aria-label={"Add Item"}
          icon={<VerdiIconAdd />}
          disabled={!addButtonEnabled}
          onClick={() => { onSubmit(companyContextItemName, companyContextItemDescription) }}
        />
        <IconButtonPrimary
          css={css`
            margin-left: 5px;
            `}
          colorScheme="red"
          variant="ghost"
          aria-label={"Add Item"}
          icon={<VerdiIconClose />}
          onClick={() => { onDeleteClick(id) }}
        />
      </div>
      {showItemNameError && (
        <Text
          fontSize="small"
          opacity={0.6}
          marginTop={1}
        >{itemNameError}</Text>
      )}
    </div>
  )
}

function convertTitleCaseToCamelCase(input: string): string {
  const words = input.split(/\s+/)
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      // Convert the first word to lowercase
      return word.toLowerCase();
    } else {
      // Capitalize the first letter of each subsequent word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  return camelCaseWords.join('');
}
