import { BrowserProtocol, queryMiddleware } from "farce";
import {
  createFarceRouter,
  createRender,
  makeRouteConfig,
  Redirect,
  RenderErrorArgs,
  Route,
} from "found";

import { RouteWrapPublic } from "./RouteWrapPublic";
import { RouteWrapLoggedIn } from "./RouteWrapLoggedIn";

import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import Logout from "../screens/logout/Logout";

import { Documents } from "../screens/documents/Documents";
import { CompareDocument } from "../screens/compareDocument/CompareDocument";
import { ZoomConnectAccount } from "../screens/zoom/ZoomConnectAccount";
import { ZoomMeetingList } from "../screens/zoom/ZoomMeetingList";
import { OpportunityDocumentPage } from "../screens/opportunityDocument/OpportunityDocumentPage";
import { DocumentHistoryDebugger } from "../screens/document/admin/DocumentHistoryDebugger";
import { DocumentTemplatePage } from "../screens/documentTemplate/DocumentTemplatePage";
import OrganizationUsersPage from "../screens/organization/users/OrganizationUsersPage";
import { OrganizationValidateUser } from "../screens/organization/users/OrganizationValidateUser";
import { SnippetPage } from "../screens/snippet/SnippetPage";
import { HomePage } from "../screens/home/HomePage";
import { StylesGuidePage } from "../screens/styling/StylesGuidePage";
import { CompanyContextPage } from "../screens/organization/companyContext/CompanyContextPage";
import { AssumptionsPage } from "../screens/assumptions/AssumptionsPage";
import { AdminReportsPage } from "../screens/admin/reports/AdminReportsPage";
import { AppLoadingScreen } from "../screens/initialLoading/AppLoadingScreen";
import { NewActionPage } from "../screens/actions/NewActionPage";
import UserProfilePage from "../screens/organization/users/UserProfilePage";
import { OnboardingPage } from "../screens/onboarding/OnboardingPage";
import { StripeAdminPage } from "../screens/admin/stripe/StripeAdminPage";
import { StripePaymentCompletePage } from "../screens/stripe/StripePaymentCompletePage";
import OrganizationPlansPage from "../screens/organization/plansAndBilling/OrganizationPlansPage";
import { OpportunitiesPage } from "../screens/opportunities/OpportunitiesPage";
import TagsPage from "../screens/tags/TagsPage";


export const allOpportunitiesRoutePath = "/allOpportunities";

export const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  renderError: ({ error }: RenderErrorArgs) => {
    console.error(error);
    return <div>{error.status === 404 ? "Not found" : "Error"}</div>;
  },
  routeConfig: makeRouteConfig(
    <Route path="/">

      <Redirect from="" to={allOpportunitiesRoutePath} />

      <Route Component={RouteWrapPublic}>
        <Route path="register" Component={Register} getData={() => { }} />
        <Route path="login" Component={Login} getData={() => { }} />
        <Route path="logout" Component={Logout} />
        <Route path="forgot-password" Component={ForgotPassword} />
        <Route path="reset-password" Component={ResetPassword} />
        <Route path="organization/join/:token" Component={OrganizationValidateUser} />
        <Route path="public/PaymentComplete" Component={StripePaymentCompletePage} />
      </Route>

      <Route Component={RouteWrapLoggedIn}>
        <Route path="home">
          <Route Component={HomePage} />
        </Route>
        <Route path="home/notes/:documentId">
          <Route Component={HomePage} />
        </Route>
        <Route path="opportunity">
          <Route Component={OpportunityDocumentPage} />
        </Route>
        <Route path="opportunity/:opportunityId">
          <Route Component={OpportunityDocumentPage} />
        </Route>
        <Route path="opportunity/:opportunityId/:documentId">
          <Route Component={OpportunityDocumentPage} />
        </Route>
        <Route path={allOpportunitiesRoutePath}>
          <Route Component={OpportunitiesPage} />
        </Route>
        <Route path="actions/new/:documentId">
          <Route Component={NewActionPage} />
        </Route>
        <Route path="documents">
          <Route Component={Documents} />
        </Route>
        <Route path="documentHistory/:documentId">
          <Route Component={DocumentHistoryDebugger} />
        </Route>
        <Route path="compareDocument/:documentId">
          <Route Component={CompareDocument} />
        </Route>
        <Route path="zoom/connect">
          <Route Component={ZoomConnectAccount} />
        </Route>
        <Route path="zoom/meetings">
          <Route Component={ZoomMeetingList} />
        </Route>
        <Route path="/tags">
          <Route Component={TagsPage} />
        </Route>
        <Route path="templates/documents/:documentId">
          <Route Component={DocumentTemplatePage} />
        </Route>
        <Route path="templates/documents">
          <Route Component={DocumentTemplatePage} />
        </Route>
        <Route path="organization">
          <Route Component={OrganizationUsersPage} />
        </Route>
        <Route path="organization/users">
          <Route Component={OrganizationUsersPage} />
        </Route>
        <Route path="organization/context">
          <Route Component={CompanyContextPage} />
        </Route>
        <Route path="organization/plan">
          <Route Component={OrganizationPlansPage} />
        </Route>
        <Route path="userProfile">
          <Route Component={UserProfilePage} />
        </Route>
        <Route path="org/:orgId/snippets">
          <Route Component={SnippetPage} />
        </Route>
        <Route path="home/notes/:documentId/assumptions">
          <Route Component={AssumptionsPage} />
        </Route>
        <Route path="assumptions">
          <Route Component={AssumptionsPage} />
        </Route>
        <Route path="onboarding" Component={OnboardingPage} />


        {/* Routes for internal use */}
        <Route path="loading" Component={AppLoadingScreen} />
        <Route path="styles" Component={StylesGuidePage} />
        <Route path="admin/Reports" Component={AdminReportsPage} />
        <Route path="admin/stripe" Component={StripeAdminPage} />

      </Route>

    </Route>
  ),
  render: createRender({}),
});

export const getUrlForDocument = (
  documentId: string,
  opportunityId?: string
) => {
  // Let's see if commenting this out breaks anything :)
  // if (opportunityId) {
  //   return `/opportunity/${opportunityId}/${documentId}`;
  // }
  return getUrlForStandaloneDocument(documentId);
};

export const getUrlForStandaloneDocument = (documentId: string) => {
  return `/home/notes/${documentId}`;
};

export const getUrlForOpportunityAssumptions = (mainDocId: string) => {
  return `/home/notes/${mainDocId}/assumptions`;
}

export const getUrlFromCompany = () => {
  return "/organization/context";
}
