import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RunAIPromptOnDocCommandArgs } from '../../components/commands/commandDefinitions/documentBody/runAIPromptOnDoc'
import { ReplacePlaceholderWithCommandArgs } from '../../components/commands/commandDefinitions/documentBody/replacePlaceholderWith'


export type PlaceholderSuggestionInfo = {
  isLoading?: boolean
  placeholderText: string // Currently key-ing off of this value
  suggestions?: ReplacementSuggestion[]
  lineText?: string
}

export type ReplacementSuggestion = {
  replaceWithMode: "text" | "customAiPrompt"
  args?: ReplacePlaceholderWithCommandArgs | RunAIPromptOnDocCommandArgs
}

interface InternalState {
  suggestions: PlaceholderSuggestionInfo[]
}

const initialState: InternalState = {
  suggestions: [],
}

export const suggestionsForPlaceholdersSlice = createSlice({
  name: 'suggestionsForPlaceholders',
  initialState,
  reducers: {
    setForPlaceholderText: (state, action: PayloadAction<PlaceholderSuggestionInfo>) => {
      const existingIndex = state.suggestions.findIndex(s => s.placeholderText === action.payload.placeholderText)
      if (existingIndex > -1) {
        state.suggestions[existingIndex] = action.payload
      } else {
        state.suggestions.push(action.payload)
      }
    },
    removeForPlaceholderText: (state, action: PayloadAction<string>) => {
      state.suggestions = state.suggestions.filter(t => t.placeholderText !== action.payload)
    },
    removeAll: (state) => {
      state.suggestions = []
    },
  },
  selectors: {
    getAll: (state) => state.suggestions,
    getForPlaceholderText: (state, placeholderText: string) => {
      return state.suggestions.find(s => s.placeholderText === placeholderText)
    },
  }
})
export const SuggestionsForPlaceholdersState = {
  ...suggestionsForPlaceholdersSlice.actions,
  ...suggestionsForPlaceholdersSlice.selectors,
}

export default suggestionsForPlaceholdersSlice.reducer
