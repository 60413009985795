import { useAppServices } from "../../components/appServices/useAppServices"
import { ButtonTertiary } from "../../components/buttons/ButtonTertiary"


/** Button used to start the onboarding flow */
export const StartOnboardingButton = () => {

  const { router } = useAppServices()


  return (
    <div>
      <ButtonTertiary
        label="?"
        onClick={() => router.router.push("/onboarding")}
        marginRight={2}
      />
    </div>
  )
}
