import { IconTypeForVerdiCommand, VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import LoadingSpinner from "../../LoadingSpinner"
import { VerdiIconAdd, VerdiIconAiSuggestions, VerdiIconArrowForward, VerdiIconCheck, VerdiIconClose, VerdiIconDebug, VerdiIconDelete, VerdiIconFramework, VerdiIconRefresh } from "../../icons/VerdiIcons"


export const renderIconOnCommandMenuItem = (c: VerdiCommand) => {
  if (c.icon) return c.icon
  return renderIconOfType(c.iconType)
}


export const renderIconOfType = (iconType: IconTypeForVerdiCommand | undefined) => {
  if (!iconType) return undefined
  switch (iconType) {
    case "aiSuggestion": return <VerdiIconAiSuggestions />
    case "dismiss": return <VerdiIconClose />
    case "loading": return <LoadingSpinner size="xs" showAfterMsDelay={0} />
    case "navigate": return <VerdiIconArrowForward />
    case "add": return <VerdiIconAdd />
    case "remove": return <VerdiIconDelete />
    case "currentValue": return <VerdiIconCheck />
    case "refresh": return <VerdiIconRefresh />
    case "framework": return <VerdiIconFramework />
    case "debug": return <VerdiIconDebug />
  }
  return undefined
}
