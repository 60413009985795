import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { useAiSuggester } from "./useAiSuggester"


type Props = {
  getDocumentContextForAi: () => DocumentContextForAi | undefined,
}

export const useWorkflowSuggester = ({
  getDocumentContextForAi,
}: Props) => {

  const baseAiSuggester = useAiSuggester({
    getDocumentContextForAi,
    getRawPrompt,
  })

  return {
    ...baseAiSuggester,
  }
}

export type WorkflowSuggester = ReturnType<typeof useWorkflowSuggester>


const getRawPrompt = (additionalContext: string) => {
  const prompt = `
###
The user provided the following context:
${additionalContext}
###

You are an expert at creating high level workflows.

Create a workflow that outlines the 5-7 high level phases of work that needs to be done to solve the problem the user presents.

Return each workflow step as it's own line. Do not put them in a numbered list. Do not add any prefix, for example:
Define the problem
Test assumptions
Ideate possible solutions
Test possible solutions
Prototype
User testing
Release
`
  return prompt

}
