import { useContext } from "react"
import { AppServicesContext } from "./AppServicesContext"


/** Consume any app services via this hook. */
export const useAppServices = () => {

  const appServices = useContext(AppServicesContext)
  if (!appServices) {
    throw new Error("appServices are undefined")
  }

  return appServices
}

/** Specifically for GraphQL Relay Mutators, 
 * since RelayEnvironmentProvider is outside of AppServices wrapper (see App.tsx).
 * 
 * This may return null. */
export const useAppServicesForGraphQlRelay = () => {

  const appServices = useContext(AppServicesContext)
  return appServices
}
