import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


/** This ID currently matches both dev and Prod environments. 
 *  
 *  At some point we may want to make this configurable, in the relational DB or Environment config.
 * */
const defaultFrameworkIdForNewAssumption = "RnJhbWV3b3JrOjUz"

interface IFrameworksState {
  frameworks: Data.FrameworkModel[]
}

const initialState: IFrameworksState = {
  frameworks: [],
}

export const frameworksSlice = createSlice({
  name: 'frameworks',
  initialState,
  reducers: {
    setAllFrameworks: (state, action: PayloadAction<Data.FrameworkModel[]>) => {
      state.frameworks = action.payload
    },
  },
  selectors: {
    getAllFrameworks: (state) => {
      return state.frameworks
    },
    /** Returns either all frameworks that apply to opportunities OR that apply to other Documents */
    getAllFrameworksByKind: (state, isForOpportunity) => {
      return state.frameworks.filter(f => f.isForOpportunity === isForOpportunity)
    },
    getFrameworkById: (state, id: string) => {
      return state.frameworks.find(f => f.id === id)
    },
    getDefaultFrameworkForNewAssumption: (state) => {
      return state.frameworks.find(f => f.id === defaultFrameworkIdForNewAssumption)
    },
  },
})

export const FrameworksState = {
  ...frameworksSlice.actions,
  ...frameworksSlice.selectors,
}


export default frameworksSlice.reducer
