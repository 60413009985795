import { css } from "@emotion/react"


type Props = {
  title: string
  children: React.ReactNode
}

export const DocumentDetailLabel = ({ title, children }: Props) => {
  return (
    <div
      css={css`
        display:flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        min-height: 30px;
      `}
    >
      <span css={css`
        font-size: 0.9em;
        width: 90px;
      `}>
        {title}
      </span>

      <div css={css`
        width: calc(100% - 90px);
      `}>
        {children}
      </div>

    </div>
  )
}
