import { useCallback, useMemo } from "react"
import { AppServices } from "../../components/appServices/useRegisterAppServices"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { setDocAccessCommandDefinition } from "../../components/commands/commandDefinitions/document/setDocAccess"
import { VisibilityToggleButton } from "../../components/VisibilityToggleButton"

export type Props = {
  document: BasicDocumentDetails,
  sharedServicesProvider: AppServices,
  landmarkId?: string,
}

export const DocumentAccessEditor = ({
  document,
  sharedServicesProvider,
  landmarkId,
}: Props) => {

  const { menuStructureProvider } = sharedServicesProvider

  const isVisibleToOrg = useMemo(() => {
    const doc = menuStructureProvider.getMenuItem(document.id)
    if (doc) {
      return doc.isVisibleToOrg
    }
    return false
  }, [document, menuStructureProvider])


  const triggerCommand = useCallback((visibility: boolean) => {
    setDocAccessCommandDefinition.triggerCommand?.({ isVisibleToOrg: visibility, document, name: "", description: "", searchName: "" })
  }, [document])

  if (isVisibleToOrg === undefined) {
    return null
  }
  return (
    <VisibilityToggleButton
      data-landmark={landmarkId}
      isVisible={isVisibleToOrg}
      canToggle={true}
      onToggle={triggerCommand}
      tooltipText={""}
      buttonTitle={isVisibleToOrg ? "Visible to org" : "Visible only to me"}
    />
  )

}
