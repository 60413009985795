import { useCallback, useEffect, useRef } from 'react'
import { Menu, Data } from '@verdi/shared-constants'
import { MenuStructureManager, typesToExcludeFromNestedMenu } from './MenuStructureManager'
import { documentUpdateFields } from '../useUpdateDocument'
import { RecentDocumentsProvider } from './useRecentDocumentProvider'
import { useAppSelector } from '../../../state/storeHooks'
import { DocumentsState } from '../../../state/DocumentsSlice'
import { dispatch } from '../../../state/store'


/** Gets all docs and organizes them in various structures, such as for the side menu
 * 
 *  TODO: Refactor MenuStructureManager to work better with the redux DocumentsSlice
 */
export const useGetMenuStructureForAllDocs = (recentDocumentsProvider: RecentDocumentsProvider) => {

  const { recentDocumentHistory } = recentDocumentsProvider

  const managerRef = useRef<MenuStructureManager>(MenuStructureManager.getSingletonInstance())
  useEffect(() => {
    managerRef.current?.loadMenuData().then(data => {
      if (!data) return
      dispatch(DocumentsState.setMenuItemsStructured(data))
      // setMenuItemsStructured(data)
    })
  }, [])

  const menuItemsStructured = useAppSelector(DocumentsState.getMenuItemsStructured)
  const recentDocumentMenuItems = useAppSelector(DocumentsState.getRecentDocumentMenuItems)

  // const [menuItemsStructured, setMenuItemsStructured] = useState<MenuDataGroups>({
  //   opportunitiesWithMixedChildren: [],
  //   opportunitiesWithOnlyOpportunityChildren: [],
  //   quickNotes: [],
  //   rootLevelDocs: [],
  //   flatMenuItems: [],
  // })
  // const [recentDocumentMenuItems, setRecentDocumentMenuItems] = useState<Menu.MenuItemData[]>([])


  /** As long as graphQL handles mutations, it should call this right after. */
  const onDocumentCreated = useCallback(async (docId: string) => {

    const newDoc = await managerRef.current?.getDocument(docId)
    dispatch(DocumentsState.addMenuItem(newDoc))

    // setMenuItemsStructured(prev => {
    //   const updatedState = hydrateDocumentMenuStructure([...prev.flatMenuItems, newDoc])
    //   return {
    //     ...updatedState
    //   }
    // })
  }, [managerRef])


  /** As long as graphQL handles mutations, it should call this right after. */
  const onDocumentDeleted = useCallback(async (docId: string) => {

    dispatch(DocumentsState.removeMenuItem(docId))
    // setMenuItemsStructured(prev => {
    //   const flatItemsWithoutMe = prev.flatMenuItems.filter(item => item.id !== docId)
    //   const updatedState = hydrateDocumentMenuStructure(flatItemsWithoutMe)
    //   return {
    //     ...updatedState
    //   }
    // })
  }, [])


  /** As long as graphQL handles mutations, it should call this right after. */
  const onDocumentUpdated = useCallback(async (docId: string, fields: documentUpdateFields) => {

    dispatch(DocumentsState.updateMenuItem({ docId, fields }))
    // setMenuItemsStructured(prev => {
    //   const docToUpdate = prev.flatMenuItems.find(item => item.id === docId)
    //   if (!docToUpdate) {
    //     return prev
    //   }
    //   const updatedDoc = {
    //     ...docToUpdate,
    //     ...fields,
    //   }
    //   const flatMenuItems = MenuStructureManager.replaceMenuItem(updatedDoc, prev.flatMenuItems)
    //   const updatedState = hydrateDocumentMenuStructure(flatMenuItems)

    //   return {
    //     ...updatedState
    //   }
    // })
  }, [])



  const getMenuItem = useCallback((id: string) => {
    return menuItemsStructured.flatMenuItems.find(item => item.id === id)
  }, [menuItemsStructured.flatMenuItems])


  /** If none found, returns the doc that was provided 
   * 
   * NOTE: Similar to Menu.getMenuItemHierarchy(), but returns sooner when traversing
  */
  const getClosestParentFoundInMainMenu = useCallback((doc: Menu.MenuItemData, recursionCount = 0): Menu.MenuItemData => {

    if (recursionCount < Data.Menu.maxNestableLevels
      && typesToExcludeFromNestedMenu.includes(doc.type)
      && doc.parentDocId) {
      const parent = getMenuItem(doc.parentDocId)

      if (parent) {
        return getClosestParentFoundInMainMenu(parent, recursionCount + 1)
      }
    }

    return doc

  }, [getMenuItem])


  useEffect(() => {

    const isMenuItem = (item: Menu.MenuItemData | undefined): item is Menu.MenuItemData => {
      return !!item
    }

    const removeChildren = (item: Menu.MenuItemData | undefined): Menu.MenuItemData | undefined => {
      if (!item) {
        return
      }
      const childlessItem: Menu.MenuItemData = {
        id: item.id,
        title: item.title,
        type: item.type,
        isVisibleToOrg: item.isVisibleToOrg,
        createdAt: item.createdAt,
        url: item.url,
        children: [],
      }
      return childlessItem
    }

    const recentItems = recentDocumentHistory.map((dh) => {
      return removeChildren(getMenuItem(dh.id))
    }).filter(isMenuItem)
    // dispatch(DocumentsState.setRecentDocumentMenuItems(recentItems))

    // setRecentDocumentMenuItems(recentDocumentHistory.map((dh) => {
    //   return stripChildren(getMenuItem(dh.id))
    // }).filter(isMenuItem))

  }, [recentDocumentHistory, getMenuItem])


  return {
    menuItemsStructured,
    recentDocumentMenuItems,
    getMenuItem,
    getClosestParentFoundInMainMenu,
    /** Finds the closest opportunity that the given doc is a part of, which may be itself */
    onDocumentCreated,
    onDocumentDeleted,
    onDocumentUpdated,
  }
}

export type MenuStructureForAllDocsProvider = ReturnType<typeof useGetMenuStructureForAllDocs>
