import { Data } from "@verdi/shared-constants"
import { DocumentRelationsData } from '../useDocumentRelationsProvider'


export type RelatedMenuItemData = {
  relation: DocumentRelationsData,
  menuItem: Data.Menu.MenuItemData,
  pathDepth?: number, // Number of steps between the current doc and the related doc
  path?: Data.Menu.MenuItemData[], // Path from the current doc to the related doc
}

type RelatedDocGroup = {
  allDocs: RelatedMenuItemData[],
  assumptionDocs: RelatedMenuItemData[],
  actionDocs: RelatedMenuItemData[],
  conceptDocs: RelatedMenuItemData[],
  insightDocs: RelatedMenuItemData[],
  otherDocs: RelatedMenuItemData[],
}

export type RelatedDocGroups = {
  docsReferencedByCurrentDoc: RelatedMenuItemData[],
  docsThatReferenceCurrentDoc: RelatedMenuItemData[],
  /** Relations where fromId === currentDocId */
  fromMe: RelatedDocGroup,
  /** Relations where toId === currentDocId */
  toMe: RelatedDocGroup,
  // might not need the actual relations below (other than internally), if we are just using MenuItemData[]
  // relationsFromMe: DocumentRelationsData, 
  // relationsToMe: DocumentRelationsData,
}

export const GetEmptyRelatedDocGroups = (): RelatedDocGroups => {
  return {
    docsReferencedByCurrentDoc: [],
    docsThatReferenceCurrentDoc: [],
    fromMe: {
      allDocs: [],
      conceptDocs: [],
      assumptionDocs: [],
      actionDocs: [],
      insightDocs: [],
      otherDocs: [],
    },
    toMe: {
      allDocs: [],
      conceptDocs: [],
      assumptionDocs: [],
      actionDocs: [],
      insightDocs: [],
      otherDocs: [],
    }
  }
}

type RelatedDocsArgs = {
  currentDocId: string,
  relations: DocumentRelationsData[],
  flatMenuItems: Data.Menu.MenuItemData[],
}


/** Gets all related docs related to a given document */
export const groupRelatedDocs = (args: RelatedDocsArgs) => {

  const docsReferencedToMe = getRelatedMenuItemsRelatedToMe(args)
  const docsReferencedFromMe = getDocsRelatedFromMe(args)

  const {
    assumptions: assumptionDocsToMe,
    actions: actionDocsToMe,
    insights: insightsDocsToMe,
    concepts: conceptDocsToMe,
  } = groupByRelationType(docsReferencedToMe)
  const {
    assumptions: assumptionDocsFromMe,
    actions: actionDocsFrom,
    insights: insightsDocsFrom,
    concepts: conceptDocsFrom,
  } = groupByRelationType(docsReferencedFromMe)


  const relatedDocs: RelatedDocGroups = {
    docsReferencedByCurrentDoc: docsReferencedToMe,
    docsThatReferenceCurrentDoc: docsReferencedFromMe,
    fromMe: {
      allDocs: docsReferencedFromMe,
      assumptionDocs: assumptionDocsFromMe,
      conceptDocs: conceptDocsFrom,
      actionDocs: actionDocsFrom,
      insightDocs: insightsDocsFrom,
      otherDocs: [],
    },
    toMe: {
      allDocs: docsReferencedToMe,
      conceptDocs: conceptDocsToMe,
      assumptionDocs: assumptionDocsToMe,
      actionDocs: actionDocsToMe,
      insightDocs: insightsDocsToMe,
      otherDocs: [],
    }
  }


  // console.log("groupRelatedDocs: relatedDocs = ", {
  //   currentDocId,
  //   relatedDocs,
  //   documentRelations: relations,
  // })

  return relatedDocs
}

const groupByRelationType = (relations: RelatedMenuItemData[]) => {

  return {
    assumptions: relations.filter(d => d.relation.type === Data.DocumentRelationType.assumptionOf),
    actions: relations.filter(d => d.relation.type === Data.DocumentRelationType.actionOf),
    insights: relations.filter(d => d.relation.type === Data.DocumentRelationType.insightOf),
    concepts: relations.filter(d => d.relation.type === Data.DocumentRelationType.conceptOf),
    references: relations.filter(d => d.relation.type === Data.DocumentRelationType.referencedBy),
  }
}

export const getRelatedMenuItemsRelatedToMe = ({
  currentDocId, // Me
  relations,
  flatMenuItems,
}: RelatedDocsArgs) => {

  const relationsToMe = relations.filter(relation => relation.toDoc?.id === currentDocId)
  const docsReferencedToMe: RelatedMenuItemData[] = []
  for (const relation of relationsToMe) {
    const doc = flatMenuItems.find(menuItem => menuItem.id === relation.fromDoc?.id)
    if (doc) {
      docsReferencedToMe.push({
        relation: relation,
        menuItem: doc,
      })
    }
  }

  return docsReferencedToMe
}

const getDocsRelatedFromMe = ({
  currentDocId, // Me
  relations,
  flatMenuItems,
}: RelatedDocsArgs) => {

  const relationsFromMe = relations.filter(relation => relation.fromDoc?.id === currentDocId)
  const docsReferencedFromMe: RelatedMenuItemData[] = []
  for (const relation of relationsFromMe) {
    const doc = flatMenuItems.find(menuItem => menuItem.id === relation.toDoc?.id)
    if (doc) {
      docsReferencedFromMe.push({
        relation: relation,
        menuItem: doc,
      })
    }
  }

  return docsReferencedFromMe
}
