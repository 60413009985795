import { Button, Link, Spinner, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useRouter } from "found"
import { DocumentIcon } from "./icons/DocumentIcon"
import { sideMenuItemStyles } from "./ColorModeValues"
import { IconButtonRemove } from "./buttons/IconButtonRemove"
import { VerdiIconAdd, VerdiIconCheck, VerdiIconLock } from "./icons/VerdiIcons"


type Props = {
  title: string
  subTitle?: string
  intendedUserAction?: "remove" | "add",
  url?: string
  onClick?: (evt: any) => void
  onRemoveClick?: (evt: any) => void
  isLoading?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  didCompleteSuccessfully?: boolean
  childCount?: number
  isUntitled?: boolean
  showAsPrivate?: boolean
}


/** Visual representation of a document */
export const DocumentReference = (props: Props) => {


  const renderIcon = () => {

    if (props.showAsPrivate) {
      return <VerdiIconLock
        boxSize={4}
        marginRight={1}
        opacity={0.5} />
    }

    if (props.didCompleteSuccessfully) {
      return <VerdiIconCheck
        boxSize={4}
        marginRight={1}
        opacity={0.5} />
    }

    if (props.isLoading) {
      return <Spinner size='xs'
        boxSize={4}
        marginRight={1}
        opacity={0.5} />
    }

    if (props.intendedUserAction === "add") {
      return <VerdiIconAdd
        boxSize={4}
        marginRight={1}
        opacity={0.5} />
    }

    return <DocumentIcon
      boxSize={4}
      marginRight={1}
      opacity={0.5} />

  }

  const router = useRouter()

  const navigate = (url: string) => {
    router.router.push(url)
  }

  const menuItemStyles = useColorModeValue(sideMenuItemStyles.light, sideMenuItemStyles.dark)


  const renderCore = () => (
    <div css={css`
       .showOnHover {
          opacity: 0;
        }
        &:hover .showOnHover {
          opacity: 1;
        }
        display: flex;
        &:hover {
          opacity: 0.8;
        }
        ${props.isSelected ? `background-color: ${menuItemStyles.selected.bg}` : ""}
        ${props.isUntitled ? "font-style: italic;" : ""}
        ${props.intendedUserAction === "remove" ? `
            justify-content: space-between;
            width: 100%;
          ` : ""}
    `}>
      <Button
        onClick={(event) => {
          if (props.onClick) {
            props.onClick(event)
          }
        }}
        fontWeight="normal"
        disabled={props.isDisabled}
        textAlign="left"
        variant="ghost"
        colorScheme={props.didCompleteSuccessfully ? "green" : "grey"}
        css={css`
          height: auto;
          padding: 7px 10px;
         
         
        `}
      >
        <div css={css`
        display: flex;
        align-items: center;
        white-space: initial;
      `}>
          {renderIcon()}

          <div>
            {props.title}
            {props.subTitle &&
              <small css={css`opacity: 0.4;`}>&nbsp; {props.subTitle}</small>
            }
          </div>

        </div>
      </Button>
      {props.intendedUserAction === "remove" && props.onRemoveClick &&
        <div className="showOnHover">
          <IconButtonRemove
            aria-label="Remove"
            onClick={(evt) => {
              evt.stopPropagation()
              evt.preventDefault()
              props.onRemoveClick?.(evt)
            }}
          />
        </div>
      }
    </div>
  )


  return (
    <div>
      {props.url
        ?
        <Link href={props.url}
          _hover={{ textDecoration: "none", color: "inherit", }}
          onClick={(evt) => {
            evt.preventDefault()
            props.onClick?.(evt)
            navigate(props.url as string)
          }}
        >
          {renderCore()}
        </Link>
        :
        renderCore()
      }
    </div>
  )

}
