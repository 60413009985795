import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { showDocEditorCommandMenuCommandDefinition } from "../utils/showDocEditorCommandMenu"
import { AutoDismissMode } from "../../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"


const cmdType = GlobalCommandType.formatNodeAsTaskCheckbox

export const formatNodeAsTaskCheckboxCommandDefinition: GlobalCommandDefinition<FormatNodeAsTaskCheckboxCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: FormatNodeAsTaskCheckboxCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Task',
        searchName: 'task checkbox todo to-do',
        maxNestingLevel: 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        shouldKeepOpenOnRun: true, // Logic below needs to handle closing the CM
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: FormatNodeAsTaskCheckboxCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('formatNodeAsTaskCheckbox: No editor found in servicesForCurrentDoc')
      return
    }

    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (!currentBlockContext) {
      onProcessingComplete(false)
      console.warn('formatNodeAsTaskCheckbox: No currentBlockContext found in servicesForCurrentDoc')
      return
    }
    const currentLineText = currentBlockContext.currentLineText
    const titleLineHasText = currentLineText ? lineHasText(currentLineText) : false

    editor
      .chain()
      .deleteToLastSlash()
      .convertNodeToType("taskCheckbox")
      .run();

    if (titleLineHasText) {
      editor
        .chain()
        .moveCursorToAdjacentNode("forwards") // Move caret to the child line
        .run();
    }

    setTimeout(() => {
      showDocEditorCommandMenuCommandDefinition.triggerCommand?.({
        onlyShowIfCurrentLineIsBlank: true,
        docCommandMenuOptions: {
          autoDismissMode: AutoDismissMode.whenLineNoLongerBlank,
        }
      })
      /** TODO: Make this setTimeout not needed
       * Looks like currentBlockContext is stale when the CM is opened
       * Resulting in the line suggestions not reflecting that the current block type is now a "section"
       */
    }, 1000)

    onProcessingComplete(true)
  },
}

type FormatNodeAsTaskCheckboxCommandArgs = {
  sectionTitleToAdd?: string
  shouldBeProactive?: boolean
}

/** Checks if a given line has text beyond the command menu search string */
const lineHasText = (line: string) => {
  const indexOfFirstSlash = line.indexOf('/')
  return line.trim().length > 0 && (indexOfFirstSlash === -1 || indexOfFirstSlash > 0)
}
