
import { ApiTypes, Data, DocumentSchema } from "@verdi/shared-constants"
import { useCallback } from "react"
import { useNewDocumentMutation } from "../documents/add/useAddNewDocument"
import { useDeleteDocument } from "../document/useDeleteDocument"
import { DocumentMutableFields, useUpdateDocument } from "../document/useUpdateDocument"
import { MenuStructureForAllDocsProvider } from "../document/organize/useGetMenuStructureForAllDocs"
import { CreateDocumentInput } from "../documents/add/__generated__/useAddNewDocumentAppendMutation.graphql"
import { GetEmptyRelatedDocGroups, RelatedDocGroups, groupRelatedDocs } from "./data/groupRelatedDocs"
import { makeDeleteRequest, makePostRequestJson } from "../../utility-hooks/fetchUtils"
import { useAppSelector } from "../../state/storeHooks"
import { DocRelationsState } from "../../state/docRelationsSlice"
import { OnboardingManager } from "../onboarding/useOnboardingManager"
import { dispatch } from "../../state/store"
import { SuggestionsForDocTitleState } from "../../state/suggestions/suggestionsForDocTitleSlice"
import { useThrottle } from "../../utility-hooks/useThrottle"


/** Manages all document relations. */
export const useDocumentRelationsProvider = (menuStructureProvider: MenuStructureForAllDocsProvider, onboardingManager: OnboardingManager) => {


  const documentRelations = useAppSelector(DocRelationsState.getAll)


  const logCreatedDocActivity = useCallback((newDocType: DocumentSchema.DocumentType) => {
    if (newDocType === DocumentSchema.DocumentType.assumption) {
      onboardingManager.updateProgress("createdAssumptionAt")
    } else if (DocTypesForResearch.includes(newDocType)) {
      onboardingManager.updateProgress("createdActionAt")
    }
  }, [onboardingManager])


  const createRelation = useCallback(async (fromDocId: string, type: Data.DocumentRelationType, toDocId: string) => {
    const body: ApiTypes.DocumentRelationUpdateRequestBody = {
      type,
      fromDocId,
      toDocId,
    }
    const results = await makePostRequestJson("documentRelations", body)
    dispatch(DocRelationsState.add(results))
  }, [])



  /** CREATE with NEW DOC */
  const [createDocument] = useNewDocumentMutation(false)
  const createNewDocWithRelation = useCallback(async (
    args: CreateNewDocWithRelationArgs
  ) => {

    const {
      newDocTitle, newDocType, currentDocId, opportunityId, parentDocId, createFromDocId, newDocDescription,
      initialStepJson, direction, relationType, onError, onComplete
    } = args

    createDocument({
      title: newDocTitle,
      type: newDocType,
      opportunityId,
      parentDocId,
      createFromDocId,
      description: newDocDescription,
      initialStepJson,
    }, menuStructureProvider,
      async (createdDocResponse) => {

        if (!createdDocResponse.createDocument) {
          onError("Failed to add")
          return
        }
        const newDocId = createdDocResponse.createDocument.id
        const fromId = direction === "currentDocIsFrom" ? currentDocId : newDocId
        const toId = direction === "currentDocIsTo" ? currentDocId : newDocId

        await createRelation(fromId, relationType, toId)
        onComplete?.(newDocId)
        logCreatedDocActivity(newDocType)
      })

  }, [createDocument, createRelation, menuStructureProvider, logCreatedDocActivity])


  const createNewDoc = useCallback(async (
    args: CreateDocumentInput,
    onError: (err: string) => void,
    onComplete?: (newDocId: string) => void
  ) => {

    console.log("docRelationsProvider.createNewDoc args ", args)

    createDocument(args, menuStructureProvider,
      async (createdDocResponse) => {

        if (!createdDocResponse.createDocument) {
          onError("Failed to add")
          return
        }
        const newDocId = createdDocResponse.createDocument.id
        onComplete?.(newDocId)
      })

  }, [createDocument, menuStructureProvider])



  const removeDocRelation = useCallback(async (relationId: string) => {
    await makeDeleteRequest(`documentRelationsDelete?id=${relationId}`)
    dispatch(DocRelationsState.remove({ id: relationId }))
  }, [])

  /** DELETE along with the document */
  const [deleteDocument] = useDeleteDocument(menuStructureProvider)
  const removeAndDeleteDoc = useCallback(async (relationId: string, documentId: string) => {
    removeDocRelation(relationId)
    deleteDocument(documentId)
  }, [removeDocRelation, deleteDocument])

  const deleteDoc = useCallback(async (documentId: string, onCompleted?: () => void) => {
    deleteDocument(documentId, onCompleted)
  }, [deleteDocument])


  const { updateDocument } = useUpdateDocument({ menuStructureProvider })
  const updateDocumentDebounced = useThrottle((documentId: string, fields: DocumentMutableFields) => {
    updateDocument(documentId, fields)
  })
  /** Updates the title of the given document, for easy editing of relations list.  */
  const updateDocTitle = useCallback(async (docId: string, newTitle: string) => {
    updateDocument(docId, { title: newTitle })
  }, [updateDocument])
  const updateDocDescription = useCallback(async (docId: string, newDescription: string) => {
    updateDocument(docId, { description: newDescription })
    // Clear out suggested titles for current doc,
    // so that new suggestions will be made using the updated description, when needed.
    dispatch(SuggestionsForDocTitleState.setTitles([]))
  }, [updateDocument])
  const updateIsVisibleToOrg = useCallback(async (docId: string, newValue: boolean) => {
    updateDocument(docId, { isVisibleToOrg: newValue })
  }, [updateDocument])
  const updateDocType = useCallback(async (docId: string, newValue: DocumentSchema.DocumentType) => {
    updateDocument(docId, { type: newValue })
  }, [updateDocument])


  const moveDocToParent = useCallback(async (childDocId: string, newParentId: string | null) => {
    console.log("moveDocToParent ", { childDocId, newParentId })
    updateDocument(childDocId, { parentDocId: newParentId })
  }, [updateDocument])


  /** Returns all relationship mappings from and to the provided documentId */
  const getRelationsForDoc = useCallback((documentId: string): DocumentRelationsData[] => {
    return documentRelations
      .filter(r => r.fromDocId === documentId || r.toDocId === documentId)
      .map(r => ({
        id: r.id,
        type: r.type,
        fromDoc: menuStructureProvider.getMenuItem(r.fromDocId || ""),
        toDoc: menuStructureProvider.getMenuItem(r.toDocId || ""),
      } as DocumentRelationsData))

  }, [documentRelations, menuStructureProvider])


  /** Returns all Docs that have a parentId set to the provided documentId */
  const getChildrenForDoc = useCallback((documentId: string) => {
    const children = menuStructureProvider.menuItemsStructured.flatMenuItems
      .filter(d => d.parentDocId === documentId)
    return children || []
  }, [menuStructureProvider.menuItemsStructured.flatMenuItems])


  /** Returns related docs for a given document, grouped by relationship direction */
  const getRelationsForDocGrouped = useCallback((
    documentId: string
  ) => {
    if (!documentRelations) {
      return GetEmptyRelatedDocGroups()
    }

    const allRelationsForDoc = getRelationsForDoc(documentId)

    const relatedDocs = groupRelatedDocs({
      currentDocId: documentId,
      flatMenuItems: menuStructureProvider.menuItemsStructured.flatMenuItems,
      relations: allRelationsForDoc,
    })

    return relatedDocs
  }, [documentRelations, getRelationsForDoc, menuStructureProvider.menuItemsStructured.flatMenuItems])


  const getRelationsForAi = useCallback((documentId: string) => {
    return getRelationsForDocGrouped(documentId)
  }, [getRelationsForDocGrouped])


  return {
    documentRelations,
    getRelationsForDoc,
    getRelationsForDocGrouped,
    getChildrenForDoc,
    getRelationsForAi,
    createRelation,
    createNewDocWithRelation,
    createNewDoc,
    remove: removeDocRelation,
    removeAndDeleteDoc,
    deleteDoc,
    moveDocToParent,
    updateDocTitle,
    updateDocDescription,
    updateIsVisibleToOrg,
    updateDocType,
    updateDocumentDebounced,
    // documentRelationsConnectionId,
  } as DocumentRelationsProvider
}

export type DocumentRelationsProvider = {
  documentRelations: Data.DocumentRelationDto[],
  getRelationsForDoc: (documentId: string) => DocumentRelationsData[],
  getRelationsForDocGrouped: (documentId: string) => RelatedDocGroups,
  getChildrenForDoc: (documentId: string) => Data.Menu.MenuItemData[],
  getRelationsForAi: (documentId: string) => RelatedDocGroups,
  createRelation: (fromDocId: string, type: Data.DocumentRelationType, toDocId: string) => Promise<void>,
  createNewDocWithRelation: (args: CreateNewDocWithRelationArgs) => void,
  createNewDoc: (args: CreateDocumentInput, onError: (err: string) => void, onComplete?: ((newDocId: string) => void) | undefined) => Promise<void>,
  remove: (relationId: string) => void,
  removeAndDeleteDoc: (relationId: string, documentId: string) => void,
  deleteDoc: (documentId: string, onCompleted?: () => void) => Promise<void>,
  moveDocToParent: (childDocId: string, newParentId: string | null) => Promise<void>,
  updateDocTitle: (docId: string, newTitle: string) => void,
  updateDocDescription: (docId: string, newTitle: string) => void,
  updateIsVisibleToOrg: (docId: string, newValue: boolean) => void,
  updateDocType: (docId: string, newValue: DocumentSchema.DocumentType) => void,
  updateDocumentDebounced: (documentId: string, fields: DocumentMutableFields) => void,
  // documentRelationsConnectionId: string,
}

export type CreateNewDocWithRelationArgs = {
  newDocTitle: string,
  newDocType: DocumentSchema.DocumentType,
  newDocDescription?: string,
  currentDocId: string,
  createFromDocId?: string,
  opportunityId?: string,
  parentDocId: string,
  relationType: Data.DocumentRelationType,
  direction: "currentDocIsFrom" | "currentDocIsTo",
  initialStepJson?: string,
  onError: (err: string) => void,
  onComplete?: (newDocId: string) => void,
}

export const DocTypesForResearch = [
  DocumentSchema.DocumentType.interviewGuide,
  DocumentSchema.DocumentType.interviewNotes,
  DocumentSchema.DocumentType.research,
  DocumentSchema.DocumentType.interview,
  DocumentSchema.DocumentType.guide,
]// export type DocumentRelationsData = ReturnType<typeof useGetDocumentRelations>['documentRelations'][0]


/** Combined models of DocRelation + Document (from + to) */
export type DocumentRelationsData = {
  id: string
  // sortOrder: number | null;
  type: Data.DocumentRelationType
  fromDoc: {
    description?: string | undefined
    id?: string | undefined
    title?: string | null | undefined
    type?: DocumentSchema.DocumentType | undefined
  }
  toDoc: {
    description?: string | undefined
    id?: string | undefined
    title?: string | null | undefined
    type?: DocumentSchema.DocumentType | undefined
  }
}

