import { mergeAttributes, Node } from '@tiptap/core'


type Config = {
  addNodeView?: NonNullable<Parameters<typeof Node.create>[0]>['addNodeView']
}

export const InlineSnippet = (config?: Config) => Node.create({
  blockName: "inlineSnippet",
  name: 'inlineSnippet',
  content: "(text|inlineSnippet)*",
  atom: false,
  isInline: true,
  isBlock: false,
  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      snippetId: { required: true },
      placeholderKey: { required: true },
      textValue: { required: true },
      // lastSyncedTextValue: { required: true } 
    }
  },

  parseHTML() {
    return [
      { tag: 'inline-snippet' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['inline-snippet', mergeAttributes(HTMLAttributes)]
  },

  addNodeView: config?.addNodeView,
})
