import { css } from "@emotion/react"
import { OpportunityRiskBar } from "./OpportunityRiskBar"
import { Data } from "@verdi/shared-constants"
import { DocumentDetailLabel } from "../../documentDetails/DocumentDetailLabel"
import { KanbanCardBadge } from "../../../components/kanban/KanbanCardBadge"
import { OpportunityRiskIcon } from "./OpportunityRiskIcon"


type Props = {
  opportunityRisk?: OpportunityRisk,
  landmarkId?: string,
  onClick?: () => void
}
/** Displayed within the opportunity details panel */
export const OpportunityRiskBadge = ({
  opportunityRisk,
  landmarkId,
  onClick,
}: Props) => {


  if (!opportunityRisk) return null

  let alertStatus: "error" | "info" | "warning" | "success" | "loading" | undefined

  if (opportunityRisk.status === "Low") alertStatus = "success"
  else if (opportunityRisk.status === "High") alertStatus = "error"
  else if (opportunityRisk.status === "Medium") alertStatus = "warning"
  else if (opportunityRisk.status === "Needs Review") alertStatus = "info"


  if (!opportunityRisk) return null


  return (
    <DocumentDetailLabel title="Risk">
      <div
        data-landmark={landmarkId}
        css={css`
        display: flex;
      `}>
        <KanbanCardBadge
          icon={
            <OpportunityRiskIcon
              opportunityRisk={opportunityRisk}
            />
          }
          label={`${opportunityRisk.totalCount || "?"}`}
          toolTip={"Risk Score"}
          onClick={onClick}
        />
        {/* <OpportunityRiskBar
          opportunityRisk={opportunityRisk}
        /> */}
      </div>
    </DocumentDetailLabel>

  )
}


type OpportunityRiskStatus = "Low" | "Medium" | "High" | "Needs Review"
export type OpportunityRisk = {
  status: OpportunityRiskStatus,
  riskScore: number,
  greenCount: number,
  yellowCount: number,
  redCount: number,
  totalCount: number,
}
export const calculateOpportunityRisk = (assumptions: Data.AssumptionMutableFields[]): OpportunityRisk => {

  if (assumptions.length === 0) return {
    status: "Needs Review",
    riskScore: 10,
    greenCount: 0,
    yellowCount: 0,
    redCount: 0,
    totalCount: 0,
  }

  const individualRiskScores = assumptions.map(a => a.relevanceLevel + (5 - a.confidenceLevel))
  const riskScore = Math.max(...individualRiskScores) // individualRiskScores.reduce((prev, curr) => prev + curr, 0) / individualRiskScores.length

  let status: OpportunityRiskStatus = "Low"
  if (riskScore > 7) status = "High"
  else if (riskScore > 5) status = "Medium"

  return {
    status,
    riskScore: Number(riskScore.toFixed(2)),
    greenCount: individualRiskScores.filter(s => s <= 5).length,
    yellowCount: individualRiskScores.filter(s => s > 5 && s <= 7).length,
    redCount: individualRiskScores.filter(s => s > 7).length,
    totalCount: individualRiskScores.length,
  }
}


export const getRiskColor = (assumption: Data.AssumptionMutableFields): "red" | "yellow" | "green" | "transparent" => {
  if (!assumption) return "transparent"
  const combined = assumption.relevanceLevel + (5 - assumption.confidenceLevel)
  if (combined > 7) return "red"
  if (combined > 5) return "yellow"
  return "green"
}
