import { css } from "@emotion/react"


type Props = {
  isVisible: boolean
  text: string
  size?: "xs"
  align?: "right"
}

/** Dynamically shows a hint to the user, similar to an input's placeholder attribute
 *  but with more flexibility in terms of positioning and styling
 */
export const PlaceholderHint = ({
  isVisible,
  text,
  size,
  align,
}: Props) => {

  if (!isVisible) {
    return null
  }


  return (
    <div
      css={css`
      position: relative;
      /* &:hover .emptyDocPlaceholder{
        border:1px solid green;
        .emptyDocPlaceholder {
        color: green;
        }
      } */
    `}>
      <div css={css`
        position: absolute;
        top: 0;
        ${align === "right" ? "right: 0;" : "left: 0;"}
        
        ${size === "xs" ? "font-size: 0.8em;" : ""}
      `}>
        <p className="emptyDocPlaceholder">
          {text}
        </p>
      </div>
    </div>
  )
}
