import { useCallback, useEffect } from 'react'
import React from 'react'
import { css } from '@emotion/react'
import { useRelayEnvironment } from 'react-relay'
import { useRouter } from 'found'
import { ApiTypes } from '@verdi/shared-constants'
import { googleAuthUrl, checkLoginStatus } from './network'
import posthog from "../helpers/posthog";
import { userWasShownOnboardingKey } from '../screens/onboarding/useOnboardingManager'


const googleClientId = () => process.env.REACT_APP_GOOGLE_CLIENT_ID

type Props = {
  setError: (error: string | undefined) => void
  orgInviteToken?: string;
  newOrgName?: string;
  flows?: string[]
}
export const LoginWithGoogle = ({ setError, orgInviteToken, newOrgName, flows = [] }: Props) => {

  const environment = useRelayEnvironment()
  const { router } = useRouter()

  const onGoogleSuccess = useCallback(async (response: any) => {
    posthog.capture('googleAuth', { flows })

    const browserWindow = window as any

    // @ts-ignore idk why the types don't match reality here?
    const code = response.credential as string
    const body: ApiTypes.GoogleOAuthRequestBody = {
      googleIdToken: code,
      orgInviteToken: browserWindow?.tempOrgInfo?.orgInviteToken,
      newOrgName: browserWindow?.tempOrgInfo?.newOrgName,
    }

    try {
      const req = new Request(googleAuthUrl(), {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })

      const fetched = await fetch(req)
      const data = await fetched.json()

      if (data.error) {
        posthog.capture('googleAuthFail', { flows, error: data.error });
        setError(data.error)
      } else {
        posthog.capture('googleAuthSuccess', { flows });
        await checkLoginStatus(environment)
        const hadAlreadyStartedOnboarding = Boolean(localStorage.getItem(userWasShownOnboardingKey))
        router.replace(hadAlreadyStartedOnboarding ? `/` : "/onboarding")
      }
    } catch (error: any) {
      posthog.capture('googleAuthFail', { flows, error });
      setError(error.message);
    }
  }, [environment, router, setError, flows])

  useEffect(() => {
    const browserWindow = window as any

    browserWindow.onGoogleLibraryLoad = () => {
      browserWindow.google.accounts.id.initialize({
        client_id: googleClientId(),
        callback: onGoogleSuccess,
        itp_support: false,
      });

      browserWindow.google.accounts.id.renderButton(document.getElementById("signInWithGoogleDiv"), {
        theme: 'filled_blue',
        size: 'large',
        width: "340px",
      });

    };

    browserWindow.onGoogleSuccess = onGoogleSuccess
    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.appendChild(script);


    return () => {
      browserWindow.onGoogleSuccess = undefined;
      browserWindow.onGoogleLibraryLoad = undefined;
      document.body.removeChild(script)
    }
  }, [onGoogleSuccess]);

  useEffect(() => {
    const browserWindow = window as any

    browserWindow.tempOrgInfo = {
      orgInviteToken,
      newOrgName,
    }
  }, [orgInviteToken, newOrgName])


  return (
    <div id="signInWithGoogleDiv"
      css={css`
        border-radius: 10px;
      & iframe { 
        clip-path: inset(5% 3% 5% 3%);
      }
    `}>
    </div>
  )
}

export default React.memo(LoginWithGoogle)
