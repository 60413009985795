import { useRouter } from "found"
import { useCallback } from "react"
import { getUrlForDocument, getUrlForOpportunityAssumptions } from "../../routes"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { css } from "@emotion/react"
import { useColor } from "../../utility-hooks/useColor"
import { useColorModeValue } from "@chakra-ui/react"
import { documentBackgroundColors } from "../../components/ColorModeValues"


type Props = {
  opportunityMainDocId: string
  currentPage: "assumptions" | "opportunityBrief"
  displayAs: "tabs" | "toggle-button"
}
export const OpportunitySubTabs = ({
  opportunityMainDocId,
  currentPage,
  displayAs,
}: Props) => {

  return null

  // const { router } = useRouter()

  // const navigateToOpportunityBrief = useCallback(() => {
  //   const url = getUrlForDocument(opportunityMainDocId)
  //   router.push(url)
  // }, [opportunityMainDocId, router])

  // const navigateToAssumptions = useCallback(() => {
  //   const url = getUrlForOpportunityAssumptions(opportunityMainDocId)
  //   router.push(url)
  // }, [opportunityMainDocId, router])


  // const borderColor = useColor("surfaceBorder")
  // const activeBgColor = useColorModeValue(documentBackgroundColors.light, documentBackgroundColors.dark);


  // return (
  //   <div>
  //     {displayAs === "toggle-button" &&
  //       <div>
  //         {currentPage === "opportunityBrief" &&
  //           <div>
  //             <ButtonPrimary
  //               label="Assumptions"
  //               onClick={navigateToAssumptions}
  //             />
  //           </div>
  //         }
  //         {currentPage === "assumptions" &&
  //           <div>
  //             <ButtonPrimary
  //               label="Opportunity Brief"
  //               onClick={navigateToOpportunityBrief}
  //             />
  //           </div>
  //         }
  //       </div>
  //     }


  //     {displayAs === "tabs" &&
  //       <div css={css`
  //       width: 100%;
  //       border-bottom: 1px solid ${borderColor};
  //       display: flex;
  //       flex-direction: row;
  //     `}>
  //         <SubTab
  //           label="Opportunity Brief"
  //           isActive={currentPage === "opportunityBrief"}
  //           onClick={() => navigateToOpportunityBrief()}
  //           borderColor={borderColor}
  //           activeBgColor={activeBgColor}
  //         />
  //         <SubTab
  //           label="Assumptions"
  //           isActive={currentPage === "assumptions"}
  //           onClick={() => navigateToAssumptions()}
  //           borderColor={borderColor}
  //           activeBgColor={activeBgColor}
  //         />
  //       </div>
  //     }
  //   </div>
  // )
}


// type SubTabProps = {
//   label: string
//   onClick: () => void
//   isActive: boolean
//   borderColor: string
//   activeBgColor: string
// }
// const SubTab = ({
//   label,
//   onClick,
//   isActive,
//   borderColor,
//   activeBgColor,
// }:
//   SubTabProps) => {
//   return (
//     <div css={css`
//       border: 1px solid transparent;
//       border-top-right-radius: 8px;
//       border-top-left-radius: 8px;
//       font-size: 0.9rem;
//       font-weight: bold;
//       ${isActive && `
//         margin-bottom: -2px;
//         background-color: ${activeBgColor};
//         border-color: ${borderColor};
//         border-bottom-color: transparent;
//         cursor: default;
//       `}
//       ${!isActive && `
//         cursor: pointer;
//         opacity: 0.9;
//         &:hover {
//           opacity: 0.7;
//         }
//       `}
//       `}
//       onClick={!isActive ? onClick : undefined}
//     >
//       <div css={css`
//         padding: 8px 16px;
//       `}>
//         {label}
//       </div>
//     </div>
//   )
// }
