import { RecursiveGraphQLBlock } from "../BlockEditorDataHandler";
import { ProsemirrorNodes } from "@verdi/shared-constants";

export const makeBlockEditorReady = (
  graphqlBlock: RecursiveGraphQLBlock,
  makeCopy = false
): ProsemirrorNodes.NestableBlock => {
  const {
    text,
    type: relayType,
    questionType,
    children,
    id: idFromBlock,
    originalBlockId,
    sourceBlockId,
    transcriptTimestamp,
  } = graphqlBlock;
  const type = relayType as ProsemirrorNodes.NestableBlock["type"];
  const editorReadyChildren = (children?.map((block) =>
    makeBlockEditorReady(block, makeCopy)
  ) || undefined) as ProsemirrorNodes.NestableBlock[];
  let editableBlock;
  const attributes = makeCopy
    ? {
        id: undefined,
        sourceBlockId: idFromBlock,
        originalBlockId: originalBlockId || idFromBlock,
      }
    : {
        id: idFromBlock,
        sourceBlockId: sourceBlockId || undefined,
        transcriptTimestamp: transcriptTimestamp || undefined,
        originalBlockId: originalBlockId || undefined,
      };
  switch (type) {
    case "question":
      editableBlock = ProsemirrorNodes.makeQuestionBlock(
        text,
        editorReadyChildren,
        attributes
      );
      break;
    case "section":
      editableBlock = ProsemirrorNodes.makeSectionBlock(
        text,
        editorReadyChildren,
        attributes
      );
      break;
    default:
      editableBlock = ProsemirrorNodes.makeFreeTextBlock(
        text,
        editorReadyChildren,
        attributes
      );
      break;
  }
  return editableBlock;
};
