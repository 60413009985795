import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


// TODO: remove this OR reconcile this with: `Data.Tags.DefaultTagToDocTypeMapping`
const defaultTags: Tag[] = [
  // {
  //   title: "Outcome",
  //   tagType: "Outcome",
  // },
  // {
  //   title: "Problem",
  //   tagType: "Problem",
  // },
  // {
  //   title: "Solution",
  //   tagType: "Solution",
  // },
  // {
  //   title: "Assumption",
  //   tagType: "Assumption",
  // }
]


interface InternalState {
  tags: Tag[]
}
const initialState: InternalState = {
  tags: defaultTags,
}

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setAllIncludeDefaults: (state, action: PayloadAction<Tag[]>) => {
      const defaultsNotCovered = defaultTags.filter(dt => !action.payload.find(t => t.title === dt.title))
      state.tags = defaultsNotCovered.concat(action.payload)
    },
    addTag: (state, action: PayloadAction<Tag>) => {
      const { id, title } = action.payload

      // check if a tag exists with the same title but no Id (is either a default or suggested by the AI)
      const lowercaseTitle = title.toLowerCase()
      const existingWithoutId = state.tags.find(t => t.title === lowercaseTitle && !t.id)
      if (existingWithoutId) {
        // Just update the existing tag with the new id and title
        existingWithoutId.id = id
        existingWithoutId.title = title
        return
      }

      // Ensure we do not add a duplicate tag
      const existing = state.tags.find(t => t.id === id)
      if (!existing) {
        state.tags.push(action.payload)
      }

    },
    removeTag: (state, action: PayloadAction<{ tagId: string }>) => {
      state.tags = state.tags.filter(t => t.id !== action.payload.tagId)
    },
  },
  selectors: {
    getAll: (state) => state.tags,
    getTagByTitle: (state, title: string) => {
      const lowercaseTitle = title.toLowerCase()
      return state.tags.find(t => t.title.toLowerCase() === lowercaseTitle)
    },
  }
})
export const TagsState = {
  ...tagsSlice.actions,
  ...tagsSlice.selectors,
}

export default tagsSlice.reducer

export type Tag = {
  id?: string
  title: string
  tagType?: TagType
}


/** Only for tags that need hard-coded behavior.
 * 
 *  Hoping to phase this out if possible, and favor passing through data with less custom logic.
 */
export type TagType = "Outcome" | "Problem" | "Solution" | "Assumption"
