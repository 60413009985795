import { Divider } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { IconWrapper } from "../icons/IconWrapper"


type Props = {
  title?: string
  titleNode?: React.ReactNode
  description: string | React.ReactNode
  iconLeft?: React.ReactNode
  titleSize?: "xl"
}
export const PageHeading = ({
  title,
  titleNode,
  description,
  iconLeft,
  titleSize,
}: Props) => {



  return (
    <div css={css`
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    `}
    >

      <h1 css={css`
        ${titleSize === "xl" ? css`font-size: 2rem;` : ""}
      `}>

        {titleNode
          ? titleNode
          : <>
            <IconWrapper
              icon={iconLeft}
            />
            {title}
          </>
        }


      </h1>

      <Divider />

      <div css={css`
        opacity: 0.7;
      `}>
        {description}
      </div>

    </div>
  )
}
