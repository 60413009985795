
import { css } from "@emotion/react"
import { VerdiIconCompanyProfile } from "../../../components/icons/VerdiIcons"
import { PageHeading } from "../../../components/pageLayout/PageHeading"
import { CompanyContextEditor } from "../../organization/companyContext/CompanyContextEditor"
import { OnboardingManager } from "../useOnboardingManager"
import { mediaQuerySmallWidth } from "../../../components/pageLayout/styleHelpers"
import { formInputLabelWidth } from "../../../components/forms/FormInputText"


type Props = {
  onboardingManager: OnboardingManager
}
export const OnboardingStepCompanyInfo = ({
  onboardingManager,
}: Props) => {


  return (
    <div css={css`
         margin-right: ${formInputLabelWidth}px;
          @media ${mediaQuerySmallWidth} {
            margin-right: 0;
          }
      `}>
      <div css={css`
            margin-left: ${formInputLabelWidth}px;
            @media ${mediaQuerySmallWidth} {
              margin-left: 0;
            }
        `}>
        <PageHeading
          title="Company Context"
          titleSize="xl"
          description="Align AI results to your company business model and strategy."
          // description={
          //   <p>
          //     Verdi will contextualize it’s responses and suggestions based on the company context you give it.<br />
          //     Start with your marketing site to give Verdi some context to go on.
          //   </p>
          // }
          iconLeft={<VerdiIconCompanyProfile />}
        />
      </div>

      <CompanyContextEditor
        onboardingManager={onboardingManager}
      />

    </div>
  )
}
