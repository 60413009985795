import { css } from "@emotion/react"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { SubSectionWrapper } from "../opportunityDocument/subMenu/SubSectionWrapper"
import { useMemo } from "react"
import { AssumptionConfidenceEditor } from "./AssumptionConfidenceEditor"
import { AssumptionRelevanceEditor } from "./AssumptionRelevanceEditor"
import { useAppSelector } from "../../state/storeHooks"
import { AssumptionsState } from "../../state/assumptionsSlice"
import { DocumentDetailLabel } from "../documentDetails/DocumentDetailLabel"


type Props = {
  document: BasicDocumentDetails,
}

/** Shows relationships and other data about a given document */
export const AssumptionDetailsPanel = ({
  document,
}: Props) => {

  const allAssumptions = useAppSelector(AssumptionsState.getAllAssumptions)

  const assumption = useMemo(() => {
    if (!document.id) return null
    return allAssumptions.find(assumption => assumption.mainDoc?.id === document.id) || null
  }, [allAssumptions, document.id])


  if (!assumption) {
    return null
  }


  return (
    <div>
      {document.id &&
        <SubSectionWrapper
          title="Assumption Status"
          initialAccordionState="expanded"
        >
          <div
            css={css`
                min-height: 20px;
                margin-top: 8px;
                margin-left: 8px;
              `}
          >
            <DocumentDetailLabel title="Confidence" >
              <AssumptionConfidenceEditor assumption={assumption} />
            </DocumentDetailLabel>

            <DocumentDetailLabel title="Relevance" >
              <AssumptionRelevanceEditor assumption={assumption} />
            </DocumentDetailLabel>


          </div>
        </SubSectionWrapper>
      }

    </div >
  )
} 
