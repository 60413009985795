import { useCallback, useMemo, useState } from "react"
import { Alert, AlertDescription, AlertIcon, AlertTitle, useDisclosure } from "@chakra-ui/react"
import { scrapeDataFromUrl } from "./scrapeDataFromUrl"
import posthog, { posthogCallback } from "../../helpers/posthog"
import { FormInputText, formInputLabelWidth } from "../forms/FormInputText"
import { ConfirmDialog } from "../forms/ConfirmDialog"
import { useKeyDownHandlerOnFocus } from "../floatingBlockContext/useKeyDownHandler"
import { ButtonPrimary } from "../buttons/ButtonPrimary"
import { css } from "@emotion/react"
import { ButtonSecondary } from "../buttons/ButtonSecondary"


type Props = {
  placeholderText: string,
  urlToScrapeInitialValue: string,
  /** When true, will show a confirmation dialog before overwriting */
  hasExistingValues: boolean,
  onUpdateUrl: (value: string) => void,
  onScrapeComplete: (wasSuccessful: boolean, rawResults: any) => void,
  shouldEmphasizeScrapeButton?: boolean,
}
export const UrlScraperInput = ({
  placeholderText,
  urlToScrapeInitialValue,
  hasExistingValues,
  onUpdateUrl,
  onScrapeComplete,
  shouldEmphasizeScrapeButton,
}: Props) => {

  const [urlToScrape, setUrlToScrape] = useState(urlToScrapeInitialValue)
  const [errorMessage, setErrorMessage] = useState("")

  const updateWebsiteUrl = useCallback((value: string) => {
    // TODO: Remove new line characters and smartly handle adding https:// ???
    if (errorMessage) setErrorMessage("")
    const sanitized = value.replace("\n", "")
    setUrlToScrape(sanitized)
    onUpdateUrl(sanitized)

  }, [setUrlToScrape, onUpdateUrl, errorMessage])


  const websiteUrlIsValid = useMemo(() => {
    if (urlToScrape) {
      return urlToScrape.length > 5 && urlToScrape.includes(".")
    }
    return false
  }, [urlToScrape])


  const confirmOverwriteDisclosure = useDisclosure()
  const [isCurrentlyScraping, setIsCurrentlyScraping] = useState(false)
  const scrapeWebsiteDataAfterConfirm = useCallback(async (hasExistingValues: boolean, url: string) => {
    setIsCurrentlyScraping(true)
    console.log("will now scrape this url ", url)
    posthog.capture('scrapeConfirmed', { hasExistingValues, flows: ['companyContext'] });

    let results
    let wasSuccessful = false
    try {
      results = await scrapeDataFromUrl(url)
      posthog.capture('scrapeSuccess', { flows: ['companyContext'] });
      console.log("UrlScraperInput: On Scraped Complete: ", results)
      wasSuccessful = true
      onScrapeComplete(true, results)

    } catch (error) {
      posthog.capture('scrapeFail', { error, flows: ['companyContext'] });
      onScrapeComplete(false, error)
    }

    if (!wasSuccessful || Object.keys(results).length === 0) {
      setErrorMessage("Unable to scrape data from that website")
    }

    setIsCurrentlyScraping(false)
  }, [setIsCurrentlyScraping, onScrapeComplete])

  const scrapeWebsite = useCallback(async () => {
    let urlToUse = urlToScrape.trim()
    if (!urlToScrape.startsWith("http")) {
      urlToUse = "https://" + urlToScrape
      updateWebsiteUrl(urlToUse)
    }
    // Confirm if destructive (if it will replace user entered values)
    if (hasExistingValues) {
      confirmOverwriteDisclosure.onOpen()
      posthog.capture('scrapeConfirm', { flows: ['companyContext'] });
      return
    }

    await scrapeWebsiteDataAfterConfirm(false, urlToUse)

  }, [urlToScrape, hasExistingValues, updateWebsiteUrl, confirmOverwriteDisclosure, scrapeWebsiteDataAfterConfirm])




  const [hasFocus, setHasFocus] = useState(false)
  useKeyDownHandlerOnFocus((keyEvent) => {
    if (keyEvent.key === 'Enter') {
      if (!websiteUrlIsValid || isCurrentlyScraping) return
      keyEvent.preventDefault() // Otherwise the modal will lose focus and close
      scrapeWebsite()
    }
  }, hasFocus)


  return (
    <div>

      <FormInputText
        label="Website"
        value={urlToScrape}
        placeholder={placeholderText || "https://"}
        onChange={(value) => updateWebsiteUrl(value)}
        bottomText="Verdi uses this website url to scrape context for your company profile."
        onFocus={() => {
          setHasFocus(true)
          posthogCallback(undefined, 'fieldFocus', { field: 'website', flows: ['companyContext'] })
        }}
        onBlur={() => setHasFocus(false)}
      />
      <div css={css`
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
        @media (min-width: 800px) {
          margin-left: ${formInputLabelWidth}px;
        }
      `}>
        {shouldEmphasizeScrapeButton ?
          <ButtonPrimary
            label={"Build your company profile"}
            onClick={posthogCallback(() => scrapeWebsite(), 'scrape', { flows: ['companyContext'] })}
            isLoading={isCurrentlyScraping}
            isDisabled={!websiteUrlIsValid}
            loadingText="Scraping ..."
          />
          :
          <ButtonSecondary
            label={"Scrape your website again"}
            onClick={posthogCallback(() => scrapeWebsite(), 'scrape', { flows: ['companyContext'] })}
            isLoading={isCurrentlyScraping}
            isDisabled={!websiteUrlIsValid}
            loadingText="Scraping ..."
          />
        }

      </div>

      {errorMessage &&
        <div>
          <Alert
            status='warning'
            size="lg"
            display="flex"
            flexDirection="row"
          >
            <AlertIcon />
            <div>
              <AlertTitle>
                {errorMessage}
              </AlertTitle>
              <AlertDescription>
                <ul>
                  <li>
                    Please ensure the url is correct.
                  </li>
                  <li>
                    Alternatively you can directly edit any of the fields below.
                  </li>
                </ul>
              </AlertDescription>
            </div>
          </Alert>
        </div>
      }

      <ConfirmDialog
        dialogBodyText="This will replace any existing values you have entered. Are you sure you want to continue?"
        heading="Overwrite existing values?"
        disclosure={confirmOverwriteDisclosure}
        onConfirmSuccess={() => scrapeWebsiteDataAfterConfirm(true, urlToScrape)}
        confirmButtonLabel="Continue"
        isDestructiveAction={true}
        onCancel={posthogCallback(undefined, 'scrapeCancel', { flows: ['companyContext'] })}
      />

    </div>
  )
}
