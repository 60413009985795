import { AiSuggestions, ApiTypes } from "@verdi/shared-constants"
import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsDocBodyMarkCreateCommandArgs } from "../../utils/cleanupAiOutput"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { Audience, AudiencePerspectivesState } from "../../../state/suggestions/AudiencePerspectivesSlice"
import { getCurrentAppState } from "../../../state/store"
import { getTargetAudientContext } from "../../promptUtils/getTargetAudientContext"


/** Generates multiple AI driven suggestions anywhere in the document body, based on the current target audience */
export const makeReviewAsAudienceAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  limitResultsTo: number,
) => {

  const allContext: AllContextForAi = await getContextForAi({
    includeMessages: {
      document: false,
    }
  })

  const currentAudience = AudiencePerspectivesState.getCurrentAudience(getCurrentAppState())

  const promptContext = getInlineDocSuggestionsPromptContext(allContext.currentDoc)
  const messageWithContext: ApiTypes.RunAiChatRequestMessage = {
    role: ApiTypes.ChatRoleType.system,
    content: promptContext,
  }
  const messageWithAudience: ApiTypes.RunAiChatRequestMessage = {
    role: ApiTypes.ChatRoleType.system,
    content: getTargetAudientContext(currentAudience),
  }

  const promptWithInstructions = getPrompt(allContext.currentDoc, currentAudience, limitResultsTo)
  console.log("makeReviewAsAudienceAiCall", { currentAudience, prompt: promptWithInstructions })

  const response = await makeAiChatRequest({
    messages: [
      ...allContext.promptMessages,
      messageWithAudience,
      messageWithContext,
    ],
    prompt: promptWithInstructions,
  })

  console.log("makeReviewAsAudienceAiCall", { response })

  // // Prompt Deubgging
  // const multipleRawTexts = (response?.aiResponseJson as ApiTypes.AiResponseJson).contentOptions
  // const countPerRawText = multipleRawTexts?.map((rawText) => {
  //   const commandArgs = cleanupAiOutputAsDocBodyMarkCreateCommandArgs(rawText)
  //   return commandArgs.length
  // })
  // console.log("makeReviewAsAudienceAiCall", { limitResultsTo, countPerRawText })

  const rawText = response?.aiResponseJson.content as string || ""
  const commandArgs = cleanupAiOutputAsDocBodyMarkCreateCommandArgs(rawText)
  if (Boolean(limitResultsTo) && commandArgs.length > limitResultsTo) {
    console.warn("makeReviewAsAudienceAiCall", `Only returning the first ${limitResultsTo}/${commandArgs.length} results.`)
    return commandArgs.slice(0, limitResultsTo)
  }
  return commandArgs
}


/** Sent as a separate chat message to the AI, to avoid the AI suggesting things that are NOT found in the document body */
export const getInlineDocSuggestionsPromptContext = (
  docContext?: DocumentContextForAi,
) => {

  const prompt = `
${docContext?.title ? `\nCURRENT DOCUMENT TITLE: ${docContext?.title}` : ""}
`
  return prompt
}


export const getPrompt = (
  docContext?: DocumentContextForAi,
  currentAudience?: Audience,
  limitResultsTo?: number,
) => {

  const commands = AiSuggestions.AISuggestionCommandOptions
    .filter((cmd) => cmd.id === "gap") // For now, only allow "replace" suggestions
    .map((cmd) => {
      return `{id: "${cmd.id}", description: "${cmd.description}" }`
    })

  const prompt = `
### BEGIN CONTEXT

START DOCUMENT BODY: 

${docContext?.bodyPartsAsMarkdown?.allLines || ""}

END DOCUMENT BODY

AVAILABLE COMMANDS:
${commands.join(",\n")}

### END CONTEXT

INSTRUCTIONS: 
You are editing the current document body to make it more detailed for the TARGET AUDIENCE (${currentAudience?.title}). 
Take on that target audience's perspective and think, what specific, concise questions do you want to ask to curate the current document body 
for the things that this target audience needs to know? 
There will be plenty of other questions you may want to ask outside of the target audience's perspective. 
For now, focus exclusively on this one audience and the types of questions they would ask. 
Ask as many concise questions as the target audience would about the current document body. 
Keep your questions concise by not repeating text that is in the current document body in your question.

${limitResultsTo && limitResultsTo > 0 ?
      `Stop reviewing once you have ${limitResultsTo} commands generated. 
Do not generate more than ${limitResultsTo} edits.
Only return up to ${limitResultsTo} lines in your response.
      ` : ""}

RESPONSE INSTRUCTIONS:
In your response, provide the name of the command to run(gaps), the verbatim text to highlight from the current document body, and the concise question you want to ask all pipe-delimited.
It is essential that you return the verbatim text from the current document body. This will allow the audience to know exactly what you have questions about.
RESPONSE FORMAT EXAMPLE:
commandName|the verbatim text to highlight from the document body|The concise, specific question that the target audience would ask.

EXAMPLE 1:
IF the provided DOCUMENT BODY contained: "Run a series of user interviews for us to be learning." 
and the audience is: "Product Manager"
THEN the response could be: 
gaps|Run a series|What is our test criteria for this series of interviews?
gaps|user interviews|Who will we be interviewing?
gaps|for us to be learning|What are the target outcomes of these user interviews?

EXAMPLE 2:
IF the provided DOCUMENT BODY contained: "Increase the efficiency of all the things in the app."
and the audience is: "Engineer"
THEN the response could be: 
gaps|increase the efficiency|What is our step-by-step plan to increase the efficiency of certain features?
gaps|all things in the app|What exact features are we trying to increase the efficiency of?
`

  return prompt
}

