import { useCallback } from 'react';
import { css } from '@emotion/react';
import { SelectCommandDropdown } from '../commands/miniCommandMenu/SelectCommandDropdown';
import { showMenuItemSearchCommandDefinition } from '../commands/commandDefinitions/utils/showMenuItemSearch';


type Props = {
}
export const MenuItemSearchButton = ({
}: Props) => {

  const triggerCommand = useCallback((rect: DOMRect) => {
    showMenuItemSearchCommandDefinition.triggerCommand?.({
      rect,
    })
  }, [])

  return (
    <SelectCommandDropdown
      css={css`
        width: 100%;
        justify-content: start;
        font-size: 0.9em;
        opacity: .5;
        border: none;
      `}
      size="md"
      label={'Search'}
      commandOptions={triggerCommand}
      onItemSelected={() => { }}
      searchPlaceholderText={'Search for a document'}
    />
  )
}
