import { StyleFunctionProps, defineStyle, defineStyleConfig } from "@chakra-ui/react"

const outlineButton = defineStyle((props: StyleFunctionProps) => ({
  backgroundColor: "transparent",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: props.colorMode === "dark" ? "#FFFFFF44" : "#00000022",
  fontWeight: "normal",
  ':hover': {
    backgroundColor: props.colorMode === "dark" ? "rgba(255, 255, 255, 0.16)" : "#E2E8F0",
  },
  ':active': {
    backgroundColor: props.colorMode === "dark" ? "rgba(255, 255, 255, 0.24)" : "#CBD5E0",
  }
}))

export const buttonTheme = defineStyleConfig({
  variants: {
    outline: outlineButton
  }
})