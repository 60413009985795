import { StyleFunctionProps, defineStyle, defineStyleConfig } from "@chakra-ui/react"

const modalBase = defineStyle((props: StyleFunctionProps) => ({
  dialog: {
    background: props.colorMode === "dark" ? "var(--chakra-colors-surfaceOverlay-dark)" : "var(--chakra-colors-surfaceOverlay-light)",
    border: `1px solid ${props.colorMode === "dark" ? "var(--chakra-colors-surfaceBorder-dark)" : "var(--chakra-colors-surfaceBorder-light)"}`,
    padding: "12px",
  },
  header: {
    padding: "0px 0px 12px 0px",
  },
  body: {
    padding: "0px",
  },
  footer: {
    borderTop: `1px solid ${props.colorMode === "dark" ? "var(--chakra-colors-surfaceBorder-dark)" : "var(--chakra-colors-surfaceBorder-light)"}`,
    padding: "12px 0px 0px 0px",
    marginTop: "12px",
  }
}))

export const modalTheme = defineStyleConfig({
  baseStyle: modalBase
})