import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { makeFrameworkSuggestionsForDocCall } from "../../../../ai/suggestions/frameworkSuggestionsForDocAiCall";


const cmdType = GlobalCommandType.suggestDocFramework


/** Asks the AI what frameworks are most applicable to the current doc */
export const suggestDocFrameworkCommandDefinition: GlobalCommandDefinition<SuggestDocFrameworkArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SuggestDocFrameworkArgs) => {
    const title = "Suggest a framework ..."
    const toReturn = {
      name: title,
      searchName: title.toLowerCase(),
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
      iconType: "framework",
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SuggestDocFrameworkArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const suggestedFrameworks = await makeFrameworkSuggestionsForDocCall(services.getContextForAi)

    console.log("suggestDocFrameworkCommandDefinition: suggestedFrameworks", { suggestedFrameworks })

    // TODO: update redux state with the suggested frameworks
    // TODO: Look at all references of `makeFrameworkSuggestionsForDocCall` and see where else this command can be used

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: SuggestDocFrameworkArgs,
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SuggestDocFrameworkArgs = {

}
