import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { InsertPosition } from "../../../../../ai/documents/InsertPosition"
import { writeContentToDocBody } from "../writeToDocBody"


const cmdType = GlobalCommandType.replaceMarkWithText


export const replaceMarkWithTextCommandDefinition: GlobalCommandDefinition<ReplaceMarkWithTextCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ReplaceMarkWithTextCommandArgs) => {
    const title = args.commandTitle
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ReplaceMarkWithTextCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      console.warn("replaceMarkWithText: No doc editor found")
      onProcessingComplete(false)
      return
    }

    writeContentToDocBody({
      editor,
      contentToInsert: args.textToWrite,
      insertPosition: args.InsertPosition,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: ReplaceMarkWithTextCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ReplaceMarkWithTextCommandArgs = {
  commandTitle: string
  textToWrite: string
  /** Where to insert the text to write. The mark will be removed regardless. */
  InsertPosition: InsertPosition
}

