import { css } from "@emotion/react"


type Props = {
  title: string | undefined
  onClick: () => void
}
export const SubMenuButton = ({
  title,
  onClick,
}: Props) => {

  return (
    <div
      title={title || undefined}
      onClick={onClick}
      css={css`
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        /* :hover {
          opacity: 0.7;
          text-overflow: unset;
          white-space: unset;
          overflow: visible;
        } */
        `}>
      {title || "Untitled"}
    </div>
  )
}
