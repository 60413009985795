/**
 * @generated SignedSource<<8521daa44b913592ba0196052fac2925>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AiPromptPreview_otherBlockLevelPrompts$data = ReadonlyArray<{
  readonly dontDo: string | null;
  readonly " $fragmentType": "AiPromptPreview_otherBlockLevelPrompts";
}>;
export type AiPromptPreview_otherBlockLevelPrompts$key = ReadonlyArray<{
  readonly " $data"?: AiPromptPreview_otherBlockLevelPrompts$data;
  readonly " $fragmentSpreads": FragmentRefs<"AiPromptPreview_otherBlockLevelPrompts">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AiPromptPreview_otherBlockLevelPrompts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dontDo",
      "storageKey": null
    }
  ],
  "type": "DocumentAiPrompt",
  "abstractKey": null
};

(node as any).hash = "5ba7bf66a7841212d3bef422a19c79b6";

export default node;
