/**
 * @generated SignedSource<<d60eba5557d9109255e4de0938386ea9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentTemplateSideMenuItem_documentTemplate$data = {
  readonly document: {
    readonly description: string | null;
    readonly id: string;
    readonly title: string | null;
    readonly type: DocumentType;
  };
  readonly id: string;
  readonly isPublished: boolean;
  readonly title: string | null;
  readonly " $fragmentType": "DocumentTemplateSideMenuItem_documentTemplate";
};
export type DocumentTemplateSideMenuItem_documentTemplate$key = {
  readonly " $data"?: DocumentTemplateSideMenuItem_documentTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentTemplateSideMenuItem_documentTemplate">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentTemplateSideMenuItem_documentTemplate",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "document",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DocumentTemplateInfo",
  "abstractKey": null
};
})();

(node as any).hash = "36ab56e675ae5c5d623bc70221f0fb17";

export default node;
