import { Data } from "@verdi/shared-constants"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiIconRelevanceLevel } from "../../../icons/VerdiIcons"



const cmdType = GlobalCommandType.setAssumptionRelevance

export const setAssumptionRelevanceCommandDefinition: GlobalCommandDefinition<SetAssumptionRelevanceCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetAssumptionRelevanceCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: args.option.label,
        description: '',
        searchName: args.option.label.toLowerCase(),
        icon: VerdiIconRelevanceLevel({ level: args.option.value }),
        runCommand: () => {
          if (args.onSelected) {
            args.onSelected(args.option)
          } else {
            triggerGlobalCommand(cmdType, args)
          }
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: SetAssumptionRelevanceCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {
    if (!args.assumption) {
      onProcessingComplete(true)
      return
    }
    const { assumption, option } = args
    const { assumptionsProvider } = services
    assumptionsProvider.updateField({
      assumptionId: assumption.id,
      field: "relevanceLevel",
      value: option.value,
      mainDocId: assumption.mainDoc?.id || "",
    })
    onProcessingComplete(true)
  },
  triggerCommand: (args: SetAssumptionRelevanceCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type SetAssumptionRelevanceCommandArgs = {
  assumption?: Data.AssumptionModel
  option: Data.AssumptionRelevanceLevelOption
  onSelected?: (option: Data.AssumptionRelevanceLevelOption) => void
}
