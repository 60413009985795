import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import "./tiptap.css";
import { extensionsForEditor } from "./extensions/extensions";
import { CommandMenuRef } from "./commandMenu/CommandMenuWrapper";
import { useTipTapData } from "./utils/useTipTapData";
import { useSaveTipTapData } from "./utils/useSaveTipTapData";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { TipTapEditor_document$key } from "./__generated__/TipTapEditor_document.graphql";
import { TipTapEditor_query$key } from "./__generated__/TipTapEditor_query.graphql";
import { LoadingIndicatorPortal } from "../LoadingIndicatorPortal";
import { applyDevTools } from "prosemirror-dev-toolkit";
import { handlePaste, transformPasted } from "./extensions/events/CopyPaste";
import { findParentNestableNodeClosestToPos } from "./utils/findParentNestableNode";
import { ZoomTranscriptForDocument } from "../../zoom/ZoomDetailsForDocument";
import { EditorState } from "prosemirror-state";
import { DocumentSubscription } from "../../../components/documentSubscriptions/useDocumentSubscription";
import { useCollabEditor } from "./useCollabEditor";
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants";
import { generateClientId } from "./utils/clientIdGenerator";
import { EditorProps, EditorView } from "prosemirror-view";
import { CurrentBlockContext, getCurrentBlockContext, } from "./utils/getCurrentBlockContext";
import { FloatingBlockContext } from "../../../components/floatingBlockContext/FloatingBlockContext";
import { useWindowResize } from "../../../utility-hooks/useWindowResize";
import { PageLayoutState } from "../../../components/pageLayout/usePageLayoutState";
import { AppServices } from "../../../components/appServices/useRegisterAppServices";
import { useRegisterDocServices } from "../docServices/useRegisterDocServices";
import { DocEditorContextWrapper } from "../docServices/DocEditorContext";
import { BasicDocumentDetails } from "../BasicDocumentDetails";
import { useFeatureFlags } from "../../../utility-hooks/useFeatureFlags";
import { css } from "@emotion/react";
import { IconButtonSecondary } from "../../../components/buttons/IconButtonSecondary";
import { VerdiIconRefresh } from "../../../components/icons/VerdiIcons";
import { AiDocSuggestionsList } from "../../../components/aiComponents/AiDocSuggestionsList";
import { CommandListLimitTo } from "../../../components/commands/commandMenuData/CommandListLimitTo";
import { GenerateAiFlowButton } from "./utils/GenerateAiFlowButton";
import { getPlaceholderSuggestionsCommandDefinition } from "../../../components/commands/commandDefinitions/documentBody/placeholders/loadCurrentPlaceholderSuggestions";
import { useHandleMarkClicked } from "../../../components/floatingBlockContext/useHandleMarkClicked";
import { useSyncDocBodyWithDatabase } from "../dataSync/useSyncDocBodyWithDatabase";
import { setFocusToCommandDefinition } from "../../../components/commands/commandDefinitions/utils/setFocusTo";


const fragmentQL = graphql`
  fragment TipTapEditor_document on Document {
    id
    type
    title
    description
    opportunities {
      title
      description
    }
    ...useTipTapData_document
    currentSnapshot {
      body
      version
    }
    templateInfo {
      id
    }
    origin {
      id
      aiPrompts {
        id
        instructionsForUser
      }
    }
  }
`;

const queryFragmentQL = graphql`
  fragment TipTapEditor_query on Query {
    loggedInUser {
      id
      email
    }
  }
`;

type Props = {
  pageLayoutState: PageLayoutState;
  document: TipTapEditor_document$key;
  setTipTapData: (content: ProsemirrorNodes.TipTapDocument) => void;
  showDevTools?: boolean;
  selectedZoomTimestamp?: string;
  scrollToTranscriptRef: RefObject<ZoomTranscriptForDocument>;
  documentSubscription: DocumentSubscription;
  /** @deprecated. Only used for old Template Editor */
  onCurrentBlockContextChange?: (args: any) => void;
  query: TipTapEditor_query$key;
  onShiftTab?: () => void;
  handleClick?: EditorProps["handleClick"];
  sharedServicesProvider: AppServices;
};



export const TiptapEditor = ({
  pageLayoutState,
  document: documentKey,
  query: queryKey,
  setTipTapData,
  showDevTools = false,
  selectedZoomTimestamp,
  scrollToTranscriptRef,
  documentSubscription,
  onCurrentBlockContextChange,
  onShiftTab,
  handleClick,
  sharedServicesProvider,
}: Props) => {


  const document = useFragment(fragmentQL, documentKey);
  const { loggedInUser } = useFragment(queryFragmentQL, queryKey);

  if (!loggedInUser) throw new Error(`No user logged in!`);


  /** Reduce extra re-rendering of deps of base document details */
  const documentMemoized = useMemo(() => {
    return {
      id: document.id,
      title: document.title,
      description: document.description,
      type: document.type as DocumentSchema.DocumentType,
    } as BasicDocumentDetails;
  }, [document.id, document.title, document.description, document.type]);

  const { experimentalEnabled } = useFeatureFlags();

  // const startingData = useTipTapData({ document })
  const [save, saveInProgress] = useSaveTipTapData(documentMemoized?.id);
  const savedDoc = useTipTapData({ document });

  const startingSnapshot = useMemo(() => {
    let startingVersion = 0;
    let startingContent: ProsemirrorNodes.TipTapDocument =
      DocumentSchema.initialDoc();
    try {
      if (
        document?.currentSnapshot?.body &&
        document?.currentSnapshot?.version > -1
      ) {
        const parsed = JSON.parse(
          document.currentSnapshot.body
        ) as ProsemirrorNodes.TipTapDocument;
        startingContent = parsed;
        startingVersion = document?.currentSnapshot?.version;
      }
    } catch (e) {
      /* If a snapshot cannot be loaded, use the initial doc with version of 0,
      so that we can request all steps from redis via the websocket */
      console.error("Error parsing document snapshot", e);
    }

    return { version: startingVersion, body: startingContent };
  }, [document?.currentSnapshot?.body, document?.currentSnapshot?.version]);

  const commandMenuRef = useRef<CommandMenuRef>();
  const editorRef = useRef<ReturnType<typeof useEditor>>();
  const previousStateRef = useRef<EditorState>();

  useEffect(() => {
    setTipTapData(startingSnapshot.body);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [mouseIsDown, setMouseIsDown] = useState(false);


  // TODO: Decide approach of using mark watcher vs just passing onclick in renderHTML() within AiSuggestion.mark.tsx
  // useMarkWatcher()


  const { windowWidth } = useWindowResize();

  const [currentBlockContext, setCurrentBlockContext] =
    useState<CurrentBlockContext>();
  const currentBlockContextRef = useRef<CurrentBlockContext>();
  const detectCurrentBlockContext = (view: EditorView, position: number) => {
    const context = getCurrentBlockContext(view, position, window);
    currentBlockContextRef.current = context;
    setCurrentBlockContext(context);
  };
  useEffect(() => {
    onCurrentBlockContextChange?.(currentBlockContextRef.current);
  }, [currentBlockContext, onCurrentBlockContextChange]);

  useEffect(() => {
    if (!editorRef.current?.view) return;
    detectCurrentBlockContext(
      editorRef.current?.view,
      editorRef.current?.view?.state.selection.from
    );
  }, [
    windowWidth,
    pageLayoutState.menuIsExpanded,
    pageLayoutState.rightSideState,
  ]);

  const clientId = generateClientId(loggedInUser.id);

  const editor = useCollabEditor(
    clientId,
    startingSnapshot.version,
    documentSubscription,
    {
      onCreate({ editor }) {
        if (showDevTools) {
          applyDevTools(editor.view, { devToolsExpanded: true });
        }
        previousStateRef.current = editor.state;
        docServices.onEditorCreated();
        docServices?.aiTipTapBridge.onDocBodyUpdated(editor.state)
      },
      extensions: [...extensionsForEditor()],
      // autofocus: true,
      onUpdate: ({ editor }) => {
        commandMenuRef.current?.onEditorUpdate(
          editor.state,
          previousStateRef.current
        );
        const doc = editor?.getJSON();
        if (!documentSubscription) {
          save(doc.content as any);
        }
        setTipTapData(doc as ProsemirrorNodes.TipTapDocument);
        previousStateRef.current = editor.state;

        docServices?.aiTipTapBridge.onDocBodyUpdated(editor.state)
      },
      // onFocus: ({ editor, event }) => {
      //   console.log("onFocus EDITOR")
      // },
      // onBlur: ({ editor, event }) => {
      //   console.log("onBlur EDITOR")
      // },
      onTransaction: (view) => {
        detectCurrentBlockContext(
          view.editor.view,
          view.editor.state.selection.$anchor.pos
        );

        // const selection = window.getSelection();
        // if (!selection || selection.type === "None") return;
        // const bounds = selection?.getRangeAt(0)?.getBoundingClientRect();
        // const coords = { left: bounds?.x || 0, top: bounds?.y || 0 };
        // const verticalPosition = coords.top + window.scrollY;

      },
      editorProps: {
        /** Called for each node around a click, from the inside out. The direct flag will be true for the inner node. */
        handleClickOn: (view, pos, node, nodePos, event, direct) => {
          if (!direct) {
            return false;
          }

          // TODO: Move this to a better place ???
          if (node.type.name === DocumentSchema.VerdiNodeTypes.PlaceholderInline) {
            getPlaceholderSuggestionsCommandDefinition.triggerCommand?.({
              shouldAbortIfAlreadyLoaded: true,
              title: "",
              lifecycleState: "load-new",
            })
            commandMenuRef.current?.openCommandMenu({
              limitTo: CommandListLimitTo.showLocalOnly
            })
          } else if (node.type.name === DocumentSchema.VerdiNodeTypes.docReferenceInline) {
            commandMenuRef.current?.openCommandMenu({
              limitTo: CommandListLimitTo.showLocalOnly
            })
          }

          // detectCurrentBlockContext(view, pos)

          const resolvedPos = view.state.doc.resolve(pos);
          const parent = findParentNestableNodeClosestToPos(resolvedPos);
          if (!parent) {
            return false;
          }

          if (selectedZoomTimestamp) {
            const transaction = view.state.tr.setNodeAttribute(
              parent.pos,
              "transcriptTimestamp",
              selectedZoomTimestamp
            );
            view.dispatch(transaction);
          }

          // const { transcriptTimestamp } = parent.node.attrs;
          // if (transcriptTimestamp) {
          //   scrollToTranscriptRef.current?.scrollToTimestamp(
          //     parent.node.attrs.transcriptTimestamp
          //   );
          // }
        },
        handleDOMEvents: {
          mouseup(view, event) { setMouseIsDown(false) },
          mousedown(view, event) { setMouseIsDown(true) },
          mouseleave(view, event) { setMouseIsDown(false) },
        },
        transformPasted: (slice) => {
          return transformPasted(slice, editorRef.current!.schema);
        },
        handlePaste: (view, event, slice) => {
          return handlePaste(view, event, slice);
        },
        /** Called when the editor is clicked, after handleClickOn handlers have been called. */
        handleClick: (view, pos, event) => {
          handleClick?.(view, pos, event);
        },
        handleKeyDown: (view, event) => {
          // detectCurrentBlockContext(view, view.state.selection.from)

          if (event.shiftKey && event.key === "Tab") {
            const bounds = window
              .getSelection()
              ?.getRangeAt(0)
              .getBoundingClientRect();
            const coords = { left: bounds?.x || 0, top: bounds?.y || 0 };
            const position = view.posAtCoords(coords);
            if (position?.inside === 1 && onShiftTab) onShiftTab();
          }
          if (commandMenuRef.current?.onKeyDown) {
            const didHandleEvent = commandMenuRef.current.onKeyDown(
              event,
              view
            );
            return didHandleEvent;
          }
        },
      },
      content: startingSnapshot.body,
    }
  );


  useHandleMarkClicked({
    openMenu: (options) => commandMenuRef.current?.openCommandMenu(options),
    currentMark: currentBlockContext?.currentMark,
  })

  if (!editorRef.current) {
    editorRef.current = editor;
  }

  useEffect(() => {

    setTimeout(() => {
      setFocusToCommandDefinition.triggerCommand?.({
        target: document.title ? "currentDocEditor" : "currentDocTitle",
      })
    }, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);


  // Setup services specific to current document
  const docServices = useRegisterDocServices({
    document: documentMemoized,
    editorRef,
    currentBlockContextRef,
    docCommandMenuRef: commandMenuRef,
    sharedServicesProvider,
    documentSubscription,
    getContextForAi: async (optionArgs) => { // Keep this as a wrapped function.
      return await sharedServicesProvider.getContextForAi(optionArgs);
    },
  });
  useEffect(() => {
    // Register document related dependencies here with the app level services
    sharedServicesProvider.setDocumentServices(docServices);
    docServices.loadCurrentDocSupportingData();

    return () => {
      // Clean up document related dependencies here
      docServices.clearCurrentDocSupportingData();
      sharedServicesProvider.clearDocumentServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Keep this array empty


  const [editorAreaHasFocus, setEditorAreaHasFocus] = useState(false);



  useSyncDocBodyWithDatabase()


  return (
    <DocEditorContextWrapper context={docServices}>
      <div
        onFocus={() => setEditorAreaHasFocus(true)}
        onBlur={() => setEditorAreaHasFocus(false)}
      >
        {/* <div
          css={css`
            position: fixed;
            top: ${hoveredMark?.getBoundingClientRect().bottom}px;
            left: ${hoveredMark?.getBoundingClientRect().left}px;
            z-index: 100;
            padding-top: 8px;
            display: ${hoveredMark ? "block" : "none"};
            border: 1px solid red;
          `}
        >
          <div
            css={css`
              background-color: red;
              width: 100%;
              height: 100%;
              padding: 16px;
            `}
          >
            <span>Mark element hovered</span>
            <ButtonPrimary
              label="Can't click me"
            />
          </div>
        </div> */}

        <LoadingIndicatorPortal isSaving={saveInProgress} />

        {/* <ReactNodeViewProvider>
        <EditorContent editor={editor} />
      </ReactNodeViewProvider> */}



        <EditorContent editor={editorRef.current} />

        <GenerateAiFlowButton docServices={docServices} />


        <AiDocSuggestionsList
          docType={documentMemoized.type}
          editorAreaHasFocus={editorAreaHasFocus}
        />

        <FloatingBlockContext
          documentId={documentMemoized.id}
          currentBlockContext={currentBlockContext}
          aiCoach={docServices.aiCoach}
          editor={editorRef.current}
          commandMenuRef={commandMenuRef}
          commandMenuOnKeyDown={commandMenuRef.current?.onKeyDown}
          isLockedForAiEditing={
            documentSubscription?.isLockedForAiEditing || false
          }
          editorAreaHasFocus={editorAreaHasFocus}
          pageLayoutState={pageLayoutState}
          mouseIsDown={mouseIsDown}
        />

        {/* <RelatedDocs
          currentDocId={documentMemoized.id}
          docType={documentMemoized.type}
          sharedServicesProvider={sharedServicesProvider}
          displayOptions={displayOptions}
        /> */}

        {experimentalEnabled && (
          <div
            css={css`
              margin-top: 60px;
              opacity: 0.3;
            `}
          >
            <IconButtonSecondary
              aria-label="Refresh note"
              icon={<VerdiIconRefresh />}
              onClick={() => {
                documentSubscription?.ensureWebsocketIsNotStale(-1, clientId);
              }}
            />
          </div>
        )}
      </div>
    </DocEditorContextWrapper>
  );
};
