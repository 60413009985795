import { css } from "@emotion/react"
import { ChatButtonClickArgs, ChatButtonProcessor } from "./useChatButtonProcessor"
import { ChatButton } from "./ChatButton"
import { chatMessageStyles } from "../ColorModeValues"
import { Button, useColorModeValue } from "@chakra-ui/react"
import { format, isValid } from "date-fns"
import { ChatMessage } from "./useChatHistory"
import { useState } from "react"
import { ChatRoleType } from "@verdi/shared-constants"


const truncateIfLongerThan = 1000
export const ChatMessageViewLabelWidth = 70

type Props = {
  commandProcessor: ChatButtonProcessor
  isLightMode: boolean
  isCompactMode: boolean
  isMostRecentMessage: boolean
  onButtonClick: (args: ChatButtonClickArgs) => void
} & ChatMessage

export const ChatMessageView = ({
  commandProcessor,
  isLightMode,
  isCompactMode,
  isMostRecentMessage,
  content: message,
  role,
  createdAt,
  buttons,
  onButtonClick,
}: Props) => {

  const messageStyles = useColorModeValue(chatMessageStyles.light.message, chatMessageStyles.dark.message)
  const createAtDate = isValid(createdAt) ? format(createdAt, "LLL dd") : "-"
  const createAtTime = isValid(createdAt) ? format(createdAt, "HH:mm aaa") : "-"
  const [isTruncated, setIsTruncated] = useState(message?.length < truncateIfLongerThan)
  const isFromAi = role != ChatRoleType.user


  return (
    <div css={css`
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      justify-content: flex-start;
      animation: fadeIn 500ms;
      @keyframes fadeIn {
        0% { opacity: 0 }
        100% { opacity: 1 }
      }
      `}>


      <div
        title={createAtDate}
        css={css`
          font-size: x-small;
          opacity: 0.6;
          text-align: right;
          margin-right: 5px;
          margin-top: 15px;
          width: ${ChatMessageViewLabelWidth}px;
          line-height: 0.8;
          text-transform: uppercase;
        `}>
        {isFromAi ? "Verdi" : "You"} <br />
      </div>


      <div css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}>
        <div css={css`
          max-width: 600px;
          background-color: ${isFromAi ? messageStyles.ai.bg : messageStyles.user.bg};
          border: 1px solid ${isFromAi ? messageStyles.ai.borderColor : messageStyles.user.borderColor};
          color: ${isLightMode ? "black" : "white"};
          border-radius: 5px;
          padding: 5px;
          width: fit-content;
          white-space: break-spaces;
          margin: ${isMostRecentMessage ? "0" : "2px"};
          border-width: ${isMostRecentMessage ? "3px" : "1px"};
        `}>
          <div>
            {isTruncated ? message : message.substring(0, truncateIfLongerThan)}

            {message.length > truncateIfLongerThan &&
              <Button
                size="sm"
                variant="ghost"
                onClick={() => setIsTruncated(!isTruncated)}
              >
                . . .
              </Button>
            }
          </div>

          {buttons && buttons.length > 0 &&
            <div css={css`
              margin-top: 5px;
            `}>
              {buttons.map((button, index) =>
                <ChatButton key={index}
                  title={button.title}
                  onClick={onButtonClick}
                  commandType={button.commandType}
                  functionArguments={button.arguments}
                />)
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
