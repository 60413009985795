import { Button, useColorModeValue } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { UserInputForChatEnterKey } from "./UserInputForChatEnterKey"
import { AutoResizingInputText } from "../forms/AutoResizingInputText"


const idForUserChatInput = "user-input-for-chat-textarea"
export const setFocusToChatInput = () => {
  const textarea = document.getElementById(idForUserChatInput)
  textarea?.focus()
}


type Props = {
  placeholderInstructionText?: string
  onSubmitButtonClick: (userInput: string) => void
  onStartNoteButtonClick?: (userInput: string) => void
}
export const UserInputForChat = ({ placeholderInstructionText, onSubmitButtonClick, onStartNoteButtonClick }: Props) => {

  const [textareaText, setTextareaText] = useState("")
  const [hasFocus, setHasFocus] = useState(false)

  const submitUserInput = useCallback(() => {
    const message = textareaText.trim()
    setTextareaText("")
    onSubmitButtonClick(message)
  }, [textareaText])

  const [canSubmit, setCanSubmit] = useState(false)
  useEffect(() => {
    setCanSubmit(textareaText.length > 0)
  }, [textareaText])

  const isLightMode = useColorModeValue(true, false)


  return (
    <div>
      <AutoResizingInputText
        id={idForUserChatInput}
        placeholder={placeholderInstructionText || "Ask the AI something"}
        value={textareaText}
        onChange={(e) => setTextareaText(e.target.value)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        backgroundColor={isLightMode ? "white" : "gray.800"}
      />

      <Button
        colorScheme="verdiButtonPrimary"
        color="white"
        marginTop={1}
        size="sm"
        onClick={() => submitUserInput()}
        disabled={!canSubmit}
      >
        Send
      </Button>

      {canSubmit &&
        <UserInputForChatEnterKey
          hasFocus={hasFocus}
          submitUserInput={submitUserInput}
        />
      }

    </div>
  )
}
