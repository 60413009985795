import { useRef } from "react"
import { useGlobalCommandListener } from "./useGlobalCommandListener"
import { GlobalCommandType } from "../GlobalCommandType"
import { useAppServices } from "../../appServices/useAppServices"
import { AllGlobalCommands } from "../AllGlobalCommands"
import { AppServices } from "../../appServices/useRegisterAppServices"


/** Processes ALL global commands, like those from the command menu */
export const useGlobalCommandProcessor = () => {

  const appServices = useAppServices()
  const appServicesRef = useRef<AppServices>(appServices)
  appServicesRef.current = appServices

  useGlobalCommandListener({
    handleGlobalCommand: (event: Event) => {
      const details = (event as CustomEvent).detail
      const commandType = details.commandType as GlobalCommandType
      const commandArgs = details.commandArgs as any
      processCommand(commandType, appServicesRef.current, commandArgs)
    }
  })

}


const processCommand = async (commandType: GlobalCommandType, services: AppServices, commandArgs?: any) => {

  const commandDefinition = AllGlobalCommands.find(c => c.globalCommandType === commandType)
  if (!commandDefinition) {
    console.warn("No command definition found for commandType '", commandType, "' ... Make sure it is added to `AllGlobalCommands.tsx`")
    return
  }

  commandDefinition.processCommand(
    commandArgs,
    services,
    (wasSuccessful: boolean) => console.log("processed Command", { commandType, wasSuccessful, commandArgs }),
  )
}
