import { css } from '@emotion/react'
import graphql from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { DocumentEditor } from '../document/DocumentEditor'
import { SideMenu } from './SideMenu'
import { OpportunityDocumentPageQuery } from './__generated__/OpportunityDocumentPageQuery.graphql'
import { useColorModeValue } from '@chakra-ui/react'
import { PageLayoutSplitViewWrapper } from '../../components/pageLayout/pageLayoutSplitViewWrapper'
import { OpportunityEditor } from '../opportunities/OpportunityEditor'
import { useUrlParams } from '../../utility-hooks/useUrlParams'
import { useEffect } from 'react'
import { useAppServices } from '../../components/appServices/useAppServices'
import { MainViewWrapper } from '../../components/pageLayout/MainViewWrapper'


const queryQL = graphql`
  query OpportunityDocumentPageQuery(
    $opportunityId: ID!
    $shouldSkip: Boolean!
  ) {
    node(id: $opportunityId) @skip(if: $shouldSkip)  {
      ...on Opportunity{
        title
        description
        mainDoc {
          id
        }
        ...OpportunityDocumentSideMenu_opportunity
      }
    } 
  }
`

type Props = {
  opportunityId?: string
  documentId?: string
}

export function OpportunityDocumentPage(props: Props) {

  const sharedServicesProvider = useAppServices()
  const {
    pageLayoutState,
    router,
    menuStructureProvider,
    opportunityProvider,
  } = sharedServicesProvider

  const opportunityId = props.opportunityId || router.match.params['opportunityId']
  const documentId = props.documentId || router.match.params['documentId']

  const query = useLazyLoadQuery<OpportunityDocumentPageQuery>(queryQL, {
    opportunityId: opportunityId || '',
    shouldSkip: !opportunityId
  })
  const opportunity = query.node


  const { chatIsVisible } = useUrlParams()
  useEffect(() => {
    pageLayoutState.setRightSideState(chatIsVisible ? "visible" : "hidden")
    //eslint-disable-next-line
  }, [])


  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={sharedServicesProvider}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              key={opportunityId}
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        mainContent={

          <div>
            {!opportunity ?
              <div css={css`
                display: flex;
                align-items: center;
                text-align: center;
                height: 80vh;
              `}>
                <div
                  css={css`
                width: 100%;
                font-size: 18px;
                padding: 40px;
                `}
                >
                  <p>
                    Hi, I’m Verdi, your AI copilot!
                  </p>
                  <p>
                    Let’s get started by clicking<br /> "Add Opportunity" <br />
                    from the top left menu
                  </p>
                </div>
              </div>
              :

              <MainViewWrapper>

                {!documentId ?
                  <div css={css`
                    padding: 30px;
                  `}>

                    <OpportunityEditor opportunity={({
                      id: opportunityId,
                      title: opportunity.title || undefined,
                      description: opportunity.description || undefined
                    })} />

                    <p>Please select a document</p>

                  </div>
                  :
                  <div>
                    <DocumentEditor
                      pageLayoutState={pageLayoutState}
                      documentId={documentId}
                      key={documentId}
                      onCurrentBlockContextChange={(args) => null}
                      sharedServicesProvider={sharedServicesProvider}
                    />
                  </div>
                }


              </MainViewWrapper>

            }

          </div>
        } />

    </div >
  )
}
