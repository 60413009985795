import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { getReplacementSuggestionsFromResponse } from "../../promptUtils/getReplacementSuggestionsFromResponse"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { DocumentSchema, OpenAi } from "@verdi/shared-constants"


/** Generates suggestions of what text could replace the current placeholder in the document body */
export const makePlaceholderReplacementsAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
) => {

  const allContext: AllContextForAi = await getContextForAi({
    includeContext: {
      document: {
        bodyAs: "indented-markdown",
      }
    }
  })

  const docContext = allContext.currentDoc
  if (!docContext) {
    console.warn("makePlaceholderReplacementsAiCall: No currentDoc context found")
    return []
  }

  const placeholderAttrs = docContext.currentPlaceholderAttrs
  if (!placeholderAttrs) {
    console.warn("makePlaceholderReplacementsAiCall: No currentPlaceholderAttrs found")
    return []
  }
  console.log("makePlaceholderReplacementsAiCall", { placeholderAttrs })

  const shouldRunAnotherPrompt = placeholderAttrs?.maxLines && placeholderAttrs?.maxLines > 0
  console.log("makePlaceholderReplacementsAiCall", { shouldRunAnotherPrompt })
  const prompt = shouldRunAnotherPrompt
    ? getPromptForMorePrompts(docContext, placeholderAttrs)
    : getPromptForSingleReplacements(docContext, placeholderAttrs)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    openAIParams: {
      model: OpenAi.Gpt_4_Latest,
    }
  })

  const rawText = response?.aiResponseJson.content as string || ""
  const suggestions = getReplacementSuggestionsFromResponse(rawText, shouldRunAnotherPrompt ? "customAiPrompt" : "text", placeholderAttrs)

  console.log("HEY STEVE: makePlaceholderReplacementsAiCall", { suggestions })

  return suggestions
}


export const getPromptForMorePrompts = (
  docContext: DocumentContextForAi,
  placeholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes
) => {

  const currentPlaceholderText = placeholderAttrs.text
  // @Kendra: you can use these where applicable
  // const maxLines = placeholderAttrs.maxLines
  // const subInstructions = placeholderAttrs.instructions

  const currentLine = docContext.currentLineText
  const prompt = `INSTRUCTIONS: Your task is to write three concise, specific, and unique descriptions that summarize the text you'd want to write in place of this placeholder: [[ ${currentPlaceholderText} ]]
  found in the current document context.

${currentLine ? `The CURRENT LINE of text around the placeholder is:\n${currentLine}` : ""}

Please generate the description options directly. Do not include a preamble, just the 3 concise description options. Should be 2-5 words long.
It is essential to tailor your description options to these things: 
how the placeholder is nested (for example if it is nested the three options should be diving deeper into the context of what is above it, or at the root level possibly three new ideas),
the format of the text immediately around the placeholder (or the format of a similar place in an example from the framework context),
the context of the words immediately around the placeholder that could give you clues as to what text is expected there (for example if the placeholder is under the section heading # Problem Statement, then respond with three variations of a problem statement),
the inferred purpose of the specific section the placeholder is in (for example if you are nested inside the section # Channels then you are probably wanting to give three options of sales channels for the context given).

Return only 3 very concise, meaningful, and varied description options that are contextual to the exact location of the placeholder.

EXAMPLE 1:
If the placeholder value was:
[[ City ]]
and the CURRENT LINE of text around it was:
Our address is: [[ street address ]], [[ City ]], [[ State ]] [[ Zip Code ]]
then the response could be:
New York
Los Angeles
Chicago

Example 2:
If the placeholder value was:
[[ 1-2 sentences describing the outcomes of the project ]] 
then the response could be:
Deliver a new product
Increase customer satisfaction
Expand customer base
  `
  return prompt
}


export const getPromptForSingleReplacements = (
  docContext: DocumentContextForAi,
  placeholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes
) => {

  const currentPlaceholderText = placeholderAttrs.text
  // @Kendra: you can use these where applicable
  // const maxLines = placeholderAttrs.maxLines
  // const subInstructions = placeholderAttrs.instructions

  const currentLine = docContext.currentLineText
  const prompt = `INSTRUCTIONS: Your task is to write three very concise, meaningfully rich text options that are contextual to the exact location of the 
placeholder that is enclosed in double brackets in the current document context. 

The value that the placeholder is currently set to is: 
[[ ${currentPlaceholderText} ]]

${currentLine ? `The CURRENT LINE of text around the placeholder is:\n${currentLine}` : ""}

This value is too generic and needs to be more specific to the context of the document.

Please generate the text options directly. Do not include a preamble, just the varied text options.
It is essential to tailor your text options to these things: 
how the placeholder is nested (for example if it is nested the three options should be diving deeper into the context of what is above it, or at the root level possibly three new ideas),
the format of the text immediately around the placeholder (or the format of a similar place in an example from the framework context),
the context of the words immediately around the placeholder that could give you clues as to what text is expected there (for example if the placeholder is under the section heading # Problem Statement, then respond with three variations of a problem statement),
the inferred purpose of the specific section the placeholder is in (for example if you are nested inside the section # Channels then you are probably wanting to give three options of sales channels for the context given).

The text should not be instruction suggestions of what could be written (ex: "Specify the features that will enhance the product), 
rather the actual direct text (ex: seamless command menu interface for the user to trigger commands)

Return only 3 very concise, meaningful, and varied text options that are contextual to the exact location of the placeholder.

EXAMPLE 1:
If the placeholder value was:
[[ City ]]
and the CURRENT LINE of text around it was:
Our address is: [[ street address ]], [[ City ]], [[ State ]] [[ Zip Code ]]
then the response could be:
New York
Los Angeles
Chicago

Example 2:
If the placeholder value was:
[[ 1-2 sentences describing the outcomes of the project ]] 
then the response could be:
Delivering a new product to market will set us up for success in the next quarter. This new product will specifically decrease bounce rate and engage more daily users.
Improving customer satisfaction will enable us to retain more customers.
Increasing our customer base will help us reach our revenue goals.

EXAMPLE 3:
If the placeholder value was:
[[ Name ]]
and the CURRENT LINE of text around it was:
says [[ name ]] , [[ title ]] at [[ Company Name ]].
then the response could be:
Todd
Jenny
Samantha

  `
  return prompt
}


// Delivering a new product to market will set us up for success in the next quarter. Being more competitive in key areas.
// Improving customer satisfaction will enable us to retain more customers. It is also the right thing to do for our customers.
// Increasing our customer base will help us reach our revenue goals. Increase Quarterly active users.
