import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useColorModeValue } from "@chakra-ui/react"
import { useAppServices } from "../../components/appServices/useAppServices"
import { InitialOnboardingScreen } from "./steps/InitialOnboardingScreen"
import { menuBackgroundColors } from "../../components/ColorModeValues"


export const StartOnboardingModal = () => {


  const { onboardingManager } = useAppServices()
  const { isOpen, onClose } = onboardingManager.onboardingDisclosure
  const backgroundColor = useColorModeValue(menuBackgroundColors.light, menuBackgroundColors.dark)

  if (!onboardingManager.onboardingDisclosure.isOpen) {
    return null
  }


  return (
    <div>
      <Modal
        onClose={onClose}
        size="full"
        isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent
          backgroundColor={backgroundColor}
        >

          <ModalCloseButton
            opacity={0.05}
            onClick={() => {
              onboardingManager.stopOnboardingFlow()
              onboardingManager.updateProgress("dismissedAt")
            }} />

          <ModalBody>
            <InitialOnboardingScreen />
          </ModalBody>

        </ModalContent>
      </Modal>

      <InitialOnboardingScreen />

    </div>
  )
}
