import { useCallback, useState } from "react"
import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary"
import { AiLogEntry } from "./AiGenerationLogEntriesReport"
import { makeAiChatRequest } from "../../../ai/requests/AiApiRequests"
import { AutoResizingInputText } from "../../../components/forms/AutoResizingInputText"
import { InputTextWithState } from "../../../components/forms/InputTextWithState"
import { css } from "@emotion/react"
import { VerdiIconDebug } from "../../../components/icons/VerdiIcons"
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary"


type Props = {
  entry: AiLogEntry
}
type RequestData = {
  prompt: string
  messages: { role: "string", content: "string" }[]
  openAIParams: {
    n: number
  }

}

/**  */
export const AiRequestDebugger = ({
  entry,
}: Props) => {

  const [isShowingDebugger, setIsShowingDebugger] = useState(false)
  const [isRunning, setIsRunning] = useState(false)
  const [rawRequestText, setRawRequestText] = useState("")
  const [isValidJson, setIsValidJson] = useState(false)
  const [reRanResponseValue, setReRanResponseValue] = useState("")


  const runRequest = useCallback(async () => {

    setIsRunning(true)
    setReRanResponseValue("")

    const requestData = JSON.parse(rawRequestText) as RequestData
    console.log("===> AiRequestDebugger: ", { requestData, entry })

    const response = await makeAiChatRequest({
      prompt: requestData.prompt,
      messages: requestData.messages as any,
      openAIParams: requestData.openAIParams,
    })
    console.log("===> AiRequestDebugger: ", { requestData, response })
    setReRanResponseValue(JSON.stringify(response?.aiResponseJson, null, 2))

    setTimeout(() => {
      setIsRunning(false)
    }, 2000)

  }, [setIsRunning, rawRequestText, setReRanResponseValue])

  const setAndValidateRawRequestText = useCallback((newValue: string) => {

    let parsed: RequestData
    try {
      parsed = JSON.parse(newValue) as RequestData
    } catch (e) {
      console.warn("Error parsing JSON: ", e)
      setIsValidJson(false)
      return
    }

    setIsValidJson(true)

    setRawRequestText(newValue)
  }, [setRawRequestText])



  return (
    <div css={css`
      border: 1px solid red;
      padding: 10px;
      width: 100%;
    `}>
      <ButtonSecondary
        marginTop={2}
        size="xs"
        label="Debug"
        onClick={() => {
          setIsShowingDebugger(!isShowingDebugger)
          const stringy = JSON.stringify(entry.requestJson, null, 2)
          setAndValidateRawRequestText(stringy)
        }}
        leftIcon={<VerdiIconDebug />}
      />

      {isShowingDebugger &&
        <div>
          <ButtonPrimary
            marginTop={2}
            size="xs"
            label="Re-run"
            onClick={runRequest}
            isLoading={isRunning}
            disabled={!isValidJson}
          />

          <div css={css`
            border: 1px solid blue;
            display: flex;
            flex-direction: row;
          `}>
            <div css={css`
              flex: 1;
            `}>
              Raw Request
              <InputTextWithState
                value={rawRequestText}
                onValueUpdated={setAndValidateRawRequestText}
                color={isValidJson ? "inherit" : "red"}
                opacity={isRunning ? 0.5 : 1}
              />
            </div>

            <div>
              Response
              <AutoResizingInputText
                value={reRanResponseValue} />
            </div>

          </div>

        </div>
      }

    </div>
  )
}
