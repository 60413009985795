import { EditorState } from "prosemirror-state";
import { ContentNodeWithPos, findParentNodeClosestToPos } from "prosemirror-utils";


/** Determines the position of where to insert a child node */
export const getChildInsertPosition = (
  state: EditorState,
  pos: number,
  insertMode: "lastChild",
) => {

  const resolvedPos = state.doc.resolve(pos);
  const section = findParentNodeClosestToPos(resolvedPos, (node) => node.type.name === "section")
  const contentList = findParentNodeClosestToPos(resolvedPos, (node) => node.type.name === "contentList")
  const targetContentListPos = findTargetContentListNode(section, contentList, insertMode)
  return targetContentListPos || null
}

const findTargetContentListNode = (
  section: ContentNodeWithPos | undefined,
  contentList: ContentNodeWithPos | undefined,
  insertMode: "lastChild",
) => {
  if (!section && !contentList) {
    return null
  }
  if (contentList && !section) {
    return contentList.pos
  }

  if (section && contentList && contentList?.depth > section?.depth) {
    return contentList.pos
  }

  let positionToReturn: number | null = null
  if (section && !contentList) {
    section.node.descendants((node, pos) => {
      if (node.type.name === "contentList") {
        const sizeOfContentList = insertMode === "lastChild" ? node.nodeSize : 1
        positionToReturn = pos + section.pos + sizeOfContentList // Put at end of content list
        return false
      }
    })
  }

  return positionToReturn
}
