import { OpportunityContextForAi } from "../../../screens/opportunities/useOpportunityProvider";
import { DocumentContextForAi } from "../../coach/AiCoachPrompts";
import { buildDocContextForPrompt } from "../../promptUtils/buildDocContextForPrompt";
import { buildOpportunityContextForPrompt } from "../../promptUtils/buildOpportunityContextForPrompt";


export const getDraftAResearchPlanPrompt = (
  companyContext: string,
  opportunityContext?: OpportunityContextForAi,
  docContext?: DocumentContextForAi,
) => {

  const docContextString = docContext ? buildDocContextForPrompt(docContext, false).detailsForPrompt : ""
  const opportunityContextString = opportunityContext ? buildOpportunityContextForPrompt(opportunityContext, false) : ""

  const prompt = `
### BEGIN CONTEXT
  
COMPANY CONTEXT: ${companyContext}

OPPORTUNITY CONTEXT: ${opportunityContextString}

CURRENT ACTION CONTEXT: ${docContextString}

### END CONTEXT

INSTRUCTIONS: 
Help the user concisely plan out how to accomplish the action ${docContext?.title ? `:'${docContext?.title}` : "."} 
Use any context given as needed.
If it is about a survey or interview, then craft a concise interview guide to validate the assumption. 
If it is desk research, concisely provide evidence-backed answers and include links to the research you find. 
If it is an in-person event of sorts, concisely outline the tasks that need to be done or things to be coordinated in order to organize the event. This can include a section for interview questions to collect feedback.
Else, concisely outline what you think is appropriate to help the user accomplish the action.

Only return the concise interview guide, evidence, tasks, or outline without any preamble or postamble. Do not repeat the action document title in the response.

RESPONSE FORMAT RULES:
If it is a section heading, start the line with a #.
If it is a question, end the line with a ?
If it is text that should be nested within a section, start the line with a -
`
console.log("Research Plan Prompt = ", prompt)

  return prompt
}


