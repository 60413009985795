import { useState, useEffect } from 'react'
import { useThrottle } from './useThrottle'


/** Listens for window resize events */
export const useWindowResize = () => {

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)

  // Function to update the window size state
  const handleResize = useThrottle(() => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }, 100)

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    windowWidth,
    windowHeight
  }
}
