import { isValid } from 'date-fns';
import { CreateDocumentAiPromptInput } from './__generated__/useCreateAiPromptMutation.graphql';
import { UpdateDocumentAiPromptInput } from './__generated__/useUpdateAiPromptMutation.graphql';


type CreateDocumentAiPromptInputForm = Partial<CreateDocumentAiPromptInput>
type UpdateDocumentAiPromptInputForm = Partial<UpdateDocumentAiPromptInput>
type OpenAIParamsInput = NonNullable<CreateDocumentAiPromptInputForm['openApiParams']>


export type DocumentAiPromptInputForm = CreateDocumentAiPromptInputForm | UpdateDocumentAiPromptInputForm

export const deleteNonRelevantOpenAIKeys = (params: OpenAIParamsInput): Partial<OpenAIParamsInput> => {
  let paramsCopy = { ...params }
  const keys = Object.keys(paramsCopy) as Array<keyof OpenAIParamsInput>
  for (const key of keys) {
    const value = paramsCopy[key]
    if (!isValidValue(value, typeof value as 'string' | 'number')) {
      delete paramsCopy[key]
    }
  }
  return paramsCopy
}


const isValidValue = (value: OpenAIParamsInput[keyof OpenAIParamsInput], type: 'number' | 'string') => {
  if (value === null || typeof value === undefined) {
    return false
  } else {
    if (typeof value === 'string') {
      return !!value.length

    } else if (typeof value === 'number') {
      return !isNaN(value as any)
    }
  }
}

export const setInputOpenApiParamsValueForInputState =
  <T extends CreateDocumentAiPromptInputForm | UpdateDocumentAiPromptInputForm>(input: T, setInput: (input: T) => void) =>
    <T extends keyof OpenAIParamsInput>(key: T, value: OpenAIParamsInput[T]) => {
      let openApiParams = input['openApiParams'] ? { ...input['openApiParams'] } : {}
      if (!isValidValue(value, typeof value as 'string' | 'number')) {
        delete openApiParams[key]
      } else {
        openApiParams = {
          ...openApiParams,
          [key]: value
        }
      }
      setInput({
        ...input,
        openApiParams,
      })
    }