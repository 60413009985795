import { useAdminReportsProvider } from "./useAdminReportsProvider"
import { AiGenerationLogEntriesReport } from "./AiGenerationLogEntriesReport"
import { PageLayoutSplitViewWrapper } from "../../../components/pageLayout/pageLayoutSplitViewWrapper"
import { SuspenseErrorBoundary } from "../../../components/SuspenseErrorBoundary"
import { SideMenu } from "../../opportunityDocument/SideMenu"
import { useEffect } from "react"
import { useAppServices } from "../../../components/appServices/useAppServices"


export const AdminReportsPage = () => {

  const appServices = useAppServices()
  const {
    pageLayoutState,
    menuStructureProvider,
    opportunityProvider,
  } = appServices

  useEffect(() => {
    pageLayoutState.setMenuIsExpanded(false)
  }, [])


  const adminReportsProvider = useAdminReportsProvider()

  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={appServices}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        rightSideInitialState={"hidden"}
        mainContent={

          <div>
            <h1>Admin Reports Page</h1>

            <h2>AI Generation Log</h2>

            <AiGenerationLogEntriesReport
              adminReportsProvider={adminReportsProvider}
            />

          </div>
        }
      />
    </div>
  )
}
