
export enum InsertPosition {
  endOfDocument,
  startOfDocument,
  currentCursorSelection,
  endOfCurrentNode,
  AsLastChildOfCurrentNode,
  AsNextRootNode,
  AsSiblingsOrInEmptyLine,
  replaceMark,
  replaceMarkThenAddChild,
  replaceAllContent,
  replaceAllChildrenOfSection,
}
