import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProsemirrorNodes } from '@verdi/shared-constants'


export interface CurrentLineNodeState {
  startPos: number,
  depth: number,
  blockType: ProsemirrorNodes.NestableBlockType | undefined,
}
interface IDocLineSuggestionsState {
  isGenerating: boolean
  suggestions: string[]
  currentLineNode: CurrentLineNodeState | undefined
}
const initialState: IDocLineSuggestionsState = {
  isGenerating: false,
  suggestions: [],
  currentLineNode: undefined,
}

export const docLineSuggestionsSlice = createSlice({
  name: 'docLineSuggestions',
  initialState,
  reducers: {
    setAll: (state, action: PayloadAction<string[]>) => {
      state.suggestions = action.payload
    },
    add: (state, action: PayloadAction<string>) => {
      state.suggestions.push(action.payload)
    },
    remove: (state, action: PayloadAction<{ id: string }>) => {
      state.suggestions = state.suggestions.filter((i) => i !== action.payload.id)
    },
    setIsGenerating: (state, action: PayloadAction<boolean>) => {
      state.isGenerating = action.payload
    },
    setCurrentLineStartPos: (state, action: PayloadAction<CurrentLineNodeState | undefined>) => {
      state.currentLineNode = action.payload
    },
  },
  selectors: {
    getIsGeneratingLineSuggestions: (state) => state.isGenerating,
    getCurrentLineStartPos: (state) => state.currentLineNode,
    getAll: (state) => state.suggestions,
  }
})

export const docLineSuggestionsState = {
  ...docLineSuggestionsSlice.actions,
  ...docLineSuggestionsSlice.selectors,
}

// export const selectCount = (state: RootState) => state.docLineSuggestions.items.length

export default docLineSuggestionsSlice.reducer
