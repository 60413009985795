import { useCallback, useEffect, useMemo, useState } from "react"
import { DocIdeasState, DocumentIdea } from "../../state/docIdeasSlice"
import { useAppSelector } from "../../state/storeHooks"
import { IdeaCard } from "./IdeaCard"
import { dispatch } from "../../state/store"
import { css } from "@emotion/react"
import { useAppServices } from "../../components/appServices/useAppServices"
import { makeAnswersAiCall } from "../../ai/prompts/ideas/answersAiCall"
import { VerdiIconAiSuggestions } from "../../components/icons/VerdiIcons"
import { GlobalCommandType } from "../../components/commands/GlobalCommandType"
import { CommandStub } from "../document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/buildCommandsFromSuggestions"
import { triggerGlobalCommand } from "../../components/commands/triggerGlobalCommand"
import { DocumentsState } from "../../state/DocumentsSlice"
import { Data, DocumentSchema } from "@verdi/shared-constants"
import { SubSectionWrapper } from "../opportunityDocument/subMenu/SubSectionWrapper"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { DocBodyMarkCreateCommandArgs, docBodyMarkCreateCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkCreate"
import { docBodyMarkClearAllCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearAll"
import { hideDocCommandMenuCommandDefinition } from "../../components/commands/commandDefinitions/utils/hideDocCommandMenu"
import { DocMetadataState } from "../../state/docMetadataSlice"
import { applyIdeaToDocCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/applyIdeaToDoc"
import { addSectionWithAiCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/addSectionWithAi"
import { findAndReplaceCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/findAndReplace"
import { DocMetadataSummary } from "../../ai/documents/DocUpdatedEvent"
import { writeToDocBodyCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/writeToDocBody"
import { InsertPosition } from "../../ai/documents/InsertPosition"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { getDocIdeasFromAiCoachCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/getDocIdeasFromAiCoach"
import { ButtonTertiary } from "../../components/buttons/ButtonTertiary"
import { AutoAiModeToggle } from "../../components/aiComponents/AutoAiModeToggle"
import { doNextAutoAiStepCommandDefinition } from "../../components/commands/commandDefinitions/autoAi/doNextAutoAiStep"
import { AutoAiModeState } from "../../state/AutoAiModeSlice"


const defaultCountOfVisibleIdeas = 1
const showMoreVisibleIdeasIncrement = 6

type Props = {
  currentDoc: BasicDocumentDetails,
}
/** Presents ideas and suggested actions for the current document */
export const DocumentIdeasPanel = ({
  currentDoc,
}: Props) => {

  const { getContextForAi } = useAppServices()

  const currentIdeaId = useAppSelector(DocIdeasState.getCurrentIdeaId)
  const currentDocId = useAppSelector(DocumentsState.getCurrentDocId)
  const currentDocSummary = useAppSelector(DocMetadataState.getSummary)
  const isInAutoAiMode = useAppSelector(AutoAiModeState.getIsInAutoAiMode)
  const isWorkingInAutoAiMode = useAppSelector(AutoAiModeState.getIsDoingSomething)
  const { experimentalEnabled } = useFeatureFlags()


  const ideas = useAppSelector(s => DocIdeasState.getIdeasForDoc(s, currentDocId))
  const ideasFiltered = useMemo(() => {
    if (experimentalEnabled) {
      return ideas.filter(idea => idea.docId === currentDocId &&
        (!idea.status || idea.status === "viewed" || idea.status === "executed")
      ) || []
    }
    return ideas.filter(idea => idea.docId === currentDocId &&
      (!idea.status || idea.status === "viewed")
    ) || []
  }, [ideas, currentDocId, experimentalEnabled])


  const [visibleIdeasCount, setVisibleIdeasCount] = useState(defaultCountOfVisibleIdeas)
  const visibleIdeas = useMemo(() => {
    return ideasFiltered.slice(0, visibleIdeasCount)
  }, [ideasFiltered, visibleIdeasCount])


  const onTitleClick = useCallback(async (idea: DocumentIdea) => {

    if (!currentDocId) {
      console.warn("No current doc id")
      return
    }

    let latestId = currentIdeaId && currentIdeaId === idea.id ? undefined : idea.id
    dispatch(DocIdeasState.setCurrentIdeaId(latestId))


    // Clear any highlights
    docBodyMarkClearAllCommandDefinition.triggerCommand?.({})
    hideDocCommandMenuCommandDefinition.triggerCommand?.({})
    if (latestId) { // Only highlight when the idea card is expanded
      let markArgs: DocBodyMarkCreateCommandArgs | undefined = idea.markArgs
      if (!markArgs && idea.title && currentDocSummary?.sectionTitles.find(s => s === idea.title)) {
        // Text happens to match a section title
        markArgs = {
          markType: "AiSuggestion",
          markStrategy: "search",
          textToHighlight: idea.title,
          attributes: {
            suggestionText: idea.question,
            commands: []
          },
        }
      }

      if (markArgs) {
        docBodyMarkCreateCommandDefinition.triggerCommand?.({
          ...markArgs,
          shouldMoveCaretPosition: true,
        })
        // scroll to mark
      }
    }

    const hasCommands = idea.commands?.length
    if (!idea.isLoadingCommands && !hasCommands && idea.question && !idea.status) {

      const newStatus = idea.status ? idea.status : "viewed" as Data.DocumentIdeas.DocumentIdeaStatus
      let ideaId = idea.id

      dispatch(DocIdeasState.updateIdea({
        id: ideaId,
        fields: {
          isLoadingCommands: true,
          status: newStatus,
        }
      }))

      // TODO: Persist to the DB at some point
      // if (DocIdeasState.isTempId(ideaId)) {
      //   const fields: Data.DocumentIdeas.DocumentIdeaMutableFields = {
      //     answer: idea.answer,
      //     question: idea.question,
      //     title: idea.title,
      //     status: newStatus,
      //   }
      //   const newIdea = await DocumentIdeasProvider.create(currentDocId, ideaId, fields)
      //   dispatch(DocIdeasState.replaceTempId({ oldId: ideaId, newId: newIdea.id }))
      //   ideaId = newIdea.id
      //   dispatch(DocIdeasState.setCurrentIdeaId(newIdea.id))
      // }

      // TODO: Run the other prompt if has markArgs ???


      const suggestedAnswers = await makeAnswersAiCall(getContextForAi, idea.question, 3)
      if (suggestedAnswers.length > 0) {

        const commands: CommandStub<any>[] = suggestedAnswers.map((answerText, i) => ({
          buttonText: answerText,
          commandType: GlobalCommandType.autoDraftFullDoc,
          args: { sectionTitle: answerText }
        }))
        const fields: Partial<DocumentIdea> = {
          isLoadingCommands: false,
          commands,
        }
        dispatch(DocIdeasState.updateIdea({ id: ideaId, fields }))
      } else {
        console.warn("I have no idea how to answer this", { idea })
        dispatch(DocIdeasState.updateIdea({ id: ideaId, fields: { isLoadingCommands: false } }))
      }

    }

  }, [currentIdeaId, currentDocId, currentDocSummary, getContextForAi])


  const isLoading = useAppSelector(DocIdeasState.getIsLoadingIdeas)

  const runQuestionsPrompt = useCallback(async (mode: "loadFromCacheIfExists" | "alwaysHitTheApi") => {
    setVisibleIdeasCount(defaultCountOfVisibleIdeas)
    dispatch(DocIdeasState.setAllIdeasForDoc({ docId: currentDocId || "", ideas: [] }))
    getDocIdeasFromAiCoachCommandDefinition.triggerCommand?.({
      mode,
    })
  }, [setVisibleIdeasCount, currentDocId])


  const onAnswerUpdate = useCallback((idea: DocumentIdea, newAnswer: string) => {

    const fields: Partial<DocumentIdea> = {
      answer: newAnswer
    }
    dispatch(DocIdeasState.updateIdea({ id: idea.id, fields }))
  }, [])


  const onCommandStubClick = useCallback((idea: DocumentIdea, commandStub: CommandStub<any>) => {

    const fields: Partial<DocumentIdea> = {
      answer: commandStub.buttonText
    }
    dispatch(DocIdeasState.updateIdea({ id: idea.id, fields }))

    triggerGlobalCommand(commandStub.commandType, commandStub.args)

  }, [])


  const onApplyToDocumentClick = useCallback((idea: DocumentIdea) => {

    if (idea.id === currentIdeaId) {
      dispatch(DocIdeasState.setCurrentIdeaId(undefined))
    }
    dispatch(DocIdeasState.updateIdea({
      id: idea.id,
      fields: {
        isApplying: true,
      }
    }))

    hideDocCommandMenuCommandDefinition.triggerCommand?.({})

    if (!idea.answer && idea.title) {

      insertPlaceholder(idea, currentDocSummary)

    } else {

      applyIdeaToDocCommandDefinition.triggerCommand?.({
        ideaToApply: idea,
        onComplete: () => onApplyCompleted(idea)
      })
    }

  }, [currentIdeaId, currentDocSummary])


  const onDismissIdea = useCallback((idea: DocumentIdea) => {
    console.log("Dismissing idea ", idea.id)
    if (idea.id === currentIdeaId) {
      dispatch(DocIdeasState.setCurrentIdeaId(undefined))
    }
    dispatch(DocIdeasState.updateIdea({
      id: idea.id,
      fields: {
        status: Data.DocumentIdeas.DocumentIdeaStatus.dismissed
      }
    }))
  }, [currentIdeaId])


  const evaluateIfShouldRunQuestionsPrompt = useCallback((hasDocTitle: boolean) => {
    if (!currentDocId
      || ideasFiltered.length > 0
      || isLoading
      || !hasDocTitle) {

      return false
    }
    return true

  }, [ideasFiltered, isLoading, currentDocId])


  const initializeForDoc = useCallback(() => {
    const hasTitle = Boolean(currentDoc.title)
    const hasDescription = Boolean(currentDoc.description)
    const hasBody = currentDocSummary?.bodyHasText

    console.log("initializeForDoc", { hasTitle, hasDescription, hasBody })

    if (isInAutoAiMode && !isWorkingInAutoAiMode && !hasBody && (hasTitle || hasDescription)) {
      console.log("initializeForDoc: Running auto AI step")
      doNextAutoAiStepCommandDefinition.triggerCommand?.({
        step: "draftAStartingPoint",
      })
    }
    else if (evaluateIfShouldRunQuestionsPrompt(hasTitle)) {
      console.log("initializeForDoc: Running questions prompt")
      runQuestionsPrompt("loadFromCacheIfExists")
    }
  }, [currentDoc, evaluateIfShouldRunQuestionsPrompt, runQuestionsPrompt, isInAutoAiMode, isWorkingInAutoAiMode])


  // Auto trigger getting the questions
  useEffect(() => {
    setTimeout(() => {
      if (!currentDocId) return
      initializeForDoc()
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDocId])


  return (
    <div>
      <SubSectionWrapper
        title="AI Coach"
        initialAccordionState="expanded"
      >
        <div css={css`
          margin-bottom: 8px;
        `}>
          <AutoAiModeToggle
          />
        </div>

        <div css={css`
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin-left: 8px;
        `}>
          {visibleIdeas.map((idea, key) =>
            <IdeaCard
              key={key}
              ideaData={idea}
              onTitleClick={() => onTitleClick(idea)}
              onAnswerUpdate={(newAnswer) => onAnswerUpdate(idea, newAnswer)}
              onCommandStubClick={(commandStub: CommandStub<any>) => onCommandStubClick(idea, commandStub)}
              onApplyToDocumentClick={() => onApplyToDocumentClick(idea)}
              onDismiss={() => onDismissIdea(idea)}
              isExpanded={idea.id === currentIdeaId}
              isFadedToBackground={Boolean(currentIdeaId) && idea.id !== currentIdeaId}
            />
          )
          }

          {visibleIdeas.length < ideasFiltered.length &&
            <ButtonTertiary
              label="Show more"
              onClick={() => setVisibleIdeasCount(visibleIdeasCount + showMoreVisibleIdeasIncrement)}
              size="xs"
              css={css`
                margin: 0 auto;
                opacity: 0.5;
              `}
            />
          }
        </div>


        {ideasFiltered.length === 0 &&
          <div css={css`
            text-align: center;
            padding: 20px 0;
          `}>
            <ButtonSecondary
              label="Get suggestions"
              loadingText="Getting suggestions ..."
              size="xs"
              leftIcon={<VerdiIconAiSuggestions />}
              onClick={() => runQuestionsPrompt("alwaysHitTheApi")}
              isLoading={isLoading}
            />
          </div>
        }

        {(visibleIdeas.length > 0 && visibleIdeas.length === ideasFiltered.length) &&
          <div css={css`
            text-align: center;
            padding: 20px 0;
          `}>
            <ButtonTertiary
              label="Get more suggestions"
              size="xs"
              leftIcon={<VerdiIconAiSuggestions />}
              onClick={() => runQuestionsPrompt("alwaysHitTheApi")}
              isLoading={isLoading}
            />
          </div>
        }
      </SubSectionWrapper>
    </div>
  )
}


const insertPlaceholder = (idea: DocumentIdea, currentDocSummary: DocMetadataSummary | undefined) => {

  const rawPlaceholderText = DocumentSchema.PlaceholderInlineNodeUtils.wrapInPlaceholderMarkup(idea.question)
  if (!idea.title) {
    writeToDocBodyCommandDefinition.triggerCommand?.({
      documentOutline: [{ type: "text", content: idea.question, }],
      insertPosition: InsertPosition.AsSiblingsOrInEmptyLine,
    })
  }
  // add section 
  // TODO: Decide if we want to detect existing section with the title, then append to it's children
  const shouldMakeChildOfSection = !idea.markArgs && currentDocSummary?.sectionTitles.some(s => s.toLowerCase() === idea.title.toLowerCase())
  if (shouldMakeChildOfSection) {
    findAndReplaceCommandDefinition.triggerCommand?.({
      mode: "insertAsNextLineAfter",
      textToFind: idea.title,
      textToAdd: rawPlaceholderText,
      onCompleted: () => onApplyCompleted(idea)
    })
  } else {
    addSectionWithAiCommandDefinition.triggerCommand?.({
      id: idea.id,
      sectionTitle: idea.title,
      isSuggestionFromAi: true,
      placeholderText: idea.question,
      onCompleted: () => onApplyCompleted(idea)
    })
  }
}


const onApplyCompleted = (idea: DocumentIdea) => {
  dispatch(DocIdeasState.updateIdea({
    id: idea.id,
    fields: {
      isApplying: false,
      status: Data.DocumentIdeas.DocumentIdeaStatus.executed,
    }
  }))
}
