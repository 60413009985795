import { useAppServices } from "../../components/appServices/useAppServices"
import { PageLayoutSplitViewWrapper } from "../../components/pageLayout/pageLayoutSplitViewWrapper"
import { SuspenseErrorBoundary } from "../../components/SuspenseErrorBoundary"
import { ChatView } from "../../components/chat/ChatView"
import { PageHeading } from "../../components/pageLayout/PageHeading"
import { MainViewWrapper } from "../../components/pageLayout/MainViewWrapper"
import { DefinitionTipForUser } from "../../components/DefinitionTipForUser"
import { css } from "@emotion/react"
import { SideMenu } from "../opportunityDocument/SideMenu"
import { NewActionWizard } from "./NewActionWizard"
import { getUrlForDocument } from "../../routes"


export const NewActionPage = () => {

  const appServices = useAppServices()
  const {
    pageLayoutState,
    menuStructureProvider,
    opportunityProvider,
    router,
  } = appServices

  const { documentId } = router.match.params
  console.log("the documentId is ", documentId)


  // useEffect(() => {
  //   pageLayoutState.setMenuIsExpanded(false)
  // }, [])

  const currentDoc = menuStructureProvider.getMenuItem(documentId)


  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={appServices}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        rightSideInitialState={"hidden"}
        mainContent={

          <MainViewWrapper maxWidth={800}>



            <PageHeading
              titleNode={
                <span>
                  <span>
                    Create a new
                  </span>
                  <span css={css`
                      margin-left: 5px;
                    `}>
                    <DefinitionTipForUser
                      word="Action"
                      definition="Something you can do to make progress towards your goal."
                      pronunciation="ak-shn"
                      wordType="verb"
                    />
                  </span>
                </span>
              }
              description=""
            />


            {currentDoc &&
              <NewActionWizard
                currentDoc={currentDoc}
                onCreated={(newDocId) => {
                  const url = getUrlForDocument(newDocId)
                  console.log("redirect to: ", url)
                  router.router.push(url)
                }}
              />
            }

          </MainViewWrapper>
        }
      />
    </div>
  )
}

