import { ApiTypes, Data, DocumentSchema } from "@verdi/shared-constants"
import { makeDeleteRequest, makeGetRequestJson, makePostRequestJson } from "../../utility-hooks/fetchUtils"
import { useCallback, useRef, useState } from "react"
import { useThrottle } from "../../utility-hooks/useThrottle"
import { MenuStructureForAllDocsProvider } from "../document/organize/useGetMenuStructureForAllDocs"
import { useNewDocumentMutation } from "../documents/add/useAddNewDocument"
import { DocumentRelationsProvider } from "../documentRelation/useDocumentRelationsProvider"
import { useDeleteDocument } from "../document/useDeleteDocument"
import { OpportunityRisk, calculateOpportunityRisk } from "./risk/OpportunityRiskBadge"
import { RelatedMenuItemData } from "../documentRelation/data/groupRelatedDocs"
import { buildFirstDocStepFromOutline } from "../document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { dispatch } from "../../state/store"
import { AssumptionsState } from "../../state/assumptionsSlice"
import { createDocOutlineForNewAssumption } from "./createDocOutlineForNewAssumption"
import { DocumentFrameworksProvider } from "../documentFrameworks/DocumentFrameworksProvider"
import { DocBodySnapshotsState } from "../../state/docBodySnapshotsSlice"


type Props = {
  documentRelationsProvider: DocumentRelationsProvider
  menuStructureProvider: MenuStructureForAllDocsProvider
}
export const useAssumptionsProvider = (
  {
    documentRelationsProvider,
    menuStructureProvider,
  }: Props) => {

  const [assumptionsInLocalState, setAssumptions] = useState<Data.AssumptionModel[]>([])

  /** TODO: This throws a "Maximum update depth exceeded." error. Need to figure out why.
      Want to replace assumptionsInLocalState with assumptionsInRedux */
  // const assumptionsInRedux = useAppSelector(AssumptionsState.getAllAssumptions)


  const [createDocument] = useNewDocumentMutation(false, [])
  const [deleteDocument] = useDeleteDocument(menuStructureProvider, [])

  const [hasLoadedAssumptions, setHasLoadedAssumptions] = useState(false)
  const loadAllPromiseRef = useRef<Promise<any>>()
  /** returns the single promise that loads all assumptions one time */
  const ensureAllAreLoaded = useCallback(async () => {

    if (loadAllPromiseRef.current) {
      return loadAllPromiseRef.current
    }

    loadAllPromiseRef.current = new Promise<void>(async (resolve, reject) => {
      // TODO: Remove this here, in favor of LoadInitialAppState.ts
      const response = await makeGetRequestJson("assumptions")
      const assumptions = response as Data.AssumptionModel[]
      setAssumptions(assumptions)
      // dispatch(AssumptionsState.setAllAssumptions({ assumptions, loading: false }))
      setHasLoadedAssumptions(true)

      resolve()
    })
  }, [setAssumptions, setHasLoadedAssumptions])


  /*
    Create 
  */
  const afterDocCreatedThenCreateAssumption = useCallback(async (
    newDocId: string,
    input: CreateAssumptionInput,
    framework: Data.FrameworkModel | undefined,
    onCreatedComplete: (
      newAssumptionDocId: string,
      assumptionName: string,
      userShouldReview: boolean,
    ) => void,
    onError: (errorMessage: string, errorDetails?: string) => void,
  ) => {

    try {
      const body: ApiTypes.AssumptionUpdateRequestBody = {
        fields: input.assumptionToAdd,
        mainDocId: newDocId,
        frameworkQuestionId: input.frameworkQuestionId,
      }
      const response = await makePostRequestJson("assumptions", body)
      const newAssumption = response as Data.AssumptionModel

      setAssumptions(prev => [...prev, newAssumption])
      dispatch(AssumptionsState.addAssumption(newAssumption))

      if (framework) {
        await DocumentFrameworksProvider.setFrameworkForDocument(newDocId, framework)
      }

      console.log("New assumption created = ", newAssumption)
      onCreatedComplete(newAssumption.mainDoc?.id || "", input.docTitle, input.userShouldReview)
    } catch (error) {
      onError("Could not add assumption.", "Try back later.")
    }
  }, [setAssumptions])

  const create = useCallback((
    input: CreateAssumptionInput,
    onCreatedComplete: (
      newAssumptionDocId: string,
      assumptionName: string,
      userShouldReview: boolean,
    ) => void,
    onError: (errorMessage: string, errorDetails?: string) => void,
  ) => {

    const { opportunityId, relatedDocumentId, answerText } = input

    const {
      initialOutline,
      framework,
    } = createDocOutlineForNewAssumption(answerText)

    console.log("initialOutline", { initialOutline })

    let initialStepJson: string | undefined = undefined
    if (initialOutline.length > 0) {
      const initialSteps = buildFirstDocStepFromOutline(initialOutline)
      initialStepJson = JSON.stringify(initialSteps)
    }

    if (relatedDocumentId) {
      documentRelationsProvider.createNewDocWithRelation({
        currentDocId: relatedDocumentId,
        newDocTitle: input.docTitle,
        newDocType: DocumentSchema.DocumentType.assumption,
        direction: "currentDocIsTo",
        initialStepJson,
        onError,
        relationType: Data.DocumentRelationType.assumptionOf,
        parentDocId: relatedDocumentId,
        opportunityId,
        onComplete: async (newDocId) => {
          console.log("AssumptionsProvider.createDocumentWithRel ", newDocId)
          await afterDocCreatedThenCreateAssumption(newDocId, input, framework, onCreatedComplete, onError)
        },
      })
    } else {
      createDocument({ // No Relations
        title: input.docTitle,
        type: DocumentSchema.DocumentType.assumption,
        opportunityId,
      },
        menuStructureProvider
        , async (createdDocResponse) => {
          console.log("AssumptionsProvider.createDocument ", createdDocResponse)
          const newDocId = createdDocResponse?.createDocument?.id
          if (!newDocId) {
            onError("Could not add assumption.", "Try back later.")
            return
          }

          await afterDocCreatedThenCreateAssumption(newDocId, input, framework, onCreatedComplete, onError)

        })
    }
  }, [documentRelationsProvider, createDocument, menuStructureProvider, afterDocCreatedThenCreateAssumption])


  /*
    Update 
  */
  const [assumptionIdsPendingUpdate, setAssumptionIdsPendingUpdate] = useState<string[]>([])

  const updateFieldDebounced = useThrottle(async (args: UpdateAssumptionArgs) => {

    const { assumptionId, field, value } = args
    const body: ApiTypes.AssumptionUpdateRequestBody = {
      fields: { [field]: value },
    }
    await makePostRequestJson(`assumptions/${assumptionId}`, body)
    setAssumptionIdsPendingUpdate(prev => prev.filter(id => id !== assumptionId))
  }, 1000)

  const updateField = useCallback(async (args: UpdateAssumptionArgs) => {
    setAssumptionIdsPendingUpdate(prev => [...prev, args.assumptionId])
    const { assumptionId, field, value } = args

    setAssumptions(prev => {
      const index = assumptionsInLocalState.findIndex(a => a.id === assumptionId)
      return [
        ...prev.slice(0, index),
        { ...prev[index], [field]: value },
        ...prev.slice(index + 1)
      ]
    })
    dispatch(AssumptionsState.updateAssumption({ id: assumptionId, fields: { [field]: value } }))

    await updateFieldDebounced(args)

  }, [assumptionsInLocalState, updateFieldDebounced])

  /*
    Remove 
  */
  const remove = useCallback(async (assumptionToDelete: Data.AssumptionModel) => {

    const idToDelete = assumptionToDelete.id
    setAssumptions(prev => {
      const index = assumptionsInLocalState.findIndex(a => a.id === idToDelete)
      return [
        ...prev.slice(0, index),
        ...prev.slice(index + 1)
      ]
    })
    dispatch(AssumptionsState.removeAssumption({ id: idToDelete }))

    if (assumptionToDelete.mainDoc?.id) {
      console.log("will now delete document", assumptionToDelete.mainDoc.id)
      deleteDocument(assumptionToDelete.mainDoc.id, async () => {
        await makeDeleteRequest(`assumptions/${idToDelete}`)
      })

    } else {
      console.log("no main doc id")
      await makeDeleteRequest(`assumptions/${idToDelete}`)
    }

  }, [assumptionsInLocalState, deleteDocument])


  /** Returns all the assumptions related to a given document */
  const getAssumptionsForDocWithRelations = useCallback((documentId: string, docsRelatedToMe: RelatedMenuItemData[]) => {
    const documentRelations = documentId ?
      docsRelatedToMe.filter(r => r.relation.toDoc?.id === documentId) :
      docsRelatedToMe

    const docIdsToInclude = documentRelations.map(r => r.relation.fromDoc?.id)
    const filtered = assumptionsInLocalState.filter(a => docIdsToInclude.includes(a.mainDoc?.id))
    const toReturn = filtered.map(a => {
      return {
        ...a,
        mainDoc: documentRelations.find((r => r.relation.fromDoc?.id === a.mainDoc?.id))?.relation.fromDoc,
        relations: documentRelations.filter(r => r.relation.fromDoc?.id === a.mainDoc?.id)
      } as AssumptionWithRelations
    })

    const risk = calculateOpportunityRisk(toReturn)

    return {
      assumptions: toReturn,
      opportunityRisk: risk,
    } as AssumptionsForDoc
  }, [assumptionsInLocalState])


  /** Simplified version of getAssumptionsForDoc */
  const getAssumptionsForDoc = useCallback((documentId: string) => {

    const relations = documentRelationsProvider.getRelationsForDocGrouped(documentId)
    const documentRelations = relations.toMe.assumptionDocs

    return getAssumptionsForDocWithRelations(documentId, documentRelations).assumptions

  }, [documentRelationsProvider, getAssumptionsForDocWithRelations])


  const getAssumptionsContextForAi = useCallback(async (docId: string) => {

    // TODO: Cache this to reduce API calls
    const assumptions = await makeGetRequestJson(`documents/${docId}/related?type=assumption`) as ApiTypes.DocumentSnapshotResponseBody[]
    // Might not need this
    // dispatch(DocBodySnapshotsState.addOrUpdateMany(assumptions))
    return {
      docBodySnapshots: assumptions,
      assumptionSummaries: assumptions.map(a => ({
        question: a.title,
        answer: a.bodyPlainText,
      } as AssumptionSummary)),
    } as AssumptionsContextForAi
  }, [])


  return {
    assumptions: assumptionsInLocalState,
    ensureAllAreLoaded,
    hasLoadedAssumptions,
    getAssumptionsForDoc,
    getAssumptionsForDocWithRelations,
    getAssumptionsContextForAi,
    create,
    updateField,
    assumptionIdsPendingUpdate,
    remove
  } as AssumptionsProvider

}

type AssumptionsContextForAi = {
  docBodySnapshots: ApiTypes.DocumentSnapshotResponseBody[]
  assumptionSummaries: AssumptionSummary[]
}

export type AssumptionSummary = {
  question: string
  answer: string
  isLoading?: boolean
}

export type UpdateAssumptionArgs = {
  assumptionId: string,
  mainDocId?: string,
  field: keyof Data.AssumptionModel,
  value: any
}

export type CreateAssumptionInput = {
  docTitle: string,
  assumptionToAdd: Data.AssumptionMutableFields,
  relatedDocumentId?: string,
  opportunityId?: string,
  relatedOpportunityMainDocId?: string,
  userShouldReview: boolean,
  frameworkQuestionId: string | undefined
  answerText: string
}

export type AssumptionsForDoc = {
  assumptions: AssumptionWithRelations[];
  opportunityRisk: OpportunityRisk;
}

export type AssumptionWithRelations = Data.AssumptionModel & {
  relations: RelatedMenuItemData[]
}

export type AssumptionWithDocBodySnapshot = Data.AssumptionModel & {
  snapshot?: ApiTypes.DocumentSnapshotResponseBody
}

export type AssumptionsProvider = {
  assumptions: Data.AssumptionModel[]
  ensureAllAreLoaded: () => Promise<void>
  hasLoadedAssumptions: boolean
  create: (
    input: CreateAssumptionInput,
    onCreatedComplete: (
      newAssumptionDocId: string,
      assumptionName: string,
      userShouldReview: boolean,
    ) => void,
    onError: (errorMessage: string, errorDetails?: string) => void,
  ) => void
  updateField: (args: UpdateAssumptionArgs) => void
  assumptionIdsPendingUpdate: string[]
  remove: (assumptionToDelete: Data.AssumptionModel) => void
  getAssumptionsForDoc: (documentId: string) => Data.AssumptionModel[]
  getAssumptionsForDocWithRelations: (documentId: string, docsRelatedToMe: RelatedMenuItemData[]) => {
    assumptions: AssumptionWithRelations[];
    opportunityRisk: OpportunityRisk;
  }
  getAssumptionsContextForAi: (docId: string) => Promise<AssumptionsContextForAi>
}
