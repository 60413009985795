import { css } from "@emotion/react"
import { VerdiIconAdd } from "./icons/VerdiIcons"
import { ButtonPrimary } from "./buttons/ButtonPrimary"
import { ReactNode } from "react"
import { mediaQuerySmallWidth } from "./pageLayout/styleHelpers"


type Props = {
  title: string
  children: ReactNode | ReactNode[]
  onButtonClick?: () => void
  buttonLabel: string,
}
export const EmptyStateMessage = ({
  title,
  children,
  onButtonClick,
  buttonLabel,
}: Props) => {


  return (
    <div css={css`
      width: 100%;
      margin-top: 40px;
    `}>
      <div
        css={css`
          margin: 20px auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 8px;
          border: 5px solid #99999911;
          border-radius: 12px;
          padding: 40px;
          max-width: 600px;
          @container mainStage ${mediaQuerySmallWidth} {
            padding: 10px;
          }
      `}>

        <h2 css={css`
          font-size: 1.3rem;
          opacity: 0.7;
        `}>
          {title}
        </h2>

        <div
          css={css`
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}>
          {children}
        </div>

        <ButtonPrimary
          leftIcon={<VerdiIconAdd />}
          onClick={onButtonClick}
          label={buttonLabel}
        />

      </div>
    </div>
  )
}
