import { Data } from "@verdi/shared-constants"
import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { conceptsAsPromptContext } from "../asPromptContext"


export const getAssumptionLevelsPrompt = (
  assumptionsToGrade: string[],
  documentContext: DocumentContextForAi | undefined,
  companyContext: string
) => {


  const docTitle = documentContext?.title
  const docBody = documentContext?.body
  const docConcepts = conceptsAsPromptContext(documentContext?.relatedDocs, documentContext?.id)

  const confidenceLevels = Data.AssumptionConfidenceLevelOptions.filter(l => l.value > 0)
  const relevanceLevels = Data.AssumptionRelevanceLevelOptions.filter(l => l.value > 0)

  /*
    label: "1",
  description: "One of many likely possibilities	"
  */
  const prompt = `
For each assumption, determine two things considering the context of the document.
Confidence Level
Relevance Level

When determining the confidence level, always error on the side of lower confidence. Think critically. 
If the assumption is based on the company's ability to do a thing, or is based in user/customer willingness to do 
or value a certain thing, assert confidence as "Unknown". If the assumption is backed by evidence you can find on 
the internet, assert a level of confidence such as "Hypothesis".
Confidence has 5 levels. Only use levels 1-3 to score confidence. Each level is defined below:
${confidenceLevels.map(l => `${l.value}\t${l.label}\t${l.description}`).join("\n")}

When determining the Relevance level, if the assumption is key to determining if the opportunity is worth pursuing, then assert a higher level of relevance. If the assumption is less significant, or is one of many ways to solve for a particular need assert a lower level of relevance like "Nice to Have". Error on the side of higher relevance.
Relevance has 5 levels. Each level is defined below:
${relevanceLevels.map(l => `${l.value}\t${l.label}\t${l.description}`).join("\n")}

More often than not, you will be asserting a level of confidence of "Unknown" and error on the side of higher relevance. Return the assumption with only the number associated with the level of confidence (1-5) and the level of relevance (1-5).

for the following assumptions:
${assumptionsToGrade.join("\n")}

For document context consider the following:
${docTitle}
${docBody}
${docConcepts}
${companyContext}

Format as: Confidence level (1-3) | Relevance level (1-5) | assumption
Example: 
2|5|Customers will value the new feature we are building
2|4|We will be successful
1|2|Pigs will fly
  `

  return prompt
}

export const parseAssumptionLevelsPromptResults = (rawResults: string) => {

  const lines = rawResults.split("\n")
  const results = lines.map(line => {

    const [confidence, relevance, ...assumption] = line.split("|")

    let confidenceAsNumber = parseInt(confidence)
    if (confidenceAsNumber > 5) confidenceAsNumber = NaN
    if (confidenceAsNumber < 0) confidenceAsNumber = NaN

    let relevanceAsNumber = parseInt(relevance)
    if (relevanceAsNumber > 5) relevanceAsNumber = NaN
    if (relevanceAsNumber < 0) relevanceAsNumber = NaN

    return {
      confidence: Number.isNaN(confidenceAsNumber) ? undefined : confidenceAsNumber,
      relevance: Number.isNaN(relevanceAsNumber) ? undefined : relevanceAsNumber,
      assumption: assumption.join("|")
    }
  })

  return results
}

