import graphql from 'babel-plugin-relay/macro'
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation';
import { UpdateSnippetInput, useUpdateSnippetMutation } from './__generated__/useUpdateSnippetMutation.graphql';
import { useCallback } from 'react';
import { useCreateAiPromptMutation$data } from '../documentTemplate/aiPromptForm/__generated__/useCreateAiPromptMutation.graphql';


const mutationQL = graphql`
  mutation useUpdateSnippetMutation (
    $input: UpdateSnippetInput!
  ) {
    updateSnippet(input: $input){
      id
      placeholderKey
      textValue
    }
  }
`


export function useUpdateSnippet(
  onUpdated?: (response: useCreateAiPromptMutation$data) => void
) {

  const [commit, isSaving] = useErrorHandledMutation<useUpdateSnippetMutation>(mutationQL)
  const update = useCallback((
    input: UpdateSnippetInput
  ) => {
    commit({
      variables: {
        input,
      },
    })

  }, [])


  return [update, isSaving] as const
}
