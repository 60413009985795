import React, { createContext, useContext, useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { ReactNodeViewContextQuery, ReactNodeViewContextQuery$data } from './__generated__/ReactNodeViewContextQuery.graphql';
import graphql from 'babel-plugin-relay/macro'


const queryQl = graphql`
  query ReactNodeViewContextQuery {
    ...useReactNodeViewSnippet_query
  }
`

export const ReactNodeViewDefaultValues = {
  queryKey: null
}

type ReactNodeViewContextValues = {
  queryKey: ReactNodeViewContextQuery$data | null
}
type ReactNodeViewContext = {} & ReactNodeViewContextValues

const ReactNodeViewContext = createContext<ReactNodeViewContext>(ReactNodeViewDefaultValues)
const ReactNodeViewProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {

  const queryKey = useLazyLoadQuery<ReactNodeViewContextQuery>(queryQl, {})

  return useMemo(() => {
    return (
      <ReactNodeViewContext.Provider
        value={{
          queryKey,
        }}>
        {children}
      </ReactNodeViewContext.Provider>
    )
  }, [queryKey])
}

const useReactNodeViewContext = () => useContext(ReactNodeViewContext)
export { ReactNodeViewProvider, useReactNodeViewContext }
