import {
  DocumentSchema,
  ProsemirrorNodes,
  Data,
} from "@verdi/shared-constants";
import { DocumentRelationsData } from '../../screens/documentRelation/useDocumentRelationsProvider';
import { SectionInfo } from "../../screens/document/tiptapEditor/utils/getSectionInfo";
import { BodyAsStringParts } from "../documents/getDocAsIndentedMarkdown";


export type DocumentContextForAi = {
  id: string;
  title: string | null;
  description: string | null;
  type: DocumentSchema.DocumentType
  body: string;
  bodyPartsAsMarkdown?: BodyAsStringParts;
  bodyBeforeSelection: string;
  bodyAfterSelection: string;
  documentVersion: number;
  rangeAtEndOfDocument: { from: number; to: number };
  rangeAtCurrentSelection?: { from: number; to: number };
  rangeAtEndOfCurrentNode?: { from: number; to: number };
  rangeAtNextRootNodeInsert?: { from: number; to: number };
  aiPromptId?: string;
  aiPromptInsertRange?: { from: number; to: number };
  nearestNestableNodeType?: ProsemirrorNodes.NestableBlockType;
  relatedDocs: DocumentRelationsData[]
  childDocs: Data.Menu.MenuItemData[]
  parentSectionInfo?: SectionInfo
  sectionTitles?: string[]
  selectedText?: string
  framework?: Data.FrameworkModel
  currentPlaceholderAttrs?: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes;
  currentLineText?: string
};
export const EmptyDocumentContextForAi: DocumentContextForAi = {
  body: "",
  description: "",
  title: "",
  id: "",
  type: DocumentSchema.DocumentType.document,
  bodyAfterSelection: "",
  bodyBeforeSelection: "",
  documentVersion: 0,
  rangeAtEndOfDocument: { from: 0, to: 0 },
  relatedDocs: [],
  childDocs: [],
};


export const buildPromptToContinueWritingDoc = () => {
  return `Write another concise paragraph within this document:`;
};

