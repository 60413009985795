import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { DocumentEditor } from '../document/DocumentEditor'
import { PageLayoutSplitViewWrapper } from '../../components/pageLayout/pageLayoutSplitViewWrapper'
import { SideMenu } from "../opportunityDocument/SideMenu"
import { SuspenseErrorBoundary } from '../../components/SuspenseErrorBoundary'
import { useUrlParams } from '../../utility-hooks/useUrlParams'
import { useAppServices } from '../../components/appServices/useAppServices'
import { MainViewWrapper } from '../../components/pageLayout/MainViewWrapper'
import { DocBreadcrumb } from '../documentRelation/breadcrumbs/DocBreadcrumb'
import { HomePageQuickAction } from './HomePageQuickAction'
import { addOpportunityShowOptionsCommandDefinition } from '../../components/commands/commandDefinitions/opportunity/addOpportunityShowOptions'
import { DocumentReference } from '../../components/DocumentReference'
import { OnboardingStatusList } from '../onboarding/OnboardingStatusList'
import { useAppSelector } from '../../state/storeHooks'
import { DocumentsState } from '../../state/DocumentsSlice'
import { dispatch } from '../../state/store'
import { DocViewToggle, DocumentViewMode } from '../../components/kanban/DocViewToggle'
import { DocumentMindMapGraph } from '../../components/mindMap/DocumentMindMapGraph'


export function HomePage() {

  const sharedServicesProvider = useAppServices()
  const {
    pageLayoutState,
    router,
    menuStructureProvider,
    opportunityProvider,
  } = sharedServicesProvider

  const { recentDocumentMenuItems } = menuStructureProvider

  // Document related
  const documentIdFromRoute = router.match.params['documentId'] || ""
  const documentId = useAppSelector(DocumentsState.getCurrentDocId)
  const [rightSideInitialState, setRightSideInitialState] = useState<"hidden" | "visible" | "fullscreen">("hidden")
  const { chatIsVisible } = useUrlParams()
  const [docViewMode, setDocViewMode] = useState<DocumentViewMode>(DocumentViewMode.DocumentEditor)


  useEffect(() => {

    const isRootHomepage = window.location.pathname.toLowerCase() === "/home"
    if (isRootHomepage) {
      // setRightSideInitialState("fullscreen")
    } else if (documentIdFromRoute || chatIsVisible) {
      setRightSideInitialState("visible")
    }
    dispatch(DocumentsState.setCurrentDocId(documentIdFromRoute))

  }, [documentIdFromRoute, chatIsVisible])


  const recentDocsMaxCount = 5
  const recentlyViewedDocs = useMemo(() => {
    if (!recentDocumentMenuItems) return []
    if (recentDocumentMenuItems.length > recentDocsMaxCount) {
      return recentDocumentMenuItems.slice(0, recentDocsMaxCount)
    }
    return recentDocumentMenuItems
  }, [recentDocumentMenuItems])


  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={sharedServicesProvider}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        rightSideInitialState={rightSideInitialState}
        mainContent={

          <div>

            {!documentId &&
              <div css={css`
              width: 100%;
              max-width: 1200px;
              min-height: 60vh;
              position: relative;
            `}>

                <div css={css`
                  background-image: url("/verdi-logo-big.svg");
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  opacity: 0.05;
                  width: 100%;
                  max-width: 1200px;
                  height: 60vh;
                  position: absolute;
                  z-index: -1;
                `}>
                </div>

                <MainViewWrapper>
                  <div css={css`
                    min-height: 60vh;
                    display: flex;
                    flex-direction: column;
                  `}>
                    <h2 css={css`
                      margin-bottom: 10px;
                    `}>
                      Welcome to Verdi
                    </h2>
                    <div css={css`
                      display: flex;
                      flex-direction: row;
                    `}>

                      <HomePageQuickAction
                        title="Start an Opportunity"
                        description='Identify gaps, validate assumptions, create documents.'
                        onClick={() => addOpportunityShowOptionsCommandDefinition.triggerCommand?.({})}
                      />
                    </div>


                    {recentlyViewedDocs.length > 0 &&
                      <div css={css`
                        margin-bottom: 10px;
                      `}>
                        <h2>Resume your journey ...</h2>
                        <div>
                          {recentlyViewedDocs.map((menuItem, key) =>
                            <div key={key}>
                              <DocumentReference
                                title={menuItem.title}
                                url={menuItem.url}
                              />
                            </div>

                          )}

                        </div>
                      </div>
                    }


                    <div css={css`
                      margin-bottom: 10px;
                    `}>
                      <OnboardingStatusList />
                    </div>

                  </div>


                </MainViewWrapper>
              </div>
            }

            {documentId &&

              <div>

                <MainViewWrapper doNotAddMarginToBottom={true}
                  maxWidth={3024}>

                  <div css={css`
                    min-height: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  `}>

                    <DocBreadcrumb
                      currentDocId={documentId}
                      flatMenuItems={menuStructureProvider.menuItemsStructured.flatMenuItems}
                    />

                    <DocViewToggle
                      viewMode={docViewMode}
                      setViewMode={setDocViewMode}
                    />
                  </div>

                  {docViewMode === DocumentViewMode.MindMap &&
                    <DocumentMindMapGraph
                      documentId={documentId}
                    />
                  }

                  {docViewMode === DocumentViewMode.DocumentEditor &&
                    <DocumentEditor
                      pageLayoutState={pageLayoutState}
                      documentId={documentId}
                      key={documentId}
                      onCurrentBlockContextChange={(args) => null}
                      sharedServicesProvider={sharedServicesProvider}
                    />
                  }

                </MainViewWrapper>

              </div>
            }
          </div>
        }
      />
    </div>
  )
}
