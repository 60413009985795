import { ResolvedPos } from "@tiptap/pm/model"
import { DocumentSchema } from "@verdi/shared-constants"
import { ContentNodeWithPos } from "prosemirror-utils"


export type AtomicNodeInfo = {
  nodeType?: DocumentSchema.VerdiNodeTypes
  placeholderAttrs?: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes
  docReferenceAttrs?: DocumentSchema.NodeExtensions.DocReferenceInlineNodeAttributeValues
}

/** Retrieves attributes and other info specific to Prosemirror nodes where `atom: true` 
 *  Such as PlaceholderInlineNode and DocReferenceInlineNode
 */
export const getAtomicNodeInfo = (
  currentNode: ContentNodeWithPos | undefined,
  resolvedPos: ResolvedPos) => {

  if (!currentNode) {
    return undefined
  }

  const relativePos = resolvedPos.pos - currentNode?.start
  const placeholderType = DocumentSchema.VerdiNodeTypes.PlaceholderInline
  const docReferenceType = DocumentSchema.VerdiNodeTypes.docReferenceInline

  let nodeType: DocumentSchema.VerdiNodeTypes | undefined
  let placeholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes | undefined
  let docReferenceAttrs: DocumentSchema.NodeExtensions.DocReferenceInlineNodeAttributeValues | undefined
  currentNode.node.descendants((node, pos) => {
    if (node.type.name === placeholderType && pos === relativePos) {
      /*
      This `pos === relativePos` is dependent on the fact that a PlaceholderInlineNode has `atom: true`
      Effectively making it only possible to have the caret at the start of the placeholder 
      (not in other positions within in)
      */
      nodeType = placeholderType
      placeholderAttrs = node.attrs as DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes
    } else if (node.type.name === docReferenceType && pos === relativePos) {
      nodeType = docReferenceType
      docReferenceAttrs = node.attrs as DocumentSchema.NodeExtensions.DocReferenceInlineNodeAttributeValues
    }
  })

  return {
    nodeType,
    placeholderAttrs,
    docReferenceAttrs,
  } as AtomicNodeInfo
}
