import { SuggestedActionType } from "../../../screens/deRiskingSteps/DeRiskingStepOptions"
import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { buildDocContextForPrompt } from "../../promptUtils/buildDocContextForPrompt"


const suggestedActionTypesAsString = Object.values(SuggestedActionType).join(", ")

export const getWaysToTestAssumptionsPrompt = (
  docContext: DocumentContextForAi | undefined,
  assumptions: string[],
  resultCount: number) => {

  const promptContext = docContext ? buildDocContextForPrompt(docContext, true).detailsForPrompt : ""

  const prompt = `
  ###
  CONTEXT: 
  ${promptContext}
  ###

Given the following: 

Assumptions: 
${assumptions.join(",\n")}

ActionTypes:
${suggestedActionTypesAsString}

INSTRUCTIONS: Give ${resultCount} specific options for ways to validate the Assumptions. 
Be very concise. Use shorthand as needed.

Return the options to test in the following format:
ActionType|short Title Of Action|description of the action to take to validate the assumption
AnotherActionType|short Title Of Action|description of another action
Other|a verb|Other ways to test the assumption not covered with the action types.

Example 1: If the assumption is "Students would buy a cheaper lunch option."
Then the options to test could be:
Interview|Conduct Interviews|To understand lunch preferences, habits, and pain points.
DeskResearch|Analyze market research data|Better understand the demand for quick and filling lunch options.
Other|Ask your colleagues|Ask people about their preferences.
UserTesting|Offer samples or discounts|give people incentives to try the lunch options and gather feedback on target customer's perceived value.

Example 2: If the assumption is "Customers will value the new feature we are building."
Then the options to test could be:
Interview|Interview an expert|Better understand the value of the feature.
Survey|Conduct a survey|Understand what value people want out of the feature.
Experiment|Run an A/B test| See which options perform better.
   `

  return prompt
}
