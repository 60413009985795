import { uniqueId } from "lodash"
import { DocumentContextForAi } from "../../../../../../../ai/coach/AiCoachPrompts"
import { SuggestionForDocument } from "../useWhatsNextSuggester"
import { draftResearchPlanCommandDefinition } from "../../../../../../../components/commands/commandDefinitions/documentBody/draftResearchPlan"


export const getWhatsNextForResearch = async (
  onCommandCompleted: (commandId: string) => void,
  docContext?: DocumentContextForAi,
): Promise<SuggestionForDocument[]> => {

  if (!docContext) {
    console.warn("useWhatsNextForInterviewGuide: no doc context")
    return []
  }

  const suggestions: SuggestionForDocument[] = []

  if (docContext.body.length < 300) {
    const commandId = uniqueId("draftResearch-")
    const draftInterviewGuideCommand = draftResearchPlanCommandDefinition.buildMenuItem({
      id: commandId,
      onCompleted: () => onCommandCompleted(commandId),
    })
    suggestions.push({
      command: draftInterviewGuideCommand,
    })
  }

  return suggestions
}

