import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { navigateToCommandDefinition } from "../navigateTo";
import { showMiniCommandMenuCommandDefinition } from "./showMiniCommandMenu";

const cmdType = GlobalCommandType.showMenuItemSearch



export const showMenuItemSearchCommandDefinition: GlobalCommandDefinition<ShowMenuItemSearchArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowMenuItemSearchArgs) => {
    const toReturn = {
      globalCommandType: cmdType,
      name: 'Go to ...',
      description: '',
      searchName: 'go to',
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
      iconType: "navigate",
      shouldKeepOpenOnRun: true,
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowMenuItemSearchArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {
    const { menuStructureProvider } = services

    // TODO: maybe if we can get some sort of pass back filtering from the input in the MiniCM we can use this
    // const menuItems = getFilteredFlatMenuItems(
    //   menuStructureProvider.menuItemsStructured.flatMenuItems,
    //   {
    //     searchRequired: false,
    //     limitTo: 20,
    //     bypassLimitToIfWildcard: true,
    //     excludeEmptyTitles: true,
    //   })

    const commands = menuStructureProvider.menuItemsStructured.flatMenuItems
      .filter((menuItem) => Boolean(menuItem.title) && Boolean(menuItem.url))
      .map((menuItem) => {
        return navigateToCommandDefinition.buildMenuItem({
          nameOfPage: menuItem.title,
          urlOfPage: menuItem.url,
          excludeNavPrefix: true,
        })
      }) as VerdiCommand[]

    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands,
      searchPlaceholderText: "type here to search ...",
      rectOfAnchorElement: args.rect,
      shouldAdjustRectForScrollOffset: false,
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: ShowMenuItemSearchArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ShowMenuItemSearchArgs = {
  rect?: DOMRect
}
