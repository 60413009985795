import { css } from "@emotion/react"
import { useColor } from "../../utility-hooks/useColor"
import { KanbanCardData } from "./KanbanCard"
import { KanbanListItemDragNDrop } from "./KanbanListItemDragNDrop"
import { VerdiIconArrowForward } from "../icons/VerdiIcons"
import { KanbanCardBadgeRow } from "./KanbanCardBadgeRow"


export type KanbanListItemProps = {
  setTitle: (newTitle: string, cardData: KanbanCardData) => void,
  cardData: KanbanCardData
  isBeingDragged: boolean,
  isDraggingOver: boolean,
  onCardClick: (cardId: string) => void,
  onShowInfoBadgeClick: (card: KanbanCardData, rect: DOMRect) => void,
}
export const KanbanListItem = ({
  cardData,
  setTitle,
  isBeingDragged,
  isDraggingOver,
  onCardClick,
  onShowInfoBadgeClick,
}: KanbanListItemProps) => {


  const borderColor = useColor("surfaceBorder")
  const borderOnDrag = useColor("surfaceBorderActive")
  const backgroundColor = useColor("surfaceBgSecondary")

  const {
    title,
    childCount,
    risk,
  } = cardData


  return (
    <div>
      <div
        css={css`
        padding: 0;
        border-radius: 8px;
        border: 1px solid ${isBeingDragged ? borderOnDrag : borderColor};
        background-color: ${backgroundColor};
        box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.05);
        margin: 0 0 2px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `}>

        <h3
          title={title}
          onClick={() => onCardClick(cardData.id)}
          css={css`
          font-size: 0.9rem;
          line-height: 1.2;
          letter-spacing: 0.5px;
          margin: 0;
          text-transform: none;
          opacity: 1;
          padding: 4px 10px;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
      `}>
          {isDraggingOver && !isBeingDragged &&
            <small>
              Move under <VerdiIconArrowForward /> &nbsp;
            </small>
          }

          {title}
        </h3>


        {!isBeingDragged && !isDraggingOver &&
          <div css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
      `}>

            <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}>

              <KanbanCardBadgeRow
                cardData={cardData}
                onShowInfoBadgeClick={onShowInfoBadgeClick}
              />

              <div css={css`
          display: flex;
          gap: 8px;
          align-items: center;
        `}>
                {/* <small>
            {statusText}
          </small> */}
                {/* <Avatar
            size="2xs"
          /> */}
              </div>

            </div>
          </div>
        }

      </div>

      {cardData.childOpportunities.length > 0 &&
        <div css={css`
          padding: 0 0 0 30px;
        `}>
          {cardData.childOpportunities.length > 0 && cardData.childOpportunities.map((childCard, key) =>

            <KanbanListItem
              key={key}
              cardData={childCard}
              setTitle={setTitle}
              isBeingDragged={isBeingDragged}
              isDraggingOver={isDraggingOver}
              onCardClick={onCardClick}
              onShowInfoBadgeClick={onShowInfoBadgeClick}
            />
          )

          }
        </div>
      }

      {/* When we are ready to revisit the nested Drag and Drop */}
      {/* {cardData.childOpportunities.length > 0 && !isBeingDragged &&
        <div css={css`
          padding: 0 0 0 30px;
        `}>
          {cardData.childOpportunities.length > 0 && cardData.childOpportunities.map((childCard, key) =>
            
            <KanbanListItemDragNDrop
              key={key}
              cardData={childCard}
              setTitle={setTitle}
              isBeingDragged={isBeingDragged}
              isDraggingOver={isDraggingOver}
              onCardClick={onCardClick}
              onShowInfoBadgeClick={onShowInfoBadgeClick}
              index={key}
              dndId={cardData.id}
            />
          )

          }
        </div>
      } */}

    </div>
  )
}
