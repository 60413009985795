import { useEffect } from "react";
import { DocUpdatedCustomEvent, DocUpdatedEvent } from "../../../ai/documents/DocUpdatedEvent";
import { syncDocBodyWithDatabase } from "./syncDocBodyWithDatabase";


/** registers a listener that calls `syncDocBodyWithDatabase()` as the document body changes */
export const useSyncDocBodyWithDatabase = () => {


  useEffect(() => {
    DocUpdatedEvent.addEventListener(handleSyncDocBodyWithDatabase)
    return () => {
      DocUpdatedEvent.removeEventListener(handleSyncDocBodyWithDatabase)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

}

const handleSyncDocBodyWithDatabase = (evt: DocUpdatedCustomEvent) => {
  syncDocBodyWithDatabase(evt.detail)
}
