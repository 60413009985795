import { fillInSectionCommandDefinition } from "./commandDefinitions/documentBody/FillInSection";
import { addDocAsActionCommandDefinition } from "./commandDefinitions/action/addDocAsAction";
import { addDocWithFrameworkCommandDefinition } from "./commandDefinitions/document/addDocWithFramework"
import { addOpportunityCommandDefinition } from "./commandDefinitions/opportunity/addOpportunity";
import { addSectionWithAiCommandDefinition } from "./commandDefinitions/documentBody/addSectionWithAi";
import { addSectionWithAiShowMoreCommandDefinition } from "./commandDefinitions/documentBody/addSectionWithAiShowMore";
import { continueWritingCommandDefinition } from "./commandDefinitions/documentBody/continueWriting";
import { continueWritingFromTemplatedAiPromptCommandDefinition } from "./commandDefinitions/documentBody/continueWritingFromTemplatedAiPrompt";
import { deleteDocCommandDefinition } from "./commandDefinitions/document/deleteDoc";
import { deleteDocNoConfirmCommandDefinition } from "./commandDefinitions/document/deleteDocNoConfirm";
import { formatNodeAsQuestionCommandDefinition } from "./commandDefinitions/documentBody/formatNodeAsQuestion";
import { formatNodeAsSectionCommandDefinition } from "./commandDefinitions/documentBody/formatNodeAsSection";
import { formatNodeAsTextCommandDefinition } from "./commandDefinitions/documentBody/formatNodeAsText";
import { moveDocToParentCommandDefinition } from "./commandDefinitions/document/moveDocToParent";
import { navigateToCommandDefinition } from "./commandDefinitions/navigateTo";
import { showAddActionCommandDefinition } from "./commandDefinitions/action/showAddAction";
import { showAddDocModalCommandDefinition } from "./commandDefinitions/document/showAddDocModal"
import { showAddFrameworkToDocCommandDefinition } from "./commandDefinitions/document/showAddFrameworkToDoc"
import { showConfirmDialogCommandDefinition } from "./commandDefinitions/utils/showConfirmDialog";
import { showMiniCommandMenuCommandDefinition } from "./commandDefinitions/utils/showMiniCommandMenu";
import { showMoveDocToParentCommandDefinition } from "./commandDefinitions/document/showMoveDocToParent";
import { setDocAccessCommandDefinition } from "./commandDefinitions/document/setDocAccess";
import { setDocAccessShowOptionsCommandDefinition } from "./commandDefinitions/document/setDocAccessShowOptions";
import { addOpportunityShowOptionsCommandDefinition } from "./commandDefinitions/opportunity/addOpportunityShowOptions";
import { addDocShowOptionsCommandDefinition } from "./commandDefinitions/document/addDocShowOptions";
import { showMenuItemSearchCommandDefinition } from "./commandDefinitions/utils/showMenuItemSearch";
import { showCustomAIPromptCommandDefinition } from "./commandDefinitions/documentBody/showCustomAIPrompt";
import { runAIPromptOnDocCommandDefinition } from "./commandDefinitions/documentBody/runAIPromptOnDoc";
import { showMoreOptionsCommandDefinition } from "./commandDefinitions/utils/showMoreOptions";
import { setAssumptionRelevanceShowOptionsCommandDefinition } from "./commandDefinitions/assumption/setAssumptionRelevanceShowOptions";
import { setAssumptionRelevanceCommandDefinition } from "./commandDefinitions/assumption/setAssumptionRelevance";
import { setAssumptionConfidenceCommandDefinition } from "./commandDefinitions/assumption/setAssumptionConfidence";
import { setAssumptionConfidenceShowOptionsCommandDefinition } from "./commandDefinitions/assumption/setAssumptionConfidenceShowOptions";
import { showChatCommandDefinition } from "./commandDefinitions/showChat";
import { draftInterviewGuideCommandDefinition } from "./commandDefinitions/documentBody/draftInterviewGuide";
import { writeToDocBodyCommandDefinition } from "./commandDefinitions/documentBody/writeToDocBody";
import { draftResearchPlanCommandDefinition } from "./commandDefinitions/documentBody/draftResearchPlan";
import { autoDraftFullDocConfirmCommandDefinition } from "./commandDefinitions/documentBody/autoDraftFullDocConfirm";
import { navigateToAddAssumptionCommandDefinition } from "./commandDefinitions/navigateToAddAssumption";
import { autoDraftFullDocCommandDefinition } from "./commandDefinitions/documentBody/autoDraftFullDoc";
import { inlineDocSuggestionsGenerateCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/inlineDocSuggestionsGenerate";
import { docBodyMarkClearAllCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearAll";
import { docBodyMarkCreateCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkCreate";
import { docBodyMarkClearCurrentCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearCurrent";
import { updateOpportunityCommandDefinition } from "./commandDefinitions/opportunity/updateOpportunity";
import { replaceMarkWithTextCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/replaceMarkWithText";
import { loadingCommandPlaceholderCommandDefinition } from "./commandDefinitions/utils/loadingCommandPlaceholder";
import { writeToDocBodyAsTextCommandDefinition } from "./commandDefinitions/documentBody/writeToDocBodyAsText";
import { showCommandMenuCommandDefinition } from "./commandDefinitions/utils/showCommandMenu";
import { showDocEditorCommandMenuCommandDefinition } from "./commandDefinitions/utils/showDocEditorCommandMenu";
import { hideDocCommandMenuCommandDefinition } from "./commandDefinitions/utils/hideDocCommandMenu";
import { setDocFrameworkCommandDefinition } from "./commandDefinitions/framework/setDocFramework";
import { setDocFrameworkShowOptionsCommandDefinition } from "./commandDefinitions/framework/setDocFrameworkShowOptions";
import { setDocFrameworkNoneCommandDefinition } from "./commandDefinitions/framework/setDocFrameworkNone";
import { applyFrameworkToDocBodyCommandDefinition } from "./commandDefinitions/framework/applyFrameworkToDocBody";
import { writeToDocBodyDebuggerCommandDefinition } from "./commandDefinitions/documentBody/writeToDocBodyDebugger";
import { addPlaceholderCommandDefinition } from "./commandDefinitions/documentBody/placeholders/addPlaceholder";
import { getPlaceholderSuggestionsCommandDefinition } from "./commandDefinitions/documentBody/placeholders/loadCurrentPlaceholderSuggestions";
import { replacePlaceholderWithCommandDefinition } from "./commandDefinitions/documentBody/replacePlaceholderWith";
import { loadPlaceholderSuggestionsCommandDefinition } from "./commandDefinitions/documentBody/placeholders/loadPlaceholderSuggestions";
import { getSuggestionsForWhatIsNextCommandDefinition } from "./commandDefinitions/document/getSuggestionsForWhatIsNext";
import { addDocFromSelectionCommandDefinition } from "./commandDefinitions/document/addDocFromSelection";
import { addDocReferenceCommandDefinition } from "./commandDefinitions/documentBody/addDocReference";
import { reviewAsAudienceCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/reviewAsAudience";
import { reviewAsAudienceClearAllCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/reviewAsAudienceClearAll";
import { reviewAsAudienceShowOptionsCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/reviewAsAudienceShowOptions";
import { loadCurrentMarkSuggestionsCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/loadCurrentMarkSuggestions";
import { showDocInfoCommandMenuCommandDefinition } from "./commandDefinitions/utils/showDocInfoCommandMenu";
import { removePlaceholderCommandDefinition } from "./commandDefinitions/documentBody/placeholders/removePlaceholder";
import { aiContextShowOptionsCommandDefinition } from "./commandDefinitions/aiContext/aiContextShowOptions";
import { formatNodeAsTaskCheckboxCommandDefinition } from "./commandDefinitions/documentBody/formatNodeAsTaskCheckbox";
import { applyIdeaToDocCommandDefinition } from "./commandDefinitions/documentBody/applyIdeaToDoc";
import { findAndReplaceCommandDefinition } from "./commandDefinitions/documentBody/findAndReplace";
import { setFocusToCommandDefinition } from "./commandDefinitions/utils/setFocusTo";
import { getDocIdeasFromAiCoachCommandDefinition } from "./commandDefinitions/documentBody/inlineDocSuggestions/getDocIdeasFromAiCoach";
import { suggestDocFrameworkCommandDefinition } from "./commandDefinitions/framework/suggestDocFramework";
import { doNextAutoAiStepCommandDefinition } from "./commandDefinitions/autoAi/doNextAutoAiStep";
import { moveSelectionToCommandDefinition } from "./commandDefinitions/documentBody/moveSelectionTo";
import { startNewMindMapCommandDefinition } from "./commandDefinitions/mindMap/startNewMindMap";
import { setTagsShowOptionsCommandDefinition } from "./commandDefinitions/tags/setTagsShowOptions";
import { setTagsCommandDefinition } from "./commandDefinitions/tags/setTags";
import { addDocAutoHandleTypeCommandDefinition } from "./commandDefinitions/document/addDocAutoHandleType";
import { mindMapNodeSuggestionAcceptCommandDefinition } from "./commandDefinitions/mindMap/mindMapNodeSuggestionAccept";


/** List of all registered global commands */
export const AllGlobalCommands = [
  addDocAsActionCommandDefinition,
  addDocAutoHandleTypeCommandDefinition,
  addDocFromSelectionCommandDefinition,
  addDocReferenceCommandDefinition,
  addDocShowOptionsCommandDefinition,
  addDocWithFrameworkCommandDefinition,
  aiContextShowOptionsCommandDefinition,
  addOpportunityCommandDefinition,
  addOpportunityShowOptionsCommandDefinition,
  addPlaceholderCommandDefinition,
  addSectionWithAiCommandDefinition,
  addSectionWithAiShowMoreCommandDefinition,
  applyFrameworkToDocBodyCommandDefinition,
  applyIdeaToDocCommandDefinition,
  autoDraftFullDocCommandDefinition,
  autoDraftFullDocConfirmCommandDefinition,
  continueWritingCommandDefinition,
  continueWritingFromTemplatedAiPromptCommandDefinition,
  deleteDocCommandDefinition,
  deleteDocNoConfirmCommandDefinition,
  docBodyMarkClearAllCommandDefinition,
  docBodyMarkClearCurrentCommandDefinition,
  docBodyMarkCreateCommandDefinition,
  doNextAutoAiStepCommandDefinition,
  draftInterviewGuideCommandDefinition,
  draftResearchPlanCommandDefinition,
  fillInSectionCommandDefinition,
  findAndReplaceCommandDefinition,
  formatNodeAsQuestionCommandDefinition,
  formatNodeAsSectionCommandDefinition,
  formatNodeAsTaskCheckboxCommandDefinition,
  formatNodeAsTextCommandDefinition,
  getDocIdeasFromAiCoachCommandDefinition,
  getPlaceholderSuggestionsCommandDefinition,
  getSuggestionsForWhatIsNextCommandDefinition,
  hideDocCommandMenuCommandDefinition,
  inlineDocSuggestionsGenerateCommandDefinition,
  loadPlaceholderSuggestionsCommandDefinition,
  loadCurrentMarkSuggestionsCommandDefinition,
  loadingCommandPlaceholderCommandDefinition,
  mindMapNodeSuggestionAcceptCommandDefinition,
  moveDocToParentCommandDefinition,
  moveSelectionToCommandDefinition,
  navigateToCommandDefinition,
  navigateToAddAssumptionCommandDefinition,
  removePlaceholderCommandDefinition,
  replaceMarkWithTextCommandDefinition,
  replacePlaceholderWithCommandDefinition,
  reviewAsAudienceCommandDefinition,
  reviewAsAudienceClearAllCommandDefinition,
  reviewAsAudienceShowOptionsCommandDefinition,
  runAIPromptOnDocCommandDefinition,
  setAssumptionConfidenceCommandDefinition,
  setAssumptionConfidenceShowOptionsCommandDefinition,
  setAssumptionRelevanceCommandDefinition,
  setAssumptionRelevanceShowOptionsCommandDefinition,
  setDocAccessCommandDefinition,
  setDocAccessShowOptionsCommandDefinition,
  setDocFrameworkCommandDefinition,
  setDocFrameworkNoneCommandDefinition,
  setDocFrameworkShowOptionsCommandDefinition,
  setFocusToCommandDefinition,
  setTagsCommandDefinition,
  setTagsShowOptionsCommandDefinition,
  showAddActionCommandDefinition,
  showAddDocModalCommandDefinition,
  showAddFrameworkToDocCommandDefinition,
  showChatCommandDefinition,
  showCommandMenuCommandDefinition,
  showConfirmDialogCommandDefinition,
  showCustomAIPromptCommandDefinition,
  showDocEditorCommandMenuCommandDefinition,
  showDocInfoCommandMenuCommandDefinition,
  showMenuItemSearchCommandDefinition,
  showMiniCommandMenuCommandDefinition,
  showMoreOptionsCommandDefinition,
  showMoveDocToParentCommandDefinition,
  startNewMindMapCommandDefinition,
  suggestDocFrameworkCommandDefinition,
  updateOpportunityCommandDefinition,
  writeToDocBodyCommandDefinition,
  writeToDocBodyAsTextCommandDefinition,
  writeToDocBodyDebuggerCommandDefinition,
];
