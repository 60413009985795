/**
 * @generated SignedSource<<70a209685e9600bd2d98f509dc28bbd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentOriginBanner_document$data = {
  readonly origin: {
    readonly id: string;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
  readonly parentDoc: {
    readonly id: string;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
  readonly " $fragmentType": "DocumentOriginBanner_document";
};
export type DocumentOriginBanner_document$key = {
  readonly " $data"?: DocumentOriginBanner_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentOriginBanner_document">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentOriginBanner_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "origin",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "parentDoc",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "df7c47e689b0db77a992b04cb77b305f";

export default node;
