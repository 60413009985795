import { AiSuggestions, ApiTypes } from "@verdi/shared-constants"
import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsDocBodyMarkCreateCommandArgs } from "../../utils/cleanupAiOutput"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"


/** Generates multiple AI driven suggestions anywhere in the document body */
export const makeInlineDocSuggestionsAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  const allContext: AllContextForAi = await getContextForAi({
    includeMessages: {
      document: false,
    }
  })

  const promptContext = getInlineDocSuggestionsPromptContext(allContext.currentDoc)
  const messageWithContext: ApiTypes.RunAiChatRequestMessage = {
    role: ApiTypes.ChatRoleType.system,
    content: promptContext,
  }

  const promptWithInstructions = getInlineDocSuggestionsPrompt(allContext.currentDoc)
  console.log("makeInlineDocSuggestionsAiCall", { prompt: promptWithInstructions })

  const response = await makeAiChatRequest({
    messages: [
      ...allContext.promptMessages,
      messageWithContext,
    ],
    prompt: promptWithInstructions,
  })

  console.log("makeInlineDocSuggestionsAiCall", { response })

  const rawText = response?.aiResponseJson.content as string || ""
  const commandArgs = cleanupAiOutputAsDocBodyMarkCreateCommandArgs(rawText)
  return commandArgs
}



/** Sent as a separate chat message to the AI, to avoid the AI suggesting things that are NOT found in the document body */
export const getInlineDocSuggestionsPromptContext = (
  docContext?: DocumentContextForAi,
) => {

  const prompt = `
${docContext?.title ? `\nCURRENT DOCUMENT TITLE: ${docContext?.title}` : ""}
`
  return prompt
}

export const getInlineDocSuggestionsPrompt = (
  docContext?: DocumentContextForAi,
) => {

  const commands = AiSuggestions.AISuggestionCommandOptions
    .filter((cmd) => cmd.id === "replace") // For now, only allow "replace" suggestions
    .map((cmd) => {
      return `{id: "${cmd.id}", description: "${cmd.description}" }`
    })

  const prompt = `
### BEGIN CONTEXT

START DOCUMENT BODY: 

${docContext?.bodyPartsAsMarkdown?.allLines || ""}

END DOCUMENT BODY

AVAILABLE COMMANDS:
${commands.join(",\n")}

### END CONTEXT

INSTRUCTIONS: 
Given the DOCUMENT BODY, return up to 10 places in the document that are overly vague, or can be majorly improved with more detail.
Take your time. Make sure to look at the whole document to ensure the text you are saying is vague isn't ellaborated on elsewhere in the document.
For each place in the text where you want to make improvements, write a concise statement (or few words) that adds rich and exact detail like metrics, names of competitors, timelines, feature names, etc.
The replacement text should add considerable detail to the document. Do not just rewrite what is already in the document.
Highlight specific text and provide a command to run to make the suggested change.


RESPONSE INSTRUCTIONS:
On separate lines, provide the name of the command to run, the text to highlight, and the concise, rich text to replace the highlighted text with. 
RESPONSE FORMAT EXAMPLE:
commandName|the text to highlight|the exact improved text to replace the highlighted text with

EXAMPLE 1:
IF the provided DOCUMENT BODY contained: "Run a series of user interviews for us to be learning."
THEN the response could be: 
gaps|user interviews|what questions will you ask?
gaps|Run a series|Run a series of 10 interviews with product managers
gaps|for us to be learning|to learn details about the pain they feel when drafting documents.

EXAMPLE 2:
IF the provided DOCUMENT BODY contained: "Increase the efficiency of all the things in the app."
THEN the response could be: 
gaps|increase the efficiency|Increase the efficiency by 30%
gaps|all things in the app|of the AI suggestions by changing to lower-cost, faster models.
`

  return prompt
}

