import { useCallback } from "react"
import { MindMapState } from "../../../../../state/MindMapSlice"
import { dispatch, getCurrentAppState } from "../../../../../state/store"
import { getNodeSummaryForAi, getSnapshotForAi } from "../../getSnapshotForAi"
import { NewNodeConfig } from "../../nodeTypes/addNew/NewNodeMenuList"
import { IdeaNodeData, IdeaNodeWrapperData } from "../../nodeTypes/idea/IdeaNode"
import { GenerateChildrenOptions } from "../useRegisterMindMapContext"
import { Edge, Node, ReactFlowJsonObject } from "@xyflow/react"
import { AiContextConfig, AllContextForAi } from "../../../../../ai/promptUtils/useAllContextForAi"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { getSuggestedNodesInGraphAiCall } from "../../../../../ai/prompts/opportunity/getSuggestedNodesInGraphAiCall"
import { addDocAutoHandleType } from "../../../../commands/commandDefinitions/document/addDocAutoHandleType"
import { DocumentSchema } from "@verdi/shared-constants"
import { TagType, TagsState } from "../../../../../state/TagsSlice"
import { WrappedGraphNode } from "../../nodeTypes/NodeTypes"
import { generateNodeId } from "../../utils/utils"
import { tagTitleToDocType } from "../../utils/TagTitleToDocType"


type Props = {
  toObject: () => ReactFlowJsonObject<Node, Edge>,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  onGenerateChildrenComplete: (results: IdeaNodeData[], parentNodeId: string) => void
  appServices: AppServices
}

/** Hook that handles creating child nodes for a MindMap graph */
export const useCreateChildNodes = ({
  appServices,
  getContextForAi,
  onGenerateChildrenComplete,
  toObject,
}: Props) => {


  const askAiForChildNodeSuggestions = useCallback(async (parentNode: Node, tagType?: TagType): Promise<IdeaNodeData[]> => {

    dispatch(MindMapState.setStatus({ isLoading: true, messageToUser: "Generating ..." }))
    const snapshot = getSnapshotForAi(toObject())
    const targetNode = getNodeSummaryForAi(parentNode)
    const results = await getSuggestedNodesInGraphAiCall(
      getContextForAi,
      snapshot,
      targetNode,
      tagType ? [tagType] : [],
    )

    const toReturn: IdeaNodeData[] = results.map((r) => {
      const tag = TagsState.getTagByTitle(getCurrentAppState(), r.key)
      return {
        text: r.value,
        tags: tag ? [tag] : undefined,
        docType: tagTitleToDocType(r.key),
      }
    })
    dispatch(MindMapState.setStatus({ isLoading: false }))

    return toReturn
  }, [getContextForAi, toObject])


  const createChildNodes = useCallback(async (
    newNodeConfig: NewNodeConfig,
    options: GenerateChildrenOptions
  ) => {

    let results: IdeaNodeData[] = []
    const { parentNode, docType } = newNodeConfig
    const { tags } = options

    if (options.shouldGetFromAi) {
      results = await askAiForChildNodeSuggestions(parentNode)

    } else {

      results = [{
        text: newNodeConfig.docTitle || "",
        placeholderText: getPlaceholderText(docType),
        tags,
        docType,
      }]
    }


    if (newNodeConfig.shouldCreateNewDoc) {
      // Create a new doc for each new node
      const promises: Promise<IdeaNodeData>[] = []
      const parentDocId = parentNode.data.docId as string || undefined
      if (!parentDocId) {
        console.error("generateChildren: Parent docId not specified", { parentNode })
        return
      }

      for (const newIdeaNode of results) {
        promises.push(new Promise((resolve, reject) => {
          addDocAutoHandleType({
            newDocType: newIdeaNode.docType || DocumentSchema.DocumentType.document,
            newDocTitle: newIdeaNode.text,
            docRelation: {
              toDocId: parentDocId,
            },
            tagsToAdd: newIdeaNode.tags,
            onStatusUpdate: (isLoading, messageToUser) => {
              dispatch(MindMapState.setStatus({ isLoading, messageToUser }))
            },
            onCreated: (docId: string) => {
              resolve({ ...newIdeaNode, docId })
            },
            parentDocId,
          }, appServices)

        }))

        Promise.allSettled(promises).then((promiseResults) => {
          const updatedIdeaNodes = promiseResults.filter(r => r.status === "fulfilled").map(r => (r as any).value) as IdeaNodeData[]
          onGenerateChildrenComplete(updatedIdeaNodes, parentNode.id)
        })
      }

    } else {
      onGenerateChildrenComplete(results, parentNode.id)
    }


  }, [onGenerateChildrenComplete, appServices, askAiForChildNodeSuggestions])


  return {
    /** Creates child nodes and optionally syncs them with the document graph */
    createChildNodes,
    askAiForChildNodeSuggestions,
  }

}



const getPlaceholderText = (docType: DocumentSchema.DocumentType) => {

  switch (docType) {
    case DocumentSchema.DocumentType.assumption:
      return "We are assuming that ..."
    case DocumentSchema.DocumentType.opportunity:
      return "Describe the opportunity ..."
    default:
      return "type something here ..."
  }
}


export const buildChildNodeAndEdge = (parentId: string, data: IdeaNodeData) => {

  const id = generateNodeId()
  const node: WrappedGraphNode = {
    id,
    type: "idea",
    data,
    position: { x: 0, y: 0 },
    selected: false,
  } as IdeaNodeWrapperData

  const edge = {
    id: `${parentId}-${id}`,
    target: id,
    source: parentId,
  }

  return { node, edge }
}
