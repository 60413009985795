/**
 * @generated SignedSource<<ae230012ef1577900a5f4ff598c7c93b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
export type MenuBuilderAddQuery$variables = {};
export type MenuBuilderAddQuery$data = {
  readonly documentTemplateConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly document: {
          readonly description: string | null;
          readonly id: string;
          readonly title: string | null;
          readonly type: DocumentType;
        };
        readonly id: string;
        readonly isPublished: boolean;
        readonly title: string | null;
      };
    }>;
  };
};
export type MenuBuilderAddQuery = {
  response: MenuBuilderAddQuery$data;
  variables: MenuBuilderAddQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 100
      }
    ],
    "concreteType": "QueryDocumentTemplateConnection",
    "kind": "LinkedField",
    "name": "documentTemplateConnection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QueryDocumentTemplateConnectionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentTemplateInfo",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPublished",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "documentTemplateConnection(first:100)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MenuBuilderAddQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MenuBuilderAddQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b6c5ab7f6c30180c090bd4d1eecb1bf0",
    "id": null,
    "metadata": {},
    "name": "MenuBuilderAddQuery",
    "operationKind": "query",
    "text": "query MenuBuilderAddQuery {\n  documentTemplateConnection(first: 100) {\n    edges {\n      node {\n        id\n        title\n        document {\n          id\n          title\n          description\n          type\n        }\n        isPublished\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "99d8a37144bfac0e49112aba28f1094e";

export default node;
