import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { CloseMenuOptions } from "../../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"


const cmdType = GlobalCommandType.hideDocCommandMenu

/** Hides the doc command menu */
export const hideDocCommandMenuCommandDefinition: GlobalCommandDefinition<HideDocCommandMenuCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: HideDocCommandMenuCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Hide the command menu',
        searchName: 'hide the command menu',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: HideDocCommandMenuCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("hideDocCommandMenuCommandDefinition.processCommand()", { args })

    const cmWasClosed = services.servicesForCurrentDoc?.hideDocEditorCommandMenuIfAvailable(args.options) || false

    onProcessingComplete(cmWasClosed)
  },
  triggerCommand: (args?: HideDocCommandMenuCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type HideDocCommandMenuCommandArgs = {
  options?: CloseMenuOptions,
}


