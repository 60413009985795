import { ApiTypes, ChatRoleType } from "@verdi/shared-constants"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItemsShortestFirst } from "../../utils/cleanupAiOutput"


/** Generates title suggestions for a document, based on its description */
export const generateTitleForDocument = async (documentDescription: string, numberOfVariations: number = 2) => {

  const prompt = `Generate a very short and concise title for this document: 
${documentDescription}`

  const message = { role: ChatRoleType.system, content: prompt }
  const results = await makeAiChatRequest({
    messages: [message],
    openAIParams: {
      temperature: 0.9,
      n: numberOfVariations,
    },
  })

  if (!results) {
    return []
  }
  const resultsJson = results.aiResponseJson as ApiTypes.AiResponseJson
  const rawResults = resultsJson.contentOptions || []
  return cleanupAiOutputItemsShortestFirst(rawResults)
}


