import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { ReactNode } from "react"

type Props = {
  buttonContent: ReactNode
  children: ReactNode
  setIsExpanded?: (isExpanded: boolean) => void
  isExpanded?: boolean
  count?: number
}
export const AccordionWrapper = ({
  buttonContent,
  children,
  setIsExpanded,
  isExpanded,
  count,
}: Props) => {


  return (
    <Accordion
      allowToggle
      borderColor="transparent"
      defaultIndex={0}
      index={isExpanded === undefined ? undefined : isExpanded ? 0 : -1}
      onChange={(index) => setIsExpanded?.(index === 0)}
    >
      <AccordionItem>
        <AccordionButton css={css`
          display: flex;
          justify-content: space-between;
        `}>
          <div css={css`
            display: flex;
            align-items: center;
          `}>
            {buttonContent}
            {count &&
              <small css={css`
            opacity:0.3;
            margin-left: 4px;
            `}>
                {count}
              </small>
            }
          </div>
          <AccordionIcon opacity={0.5} />
        </AccordionButton>
        <AccordionPanel
          margin={0}
          padding={3}
          borderLeft="1px solid #99999922"
          _selected={{ border: "1px solid red" }}
        >
          <div>
            {children}
          </div>
        </AccordionPanel>
      </AccordionItem>

    </Accordion>
  )
}
