import { Data, DocumentSchema, KeyValuePair } from "@verdi/shared-constants"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DeRiskingStepOption } from "../../../../screens/deRiskingSteps/DeRiskingStepOptions"
import { getInitialDocBodyStepFromConcepts } from "../../../../ai/docDrafter/concepts/getInitialDocBodyStepFromConcepts"


const cmdType = GlobalCommandType.addDocAsAction


export const addDocAsActionCommandDefinition: GlobalCommandDefinition<AddDocAsActionCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddDocAsActionCommandArgs) => {

    const { prefix, action } = args
    const { title, description, } = action

    const toReturn =
      {
        id: args.id,
        globalCommandType: cmdType,
        name: (prefix ? prefix + " " : "") + title,
        tooltip: description,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        searchName: prefix ? `add action ${title.toLocaleLowerCase()}` : title.toLocaleLowerCase(),
        iconType: args.isSuggestionFromAi ? "aiSuggestion" : undefined
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: AddDocAsActionCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { currentDocId, conceptsToUse, action, onCompleted } = args
    if (!currentDocId) {
      console.error("Could not find currentDocId")
      return
    }
    const {
      documentRelationsProvider,
    } = services

    const initialStep = getInitialDocBodyStepFromConcepts(conceptsToUse)
    const initialStepJson = JSON.stringify(initialStep)

    documentRelationsProvider.createNewDocWithRelation({
      currentDocId: currentDocId,
      direction: "currentDocIsTo",
      newDocType: detectDocTypeFromTitle(action.title),
      newDocTitle: action?.title || "",
      newDocDescription: action?.description,
      parentDocId: currentDocId,
      relationType: Data.DocumentRelationType.actionOf,
      initialStepJson,
      onError: (error) => {
        console.log("Error creating action doc", error)
      },
      onComplete: (newDocId) => {
        console.log("Created action doc", newDocId)
        args.onCreated?.(newDocId)
        onProcessingComplete(Boolean(newDocId))
        // addConceptsToDocument(newDocId, conceptsToUse, () => {
        //   setIsCreatingEverything(false)
        //   onCreated(newDocId)
        // })
      }
    })

    onCompleted?.()
    onProcessingComplete(true)
  },
}

export const addDocAsActionPrefix = "Add Action:"

export type AddDocAsActionCommandArgs = {
  currentDocId: string
  action: DeRiskingStepOption
  conceptsToUse: KeyValuePair[]
  onCreated?: (newDocId: string) => void,
  prefix?: string,
  id: string
  onCompleted: () => void
  isSuggestionFromAi?: boolean
}


const detectDocTypeFromTitle = (title: string) => {
  const lowerCaseTitle = title.toLowerCase()
  if (lowerCaseTitle.includes("interview")) {
    return DocumentSchema.DocumentType.interviewGuide
  }
  return DocumentSchema.DocumentType.research
}
