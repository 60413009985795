import { useContext } from "react"
import { MindMapContext } from "./MindMapContext"


export const useMindMapContext = () => {

  const context = useContext(MindMapContext)
  if (!context) {
    throw new Error("MindMapContext is undefined")
  }
  return context
}
