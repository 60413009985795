import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { noneMenuItem } from "../../miniCommandMenu/NoneMenuItem";
import { documentFrameworkState } from "../../../../state/documentFrameworksSlice";
import { getCurrentAppState } from "../../../../state/store";
import { DocumentFrameworksProvider } from "../../../../screens/documentFrameworks/DocumentFrameworksProvider";


const cmdType = GlobalCommandType.setDocFrameworkNone


/** Removes any framework connected to the specified document */
export const setDocFrameworkNoneCommandDefinition: GlobalCommandDefinition<SetDocFrameworkNoneArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetDocFrameworkNoneArgs) => {

    const toReturn = {
      ...noneMenuItem,
      globalCommandType: cmdType,
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
      iconType: args.isCurrentValue ? "currentValue" : undefined,
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetDocFrameworkNoneArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    if (!DocumentFrameworksProvider) {
      console.warn("setDocFrameworkNone: No documentFrameworksProvider")
      onProcessingComplete(false)
      return
    }

    const docFrameworks = documentFrameworkState.getDocumentFrameworksForDoc(getCurrentAppState(), args.documentId)
    for (const df of docFrameworks) {
      await DocumentFrameworksProvider.removeDocumentFramework(df.id)
    }

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: SetDocFrameworkNoneArgs,
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetDocFrameworkNoneArgs = {
  documentId: string
  isCurrentValue: boolean
}
