import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.formatNodeAsQuestion

export const formatNodeAsQuestionCommandDefinition: GlobalCommandDefinition<FormatNodeAsQuestionCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: FormatNodeAsQuestionCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Question',
        searchName: 'question',
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL - 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: FormatNodeAsQuestionCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('formatNodeAsQuestion: No editor found in servicesForCurrentDoc')
      return
    }

    editor
      .chain()
      .deleteToLastSlash()
      .convertNodeToType('question')
      .addEmptyChildIfThereAreNone()
      .run();

    onProcessingComplete(true)
  },
}

type FormatNodeAsQuestionCommandArgs = {
}
