import { useEffect, useMemo, useRef } from "react"
import { useComboBoxController } from "../useComboBoxController"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { showMoreOptionsCommandDefinition } from "../../../commandDefinitions/utils/showMoreOptions"
import { ComboBox } from "../ComboBox"
import { addOpportunityShowOptionsCommandDefinition } from "../../../commandDefinitions/opportunity/addOpportunityShowOptions"
import { addDocShowOptionsCommandDefinition } from "../../../commandDefinitions/document/addDocShowOptions"


/** Example of how to use the "ComboBox" component, viewable on the Styles page */
export const ComboBoxExample = () => {


  const comboBoxRef: React.RefObject<HTMLDivElement> = useRef(null)
  const comboBoxState = useComboBoxController({
    onClose: () => { console.log("did close") },
    onRunCommand: (command) => { console.log("Should now run this command", command) },
    shouldSelectOnItemHover: true,
    mode: "search-and-filter",
  })


  const commands: VerdiCommand[] = useMemo(() => {
    return [
      showMoreOptionsCommandDefinition.buildMenuItem({
        commands: [],
      }),
      addOpportunityShowOptionsCommandDefinition.buildMenuItem({}),
      addDocShowOptionsCommandDefinition.buildMenuItem({
        parentDocId: "asdf",
      }),
    ]
  }, [])

  useEffect(() => {
    comboBoxState.setCommandsInGroups([{
      title: "Some group title here",
      commands
    }, {
      title: "Another Group here",
      commands: [...commands].reverse()
    },
    {
      title: "Hidden title",
      hideTitle: true,
      commands,
    }, {
      title: "More group",
      commands: [...commands].reverse()
    },
    ])
    // eslint-disable-next-line
  }, [])


  return (
    <div ref={comboBoxRef}>
      <ComboBox
        comboBoxState={comboBoxState}
      />
    </div>
  )
}
