import { Kbd } from "@chakra-ui/react"
import { css } from "@emotion/react"


type Props = {
  title: string
  icon?: React.ReactNode
  commandTypeText?: string
  shortcutKeys?: string[]
  description?: string
  disabled?: boolean
  isLoading?: boolean
  onMouseEnter?: () => void
  shouldTruncate?: boolean
}
/** Keeps all command menu items displaying consistently by using this.
 * 
 * regardless of if it is in a ComboBox vs cmdK, etc. 
 *  */
export const CommandMenuSectionItemContent = ({
  title,
  icon,
  commandTypeText,
  shortcutKeys,
  description,
  disabled,
  isLoading,
  onMouseEnter,
  shouldTruncate,
}: Props) => {


  const tooltipText = description || shouldTruncate ? title : undefined


  return (
    <span
      onMouseEnter={onMouseEnter}
      title={tooltipText}
      css={css`
        width: 100%;
      `}
    >

      <span
        css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${isLoading ? css`
          animation: loadingPulse 3s infinite;
        ` : ""}
       `}
      >

        {commandTypeText &&
          <span css={css`
          font-size: 0.8em;
          opacity: 0.7;
          margin-right: 4px;
          `}>
            {commandTypeText}
          </span>
        }


        <span css={css`
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;
          `}>
          {Boolean(icon) && icon}
        </span>


        <span css={css`
          width: calc(100% - 20px);
          ${shouldTruncate ? css`
            max-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            `: ""
          }
        `}>
          {title}
        </span>

        &nbsp;
        <span
          css={css`
          opacity: 0.7;
          font-size: small;
        `}>
          {disabled && ' (Unavailable)'}
        </span>
      </span>

      {shortcutKeys?.length &&
        <span css={css`
        margin: -4px -4px 0 0;
        `}>
          {shortcutKeys.map((k, i) =>
            <span key={i}>
              <Kbd>{k}</Kbd>
              {i < shortcutKeys.length - 1 && ' + '}
            </span>
          )}
        </span>
      }

    </span >
  )

}
