import { ContentNodeWithPos } from "prosemirror-utils"


export const getRangeForInsertingChildren = (
  currentNode: ContentNodeWithPos,
  rangeMode: "replaceAllChildren" | "insertAsFirstChild" | "insertAsLastChild") => {

  const titleNode = currentNode.node.child(0)
  const contentListPosition = currentNode.pos + titleNode.nodeSize + 1
  const contentListSize = currentNode.node.maybeChild(1)?.nodeSize || 0

  switch (rangeMode) {
    case "replaceAllChildren":
      return {
        from: contentListPosition,
        to: contentListPosition + contentListSize,
      }

    case "insertAsFirstChild":
      const firstChildPos = contentListPosition + 1
      return {
        from: firstChildPos,
        to: firstChildPos,
      }

    case "insertAsLastChild": // TODO: When children are empty, this breaks!
      const lastChildPos = contentListPosition + contentListSize - 1
      return {
        from: lastChildPos,
        to: lastChildPos,
      }
  }
}
