/**
 * @generated SignedSource<<0c8713634dd466f493be84f14516d738>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentSettingsEditor_document$data = {
  readonly createdByUser: {
    readonly email: string;
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly description: string | null;
  readonly id: string;
  readonly isVisibleToOrg: boolean;
  readonly opportunities: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly isVisibleToOrg: boolean;
    readonly mainDoc: {
      readonly id: string;
    } | null;
    readonly title: string | null;
  }>;
  readonly title: string | null;
  readonly type: DocumentType;
  readonly " $fragmentType": "DocumentSettingsEditor_document";
};
export type DocumentSettingsEditor_document$key = {
  readonly " $data"?: DocumentSettingsEditor_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentSettingsEditor_document">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVisibleToOrg",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentSettingsEditor_document",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdByUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Opportunity",
      "kind": "LinkedField",
      "name": "opportunities",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "mainDoc",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "8839d980f41481335c82ae97fa8eb74c";

export default node;
