import { useDisclosure } from "@chakra-ui/react"
import { totalOnboardingSteps } from "./OnboardingStep"
import { useCallback, useEffect, useState } from "react"
import { makeGetRequestJson, makePostRequestJson } from "../../utility-hooks/fetchUtils"
import { Data } from "@verdi/shared-constants"
import { OnboardingStatusStateForUser, setOnboardingStatusForUser, updateOnboardingProgressForUser } from "../../state/onboardingStatusSlice"
import { dispatch } from "../../state/store"


/** Controls if user should be redirected to onboarding when logged in via google */
export const userWasShownOnboardingKey = "userWasShownOnboarding"

export const useOnboardingManager = () => {

  const onboardingDisclosure = useDisclosure() // Controls the onboarding modal


  // Steps
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const gotoNextStep = useCallback(() => {
    if (currentStepIndex < totalOnboardingSteps) {
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }, [currentStepIndex])
  const gotoPreviousStep = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }, [currentStepIndex])



  const [isOnboarding, setIsOnboarding] = useState(false)

  const setLocallyThatUserWasShownOnboarding = useCallback(() => {
    localStorage.setItem(userWasShownOnboardingKey, "true")
  }, [])

  const showOnboardingModal = useCallback(() => {
    localStorage.setItem(userWasShownOnboardingKey, "true")
    onboardingDisclosure.onOpen()
  }, [onboardingDisclosure])

  useEffect(() => {
    makeGetRequestJson("onboardingProgress")
      .then((response: Data.Onboarding.OnboardingProgressMutableFields) => {
        if (response) {

          const onboardingStatus: OnboardingStatusStateForUser = {
            hasLoaded: true,
            ...response,
          }
          dispatch(setOnboardingStatusForUser(onboardingStatus))
        }
        const hadAlreadyStartedOnboarding = Boolean(localStorage.getItem(userWasShownOnboardingKey))
        if (hadAlreadyStartedOnboarding) {
          return
        }

        if (!response || Object.keys(response).length === 0) {
          showOnboardingModal()
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const startOnboardingFlow = useCallback(() => {
    //TODO: Determine which step to start on
    /** TODO: When we are ready to release, let's invert this logic
     *  Store a "hasOnboarded" flag in local storage, to cover all new and existing users
     *  */
    localStorage.setItem("isOnboarding", "true")
    setIsOnboarding(true)
  }, [setIsOnboarding])

  const stopOnboardingFlow = useCallback(() => {
    localStorage.removeItem("isOnboarding")
    setIsOnboarding(false)
    onboardingDisclosure.onClose()
  }, [onboardingDisclosure, setIsOnboarding])

  const detectOnboardingState = useCallback(() => {
    const isOnboarding = Boolean(localStorage.getItem("isOnboarding"))
    setIsOnboarding(isOnboarding)
    return isOnboarding
  }, [setIsOnboarding])


  const updateProgress = useCallback(async (type: keyof Data.Onboarding.OnboardingProgressMutableFields) => {
    dispatch(updateOnboardingProgressForUser(type))
    try {
      await makePostRequestJson("onboardingProgressUpdate", { [type]: new Date() })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const resetSteps = useCallback(() => {
    setCurrentStepIndex(0)
  }, [])


  return {
    startOnboardingFlow,
    stopOnboardingFlow,
    detectOnboardingState,
    updateProgress,
    isOnboarding,
    showOnboardingModal,
    onboardingDisclosure,
    gotoNextStep,
    gotoPreviousStep,
    resetSteps,
    currentStepIndex,
    setLocallyThatUserWasShownOnboarding,
  }

}

export type OnboardingManager = ReturnType<typeof useOnboardingManager>
