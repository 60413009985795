
/** IMPORTANT: These are tied directly to document data, avoid renaming them 
 * unless you can also update ALL the document data in redis and the db. */
export type AISuggestionCommandType =
  "gap" |
  "longer" |
  "shorter" |
  "replace"

export type AISuggestionCommandOption = {
  id: AISuggestionCommandType;
  description: string;
}
export const AISuggestionCommandOptions: AISuggestionCommandOption[] = [
  { id: "gap", description: "highlights text of statements that are ambiguous or need more information." },
  { id: "longer", description: "Replaces the text of a statement that is too short and that can be expanded." },
  { id: "shorter", description: "Replaces the text of a statement that is too long and that can be shortened, with a shorter version." },
  { id: "replace", description: "Replaces the text of a statement that can be better phrased or improved, with a better option, so that meaning is clearer to the reader." },
]
