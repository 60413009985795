import { DocumentOutlineEntry } from "../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"
import { cleanupAiOutputItem, removeCheckboxChars } from "./cleanupAiOutput"


/** Converts a raw markdown string to an array of DocumentOutlineEntry
 * 
 * Assumes the `rawString` arg is in a VERY LIMITED markdown format */
export const markdownToDocOutline = (rawString: string) => {
  const lines = rawString.split("\n") ?? []
  const toReturn: DocumentOutlineEntry[] = []
  let currentSection: DocumentOutlineEntry | undefined
  for (const line of lines) {
    const trimmedLine = line.trim()

    if (trimmedLine.length === 0) {
      currentSection = undefined
      continue
    }

    // Handle sections (with potential of nested children)
    if (trimmedLine.startsWith("#") || trimmedLine.endsWith(":")) {
      currentSection = {
        content: cleanupAiOutputItem(trimmedLine),
        type: "heading",
        children: [],
      }
      toReturn.push(currentSection)

    } else {

      const isCheckbox = checkIfIsCheckbox(trimmedLine)
      let nodeToAdd: DocumentOutlineEntry

      if (isCheckbox) {
        nodeToAdd = {
          content: removeCheckboxChars(cleanupAiOutputItem(trimmedLine)),
          type: "checkbox",
        }
      } else {
        nodeToAdd = {
          content: cleanupAiOutputItem(trimmedLine),
          type: "text",
        }
      }

      if (currentSection) { // Add as nested child
        currentSection.children?.push(nodeToAdd)
      } else {
        toReturn.push(nodeToAdd)
      }

    }
  }

  return toReturn
}

const checkIfIsCheckbox = (line: string) => {
  return line.startsWith("[ ]")
    || line.startsWith("[x]")
    || line.startsWith("[X]")
}
