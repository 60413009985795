/**
 * @generated SignedSource<<9ac9544c9b7a2d27d55d4a59ed4c2d20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BlockType = "answer" | "freeText" | "question" | "section" | "%future added value";
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
export type QuestionType = "freeResponse" | "multipleChoice" | "numeric" | "picture" | "%future added value";
export type UpdateDocumentInput = {
  blocks?: ReadonlyArray<RecursiveBlockInput> | null;
  description?: string | null;
  documentId: string;
  isVisibleToOrg?: boolean | null;
  parentDocId?: string | null;
  title?: string | null;
  type?: DocumentType | null;
};
export type RecursiveBlockInput = {
  children?: ReadonlyArray<RecursiveBlockInput> | null;
  id?: string | null;
  level: number;
  order: number;
  questionType?: QuestionType | null;
  text: string;
  transcriptTimestamp?: string | null;
  type: BlockType;
};
export type useUpdateDocumentMutation$variables = {
  input: UpdateDocumentInput;
};
export type useUpdateDocumentMutation$data = {
  readonly updateDocument: {
    readonly description: string | null;
    readonly id: string;
    readonly isVisibleToOrg: boolean;
    readonly parentDoc: {
      readonly id: string;
    } | null;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
};
export type useUpdateDocumentMutation$rawResponse = {
  readonly updateDocument: {
    readonly description: string | null;
    readonly id: string;
    readonly isVisibleToOrg: boolean;
    readonly parentDoc: {
      readonly id: string;
    } | null;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
};
export type useUpdateDocumentMutation = {
  rawResponse: useUpdateDocumentMutation$rawResponse;
  response: useUpdateDocumentMutation$data;
  variables: useUpdateDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "updateDocument",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVisibleToOrg",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "parentDoc",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateDocumentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateDocumentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a5fc7fbd8ac0c62e6b526736a976b8a6",
    "id": null,
    "metadata": {},
    "name": "useUpdateDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateDocumentMutation(\n  $input: UpdateDocumentInput!\n) {\n  updateDocument(input: $input) {\n    id\n    title\n    description\n    isVisibleToOrg\n    type\n    parentDoc {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9cd1044d5763ec3ce5ef9b76b59f3b6c";

export default node;
