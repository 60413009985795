import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails"
import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { addDocShowOptionsCommandDefinition } from "../../commandDefinitions/document/addDocShowOptions"
import { autoDraftFullDocConfirmCommandDefinition } from "../../commandDefinitions/documentBody/autoDraftFullDocConfirm"
import { deleteDocCommandDefinition } from "../../commandDefinitions/document/deleteDoc"
import { docBodyMarkClearAllCommandDefinition } from "../../commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearAll"
import { inlineDocSuggestionsGenerateCommandDefinition } from "../../commandDefinitions/documentBody/inlineDocSuggestions/inlineDocSuggestionsGenerate"
import { setDocAccessShowOptionsCommandDefinition } from "../../commandDefinitions/document/setDocAccessShowOptions"
import { showAddActionCommandDefinition } from "../../commandDefinitions/action/showAddAction"
import { showCustomAIPromptCommandDefinition } from "../../commandDefinitions/documentBody/showCustomAIPrompt"
import { showMoveDocToParentCommandDefinition } from "../../commandDefinitions/document/showMoveDocToParent"
import { setDocFrameworkShowOptionsCommandDefinition } from "../../commandDefinitions/framework/setDocFrameworkShowOptions"
import { DocumentSchema } from "@verdi/shared-constants"
import { reviewAsAudienceShowOptionsCommandDefinition } from "../../commandDefinitions/documentBody/inlineDocSuggestions/reviewAsAudienceShowOptions"


/** Returns groups of commands for a document */
export const getCmdsForDoc = (document: BasicDocumentDetails): VerdiCommandGroup[] => {

  if (!document.id) return []
  const groups: VerdiCommandGroup[] = []

  const reviewAndEditGroup = getGroupForReviewAndEdit(document)
  if (reviewAndEditGroup) {
    groups.push(reviewAndEditGroup)
  }

  const staticOptionsGroup = getDocInfoCommandsGroup(document, "docEditor")
  if (staticOptionsGroup) {
    groups.push(staticOptionsGroup)
  }

  return groups
}


const getGroupForReviewAndEdit = (document: BasicDocumentDetails): VerdiCommandGroup | undefined => {

  if (!document.id) return undefined
  const commandsToShow: VerdiCommand[] = []

  // const addSectionCmd = addSectionWithAiShowMoreCommandDefinition.buildMenuItem({
  //   doRefresh: async () => console.log("no implemented. TODO: make this command actually do something"),
  // })
  // commandsToShow.push(addSectionCmd)


  commandsToShow.push(showCustomAIPromptCommandDefinition.buildMenuItem({
    title: "Run custom AI Prompt ..."
  }))

  const autoDraftCmd = autoDraftFullDocConfirmCommandDefinition.buildMenuItem({})
  commandsToShow.push(autoDraftCmd)

  const makeInlineSuggestionsCmd = inlineDocSuggestionsGenerateCommandDefinition.buildMenuItem({})
  commandsToShow.push(makeInlineSuggestionsCmd)

  const clearInlineSuggestionsCmd = docBodyMarkClearAllCommandDefinition.buildMenuItem({})
  commandsToShow.push(clearInlineSuggestionsCmd)

  const reviewAsCmd = reviewAsAudienceShowOptionsCommandDefinition.buildMenuItem({})
  commandsToShow.push(reviewAsCmd)

  const group: VerdiCommandGroup = {
    title: "Review & Edit",
    commands: commandsToShow,
  }
  return group
}

/** These commands DO NOT require the document editor to be loaded */
export const getDocInfoCommandsGroup = (
  document: BasicDocumentDetails,
  filterMode: "docEditor" | "opportunityKanban",
  rect?: DOMRect,
): VerdiCommandGroup | undefined => {

  if (!document.id) return undefined
  const commandsToShow: VerdiCommand[] = []


  if (filterMode === "docEditor") {
    const addDocCommand = addDocShowOptionsCommandDefinition.buildMenuItem({
      parentDocId: document.id,
      rect,
    })
    commandsToShow.push(addDocCommand)

    // const addActionCommand = showAddActionCommandDefinition.buildMenuItem({
    //   parentDoc: document,
    // })
    // commandsToShow.push(addActionCommand)

    const setDocFrameworkCommand = setDocFrameworkShowOptionsCommandDefinition.buildMenuItem({
      documentId: document.id,
      isForOpportunity: document.type === DocumentSchema.DocumentType.opportunity,
      shouldAutoApplyToDocBody: true,
      rect,
    })
    commandsToShow.push(setDocFrameworkCommand)

    const setDocAccessShowOptionsCommand = setDocAccessShowOptionsCommandDefinition.buildMenuItem({
      document: document,
      rect,
    })
    commandsToShow.push(setDocAccessShowOptionsCommand)

  }

  const moveDocCommand = showMoveDocToParentCommandDefinition.buildMenuItem({
    documentId: document.id,
    rect,
  })
  commandsToShow.push(moveDocCommand)

  const deleteDocCommand = deleteDocCommandDefinition.buildMenuItem({
    docId: document.id,
    docTitle: document.title || "",
    docType: document.type,
    redirectAfterDelete: true,
  })
  commandsToShow.push(deleteDocCommand)

  // TODO: Put Delete here
  // TODO: Put show/hide doc here

  const group: VerdiCommandGroup = {
    title: "Note options",
    commands: commandsToShow,
  }
  return group
}
