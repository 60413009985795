import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { dispatch } from "../../../../../state/store"
import { loadingStatusState } from "../../../../../state/loadingStatusSlice"
import { docBodyMarkCreateCommandDefinition } from "./docBodyMarkCreate"
import { docBodyMarkClearAllCommandDefinition } from "./docBodyMarkClearAll"
import { Audience, AudiencePerspectivesState } from "../../../../../state/suggestions/AudiencePerspectivesSlice"
import { makeReviewAsAudienceAiCall } from "../../../../../ai/prompts/docDrafter/reviewAsAudienceAiCall"


const cmdType = GlobalCommandType.reviewAsAudience


/** Generates inline suggestions from the perspective of a target audience. */
export const reviewAsAudienceCommandDefinition: GlobalCommandDefinition<ReviewAsAudienceCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ReviewAsAudienceCommandArgs) => {
    const title = `Review as ${args.audience.title}`
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: args.isCurrentlySelected ? "currentValue" : undefined,
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ReviewAsAudienceCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Reviewing document ...",
    }))

    dispatch(AudiencePerspectivesState.setCurrentAudience(args.audience))


    // For now, clear all existing suggestions (faster to test as well)
    docBodyMarkClearAllCommandDefinition.triggerCommand?.({})

    const commandArgs = await makeReviewAsAudienceAiCall(services.getContextForAi, args.limitResultsTo)

    for (const arg of commandArgs) {
      docBodyMarkCreateCommandDefinition.triggerCommand?.(arg)
    }

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
      message: "",
    }))

    services.toast.showSuccess("Finished reviewing the document")

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: ReviewAsAudienceCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ReviewAsAudienceCommandArgs = {
  audience: Audience
  isCurrentlySelected: boolean
  limitResultsTo: number,
}
