import { SideMenuButton } from './SideMenuButton';
import { css } from '@emotion/react';


type Props = {
  onBeforeNavigate: () => void
}
export const SideMenuSectionForCompany = ({
  onBeforeNavigate,
}: Props) => {

  const isExpanded = window.location.pathname.includes("/organization/context")


  return (

    <div css={css`
      margin-left: 7px;
      margin-bottom: 2px;
    `}>
      <SideMenuButton
        landmarkId="SideMenuCompanyProfile"
        isExpanded={isExpanded}
        isSelected={isExpanded}
        isVisibleToOthers={true}
        title={"Company Profile"}
        url={"/organization/context"}
        onClick={onBeforeNavigate}
        childCount={0}
        shouldIndent={true}
      />
    </div>

  )
}
