import { ApiTypes, ChatRoleType } from "@verdi/shared-constants"
import { AssumptionSummary } from "../../../screens/assumptions/useAssumptionsProvider"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItem } from "../../utils/cleanupAiOutput"
import { AssumptionsState } from "../../../state/assumptionsSlice"
import { getCurrentAppState } from "../../../state/store"


/** Returns a list of questions to be used to identify Assumptions */
export const makeAssumptionQuestionsAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  suggestedQuestionsPending: string[],
  maxResultCount = 10,
) => {

  const allContext: AllContextForAi = await getContextForAi()
  const mainDocId = allContext.currentOpportunity?.opportunityMainDocId

  const amountToReturn = maxResultCount - suggestedQuestionsPending.length
  const existingQuestionAndAnswers = allContext.currentOpportunity?.assumptions || []
  const suggestionsPending: AssumptionSummary[] = suggestedQuestionsPending.map(q => ({ question: q, answer: "" }))
  const suggestedAnsweredAssumptions = AssumptionsState.getSuggestedAnsweredAssumptions(getCurrentAppState(), mainDocId)
  const questionsToExclude = [
    ...existingQuestionAndAnswers,
    ...suggestionsPending,
    ...suggestedAnsweredAssumptions,
  ]

  const messageForAllAssumptions = buildMessageForAllAssumptions(questionsToExclude)

  const prompt = getPrompt(amountToReturn)

  const response = await makeAiChatRequest({
    prompt,
    messages: [...allContext.promptMessages, ...messageForAllAssumptions],
  })

  const rawString = response?.aiResponseJson.content as string || ""
  let rawAssumptions = rawString.split("\n").map(l => cleanupAiOutputItem(l)).filter(l => l.trim().length > 0)
  // console.log("makeAssumptionQuestionsAiCall: ", { prompt, rawAssumptions, response })

  if (rawAssumptions.length > maxResultCount) {
    rawAssumptions = rawAssumptions.slice(0, maxResultCount)
  }

  return rawAssumptions
}


export const getPrompt = (maxResultCount: number) => {

  const prompt = `You are a product expert and whose job it is to determine key pieces of information about this specific opportunity by asking critical questions.
    
    INSTRUCTIONS:
    Focus on the specific opportunity context.
    You are outlining the specific assumptions for this opportunity in question format.
    Return the max of ${maxResultCount} critical questions.
    These questions should be formulaic, in that they encompass the critical aspects of 
    the opportunity to guide in determining if the opportunity should be discarded or not.
    You prefer questions that are open-ended over questions that can be answered with a yes/no.
    Only return the questions in your output, each question on its own line, similar to the example outputs listed below.
    
    Example questions:
    What is a basic description of this opportunity?
    What are the core features and functionality of the opportunity?
    What outcome is this opportunity targeting?
    What is the customer segment that loves this product enough to buy and use it?
    How likely is it that the customer segment is willing to pay enough to cover our target margin structure?
    Can we produce, market, and distribute the product at a low enough cost to support that margin structure?
    How large is this customer segment?
    What are the opportunity costs associated with [this outcome or timeline or ...]?
    Why is this the opportunity worth going after right now?
    What aspects of this opportunity align with our brand?
    What legal or intellectual property constraints that would prevent us from developing and selling this product?
    How will this product impact our existing product lines?
    How will we measure success?
    `

  return prompt
}


export const buildMessageForAllAssumptions = (allAssumptions: AssumptionSummary[]) => {

  if (allAssumptions.length === 0) return []

  const questionsAndAnswers = allAssumptions.map(a => {
    return `${a.question} ${a.answer}`
  })

  const content = `WE HAVE ALREADY ASKED THESE QUESTIONS. DO NOT REPEAT THEM OR VARIATIONS OF THEM:
${questionsAndAnswers.join('\n')}
`
  const message: ApiTypes.RunAiChatRequestMessage = {
    content,
    role: ChatRoleType.system
  }
  return [message]
}
