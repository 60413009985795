import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


/** TODO: Decide if we want to structure by Permissions instead of org? */
interface currentOrgState {
  allUsersAndPermissions: Data.User.UserWithPermission[]
  allUsers: Data.User.UserBasicInfo[]
  allUserPermissions: Data.UserPermissionForOrg.UserPermissionForOrgModel[]
}

const initialState: currentOrgState = {
  allUsersAndPermissions: [],
  allUsers: [],
  allUserPermissions: [],
}

export const currentOrgContextSlice = createSlice({
  name: 'currentOrg',
  initialState,
  reducers: {
    setAllUsers: (state, action: PayloadAction<Data.User.UserBasicInfo[]>) => {
      state.allUsers = action.payload
      state.allUsersAndPermissions = mapUsersWithPermissions(action.payload, state.allUserPermissions)
    },
    setAllUserPermissions: (state, action: PayloadAction<Data.UserPermissionForOrg.UserPermissionForOrgModel[]>) => {
      state.allUserPermissions = action.payload
      state.allUsersAndPermissions = mapUsersWithPermissions(state.allUsers, action.payload)
    },
    updateUserPermission: (state, action: PayloadAction<{
      userId: string,
      orgId: string,
      changes: Partial<Data.UserPermissionForOrg.UserPermissionForOrgModel>
    }>) => {

      const { userId, orgId, changes } = action.payload

      const existing = state.allUserPermissions.find(p => p.userId === userId && p.orgId === orgId)

      if (!existing) {
        const newToAdd: Data.UserPermissionForOrg.UserPermissionForOrgModel = {
          id: '',
          userId,
          orgId,
          canManageBilling: changes.canManageBilling || false,
          canManagePermissions: changes.canManagePermissions || false,
        }
        state.allUserPermissions = [...state.allUserPermissions, newToAdd]

      } else {
        state.allUserPermissions = state.allUserPermissions.map(p => {
          if (p.userId === userId && p.orgId === orgId) {
            return {
              ...p,
              ...changes
            }
          }
          return p
        })
      }

      state.allUsersAndPermissions = mapUsersWithPermissions(state.allUsers, state.allUserPermissions)

    }
  },
  selectors: {
    getAllUsersAndPermissions: createSelector([state => state.allUsersAndPermissions], (allUsersAndPermissions) => {
      return allUsersAndPermissions
    })
  }
})


const mapUsersWithPermissions = (allUsers: Data.User.UserBasicInfo[], allUserPermissions: Data.UserPermissionForOrg.UserPermissionForOrgModel[]) => {
  const mapped = allUsers.map(u => {
    const userPermission = allUserPermissions.find(p => p.userId === u.id)
    return {
      user: u,
      permission: userPermission,
    } as Data.User.UserWithPermission
  })
  return mapped
}

export const {
  setAllUsers,
  setAllUserPermissions,
  updateUserPermission,
} = currentOrgContextSlice.actions
export const {
  getAllUsersAndPermissions,
} = currentOrgContextSlice.selectors


export default currentOrgContextSlice.reducer

