import { Extension } from '@tiptap/react'

import { TextSelection, Selection } from "prosemirror-state";


import { getSelectedBlockText } from '../../utils/getSelectedBlockText';
import VeNode from '../../VeNode';
import runUntil from "../../utils/runUntil";

import { cursorIsAtBeginning } from './PressEnter';


declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    PressBackspace: {
      deleteNodeIfEmpty: (
        moveCursor?: boolean
      ) => ReturnType,
      deleteToLastSlash: () => ReturnType,
      deletePreviousChar: () => ReturnType
      deleteAtFirstPosition: () => ReturnType
    }
  }
}

export const PressBackspace = Extension.create<{}>({
  name: 'PressBackspace',
  addKeyboardShortcuts() {
    return {
      // 'Mod-Backspace': () => true,
      // 'Alt-Backspace': () => true,
      'Backspace': ({ editor }) => 
        runUntil(
          (result: boolean) => !!result,
          () => editor.commands.deletePreviousChar(),
          () => editor.commands.deleteSelection(),
          () => editor.commands.deleteNodeIfEmpty(),
          () => editor.commands.deleteAtFirstPosition(),
          () => editor.commands.joinBackward(), 
          // () => editor.commands.moveCursorToPreviousNode(true)
      ),
      // TODO: Implement these
      // REF: https://prosemirror.net/docs/ref/#commands.pcBaseKeymap
      // REF: https://prosemirror.net/docs/ref/#commands.macBaseKeymap
      'Ctrl-Alt-Backspace': () => true,
      'Alt-Delete': () => true,
      'Alt-d': () => true,
      'Ctrl-h': () => true,
      'Ctrl-d': () => true,
      'Delete': () => true,
      'Mod-Delete': () => true,
    }
  },
  addCommands() {
    return {
      deleteNodeIfEmpty: () => ({ state, dispatch, chain }) => {
        const originalHead = state.selection.$head
        if (!state.selection.empty) return false
        const selectedBlocktext = getSelectedBlockText(state.selection.$head)
        const isNotEmpty = Boolean(selectedBlocktext?.length)
        if (isNotEmpty) {
          return false
        }
        const veNode = VeNode.fromPosition(state, state.selection.$head.pos)
        if (!veNode) return false
        const parent = veNode.findNestableParent(state)
        if (typeof parent === 'undefined') {
          return false
        }
        const isOnlyBlockInDoc = parent.pos() === 0 && state.doc.childCount === 1
        if (isOnlyBlockInDoc)
          return false
        const contentList = parent.node().maybeChild(1)
        const childCount = contentList?.childCount
        if (childCount) return false
        if (dispatch) {
          const immediateParent = veNode.findParent(state)
          const grandparent = immediateParent?.findParent(state)
          const isOnlyChild = grandparent?.childCount() === 1
          const isWrappedByContentList = grandparent?.type().name === 'contentList'
          if (isOnlyChild && isWrappedByContentList) {
            chain().deleteNode(state.schema.nodes.contentList)
          } else {
            chain().deleteNode(parent.node().type)
          }
          const newHead = state.tr.selection.$head
          const cursorMovedDown = originalHead.pos <= newHead.pos
          if (cursorMovedDown) {
            chain().moveCursorToPreviousNode()
          }
        }
        return true
      },
      deletePreviousChar: () => ({ state, dispatch }) => {
        const { $head, empty } = state.selection
        if (!empty) return false
        if (cursorIsAtBeginning($head)) return false
        const numCharsToDelete = 1
        const to = state.selection.$anchor.pos
        const from = to - numCharsToDelete
        if (dispatch) {
          dispatch(state.tr.deleteRange(from, to))
        }
        return true
      },
      deleteToLastSlash: () => ({ state, dispatch }) => {
        const selectedBlocktext = getSelectedBlockText(state.selection.$anchor)
        if (typeof selectedBlocktext === 'undefined')
          return false
        const lastSlashIndex = selectedBlocktext?.lastIndexOf('/')
        if (lastSlashIndex === -1)
          return false
        const numCharsToDelete = selectedBlocktext.length - lastSlashIndex
        const to = state.selection.$anchor.pos
        const from = to - numCharsToDelete
        if (dispatch) {
          dispatch(state.tr.deleteRange(from, to))
        }
        return true
      },
      deleteAtFirstPosition: () => ({state}) => {
        const { $from } = state.selection;
        const { nodes: nodeTypes} = state.schema;

        const node = VeNode.fromResolvedPosition(
          $from
        )?.findParentWithType(state, VeNode.NESTABLE_TYPES);

        if(!node || node.pos() === 0){
          return false;
        }

        const title = node.title(state);
        const contentList = node.contentList(state);

        if($from.pos !== title.bounds().start + 1){
          return false;
        }

        const previousNode = node.findNestableNodeInPreviousPosition(state);

        if(!previousNode){
          return false;
        }

        const previousTitle = previousNode.title(state);

        const selectionStart = previousTitle.bounds().end;

        state.apply(state.tr.delete(node.bounds().start, node.bounds().end));
        
        if(contentList){
          state.apply(
            state.tr.insert(
              previousTitle.bounds().end,  
              nodeTypes.contentList.create(null, 
                contentList?.content()
              )
            )
          )
        }
        
        state.apply(state.tr.replaceRangeWith(previousTitle.bounds().start, previousTitle.bounds().end, nodeTypes.title.create(null, [
          state.schema.text(`${previousTitle.textContent()}${title.textContent()}`)
        ])));

        state.apply(state.tr.setSelection(TextSelection.create(state.doc, selectionStart - 1)));

        return true;
      }
    }
  },
})

