import { DocumentType } from "../documentSchema"


export type TagDto = {
  id: string,
  title: string,
}

export type addTagIfNotExistsRequestBody = {
  title: string,
}


export const DefaultTagToDocTypeMapping: TagToDocTypeMapping[] = [
  {
    title: "Outcome",
    docType: DocumentType.opportunity,
  },
  {
    title: "Problem",
    docType: DocumentType.opportunity,
  },
  {
    title: "Solution",
    docType: DocumentType.opportunity,
  },
  {
    title: "Assumption",
    docType: DocumentType.assumption,
  }
]

export type TagToDocTypeMapping = {
  title: string,
  docType?: DocumentType,
}
