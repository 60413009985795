import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react'
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation'
import { useCreateSnippetMutation, useCreateSnippetMutation$data, CreateSnippetInput } from './__generated__/useCreateSnippetMutation.graphql'


const mutationQL = graphql`
mutation useCreateSnippetMutation (
  $input: CreateSnippetInput!
  $updateConnectionIds: [ID!]!
) {
  createSnippet(input: $input)
  @appendNode(connections: $updateConnectionIds, edgeTypeName: "SnippetEdge") {
    placeholderKey
    textValue
  }
}`

export const useCreateSnippet = (
  onCreated?: (response: useCreateSnippetMutation$data['createSnippet']) => void,
  updateConnectionIds?: string[]
) => {

  const [save, isSaving] = useErrorHandledMutation<useCreateSnippetMutation>(mutationQL)
  const createSnippet = useCallback((input: CreateSnippetInput) => {
    save({
      variables: {
        input,
        updateConnectionIds: updateConnectionIds || [],
      },
      onCompleted: (response) => {
        if (onCreated) {
          onCreated(response.createSnippet)
        }
      }
    })
  }, [])
  return [createSnippet, isSaving] as const
}
