import { configureStore } from '@reduxjs/toolkit'
import { docRelationsSlice } from './docRelationsSlice'
import { suggestionsForDocSlice } from './suggestions/suggestionsForDocSlice'
import { loadingStatusSlice } from './loadingStatusSlice'
import { onboardingStatusSlice } from './onboardingStatusSlice'
import { aiContextSlice } from './aiSlice'
import { frameworksSlice } from './frameworksSlice'
import { documentFrameworksSlice } from './documentFrameworksSlice'
import { plansSlice } from './PlanSlice'
import { currentOrgContextSlice } from './currentOrgSlice'
import { assumptionsSlice } from './assumptionsSlice'
import { opportunitiesSlice } from './opportunitiesSlice'
import { docLineSuggestionsSlice } from './suggestions/docLineSuggestionsSlice'
import { companyContextSlice } from './companyContextSlice'
import { docBodySnapshotsSlice } from './docBodySnapshotsSlice'
import { suggestionsForPlaceholdersSlice } from './suggestions/suggestionsForPlaceholdersSlice'
import { audiencePerspectivesSlice } from './suggestions/AudiencePerspectivesSlice'
import { suggestionsForMarksSlice } from './suggestions/suggestionsForMarks'
import { aiContextConfigSlice } from './aiContextConfigSlice'
import { documentsSlice } from './DocumentsSlice'
import { docMetadataSlice } from './docMetadataSlice'
import { documentTasksSliceSlice } from './documentTasksSlice'
import { docIdeasSlice } from './docIdeasSlice'
import { suggestionsForDocTitleSlice } from './suggestions/suggestionsForDocTitleSlice'
import { autoAiModeSlice } from './AutoAiModeSlice'
import { mindMapSlice } from './MindMapSlice'
import { tagsSlice } from './TagsSlice'
import { docTagsSlice } from './docTagsSlice'
import { mindMapSuggestionsSlice } from './MindMapSuggestionsSlice'


export const store = configureStore({
  reducer: { // property names here MUST match slice.name
    aiContext: aiContextSlice.reducer,
    aiContextConfig: aiContextConfigSlice.reducer,
    assumptions: assumptionsSlice.reducer,
    audiencePerspectives: audiencePerspectivesSlice.reducer,
    autoAiMode: autoAiModeSlice.reducer,
    companyContext: companyContextSlice.reducer,
    currentOrg: currentOrgContextSlice.reducer,
    docBodySnapshots: docBodySnapshotsSlice.reducer,
    docIdeas: docIdeasSlice.reducer,
    docMetadata: docMetadataSlice.reducer,
    docTags: docTagsSlice.reducer,
    documents: documentsSlice.reducer,
    documentFrameworks: documentFrameworksSlice.reducer,
    documentTasks: documentTasksSliceSlice.reducer,
    onboardingStatus: onboardingStatusSlice.reducer,
    opportunities: opportunitiesSlice.reducer,
    docRelations: docRelationsSlice.reducer,
    frameworks: frameworksSlice.reducer,
    loadingStatus: loadingStatusSlice.reducer,
    mindMap: mindMapSlice.reducer,
    mindMapSuggestions: mindMapSuggestionsSlice.reducer,
    plans: plansSlice.reducer,
    suggestionsForDoc: suggestionsForDocSlice.reducer,
    suggestionsForMarks: suggestionsForMarksSlice.reducer,
    suggestionsForDocTitle: suggestionsForDocTitleSlice.reducer,
    suggestionsForPlaceholders: suggestionsForPlaceholdersSlice.reducer,
    tags: tagsSlice.reducer,
    docLineSuggestions: docLineSuggestionsSlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

/** Allows access to current state, without using react hooks */
export const getCurrentAppState = () => store.getState()

/** Dispatch events, without using the AppDispatch hook */
export const dispatch = store.dispatch
