import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { useAiSuggester } from "./useAiSuggester"


type Props = {
  getDocumentContextForAi: () => DocumentContextForAi | undefined,
}
/** Extension of useAiSuggestionsGenerator */
export const useInsightsDetector = ({
  getDocumentContextForAi,
}: Props) => {


  const baseAiSuggester = useAiSuggester({
    getDocumentContextForAi,
    getRawPrompt,
  })

  return {
    ...baseAiSuggester,
  }
}

export type InsightsDetector = ReturnType<typeof useInsightsDetector>


const getRawPrompt = (documentBody: string) => {
  const prompt = `Detect key, underlying insights that will support future work. 
An insight is a deep, accurate understanding of a cause-and-effect relationship, often derived from multiple learnings or a comprehensive analysis of data.
Insights are valuable for long-term strategy and innovation. They can reveal underlying user behaviors, preferences, or pain points that are not immediately obvious.

Phrase the insights in as little words as possible.

Return each insight as it's own line, do not put them in a numbered list. Do not add any prefix, for example:
Users are not just looking for a faster checkout
Users want multiple payment options, which currently we don't offer
Users are not understanding what an opportunity is

###
The insights you are detecting should be intuited from common themes in the document body.
Extract up to 5 insights from the following document body:
${documentBody}
###
`
  return prompt

}
