import { memo } from 'react';
import { Handle, Position, NodeProps, Node, useReactFlow } from '@xyflow/react';
import { css } from '@emotion/react';
import { NewNodeMenuList } from './NewNodeMenuList';


export type AddNewNodeWrapperData = Node & {
  type: "addNew"
  data: AddNewNodeData
}

export type AddNewNodeData = {
  title: string
  targetParentNodeId: string
}


/** The Add button as it's own node on the graph */
const AddNewNode = (props: NodeProps<AddNewNodeWrapperData>) => {

  const { data, selected } = props
  const { title } = data as AddNewNodeData

  const { getNode } = useReactFlow()
  const parentNode = getNode(data.targetParentNodeId) as Node


  return (
    <div
      title="click to add a node"
      css={css`
        border: 1px dashed #0984e3;
        border-radius: 4px;
        background-color: ${selected ? `var(--item-selected-bg)` : `var(--surface-bg)`};
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        padding: 12px;
        min-width: 200px;
        text-align: center;
        font-weight: bold;
        color: #0984e3;
        cursor: pointer;
        line-height: 1.2;
    `}>
      {title}
      <Handle
        id="child"
        type="target"
        position={Position.Left}
        css={css`
          visibility: hidden;
        `} />
      <Handle
        id="parent"
        type="source"
        position={Position.Right}
        css={css`
          visibility: hidden;
        `} />

      <div className="nodrag">
        <NewNodeMenuList
          parentNode={parentNode}
        />
      </div>
    </div>
  );
};

export default memo(AddNewNode);
