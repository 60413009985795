import { useKeyDownHandler } from "../floatingBlockContext/useKeyDownHandler"


type Props = {
  hasFocus: boolean
  submitUserInput: () => void
}
/** Simple utility to listen for ENTER key for chat input only when necessary */
export const UserInputForChatEnterKey = ({ hasFocus, submitUserInput }: Props) => {

  useKeyDownHandler((evt) => {
    if (!hasFocus) return
    if (evt.key === "Enter" && !evt.shiftKey) {
      evt.preventDefault() // Prevent adding a line break in text area
      submitUserInput()
    }
  })

  return (
    null
  )
}
