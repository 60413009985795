import { makeAiApiRequest } from "../../ai/requests/AiApiRequests"
import { getAssumptionLevelsPrompt, parseAssumptionLevelsPromptResults } from "../../ai/prompts/assumptions/getAssumptionLevelsPrompt"
import { makeAssumptionQuestionsAiCall } from "../../ai/prompts/assumptions/makeAssumptionQuestionsAiCall"
import { suggestAssumptionTitlePrompt } from "../../ai/prompts/assumptions/suggestAssumptionTitlePrompt"
import { AiContextConfig, AllContextForAi } from "../../ai/promptUtils/useAllContextForAi"
import { makeAssumptionAnswersAiCall } from "../../ai/prompts/assumptions/makeAssumptionAnswersAiCall"
import { dispatch } from "../../state/store"
import { AssumptionsState } from "../../state/assumptionsSlice"
import { makeFindAnsweredAssumptionsAiCall } from "../../ai/prompts/assumptions/FindAnsweredAssumptionsAiCall"


/** Step 1 of 2: Determine which assumptions are relevant, return in question format */
const detectAssumptions = async (
  existingSuggestions: string[],
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  dispatch(AssumptionsState.setSuggestionsLoading(true))
  let newQuestions: string[] = []
  try {
    newQuestions = await makeAssumptionQuestionsAiCall(getContextForAi, existingSuggestions)
  } catch (error) {
    console.error("detectAssumptions: error = ", error)
  }
  dispatch(AssumptionsState.setSuggestionsLoading(false))

  return newQuestions

}


/** Step 2 of 2: generate potential answers to a specific question */
const getPotentialAnswers = async (
  question: string,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  try {
    return await makeAssumptionAnswersAiCall(getContextForAi, question)
  } catch (error) {
    console.error("detectAnswers: error = ", error)
  }
  return []

}


/** For an existing document body, find answered assumptions (questions and answers) */
const detectAnsweredAssumptions = async (
  targetDocId: string,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {
  if (!targetDocId) {
    console.warn("Could not detect answered assumptions. No related document id.")
    return
  }

  dispatch(AssumptionsState.setSuggestedAnsweredAssumptions({
    targetDocId,
    answeredAssumptions: [],
    isLoading: true
  }))

  const newAnsweredAssumptions = await makeFindAnsweredAssumptionsAiCall(getContextForAi)

  dispatch(AssumptionsState.setSuggestedAnsweredAssumptions({
    targetDocId,
    answeredAssumptions: newAnsweredAssumptions,
    isLoading: false,
  }))
}


/** @deprecated We _might_ not need this anymore */
const detectAssumptionLevels = async (assumptionsToGrade: string[]) => {
  const prompt = getAssumptionLevelsPrompt(assumptionsToGrade, undefined, "")
  try {
    const response = await makeAiApiRequest(prompt, "", true)
    const rawAssumptions = response?.aiResponseJson as string

    const formattedResults = parseAssumptionLevelsPromptResults(rawAssumptions)
    return formattedResults

  } catch (error) {
    console.error("detectAssumptionLevels: error = ", error)
  }
}

/** @deprecated We _might_ not need this anymore */
const detectAssumptionTitleFromQuestionAndAnswer = async (question: string, answer: string) => {
  const prompt = suggestAssumptionTitlePrompt(question, answer)
  try {
    const response = await makeAiApiRequest(prompt, "", true)
    const responseString = response?.aiResponseJson as string
    console.log("detected Title", responseString)
    return responseString

  } catch (error) {
    console.error("detectAssumptionLevels: error = ", error)
  }

}

export const AssumptionsDetector = {
  detectAssumptions,
  getPotentialAnswers,
  detectAnsweredAssumptions,
  detectAssumptionLevels,
  detectAssumptionTitleFromQuestionAndAnswer,
}
