import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { IconTypeForVerdiCommand, VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DomPositionRect } from "../../../../screens/document/tiptapEditor/utils/getCurrentBlockContext"


const cmdType = GlobalCommandType.showMiniCommandMenu

/** Handles the small drop down command menu for specific fields / buttons */
export const showMiniCommandMenuCommandDefinition: GlobalCommandDefinition<ShowMiniCommandMenuCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowMiniCommandMenuCommandArgs) => {
    // This is a special command that is not shown in the command menu
    const toReturn =
      {
        globalCommandType: cmdType,
        name: '',
        description: '',
        searchName: '',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ShowMiniCommandMenuCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("showMiniCommandMenuCommandDefinition: processCommand: args", args)

    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (currentBlockContext && !args.rectOfAnchorElement) {
      args.rectOfAnchorElement = currentBlockContext.domElement?.getBoundingClientRect()
      console.log("Auto-setting rectOfAnchorElement", args.rectOfAnchorElement)
    }

    services.commandMenuProvider.openMiniCommandMenu(args)

    onProcessingComplete(true)

  },
  triggerCommand: (args: ShowMiniCommandMenuCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ShowMiniCommandMenuCommandArgs = {
  /** The GetBoundingRect() of a target element to visually anchor the CM next to */
  rectOfAnchorElement: DomPositionRect | undefined
  /** If the rectOfAnchorElement can be scrolled on the page consider setting this to true, 
   * 
   *  If the anchor element is fixed and not affected by scroll, set it to false. */
  shouldAdjustRectForScrollOffset: boolean
  commands: VerdiCommand[]
  commandForNoResults?: CommandForNoResultsOptions
  loadCommands?: () => Promise<VerdiCommand[]>
  onItemSelected?: (command: VerdiCommand) => void
  searchPlaceholderText: string
  title?: string
  subTitle?: string
  isLoading?: boolean
  loadingText?: string
  onCloseCallback?: () => void
  transparentOverlay?: boolean
  hideSearchInput?: boolean
}

/** When search text returns no commands in the filter, optionally show a "no results found" message with a special command */
export type CommandForNoResultsOptions = {
  commandTitle: string
  onCommandSelected: (searchText: string) => Promise<void>
  iconType?: IconTypeForVerdiCommand
}
