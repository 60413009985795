import { OpenAi } from "@verdi/shared-constants"
import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { insertionPointPlaceholder } from "../../documents/getDocAsIndentedMarkdown"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsStrings } from "../../utils/cleanupAiOutput"


/** Generates suggested text for the current line */
export const getLineSuggestions = async (getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>) => {

  const allContext: AllContextForAi = await getContextForAi({
    includeContext: {
      document: {
        bodyAs: "indented-markdown-with-caret-position",
      }
    },
    includeMessages: {
      documentFramework: true,
    }
  })

  const docContext = allContext.currentDoc
  if (!docContext) {
    console.warn("getLineSuggestions: No currentDoc context found")
    return []
  }

  const prompt = getLineSuggestionsPrompt(docContext)

  console.log("generateLineSuggestions: ", { allContext, prompt })


  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    openAIParams: {
      model: OpenAi.Gpt_4_Latest,
    }
  })

  const rawText = response?.aiResponseJson.content as string || ""
  const suggestions = cleanupAiOutputAsStrings(rawText)
  return suggestions
}


export const getLineSuggestionsPrompt = (docContext: DocumentContextForAi) => {

  const numberToReturn = 3
  const isSection = docContext.nearestNestableNodeType === "section"
  const instructions = isSection ?
    `INSTRUCTIONS: Your goal is to give your ${numberToReturn} best contextual, logical, 
and very concise section headings for this document based on the document context, framework, 
and the exact position of ${insertionPointPlaceholder}.

Include only the ${numberToReturn} suggestions exactly as they should appear in the document in your response.
Do not include any section headings already in the document.
Only return the concise section headings.
`
    :
    `INSTRUCTIONS: Your task is to write exactly three very concise, meaningfully rich text options that are contextual to the exact location of the placeholder: ${insertionPointPlaceholder} in the current document context. 
Please generate the text options directly. Do not include a preamble, just the varied text options.
It is essential to tailor your text options to these things: 
how the placeholder is nested (for example if it is nested the three options should be diving deeper into the context of what is above it, or at the root level possibly three new ideas),
the format of the text immediately around the placeholder (or the format of a similar place in an example from the framework context),
the context of the words immediately around the placeholder that could give you clues as to what text is expected there (for example if the placeholder is under the section heading # Problem Statement, then respond with three variations of a problem statement),
the inferred purpose of the specific section the placeholder is in (for example if you are nested inside the section # Channels then you are probably wanting to give three options of sales channels for the context given).

The options should not be instruction suggestions of what could be written (ex: "Specify the features that will enhance the product), 
rather the actual direct text (ex: seamless command menu interface for the user to trigger commands)

EXAMPLE RESPONSE (with ${numberToReturn} very concise, contextual options):
This is an interesting sentence that is very contextual to the placeholder's position
Different text that is also contextual
Another interesting idea for the placeholder's position
  `

  const bodyWithPlaceholder = docContext.bodyPartsAsMarkdown?.allLines

  const prompt = `
You have been given a specific opportunity, framework, and current document context to help you with this task. Rely on these contexts most heavily.
${instructions}
Organize your text options with most logical text to put in the exact placeholder position: ${insertionPointPlaceholder} first in the current document body.
Do not have line breaks within a text option, return a each individual text option without a carriage return.
Return each text option on a separate line. Remember to be very concise. 
`
  return prompt
}
