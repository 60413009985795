import { css } from "@emotion/react"
import { InputTextWithState } from "./InputTextWithState"
import { AiSuggestionButtonList } from "../aiComponents/AiSuggestionButtonList"
import { ResponsiveValue } from "@chakra-ui/react"
import { useComboBoxController } from "../commands/miniCommandMenu/comboBox/useComboBoxController"
import { useCallback, useState } from "react"


type Props = {
  value: string
  onValueUpdated: (newValue: string) => void
  onFocus?: () => void,
  onBlur?: () => void,
  isLoading: boolean
  suggestedValues: string[]
  prefix?: string | undefined
  placeholder?: string | undefined
  suggestionsLoadingMessage?: string | undefined
  noOfLines?: ResponsiveValue<number> | undefined
  debounceDelay?: number | undefined
  inputTextRef?: React.RefObject<HTMLTextAreaElement>
}
export const InputTextWithSuggestions = ({
  onValueUpdated,
  onFocus,
  onBlur,
  value,
  isLoading,
  suggestedValues,
  prefix,
  placeholder,
  suggestionsLoadingMessage,
  noOfLines,
  debounceDelay,
  inputTextRef,
}: Props) => {


  const comboBoxState = useComboBoxController({
    onClose: () => { },
    onRunCommand: (command) => { updateTextValue(command.name) },
    mode: "show-all-no-filter",
  })

  const updateTextValue = useCallback((newValue: string) => {
    comboBoxState.setSearchText(newValue)
    onValueUpdated(newValue)
  }, [comboBoxState, onValueUpdated])

  const [inputHasFocus, setInputHasFocus] = useState(false)


  return (
    <div>
      <div
        css={css`
          margin-top: 6px;
      `}>
        <div css={css`
          display: flex;
          flex-direction: row;
        `}>
          {prefix &&
            <div css={css`
            font-size: 20px;
            width: ${prefixWidth}px;
            font-weight: bold;
            display: flex;
            align-items: center;
            opacity: 0.5;
          `}>
              {prefix}
            </div>
          }
          <InputTextWithState
            onValueUpdated={updateTextValue}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                evt.preventDefault()
              }
              comboBoxState.handleOnKeyDown(evt as any)
            }}
            onFocus={() => {
              setInputHasFocus(true)
              onFocus?.()
            }}
            onBlur={() => {
              setTimeout(() => {
                setInputHasFocus(false)
                onBlur?.()
              }, 100)
            }}
            placeholder={placeholder}
            value={value}
            fontSize={20}
            maxHeight={200}
            minHeight={12}
            overflowY={"auto"}
            debounceDelay={debounceDelay}
            noOfLines={noOfLines}
            inputTextRef={inputTextRef}
          />
        </div>

      </div>

      {/* {suggestedValues.length > 0 && */}
      <div
        css={css`
            width: calc(100% - ${prefixWidth}px);
            /* min-height: 150px; */
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-left: ${prefix ? prefixWidth : 0}px;
        `}>

        {inputHasFocus && value.length === 0 &&
          <AiSuggestionButtonList
            comboBoxState={comboBoxState}
            isLoading={isLoading}
            suggestionNames={suggestedValues}
            selectedSuggestionName={value}
            loadingMessage={suggestionsLoadingMessage}
            shouldFloat={true}
          />
        }

      </div>
      {/* } */}

    </div>

  )
}

const prefixWidth = 24;
