import { css } from "@emotion/react"
import { LoggedInUserProvider } from "../../../components/appServices/users/useLoggedInUserProvider"
import { VerdiIconUserProfile } from "../../../components/icons/VerdiIcons"
import { PageHeading } from "../../../components/pageLayout/PageHeading"
import { CurrentUserEditor } from "../../organization/users/currentUser/CurrentUserEditor"
import { OnboardingManager } from "../useOnboardingManager"
import { mediaQuerySmallWidth } from "../../../components/pageLayout/styleHelpers"
import { formInputLabelWidth } from "../../../components/forms/FormInputText"


type Props = {
  loggedInUserProvider: LoggedInUserProvider
  onboardingManager: OnboardingManager
}
export const OnboardingStepUserInfo = ({
  loggedInUserProvider,
  onboardingManager,
}: Props) => {


  return (
    <div>
      {loggedInUserProvider.loggedInUser &&
        <div css={css`
        margin-right: ${formInputLabelWidth}px;
        @media ${mediaQuerySmallWidth} {
          margin-right: 0;
        }
      `}>
          <div css={css`
            margin-left: ${formInputLabelWidth}px;
            @media ${mediaQuerySmallWidth} {
              margin-left: 0;
            }
          `}>
            <PageHeading
              title="User Profile"
              titleSize="xl"
              description="Personalize AI results to your specific context."
              // description="This helps guide your experience toward the scope and outcomes you’re targeting."
              iconLeft={<VerdiIconUserProfile />}
            />
          </div>
          <CurrentUserEditor
            currentUser={loggedInUserProvider.loggedInUser}
            onboardingManager={onboardingManager}
          />
        </div>
      }
    </div>
  )
}
