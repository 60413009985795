import { css } from "@emotion/react"
import { mediaQuerySmallWidth } from "../../../components/pageLayout/styleHelpers"
import { VerdiIconCompanyProfile, VerdiIconOpportunity, VerdiIconUserProfile } from "../../../components/icons/VerdiIcons"
import { useAppServices } from "../../../components/appServices/useAppServices"


export const OnboardingStepWelcome = () => {


  // const year = new Date().getFullYear()

  const { loggedInUserProvider } = useAppServices()

  const usersFirstName = loggedInUserProvider.loggedInUser?.name?.split(" ")[0] || ""


  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 300px);
        justify-content: start;
        align-items: center;
        @media (${mediaQuerySmallWidth}) {
          justify-content: start;
          min-height: inherit;
        }
    `}>

      <h1
        css={css`
          width: 100%;
          text-align: center;
          margin: 0;
          font-size: 2rem;
          /* color: var(--verdi-primary-color); */
          @media (${mediaQuerySmallWidth}) {
            font-size: 1.4rem;
          }
      `}>
        {usersFirstName ?
          usersFirstName :
          "Hi there"}, welcome to Verdi!
      </h1>

      <p css={css`
        text-align: center;
        margin-bottom: 20px;
        font-weight: normal;
      `}>
        An AI Coach for your product process.
      </p>

      <div css={css`
        max-width: 500px;
        width: 100%;
      `}>
        <img
          src="images/onboarding-files-1.png"
          alt="onboarding-files-1"
          css={css`
          border-radius: 20px;
        `}
        />
      </div>

      <p css={css`
        padding: 8px;
      `}>
        Let’s start by gathering a little context.
      </p>

      {/* <div
        css={css`
          display: flex;
          flex-direction: row;
          gap: 30px;
          margin: 0 0 60px;
          @media (${mediaQuerySmallWidth}) {
            flex-direction: column;
            gap: 8px;
            margin: 0;
          }
      `}>



        <WelcomeCard
          title="User Profile"
          description="Verdi's goal is to help you reach yours. Let's start with an intro about your role."
          icon={<VerdiIconUserProfile boxSize={50} />}
        />

        <WelcomeCard
          title="Company Profile"
          description="Your business model and strategy are the foundation for each product opportunity."
          icon={<VerdiIconCompanyProfile boxSize={50} />}
        />

        <WelcomeCard
          title="First Opportunity"
          description="Let's explore a problem or idea to help your customers and your company."
          icon={<VerdiIconOpportunity boxSize={50} />}
        />


      </div> */}

      {/* <div
        css={css`
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.2;
          font-size: 0.8rem;
      `}>
        &#169; {year} Verdi
      </div> */}
    </div>
  )
}


type WelcomeCardProps = {
  title: string
  description: string
  icon?: React.ReactNode
}
const WelcomeCard = ({
  title,
  description,
  icon,
}: WelcomeCardProps) => {


  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;
      justify-content: start;
      align-items: center;
      @media (${mediaQuerySmallWidth}) {
        flex-direction: row;
        align-items: start;
        max-width: 300px;
        margin: auto;
        gap: 16px;
      }
    `}>

      <div css={css`
            width: 50px;
            height: 50px;
            margin: 20px 0;
          `}>
        {icon}
      </div>

      <div css={css`
        text-align: center;
        width: 100%;
        & > h2 {
          margin-bottom: 20px;
        }
        @media (${mediaQuerySmallWidth}) {
          text-align: left;
          & > h2 {
            margin-bottom: 0;
          }
        }
      `}>
        <h2 css={css`
          text-transform: uppercase;
        `}>
          {title}
        </h2>
        <p>
          {description}
        </p>
      </div>

    </div>
  )
}
