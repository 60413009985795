import { css } from "@emotion/react"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { IconButtonTertiary } from "../buttons/IconButtonTertiary"
import { VerdiIconDocument, VerdiIconViewModeMindMap } from "../icons/VerdiIcons"
import { smallBreakpoint } from "../pageLayout/styleHelpers"
import { ButtonTertiary } from "../buttons/ButtonTertiary"


type Props = {
  viewMode: DocumentViewMode
  setViewMode: (mode: DocumentViewMode) => void
}
export const DocViewToggle = ({
  viewMode,
  setViewMode,
}: Props) => {

  const { experimentalEnabled } = useFeatureFlags()
  if (!experimentalEnabled) {
    return null
  }

  return (
    <div css={css`
      font-size: 1rem;
      opacity: 0.7;
      `}
    >
      <div css={css`
        display: none;
        @container mainStage (max-width: ${smallBreakpoint}px) {
        display: inline-block;
      }
      `}>
        <IconButtonTertiary
          aria-label="Document Editor"
          title="Document Editor"
          icon={<VerdiIconDocument />}
          onClick={() => setViewMode(DocumentViewMode.DocumentEditor)}
          backgroundColor={viewMode === DocumentViewMode.DocumentEditor ? "var(--item-selected-bg)" : undefined}
        />
        <IconButtonTertiary
          aria-label="Mind Map"
          title="Mind Map"
          icon={<VerdiIconViewModeMindMap />}
          onClick={() => setViewMode(DocumentViewMode.MindMap)}
          backgroundColor={viewMode === DocumentViewMode.MindMap ? "var(--item-selected-bg)" : undefined}
        />
      </div>

      <div css={css`
        display: none;
        @container mainStage (min-width: ${smallBreakpoint}px) {
          display: inline-block;
        }
      `}>
        <ButtonTertiary
          label="Document Editor"
          title="Document Editor"
          leftIcon={<VerdiIconDocument />}
          onClick={() => setViewMode(DocumentViewMode.DocumentEditor)}
          backgroundColor={viewMode === DocumentViewMode.DocumentEditor ? "var(--item-selected-bg)" : undefined}
        />
        <ButtonTertiary
          label="Mind Map"
          title="Mind Map"
          leftIcon={<VerdiIconViewModeMindMap />}
          onClick={() => setViewMode(DocumentViewMode.MindMap)}
          backgroundColor={viewMode === DocumentViewMode.MindMap ? "var(--item-selected-bg)" : undefined}
        />
      </div>
    </div>
  )

}


export enum DocumentViewMode {
  DocumentEditor = "DocumentEditor",
  MindMap = "MindMap",
}
