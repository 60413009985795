import { Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { VerdiIconQuestion } from "./icons/VerdiIcons"


type Props = {
  word: string,
  definition: string,
  triggerDisplayMode?: "showHelpIcon" | "showWord",
  pronunciation?: string,
  wordType?: "noun" | "verb" | "adjective" | "adverb" | "pronoun" | "preposition" | "conjunction" | "interjection"
}
export const DefinitionTipForUser = ({
  word,
  definition,
  triggerDisplayMode,
  pronunciation,
  wordType,
}: Props) => {


  const titleColor = useColorModeValue("#3F45D2", "#7075e1")


  return (
    <Popover
      trigger="hover"
      placement="top-start"
    >
      <PopoverTrigger>
        <span css={css`
          color: ${titleColor};
          cursor: help;
          line-height: 1;
        `}>
          {triggerDisplayMode === "showHelpIcon"
            ?
            <div css={css`
              font-size: 0.8em;
            `}>
              <VerdiIconQuestion
                opacity={0.8}
                lineHeight={0}
              />
            </div>
            : word
          }
        </span>
      </PopoverTrigger>
      <PopoverContent
        marginTop="-54px"
        marginLeft="-13px"
      >
        <PopoverCloseButton />
        <PopoverHeader
          fontSize="1rem"
          fontWeight="normal">
          <h2 css={css`
          color: ${titleColor};
          font-size: 1.5rem;
        `}>
            {word}
          </h2>
          {(!!pronunciation || !!wordType) &&
            <p>
              {!!pronunciation &&
                <span>
                  [{pronunciation}]
                </span>
              }
              {!!wordType &&
                <strong>
                  &nbsp;
                  {wordType}
                </strong>
              }
            </p>
          }
        </PopoverHeader>
        <PopoverBody
          fontSize="1rem"
          fontWeight="normal">
          {definition}
        </PopoverBody>
      </PopoverContent>
    </Popover >
  )
}
