import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { buildPromptToContinueWritingDoc } from "../../../../ai/coach/AiCoachPrompts"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"


const cmdType = GlobalCommandType.continueWriting

export const continueWritingCommandDefinition: GlobalCommandDefinition<ContinueWritingCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ContinueWritingCommandArgs) => {

    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Continue Writing',
        searchName: 'continue writing',
        keyboardShortcutMac: ["cmd", "enter"],
        keyboardShortcutWin: ["ctrl", "enter"],
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ContinueWritingCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const prompt = buildPromptToContinueWritingDoc()
    // const params: OpenAIParamsType = { suffix }

    if (!services.servicesForCurrentDoc?.aiCoach.requestDocumentEdit) {
      console.error("Could not find aiCoach.requestDocumentEdit")
      return
    }

    await services.servicesForCurrentDoc?.aiCoach.requestDocumentEdit(
      {
        prompt,
        insertAt: InsertPosition.endOfCurrentNode,
        includeCompanyContext: true,
        includeDocDetails: true,
        includeDocMetadata: false,
        includeRelatedContext: false,
        isInline: true,
        textToAddBefore: " ",
        // openApiParams: params,
      }
    )

    onProcessingComplete(true)
  },
}

type ContinueWritingCommandArgs = {
}
