import { useCallback, useMemo } from "react";
import { SuspenseErrorBoundary } from "../../components/SuspenseErrorBoundary";
import { useAppServices } from "../../components/appServices/useAppServices";
import { MainViewWrapper } from "../../components/pageLayout/MainViewWrapper";
import { PageHeading } from "../../components/pageLayout/PageHeading";
import { PageLayoutSplitViewWrapper } from "../../components/pageLayout/pageLayoutSplitViewWrapper";
import { Tag, TagsState } from "../../state/TagsSlice";
import { DocTagsState } from "../../state/docTagsSlice";
import { useAppSelector } from "../../state/storeHooks";
import { SideMenu } from "../opportunityDocument/SideMenu";
import { Data } from "@verdi/shared-constants";
import { css } from "@emotion/react";
import { Badge } from "@chakra-ui/react";
import { DocumentReference } from "../../components/DocumentReference";
import { VerdiIconTag } from "../../components/icons/VerdiIcons";
import { showConfirmDialogCommandDefinition } from "../../components/commands/commandDefinitions/utils/showConfirmDialog";
import { IconButtonRemove } from "../../components/buttons/IconButtonRemove";
import { TagsProvider } from "../../state/TagsProvider";


export default function TagsPage() {

  const appServices = useAppServices()
  const {
    pageLayoutState,
    menuStructureProvider,
    opportunityProvider,
  } = appServices


  const allTags = useAppSelector(TagsState.getAll)
  const allDocTags = useAppSelector(DocTagsState.getAll)

  const tagInfos = useMemo(() => {
    const toReturn: TagInfo[] = []
    const flatMenuItems = menuStructureProvider.menuItemsStructured.flatMenuItems
    for (const tag of allTags) {
      const documents = allDocTags
        .filter(dt => dt.tagId === tag.id)
        .map(dt => {
          const menuItem = flatMenuItems.find(m => m.id === dt.docId)
          return {
            docTag: dt,
            menuItem,
          } as DocTagInfo
        })
      toReturn.push({
        tag,
        documents: documents,
      })
    }

    return toReturn
  }, [allTags, allDocTags, menuStructureProvider])


  const confirmDeleteTag = useCallback((tagInfo: TagInfo) => {

    const docCountMessage = tagInfo.documents.length > 0 ? ` ${tagInfo.documents.length} documents` : "any documents"

    showConfirmDialogCommandDefinition.triggerCommand?.({
      heading: "This action cannot be undone.",
      confirmButtonLabel: "Delete",
      dialogBodyText: `Delete this tag, including removing it from ${docCountMessage} and graphs?`,
      onConfirmSuccess: () => {
        console.log("delete tag", tagInfo)
        TagsProvider.removeTag(tagInfo.tag, true)
      },
      isDestructiveAction: true,
    })

  }, [])


  return (
    <PageLayoutSplitViewWrapper
      appServices={appServices}
      mainMenu={
        <SuspenseErrorBoundary>
          <SideMenu
            onBeforeNavigate={pageLayoutState.onBeforeNavigate}
            menuStructureProvider={menuStructureProvider}
            opportunityProvider={opportunityProvider}
          />
        </SuspenseErrorBoundary>
      }
      mainContent={
        <div>
          <MainViewWrapper>

            <PageHeading
              title="Tags"
              description=""
              iconLeft={<VerdiIconTag />}
            />

            <div css={css`
              display: flex;
              flex-direction: column;
              gap: 4px;
            `}>
              {tagInfos.map((tagInfo, key) =>
                <div key={key}
                  css={css`
                 `}
                >

                  <div css={css`
                    `}>
                    <span css={css`
                      display: inline-block;
                      border: 1px solid #99999944;
                      border-radius: 8px;
                      padding: 2px 4px;
                      margin-right: 4px;
                      font-size: 0.8em;
                      background-color: var(--surface-bg);
                    `}>
                      {tagInfo.tag.title}
                    </span>
                    {Boolean(tagInfo.documents.length) &&
                      <Badge>{tagInfo.documents.length}</Badge>
                    }
                    <IconButtonRemove
                      aria-label="Delete tag"
                      onClick={() => confirmDeleteTag(tagInfo)}
                    />
                  </div>

                  <div css={css`
                    margin-left: 20px;
                  `}>
                    {tagInfo.documents.map((docTagInfo, key) =>
                      <div key={key}>
                        {docTagInfo.menuItem
                          ? <DocumentReference
                            title={docTagInfo.menuItem?.title}
                            url={docTagInfo.menuItem?.url}
                          />
                          :
                          <div>
                            Broken reference
                          </div>
                        }

                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>


          </MainViewWrapper>

        </div>
      } />
  );
}

type TagInfo = {
  tag: Tag,
  documents: DocTagInfo[],
}

type DocTagInfo = {
  docTag: Data.DocumentTags.DocumentTagDto,
  menuItem: Data.Menu.MenuItemData,
}
