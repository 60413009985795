import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { ShowMiniCommandMenuCommandArgs, showMiniCommandMenuCommandDefinition } from "./showMiniCommandMenu"
import { DomPositionRect } from "../../../../screens/document/tiptapEditor/utils/getCurrentBlockContext"


const cmdType = GlobalCommandType.showMoreOptions

export const showMoreOptionsCommandDefinition: GlobalCommandDefinition<ShowMoreOptionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowMoreOptionsCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Show more suggestions ...',
        searchName: 'show more suggestions',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "navigate",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowMoreOptionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    let rectOfAnchorElement: DomPositionRect | undefined
    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (currentBlockContext) {
      rectOfAnchorElement = currentBlockContext.domPositionCaret || currentBlockContext.domPositionBlock || undefined
    }

    const cmState: ShowMiniCommandMenuCommandArgs = {
      commands: args.commands,
      title: "Suggested next steps",
      searchPlaceholderText: "Search ...",
      rectOfAnchorElement,
      shouldAdjustRectForScrollOffset: true,
    }

    // Show loading state of command menu
    // IDEA: Pass in the promise, so that it can cancel it?
    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      ...cmState,
    })

    onProcessingComplete(true)
  },
}

type ShowMoreOptionsCommandArgs = {
  commands: VerdiCommand[]
}
