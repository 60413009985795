import { Button, Link } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { DocumentTemplateSideMenuItem_documentTemplate$key } from "./__generated__/DocumentTemplateSideMenuItem_documentTemplate.graphql";
import { VerdiIconIsNotVisible, VerdiIconIsVisible } from "../../components/icons/VerdiIcons";


const fragmentQl = graphql`
fragment DocumentTemplateSideMenuItem_documentTemplate on DocumentTemplateInfo {
  id
  title
  isPublished
  document {
    title
    description
    id
    type
  }
}
`


type DocumentTemplateSideMenuItemProps = {
  item: DocumentTemplateSideMenuItem_documentTemplate$key
  index: number
  isSelected: boolean
  selectedMenuItemBackground: string
  selectedMenuItemColor: string
};

export function DocumentTemplateSideMenuItem({
  index,
  item: itemKey,
  isSelected,
  selectedMenuItemColor,
  selectedMenuItemBackground,
}: DocumentTemplateSideMenuItemProps) {

  const item = useFragment(fragmentQl, itemKey)


  return (
    <Link
      key={index}
      href={`/templates/documents/${item.document.id}`}
      css={css`
            &:hover{
              text-decoration: none;
              color: inherit;
            }
          `}
    >
      <Button
        css={css`
            display: flex;
            flex-direction: row;
            align-items: baseline;
            justify-content: start;
            height: auto;
            width: 100%;
            padding: 8px 16px;
            overflow: hidden;
          `}
        aria-selected={isSelected}
        _selected={{
          "color": selectedMenuItemColor,
          "backgroundColor": selectedMenuItemBackground
        }}
        _hover={{ bg: "#555" }}
        variant={item.isPublished ? "solid" : "ghost"}
        leftIcon={item.isPublished ? <VerdiIconIsVisible opacity={0.3} /> : <VerdiIconIsNotVisible opacity={0.3} />}
      >
        <div css={css`
              display: flex;
              flex-direction: column;
              align-items: baseline;
            `}>
          {item.title || "no template title"} <br />
          <small>
            {item.document.type} - {item.document.title || "no doc title"}
          </small>
        </div>
      </Button>
    </Link>
  );
}
