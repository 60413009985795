import { Data } from "@verdi/shared-constants";
import { VerdiCommand } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { deleteDocCommandDefinition } from "../commandDefinitions/document/deleteDoc";


export const getContextMenuCommandsForAssumption = (assumption: Data.AssumptionModel): VerdiCommand[] => {
  if (!assumption.mainDoc) return []

  const { id, title, type } = assumption.mainDoc
  return [
    // TODO: sub-menus don't seem to work yet
    // setAssumptionRelevanceShowOptionsCommandDefinition.buildMenuItem({assumption}),

    deleteDocCommandDefinition.buildMenuItem({
      docId: id,
      docTitle: title || "",
      docType: type,
      redirectAfterDelete: false,
    }),
  ] as VerdiCommand[]
}
