import graphql from 'babel-plugin-relay/macro'
import {
  useCreateOpportunityMutation,
  CreateOpportunityInput,
} from './__generated__/useCreateOpportunityMutation.graphql';
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation';
import { MenuStructureForAllDocsProvider } from '../document/organize/useGetMenuStructureForAllDocs';
import { CreateOpportunityResults } from './useOpportunityProvider';
import { dispatch } from '../../state/store';
import { makeGetRequestJson } from '../../utility-hooks/fetchUtils';
import { OpportunitiesState } from '../../state/opportunitiesSlice';


const mutationQL = graphql`
  mutation useCreateOpportunityMutation (
    $input: CreateOpportunityInput!
    $updateConnectionIds: [ID!]!
  ) {
   createOpportunity(input: $input) 
   @appendNode(connections: $updateConnectionIds, edgeTypeName: "OpportunityEdge") {
    id 
    title 
    description 
    status
    mainDoc {
      id
      parentDoc {
        id
      }
    }
   }    
  }
`
/** SHOULD ONLY BE USED BY useOpportunityProvider to ensure a single flow of the process. */
export function useCreateOpportunity() {


  const [commit, isSaving] = useErrorHandledMutation<useCreateOpportunityMutation>(mutationQL)
  const createOpportunity = useCallback((
    input: CreateOpportunityInput,
    menuStructureProvider: MenuStructureForAllDocsProvider,
    logUserActivity: () => void,
    onCreateComplete?: (results: CreateOpportunityResults) => void,
  ) => {
    commit({
      variables: {
        input,
        updateConnectionIds: [],
      },
      onCompleted: (response) => {
        console.log("useCreateOpportunityMutation onCompleted", response)

        const mainDocId = response.createOpportunity.mainDoc?.id
        if (mainDocId) {
          menuStructureProvider.onDocumentCreated(mainDocId)
            .then(async () => {
              console.log("Created opportunity complete ", { onCreateComplete })

              const opportunityId = response.createOpportunity.id
              const newOpportunity = await makeGetRequestJson(`opportunities/${opportunityId}`)
              dispatch(OpportunitiesState.addOpportunityPartial(newOpportunity))

              if (onCreateComplete) {
                const results: CreateOpportunityResults = {
                  newOpportunityId: opportunityId,
                  newMainDocId: mainDocId,
                }
                onCreateComplete(results)
              }
            })
        } else {
          console.warn("useCreateOpportunityMutation onCompleted: No mainDocId found in response", response)
        }

        logUserActivity()

      }
    })
  }, [commit])
  return [createOpportunity, isSaving] as const
}

