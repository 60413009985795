import { PageLayoutSplitViewWrapper } from "../../../components/pageLayout/pageLayoutSplitViewWrapper";
import { useAppServices } from "../../../components/appServices/useAppServices";
import { useEffect } from "react";
import { CurrentUserEditor } from "./currentUser/CurrentUserEditor";
import { PageHeading } from "../../../components/pageLayout/PageHeading";
import { SuspenseErrorBoundary } from "../../../components/SuspenseErrorBoundary";
import { SideMenu } from "../../opportunityDocument/SideMenu";
import { MainViewWrapper } from "../../../components/pageLayout/MainViewWrapper";
import { SectionWrapper } from "../../../components/pageLayout/SectionWrapper";
import { VerdiIconUserProfile } from "../../../components/icons/VerdiIcons";


export default function UserProfilePage() {

  const appServices = useAppServices()
  const {
    pageLayoutState,
    onboardingManager,
    loggedInUserProvider,
    menuStructureProvider,
    opportunityProvider,
  } = appServices


  useEffect(() => {
    pageLayoutState.setRightSideState("hidden")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <PageLayoutSplitViewWrapper
      appServices={appServices}
      mainMenu={
        <SuspenseErrorBoundary>
          <SideMenu
            onBeforeNavigate={pageLayoutState.onBeforeNavigate}
            menuStructureProvider={menuStructureProvider}
            opportunityProvider={opportunityProvider}
          />
        </SuspenseErrorBoundary>
      }
      mainContent={
        <div>
          <MainViewWrapper>

            <PageHeading
              title="My Profile"
              description=""
              iconLeft={<VerdiIconUserProfile />}
            />

            {loggedInUserProvider.loggedInUser &&
              <SectionWrapper>
                <CurrentUserEditor
                  currentUser={loggedInUserProvider.loggedInUser}
                  onboardingManager={onboardingManager}
                />

              </SectionWrapper>
            }

          </MainViewWrapper>

        </div>
      } />
  );
}
