import { NodeViewProps, NodeViewWrapper, NodeViewContent } from "@tiptap/react";
import { useEffect, useState } from "react";
import { useReactNodeViewSnippet } from "../../nodeViewData/useReactNodeViewSnippet";
import { Fragment, Slice } from "@tiptap/pm/model";
import { ProsemirrorNodes } from "@verdi/shared-constants";
import { useUpdateSnippet } from "../../../../snippet/useUpdateSnippet";
import { VerdiIconDelete, VerdiIconRefresh } from "../../../../../components/icons/VerdiIcons";


type Props = NodeViewProps;

type VisibleState = "textValue" | "placeholderKey";

type InlineSnippetMenuProps = {
  onToggleVisibleState: () => void;
  onSyncValue: () => void;
  removeSnippet: () => void;
};

const InlineSnippetMenu = ({
  onToggleVisibleState,
  onSyncValue,
  removeSnippet,
}: InlineSnippetMenuProps) => {
  return (
    <div className="inlineSnippetMenu" contentEditable={false}>
      <button onClick={onToggleVisibleState} title="Toggle placeholder view">
        <strong>{"{{}}"}</strong>
      </button>
      <button onClick={onSyncValue} title="Update to latest sync'd value">
        <VerdiIconRefresh />
      </button>
      <button onClick={removeSnippet} title="Remove sync">
        <VerdiIconDelete />
      </button>
    </div>
  );
};

export const InlineSnippetComponent = (props: Props) => {
  const [visibleState, setVisibleState] = useState<VisibleState>("textValue");
  const { snippet } = useReactNodeViewSnippet({
    snippetId: props.node.attrs.snippetId,
  });
  const outOfDate =
    snippet?.textValue && props.node.textContent !== snippet.textValue;
  const isFocused =
    props.editor.state.selection.$from.pos >= props.getPos() &&
    props.editor.state.selection.$to.pos <=
    props.getPos() + props.node.nodeSize;
  const [update, isSaving] = useUpdateSnippet();

  useEffect(() => {
    if (!outOfDate) return;

    update({
      id: snippet.id,
      textValue: props.node.textContent,
      placeholderKey: snippet.placeholderKey, // TODO: Do not update placeholder
    });
  }, [props.node.textContent, outOfDate, snippet]);

  return (
    <NodeViewWrapper as="span" data-snippet-id={props.node.attrs.snippetId}>
      {outOfDate && !isSaving && <div>🏴‍☠️☠️</div>}
      {isFocused && (
        <InlineSnippetMenu
          onToggleVisibleState={() => {
            setVisibleState((visibleState) =>
              visibleState === "textValue" ? "placeholderKey" : "textValue"
            );
          }}
          removeSnippet={() => {
            const state = props.editor.state;
            const pos = props.getPos();
            const newTextBlockJson = ProsemirrorNodes.makeFreeTextBlock(
              props.node.textContent,
              undefined,
              undefined
            );
            const fragment = Fragment.fromJSON(state.schema, [
              newTextBlockJson,
            ]);
            const transaction = state.tr.replace(
              pos,
              pos + props.node.nodeSize,
              new Slice(fragment, 0, 0)
            );
            props.editor.view.dispatch(transaction);
          }}
          onSyncValue={() => {
            if (!snippet) return;
            const state = props.editor.state;
            const pos = props.getPos();

            const insertSnippet = ProsemirrorNodes.makeInlineSnippet(
              snippet?.textValue,
              {
                snippetId: snippet.id,
                placeholderKey: snippet.placeholderKey,
              }
            );
            const fragment = Fragment.fromJSON(state.schema, [insertSnippet]);
            const transaction = state.tr.replace(
              pos,
              pos + props.node.nodeSize,
              new Slice(fragment, 0, 0)
            );
            props.editor.view.dispatch(transaction);
          }}
        />
      )}
      {visibleState === "textValue" ? (
        <NodeViewContent as="span" className="content" />
      ) : (
        `{{ ${props.node.attrs.placeholderKey} }}`
      )}
    </NodeViewWrapper>
  );
};
