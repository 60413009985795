
export const menuBackgroundColors = {
  light: '#f9f9f9', dark: 'black'
}
export const documentBackgroundColors = {
  light: '#FFF', dark: '#121217'
}
export const menuBorderColors = {
  light: '#DFE1E4', dark: '#38394B'
}
export const menuItemSelectedBackgroundColors = {
  light: "#7addfc70", dark: "#3edafe38"
}
export const menuItemSelectedColors = {
  light: "#568282", dark: "#bfeeee"
}
export const CommandMenuBackgroundColors = {
  light: '#FFF', dark: '#333'
}

export const selectedNodeStyles = {
  light: {
    bg: '#00005509',
    border: '#00005511'
  },
  dark: {
    bg: '#FFFFFF22',
    border: 'transparent'
  }
}

export const modalBackgroundColors = {
  light: '#FFF', dark: '#222'
}
export const modalBorderColors = {
  light: '#99999966', dark: '#99999966'
}

export const sideMenuItemStyles = {
  light: {
    selected: {
      bg: `var(--chakra-colors-verdiButtonSecondaryHighlighted-500)`,
      borderColor: `var(--chakra-colors-verdiButtonSecondaryHighlighted-500)`,
    }

  },
  dark: {
    selected: {
      bg: `var(--chakra-colors-verdiButtonSecondaryHighlighted-200)`,
      borderColor: `var(--chakra-colors-verdiButtonSecondaryHighlighted-200)`,
    }
  }
}

export const chatMessageStyles = {
  light: {
    message: {
      user: {
        bg: `var(--chakra-colors-verdiAiAction-50)`,
        borderColor: `var(--chakra-colors-verdiAiAction-100)`,
      },
      ai: {
        bg: `var(--chakra-colors-verdiUserAction-50)`,
        borderColor: `var(--chakra-colors-verdiUserAction-100)`,
      }
    }

  },
  dark: {
    message: {
      user: {
        bg: `#171A64`,
        borderColor: `#282da0`,
      },
      ai: {
        bg: `transparent`,
        borderColor: `transparent`,
      }
    }
  }
}


// export const getCssVar = (
//   scheme: "user" | "ai",
//   isLightMode: boolean,
//   colorIntensity: 50 | 100 | 200 | 300 | 400
// ) => {

//   const colorScheme = scheme === "user" ? "verdiUserAction" : "verdiAiAction"
//   const colorNumber = isLightMode ? colorIntensity : 900 - colorIntensity
//   return `var(--chakra-colors-${colorScheme}-${colorNumber})`
// }
