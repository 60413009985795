import { IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react"
import DeleteDocumentButton from "./DeleteDocumentButton"
import { VisibilityToggleButton } from "../../components/VisibilityToggleButton"
import { DocumentSettingsEditor_document$data } from "./__generated__/DocumentSettingsEditor_document.graphql"
import { css } from "@emotion/react"
import { VerdiIconShowMore } from "../../components/icons/VerdiIcons"
import { DocumentSchema } from "@verdi/shared-constants"


type Props = {
  loggedInUserId: string | undefined
  opportunityId: string | undefined
  isMainDocForAnOpportunity: boolean
  document: DocumentSettingsEditor_document$data
  redirectUrlOnDelete: string
  onVisibilityToggle: (isNowVisible: boolean) => void
}
export const DocumentSettingsEditorMenu = (
  {
    loggedInUserId,
    opportunityId,
    isMainDocForAnOpportunity,
    document,
    redirectUrlOnDelete,
    onVisibilityToggle,
  }: Props) => {


  return (
    <Menu placement="left-start">
      <MenuButton
        as={IconButton}
        aria-label='Show document options'
        icon={<VerdiIconShowMore />}
        variant='ghost'
      />

      <MenuList
        textAlign="left"
        minWidth={150}
        display="flex"
        flexDirection="column"
        alignItems="baseline"
      >
        {document.createdByUser &&
          <div css={css`
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 200px;
                padding: 0 12px;
                font-size: small;
                cursor: default;
              `}>
            <small>Created by </small><br />
            <span>
              {document.createdByUser.name || document.createdByUser.email}
            </span>
          </div>

        }

        <VisibilityToggleButton
          canToggle={!document.createdByUser || document.createdByUser.id === loggedInUserId}
          isVisible={document.isVisibleToOrg}
          buttonTitle={document.isVisibleToOrg ? "Visible to my org" : "Visible only to me"}
          tooltipText={document.isVisibleToOrg ? "Visible to others within your org" : "Only you can see this doc"}
          onToggle={(isNowVisible) => onVisibilityToggle(isNowVisible)}
        />

        {/* <MoveDocumentButton
          documentId={document.id}
        /> */}

        <DeleteDocumentButton
          buttonTitle="Delete document"
          opportunityId={opportunityId}
          documentId={document.id}
          documentTitle={document.title || ""}
          documentType={document.type as DocumentSchema.DocumentType}
          redirectUrl={redirectUrlOnDelete}
          isMainDocForAnOpportunity={isMainDocForAnOpportunity}
        />

      </MenuList>
    </Menu>
  )
}
