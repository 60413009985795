import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { getAllPlaceholderNodes } from "../../../../../ai/documents/getAllPlaceholderNodes"


const cmdType = GlobalCommandType.loadPlaceholderSuggestions

/** for a given document, pre-loads placeholder suggestions for any placeholders on the doc. */
export const loadPlaceholderSuggestionsCommandDefinition: GlobalCommandDefinition<LoadPlaceholderSuggestionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: LoadPlaceholderSuggestionsCommandArgs) => {
    const title = "Load all placeholder suggestions"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        shouldKeepOpenOnRun: true,
        iconType: "debug",
      } as VerdiCommand

    return toReturn
  },
  triggerCommand: (args: LoadPlaceholderSuggestionsCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  },
  processCommand: async (
    args: LoadPlaceholderSuggestionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor || !editor.state.doc) {
      console.warn('loadPlaceholderSuggestions: No editor doc state found in servicesForCurrentDoc')
      onProcessingComplete(false)
      return
    }


    //IDEA: Load only the first 2 or so at a time?
    // We have to re-un anyways as the document changes


    // Load all placeholder nodes from the document
    const placeholderNodes = getAllPlaceholderNodes(editor?.state.doc)
    console.log("loadPlaceholderSuggestions:", { placeholderNodes })




    onProcessingComplete(true)
  },
}

type LoadPlaceholderSuggestionsCommandArgs = {

}
