import { Audience } from "../../state/suggestions/AudiencePerspectivesSlice"


export const getTargetAudientContext = (currentAudience?: Audience) => {

  if (!currentAudience) {
    return "TARGET AUDIENCE: everyone"
  }

  return `
TARGET AUDIENCE: ${currentAudience?.title || "everyone"}
TYPICAL QUESTION THE TARGET AUDIENCE WOULD ASK:
${currentAudience?.typicalQuestionsToAsk.join("\n") || ""}`
}
