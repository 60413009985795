import { useCallback, useEffect, useMemo } from 'react';
import { MenuStructureForAllDocsProvider } from '../document/organize/useGetMenuStructureForAllDocs';
import { CreateOpportunityInput } from './__generated__/useCreateOpportunityMutation.graphql';
import { useCreateOpportunity } from './useCreateOpportunity';
import { getNearestOpportunity } from '../document/organize/getNearestOpportunity';
import { RouterState } from 'found';
import { getDocBodySnapshotFromCache } from '../../state/loaders/getDocBodySnapshotFromCache';
import { AssumptionSummary, AssumptionsProvider } from '../assumptions/useAssumptionsProvider';
import { OnboardingManager } from '../onboarding/useOnboardingManager';
import { dispatch, getCurrentAppState } from '../../state/store';
import { setAssumptions } from '../../state/aiSlice';
import { DocumentFrameworksProvider } from '../documentFrameworks/DocumentFrameworksProvider';
import { documentFrameworkState } from '../../state/documentFrameworksSlice';
import { ApiTypes, Data, Menu } from '@verdi/shared-constants';
import { makePostRequestJson } from '../../utility-hooks/fetchUtils';
import { OpportunitiesState } from '../../state/opportunitiesSlice';
import { DocBodySnapshotsState } from '../../state/docBodySnapshotsSlice';


export function useOpportunityProvider(
  menuStructureProvider: MenuStructureForAllDocsProvider,
  router: RouterState<any>,
  assumptionsProvider: AssumptionsProvider,
  onboardingManager: OnboardingManager,
) {


  const [create] = useCreateOpportunity()
  const createOpportunity = useCallback((
    input: CreateOpportunityInput,
    onCreateComplete?: (results: CreateOpportunityResults) => void,
  ) => {
    const logUserActivity = () => {
      onboardingManager.updateProgress("createdOpportunityAt")
    }
    create(input, menuStructureProvider, logUserActivity, onCreateComplete)
  }, [create, menuStructureProvider, onboardingManager])


  /** Returns the current opportunity (if any) whenever a documentId is in the current url */
  const currentOpportunityDoc = useMemo(() => {
    const currentDocId = router.match.params["documentId"]
    if (!currentDocId) return undefined
    const doc = menuStructureProvider.getMenuItem(currentDocId)
    if (!doc) return undefined
    const parentOpportunity = getNearestOpportunity(doc, menuStructureProvider.getMenuItem)
    return parentOpportunity
  }, [router.match.params, menuStructureProvider])

  /** Stores Parent Opportunity "lineage" of the current Opportunity, if any.
   * 
   *  Example: [Parent Opportunity, Grandparent Opportunity, Great-Grandparent Opportunity, ...]
   */
  const parentOpportunities = useMemo(() => {
    if (!currentOpportunityDoc) return []
    return Menu.getMenuItemHierarchy(currentOpportunityDoc, menuStructureProvider.menuItemsStructured.flatMenuItems)
  }, [currentOpportunityDoc, menuStructureProvider.menuItemsStructured.flatMenuItems])


  const updateOpportunity = useCallback(async (id: string, fields: Partial<Data.Opportunities.OpportunityMutableFields>) => {
    dispatch(OpportunitiesState.updateOpportunityPartial({ id, fields }))
    const body: ApiTypes.UpdateOpportunityRequestBody = {
      opportunityId: id,
      fields,
    }
    makePostRequestJson("opportunities/updateOpportunity", body)
  }, [])


  const getOpportunityContextForAi = useCallback(async (): Promise<OpportunityContextForAi | undefined> => {
    if (!currentOpportunityDoc) return undefined

    const { id, title, description } = currentOpportunityDoc

    const snapshot = await getDocBodySnapshotFromCache(id)
    const bodyPlainText = snapshot ? snapshot.bodyPlainText : ""

    const assumptions = await assumptionsProvider.getAssumptionsContextForAi(id)

    const docFramework = getCurrentAppState().documentFrameworks.currentOpportunityFramework
    const framework = docFramework ? getCurrentAppState().frameworks.frameworks.find(f => f.id === docFramework.frameworkId) : undefined

    return {
      title,
      description,
      bodyPlainText,
      opportunityMainDocId: id,
      assumptions: assumptions.assumptionSummaries,
      framework,
      parentOpportunities,
    }

  }, [currentOpportunityDoc, assumptionsProvider])


  /** Whenever the current opportunityDoc changes, this loads additional data */
  const loadCurrentOpportunitySupportingData = useCallback(async () => {

    if (!currentOpportunityDoc) {
      dispatch(setAssumptions([]))
      dispatch(documentFrameworkState.setCurrentOpportunityFramework())
      return
    }
    assumptionsProvider.getAssumptionsContextForAi(currentOpportunityDoc?.id || "")
      .then((assumptionsContext) => {
        dispatch(setAssumptions(assumptionsContext.assumptionSummaries))
        dispatch(DocBodySnapshotsState.addOrUpdateMany(assumptionsContext.docBodySnapshots))
      })

    DocumentFrameworksProvider.ensureAllAreLoaded()
      .then(() => {
        // set the current opportunity framework
        const currentFramework = getCurrentAppState().documentFrameworks.allDocumentFrameworks.find(df => df.documentId === currentOpportunityDoc?.id)
        dispatch(documentFrameworkState.setCurrentOpportunityFramework(currentFramework))
      })

  }, [currentOpportunityDoc, assumptionsProvider])


  useEffect(() => {
    loadCurrentOpportunitySupportingData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpportunityDoc?.id])


  return {
    createOpportunity,
    updateOpportunity,
    currentOpportunityDoc,
    getOpportunityContextForAi,
  }

}

export type OpportunityProvider = ReturnType<typeof useOpportunityProvider>


export type OpportunityContextForAi = {
  title?: string
  description?: string
  bodyPlainText?: string
  opportunityMainDocId: string
  assumptions: AssumptionSummary[]
  framework?: Data.FrameworkModel
  parentOpportunities: Menu.MenuItemData[]
}

export type CreateOpportunityResults = {
  newOpportunityId: string,
  newMainDocId?: string,
}
