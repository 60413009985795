import { useCallback } from "react"
import { CurrentBlockContext, getPosAtEndOfCurrentNode } from "../../screens/document/tiptapEditor/utils/getCurrentBlockContext"
import { Editor } from "@tiptap/core"
import { DocumentContextForAi, EmptyDocumentContextForAi } from "../coach/AiCoachPrompts"
import { getVersion } from "../../screens/document/tiptapEditor/prosemirror-verdi-collab"
import { addEmptyChildIfThereAreNone } from "../../screens/document/tiptapEditor/utils/addEmptyChildIfThereAreNone"
import { InsertPosition } from "./InsertPosition"
import { EditorState } from "prosemirror-state"
import { DocumentRelationsProvider } from "../../screens/documentRelation/useDocumentRelationsProvider"
import { BasicDocumentDetails } from "../../screens/document/BasicDocumentDetails"
import { DocumentSchema } from "@verdi/shared-constants"
import { getDocSummaryOfNodes, getSectionTitles } from "../../screens/document/tiptapEditor/utils/getDocSummaryOfNodes"
import { DocMetadataSummary, DocUpdatedEvent } from "./DocUpdatedEvent"
import { BodyAsStringParts, getDocAsIndentedMarkdown } from "./getDocAsIndentedMarkdown"
import { getNextRootNodeInsertPosition } from "../../screens/document/tiptapEditor/utils/getNextRootNodeInsertPosition"
import { dispatch, getCurrentAppState } from "../../state/store"
import { documentFrameworkState } from "../../state/documentFrameworksSlice"
import { FrameworksState } from "../../state/frameworksSlice"
import { DocBodySnapshotsState } from "../../state/docBodySnapshotsSlice"
import { DocMetadataState } from "../../state/docMetadataSlice"


type Props = {
  document: BasicDocumentDetails
  editorRef: React.MutableRefObject<Editor | null | undefined>
  currentBlockContextRef: React.MutableRefObject<CurrentBlockContext | undefined>
  documentRelationsProvider: DocumentRelationsProvider
}
/** Connects the Tip Tap document to other app services, such as the AiCoach
 * 
 *  Think of this as a boundary that abstracts away many details of the Tip Tap editor
 */
export const useAiTipTapBridge = ({
  document,
  editorRef,
  currentBlockContextRef,
  documentRelationsProvider,
}: Props) => {

  /** Allows direct access to the prosemirror editor instance */
  const getEditor = useCallback(() => {
    return editorRef.current
  }, [editorRef])

  /** Gets many details about the current document */
  const getDocumentContextForAi = useCallback((include?: DocContextIncludeArgs) => {
    if (!editorRef.current) {
      return EmptyDocumentContextForAi
    }
    const state = editorRef.current.state

    const { to } = state.selection
    const endOfNodePosition = getPosAtEndOfCurrentNode(state, to)

    const bodyText = DocumentSchema.getPlainTextFromDocumentNode(state.doc) || ""
    const bodyBeforeSelection = state.doc.textBetween(0, endOfNodePosition, '\n') || ""
    const bodyAfterSelection = state.doc.textBetween(endOfNodePosition, state.doc.nodeSize - 2, '\n') || ""

    const nextRootNodeInsertPosition = getNextRootNodeInsertPosition(state, to)

    let bodyAsMarkdown: BodyAsStringParts | undefined
    if (include?.bodyAs) {
      const insertPosition = include?.bodyAs === "indented-markdown-with-caret-position" ? endOfNodePosition : -1
      bodyAsMarkdown = getDocAsIndentedMarkdown(state.doc, insertPosition)
    }

    const endOfDocInsertPoints = state.doc.content.size

    const context: DocumentContextForAi = {
      id: document.id,
      title: document.title,
      type: document.type as DocumentSchema.DocumentType,
      description: document.description,
      body: bodyText,
      bodyBeforeSelection,
      bodyAfterSelection,
      bodyPartsAsMarkdown: bodyAsMarkdown,
      documentVersion: getVersion(state),
      rangeAtEndOfDocument: { from: endOfDocInsertPoints, to: endOfDocInsertPoints },
      rangeAtCurrentSelection: { from: state.selection.from, to: state.selection.to },
      rangeAtEndOfCurrentNode: endOfNodePosition ? { from: endOfNodePosition, to: endOfNodePosition } : undefined,
      rangeAtNextRootNodeInsert: { from: nextRootNodeInsertPosition, to: nextRootNodeInsertPosition },
      relatedDocs: documentRelationsProvider.getRelationsForDoc(document.id),
      childDocs: documentRelationsProvider.getChildrenForDoc(document.id),
      currentPlaceholderAttrs: currentBlockContextRef.current?.currentPlaceholderAttrs,
      currentLineText: currentBlockContextRef.current?.currentLineText,
    }

    if (include?.sectionTitles) {
      context.sectionTitles = getSectionTitles(state.doc)
    }

    const currentBlock = currentBlockContextRef.current
    if (currentBlock) {
      context.selectedText = currentBlock.currentSelectText
      context.parentSectionInfo = currentBlock.parentSectionInfo
      context.nearestNestableNodeType = currentBlock.nearestNestableNodeType || undefined

      if (currentBlock.aiPromptId) {
        context.aiPromptId = currentBlock.aiPromptId
        context.aiPromptInsertRange = currentBlock.aiPromptInsertRange
      }
    }

    // Add framework specific details
    const docFramework = documentFrameworkState.getCurrentDocumentFramework(getCurrentAppState())
    if (docFramework?.frameworkId) {
      const framework = FrameworksState.getFrameworkById(getCurrentAppState(), docFramework.frameworkId)
      context.framework = framework
    }

    return context
  }, [document, editorRef, currentBlockContextRef, documentRelationsProvider])


  const adjustInsertPositionAfterSectionHeading = useCallback(() => {

    console.log("adjustInsertPositionAfterSectionHeading() currentBlockContext = ", currentBlockContextRef.current)

    const editor = editorRef.current
    const state = editor?.state
    if (!state) {
      console.error("adjustInsertPositionForSectionHeading() editorRef.current?.state is undefined")
      return {
        childWasInserted: false,
        updatedInsertPosition: undefined,
      }
    }

    const childWasInserted = addEmptyChildIfThereAreNone(state, editor.chain, true)
    if (!childWasInserted) {
      return {
        childWasInserted,
        updatedInsertPosition: undefined,
      }
    }

    return {
      childWasInserted,
      updatedInsertPosition: resolveInsertPosition(state, InsertPosition.endOfCurrentNode),
    }

  }, [editorRef, currentBlockContextRef])

  const getCurrentBlockContext = useCallback(() => {
    return currentBlockContextRef.current
  }, [currentBlockContextRef])

  const onDocBodyUpdated = useCallback((
    editorState: EditorState,
  ) => {

    const lengthOfBody = editorState.doc.content.size
    const bodyHasText = Boolean(editorState.doc.textContent.trim())
    const summaryOfNodes = getDocSummaryOfNodes(editorState.doc)

    const current: DocMetadataSummary = {
      documentId: document.id,
      bodyHasText,
      lengthOfBody,
      ...summaryOfNodes,
      timeStamp: new Date().toISOString(),
    }

    // Clear snapshot cache. It should get resolved the next time it is needed
    dispatch(DocBodySnapshotsState.remove({ id: document.id }))

    const previous = DocMetadataState.getSummary(getCurrentAppState())
    dispatch(DocMetadataState.setSummary(current))

    DocUpdatedEvent.triggerDocUpdated({ current, previous })

  }, [document.id])


  return {
    getEditor,
    getDocumentContextForAi,
    getCurrentBlockContext,
    adjustInsertPositionAfterSectionHeading,
    onDocBodyUpdated,
  }
}
export type UseAiTipTapBridge = ReturnType<typeof useAiTipTapBridge>


export type DocContextIncludeArgs = {
  sectionTitles?: boolean
  bodyAs?: "text" | "indented-markdown" | "indented-markdown-with-caret-position"
}

const resolveInsertPosition = (state: EditorState, insertAt: InsertPosition) => {

  switch (insertAt) {

    case InsertPosition.currentCursorSelection: {
      const { from, to } = state.selection
      return { from, to }
    }

    case InsertPosition.endOfCurrentNode: {
      const endOfNodePosition = getPosAtEndOfCurrentNode(state, state.selection.to)
      if (endOfNodePosition) {
        return { from: endOfNodePosition, to: endOfNodePosition }
      }
    }
  }

  // fallback to end of document
  const endOfDocInsertPoints = state.doc.content.size
  return { from: endOfDocInsertPoints, to: endOfDocInsertPoints }

}
