const w = window as any;

export const posthogCallback = (fn: ((...args: any[]) => any) | undefined, ...postHogArgs: any[]) => {
  return (...args: any[]) => {
    w.posthog.capture(...postHogArgs);
    
    fn?.(...args);
  }
}

export default w.posthog;