import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Data } from '@verdi/shared-constants'


export interface OnboardingStatusStateForOrg {
  hasLoaded: boolean,
  hasCreatedACompanyProfile?: boolean,
}
export interface OnboardingStatusStateForUser extends Data.Onboarding.OnboardingProgressMutableFields {
  hasLoaded: boolean,
}

export interface OnboardingStatusState {
  currentOrg: OnboardingStatusStateForOrg,
  currentUser: OnboardingStatusStateForUser,
}

const initialState: OnboardingStatusState = {
  currentOrg: {
    hasLoaded: false,
  },
  currentUser: {
    hasLoaded: false,
  }
}

export const onboardingStatusSlice = createSlice({
  name: 'onboardingStatus',
  initialState,
  reducers: {
    setOnboardingStatusForOrg: (state, action: PayloadAction<OnboardingStatusStateForOrg>) => {
      state.currentOrg = action.payload
    },
    setOnboardingStatusForUser: (state, action: PayloadAction<OnboardingStatusStateForUser>) => {
      state.currentUser = action.payload
    },
    updateOnboardingProgressForUser: (state, action: PayloadAction<keyof Data.Onboarding.OnboardingProgressMutableFields>) => {
      const now = new Date().toISOString()
      state.currentUser = { ...state.currentUser, [action.payload]: now }
    }
  },
})

export const {
  setOnboardingStatusForOrg,
  setOnboardingStatusForUser,
  updateOnboardingProgressForUser,
} = onboardingStatusSlice.actions


export default onboardingStatusSlice.reducer
