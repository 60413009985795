import { Button } from "@chakra-ui/react"
import { ChatButtonClickArgs } from "./useChatButtonProcessor"
import { GlobalCommandType } from "../commands/GlobalCommandType"
import { useState } from "react"
import { ButtonPrimary } from "../buttons/ButtonPrimary"


type Props = {
  title: string
  onClick: (args: ChatButtonClickArgs) => void
  commandType?: GlobalCommandType
  functionArguments?: object
}
export const ChatButton = (props: Props) => {

  const { title, commandType, functionArguments, onClick } = props
  const [hasBeenClicked, setHasBeenClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  return (
    <ButtonPrimary
      label={title}
      size="xs"
      marginRight={2}
      marginBottom={2}
      padding={1}
      height="auto"
      whiteSpace="break-spaces"
      opacity={hasBeenClicked ? 0.2 : 1}
      isLoading={isLoading}
      onClick={() => {
        setHasBeenClicked(true)
        onClick({
          buttonText: title,
          commandType,
          functionArguments,
        })
        if (commandType === GlobalCommandType.createQuickNote) {
          setIsLoading(true)
          setTimeout(() => setIsLoading(false), 3000)
        }
      }}
    />
  )
}
