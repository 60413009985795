import { useCallback, useState } from "react";
import { Select, Input } from "@chakra-ui/react";
import { css } from "@emotion/react";

import { DocServicesContext } from "../../../screens/document/docServices/useRegisterDocServices";
import { docBodyMarkCreateCommandDefinition } from "../../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkCreate";
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary";
import { docBodyMarkClearAllCommandDefinition } from "../../../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearAll";
import { DocumentSchema } from "@verdi/shared-constants";


type Props = {
  docServices: DocServicesContext;
};

const formGroupStyles = css`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
`;

const formControlStyles = css`
  margin-bottom: 8px;
`;

function MarkDebugger({ docServices }: Props) {
  const [markType, setMarkType] = useState();
  const [markStrategy, setMarkStrategy] = useState("range");
  const [markConfig, setMarkConfig] = useState<{
    from?: string;
    to?: string;
    link?: string;
    search?: string;
  }>({});

  const handleMarkTypeChange = useCallback(
    (e: any) => setMarkType(e.target.value),
    []
  );

  const handleStrategyChange = useCallback(
    (e: any) => setMarkStrategy(e.target.value),
    []
  );

  const handleMarkConfigChange = useCallback(
    (key: string) => (e: any) => {
      setMarkConfig((prev) => ({ ...prev, [key]: e.target.value }));
    },
    []
  );

  // const handleCreateMarkPress = useCallback(() => {
  //   const { state: editorState } = docServices.editor;

  //   const mark =
  //     editorState.schema.marks[markType || "KnowledgeGraph"].create(markConfig);

  //   if (markStrategy === "search") {
  //     docServices.editor.commands.setVerdiSearchMark(
  //       markConfig.search || "",
  //       mark
  //     );

  //     return;
  //   }

  //   if (markStrategy === "range") {
  //     docServices.editor.commands.setVerdiMark(
  //       parseInt(markConfig.from || "0"),
  //       parseInt(markConfig.to || "0"),
  //       mark
  //     );

  //     return;
  //   }

  //   console.warn("unknown mark strategy");
  // }, [markType, markConfig, markStrategy, docServices]);


  // const handleClearMarksPress = useCallback(() => {
  //   const { state: editorState } = docServices.editor;

  //   docServices.editor.commands.clearVerdiMarks([
  //     editorState.schema.marks.KnowledgeGraph,
  //     editorState.schema.marks.AiSuggestion,
  //   ]);
  // }, [docServices]);


  const triggerCreateMarkCommand = useCallback(() => {

    const from = parseInt(markConfig.from || "0")
    const to = parseInt(markConfig.to || "0")

    const commands: DocumentSchema.VerdiMarks.AiSuggestionMarkCommand[] = markType === "AiSuggestion" ? [
      { commandType: "gap", title: `Fill in the gap ` },
      { commandType: "longer", title: `Make this longer ` }
    ] : [
      { commandType: "replace", title: `Not sure what this placeholder will do yet` }
    ]

    docBodyMarkCreateCommandDefinition.triggerCommand?.({
      markStrategy: markStrategy as any,
      markType: markType as any,
      textToHighlight: markConfig.search,
      range: from && to ? { from, to } : undefined,
      attributes: {
        suggestionText: "Instructional text for the user",
        commands,
      }
    })
  }, [markStrategy, markType, markConfig]);



  const triggerClearMarksCommand = useCallback(() => {
    docBodyMarkClearAllCommandDefinition.triggerCommand?.({})
  }, []);


  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        <h3>Marks Utility</h3>
      </div>

      <div css={formGroupStyles}>
        <div css={formControlStyles}>
          {/* <Button onClick={handleClearMarksPress}>Clear Marks</Button> */}
          <ButtonPrimary
            label="Clear Marks CMDDef"
            onClick={triggerClearMarksCommand}
          />
        </div>
      </div>

      <div
        css={css`
          ${formGroupStyles}
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        `}
      >
        <div css={formControlStyles}>
          <Select
            placeholder="Select a mark type..."
            value={markType}
            onChange={handleMarkTypeChange}
          >
            <option value="KnowledgeGraph">Knowledge Graph Mark</option>
            <option value="AiSuggestion">AI Suggestion Mark</option>
            <option value="PlaceholderInline">Placeholder MARK Inline</option>

          </Select>
        </div>
      </div>

      {!!markType && (
        <div css={formGroupStyles}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <Select value={markStrategy} onChange={handleStrategyChange}>
              <option value="range">Range</option>
              <option value="search">Search</option>
            </Select>
          </div>
        </div>
      )}

      {!!markType && markStrategy === "range" && (
        <div css={formGroupStyles}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <div css={formControlStyles}>
              <Input
                placeholder="from"
                type="number"
                value={markConfig.from || ""}
                onChange={handleMarkConfigChange("from")}
              />
            </div>

            <div css={formControlStyles}>
              <Input
                placeholder="to"
                type="number"
                value={markConfig.to || ""}
                onChange={handleMarkConfigChange("to")}
              />
            </div>
          </div>
        </div>
      )}

      {!!markType && markStrategy === "search" && (
        <div css={formGroupStyles}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <div css={formControlStyles}>
              <Input
                placeholder="Search"
                value={markConfig.search || ""}
                onChange={handleMarkConfigChange("search")}
              />
            </div>
          </div>
        </div>
      )}

      {markType === "KnowledgeGraph" && (
        <div css={formGroupStyles}>
          <div css={formControlStyles}>
            <Input
              placeholder="Link"
              value={markConfig.link || ""}
              onChange={handleMarkConfigChange("link")}
            />
          </div>
        </div>
      )}

      {!!markType && (
        <div css={formGroupStyles}>
          <div css={formControlStyles}>
            {/* <Button onClick={handleCreateMarkPress}>Create Mark</Button> */}
            <ButtonPrimary
              label="Create Mark CMDDef"
              onClick={triggerCreateMarkCommand}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MarkDebugger;
