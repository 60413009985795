import { useKeyDownHandler } from "../../floatingBlockContext/useKeyDownHandler"
import { triggerGlobalCommand } from "../triggerGlobalCommand"
import { allKeyboardShortcuts, getCommandTypeFromKeyboardShortcut } from "./allKeyboardShortcuts"

export const useGlobalShortcutKeyListener = () => {

  useKeyDownHandler((event: KeyboardEvent) => {

    const commandToRun = getCommandTypeFromKeyboardShortcut(event)

    if (commandToRun) {
      event.preventDefault()
      event.stopPropagation()
      triggerGlobalCommand(commandToRun)
    }
  })


}
