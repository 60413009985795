

export type DocumentRelationDto = {
  id: string,
  fromDocId: string,
  toDocId: string,
  type: DocumentRelationType,
}

export enum DocumentRelationType {
  relatedTo = "relatedTo", // Default
  actionOf = "actionOf",
  insightOf = "insightOf",
  assumptionOf = "assumptionOf",
  opportunityOf = "opportunityOf",
  conceptOf = "conceptOf",
  /** Typically corresponds with a matching `docReferenceInlineNode` or other mark or "hyperlink" within a doc body, 
   * 
   *  `fromDoc` (could be the doc created from a selection) -> `"referencedBy"` -> `toDoc` (that has the actual hyperlink in its body)
   */
  referencedBy = "referencedBy",
}
