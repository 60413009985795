

/** Splits a raw line of text, separating placeholders from plain text. */
export const splitLineByPlaceholders = (inputString: string): LinePart[] => {
  const regex = /\[\[(.*?)\]\]/g;
  const parts: LinePart[] = [];
  let match;
  let lastIndex = 0;

  while ((match = regex.exec(inputString)) !== null) {
    const placeholderIndex = match.index;
    const placeholderText = match[1];

    // Add the text before the placeholder
    const textBefore = inputString.substring(lastIndex, placeholderIndex);
    if (textBefore !== "") {
      parts.push({ text: textBefore });
    }

    // Add the placeholder
    parts.push({ text: placeholderText, isPlaceholder: true });

    lastIndex = placeholderIndex + match[0].length;
  }

  // Add the text after the last placeholder
  const textAfter = inputString.substring(lastIndex);
  if (textAfter !== "") {
    parts.push({ text: textAfter });
  }

  return parts;
}

export type LinePart = {
  text: string
  isPlaceholder?: boolean
}
