

export const suggestAssumptionTitlePrompt = (question: string, answer: string) => {
  const prompt = `
rephrase the following question as a statement that includes the following answer.

Question:
${question}
Answer:
${answer}

Return only the statement as a single line of text.


For example, given the following input:
Question: Is this feature feasible?
Answer: yes
Respond with the following output:
This feature is feasible.

Another example, given the following input:
Question: what customer segment would be most likely to buy this product?
Answer: Young adults ages 18-24
Respond with the following output:
Young adults ages 18-24 will likely buy this product.
  `
  return prompt
}
// rephrase the question as a statement that includes answer
