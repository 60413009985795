import { Button, IconButton } from "@chakra-ui/react"
import { VerdiIconIsNotVisible, VerdiIconIsVisible } from "./icons/VerdiIcons"


type Props = {
  buttonTitle?: string
  isVisible: boolean
  canToggle: boolean
  onToggle: (isNowVisible: boolean) => void
  tooltipText: string
}

export const VisibilityToggleButton = (props: Props) => {

  if (props.buttonTitle) {
    return (
      <div>
        <Button
          disabled={!props.canToggle}
          aria-label={props.tooltipText}
          title={props.tooltipText}
          variant="outline"
          size="xs"
          leftIcon={props.isVisible ? <VerdiIconIsVisible /> : <VerdiIconIsNotVisible />}
          onClick={() => props.onToggle(!props.isVisible)}
        >
          {props.buttonTitle}
        </Button>
      </div>
    )
  }

  return (
    <div>
      <IconButton
        disabled={!props.canToggle}
        aria-label={props.tooltipText}
        title={props.tooltipText}
        variant="outline"
        size="xs"
        icon={props.isVisible ? <VerdiIconIsVisible /> : <VerdiIconIsNotVisible />}
        onClick={() => props.onToggle(!props.isVisible)}
      />
    </div>
  )

}
