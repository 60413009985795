import { css } from "@emotion/react"
import { AddNewDocGlobalProvider } from "./useAddNewDocGlobalProvider"
import { DocOutlinePreview } from "./DocOutlinePreview"
import { cleanupAiOutputAsDocumentOutline } from "../../../../ai/utils/cleanupAiOutput"


type Props = {
  addNewDocProvider: AddNewDocGlobalProvider
}
export const AddNewDocView = ({
  addNewDocProvider,
}: Props) => {

  const {
    selectedDocFramework
  } = addNewDocProvider


  if (!selectedDocFramework) return null

  const {
    title,
    description,
    whenToUse,
    docTemplates,
  } = selectedDocFramework

  const bodyMarkdown = docTemplates.find(t => !t.isExample)?.bodyMarkdown
  const docOutline = bodyMarkdown ? cleanupAiOutputAsDocumentOutline(bodyMarkdown) : undefined


  return (
    <div css={css`
      padding: 0 10px;
      font-size: 0.9em;
      & > div {
        margin-bottom: 20px;
        line-height: 1.3;
      }
      & > div > h3 {
        font-size: 1em;
      }
    `}>
      <h2 css={css`
        line-height: 1.2em;
        margin-bottom: 10px;
      `}>
        {title}
      </h2>

      {Boolean(description) &&
        <div>
          <p>
            {description}
          </p>
        </div>
      }

      {Boolean(whenToUse) &&
        <div>
          <h3>
            When to use:
          </h3>
          <p>
            {whenToUse}
          </p>
        </div>
      }

      {Boolean(docOutline) &&
        <div>
          <h3>
            Preview
          </h3>
          <DocOutlinePreview
            docOutline={docOutline}
          />
        </div>
      }

    </div>
  )
}
