import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { getDocInfoCommandsGroup } from "../../commandMenuData/getCmds/getCmdsForDoc"
import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails"
import { showMiniCommandMenuCommandDefinition } from "./showMiniCommandMenu"


const cmdType = GlobalCommandType.showDocInfoCommandMenu

/** Opens a command menu for a given document with options outside of the doc editor. */
export const showDocInfoCommandMenuCommandDefinition: GlobalCommandDefinition<ShowDocInfoCommandMenuCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowDocInfoCommandMenuCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Open the command menu',
        searchName: 'open the command menu',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        // shouldKeepOpenOnRun: true,
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowDocInfoCommandMenuCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("showDocInfoCommandMenuCommandDefinition.processCommand()", { args })

    const {
      document,
      rect,
      filterMode,
    } = args

    const commandGroup = getDocInfoCommandsGroup(document, filterMode, rect)

    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands: commandGroup?.commands || [],
      rectOfAnchorElement: rect,
      shouldAdjustRectForScrollOffset: false,
      searchPlaceholderText: document.title || "",
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args?: ShowDocInfoCommandMenuCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type ShowDocInfoCommandMenuCommandArgs = {
  document: BasicDocumentDetails
  rect: DOMRect
  filterMode: "docEditor" | "opportunityKanban"
}


