import { Button, Spinner } from "@chakra-ui/react"
import { useState } from "react"
import { SnippetRow } from "./SnippetRow"
import { useCreateSnippet } from './useCreateSnippet'
import { CreateSnippetInput, useCreateSnippetMutation$data } from './__generated__/useCreateSnippetMutation.graphql'
import { css } from "@emotion/react"


type Props = {
  updateConnectionIds: string[]
};
export const AddSnippetButton = ({ updateConnectionIds }: Props) => {

  const onChange = (fields: CreateSnippetInput) => {
    setUpdatedFields(fields)
  }

  const onCreated = (response: useCreateSnippetMutation$data['createSnippet']) => {
    console.log("it was created! ", response)
  }

  const [saveSnippet, isSaving] = useCreateSnippet(onCreated, updateConnectionIds)
  const [updatedFields, setUpdatedFields] = useState<CreateSnippetInput>()

  const saveClicked = () => {
    if (updatedFields) saveSnippet(updatedFields)
  }


  return (
    <div css={css`
      border-top: 1px solid rgba(192,192,192,0.2);
      margin-top: 20px;
      padding-top: 10px;
    `}>
      <h2>Add Snippet</h2>
      <div css={css`
      display: flex;
      align-items: center;
    `}>

        <SnippetRow
          placeholderKey={""}
          textValue={""}
          onChange={onChange}
        />
        <div css={css`
          width: 70px;
        `}>
          {isSaving ?
            <Spinner size='sm' margin="12px" opacity={0.2} />
            :
            <Button onClick={saveClicked}
              size="sm"
              colorScheme="green"
            >Add</Button>
          }
        </div>
      </div>
    </div>
  )
}
