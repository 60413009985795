import { css } from "@emotion/react"
import { OpportunityRiskIcon } from "../assumptions/risk/OpportunityRiskIcon"


export const OpportunityRiskIconSample = () => {

  return (
    <div css={css`
      display: flex;
      flex-direction: row;
      gap: 20px;
    
    `}>
      <OpportunityRiskIcon
        size="xs"
        opportunityRisk={{
          greenCount: 4,
          redCount: 2,
          yellowCount: 1,
          totalCount: 7,
          riskScore: 44,
          status: "High",
        }}
      />
      <OpportunityRiskIcon
        size="xs"
        opportunityRisk={undefined}
      />

      <OpportunityRiskIcon
        size="xs"
        opportunityRisk={{
          greenCount: 0,
          redCount: 2,
          yellowCount: 1,
          totalCount: 3,
          riskScore: 44,
          status: "Low",
        }}
      />
      <OpportunityRiskIcon
        size="xs"
        opportunityRisk={{
          greenCount: 0,
          redCount: 1,
          yellowCount: 0,
          totalCount: 1,
          riskScore: 44,
          status: "Low",
        }}
      />
      <OpportunityRiskIcon
        size="xs"
        opportunityRisk={{
          greenCount: 12,
          redCount: 7,
          yellowCount: 16,
          totalCount: 35,
          riskScore: 44,
          status: "Low",
        }}
      />
    </div>
  )
}
