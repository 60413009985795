import { css } from "@emotion/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { useAppServices } from "../../components/appServices/useAppServices"
import { useColorModeValue } from "@chakra-ui/react"
import { modalBackgroundColors } from "../../components/ColorModeValues"
import { Data } from "@verdi/shared-constants"
import { useEffect, useRef, useState } from "react"
import { OnboardingStepIds } from "./OnboardingStep"


export type OnboardingPopupMessageProps = {
  stepId: OnboardingStepIds
  children: React.ReactNode[]
  buttonText: string
  buttonActionUrl?: string
  buttonAction?: () => void
  buttonProgressKey: keyof Data.Onboarding.OnboardingProgressMutableFields
}
export const OnboardingPopupMessage = ({
  stepId,
  children,
  buttonText,
  buttonActionUrl,
  buttonAction,
  buttonProgressKey,
}: OnboardingPopupMessageProps) => {


  const { onboardingManager, router } = useAppServices()
  const modalBackground = useColorModeValue(modalBackgroundColors.light, modalBackgroundColors.dark)

  /** Delay showing of messages */
  const [isShowingButton, setIsShowingButton] = useState(false)
  const [messageCountToShow, setMessageCountToShow] = useState(1)
  const intervalId = useRef<number>();

  useEffect(() => {
    if (messageCountToShow <= children.length) {
      intervalId.current = window.setInterval(() => {

        const incremented = messageCountToShow + 1
        setMessageCountToShow(incremented)

        if (incremented > children.length) {
          clearInterval(intervalId.current)
          console.log("ending the madness")
          setTimeout(() => {
            setIsShowingButton(true)
          }, 300)
        }

      }, 600)
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [messageCountToShow, children.length]);


  return (

    <div css={css`
      position: fixed;
      top: auto;
      bottom: 20px;
      right: 20px;
      border: 1px solid #99999966;
      width: 300px;
      padding: 10px;
      border-radius: 10px;
      background-color: ${modalBackground};
      -webkit-box-shadow: 5px 5px 15px 5px rgba(124, 124, 124, 0.3); 
      box-shadow: 5px 5px 15px 5px rgba(124, 124, 124, 0.3);
    `}>

      <div css={css`
        font-size: x-small;
        opacity: 0.6;
        width: 70px;
        line-height: 0.8;
        text-transform: uppercase;
        margin: 10px 0;
      `}>
        VERDI
      </div>

      <div>
        {children.map((child, index) =>
          <div key={index}
            css={css`
            margin-bottom: 10px;
            opacity: ${index < messageCountToShow ? 1 : 0};
            ${index < messageCountToShow ? "animation: fadeIn 500ms;" : ""}
            @keyframes fadeIn {
              0% { opacity: 0 }
              100% { opacity: 1 }
            }
          `}>
            {child}
          </div>
        )}
      </div>

      <div css={css`
        display: flex;
        justify-content: space-between;
      `}>
        <ButtonPrimary
          label={buttonText}
          onClick={() => {
            console.log("clicked, ", buttonActionUrl)
            onboardingManager.startOnboardingFlow()
            onboardingManager.updateProgress(buttonProgressKey)
            if (buttonAction) {
              buttonAction()
            }
            if (buttonActionUrl) {
              router.router.push(buttonActionUrl)
            }
          }}
          opacity={isShowingButton ? 1 : 0}
        />

        {/* <IconButtonSecondary
          aria-label="Close"
          icon={<VerdiIconClose />}
          opacity={0.5}
          onClick={() => {
            onboardingManager.stopOnboardingFlow()
            onboardingManager.updateProgress("dismissedAt")
          }}
        /> */}
      </div>
    </div>
  )
}
