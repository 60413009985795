import { useCallback, useMemo, useState } from "react"
import { DocumentReference } from "../../../components/DocumentReference"
import { OnNewDocumentMutationCompleted, useNewDocumentMutation } from "./useAddNewDocument"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { useAddNewDocumentAppendMutation$data } from "./__generated__/useAddNewDocumentAppendMutation.graphql"
import { mapDocTypeToRelationType } from "../../documentRelation/data/DocTypeToRelationTypeMapper"
import { DocumentSchema } from "@verdi/shared-constants"


type Props = {
  buttonText: string
  createFromDocId?: string
  parentDocId?: string
  opportunityId?: string
  title?: string | null,
  description?: string | null,
  type: DocumentSchema.DocumentType,
  onCreated?: (document: useAddNewDocumentAppendMutation$data['createDocument']) => void
}

/** On press, this component will create a new document  */
export function NewDocumentButton({
  buttonText,
  createFromDocId,
  parentDocId,
  opportunityId,
  title,
  description,
  type,
  onCreated
}: Props) {

  const onCompleted: OnNewDocumentMutationCompleted = (response) => {
    console.log("On create complete (no relation added)")
    setDidCompleteSuccessfully(true)
    onCreated?.(response.createDocument)
  }

  const appServices = useAppServices()

  const [createDocument, isSaving] = useNewDocumentMutation(false, [], onCompleted)
  const [hasClicked, setHasClicked] = useState(false)
  const [didCompleteSuccessfully, setDidCompleteSuccessfully] = useState(false)

  const relationType = useMemo(() => {
    const docType = type as DocumentSchema.DocumentType
    return mapDocTypeToRelationType(docType)
  }, [type])

  const createDocWithRelation = useCallback(async () => {

    if (relationType && parentDocId) {
      appServices.documentRelationsProvider.createNewDocWithRelation({
        currentDocId: parentDocId,
        direction: "currentDocIsTo",
        newDocTitle: title || "",
        newDocType: type,
        relationType,
        parentDocId,
        opportunityId: opportunityId || undefined,

        newDocDescription: description || "",
        onError: () => {
          console.log("createDocWithRelation.onError")
        },
        onComplete: (newDocId: string) => {
          console.log("createDocWithRelation.onComplete", { newDocId })
        }
      })

    } else {
      createDocument(
        { type, createFromDocId, parentDocId, opportunityId, title, description },
        appServices.menuStructureProvider,
      )
    }
  }, [createDocument, appServices, createFromDocId, parentDocId, opportunityId, title, description, type])


  return (
    <DocumentReference
      onClick={async (event) => {
        setHasClicked(true)
        if (isSaving) return
        event.preventDefault()
        await createDocWithRelation()
      }}
      isDisabled={hasClicked}
      isLoading={isSaving}
      didCompleteSuccessfully={didCompleteSuccessfully}
      title={buttonText}
      // subTitle={type + " " + relationType}
      intendedUserAction="add" />
  )
}
