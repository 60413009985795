import { Data } from "@verdi/shared-constants";
import { OpportunityContextForAi } from "../../screens/opportunities/useOpportunityProvider";


export const buildOpportunityContextForPrompt = (opportunityContext: OpportunityContextForAi, includeBody = true) => {

  const { assumptions, title, bodyPlainText, framework } = opportunityContext
  const partsToReturn = []

  if (title) {
    partsToReturn.push(`title: ${title}`)
  }

  if (assumptions.length > 0) {
    const assumptionLineItems = assumptions
      .map(a => `${a.question} ${a.answer}`).filter(a => a.length > 0)
    // TODO: Decide if we want to have confidence level influence this.

    if (assumptionLineItems.length > 0) {
      partsToReturn.push(`
      QUESTIONS ABOUT THIS OPPORTUNITY WE ALREADY ANSWERED ARE BELOW. THESE ARE ALSO CALLED ASSUMPTIONS:
      ${assumptionLineItems.join("\n")}
      `)
    }
  }

  if (bodyPlainText && includeBody) {
    partsToReturn.push(`
    body:
    ${bodyPlainText}
    `)
  }

  if (framework) {
    partsToReturn.push(getFrameworkString(framework))
  }

  return partsToReturn.join("\n")
}


const getFrameworkString = (framework: Data.FrameworkModel) => {
  return `START FRAMEWORK CONTEXT: 
Framework to follow: ${framework.title}
Framework objective: ${framework.description}
${framework.whenToUse}
END FRAMEWORK CONTEXT
`
}
