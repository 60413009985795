import { useState, useMemo, useEffect } from "react";
import { useRouter } from "found";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import {
  DocumentHistoryDebuggerQuery,
  DocumentHistoryDebuggerQuery$data,
} from "./__generated__/DocumentHistoryDebuggerQuery.graphql";
import { useDocumentSubscription } from "../../../components/documentSubscriptions/useDocumentSubscription";
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants";
import { Button, Flex, Heading, Input, Textarea } from "@chakra-ui/react";
import { useEditor, EditorContent } from "@tiptap/react";
import ".././tiptapEditor/tiptap.css";
import { extensionsForEditor } from "../tiptapEditor/extensions/extensions";
import { Step } from "prosemirror-transform";
import { EditorState } from "prosemirror-state";
import { css } from "@emotion/react";
import { SuspenseErrorBoundary } from "../../../components/SuspenseErrorBoundary";
import { DocumentHistoryDebugger_PaginationQuery } from "./__generated__/DocumentHistoryDebugger_PaginationQuery.graphql";
import {
  DocumentHistoryDebugger_query$data,
  DocumentHistoryDebugger_query$key,
} from "./__generated__/DocumentHistoryDebugger_query.graphql";
import { LookupById } from "./LookupById";
import { generateClientId } from "../tiptapEditor/utils/clientIdGenerator";
import { Node } from "prosemirror-model"
import { applyDevTools } from "prosemirror-dev-toolkit";


const fragmentQl = graphql`
  fragment DocumentHistoryDebugger_query on Query
  @refetchable(queryName: "DocumentHistoryDebugger_PaginationQuery") {
    documentStepConnection(documentId: $id, first: $first, after: $after)
      @connection(
        key: "DocumentHistoryDebuggerPagination__documentStepConnection"
      ) {
      edges {
        node {
          id
          redisId
          body
          stepNumber
          timestamp
          user {
            id
            email
          }
        }
      }
    }
    loggedInUser {
      id
    }
  }
`;

export const queryQL = graphql`
  query DocumentHistoryDebuggerQuery($id: ID!, $first: Int!, $after: String) {
    ...TipTapEditor_query
    node(id: $id) {
      id
      ... on Document {
        title
        type
        ...DocumentSettingsEditor_document
        ...TipTapEditor_document
      }
    }
    ...DocumentHistoryDebugger_query
    loggedInUser {
      id
      email
    }
  }
`;

type StepInfo =
  DocumentHistoryDebugger_query$data["documentStepConnection"]["edges"];

const exampleJson = JSON.stringify({
  type: "doc",
  content: [
    {
      type: "freeText",
      attrs: {
        id: null,
        sourceBlockId: null,
        originalBlockId: null,
        transcriptTimestamp: null,
      },
      content: [
        {
          type: "title",
          content: [
            {
              text: "This is document text!",
              type: "text",
            },
          ],
        },
      ],
    },
  ],
});

// TODO: See if we can consolidate with shared-constants
export const incrementallyUpdateSnapshotUntilAnyBadStep = (
  docJson: ProsemirrorNodes.TipTapDocument,
  steps: StepInfo
) => {
  const prosemirrorDocument = DocumentSchema.createNewDoc(docJson);
  let newSnapshotDoc = prosemirrorDocument;
  let currentStepNumber = 0;
  let badStepId = -1;
  let badStepDetails
  let currentIndex = 0;

  try {
    for (const stepInfo of steps) {
      currentStepNumber = stepInfo.node.stepNumber;
      const step = Step.fromJSON(
        DocumentSchema.schema,
        JSON.parse(stepInfo.node.body)
      );

      if (currentIndex < 2) {
        console.log(
          "Real step BEFORE = ",
          stepInfo.node.body,
          ", AFTER = ",
          step
        );
      }
      currentIndex++;

      const newDocument = step.apply(newSnapshotDoc).doc;
      if (!newDocument) {
        throw new Error(
          `Step created empty document! [${JSON.stringify(stepInfo, null, 2)}]`
        );
      }
      newSnapshotDoc = newDocument;
    }
  } catch (err) {
    console.error("Error at step ", currentStepNumber, { err })
    const e = (err as any)
    const errorDetails = e.message ? e.message : e.toString();
    badStepId = currentStepNumber;
    badStepDetails = buildStateDetails(badStepId, steps, newSnapshotDoc, errorDetails);
  }
  return {
    newSnapshotDoc,
    badStepId,
    badStepDetails,
  };
};


const buildStateDetails = (badStepId: number, steps: StepInfo | object[], newSnapshotDoc: Node, errorMessage: string) => {

  let badStepBody
  try {
    const stringyBody = JSON.parse(JSON.stringify(steps[badStepId - 1])).node.body
    badStepBody = JSON.stringify(JSON.parse(stringyBody), null, 2)
  } catch { }

  const badStepDetails = {
    badStepId,
    badStep: steps[badStepId - 1],
    badStepStringified: JSON.stringify(steps[badStepId - 1], null, 2),
    badStepBody,
    previousStep: badStepId > 1 ? steps[badStepId - 2] : null,
    previousStepStringified: badStepId > 1 ? JSON.stringify(steps[badStepId - 2], null, 2) : null,
    documentState: JSON.stringify(newSnapshotDoc.toJSON(), null, 2),
    errorMessage,
  }
  return badStepDetails;
}


export const incrementallyUpdateSnapshotUntilAnyBadStepWithPureSteps = (
  docJson: ProsemirrorNodes.TipTapDocument,
  steps: object[]
) => {
  const prosemirrorDocument = DocumentSchema.createNewDoc(docJson);
  let newSnapshotDoc = prosemirrorDocument;
  let currentStepNumber = 0;
  let badStepId = -1;
  let badStepDetails

  try {
    for (const stepInfo of steps) {
      currentStepNumber++;
      const step = Step.fromJSON(DocumentSchema.schema, stepInfo);

      const newDocument = step.apply(newSnapshotDoc).doc;
      if (!newDocument) {
        throw new Error(
          `Step created empty document! [${JSON.stringify(stepInfo, null, 2)}]`
        );
      }
      newSnapshotDoc = newDocument;
    }
  } catch (err) {
    console.error("Error at step ", currentStepNumber, { err })
    const e = (err as any)
    const errorDetails = e.message ? e.message : e.toString();
    badStepId = currentStepNumber;
    badStepDetails = buildStateDetails(badStepId, steps, newSnapshotDoc, errorDetails);

  }
  return {
    newSnapshotDoc,
    badStepId,
    badStepDetails,
  };
};

const loadThisMany = 10000;

type Props = {
  documentId?: string;
};

enum ValidateMode {
  DOCUMENT_HISTORY,
  RAW_DOCUMENT_JSON,
}
export const DocumentHistoryDebugger = (props: Props) => {
  const router = useRouter();
  const documentId = props.documentId || router.match.params["documentId"];

  const response = useLazyLoadQuery<DocumentHistoryDebuggerQuery>(queryQL, {
    id: documentId,
    first: loadThisMany,
  });

  console.log("response = ", response)

  const {
    data: paginatedSteps,
    loadNext,
    hasNext,
  } = usePaginationFragment<
    DocumentHistoryDebugger_PaginationQuery,
    DocumentHistoryDebugger_query$key
  >(fragmentQl, response);

  if (!response.loggedInUser) throw new Error(`No user logged in!`);
  const stepEdges = paginatedSteps.documentStepConnection.edges;
  const [activeStepIndex, setActiveStepIndex] = useState(stepEdges.length - 1);
  const document = response.node;
  const clientId = generateClientId(response.loggedInUser.id);
  const documentSubscription = useDocumentSubscription(documentId);
  const startingContent = documentSubscription?.initialDoc
    ? documentSubscription.initialDoc!.body
    : DocumentSchema.initialDoc();

  const [validationMode, setValidationMode] = useState<ValidateMode>(
    ValidateMode.DOCUMENT_HISTORY
  );
  const [rawDocumentJsonString, setRawDocumentJsonString] =
    useState(exampleJson);
  const validateRawDocumentJSON = () => {
    console.log("validateRawDocumentJSON()");
    setValidationMode(ValidateMode.RAW_DOCUMENT_JSON);
  };


  const documentRootContent = useMemo(() => {
    try {
      if (validationMode === ValidateMode.DOCUMENT_HISTORY) {
        const stepsToApply = stepEdges.slice(0, activeStepIndex + 1);
        const results = incrementallyUpdateSnapshotUntilAnyBadStep(
          startingContent,
          stepsToApply
        );
        return results;
      }

      if (validationMode === ValidateMode.RAW_DOCUMENT_JSON) {
        try {
          const jsonDoc = JSON.parse(rawDocumentJsonString as string);
          const firstStep = DocumentSchema.createStepFromDocumentBody(jsonDoc);
          console.log("firstStep = ", firstStep);
          return incrementallyUpdateSnapshotUntilAnyBadStepWithPureSteps(
            DocumentSchema.initialDoc(),
            [firstStep]
          );
        } catch (err) {
          console.error("Invalid JSON: ", err);
        }
      }
    } catch (err) {
      console.log("ERROR = ", err);
    }
    return undefined;
  }, [stepEdges, activeStepIndex, validationMode]);


  const editor = useEditor({
    extensions: [...extensionsForEditor()],
    content: documentRootContent,
    editable: false,
    onCreate({ editor }) {
      applyDevTools(editor.view, { devToolsExpanded: true });
    },
  })


  useEffect(() => {
    if (!documentRootContent) return;
    const emptyDoc = DocumentSchema.createNewDoc(DocumentSchema.initialDoc());

    const newState = EditorState.create({
      schema: DocumentSchema.schema,
      doc: documentRootContent.newSnapshotDoc,
    });
    editor?.view.updateState(newState);
  }, [documentRootContent]);

  const allSteps = paginatedSteps.documentStepConnection.edges.map(
    (s) => s.node
  );

  const onStepClick = (stepNumber: number) => {
    setValidationMode(ValidateMode.DOCUMENT_HISTORY);
    setActiveStepIndex(stepNumber);

    console.log(`onStepClick(${stepNumber})`);
  };


  return (
    <div
      css={css`
        padding: 0 20px 200px;
        margin-bottom: 20px;
        background-color: rgb(255, 230, 200);
        background-image: linear-gradient(
          45deg,
          #f5e5d0 25%,
          #faecdc 25%,
          #faecdc 50%,
          #f5e5d0 50%,
          #f5e5d0 75%,
          #faecdc 75%,
          #faecdc 100%
        );
        background-size: 100px 100px;
        background-attachment: fixed;
      `}
    >
      <div>
        Document History Debugger
        <small> | Title:</small> <strong> {document?.title}</strong>
        <small> | Type:</small> <strong> {document?.type}</strong>
        <small> | badStepId:</small> <strong> {documentRootContent?.badStepId}</strong>
        {!documentSubscription?.mustRefreshMessage}

      </div>

      <Flex direction="row">
        <div
          css={css`
            width: 100%;
          `}
        >
          State of the document
          <div
            css={css`
              border: 1px solid #f5d6b1;
              width: 100%;
              height: calc(50vh - 80px);
              overflow-y: auto;
              padding: 20px;
              background-color: #fff;
              border-bottom-left-radius: 10px;
              border-top-left-radius: 10px;
            `}
          >
            <SuspenseErrorBoundary>
              <EditorContent editor={editor} />
            </SuspenseErrorBoundary>
          </div>
        </div>
        <div>
          <Flex justifyContent="space-between">
            List of steps: ({allSteps.length})
            {hasNext && (
              <Button
                onClick={() => loadNext(loadThisMany)}
                colorScheme="orange"
                variant="ghost"
                size="sm"
              >
                Load more steps
              </Button>
            )}
          </Flex>
          <ul
            css={css`
              border: 1px solid #f5d6b1;
              height: calc(50vh - 80px);
              overflow-y: scroll;
              width: 400px;
              background-color: #fff;
              margin: 0;
              border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;
            `}
          >
            {allSteps.map((s, i) => (
              <li key={i}>
                <Button
                  isActive={activeStepIndex === s.stepNumber}
                  onClick={() => onStepClick(s.stepNumber)}
                  size="sm"
                  variant="ghost"
                  colorScheme={
                    documentRootContent?.badStepId === s.stepNumber
                      ? "red"
                      : "blackAlpha"
                  }
                >
                  {s.stepNumber} - {s.timestamp} -{" "}
                  <small>{s.user?.email}</small>
                </Button>
              </li>
            ))}
          </ul>
          <p>Bad Step Id: {documentRootContent?.badStepId}</p>
        </div>
      </Flex>
      <div css={css`margin-bottom: 20px;`}>
        <h2>Bad Step Details</h2>
        {documentRootContent &&
          <div css={css`
            display: flex;
            gap: 2px;
            & div.jsonView {
              
              & > p {
                font-size: small; 
                white-space:pre-wrap; 
                border: 1px solid #00000033;
                background-color: #FFF;
                padding: 2px;
                max-height: 600px;
                overflow-y: auto;
              }
            }
          `}>
            <div className="jsonView">
              ERROR THROWN
              <p>
                {documentRootContent.badStepDetails?.errorMessage}
              </p>
            </div>
            <div css={css`
              display: flex;
              flex-direction: column;
            `}>
              <div className="jsonView">
                BAD STEP BODY
                <p>
                  {documentRootContent.badStepDetails?.badStepBody}
                </p>
              </div>
              <div className="jsonView">
                ENTIRE BAD STEP
                <p>
                  {documentRootContent.badStepDetails?.badStepStringified}
                </p>
              </div>
            </div>
          </div>
        }
      </div>
      <div>
        <h2>Validate Raw JSON</h2>
        <Textarea
          value={rawDocumentJsonString}
          onChange={(evt) => setRawDocumentJsonString(evt.target.value)}
          placeholder="Paste document JSON here"
          size="sm"
          backgroundColor="white"
        />
        <Button
          onClick={validateRawDocumentJSON}
          colorScheme="orange"
          size="sm"
        >
          Validate Raw Document JSON
        </Button>
      </div>

      <LookupById initialId={documentId} />
    </div>
  );
};
