import { Editor } from "@tiptap/core";
import { CurrentBlockContext } from "../tiptapEditor/utils/getCurrentBlockContext"
import { DocContextIncludeArgs, UseAiTipTapBridge, useAiTipTapBridge } from "../../../ai/documents/useAiTipTapBridge"
import { AppServices } from "../../../components/appServices/useRegisterAppServices"
import { UseAiCoach, useAiCoach } from "../../../ai/coach/useAiCoach"
import { AiCoachCache } from "../../../ai/coach/aiCoachCache"
import { DocumentSubscription } from "../../../components/documentSubscriptions/useDocumentSubscription"
import { useCallback, useMemo } from "react"
import { BasicDocumentDetails } from "../BasicDocumentDetails"
import { WhatsNextSuggester, useWhatsNextSuggester } from "../tiptapEditor/commandMenu/commands/whatsNextSuggestions/useWhatsNextSuggester"
import { DocumentContextForAi } from "../../../ai/coach/AiCoachPrompts"
import { CloseMenuOptions, CommandMenuRef, OpenMenuOptions } from "../tiptapEditor/commandMenu/CommandMenuWrapper";
import { AiContextConfig, AllContextForAi } from "../../../ai/promptUtils/useAllContextForAi";
import { dispatch, getCurrentAppState } from "../../../state/store";
import { documentFrameworkState } from "../../../state/documentFrameworksSlice";
import { SuggestionsForPlaceholdersState } from "../../../state/suggestions/suggestionsForPlaceholdersSlice";
import { DocumentFrameworksProvider } from "../../documentFrameworks/DocumentFrameworksProvider";
import { DocMetadataState } from "../../../state/docMetadataSlice";
import { SuggestionsForDocTitleState } from "../../../state/suggestions/suggestionsForDocTitleSlice";


type Props = {
  document: BasicDocumentDetails
  sharedServicesProvider: AppServices
  editorRef: React.MutableRefObject<Editor | null | undefined>
  docCommandMenuRef: React.MutableRefObject<CommandMenuRef | undefined>
  currentBlockContextRef: React.MutableRefObject<CurrentBlockContext | undefined>
  documentSubscription: DocumentSubscription
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
}
/** IMPORTANT: use `useDocServices` instead of referencing this hook directly (When consuming).
 * 
 *  Registers context and services for a specific doc. 
 * 
 * NOTE: when we move to having multiple editors at the same time, 
 * We _might_ have a new instance of this hook for each editor.
 * */
export const useRegisterDocServices = ({
  document,
  sharedServicesProvider,
  editorRef,
  docCommandMenuRef,
  currentBlockContextRef,
  documentSubscription,
  getContextForAi,
}: Props) => {

  /** AI Specific stuff */
  const aiTipTapBridge = useAiTipTapBridge({
    document,
    editorRef,
    currentBlockContextRef: currentBlockContextRef,
    documentRelationsProvider: sharedServicesProvider.documentRelationsProvider,
  });


  /** Allows direct access to the prosemirror editor instance */
  const getEditor = useCallback(() => {
    return aiTipTapBridge.getEditor()
  }, [aiTipTapBridge])


  /** Gets many details about the current document state */
  const getDocumentContextForAi = useCallback((include?: DocContextIncludeArgs) => {
    // console.log("getDocumentContextForAi NEW WAY")
    const docContext = aiTipTapBridge.getDocumentContextForAi(include)
    if (docContext) {
      docContext.relatedDocs =
        sharedServicesProvider.documentRelationsProvider.getRelationsForDoc(docContext.id)
    }
    return docContext
  }, [aiTipTapBridge, sharedServicesProvider.documentRelationsProvider])


  /** Attempts to show the document level command menu. Returns false if it is not available */
  const showDocEditorCommandMenuIfAvailable = useCallback((options?: OpenMenuOptions) => {
    if (docCommandMenuRef.current) {
      docCommandMenuRef.current?.openCommandMenu(options)
      return true
    }
    return false
  }, [docCommandMenuRef])

  /** Attempts to hide the document level command menu. */
  const hideDocEditorCommandMenuIfAvailable = useCallback((options?: CloseMenuOptions) => {
    if (docCommandMenuRef.current) {
      docCommandMenuRef.current.closeCommandMenu(options)
      return true
    }
    return false
  }, [docCommandMenuRef])


  const aiCoach = useAiCoach({
    documentId: document.id,
    aiTipTapBridge,
    aiSubscription: sharedServicesProvider.aiSubscription,
    aiCoachCache: AiCoachCache.SingletonInstance,
    getCompanyContextForPrompt:
      sharedServicesProvider.companyContextProvider.getCompanyContextForPrompt,
  });


  const whatsNextSuggester = useWhatsNextSuggester({
    currentBlockContext: currentBlockContextRef.current,
    document,
    getDocumentContextForAi,
    getContextForAi,
  })


  const onEditorCreated = useCallback(() => {
    sharedServicesProvider.recentDocumentsProvider.addRecentDocument(document.id)

  }, [whatsNextSuggester, document, sharedServicesProvider.recentDocumentsProvider])



  /** Whenever the current document changes, this loads additional data specific to that document */
  const loadCurrentDocSupportingData = useCallback(async () => {
    const docId = document?.id
    if (!document) {
      return
    }

    // Load Doc Framework info
    DocumentFrameworksProvider.ensureAllAreLoaded()
      .then(() => {
        const currentFramework = getCurrentAppState().documentFrameworks.allDocumentFrameworks
          .find(df => df.documentId === docId)

        // TODO: Consider making an API call here to get the extended info for the framework (questions, template, etc)

        dispatch(documentFrameworkState.setCurrentDocumentFramework(currentFramework))
      })

    // Clear out any placeholder suggestions
    // IDEA: Or do we want to keep them around?
    dispatch(SuggestionsForPlaceholdersState.removeAll())

  }, [document.id])

  /** Clears state of supporting data related directly to the current document */
  const clearCurrentDocSupportingData = useCallback(() => {
    dispatch(documentFrameworkState.setCurrentDocumentFramework())
    dispatch(DocMetadataState.setSummary())
    dispatch(SuggestionsForDocTitleState.reset())
  }, [document.id])


  /** Context and services that do not change very much */
  const context = useMemo(() => {
    // console.log("recalculating docServicesContext")
    return {
      document,
      onEditorCreated,
      getEditor,
      getDocumentContextForAi,
      aiCoach,
      aiTipTapBridge,
      documentSubscription,
      editor: editorRef.current,
      whatsNextSuggester,
      showDocEditorCommandMenuIfAvailable,
      hideDocEditorCommandMenuIfAvailable,
      loadCurrentDocSupportingData,
      clearCurrentDocSupportingData,
      // commandsProvider,
    } as DocServicesContext
  }, [document, aiCoach, aiTipTapBridge, documentSubscription, editorRef, whatsNextSuggester, getDocumentContextForAi, onEditorCreated, getEditor, showDocEditorCommandMenuIfAvailable])

  // TODO: Make this rerender less often
  // Test when each object changes and forces a react re-render
  // useEffect(() => { console.log("changed: document, ", { document }) }, [document])
  // useEffect(() => { console.log("changed: currentBlockContextRef, ", { currentBlockContextRef }) }, [currentBlockContextRef])
  // useEffect(() => { console.log("changed: aiCoach, ", { aiCoach }) }, [aiCoach])
  // useEffect(() => { console.log("changed: aiTipTapBridge, ", { aiTipTapBridge }) }, [aiTipTapBridge])
  // useEffect(() => { console.log("changed: documentSubscription, ", { documentSubscription }) }, [documentSubscription])
  // useEffect(() => { console.log("changed: editorRef, ", { editorRef }) }, [editorRef])
  // useEffect(() => { console.log("changed: whatsNextSuggester, ", { whatsNextSuggester }) }, [whatsNextSuggester])
  // useEffect(() => { console.log("changed: commandsProvider, ", {commandsProvider}) }, [commandsProvider])



  //   /** Pieces of context that change frequently while editing */
  //  const editingContext = useMemo(() => {
  //      console.log("recalculating editingContext")
  //      return {
  //        document: document,
  //        currentBlockContext: currentBlockContextRef.current,
  //        aiCoach,
  //        aiTipTapBridge,
  //        documentSubscription,
  //        editor: editorRef.current,
  //        whatsNextSuggester,
  //        // commandsProvider,
  //      } as DocServicesContext
  //    }, [document, currentBlockContextRef, aiCoach, aiTipTapBridge, documentSubscription, editorRef])


  return context
}


export type DocServicesContext = {
  document: Readonly<BasicDocumentDetails>
  onEditorCreated: () => void,
  getEditor: () => Editor | null | undefined,
  getDocumentContextForAi: (include?: DocContextIncludeArgs) => DocumentContextForAi,
  currentBlockContext?: CurrentBlockContext,
  aiTipTapBridge: UseAiTipTapBridge,
  aiCoach: UseAiCoach,
  documentSubscription: DocumentSubscription;
  editor: Editor,
  whatsNextSuggester: WhatsNextSuggester,
  showDocEditorCommandMenuIfAvailable: (options?: OpenMenuOptions) => boolean,
  hideDocEditorCommandMenuIfAvailable: (options?: CloseMenuOptions) => void
  loadCurrentDocSupportingData: () => Promise<void>,
  clearCurrentDocSupportingData: () => void,
  // commandsProvider: DocCommandsProvider,
}
