import graphql from "babel-plugin-relay/macro"
import { useLazyLoadQuery } from "react-relay/hooks"
import { useDocumentEditorDataLoaderQuery } from "./__generated__/useDocumentEditorDataLoaderQuery.graphql"


export const queryQL = graphql`
  query useDocumentEditorDataLoaderQuery($id: ID!) {
    ...TipTapEditor_query
    node(id: $id) {
      id
      ... on Document {
        title
        type
        currentSnapshot {
          version
        }
        templateInfo {
          id
        }
        opportunities {
          id
        }
        ...DocumentSettingsEditor_document
        ...DocumentOriginBanner_document
        ...TipTapEditor_document
        ...ZoomDetailsForDocument_document
      }
    }
    loggedInUser {
      id
    }
  }
`

/** Loads data required for the document editor */
export const useDocumentEditorDataLoader = (documentId: string): { response: DocumentEditorQueryData | null } => {

  const response = useLazyLoadQuery<useDocumentEditorDataLoaderQuery>(
    queryQL,
    {
      id: documentId,
    },
    {
      /* prevent loading stale state when navigating back to this doc.
         Keep this in place until we implement a better caching strategy that 
         ensures steps are always coupled with version numbers.
       */
      fetchPolicy: "network-only",
    }
  )

  return {
    response
  }
}


export type DocumentEditorQueryData = NonNullable<useDocumentEditorDataLoaderQuery["response"]>
