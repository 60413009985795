/**
 * @generated SignedSource<<a258c79fd9dbc96795850803ff190508>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateDocumentAiPromptInput = {
  dontDo?: string | null;
  id: string;
  instructionsForUser?: string | null;
  notesForAdmin?: string | null;
  openApiParams?: OpenAIParamsInput | null;
  promptText?: string | null;
};
export type OpenAIParamsInput = {
  best_of?: number | null;
  echo?: boolean | null;
  frequency_penalty?: number | null;
  logprobs?: number | null;
  max_tokens?: number | null;
  model?: string | null;
  n?: number | null;
  presence_penalty?: number | null;
  stop?: ReadonlyArray<string> | null;
  suffix?: string | null;
  temperature?: number | null;
  top_p?: number | null;
};
export type useUpdateAiPromptMutation$variables = {
  input: UpdateDocumentAiPromptInput;
};
export type useUpdateAiPromptMutation$data = {
  readonly updateDocumentAiPrompt: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"useUpdateAiPromptMutation_documentAiPrompt">;
  };
};
export type useUpdateAiPromptMutation$rawResponse = {
  readonly updateDocumentAiPrompt: {
    readonly dontDo: string | null;
    readonly id: string;
    readonly notesForAdmin: string | null;
    readonly openApiParams: {
      readonly best_of: number | null;
      readonly echo: boolean | null;
      readonly frequency_penalty: number | null;
      readonly logprobs: number | null;
      readonly max_tokens: number | null;
      readonly model: string | null;
      readonly n: number | null;
      readonly presence_penalty: number | null;
      readonly stop: ReadonlyArray<string> | null;
      readonly suffix: string | null;
      readonly temperature: number | null;
      readonly top_p: number | null;
    } | null;
    readonly promptText: string;
  };
};
export type useUpdateAiPromptMutation = {
  rawResponse: useUpdateAiPromptMutation$rawResponse;
  response: useUpdateAiPromptMutation$data;
  variables: useUpdateAiPromptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateAiPromptMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DocumentAiPrompt",
        "kind": "LinkedField",
        "name": "updateDocumentAiPrompt",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useUpdateAiPromptMutation_documentAiPrompt"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateAiPromptMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DocumentAiPrompt",
        "kind": "LinkedField",
        "name": "updateDocumentAiPrompt",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "promptText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dontDo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notesForAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OpenAIParams",
            "kind": "LinkedField",
            "name": "openApiParams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "best_of",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "echo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "frequency_penalty",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logprobs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "max_tokens",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "model",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "n",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "presence_penalty",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stop",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suffix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "temperature",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "top_p",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70b389130e803cd69bcaa015ae6476a8",
    "id": null,
    "metadata": {},
    "name": "useUpdateAiPromptMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateAiPromptMutation(\n  $input: UpdateDocumentAiPromptInput!\n) {\n  updateDocumentAiPrompt(input: $input) {\n    id\n    ...useUpdateAiPromptMutation_documentAiPrompt\n  }\n}\n\nfragment useUpdateAiPromptMutation_documentAiPrompt on DocumentAiPrompt {\n  id\n  promptText\n  dontDo\n  notesForAdmin\n  openApiParams {\n    best_of\n    echo\n    frequency_penalty\n    logprobs\n    max_tokens\n    model\n    n\n    presence_penalty\n    stop\n    suffix\n    temperature\n    top_p\n  }\n}\n"
  }
};
})();

(node as any).hash = "373a16ac27dfd9e143d9dc207e0fd6b9";

export default node;
