import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.formatNodeAsText

export const formatNodeAsTextCommandDefinition: GlobalCommandDefinition<FormatNodeAsTextCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: FormatNodeAsTextCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Text',
        searchName: 'text paragraph',
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: FormatNodeAsTextCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('formatNodeAsText: No editor found in servicesForCurrentDoc')
      return
    }

    editor
      .chain()
      .convertNodeToType('freeText')
      .deleteToLastSlash()
      .run();

    onProcessingComplete(true)
  },
}

type FormatNodeAsTextCommandArgs = {
}
