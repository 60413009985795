import { ReactNode } from "react"
import { AppServicesWrapper } from "../components/appServices/AppServicesContext"
import { StartOnboardingModal } from "../screens/onboarding/StartOnboardingModal"
import { GlobalCommandsContainer } from "../components/commands/globalComponents/GlobalCommandsContainer"


type Props = {
  children: ReactNode | ReactNode[]
}
/** For all NON-PUBLIC routes */
export const RouteWrapLoggedIn = (props: Props) => {


  return (
    <AppServicesWrapper>
      {props.children}
      <StartOnboardingModal />
      <GlobalCommandsContainer />
    </AppServicesWrapper>
  )
}
