import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from "relay-runtime";
import { useFetchStepsQuery, useFetchStepsQuery$data } from "./__generated__/useFetchStepsQuery.graphql";
import { useRelayEnvironment } from "react-relay";

const queryQL = graphql`
    query useFetchStepsQuery(
      $documentId: ID!
      $after: String!
      $first: Int!
    ) {
      documentStepConnection(
        documentId: $documentId
        after: $after
        first: $first
      ) {
        edges{
          node{
            id
            body
            redisId
            clientId
            stepNumber
            user {
              id
            }
          }
        }
      }
    }
  `


export const useFetchSteps = () => {
  const environment = useRelayEnvironment()
  const fetchSteps = (documentId: string, fromStepNumber: number, toStepNumber: number) => {
    const afterStepNumber = fromStepNumber - 1
    const after = window.btoa(afterStepNumber.toString())
    const first = toStepNumber - fromStepNumber + 1
    return new Promise<useFetchStepsQuery$data>((resolve, reject) => {
      fetchQuery<useFetchStepsQuery>(environment, queryQL, { documentId, after, first })
        .subscribe({
          next: (data) => resolve(data),
          error: (error: any) => reject(error),
        })
    })
  }
  return fetchSteps
}