import { css } from "@emotion/react"
import { VerdiIconCheck } from "../../components/icons/VerdiIcons"
import { useColor } from "../../utility-hooks/useColor"
import { Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from "@chakra-ui/react"


type Props = {
  title: string
  isComplete: boolean
  onClick?: () => void
  moreDetailsNode?: React.ReactNode | React.ReactNode[]
}
export const OnboardingStatusListItem = ({
  title,
  isComplete,
  onClick,
  moreDetailsNode,
}: Props) => {


  const completedColor = useColor("itemCompleted")
  const fontColor = isComplete ? completedColor : "inherit"


  return (
    <div css={css`
      display: flex;
      align-items: center;
      gap: 8px;
      width: fit-content;
      cursor: pointer;
      padding: 0 4px 2px;
      &:hover {
        opacity: 0.8;
      }
    `}
      onClick={onClick}
    >
      <div css={css`
        width: 20px;
        margin: -4px -4px 0 0;
      color: ${fontColor};
      `}>
        {isComplete &&
          <VerdiIconCheck />
        }
      </div>
      <div>

        {Boolean(moreDetailsNode) ?
          <Popover
            closeOnBlur={true}
            closeOnEsc={true}
            placement="right"
          >
            <PopoverTrigger>
              <span css={css`
                color: ${fontColor};
              `}>
                {title}
              </span>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader border="none"></PopoverHeader>
              <PopoverBody
                color="inherit"
                fontSize="0.9rem"
              >
                {moreDetailsNode}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          :
          <span css={css`
            color: ${fontColor};
          `}>
            {title}
          </span>
        }



      </div>


    </div>
  )
}
