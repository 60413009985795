import { Progress, ProgressProps } from "@chakra-ui/react"


type Props = ProgressProps & {
  currentProgress?: number
  totalProgress?: number
}

export const ProgressPrimary = (props: Props) => {

  const { currentProgress, totalProgress, value, ...chakraProps } = props
  let percent
  if (totalProgress && currentProgress) {
    percent = currentProgress / totalProgress * 100
  }

  return (
    <Progress
      colorScheme="verdiButtonPrimary"
      size="sm"
      value={percent || value}
      {...chakraProps}
    />
  )
}

