import { DocumentType } from "../documentSchema"


export type MenuItemData = {
  id: string
  title: string
  description?: string
  type: DocumentType
  isVisibleToOrg: boolean
  parentDocId?: string
  opportunityId?: string // such as OpportunityId
  createdAt: Date
  deletedAt?: Date
  url: string
  children: MenuItemData[]
  mainDocId?: string // TODO: Remove this
}

export type RawMenuItemData = {
  id: string
  title: string
  description?: string
  type: DocumentType
  isVisibleToOrg: boolean
  parentDocId?: string
  opportunityId?: string
  createdAt: Date
  deletedAt?: Date
  children?: RawMenuItemData[]
  createdByUserId?: string
}

/** Menu structure can never have more than this amount of child levels */
export const maxNestableLevels = 20


/** Traverses the menu structure via .parentDocId, starting from a child doc, 
 * until it reaches a doc that does not have .parentDocId */
export const getMenuItemHierarchy = (target: MenuItemData, flatMenuItems: MenuItemData[]): MenuItemData[] => {
  const toReturn: MenuItemData[] = []
  getParents(target, flatMenuItems, toReturn, maxNestableLevels)
  return toReturn
}

const getParents = (target: MenuItemData, flatMenuItems: MenuItemData[], toReturn: MenuItemData[], recursiveLimit: number) => {
  if (recursiveLimit < 0) return
  if (target.parentDocId) {
    const parent = flatMenuItems.find(item => item.id === target.parentDocId)
    if (parent) {
      toReturn.push(parent)
      if (parent.parentDocId) {
        getParents(parent, flatMenuItems, toReturn, recursiveLimit - 1)
      }
    }
  }
}

