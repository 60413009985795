import graphql from 'babel-plugin-relay/macro'
import { useEffect, useState } from 'react';
import { useMutation, UseMutationConfig } from 'react-relay/hooks'
import { DocumentAiPromptInputForm, setInputOpenApiParamsValueForInputState } from './setOpenAIParamsValue';
import { useCreateAiPromptMutation, CreateDocumentAiPromptInput } from './__generated__/useCreateAiPromptMutation.graphql';

const mutationQL = graphql`
  mutation useCreateAiPromptMutation (
    $input: CreateDocumentAiPromptInput!
  ) @raw_response_type {
    createDocumentAiPrompt(input: $input){
      id
      ...useUpdateAiPromptMutation_documentAiPrompt
      document{
        ...EditDocumentContextTemplate_document
        aiPrompts {
          id
        }
      }
      document{
        aiPrompts {
          id
        }
      }
    }
  }
`

type CreateDocumentAiPromptInputForm = Partial<CreateDocumentAiPromptInput>
type Props = {
  initialInputValue: CreateDocumentAiPromptInputForm
  onCompleted?: UseMutationConfig<useCreateAiPromptMutation>['onCompleted']
  documentId: string
};

export function useCreateAiPrompt({
  initialInputValue,
  onCompleted,
  documentId,
}: Props) {

  const [isValid, setIsValid] = useState(false)
  const [input, setInput] = useState<DocumentAiPromptInputForm>(initialInputValue)
  const [commit, isSaving] = useMutation<useCreateAiPromptMutation>(mutationQL)

  const setInputValue = <T extends keyof DocumentAiPromptInputForm>(key: T, value: DocumentAiPromptInputForm[T]) => {
    setInput({ ...input, [key]: value })
  }

  const setInputOpenApiParamsValue = setInputOpenApiParamsValueForInputState(input, setInput)

  useEffect(() => {
    if (input.promptText?.length) {
      setIsValid(true)
    }
  }, [input])

  const save = () => {
    if (!isValid) return
    if (!input.promptText) return
    commit({
      variables: {
        input: {
          ...input,
          documentId,
          promptText: input.promptText,
        }
      },
      onCompleted: (response, errors) => {
        setInput({})
        onCompleted?.(response, errors)
      },
    })
  }
  return ({
    input,
    setInputValue,
    setInputOpenApiParamsValue,
    save,
    isSaving,
    isValid,
  })
}