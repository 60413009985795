import { ParentOpportunityContext, getContextForParentsOfCurrentOpportunity } from "../../../screens/opportunities/getContextForParentsOfCurrentOpportunity"
import { OpportunityContextForAi } from "../../../screens/opportunities/useOpportunityProvider"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItem } from "../../utils/cleanupAiOutput"


export const getSuggestedOpportunitiesAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
) => {

  const allContext: AllContextForAi = await getContextForAi()
  const contextForParents = await getContextForParentsOfCurrentOpportunity(allContext.currentOpportunity)

  const numResults = 4
  const prompt = getPrompt(allContext, contextForParents, numResults)

  try {
    const response = await makeAiChatRequest({
      prompt,
      messages: allContext.promptMessages,
    })

    const rawString = response?.aiResponseJson.content as string
    let rawAnswers = rawString.split("\n").map(l => cleanupAiOutputItem(l)).filter(l => l.trim().length > 0)

    if (rawAnswers.length > numResults) {
      rawAnswers = rawAnswers.slice(0, numResults)
    }

    return rawAnswers

  } catch (error) {
    console.error("detectAnswers: error = ", error)
  }
  return []
}


export const getPrompt = (
  allContext: AllContextForAi,
  contextForParents: ParentOpportunityContext[],
  maxResultCount: number,
) => {

  const parentOpportunityTitles = buildOpportunityRelationsForPrompt(allContext.currentOpportunity)
  const parentDocBodies = buildDocBodyForEacParentOpportunity(contextForParents)

  const prompt = `
  You are a product expert and whose job is to think up new opportunities for a company to pursue.
    
  ${parentDocBodies}

  ${parentOpportunityTitles}

  INSTRUCTIONS:
  Return the max of ${maxResultCount} very concise ideas for new opportunities.
  Only return the concise ideas in your output, each idea on its own line, similar to the example ideas listed below.
  The ideas should be written in sentence fragments.
  
  Example ideas:
  Increase revenue by 10%
  Increase customer satisfaction
  Reduce churn
  Increase customer retention
    `

  return prompt
}

const indentSpaces = 4

const buildOpportunityRelationsForPrompt = (currentOpportunity: OpportunityContextForAi | undefined) => {

  if (!currentOpportunity || currentOpportunity.parentOpportunities.length === 0) {
    return ""
  }

  const parentOpportunityTitles = currentOpportunity.parentOpportunities.map(p => p.title)
  const rawTitles: string[] = []
  rawTitles.push(...parentOpportunityTitles)
  if (currentOpportunity.title) {
    rawTitles.push(currentOpportunity.title)
  }
  rawTitles.push(`[[New Opportunity goes here]]`)

  const titlesNested = rawTitles.map((title, i) => {
    return `${" ".repeat(i * indentSpaces)}- ${title}`
  })

  // Indent each parent opportunity title by 4 characters
  return `
We are wanting to add a new Opportunity that would fit underneath the following 
Parent Opportunities: 
${titlesNested.join("\n")}
  `
}

const buildDocBodyForEacParentOpportunity = (contextForParents: ParentOpportunityContext[]) => {

  const parentDobBodies = contextForParents.map((p, i) => {
    const body = p.bodySnapshot.bodyPlainText
    const bodySnippet = body.slice(0, 200)
    return `
    START: Parent Opportunity Titled: ${p.bodySnapshot.title}
    ${bodySnippet}
    END: Parent Opportunity Titled: ${p.bodySnapshot.title}
    `
  })

  if (parentDobBodies.length === 0) {
    return ""
  }

  const promptContext = `
  Here is a small portion of what each Parent Opportunity has for its Document Body:

  ${parentDobBodies.join("\n")}
  
  `
  return promptContext
}
