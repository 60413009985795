import { DocMetadataSummary } from "../../../../../ai/documents/DocUpdatedEvent"
import { AiContextConfig, AllContextForAi } from "../../../../../ai/promptUtils/useAllContextForAi"
import { makeFrameworkSuggestionsForDocCall } from "../../../../../ai/suggestions/frameworkSuggestionsForDocAiCall"
import { AutoAiModeState } from "../../../../../state/AutoAiModeSlice"
import { documentFrameworkState } from "../../../../../state/documentFrameworksSlice"
import { dispatch, getCurrentAppState } from "../../../../../state/store"
import { setDocFrameworkCommandDefinition } from "../../framework/setDocFramework"
import { AutoAiStepOutcomeType } from "../AutoAiStepOutcomeType"


/** Attempts to auto draft a starting point for the document, including selecting a framework */
export const addFrameworkAiStep = async (
  docSummary: DocMetadataSummary | undefined,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  shouldStop: () => boolean,
): Promise<AutoAiStepOutcomeType> => {


  const documentId = docSummary?.documentId // || DocumentsState.getCurrentDocId(getCurrentAppState())
  if (!documentId) {
    return AutoAiStepOutcomeType.Skipped
  }


  // Only if no framework is selected
  const currentDocFrameworks = documentFrameworkState
    .getDocumentFrameworksForDoc(getCurrentAppState(), documentId)
  if (currentDocFrameworks.length > 0) {
    return AutoAiStepOutcomeType.Skipped
  }
  // TODO: ^^ Check if doc body is empty, then auto draft ???


  // TODO: Check for title and description, if NONE, then abort ???


  // get suggested frameworks
  dispatch(AutoAiModeState.setStatus({
    isDoingSomething: true,
    messageToUser: "Finding framework ..."
  }))

  const suggestedFrameworks = await makeFrameworkSuggestionsForDocCall(getContextForAi)
  if (suggestedFrameworks.length === 0) {

    // Q: Auto draft without a framework ???

    dispatch(AutoAiModeState.setStatus({
      isDoingSomething: false,
    }))
    return AutoAiStepOutcomeType.Failed
  }


  // Apply the first suggested framework
  const firstSuggestedFramework = suggestedFrameworks[0]

  dispatch(AutoAiModeState.setStatus({
    isDoingSomething: true,
    messageToUser: "Applying ..." + firstSuggestedFramework.title
  }))

  if (shouldStop()) {
    dispatch(AutoAiModeState.setStatus({
      isDoingSomething: false,
      messageToUser: "Stopping"
    }))
    return AutoAiStepOutcomeType.StoppedByUser
  }

  dispatch(AutoAiModeState.setStatus({
    isDoingSomething: true,
    messageToUser: "Applying ..."
  }))
  // apply the first one to the doc body


  setDocFrameworkCommandDefinition.triggerCommand?.({
    documentId,
    framework: firstSuggestedFramework,
    shouldAutoApplyToDocBody: true,
  })

  // await new Promise(resolve => setTimeout(resolve, 5000));

  dispatch(AutoAiModeState.setStatus({
    isDoingSomething: false,
  }))

  return AutoAiStepOutcomeType.Success
}
