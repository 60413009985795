import { OpportunityContextForAi } from "../../../screens/opportunities/useOpportunityProvider";
import { DocumentContextForAi } from "../../coach/AiCoachPrompts";
import { buildDocContextForPrompt } from "../../promptUtils/buildDocContextForPrompt";
import { buildOpportunityContextForPrompt } from "../../promptUtils/buildOpportunityContextForPrompt";


export const getDraftAnInterviewGuidePrompt = (
  companyContext: string,
  opportunityContext?: OpportunityContextForAi,
  docContext?: DocumentContextForAi,
) => {

  const docContextString = docContext ? buildDocContextForPrompt(docContext, false).detailsForPrompt : ""
  const opportunityContextString = opportunityContext ? buildOpportunityContextForPrompt(opportunityContext, false) : ""

  const prompt = `
### BEGIN CONTEXT
  
COMPANY CONTEXT: ${companyContext}

OPPORTUNITY CONTEXT: ${opportunityContextString}

CURRENT INTERVIEW GUIDE: ${docContextString}

### END CONTEXT

INSTRUCTIONS: 
Given the context above, generate a concise interview guide with questions that will help validate assumptions.
Only return the concise interview guide, without any preamble or postamble. 
Do not repeat the current interview guide document title in the response.
`
console.log("Interview Guide Prompt = ", prompt)

  return prompt
}
