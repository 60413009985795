import { PageLayoutSplitViewWrapper } from "../../components/pageLayout/pageLayoutSplitViewWrapper"
import { SuspenseErrorBoundary } from "../../components/SuspenseErrorBoundary"
import { SideMenu } from "../opportunityDocument/SideMenu"
import { AssumptionsList } from "./AssumptionsList"
import { useAppServices } from "../../components/appServices/useAppServices"
import { useMemo } from "react"
import { MainViewWrapper } from "../../components/pageLayout/MainViewWrapper"
import { OpportunitySubTabs } from "../opportunities/OpportunitySubTabs"
import { css } from "@emotion/react"
import { mediaQuerySmallWidth } from "../../components/pageLayout/styleHelpers"
import { useAppSelector } from "../../state/storeHooks"
import { loadingStatusState } from "../../state/loadingStatusSlice"
import LoadingSpinner from "../../components/LoadingSpinner"


export const AssumptionsPage = () => {


  const appServices = useAppServices()
  const {
    pageLayoutState,
    menuStructureProvider,
    assumptionsProvider,
    documentRelationsProvider,
    router,
    opportunityProvider,
  } = appServices


  const documentId = router.match.params.documentId
  const document = useMemo(() => {
    return menuStructureProvider.getMenuItem(documentId)
  }, [documentId, menuStructureProvider])

  const opportunityDoc = useMemo(() => {
    return opportunityProvider.currentOpportunityDoc
  }, [opportunityProvider.currentOpportunityDoc])


  const relatedDocs = useMemo(() => {
    return documentRelationsProvider.getRelationsForDocGrouped(documentId)
  }, [documentId, documentRelationsProvider])

  const isLoadingInitialData = useAppSelector(loadingStatusState.getIsLoadingInitialData)


  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={appServices}
        rightSideInitialState="visible"
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        mainContent={

          <div>
            <MainViewWrapper >

              {/* {document?.id && opportunityDoc &&
                <div>
                  <DocBreadcrumb
                    currentDocId={documentId}
                    flatMenuItems={[opportunityDoc]}
                    shouldShowCurrentDoc={true}
                  />
                </div>
              } */}
              <h1 css={css`
                margin-bottom: 16px;
                /* border-bottom: 1px solid #ccc; */
                padding-right: 45px;
                @media ${mediaQuerySmallWidth} {
                  padding-top: 10px;
                  font-size: x-large;
                  line-height: 1;
                }
              `}>
                <span>
                  {document?.title || "Assumptions"}
                </span>
                {/* <OpportunitySubTabs
                  currentPage="assumptions"
                  opportunityMainDocId={documentId}
                  displayAs="toggle-button"
                /> */}
              </h1>

              <OpportunitySubTabs
                currentPage="assumptions"
                opportunityMainDocId={documentId}
                displayAs="tabs"
              />

              {isLoadingInitialData ?
                <div css={css`
                  width: 100%;
                  text-align: center;
                  padding: 40px 20px;
                `}>
                  <LoadingSpinner
                    size="sm"
                    showAfterMsDelay={1000} />
                </div>
                :
                <div>
                  <AssumptionsList
                    assumptionsProvider={assumptionsProvider}
                    docsRelatedToMe={relatedDocs.toMe.assumptionDocs}
                    currentDocType={document?.type}
                    documentId={opportunityDoc?.id}
                  />

                </div>

              }

            </MainViewWrapper>
          </div>
        } />

    </div >

  )
}

