import { Button, useColorModeValue } from "@chakra-ui/react"
import { VerdiButtonProps } from "./VerdiButtonProps"


type Props = VerdiButtonProps & {
  label: String
}
export const ButtonSecondary = (props: Props) => {

  const isLightMode = useColorModeValue(true, false)


  return (
    <Button
      colorScheme="verdiButtonSecondary"
      color={isLightMode ? "#6903D8" : "white"}
      border={isLightMode ? "1px solid #99999944" : "1px solid #FFFFFF22"}
      size="sm"
      {...props}
    >
      {props.label}
    </Button>
  )

}
