import graphql from "babel-plugin-relay/macro"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { useChatHistoryQuery } from "./__generated__/useChatHistoryQuery.graphql"
import { useCreateChatMessage } from "./useCreateChatMessage"
import { useCallback, useMemo } from "react"
import { GlobalCommandType } from "../commands/GlobalCommandType"
import { useChatHistory_query$key } from "./__generated__/useChatHistory_query.graphql"
import { useChatHistoryQuery_PaginationQuery } from "./__generated__/useChatHistoryQuery_PaginationQuery.graphql"
import { ChatRoleType } from "@verdi/shared-constants"


type RawDynamicJson = {
  buttons?: [
    {
      title: string,
      commandType?: string,
      arguments: any
    }
  ]
}

const fragmentQl = graphql`
  fragment useChatHistory_query on Query 
    @refetchable(queryName: "useChatHistoryQuery_PaginationQuery") {
    chatMessageConnection(
    last: $last,
    before: $before,
    sortOrder:[{
      fieldName: createdAt
      order: ASC
    }],
  )
  @connection(key:"useChatHistoryQuery_chatMessageConnection")
   {
      __id
      edges {
        node {
          id
          role
          dynamicJsonRawString
          createdAt
          message
        }
      }
    }
  }
`

const INITIAL_LOAD_COUNT = 0
const PAGINATION_LOAD_COUNT = 10

const queryQL = graphql`
  query useChatHistoryQuery (
    $last: Int!,
    $before: String,
  ){
    ...useChatHistory_query
  }
`
export type ChatMessageButton = {
  title: string,
  commandType?: GlobalCommandType
  arguments?: any
}
export type ChatMessage = {
  content: string
  role: ChatRoleType,
  createdAt: Date
  buttons?: ChatMessageButton[]
}

/** Connects ChatMessages from database, with the UX */
export const useChatHistory = () => {

  const query = useLazyLoadQuery<useChatHistoryQuery>(queryQL, {
    last: INITIAL_LOAD_COUNT,
  })


  const {
    data: { chatMessageConnection },
    loadPrevious,
    isLoadingPrevious,
    hasNext,
    hasPrevious,
  } = usePaginationFragment<useChatHistoryQuery_PaginationQuery, useChatHistory_query$key>(fragmentQl, query)


  const allMessages = useMemo(() => {
    // console.log("did you get the memo? ", chatMessageConnection.edges)
    const messagesMapped = chatMessageConnection.edges.map((edge) => {
      // console.log("The stringy thing ", edge.node.dynamicJsonRawString)
      let buttons
      if (edge.node.dynamicJsonRawString) {
        const parsedButtons = JSON.parse(edge.node.dynamicJsonRawString) as RawDynamicJson || undefined
        if (parsedButtons) {
          buttons = parsedButtons.buttons?.map((button) => {
            return {
              title: button.title,
              commandType: button.commandType as GlobalCommandType,
              arguments: button.arguments
            }
          })
        }
        // console.log("The buttons ", buttons)
      }

      return {
        createdAt: edge.node.createdAt,
        role: edge.node.role as ChatRoleType,
        content: edge.node.message,
        buttons
      } as ChatMessage
    })
    return messagesMapped
  }, [chatMessageConnection.edges])


  const { createChatMessage, isSaving, } = useCreateChatMessage([chatMessageConnection.__id])

  const addMessage = useCallback((message: string, role: ChatRoleType, dynamicJson?: string) => {
    console.log("useChatHistory: addMessage: ", message, role, dynamicJson)
    createChatMessage({
      message,
      role,
      dynamicJson,
    })
  }, [])

  const clearCurrentSessionMessages = useCallback(() => {
    // TODO: Figure out how to clear out the graphql cache ???
    // Maybe put a smaller array in front of it?
  }, [])


  return {
    currentSessionMessages: allMessages, // TODO: decide how to handle sessions
    clearCurrentSessionMessages,
    addMessage,
    loadPastMessages: () => loadPrevious(PAGINATION_LOAD_COUNT),
    hasPastMessagesToLoad: hasPrevious,
    isLoadingMoreChatHistory: isLoadingPrevious,
  }
}

export type ChatHistoryProvider = ReturnType<typeof useChatHistory>
