import { StyleFunctionProps, defineStyle, defineStyleConfig } from "@chakra-ui/react"

const tabsStyle = defineStyle((props: StyleFunctionProps) => ({
  tab: {
    fontWeight: "semibold",
    color: "inherit",
    _selected: {
      color: "inherit",
      // color: mode(`${c}.500`, `${c}.300`)(props),
      _hover: {
        opacity: 1,
      }
    },
    _hover: {
      opacity: 0.7,
    }
  },
}))

export const tabsTheme = defineStyleConfig({
  variants: {
    enclosed: tabsStyle
  }
})
