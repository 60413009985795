import { useEffect } from "react"
import { GLOBAL_COMMAND_KEY } from "../triggerGlobalCommand";


type Props = {
  handleGlobalCommand: (evt: Event) => void
}

/** Listens for events that trigger global commands */
export const useGlobalCommandListener = ({
  handleGlobalCommand,
}: Props) => {

  useEffect(() => {
    window.addEventListener(GLOBAL_COMMAND_KEY, handleGlobalCommand)
    return () => {
      window.removeEventListener(GLOBAL_COMMAND_KEY, handleGlobalCommand)
    }

  }, []);
}
