import { DocumentSchema } from "@verdi/shared-constants"
import { ReplacementSuggestion } from "../../state/suggestions/suggestionsForPlaceholdersSlice"
import { ReplacePlaceholderWithCommandArgs } from "../../components/commands/commandDefinitions/documentBody/replacePlaceholderWith"
import { RunAIPromptOnDocCommandArgs } from "../../components/commands/commandDefinitions/documentBody/runAIPromptOnDoc"
import { cleanupAiOutputAsStrings } from "../utils/cleanupAiOutput"
import { InsertPosition } from "../documents/InsertPosition"


/** Converts raw AI output to an array of Suggestions for replacing placeholders 
 * with either literal text or output from another AI call. */
export const getReplacementSuggestionsFromResponse = (
  rawResponseText: string,
  replaceWithMode: "text" | "customAiPrompt",
  placeholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes | undefined,
) => {
  const suggestionTitles = cleanupAiOutputAsStrings(rawResponseText)
  let suggestions: ReplacementSuggestion[]
  if (replaceWithMode === "text") {
    suggestions = suggestionTitles.map((title) => {
      const args: ReplacePlaceholderWithCommandArgs = {
        commandTitle: title,
        insertPosition: InsertPosition.currentCursorSelection,
        textToWrite: title,
      }
      return {
        replaceWithMode: "text",
        args
      }
    })
  } else {
    suggestions = suggestionTitles.map((title) => {
      const args: RunAIPromptOnDocCommandArgs = {
        commandTitle: title,
        prompt: placeholderAttrs?.instructions || title,
        maxNumLines: placeholderAttrs?.maxLines || undefined,
        shouldDeleteCurrentSelection: true,
        promptOptions: {
          shouldAddNewDocPlaceholders: Boolean(placeholderAttrs?.maxLines),
        }
      }
      return {
        replaceWithMode: "customAiPrompt",
        args
      }
    })
  }
  return suggestions
}
