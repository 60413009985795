/**
 * @generated SignedSource<<b0e098d490ef1109015b89f9c193aec9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
export type CreateDocumentInput = {
  createAsTemplate?: boolean | null;
  createFromDocId?: string | null;
  description?: string | null;
  initialStepJson?: string | null;
  opportunityId?: string | null;
  parentDocId?: string | null;
  projectId?: string | null;
  title?: string | null;
  type: DocumentType;
};
export type useAddNewDocumentAppendMutation$variables = {
  input: CreateDocumentInput;
  updateConnectionIds: ReadonlyArray<string>;
  updateDocumentTemplateConnectionIds: ReadonlyArray<string>;
};
export type useAddNewDocumentAppendMutation$data = {
  readonly createDocument: {
    readonly description: string | null;
    readonly id: string;
    readonly opportunities: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
    }>;
    readonly parentDoc: {
      readonly id: string;
    } | null;
    readonly templateInfo: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"DocumentTemplateSideMenuItem_documentTemplate">;
    } | null;
    readonly title: string | null;
    readonly type: DocumentType;
  } | null;
};
export type useAddNewDocumentAppendMutation = {
  response: useAddNewDocumentAppendMutation$data;
  variables: useAddNewDocumentAppendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateConnectionIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateDocumentTemplateConnectionIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Document",
  "kind": "LinkedField",
  "name": "parentDoc",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVisibleToOrg",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddNewDocumentAppendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "createDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OpportunityDocumentSideMenu_opportunity"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentTemplateInfo",
            "kind": "LinkedField",
            "name": "templateInfo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DocumentTemplateSideMenuItem_documentTemplate"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddNewDocumentAppendMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "createDocument",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Opportunity",
            "kind": "LinkedField",
            "name": "opportunities",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeChildrenAtTheRoot",
                    "value": true
                  }
                ],
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "childDocs",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "documents(excludeChildrenAtTheRoot:true)"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentTemplateInfo",
            "kind": "LinkedField",
            "name": "templateInfo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPublished",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "templateInfo",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "updateDocumentTemplateConnectionIds"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "QueryDocumentTemplateEdge"
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createDocument",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "updateConnectionIds"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "DocumentEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "aa7d9e9b06e78cf361eda88b8279f358",
    "id": null,
    "metadata": {},
    "name": "useAddNewDocumentAppendMutation",
    "operationKind": "mutation",
    "text": "mutation useAddNewDocumentAppendMutation(\n  $input: CreateDocumentInput!\n) {\n  createDocument(input: $input) {\n    id\n    title\n    description\n    type\n    parentDoc {\n      id\n    }\n    opportunities {\n      ...OpportunityDocumentSideMenu_opportunity\n      id\n    }\n    templateInfo {\n      id\n      ...DocumentTemplateSideMenuItem_documentTemplate\n    }\n  }\n}\n\nfragment DocumentTemplateSideMenuItem_documentTemplate on DocumentTemplateInfo {\n  id\n  title\n  isPublished\n  document {\n    title\n    description\n    id\n    type\n  }\n}\n\nfragment OpportunityDocumentSideMenu_opportunity on Opportunity {\n  id\n  documents(excludeChildrenAtTheRoot: true) {\n    id\n    title\n    type\n    isVisibleToOrg\n    childDocs {\n      id\n      title\n      type\n      isVisibleToOrg\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff8809a105117e8e3f8ef01b1bcdee43";

export default node;
