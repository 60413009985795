import { OpportunityContextForAi } from "../../screens/opportunities/useOpportunityProvider"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { getDraftAResearchPlanPrompt } from "../prompts/docDrafter/researchPlanPrompts"
import { makeAiChatRequest } from "../requests/AiApiRequests"
import { cleanupAiOutputAsDocumentOutline } from "../utils/cleanupAiOutput"


export const getDraftResearchPlan = async (
  companyContext: string,
  opportunityContext?: OpportunityContextForAi,
  docContext?: DocumentContextForAi,
) => {

  const prompt = getDraftAResearchPlanPrompt(
    companyContext,
    opportunityContext,
    docContext,
  )

  const response = await makeAiChatRequest({
    prompt,
    messages: [],
  })

  const rawText = response?.aiResponseJson.content as string || ""
  const docOutline = cleanupAiOutputAsDocumentOutline(rawText)
  console.log("draftResearch", { rawText, docOutline })

  return docOutline
}
