import { useEffect, useMemo } from "react";
import { CurrentBlockContext } from "../../../../screens/document/tiptapEditor/utils/getCurrentBlockContext";
import { useAppSelector } from "../../../../state/storeHooks";
import { CurrentLineNodeState, docLineSuggestionsState } from "../../../../state/suggestions/docLineSuggestionsSlice";
import { dispatch, getCurrentAppState } from "../../../../state/store";
import { getLineSuggestions } from "../../../../ai/prompts/docDrafter/LineSuggestionsAiCall";
import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { writeToDocBodyAsTextCommandDefinition } from "../../commandDefinitions/documentBody/writeToDocBodyAsText";
import { loadingCommandPlaceholderCommandDefinition } from "../../commandDefinitions/utils/loadingCommandPlaceholder";
import { COMMAND_CHAR } from "../../../../screens/document/tiptapEditor/commandMenu/useDocEditorAsComboBoxInput";
import { AiContextConfig, AllContextForAi } from "../../../../ai/promptUtils/useAllContextForAi";
import { InsertPosition } from "../../../../ai/documents/InsertPosition";


type Props = {
  currentBlockContext: CurrentBlockContext | undefined
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
  menuIsOpen: boolean
}
/** Provides AI driven suggestions to fill in the current line. */
export const useLineSuggestionCommands = ({
  currentBlockContext,
  getContextForAi,
  menuIsOpen,
}: Props) => {

  const isLoading = useAppSelector(docLineSuggestionsState.getIsGeneratingLineSuggestions)
  const currentLineSuggestionStrings = useAppSelector(docLineSuggestionsState.getAll)

  const currentLineSuggestionCommandGroup = useMemo(() => {
    const commands: VerdiCommand[] = []
    if (isLoading) {
      const loadingCommand = loadingCommandPlaceholderCommandDefinition.buildMenuItem({
        loadingMessage: "Generating line suggestions ..."
      })
      commands.push(loadingCommand)
    } else {

      const suggestionCommands = buildCommands(currentLineSuggestionStrings, currentBlockContext)
      commands.push(...suggestionCommands)
    }

    return {
      title: "Line Suggestions",
      commands,
    } as VerdiCommandGroup
  }, [currentLineSuggestionStrings, isLoading])


  useEffect(() => {
    if (!currentBlockContext) return
    if (!menuIsOpen) return

    generateNewSuggestionsIfNeeded(currentBlockContext, getContextForAi)

  }, [menuIsOpen, currentBlockContext, getContextForAi])


  return {
    currentLineSuggestionCommandGroup,
  }
}


/** Determines if new Line suggestions are needed, and if so generates them. */
const generateNewSuggestionsIfNeeded = (
  currentBlockContext: CurrentBlockContext,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  const { isGenerating, currentLineNode, suggestions } = getCurrentAppState().docLineSuggestions

  if (isGenerating) return

  const newNode: CurrentLineNodeState = {
    startPos: currentBlockContext.node.start,
    depth: currentBlockContext.node.depth,
    blockType: currentBlockContext.nearestNestableNodeType,
  }

  const isStillOnTheSameLine =
    currentLineNode?.blockType === newNode.blockType
    && currentLineNode?.startPos === newNode.startPos
    && currentLineNode?.depth === newNode.depth
  if (isStillOnTheSameLine) {
    return
  }

  // Must be a blank line or just a "/" character
  const currentLineText = currentBlockContext.currentLineText?.trim() || ""
  const lineIsNotBlank = currentLineText.length > 0 && currentLineText !== COMMAND_CHAR
  if (lineIsNotBlank) {
    if (suggestions.length > 0) {
      dispatch(docLineSuggestionsState.setAll([]))
    }
    return
  }
  console.log("generateNewSuggestionsIfNeeded: IS NOW GENERATING", { isGenerating, newNode, currentLineNode, currentBlockContext })

  dispatch(docLineSuggestionsState.setCurrentLineStartPos(newNode))

  // Since there was no reason to return early, then generate new suggestions
  generateLineSuggestionCommands(currentBlockContext, getContextForAi)
}


const generateLineSuggestionCommands = async (
  currentBlockContext: CurrentBlockContext,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
) => {

  dispatch(docLineSuggestionsState.setIsGenerating(true))

  const suggestions = await getLineSuggestions(getContextForAi)
  dispatch(docLineSuggestionsState.setAll(suggestions))

  const commands = buildCommands(suggestions, currentBlockContext)

  dispatch(docLineSuggestionsState.setIsGenerating(false))
  return commands
}


const buildCommands = (suggestions: string[], currentBlockContext: CurrentBlockContext | undefined) => {
  const isSection = currentBlockContext?.nearestNestableNodeType === "section"

  const commands: VerdiCommand[] = []
  for (const suggestion of suggestions) {
    // build commands
    const command = writeToDocBodyAsTextCommandDefinition.buildMenuItem({
      textToWrite: suggestion,
      title: suggestion,
      insertPosition: InsertPosition.currentCursorSelection,
      shouldMoveCursorToNextNode: isSection,
      shouldAutoOpenCommandMenu: isSection,
      shouldDeleteSlashCommand: true,
    })
    commands.push(command)
  }
  return commands
}
