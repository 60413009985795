import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react'
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation'
import { UpdateDocumentTemplateInfoInput, useUpdateDocumentTemplateInfoMutation, useUpdateDocumentTemplateInfoMutation$data } from './__generated__/useUpdateDocumentTemplateInfoMutation.graphql'


const mutationQL = graphql`
  mutation useUpdateDocumentTemplateInfoMutation (
    $input: UpdateDocumentTemplateInfoInput!
  ) {
    updateDocumentTemplateInfo(input: $input) {
      id,
      title,
      isPublished
    }
  }
`

export function useUpdateDocumentTemplateInfo(
  onCreated?: (response: useUpdateDocumentTemplateInfoMutation$data['updateDocumentTemplateInfo']) => void
) {
  const [commit, isSaving] = useErrorHandledMutation<useUpdateDocumentTemplateInfoMutation>(mutationQL)
  const updateDocumentTemplateInfo = useCallback((
    input: UpdateDocumentTemplateInfoInput
  ) => {
    commit({
      variables: {
        input,
      },
      onCompleted: (response) => {
        if (onCreated) {
          onCreated(response.updateDocumentTemplateInfo)
        }
      }
    })
  }, [])
  return [updateDocumentTemplateInfo, isSaving] as const
}