import { useState, useCallback, useMemo } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { useRouter } from 'found'

import {
  AbsoluteCenter,
  Alert,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { css } from "@emotion/react";

import { menuBackgroundColors, documentBackgroundColors } from '../components/ColorModeValues'
import { VerdiLogo } from '../components/logo/VerdiLogo'
import posthog, { posthogCallback } from "../helpers/posthog";

import LoginWithGoogle from './LoginWithGoogle'
import {
  checkLoginStatus,
  login,
} from "./network";
import { VerdiIconEmail, VerdiIconIsNotVisible, VerdiIconIsVisible, VerdiIconLock } from "../components/icons/VerdiIcons";

const Login = ({ routeData }: { routeData: any }) => {
  const { router } = useRouter();
  const [error, setError] = useState<string>();
  const [loginCreds, setLoginCreds] = useState({
    username: ``,
    password: ``,
  });
  const [loggingIn, setLoggingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleForgotPasswordPress = useCallback(() => {
    window.location.href = '/forgot-password';
  }, []);

  const handleRegisterPress = useCallback(() => {
    window.location.href = '/register'
  }, []);

  const onSuccess = async () => {
    await checkLoginStatus(environment);
    router.replace(`/`);
  };

  const flows = useMemo(() => {
    return ['login']
  }, [])

  const isReadyForPasswordAuth =
    loginCreds.username?.length && loginCreds.password?.length;

  const attemptLogin = async () => {
    if (!isReadyForPasswordAuth) {
      return;
    }
    setLoggingIn(true);

    try {
      const { jwt, error } = await login(
        loginCreds.username,
        loginCreds.password
      );
      if (jwt) {
        posthog.capture('loginSuccess', { flows: ['login'] })
        await onSuccess();
      } else {
        posthog.capture('loginFail', { error, flows: ['login'] })
        setError(error?.message || error);
      }
    } catch (error: any) {
      posthog.capture('loginFail', { error, flows: ['login'] })
      setError(error.message);
    } finally {
      setLoggingIn(false);
    }
  };

  const environment = useRelayEnvironment()

  const dialogBgColor = useColorModeValue(
    documentBackgroundColors.light,
    documentBackgroundColors.dark
  )


  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor={useColorModeValue(
        menuBackgroundColors.light,
        menuBackgroundColors.dark
      )}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "375px" }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor={dialogBgColor}
              boxShadow="sm"
              borderRadius="lg"
              py="25"
            >
              <div
                style={{
                  margin: `12px`,
                  marginLeft: `33px`,
                  maxWidth: 275,
                }}
              >
                <VerdiLogo />
              </div>

              <LoginWithGoogle
                setError={setError}
                flows={flows}
              />

              <Box position='relative'
                padding='4'>
                <Divider />
                <AbsoluteCenter bg={dialogBgColor} px='4'>
                  <span css={css`opacity: 0.5;`}>
                    OR
                  </span>
                </AbsoluteCenter>
              </Box>


              {error && <Alert status="error">{error}</Alert>}

              <FormControl w="100%">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={
                      <VerdiIconEmail
                        color={useColorModeValue("gray.400", "gray.500")}
                      />
                    }
                  />
                  <Input
                    id="usernameemail"
                    variant={`outline`}
                    value={loginCreds.username}
                    onFocus={posthogCallback(undefined, 'fieldFocus', { field: 'username', flows: ['login'] })}
                    onChange={(e) =>
                      setLoginCreds({ ...loginCreds, username: e.target.value })
                    }
                    placeholder="Email Address"
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={
                      <VerdiIconLock
                        color={useColorModeValue("gray.400", "gray.500")}
                      />
                    }
                  />
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    variant={`outline`}
                    value={loginCreds.password}
                    onFocus={posthogCallback(undefined, 'fieldFocus', { field: 'password', flows: ['login'] })}
                    onChange={(e) =>
                      setLoginCreds({ ...loginCreds, password: e.target.value })
                    }
                  />
                  <InputRightElement>
                    <Button
                      h="1.75rem"
                      variant="link"
                      onClick={posthogCallback(() => setShowPassword(!showPassword), 'showPasswordToggle', { state: !showPassword, flows: ['login'] })}
                      color={useColorModeValue("gray.400", "gray.500")}
                    >
                      {showPassword ? <VerdiIconIsNotVisible /> : <VerdiIconIsVisible />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                width="100%"
                onClick={posthogCallback(attemptLogin, 'login', { flows: ['login'] })}
                isLoading={loggingIn}
                disabled={!isReadyForPasswordAuth || loggingIn}
                variant="solid"
                colorScheme="verdiButtonPrimary"
                color="#fff"
              >
                Log In
              </Button>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ marginTop: 4 }}
              >
                <Link variant="link"
                  onClick={posthogCallback(handleForgotPasswordPress, 'toForgotPassword', { flows: ['login'] })}>
                  Forgot Password?
                </Link>
              </Box>



            </Stack>
          </form>
        </Box>
      </Stack>
      <Box>
        No account?
        <Link
          onClick={posthogCallback(handleRegisterPress, 'toRegister', { flows: ['login'] })}
          marginLeft={2}>
          Create one
        </Link>
      </Box>
    </Flex>
  );
};

export default Login;
