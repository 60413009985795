import { useCallback, useMemo } from "react"
import { KanbanBoard } from "../../components/kanban/KanbanBoard"
import { useAppServices } from "../../components/appServices/useAppServices"
import { Data, DocumentSchema } from "@verdi/shared-constants"
import { KanbanCardData } from "../../components/kanban/KanbanCard"
import { useAppSelector } from "../../state/storeHooks"
import { OpportunitiesState, OpportunitiesViewMode } from "../../state/opportunitiesSlice"
import { OpportunityStatusTitleMap, OpportunityStatusType, kanbanColumnOptionsForOpportunities } from "../../components/kanban/KanbanColumnOptions"
import { css } from "@emotion/react"
import { formatDistance } from "date-fns"
import { updateOpportunityCommandDefinition } from "../../components/commands/commandDefinitions/opportunity/updateOpportunity"
import { navigateToCommandDefinition } from "../../components/commands/commandDefinitions/navigateTo"
import { getUrlForStandaloneDocument } from "../../routes"
import { OpportunityRisk, calculateOpportunityRisk } from "../assumptions/risk/OpportunityRiskBadge"
import { AssumptionsState } from "../../state/assumptionsSlice"
import { DocRelationsState } from "../../state/docRelationsSlice"
import { addOpportunityShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/opportunity/addOpportunityShowOptions"
import { filterOutSpecialDocTypes } from "../opportunityDocument/subMenu/SideMenuSectionForSingleOpportunity"
import { EmptyStateMessage } from "../../components/EmptyStateMessage"
import LoadingSpinner from "../../components/LoadingSpinner"
import { KanbanListView } from "../../components/kanban/KanbanListView"
import { MAX_MENU_DEPTH } from "../document/organize/MenuStructureManager"
import { showDocInfoCommandMenuCommandDefinition } from "../../components/commands/commandDefinitions/utils/showDocInfoCommandMenu"
import { BasicDocumentDetails } from "../document/BasicDocumentDetails"
import { DocumentsState } from "../../state/DocumentsSlice"
import { AddNewOpportunityFromHome } from "./AddNewOpportunityFromHome"
import { GlobalMindMapGraph } from "../../components/mindMap/GlobalMindMapGraph"


const RecentThreshold = 10 * 1000 // 10 seconds

/** Handles Opportunities CRUD for the Kanban board View */
export const OpportunitiesKanbanBoard = (props: any) => {

  const {
    menuStructureProvider,
  } = useAppServices()


  const allAssumptions = useAppSelector(AssumptionsState.getAllAssumptions)
  const allDocRelations = useAppSelector(DocRelationsState.getAll)
  const allDocs = useAppSelector(DocumentsState.getFlatMenuItems)

  const riskByDocId = useMemo(() => {
    if (!allAssumptions) return {}
    // // Group the assumptions by DocId
    // const assumptionsByDocId: Record<string, Data.AssumptionModel[]> = {}
    // allAssumptions.forEach((a) => {
    //   const docId = a.mainDoc?.id
    //   if (!docId) return
    //   if (!assumptionsByDocId[docId]) assumptionsByDocId[docId] = []
    //   assumptionsByDocId[docId].push(a)
    // })

    // Get each opportunity's related assumptions
    const existingDocIds = allDocs.filter((d) => !d.deletedAt).map(d => d.id)
    const docRelations = allDocRelations.filter((r) => r.type === Data.DocumentRelationType.assumptionOf
      && existingDocIds.includes(r.fromDocId))
    const opportunityAssumptions: Record<string, Data.AssumptionModel[]> = {}
    docRelations.forEach((r) => {
      const opportunityId = r.toDocId
      const assumption = allAssumptions.find((a) => a.mainDoc?.id === r.fromDocId)
      if (!assumption) {
        return
      }
      if (!opportunityAssumptions[opportunityId]) {
        opportunityAssumptions[opportunityId] = []
      }
      opportunityAssumptions[opportunityId].push(assumption)
    })

    // calculateOpportunityRisk
    const toReturn: Record<string, OpportunityRisk> = {}
    Object.keys(opportunityAssumptions).forEach(key => {
      const assumptions = opportunityAssumptions[key]
      const risk = calculateOpportunityRisk(assumptions)
      toReturn[key] = risk
    })

    return toReturn
  }, [allAssumptions, allDocRelations, allDocs])


  const viewMode = useAppSelector(OpportunitiesState.getOpportunitiesViewMode)
  const rawOpportunities = useAppSelector(OpportunitiesState.getAllOpportunityPartials)
  const isLoadingRawOpportunities = useAppSelector(OpportunitiesState.getIsLoadingOpportunityPartials)


  const opportunityCards = useMemo<{ rootCards: KanbanCardData[], allCards: KanbanCardData[] }>(() => {
    const now = new Date()
    const rawOpportunityDocs = menuStructureProvider.menuItemsStructured.opportunitiesWithMixedChildren

    const nonRootOpportunityCards: KanbanCardData[] = [] // Collect all opportunities that are not root level here

    const mapToCardData = (doc: Data.Menu.MenuItemData, recursionLimit: number): KanbanCardData => {

      const opportunity = rawOpportunities.find((o) => o.mainDocId === doc.id)
      const createdAt = opportunity?.createdAt ? new Date(opportunity?.createdAt) : undefined
      const lastActivityAt = opportunity?.lastActivityAt ? new Date(opportunity?.lastActivityAt) : createdAt
      const shouldHighlight = lastActivityAt ? now.getTime() - lastActivityAt.getTime() < RecentThreshold : false
      const lastActivitySince = lastActivityAt ? formatDistance(lastActivityAt, now, { addSuffix: true }) : undefined
      const lastActivityToolTip = lastActivityAt ? `Activity ${formatDistance(lastActivityAt, now, { addSuffix: true })}` : "No activity yet"

      const risk = riskByDocId[doc.id]

      const childOpportunities: KanbanCardData[] = []
      const childOpportunityMenuItems = doc.children.filter(c => c.type === DocumentSchema.DocumentType.opportunity)
      if (childOpportunityMenuItems.length > 0 && recursionLimit > 0) {
        for (const child of childOpportunityMenuItems) {
          const childOpportunity = mapToCardData(child, recursionLimit - 1)
          childOpportunities.push(childOpportunity)
          nonRootOpportunityCards.push(childOpportunity)
        }
      }

      return {
        id: opportunity?.id || "",
        documentId: doc.id,
        title: doc.title,
        statusColumnId: opportunity?.status.toString() || "Some column",
        statusText: opportunity?.status ? OpportunityStatusTitleMap[opportunity?.status as OpportunityStatusType] : undefined || "",
        childCount: filterOutSpecialDocTypes(doc.children).length || undefined,
        childOpportunities,
        createdAt,
        lastActivityAt,
        lastActivityIsRecent: shouldHighlight ? true : undefined,
        lastActivitySince,
        lastActivityToolTip,
        risk,
      }
    }

    const rootCards: KanbanCardData[] = rawOpportunityDocs
      .map((doc: Data.Menu.MenuItemData) => mapToCardData(doc, MAX_MENU_DEPTH))
      .filter((card) => card.id)

    const allCards = [
      ...rootCards,
      ...nonRootOpportunityCards.filter((card) => card.id)
    ]


    return {
      /** Opportunities that do not have parents, but may still have childOpportunities */
      rootCards,
      /** All Opportunity cards in a simple, flat array. */
      allCards,
    }
  }, [menuStructureProvider.menuItemsStructured, rawOpportunities, riskByDocId])


  const onStatusColumnChange = useCallback(async (cardId: string, newStatusColumnId: string) => {
    updateOpportunityCommandDefinition.triggerCommand?.({
      commandName: "",
      newStatusText: newStatusColumnId,
      opportunityId: cardId,
      fieldsToUpdate: {},
    })
  }, [])


  const onCardClick = useCallback((cardId: string) => {
    const opportunity = rawOpportunities.find((o) => o.id === cardId)
    const mainDocId = opportunity?.mainDocId
    if (!mainDocId) {
      console.warn("No mainDocId found for opportunity", { opportunity })
      return
    }
    navigateToCommandDefinition.triggerCommand?.({
      urlOfPage: getUrlForStandaloneDocument(mainDocId),
      nameOfPage: "Opportunity",
    })
  }, [rawOpportunities])


  const onAddClick = useCallback((
    statusColumnId: OpportunityStatusType,
    shouldAutoAddWithDefaults?: boolean,
  ) => {
    console.log("Add clicked ", { statusColumnId })
    addOpportunityShowOptionsCommandDefinition.triggerCommand?.({
      status: statusColumnId,
      shouldAutoAddWithDefaults,
    })
  }, [])

  const onShowInfoBadgeClick = useCallback((card: KanbanCardData, rect: DOMRect) => {

    const document: BasicDocumentDetails = {
      id: card.documentId,
      title: card.title,
      description: "",
      type: DocumentSchema.DocumentType.opportunity,
    }

    showDocInfoCommandMenuCommandDefinition.triggerCommand?.({
      document,
      rect,
      filterMode: "opportunityKanban",
    })

  }, [])


  return (
    <div>

      {(isLoadingRawOpportunities) &&
        <LoadingSpinner
          size="sm"
          showAfterMsDelay={500} />
      }

      {!isLoadingRawOpportunities && opportunityCards.allCards.length === 0 &&
        <AddNewOpportunityFromHome />

        // <EmptyStateMessage
        //   title="No opportunities yet..."
        //   onButtonClick={() => onAddClick(OpportunityStatusType.Explore, true)}
        //   buttonLabel="Add an Opportunity">
        //   <p>
        //     Here you can see the state of all of your opportunities.
        //   </p>
        // </EmptyStateMessage>
      }

      {viewMode === OpportunitiesViewMode.List &&
        <KanbanListView
          cards={opportunityCards.rootCards}
          columnOptions={kanbanColumnOptionsForOpportunities}
          onCardClick={onCardClick}
          onStatusColumnChange={onStatusColumnChange}
          onAddClick={onAddClick}
          onShowInfoBadgeClick={onShowInfoBadgeClick}
        />
      }

      {viewMode === OpportunitiesViewMode.Kanban && opportunityCards.allCards.length > 0 &&
        <div css={css`
          overflow: auto;
          max-height: calc(100vh - 150px);
          width: calc(100% - 20px);
        `}>
          <KanbanBoard
            cards={opportunityCards.allCards}
            columnOptions={kanbanColumnOptionsForOpportunities}
            onCardClick={onCardClick}
            onStatusColumnChange={onStatusColumnChange}
            onAddClick={onAddClick}
            onShowInfoBadgeClick={onShowInfoBadgeClick}
          />

        </div>
      }

      {viewMode === OpportunitiesViewMode.MindMap &&
        <div css={css`
          display: flex;
          flex-direction: row;
          overflow: auto;
        `}>
          <GlobalMindMapGraph />
        </div>
      }

    </div>
  )
}
