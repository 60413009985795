import { SuggestionForDocument } from "../useWhatsNextSuggester"
import { AllContextForAi } from "../../../../../../../ai/promptUtils/useAllContextForAi"
import { makeSuggestChildDocsAiCall } from "../../../../../../../ai/suggestions/makeSuggestChildDocsAiCall"
import { VerdiCommand } from "../../VerdiCommand"
import { uniqueId } from "lodash"
import { addDocWithFrameworkCommandDefinition } from "../../../../../../../components/commands/commandDefinitions/document/addDocWithFramework"


export const getWhatsNextForOpportunity = async (
  onCommandCompleted: (id: string, sectionTitleToRemove?: string) => void,
  contextForAi: AllContextForAi,
): Promise<SuggestionForDocument[]> => {

  const { currentDoc: docContext } = contextForAi

  console.log("getting suggestions for opportunity", { contextForAi })

  if (!docContext) {
    console.warn("useWhatsNextForOpportunity: no doc context")
    return []
  }
  if (!docContext.title) {
    // IDEA: Add a command that sets focus to the title, and pop up a CM with suggestions ???
    console.warn("useWhatsNextForOpportunity: no doc title")
    return []
  }

  // TODO: See what current child notes this has, and remove those from the list of suggestions

  const addChildNoteCommands = (await getCommandsForSuggestedChildDocsWithFrameworks(onCommandCompleted, contextForAi))
    .map(c => {
      return {
        command: c,
      } as SuggestionForDocument
    })

  return [...addChildNoteCommands]
}


export const getCommandsForSuggestedChildDocsWithFrameworks = async (
  onCommandCompleted: (commandId: string) => void,
  contextForAi: AllContextForAi,
) => {

  const frameworks = await makeSuggestChildDocsAiCall(contextForAi)
  const newDocWithFrameworkCommands: VerdiCommand[] =
    frameworks.map(framework => {
      const id = uniqueId("addDocWithFramework-")
      return addDocWithFrameworkCommandDefinition.buildMenuItem({
        cmdTitle: `Add Note > ${framework.title}`,
        docTitle: framework.title,
        docDescription: framework.description,
        framework,
        id,
        onCompleted: async () => {
          onCommandCompleted(id)
        }
      })
    })

  return newDocWithFrameworkCommands
}
