import { cleanupAiOutputAsDocumentOutline } from "../../ai/utils/cleanupAiOutput"
import { FrameworksState } from "../../state/frameworksSlice"
import { getCurrentAppState } from "../../state/store"
import { DocumentOutlineEntry } from "../document/tiptapEditor/utils/buildFirstDocStepFromJson"


/** Creates the starting point of an assumption document body, based on the default framework for assumptions */
export const createDocOutlineForNewAssumption = (answerText: string) => {
  const firstStep: DocumentOutlineEntry = {
    type: "text",
    content: answerText
  }

  const framework = FrameworksState.getDefaultFrameworkForNewAssumption(getCurrentAppState())
  if (!framework) {
    return {
      initialOutline: [firstStep],
      framework,
    }
  }

  const docTemplate = framework.docTemplates.find(t => !t.isExample)?.bodyMarkdown
  if (!docTemplate) {
    return {
      initialOutline: [firstStep],
      framework,
    }
  }

  const combinedOutline = answerText + "\n" + docTemplate

  const initialOutline = cleanupAiOutputAsDocumentOutline(combinedOutline)

  return {
    initialOutline,
    framework,
  }
}
