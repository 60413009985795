import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IdeaNodeData } from '../components/mindMap/internals/nodeTypes/idea/IdeaNode'
import { Edge } from '@xyflow/react'
import { LocalStorageKeys } from './loaders/LocalStorageKeys'
import { WrappedGraphNode } from '../components/mindMap/internals/nodeTypes/NodeTypes'


interface InternalState {
  isLoading: boolean
  messageToUser: string
  nodes: WrappedGraphNode[]
  edges: Edge[]
  directionType: DirectionType
  mindMaps: MindMapModel[]
}
const initialState: InternalState = {
  isLoading: false,
  messageToUser: "",
  nodes: [],
  edges: [],
  directionType: "LR",
  mindMaps: []
}

export const mindMapSlice = createSlice({
  name: 'mindMap',
  initialState,
  reducers: {
    setMindMaps: (state, action: PayloadAction<MindMapModel[]>) => {
      state.mindMaps = action.payload
    },
    addMindMap: (state, action: PayloadAction<MindMapModel>) => {
      state.mindMaps.push(action.payload)
    },
    removeMindMap: (state, action: PayloadAction<string>) => {
      state.mindMaps = state.mindMaps.filter(m => m.id !== action.payload)
    },
    setStatus: (state, action: PayloadAction<{ isLoading: boolean, messageToUser?: string }>) => {
      state.isLoading = action.payload.isLoading
      state.messageToUser = action.payload.messageToUser || ""
    },
    setNodes: (state, action: PayloadAction<WrappedGraphNode[]>) => {
      state.nodes = action.payload
    },
    removeNodeAndItsEdges: (state, action: PayloadAction<{ nodeId: string }>) => {
      const { nodeId } = action.payload
      state.nodes = state.nodes.filter(n => n.id !== nodeId)
      state.edges = state.edges.filter(e => e.source !== nodeId && e.target !== nodeId)
    },
    /** Updates values on the `node.data` property for a given node */
    updateIdeaNodeData: (state, action: PayloadAction<{ id: string, data: Partial<IdeaNodeData> }>) => {
      const { id, data } = action.payload
      const node = state.nodes.find(n => n.id === id)
      if (node) {
        node.data = { ...node.data, ...data }
      }
    },
    setEdges: (state, action: PayloadAction<Edge[]>) => {
      state.edges = action.payload
    },
    setDirection: (state, action: PayloadAction<DirectionType>) => {
      state.directionType = action.payload
    },
    saveStateToLocalStorage: (state) => {
      localStorage.setItem(LocalStorageKeys.mindMapTemp, JSON.stringify(state))
    },
  },
  selectors: {
    getIsLoading: (state) => state.isLoading,
    getMessageToUser: (state) => state.messageToUser,
    /** Gets ReactFlow nodes */
    getNodes: (state) => state.nodes,
    /** Gets ReactFlow edges */
    getEdges: (state) => state.edges,
    /** Alters the layout of the graph when changed */
    getDirection: (state) => state.directionType,
    getNodeAndItsEdges: (state, nodeId: string) => {
      const node = state.nodes.find(n => n.id === nodeId)
      if (!node) return undefined
      const edges = state.edges.filter(e => e.source === nodeId || e.target === nodeId)
      return { node, edges }
    },
  }
})

const getFromLocalStorage = () => {
  const savedState = localStorage.getItem(LocalStorageKeys.mindMapTemp)
  if (savedState) {
    const parsedState = JSON.parse(savedState)
    return parsedState as InternalState
  }
  return undefined
}
const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.mindMapTemp)
}

export const MindMapState = {
  ...mindMapSlice.actions,
  ...mindMapSlice.selectors,
  getFromLocalStorage,
  clearLocalStorage,
}

export default mindMapSlice.reducer

export type DirectionType = "TB" | "BT" | "LR" | "RL";


export type MindMapModel = {
  id: string
  title?: string
  graphData: MindMapData
}

export type MindMapData = {
  nodes: WrappedGraphNode[]
  edges: Edge[]
  directionType?: DirectionType
}


