import { ApiTypes, Data } from "@verdi/shared-constants";
import { OpportunityContextForAi } from "./useOpportunityProvider";
import { getDocBodySnapshotFromCache } from "../../state/loaders/getDocBodySnapshotFromCache";


export const getContextForParentsOfCurrentOpportunity = async (
  currentOpportunity: OpportunityContextForAi | undefined
) => {

  if (!currentOpportunity || currentOpportunity.parentOpportunities.length === 0) {
    return []
  }

  const allDocIds = [
    currentOpportunity.opportunityMainDocId,
    ...currentOpportunity.parentOpportunities.map(p => p.id)
  ]

  // Load body snapshots for each of the parent opportunities
  const snapshots: ApiTypes.DocumentSnapshotResponseBody[] = []
  const docBodySnapshotPromises = allDocIds.map(async (docId) => getDocBodySnapshotFromCache(docId))
  const snapshotResults = await Promise.allSettled(docBodySnapshotPromises)

  for (const results of snapshotResults) {
    if (results.status === "fulfilled" && results.value) {
      const thing = results.value
      snapshots.push(thing)
    }
  }

  return snapshots.map((s, i) => {
    return {
      menuItem: currentOpportunity.parentOpportunities[i],
      bodySnapshot: s,
    } as ParentOpportunityContext
  })

}

/** IDEA: Can we use this for the current opportunity as well as the parents? */
export type ParentOpportunityContext = {
  menuItem: Data.Menu.MenuItemData
  bodySnapshot: ApiTypes.DocumentSnapshotResponseBody
  // assumptions: string[]
}
