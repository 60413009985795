import { DocMetadataSummaryDiff } from "../../../ai/documents/DocUpdatedEvent";
import { syncTaskCheckboxes } from "./syncTaskCheckboxes";


/** Syncs any referenced data from within the doc body, with relational data outside of the doc body 
 * 
 *  This is separate from the doc editor markup sync (collaboration)
*/
export const syncDocBodyWithDatabase = async (summaryDiff: DocMetadataSummaryDiff) => {

  // ensure TaskCheckboxes are in sync
  syncTaskCheckboxes(summaryDiff)

}
