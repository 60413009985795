import { Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react"
import { Data } from "@verdi/shared-constants"
import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary"
import { Dictionary } from "lodash"
import { css } from "@emotion/react"


type Props = {
  customers: Data.StripeShared.StripeCustomer[]
  subscriptions: Dictionary<Data.StripeShared.StripeSubscription[]>
  loadSubscriptionsForCustomer: (customerId: string) => void
}
export const StripeCustomerList = ({
  customers,
  subscriptions,
  loadSubscriptionsForCustomer,
}: Props) => {


  return (
    <div>
      <h2>Customers in Stripe</h2>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <tr>
              <th>Customers</th>
              <th>Subscriptions</th>
            </tr>
          </Thead>
          <Tbody>
            {customers.map(c =>
              <Tr key={c.id}>
                <Td>
                  {c.email}<br />
                  <small>{c.id}</small>
                </Td>
                <Td>
                  {subscriptions[c.id]?.map(s =>
                    <div key={s.id}
                      css={css`
                      border: 1px solid #99999933;
                      padding: 8px;
                      margin: 8px;
                  `}>
                      Start: {s.current_period_start} <br />
                      End {s.current_period_end} <br />

                      <small>
                        {s.id}
                      </small>
                    </div>
                  )}
                  <ButtonSecondary
                    label={"Load Subscriptions"}
                    size="xs"
                    onClick={() => loadSubscriptionsForCustomer(c.id)}
                  />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  )
}
