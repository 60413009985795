import { ProsemirrorNodes } from "@verdi/shared-constants";
import { EditorState } from "prosemirror-state";
import { ChainedCommands } from "@tiptap/core";
import VeNode from "../VeNode";

export const addEmptyChildIfThereAreNone = (
  state: EditorState,
  chain: () => ChainedCommands,
  dispatch: ((args?: any) => any) | boolean | undefined
) => {
  if (!state.selection.empty) return false;
  const cursorPos = state.selection.$from;
  const titleNode = VeNode.fromResolvedPosition(cursorPos);
  if (!titleNode) return false;
  const nestableParent = titleNode.findNestableParent(state);
  if (!nestableParent) return false;
  const contentList = nestableParent.node().maybeChild(1);
  const hasChildren = contentList && contentList.childCount;
  if (hasChildren) return false;
  if (contentList) {
    console.warn(
      "Did not expect a contentList with no children. Schema should disallow it"
    );
    return false;
  }

  if (dispatch) {
    const titleResolvedPosition = titleNode.resolvePosition(state);
    const insertPosition =
      titleResolvedPosition.after(titleResolvedPosition.depth) - 1;
    const insertJSONContent =
      ProsemirrorNodes.makeContentListWithAtLeastOneChild([
        ProsemirrorNodes.makeFreeTextBlock(""),
      ]);
    chain().insertContentAt(insertPosition, insertJSONContent, {
      updateSelection: false,
    });
  }
  return true;
};
