import { makeAiChatRequest } from "../requests/AiApiRequests"
import { AllContextForAi } from "../promptUtils/useAllContextForAi"
import { cleanupAiOutputAsStrings } from "../utils/cleanupAiOutput"


/** Returns a string array of suggested section titles for a document */
export const makeNextSectionTitleAiCall = async (
  allContext: AllContextForAi,
  titlesToNotInclude: string[] = [],
  isForOpportunity: boolean,
  amountToReturn: number,
) => {

  const prompt = isForOpportunity
    ? buildPromptForOpportunity(titlesToNotInclude, amountToReturn)
    : buildPromptForDocument(allContext.currentDoc?.body || "", titlesToNotInclude)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
  })

  // return response?.aiResponseString?.split("\n") || []
  const rawText = response?.aiResponseJson.content as string || ""
  const sectionTitles = cleanupAiOutputAsStrings(rawText)

  return sectionTitles
}


export const buildPromptForOpportunity = (
  promptTitlesToNotInclude: string[] = [],
  amountToReturn: number,
) => {

  return `
INSTRUCTIONS: Think as a product expert would. Write exactly ${amountToReturn} highly relevant new section headings that the user should create to flush
out this opportunity. An opportunity should outline the outcome, problem, solutions, and any other sections to build out context on the
opportunity. Use the document title and description to help you intuit what the user is trying to accomplish.
Order the sections with most relevant first. 

EXAMPLES OF SECTION HEADINGS TO INCLUDE: Problem Statement, Potential Solutions, Target Customers, Product Requirements, Resources Needed, Considerations, 
Data Collection Ideas, Possible Risks, Key Metrics, Potential Product Features, Research Plan, etc. 

Do not repeat any section headings that are already in the document.
Do not suggest sections like "Research Findings", "User Insights", "Case Studies", "Success Stories", "Testimonials", "Feedback", or similar headings.
${promptTitlesToNotInclude.length > 0
      ? `Do not include any of the following titles, or titles that have the same meaning as the following:\n${promptTitlesToNotInclude.join(
        "\n"
      )}`
      : ""
    }

Return only the ${amountToReturn} section headings, each on a separate line.

Example response 1:
name of first section
name of first section
another section title

Example response 2:
Problem Statement
Possible Solutions
Risk Mitigation Strategies
`;
}


export const buildPromptForDocument = (
  documentContext: string,
  promptTitlesToNotInclude: string[] = [],
) => {
  const amountToReturn = 3;

  return `You are a product expert. You suggest what highly relevant sections to write next in the current document given the current document framework and context.

INSTRUCTIONS: Write exactly ${amountToReturn} highly relevant new section headings to write to make this document more complete. 
Use the document title, body, framework, and other context to help you intuit what the purpose of this document is and adjust your section headings accordingly.
Order the sections with most relevant first. 

${promptTitlesToNotInclude.length > 0
      ? `Do not include these section headings in your suggestion because they are already in the current document: ${promptTitlesToNotInclude.join(", ")}`
      : ""
    }
Do not suggest sections like "Research Findings", "User Insights", "Case Studies", "Success Stories", "Testimonials", "Feedback", or similar headings.

Return the ${amountToReturn} section headings each on a separate line
EXAMPLE 1:
name of first section
name of first section
another section title

EXAMPLE 2:
Introduction
Problem Statement
Possible Solutions
`;
};
