import { Data, DocumentSchema } from "@verdi/shared-constants"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { Tag } from "../../../../state/TagsSlice"
import { TagsProvider } from "../../../../state/TagsProvider"


const cmdType = GlobalCommandType.addDocAutoHandleType


/** Creates a new document, automatically handling if it is an opportunity, assumption or other type */
export const addDocAutoHandleTypeCommandDefinition: GlobalCommandDefinition<AddDocAutoHandleTypeCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddDocAutoHandleTypeCommandArgs) => {
    const title = "Add Doc"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  triggerCommand: (args: AddDocAutoHandleTypeCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  },
  processCommand: async (
    args: AddDocAutoHandleTypeCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    await addDocAutoHandleType(args, services)

    onProcessingComplete(true)
  },
}

export type AddDocAutoHandleTypeCommandArgs = {
  newDocTitle?: string
  newDocType: DocumentSchema.DocumentType
  tagsToAdd?: Tag[]
  docRelation?: Partial<Data.DocumentRelationDto>
  parentDocId?: string
  onCreated: (newDocId: string) => void
  onStatusUpdate: (isLoading: boolean, message?: string) => void
}


export const addDocAutoHandleType = async (args: AddDocAutoHandleTypeCommandArgs, services: AppServices) => {

  const {
    documentRelationsProvider,
  } = services

  const {
    newDocType,
    newDocTitle,
    onStatusUpdate,
    onCreated,
    tagsToAdd,
    docRelation,
    parentDocId,
  } = args

  onStatusUpdate(true, "Creating a new document ...")

  const onError = (error: string) => {
    onStatusUpdate(false, error)
    services.toast.showError(error)
  }
  const onDocCreated = async (docId: string | undefined) => {
    if (!docId) {
      services.toast.showError("Failed to create a new note")
      onStatusUpdate(false, "Failed to create a new note")
      return
    }

    // Attach docRelation if any
    if (docRelation) {
      const fromDocId = docId
      const relationType = (() => {
        if (docRelation.type) return docRelation.type as Data.DocumentRelationType
        if (newDocType === DocumentSchema.DocumentType.assumption) return Data.DocumentRelationType.assumptionOf
        if (newDocType === DocumentSchema.DocumentType.opportunity) return Data.DocumentRelationType.opportunityOf
        return Data.DocumentRelationType.relatedTo
      })();
      const toDocId = docRelation.toDocId || parentDocId

      if (toDocId && relationType && fromDocId) {
        await documentRelationsProvider.createRelation(fromDocId, relationType, toDocId)
      } else {
        console.warn("addDocAutoHandleType: docRelation was not created because of missing data", { docRelation })
      }
    }


    // Attach DocumentTags if any
    if (tagsToAdd) {
      for (const tag of tagsToAdd) {
        await TagsProvider.addDocTag(docId, tag)
      }
    }

    onStatusUpdate(false)
    onCreated(docId)
  }


  if (newDocType === DocumentSchema.DocumentType.opportunity) {

    services.opportunityProvider.createOpportunity({
      title: newDocTitle,
      parentDocId,
    }, (result) => {
      onDocCreated(result.newMainDocId)
    })

  } else if (newDocType === DocumentSchema.DocumentType.assumption) {

    services.assumptionsProvider.create(
      {
        docTitle: newDocTitle || "",
        userShouldReview: false,
        frameworkQuestionId: undefined,
        assumptionToAdd: {
          confidenceLevel: 0,
          relevanceLevel: 0,
        },
        answerText: "",
        relatedDocumentId: parentDocId,
      },
      (newDocId: string) => onDocCreated(newDocId),
      (errorMessage: string, errorDetails?: string) => onError(errorMessage + " " + (errorDetails || "")),
    )

  } else {

    services.documentRelationsProvider.createNewDoc({
      type: newDocType || DocumentSchema.DocumentType.document,
      title: newDocTitle,
      parentDocId,
    },
      (error: string) => onError(error),
      (newDocId: string) => onDocCreated(newDocId),
    )
  }
}
