import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useAppServices } from "../../appServices/useAppServices"
import { useCallback, useEffect, useMemo } from "react"
import { positionTheCommandMenu } from "./positionTheCommandMenu"
import LoadingSpinner from "../../LoadingSpinner"
import { useKeyDownHandler } from "../../floatingBlockContext/useKeyDownHandler"
import { useColor } from "../../../utility-hooks/useColor"
import { useCommandMenuWH } from "./useCommandMenuWH"
import { ComboBox } from "./comboBox/ComboBox"
import { useComboBoxController } from "./comboBox/useComboBoxController"
import { VerdiCommand, VerdiCommandGroup } from "../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"


/** Allows adding a new document via the Enchiridion, from anywhere in the app */
export const MiniCommandMenuModal = () => {

  const { commandMenuProvider } = useAppServices()
  const {
    miniCommandMenuArgs: args,
    closeMiniCommandMenu,
    miniCommandMenuIsOpen,
  } = commandMenuProvider


  const comboBoxController = useComboBoxController({
    mode: "search-and-filter",
    onRunCommand: (command) => runCommand(command),
    onClose: (wasDismissed) => {
      args.onCloseCallback?.()
      handleClose()
    },
  })

  const handleClose = useCallback(() => {
    closeMiniCommandMenu()
    comboBoxController.setSearchText("")
  }, [closeMiniCommandMenu, comboBoxController])

  // const commands = useMemo((): CmdKCommand[] => {
  //   if (!args?.commands) return []
  //   return args?.commands.map(c => ({
  //     itemId: c.id || c.name || "",
  //     title: c.name,
  //     icon: renderIconOnCommandMenuItem(c),
  //     isLoading: c.isLoading,
  //     onExecute: () => {
  //       if (c.runCommand) {
  //         c.runCommand()
  //       } else {
  //         args.onItemSelected?.(c)
  //       }
  //       if (!c.shouldKeepOpenOnRun) {
  //         handleClose()
  //       }
  //     },
  //   }))
  // }, [args, handleClose])


  const commandMenuWH = useCommandMenuWH()


  const position = useMemo(() => {
    return positionTheCommandMenu(commandMenuWH, args?.rectOfAnchorElement, Boolean(args?.shouldAdjustRectForScrollOffset))
  }, [args?.rectOfAnchorElement, commandMenuWH, args?.shouldAdjustRectForScrollOffset])


  const runCommand = useCallback((c: VerdiCommand) => {
    if (c.runCommand) {
      c.runCommand()
    } else {
      args.onItemSelected?.(c)
    }
    if (!c.shouldKeepOpenOnRun) {
      handleClose()
    }
  }, [handleClose, args])


  const menuBgColor = useColor("surfaceBg")
  const borderColor = useColor("surfaceBorder")


  useKeyDownHandler((keyboardEvent) => {
    if (keyboardEvent.key === "Escape") {
      handleClose()
    }
  })


  useEffect(() => {
    const group: VerdiCommandGroup = {
      commands: args.commands || [],
      title: "",
      hideTitle: true,
    }
    comboBoxController.setCommandsInGroups([group])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.commands])


  useEffect(() => {
    comboBoxController.setNoResultsCommand(args.commandForNoResults)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.commandForNoResults])


  if (!args) return null


  // const maxCmdListHeight = commandMenuWH.height - 30
  //   - (args.title ? 32 : 0)
  //   - (args.subTitle ? 20 : 0)


  return (
    <Modal
      onClose={() => handleClose()}
      isOpen={miniCommandMenuIsOpen}
    >
      <ModalOverlay
        backgroundColor={args.transparentOverlay ? "transparent" : "var(--chakra-colors-blackAlpha-300);"}
      />
      <ModalContent
        css={css`
          position: absolute;
          top: ${position.top}px;
          left: ${position.left}px;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: ${commandMenuWH.width}px;
          max-height: ${commandMenuWH.height}px;
          background-color: ${menuBgColor};
          padding: 8px;
          border: 1px solid ${borderColor};
          border-radius: 8px;
          overflow-y: hidden;
          box-shadow: 1px 1px 8px 4px rgba(0,0,0,0.1);
          margin-top: 0px;
        `}
        onClick={(evt) => evt.stopPropagation()}
      >
        {args.title &&
          <div css={css`
            padding: 4px 12px 12px;
            font-size: 1em;
            font-weight: bold;
            text-overflow: ellipsis;
          `}>
            {args.title}
          </div>
        }

        {args.subTitle &&
          <div css={css`
            padding: 0 12px 8px;
            font-size: 0.8em;
          `}>
            {args.subTitle}
          </div>
        }

        {args.isLoading &&
          <div css={css`
            height: 200px;
            width: 100%;
            display:flex;
            justify-content: center;
            align-items: center;
          `}>
            <div>
              <LoadingSpinner
                size="md"
                label={args.loadingText || undefined}
              />
            </div>
          </div>
        }

        {!args.isLoading &&
          <div>
            <ComboBox
              comboBoxState={comboBoxController}
            />
          </div>
        }
      </ModalContent>
    </Modal>
  )
}





