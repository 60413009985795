

export const getFrameActionPrompt = (
  assumptionTitles: string[],
  actionTitleAndDescription: string,
  conceptCount = 5,
) => {


  const prompt = `
I am a product manager trying to validate whether the following assumptions are true or not: 
${assumptionTitles.join(",\n")}

I want to validate the assumptions by performing the following action:
${actionTitleAndDescription}

Types of returnTypes include:
concept
task

INSTRUCTIONS: Draft me a document outline of things I should be considering in performing this action. 
This outline should consist of concepts that contain a title and a description. 
This outline should be concise and only include up to ${conceptCount} concepts.

Return results in the following format:
concept name|concept description
another concept name|another concept description

Example 1: If the action is "Conduct Interviews"
Main Objective| To better understand the user's needs
Who to Interview| Users who have used the product or those that might use it in the future
Interview Format| 30 minute video call
  `
  return prompt
}
