import { memo, useCallback, useState } from "react";
import { Position, NodeProps, Handle, useReactFlow, Node, NodeToolbar } from "@xyflow/react";
import { AutoResizingInputText } from "../../../../forms/AutoResizingInputText";
import { css } from "@emotion/react";
import { IconButtonPrimary } from "../../../../buttons/IconButtonPrimary";
import { VerdiIconAdd, VerdiIconAiSuggestions, VerdiIconDocument, VerdiIconDocumentAdd, VerdiIconSuggestionAccept, VerdiIconSuggestionReject } from "../../../../icons/VerdiIcons";
import { IconButtonTertiary } from "../../../../buttons/IconButtonTertiary";
import { useMindMapContext } from "../../context/useMindMapContext";
import { Tag } from "../../../../../state/TagsSlice";
import { TagsChangeSet } from "../../../../../state/TagsProvider";
import { SelectTagDropdown } from "../../../../../screens/tags/SelectTagDropdown";
import { DocumentSchema } from "@verdi/shared-constants";
import { NewNodeMenuList } from "../addNew/NewNodeMenuList";
import LoadingSpinner from "../../../../LoadingSpinner";


export const IdeaNodeView = memo((props: NodeProps<IdeaNodeWrapperData>) => {

  const { id, data, selected: isSelected } = props
  const { docId } = data

  const { updateNodeData } = useReactFlow();
  const [text, setText] = useState<string>(data.text);
  const updateText = (text: string) => {
    setText(text);
    updateNodeData(id, { text });
  };

  const [isShowingAdd, setIsShowingAdd] = useState(false)
  const {
    isLoading,
    onTagSelected,
    generateSuggestedChildNodes,
    acceptSuggestedNode,
    rejectSuggestedNode,
    navigateToDocument,
  } = useMindMapContext()


  const onItemSelected = useCallback((tagChanges: TagsChangeSet) => {
    console.log("ideaNode: onItemSelected", { tagChanges })
    const newTag = tagChanges.tagsToAdd?.[0]
    onTagSelected(props.id, newTag)
  }, [onTagSelected, props.id])


  return (
    <div css={css`
      border: 1px ${docId ? "solid" : "dashed"} #99999944;
      border-radius: 8px;
      background-color: ${isSelected ? `var(--item-selected-bg)` : `var(--surface-bg)`};
      padding: 0;
      width: 240px;
    `}
    >

      <NodeToolbar
        isVisible={data.toolbarVisible as any}
        position={Position.Right}
        offset={-5}
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}>

        <IconButtonPrimary
          aria-label="Add child"
          title="Add child"
          size={"xs"}
          icon={<VerdiIconAdd css={css` pointer-events: none; `} />}
          onClick={() => setIsShowingAdd(!isShowingAdd)}
        />
        {isShowingAdd &&
          <div onClick={() => setIsShowingAdd(false)}>
            <NewNodeMenuList
              parentNode={props as any as Node}
            />
          </div>
        }

      </NodeToolbar>


      {Boolean(docId) &&
        <NodeToolbar
          isVisible={data.toolbarVisible as any}
          position={Position.Bottom}
          offset={-5}
          css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          `}>

          <IconButtonPrimary
            aria-label="Get suggestions"
            title="Get suggestions"
            size={"xs"}
            icon={<VerdiIconAiSuggestions css={css` pointer-events: none; `} />}
            onClick={() => {
              if (!docId) {
                console.warn("No docId to generate suggestions for")
                return
              }
              generateSuggestedChildNodes(props as any as Node)
            }}
          />

        </NodeToolbar>
      }



      <div css={css`
        border-radius: 8px;
        overflow: hidden;
      `}>
        <div css={css`
          overflow-y: auto;
          max-height: 50px;
          margin-bottom: 4px;
        `}>

          <AutoResizingInputText
            value={text || ""}
            onChange={(event) => updateText(event.target.value)}
            size="xs"
            className="nodrag"
            borderRadius={8}
            border={0}
            padding="6px 6px 0"
            placeholder={data.placeholderText}
          />
        </div>
        <div css={css`
            /* opacity: 0.5; */
            font-size: 0.5em;
            /* line-height: 0em; */
            margin: 0 0 0 4px;
            padding: 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}>
          <span css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          
          `}>
            <SelectTagDropdown
              documentId={docId}
              currentTags={data.tags || []}
              onTagSelected={onItemSelected}
            />
          </span>

          {isLoading && isSelected &&

            <LoadingSpinner size="xs" showAfterMsDelay={500} />
          }

          {!isLoading &&
            <div>
              {docId
                ?
                <div>
                  <span>
                    <span css={css`
                  ${!docId && css`opacity: 0.3;`}
                `}>
                      {data.docType}
                    </span>
                    <IconButtonTertiary
                      aria-label={docId ? "View details" : "Add a note"}
                      title={docId ? "View details" : "Add a note"}
                      size={"xs"}
                      height="10px"
                      opacity={docId ? 0.5 : 0.2}
                      _hover={{ opacity: 0.7 }}
                      minWidth={0}
                      onClick={() => navigateToDocument(docId)}
                      icon={docId
                        ? <VerdiIconDocument css={css` pointer-events: none;`} />
                        : <VerdiIconDocumentAdd css={css` pointer-events: none;`} />
                      }
                    />
                  </span>
                </div>
                :
                <div className="nodrag">
                  <IconButtonTertiary
                    size="xs"
                    aria-label="Accept"
                    title="Accept suggestion"
                    color="var(--suggestion-accept-color)"
                    icon={<VerdiIconSuggestionAccept />}
                    onClick={() => acceptSuggestedNode(props as any as Node)}
                  />
                  <IconButtonTertiary
                    size="xs"
                    aria-label="Reject"
                    title="Reject suggestion"
                    color="var(--suggestion-reject-color)"
                    icon={<VerdiIconSuggestionReject />}
                    onClick={() => rejectSuggestedNode(props as any as Node)}
                  />
                </div>

              }
            </div>
          }




        </div>
      </div>

      {/* <Handle id="right" type="source" position={Position.Right} />
      <Handle id="left" type="source" position={Position.Left} /> */}
      <Handle id="child" type="target" position={Position.Left}
        css={css`
          opacity: 0;
       `}
      />
      <Handle id="parent" type="source" position={Position.Right}
        css={css`
          opacity: 0;
        `}
      />



    </div>
  );
})

export type IdeaNodeWrapperData = Node & {
  type: "idea"
  data: IdeaNodeData
}

export type IdeaNodeData = {
  text: string;
  tags?: Tag[];
  docId?: string;
  docType?: DocumentSchema.DocumentType;
  placeholderText?: string;
  // isLoading?: boolean; // Might put this on a separate "add button" node
}

