/**
 * @generated SignedSource<<f5f108589e1e12cc1e05efada02f9c63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditAiPromptOptionsMenu_document$data = {
  readonly aiPrompts: ReadonlyArray<{
    readonly id: string;
    readonly isDocContext: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"AiPromptFormModal_documentLevelContextAiPrompt" | "AiPromptPreview_otherBlockLevelPrompts" | "useUpdateAiPromptMutation_documentAiPrompt">;
  }>;
  readonly id: string;
  readonly title: string | null;
  readonly " $fragmentType": "EditAiPromptOptionsMenu_document";
};
export type EditAiPromptOptionsMenu_document$key = {
  readonly " $data"?: EditAiPromptOptionsMenu_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditAiPromptOptionsMenu_document">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditAiPromptOptionsMenu_document",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentAiPrompt",
      "kind": "LinkedField",
      "name": "aiPrompts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDocContext",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useUpdateAiPromptMutation_documentAiPrompt"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AiPromptFormModal_documentLevelContextAiPrompt"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AiPromptPreview_otherBlockLevelPrompts"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "ccfae848714f8e7ee4c4883adddf61fc";

export default node;
