import { useRouter } from "found"
import { OnNewDocumentMutationCompleted, useNewDocumentMutation } from "./useAddNewDocument"
import { format } from "date-fns"
import { useCallback } from "react"
import { getUrlForDocument } from "../../../routes"
import { useAppServices } from "../../../components/appServices/useAppServices"


type Props = {
  shouldShowChatOnRedirect: boolean,
}

/** Handles the creation of a quick note, and redirecting to it */
export const useNewQuickNoteCreator = ({
  shouldShowChatOnRedirect,
}: Props) => {

  const appServices = useAppServices()
  const router = useRouter()

  const redirectToNotePage = useCallback((documentId: string) => {
    const url = `/home/notes/${documentId}?chatIsVisible=${shouldShowChatOnRedirect}&quickNotes=true`
    router.router.push(url)
  }, [router, shouldShowChatOnRedirect])

  const redirectToOpportunityDocPage = useCallback((documentId: string, opportunityId: string) => {
    const url = getUrlForDocument(documentId, opportunityId) + `?chatIsVisible=${shouldShowChatOnRedirect}&quickNotes=true`
    router.router.push(url)
  }, [])

  const handleOnCompleted: OnNewDocumentMutationCompleted = (response, errors) => {
    console.log("look what I made mom: ", response)
    if (!response.createDocument) {
      console.error("document is null")
      return
    }
    redirectToNotePage(response.createDocument?.id)
  }

  const [createDocument, isSaving] = useNewDocumentMutation(false, [], handleOnCompleted)

  const createNewQuickNote = useCallback((
    title?: string,
    initialStepJsonString?: string
  ) => {
    if (isSaving) return
    createDocument({
      type: "quickNote",
      title: title ? title : format(new Date(), "MMM dd - hh:mm a"),
      initialStepJson: initialStepJsonString,
    }, appServices.menuStructureProvider)
  }, [createDocument, isSaving])


  const createNewDocWithinOpportunity = useCallback((
    opportunityId: string,
    title?: string,
    initialStepJsonString?: string,
  ) => {
    if (isSaving) return
    createDocument({
      type: "document",
      title: title ? title : format(new Date(), "MMM dd - hh:mm a"),
      initialStepJson: initialStepJsonString,
      opportunityId,
    }, appServices.menuStructureProvider,
      (response) => {
        if (!response.createDocument) {
          console.error("failed to get new document data ", response)
          return
        }
        redirectToOpportunityDocPage(response.createDocument?.id, opportunityId)
      })
  }, [createDocument, isSaving, redirectToOpportunityDocPage])

  return {
    createNewQuickNote,
    isSaving,
    createNewDocWithinOpportunity,
  }
}
