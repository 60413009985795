import { useCallback, useMemo } from "react"
import { css } from "@emotion/react"
import { KanbanCardData } from "./KanbanCard"
import { KanbanColumnOption, OpportunityStatusType } from "./KanbanColumnOptions";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "@hello-pangea/dnd";
import { KanbanListItemDragNDrop } from "./KanbanListItemDragNDrop";
import { KanbanListItem } from "./KanbanListItem";


type KanbanListViewProps = {
  cards: KanbanCardData[],
  columnOptions: KanbanColumnOption[],
  onTitleChange?: (cardId: string, newTitle: string) => void,
  onStatusColumnChange: (cardId: string, newStatusColumnId: string) => void,
  onCardClick: (cardId: string) => void,
  onAddClick: (statusColumnId: OpportunityStatusType) => void,
  onShowInfoBadgeClick: (card: KanbanCardData, rect: DOMRect) => void,
}

export const KanbanListView = ({
  cards,
  columnOptions,
  onCardClick,
  onAddClick,
  onStatusColumnChange,
  onTitleChange,
  onShowInfoBadgeClick,
}: KanbanListViewProps) => {


  const cardsSorted = useMemo(() => {

    // sort each column by most recent activity
    const sorted = cards.sort((a, b) => {
      if (a.statusColumnId !== b.statusColumnId) {
        return b.statusColumnId.localeCompare(a.statusColumnId)
      }
      if (!a.lastActivityAt || !b.lastActivityAt) return 0
      return b.lastActivityAt.getTime() - a.lastActivityAt.getTime()
    })

    return cards // sorted
  }, [cards])


  // const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
  //   const { source, destination, draggableId } = result;

  //   console.log("Drag end", { result, provided, source, destination, draggableId })

  // }, [onStatusColumnChange])



  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      max-width: 800px;
    `}>
      {cardsSorted.map((cardData, key) => (
        <KanbanListItem
          key={key}
          cardData={cardData}
          setTitle={() => onTitleChange?.(cardData.id, "new title")}
          isBeingDragged={false}
          isDraggingOver={false}
          onCardClick={onCardClick}
          onShowInfoBadgeClick={onShowInfoBadgeClick}
        />
      ))}

      {/* When we are ready to revisit the nested Drag and Drop */}
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <div>
          {cardsSorted.map((cardData, key) => (
            <KanbanListItemDragNDrop
              key={key}
              index={key}
              dndId={cardData.id}
              cardData={cardData}
              setTitle={() => onTitleChange?.(cardData.id, "new title")}
              isBeingDragged={false}
              isDraggingOver={false}
              onCardClick={onCardClick}
              onShowInfoBadgeClick={onShowInfoBadgeClick}
            />
          ))}
        </div>
      </DragDropContext> */}
    </div>

  )
}
