import { useCallback, useEffect, useRef, useState } from "react"
import { useAiSubscription } from "../../ai/utils/useAiSubscription"
import { useAiChatBridge } from "../chat/useAiChatBridge"
import { DocContextIncludeArgs, UseAiTipTapBridge } from "../../ai/documents/useAiTipTapBridge"
import { UseCompanyContext, useCompanyContext } from "../../screens/organization/companyContext/useCompanyContext"
import { AssumptionsProvider, useAssumptionsProvider } from "../../screens/assumptions/useAssumptionsProvider"
import { useDeRiskingStepsProvider } from "../../screens/deRiskingSteps/useDeRiskingStepsProvider"
import { DocumentRelationsProvider, useDocumentRelationsProvider } from "../../screens/documentRelation/useDocumentRelationsProvider"
import { useInsightsDetector } from "../../ai/suggestions/useInsightsDetector"
import { MenuStructureForAllDocsProvider, useGetMenuStructureForAllDocs } from "../../screens/document/organize/useGetMenuStructureForAllDocs"
import { ToastProvider, useShowToast } from "../toast/useShowToast"
import { useOnboardingManager } from "../../screens/onboarding/useOnboardingManager"
import { useLoggedInUserProvider } from "./users/useLoggedInUserProvider"
import { WorkflowSuggester, useWorkflowSuggester } from "../../ai/suggestions/useWorkflowSuggester"
import { DocServicesContext } from "../../screens/document/docServices/useRegisterDocServices"
import { AddNewDocGlobalProvider, useAddNewDocGlobalProvider } from "../../screens/documents/add/globalModal/useAddNewDocGlobalProvider"
import { CommandMenuProvider, useCommandMenuProvider } from "../commands/commandMenu/useCommandMenuProvider"
import { RouterState, useRouter } from "found"
import { RecentDocumentsProvider, useRecentDocumentsProvider } from "../../screens/document/organize/useRecentDocumentProvider"
import { GlobalConfirmDialogProvider, useGlobalConfirmDialog } from "../commands/globalComponents/useGlobalConfirmDialog"
import { PageLayoutState, usePageLayoutState } from "../pageLayout/usePageLayoutState"
import { AddNewOpportunityGlobalProvider, useAddNewOpportunityGlobalProvider } from "../../screens/documents/add/globalModal/useAddNewOpportunityGlobalProvider"
import { DocumentFrameworksProvider } from "../../screens/documentFrameworks/DocumentFrameworksProvider"
import { useCustomAIPromptModalProvider } from "../commands/customPrompt/useCustomPromptProvider"
import { OpportunityContextForAi, OpportunityProvider, useOpportunityProvider } from "../../screens/opportunities/useOpportunityProvider"
import { LoadInitialAppState } from "../../state/loaders/LoadInitialAppState"
import { AiContextConfig, AllContextForAi, useAllContextForAi } from "../../ai/promptUtils/useAllContextForAi"


/** Top level place to declare dependencies used across the app 
 * 
 *  FOR CONSUMING USE: useAppServices
*/
export const useRegisterAppServices = () => {

  // IDEA: Does this need to be a hook? Could it be a NON react function / object / class?
  // If not a hook, how would it work with the graphQL hooks?


  // Independent Services (that have no dependencies)
  const addNewDocGlobalProvider = useAddNewDocGlobalProvider()
  const addNewOpportunityGlobalProvider = useAddNewOpportunityGlobalProvider()
  const aiSubscription = useAiSubscription()
  const commandMenuProvider = useCommandMenuProvider()
  const confirmDialogProvider = useGlobalConfirmDialog()
  const customAIPromptModalProvider = useCustomAIPromptModalProvider()
  const deRiskingStepsProvider = useDeRiskingStepsProvider()
  const loggedInUserProvider = useLoggedInUserProvider()
  const pageLayoutState = usePageLayoutState()
  const recentDocumentsProvider = useRecentDocumentsProvider()
  const router = useRouter()
  const toast = useShowToast()
  const onboardingManager = useOnboardingManager()


  // Services that provide context to the AI
  const getDocumentContextForAi = (include?: DocContextIncludeArgs) => servicesForCurrentDoc?.getDocumentContextForAi(include)
  const getOpportunityContextForAi = async () => await opportunityProvider.getOpportunityContextForAi()
  const companyContextProvider = useCompanyContext(loggedInUserProvider)
  const { getContextForAi } = useAllContextForAi({
    getCompanyContextForAi: companyContextProvider.getCompanyContextForPrompt,
    getDocumentContextForAi,
    getOpportunityContextForAi,
  })


  // Services that depend on other services
  const aiChatBridge = useAiChatBridge({
    getCompanyContextForPrompt: companyContextProvider.getCompanyContextForPrompt,
    getDocumentContextForAi,
    getOpportunityContextForAi,
  })
  const menuStructureProvider = useGetMenuStructureForAllDocs(recentDocumentsProvider)
  const documentRelationsProvider = useDocumentRelationsProvider(menuStructureProvider, onboardingManager)
  const assumptionsProvider = useAssumptionsProvider({ documentRelationsProvider, menuStructureProvider, })
  const opportunityProvider = useOpportunityProvider(menuStructureProvider, router, assumptionsProvider, onboardingManager)

  const insightsDetector = useInsightsDetector({ getDocumentContextForAi, })
  const workflowSuggester = useWorkflowSuggester({ getDocumentContextForAi, })


  // Services that are TIGHTLY coupled to the current document are controlled within ServicesForCurrentDoc
  /** As Documents are loaded and unloaded, these should change */
  const [servicesForCurrentDoc, setServicesForCurrentDoc] = useState<DocServicesContext | undefined>()
  /** Registers services directly coupled to the current document */
  const setDocumentServices = useCallback((
    docServices: DocServicesContext,
  ) => {
    setServicesForCurrentDoc(docServices)
    aiSubscription.setDocumentSubscription(docServices.documentSubscription)

  }, [aiSubscription, setServicesForCurrentDoc])

  const clearDocumentServices = useCallback(() => {
    setServicesForCurrentDoc(undefined)
    aiSubscription.setDocumentSubscription(undefined)

  }, [aiSubscription, setServicesForCurrentDoc])


  // Load once data
  const hasRanOnceRef = useRef<boolean>(false)
  useEffect(() => {
    if (hasRanOnceRef.current) return
    hasRanOnceRef.current = true

    // Put any "run only once at the beginning" calls here
    LoadInitialAppState() // Redux state
    DocumentFrameworksProvider.ensureAllAreLoaded()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return {
    addNewDocGlobalProvider,
    addNewOpportunityGlobalProvider,
    aiChatBridge,
    aiSubscription,
    assumptionsProvider,
    getContextForAi,
    commandMenuProvider,
    companyContextProvider,
    confirmDialogProvider,
    customAIPromptModalProvider,
    deRiskingStepsProvider,
    documentRelationsProvider,
    hasDocument: Boolean(servicesForCurrentDoc?.document?.id),
    insightsDetector,
    loggedInUserProvider,
    menuStructureProvider,
    onboardingManager,
    opportunityProvider,
    pageLayoutState,
    recentDocumentsProvider,
    router,
    servicesForCurrentDoc,
    toast,
    workflowSuggester,
    getDocumentContextForAi,
    getOpportunityContextForAi,
    /** Registers document related dependencies */
    setDocumentServices,
    /** De-Registers document related dependencies */
    clearDocumentServices,
  } as AppServices
}


/** This was `ReturnType<typeof useRegisterAppServices>` but 
 *  Typescript had issues with this not being more explicit
 * */
export type AppServices = {
  addNewDocGlobalProvider: AddNewDocGlobalProvider
  addNewOpportunityGlobalProvider: AddNewOpportunityGlobalProvider
  aiChatBridge: ReturnType<typeof useAiChatBridge>
  aiSubscription: ReturnType<typeof useAiSubscription>
  assumptionsProvider: AssumptionsProvider
  commandMenuProvider: CommandMenuProvider
  companyContextProvider: UseCompanyContext
  confirmDialogProvider: GlobalConfirmDialogProvider
  customAIPromptModalProvider: ReturnType<typeof useCustomAIPromptModalProvider>
  deRiskingStepsProvider: ReturnType<typeof useDeRiskingStepsProvider>
  documentRelationsProvider: DocumentRelationsProvider
  /** Retrieves context that the AI typically should know about */
  getContextForAi: (optionArgs?: AiContextConfig) => Promise<AllContextForAi>
  hasDocument: boolean
  insightsDetector: ReturnType<typeof useInsightsDetector>
  loggedInUserProvider: ReturnType<typeof useLoggedInUserProvider>
  menuStructureProvider: MenuStructureForAllDocsProvider
  onboardingManager: ReturnType<typeof useOnboardingManager>
  opportunityProvider: OpportunityProvider
  pageLayoutState: PageLayoutState
  recentDocumentsProvider: RecentDocumentsProvider
  router: RouterState<any>
  servicesForCurrentDoc: DocServicesContext | undefined,
  toast: ToastProvider
  workflowSuggester: WorkflowSuggester
  getDocumentContextForAi: (include?: DocContextIncludeArgs) => ReturnType<UseAiTipTapBridge["getDocumentContextForAi"]> | undefined
  getOpportunityContextForAi: () => Promise<OpportunityContextForAi | undefined>
  /** Registers document related dependencies */
  setDocumentServices: (docServices: DocServicesContext) => void
  /** De-Registers document related dependencies */
  clearDocumentServices: () => void
}
