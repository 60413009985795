import { Edge, EdgeChange, Node, NodeChange, ReactFlowProvider } from "@xyflow/react"
import { MindMapGraph } from "./MindMapGraph"
import { useCallback, useEffect, useMemo } from "react"
import { dispatch } from "../../state/store"
import { MindMapState } from "../../state/MindMapSlice"
import { initialEdgesSampleData, initialNodesSampleData } from "./internals/sampleData"
import { css } from "@emotion/react"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { useAppServices } from "../appServices/useAppServices"
import { VerdiIconDebug, VerdiIconDelete, VerdiIconDocument, VerdiIconOpportunity } from "../icons/VerdiIcons"
import { ButtonSecondary } from "../buttons/ButtonSecondary"
import { IdeaNodeWrapperData } from "./internals/nodeTypes/idea/IdeaNode"
import { NodeTypeIds } from "./internals/nodeTypes/NodeTypes"
import { buildStartingPoint } from "./internals/buildStartingPoint"
import { MindMapContextWrapper } from "./internals/context/MindMapContext"
import { MindMapDataConverter } from "./MindMapDataConverter"
import { useAppSelector } from "../../state/storeHooks"
import { DocTagDictionary, DocTagsState } from "../../state/docTagsSlice"
import { TagsState } from "../../state/TagsSlice"


/** UI Graph that helps the user think through ideas and opportunities */
export const GlobalMindMapGraph = () => {


  // TODO: Consolidate this file with DocumentMindMapGraph.tsx
  const allDocTags = useAppSelector(DocTagsState.getAll)
  const allTags = useAppSelector(TagsState.getAll)

  const docTagsDictionary = useMemo<DocTagDictionary>(() => {

    const toReturn = allDocTags.reduce((acc, docTagMap) => {
      const docId = docTagMap.docId
      const tag = allTags.find(tag => tag.id === docTagMap.tagId)
      if (!tag) return acc
      if (!acc[docId]) {
        acc[docId] = []
      }
      acc[docId].push(tag)
      return acc
    }, {} as DocTagDictionary)

    return toReturn

  }, [allDocTags, allTags])


  useEffect(() => {

    const fromLocalStorage = MindMapState.getFromLocalStorage()
    if (fromLocalStorage?.nodes) {
      dispatch(MindMapState.setNodes(fromLocalStorage.nodes))
      dispatch(MindMapState.setEdges(fromLocalStorage.edges))
    } else {
      dispatch(MindMapState.setNodes(initialNodesSampleData))
      dispatch(MindMapState.setEdges(initialEdgesSampleData))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { experimentalEnabled } = useFeatureFlags()
  const { menuStructureProvider } = useAppServices()


  const setNodesFromDocs = useCallback((docTypes: "all" | "opportunities" | "first-node-only") => {
    console.log("setNodesFromDocs", docTypes)

    if (docTypes === "first-node-only") {
      const data = buildStartingPoint("Increase revenue by 3%", { title: "Outcome", tagType: "Outcome" })
      dispatch(MindMapState.setNodes(data.nodes))
      dispatch(MindMapState.setEdges(data.edges))
      return
    }

    const docs = docTypes === "all"
      ? menuStructureProvider.menuItemsStructured.opportunitiesWithMixedChildren
      : menuStructureProvider.menuItemsStructured.opportunitiesWithOnlyOpportunityChildren

    const { nodes, edges } = MindMapDataConverter.convertMenuStructure(docs, docTagsDictionary, [], [])
    console.log("nodes", { nodes, edges, docs })

    dispatch(MindMapState.setNodes(nodes))
    dispatch(MindMapState.setEdges(edges))

  }, [menuStructureProvider.menuItemsStructured, docTagsDictionary])


  const clearGraph = useCallback(() => {
    dispatch(MindMapState.setNodes([]))
    dispatch(MindMapState.setEdges([]))
    MindMapState.clearLocalStorage()

  }, [])

  // onChangedNodes: (changes: NodeChange<Node>[]) => void
  const onChangedNodes = useCallback((changes: NodeChange<Node>[]) => {
    console.log("onChangedNodes", { changes })
    changes.forEach(change => {
      const rawNode = (change as any).item
      const nodeType = rawNode?.type
      if (nodeType === NodeTypeIds.idea) {
        const node = rawNode as IdeaNodeWrapperData
        if (node.data.docId) {
          // TODO: compare changes then update title, type, etc
          // TODO: Need to debounce
          // TODO: Need to ensure local state is updated
          // documentRelationsProvider.updateDocTitle(node.data.docId, node.data.text)

        }
      }
    })

  }, [])

  // onChangedEdges: (changes: EdgeChange<Edge>[]) => void
  const onChangedEdges = useCallback((changes: EdgeChange<Edge>[]) => {
    // changes.map(change => {
    //   // Determine if both nodes have a docId
    //   // If so, update the edge
    //   // If not, do nothing
    //   // Complications: a grandparent => parent => child, where the parent does not exists
    //   // One option is to have a flat hierarchy, where all documents are direct children of the opportunity
    //   // But in the graph view they are nested ???
    // })

  }, [])


  return (
    <ReactFlowProvider>
      <MindMapContextWrapper
        onAddNew={(nodeId) => { console.log("GlobalMindMap: Add new clicked", { nodeId }) }}
        onChangedEdges={onChangedEdges}
        onChangedNodes={onChangedNodes}
      >
        <div css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}>

          <MindMapGraph />

          {experimentalEnabled &&
            <div css={css`
            opacity: 0.5;
            display: flex;
            gap: 12px;
          `}>
              <div>
                <VerdiIconDebug />
              </div>
              <ButtonSecondary
                label="All opportunities"
                size={"xs"}
                leftIcon={<VerdiIconOpportunity />}
                onClick={() => setNodesFromDocs("opportunities")}
              />
              <ButtonSecondary
                label="All opportunities and notes"
                size={"xs"}
                leftIcon={<VerdiIconDocument />}
                onClick={() => setNodesFromDocs("all")}
              />
              <ButtonSecondary
                label="New"
                size={"xs"}
                leftIcon={<VerdiIconDocument />}
                onClick={() => setNodesFromDocs("first-node-only")}
              />
              <ButtonSecondary
                label="Clear Graph"
                size={"xs"}
                leftIcon={<VerdiIconDelete />}
                onClick={clearGraph}
              />
            </div>
          }
        </div>
      </MindMapContextWrapper>
    </ReactFlowProvider>
  )
}
