import { DocumentSchema } from "@verdi/shared-constants"
import { DocMetadataSummary } from "../../../../../ai/documents/DocUpdatedEvent"
import { AiContextConfig, AllContextForAi } from "../../../../../ai/promptUtils/useAllContextForAi"
import { moveSelectionToCommandDefinition } from "../../documentBody/moveSelectionTo"
import { getPlaceholderSuggestionsCommandDefinition } from "../../documentBody/placeholders/loadCurrentPlaceholderSuggestions"
import { AutoAiStepOutcomeType } from "../AutoAiStepOutcomeType"
import { makePlaceholderReplacementsAiCall } from "../../../../../ai/prompts/docDrafter/PlaceholderReplacementAiCall"
import { ReplacePlaceholderWithCommandArgs, replacePlaceholderWithCommandDefinition } from "../../documentBody/replacePlaceholderWith"
import { AutoAiModeState } from "../../../../../state/AutoAiModeSlice"
import { dispatch } from "../../../../../state/store"
import { RunAIPromptOnDocCommandArgs, runAIPromptOnDocCommandDefinition } from "../../documentBody/runAIPromptOnDoc"


export const fillInNextPlaceholderAiStep = async (
  docSummary: DocMetadataSummary | undefined,
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  shouldStop: () => boolean,
) => {
  console.log("fillInNextPlaceholderAiStep: Starting")

  if (!docSummary || docSummary?.placeholders.length === 0) {
    console.log("fillInNextPlaceholderAiStep: No placeholders to fill")
    return AutoAiStepOutcomeType.Skipped
  }

  const placeholderToFill = docSummary.placeholders[0]

  dispatch(AutoAiModeState.setStatus({
    isDoingSomething: true,
    messageToUser: "Filling in ..."
  }))

  // move cursor to placeholder
  moveSelectionToCommandDefinition.triggerCommand?.({
    nodeType: DocumentSchema.NodeExtensions.VerdiNodeTypes.PlaceholderInline,
    textToFind: placeholderToFill.text,
  })

  // TODO: Decide, use suggestions if already exist vs just make this call without storing in redux?
  // Calling the command handles "no-replace" and other special behaviors
  const suggestions = await makePlaceholderReplacementsAiCall(getContextForAi)
  getPlaceholderSuggestionsCommandDefinition.triggerCommand?.({
    shouldAbortIfAlreadyLoaded: true,
    title: "",
    lifecycleState: "load-new",
  })

  if (shouldStop()) {
    return AutoAiStepOutcomeType.StoppedByUser
  }

  const replacement = suggestions[0]
  if (!replacement) {
    return AutoAiStepOutcomeType.Failed
  }

  if (replacement.replaceWithMode === "text") {
    replacePlaceholderWithCommandDefinition.triggerCommand?.(
      replacement.args as ReplacePlaceholderWithCommandArgs
    )
  } else if (replacement.replaceWithMode === "customAiPrompt") {
    const args = replacement.args as RunAIPromptOnDocCommandArgs

    return new Promise<AutoAiStepOutcomeType>((resolve) => {
      runAIPromptOnDocCommandDefinition.triggerCommand?.({
        ...args,
        promptOptions: {
          ...args.promptOptions,
          shouldAddNewDocPlaceholders: false,
        },
        onComplete: (wasSuccessful) => {
          resolve(wasSuccessful ? AutoAiStepOutcomeType.Success : AutoAiStepOutcomeType.Failed)
        }
      })
    })
  }

  return AutoAiStepOutcomeType.Success
}
