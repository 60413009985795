import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CommandStub } from '../screens/document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/buildCommandsFromSuggestions'
import { uniqueId } from 'lodash'
import { Data } from '@verdi/shared-constants'
import { DocBodyMarkCreateCommandArgs } from '../components/commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkCreate'


export type DocumentIdea = {
  id: string,
  title: string,
  question: string,
  answer: string,
  isLoadingCommands?: boolean,
  isApplying?: boolean,
  commands?: CommandStub<any>[],
  status?: Data.DocumentIdeas.DocumentIdeaStatus,
  docId: string
  /** IF specified, will highlight text in the doc when this idea is active */
  markArgs?: DocBodyMarkCreateCommandArgs
  // is suggestion / status ???
}


interface IDocIdeasState {
  isLoadingIdeas: boolean
  ideas: DocumentIdea[]
  currentIdeaId: string | undefined
}

/** Store the current doc's summary of metadata */
const initialState: IDocIdeasState = {
  isLoadingIdeas: false,
  ideas: [],
  currentIdeaId: undefined,
}

export const docIdeasSlice = createSlice({
  name: 'docIdeas',
  initialState,
  reducers: {
    setIsLoadingIdeas: (state, action: PayloadAction<boolean>) => {
      state.isLoadingIdeas = action.payload
    },
    setAllIdeasForDoc: (state, action: PayloadAction<{ docId: string, ideas: DocumentIdea[] }>) => {
      const { docId, ideas } = action.payload
      state.ideas = [
        ...state.ideas.filter(i => i.docId !== docId),
        ...ideas
      ]
    },
    addIdea: (state, action: PayloadAction<DocumentIdea>) => {
      state.ideas.push(action.payload)
    },
    updateIdea: (state, action: PayloadAction<{ id: string, fields: Partial<DocumentIdea> }>) => {
      const { id, fields } = action.payload
      const idea = state.ideas.find(idea => idea.id === id)
      if (idea) {
        Object.assign(idea, fields)
      }
    },
    removeIdea: (state, action: PayloadAction<{ id: string }>) => {
      state.ideas = state.ideas.filter(idea => idea.id !== action.payload.id)
    },
    removeAllIdeas: (state) => {
      state.ideas = []
    },
    replaceTempId: (state, action: PayloadAction<{ oldId: string, newId: string }>) => {
      const { oldId, newId } = action.payload
      const target = state.ideas.find(idea => idea.id === oldId)
      if (target) {
        target.id = newId
      }
    },
    setCurrentIdeaId: (state, action: PayloadAction<string | undefined>) => {
      state.currentIdeaId = action.payload
    },

  },
  selectors: {
    getIsLoadingIdeas: (state) => state.isLoadingIdeas,
    getIdeasForDoc: (state, docId: string | undefined) => {
      if (!docId) return []
      return state.ideas.filter(idea => idea.docId === docId)
    },
    getCurrentIdeaId: (state) => state.currentIdeaId,
  }
})


export const DocumentIdea_TempIDPrefix = "temp-idea-"
const generateTempId = () => {
  return uniqueId(DocumentIdea_TempIDPrefix)
}
const isTempId = (id: string) => id.startsWith(DocumentIdea_TempIDPrefix)


export const DocIdeasState = {
  ...docIdeasSlice.actions,
  ...docIdeasSlice.selectors,
  generateTempId,
  isTempId,
}

export default docIdeasSlice.reducer
