import { css } from "@emotion/react"
import { useMindMapContext } from "../../context/useMindMapContext"
import { IconButtonSecondary } from "../../../../buttons/IconButtonSecondary"
import { VerdiIconAdd, VerdiIconAiSuggestions } from "../../../../icons/VerdiIcons"
import { ButtonSecondary } from "../../../../buttons/ButtonSecondary"
import { DocumentSchema } from "@verdi/shared-constants"
import { Node } from "@xyflow/react"
import { useComboBoxController } from "../../../../commands/miniCommandMenu/comboBox/useComboBoxController"
import { ComboBox } from "../../../../commands/miniCommandMenu/comboBox/ComboBox"
import { useEffect, useMemo } from "react"
import { VerdiCommand, VerdiCommandGroup } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { IconButton } from "@chakra-ui/react"


type Props = {
  parentNode: Node
}

/** Shows suggestions and options for adding a new node.
 * 
 *  Can be triggered like a pop up menu.
 */
export const NewNodeMenuList = ({
  parentNode,
}: Props) => {


  const {
    addNewNode,
    generateNodes,
  } = useMindMapContext()



  const comboBoxController = useComboBoxController({
    mode: "search-and-filter",
    onRunCommand: (command) => {
      command.runCommand?.()
    },
    onClose: () => {
      console.log("NewNodeMenuList: onClose")
    },
  })


  const newNodeOptions = useMemo(() => {
    return getOptionsForNewNode(parentNode)
  }, [parentNode])


  useEffect(() => {
    const commands: VerdiCommand[] = []

    // for (const suggestion of suggestions) {
    //   const addNewCmd: VerdiCommand = {
    //     name: suggestion.text,
    //     searchName: suggestion.text.toLowerCase(),
    //     icon: <VerdiIconAiSuggestions />,
    //     runCommand: () => addNewNode({
    //       parentNode,
    //       docTitle: suggestion.text,
    //       docType: DocumentSchema.DocumentType.document,
    //       titleForDocType: suggestion.text,
    //     })
    //   }
    //   commands.push(addNewCmd)
    // }

    for (const option of newNodeOptions) {
      const addNewCmd: VerdiCommand = {
        name: option.titleForDocType || "",
        searchName: option.titleForDocType?.toLowerCase() || "",
        icon: <VerdiIconAdd />,
        runCommand: () => addNewNode(option)
      }
      commands.push(addNewCmd)
    }


    const group: VerdiCommandGroup = {
      title: "Add New Node",
      commands,
      hideTitle: true,
    }

    comboBoxController.setCommandsInGroups([group])

  }, [newNodeOptions])


  return (
    <div css={css`
      border-radius: 4px;
      padding: 4px;
      background-color: var(--surface-bg);
      min-width: 200px;
    `}>

      {/* <ComboBox
        comboBoxState={comboBoxController}
        hasExternalInput={true} // Hides the input box
      /> */}

      {newNodeOptions.map((option, key) =>
        <div key={key}>
          <IconButtonSecondary
            aria-label="Generate"
            size="xs"
            marginRight="1px"
            icon={<VerdiIconAiSuggestions css={css` pointer-events: none;`} />}
            onClick={() => generateNodes(option)}
          />
          <ButtonSecondary
            label={option.titleForDocType || ""}
            textAlign={"left"}
            size={"xs"}
            onClick={() => addNewNode(option)}
          />
        </div>
      )}

    </div>
  )

}

export const getOptionsForNewNode = (parentNode: Node): NewNodeConfig[] => {
  return [
    {
      parentNode,
      shouldCreateNewDoc: true,
      titleForDocType: "Opportunity",
      docType: DocumentSchema.DocumentType.opportunity,
    },
    {
      parentNode,
      shouldCreateNewDoc: true,
      titleForDocType: "Assumption",
      docType: DocumentSchema.DocumentType.assumption,
    },
    {
      parentNode,
      shouldCreateNewDoc: true,
      titleForDocType: "Note",
      docType: DocumentSchema.DocumentType.document,
    },
    {
      parentNode,
      shouldCreateNewDoc: false,
      titleForDocType: "Other (Debug)",
      docType: DocumentSchema.DocumentType.document,
    },

  ]
}


export type NewNodeConfig = {
  parentNode: Node,
  shouldCreateNewDoc: boolean,
  docType: DocumentSchema.DocumentType,
  docTitle?: string,
  titleForDocType?: string,
}
