import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { MindMapState } from "../../../../state/MindMapSlice"
import { dispatch } from "../../../../state/store"
import { buildStartingPoint } from "../../../mindMap/internals/buildStartingPoint"
import { OpportunitiesState, OpportunitiesViewMode } from "../../../../state/opportunitiesSlice"
import { navigateToCommandDefinition } from "../navigateTo"
import { detectOpportunitySubTypeAiCall } from "../../../../ai/prompts/opportunity/detectOpportunitySubType"
import { Tag, TagType } from "../../../../state/TagsSlice"


const cmdType = GlobalCommandType.startNewMindMap


export const startNewMindMapCommandDefinition: GlobalCommandDefinition<StartNewMindMapCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: StartNewMindMapCommandArgs) => {
    const title = "Start New Mind Map"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLocaleLowerCase(),
        runCommand: () => triggerGlobalCommand(cmdType, args),
      } as VerdiCommand
    return toReturn
  },
  processCommand: async (
    args: StartNewMindMapCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const tagTypeRaw = await detectOpportunitySubTypeAiCall(services.getContextForAi, args.description)
    const primaryTag = tagTypeRaw ? { title: tagTypeRaw, tagType: tagTypeRaw as TagType } as Tag : undefined

    const graphData = buildStartingPoint(args.description, primaryTag)

    // save to DB
    // const mindMap = await MindMapProvider.createMindMap(args.description || "", graphData)

    // set as current MindMap. TODO: Local state can be improved here, clearly labeling the "Current" graph.
    dispatch(MindMapState.setNodes(graphData.nodes))
    dispatch(MindMapState.setEdges(graphData.edges))

    dispatch(OpportunitiesState.setOpportunitiesViewMode(OpportunitiesViewMode.MindMap))

    navigateToCommandDefinition.triggerCommand?.({
      urlOfPage: "/allOpportunities",
      nameOfPage: "",
    })

    args.onCompleted?.()
    onProcessingComplete(true)
  },
  triggerCommand: (args: StartNewMindMapCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export const startNewMindMapPrefix = "+ Action:"

export type StartNewMindMapCommandArgs = {
  description: string
  onCompleted?: () => void
}
