import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../components/buttons/ButtonSecondary"
import { css } from "@emotion/react"
import { DefinitionTipForUser } from "../../components/DefinitionTipForUser"


type Props = {
  disclosure: ReturnType<typeof useDisclosure>
  onClose: () => void
  onStart: () => void
}
export const AssumptionIntroModal = ({
  disclosure,
  onClose: onClose,
  onStart,
}: Props) => {


  const { isOpen } = disclosure



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxWidth={"500px"}
      >
        <ModalHeader>
          <div>
            Mapping
            <span css={css`
                margin-left: 5px;
              `}>
              <DefinitionTipForUser
                word="Assumptions"
                definition="Statements or answered questions that may be true or in need to be further verified"
                pronunciation="a-sump-shun"
                wordType="noun"
              />
            </span>

          </div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div
            css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
          `}
          >
            <span>Enhance your decision-making with clear assumptions. Create your own or go faster with Verdi's suggestions.</span>
            <span>When you have enough, click on the assumptions to take notes and start testing them. Rank them by confidence and relevance to focus on the riskiest ones first.</span>
          </div>
        </ModalBody>
        <ModalFooter gap={2}>
          <ButtonSecondary
            label={"Cancel"}
            onClick={onClose}
          />
          <ButtonPrimary
            label={"Get Started"}
            onClick={onStart}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}


