import { dispatch, getCurrentAppState } from "../../../../state/store"
import { DirectionType, MindMapState } from "../../../../state/MindMapSlice"
import { Tag } from "../../../../state/TagsSlice"
import { useCallback, useEffect, useState } from "react"
import { NewNodeConfig } from "../nodeTypes/addNew/NewNodeMenuList"
import { useAppServices } from "../../../appServices/useAppServices"
import { Edge, EdgeChange, Node, NodeChange, OnBeforeDelete, OnConnect, OnEdgesChange, OnNodesChange, addEdge, applyEdgeChanges, applyNodeChanges, useReactFlow } from "@xyflow/react"
import { useAppSelector } from "../../../../state/storeHooks"
import { arrangeElementsAsTree } from "../layout/arrangeElementsAsTree"
import { IdeaNodeData, IdeaNodeWrapperData } from "../nodeTypes/idea/IdeaNode"
import { generateNodeId } from "../utils/utils"
import { buildChildNodeAndEdge, useCreateChildNodes } from "./helpers/useCreateChildNodes"
import { MindMapSuggestionsState, SuggestedIdeaNodeData } from "../../../../state/MindMapSuggestionsSlice"
import { mindMapNodeSuggestionAcceptCommandDefinition } from "../../../commands/commandDefinitions/mindMap/mindMapNodeSuggestionAccept"
import { navigateToCommandDefinition } from "../../../commands/commandDefinitions/navigateTo"
import { getUrlForDocument } from "../../../../routes"
import arrangeWithElk from "../layout/algorithms/elk"
import { showConfirmDialogCommandDefinition } from "../../../commands/commandDefinitions/utils/showConfirmDialog"


export type MindMapContextProps = {
  /** When a new node should be added */
  onAddNew: (options: NewNodeConfig) => void

  onChangedNodes: (
    /** Includes type of change, node Id, and PARTIAL properties of only the changes. */
    changes: NodeChange<Node>[],
    /** Current state of the FULL node data found in the change set */
    affectedNodes: Node[]
  ) => void
  onChangedEdges: (changes: EdgeChange<Edge>[]) => void
}

export const useRegisterMindMapContext = ({
  // onTagSelected, 
  onAddNew,
  onChangedEdges,
  onChangedNodes,
}: MindMapContextProps) => {


  const appServices = useAppServices()
  const { getContextForAi } = appServices
  const { fitView, toObject, deleteElements, updateNodeData, getNode } = useReactFlow();

  const nodes = useAppSelector(MindMapState.getNodes)
  const edges = useAppSelector(MindMapState.getEdges)
  const direction = useAppSelector(MindMapState.getDirection)
  const isLoading = useAppSelector(MindMapState.getIsLoading)
  const messageToUser = useAppSelector(MindMapState.getMessageToUser)


  const onTagSelected = (nodeId: string, tag: Tag | undefined) => {
    console.log("Tag selected", { nodeId, tag })
    dispatch(MindMapState.updateIdeaNodeData({
      id: nodeId,
      data: {
        tags: tag ? [tag] : [],
      }
    }))

    // As long as we are storing tag data on a node, we need to persist after it changes
    // dispatch(MindMapState.saveStateToLocalStorage())
  }


  const autoArrangeLayout = useCallback(async (
    theNodes: Node<any>[],
    theEdges: Edge<any>[],
    direction: DirectionType,
    /** If specified, will ensure nodes with these Ids are visible when `fitView()` is called.  */
    nodeIdsToFocus?: string[],
    shouldPersist: boolean = true,
  ) => {

    // const arrangedNodes = arrangeElementsAsTree(theNodes, theEdges, { rankdir: direction });

    const arrangedNodes = await arrangeWithElk(theNodes, theEdges, {
      direction,
      spacing: [20, 0], // The 2nd value does not appear to do anything with spacing.
    })

    dispatch(MindMapState.setNodes(arrangedNodes.nodes as any))
    dispatch(MindMapState.setEdges(arrangedNodes.edges as any))
    if (shouldPersist) {
      // dispatch(MindMapState.saveStateToLocalStorage())
    }

    window.requestAnimationFrame(() => {
      fitView({
        nodes: nodeIdsToFocus?.map(id => ({ id })) || undefined,
        duration: 500,
        maxZoom: 1.5, // percentage of zoom, 3=300% zoom, 1=100% zoom, etc. (more than 2 is too big I think)
      });
    });
  }, [fitView])


  const onNodesChange: OnNodesChange = useCallback((changes) => {

    // console.log("onNodesChange", { changes, nodesBefore: nodes })
    // onNodesChangeState(changes)
    // TODO: Error happens here with readonly width property, on dimensions change
    // const updatedNodes = applyNodeChanges(changes as any, nodes.map(n => ({ ...n, data: { ...n.data } })) as any)
    const updatedNodes = applyNodeChanges(changes as any, nodes as Node[])
    // console.log("did applyNodeChanges", { updatedNodes })
    dispatch(MindMapState.setNodes(updatedNodes as any))

    const shouldAutoArrange = changes.some(c => c.type === "dimensions" || c.type === "add")

    if (shouldAutoArrange) {
      console.log("onNodesChange: shouldAutoArrange", { changes })

      const dimensionsWereUpdated = changes.some(c => c.type === "dimensions")
      const changedIds = dimensionsWereUpdated ? changes.map(c => (c as any).id) : []
      console.log("autoArrangeLayout from: onNodesChange")
      autoArrangeLayout(updatedNodes, edges, direction, changedIds)
    }
    // dispatch(MindMapState.saveStateToLocalStorage())

    const shouldUpdateSourceData = changes.some(c => c.type === "add" || c.type === "remove" || c.type === "replace")
    if (shouldUpdateSourceData) {
      // getNode(nodeId)
      const affectedNodes = changes.map(c => getNode((c as any).id))
        .filter(n => n !== undefined) as Node[]
      console.log("onNodesChange: shouldUpdateSourceData", { changes })
      onChangedNodes(changes, affectedNodes)
    }

  }, [nodes, edges, direction, autoArrangeLayout, onChangedNodes])


  const onEdgesChange: OnEdgesChange = useCallback((changes: EdgeChange<Edge>[]) => {

    console.log("onEdgesChange", changes)
    dispatch(MindMapState.setEdges(applyEdgeChanges(changes as any, edges)))

    const shouldUpdateSourceEdgeData = changes.some(c => c.type === "add" || c.type === "remove" || c.type === "replace")
    if (shouldUpdateSourceEdgeData) {
      onChangedEdges(changes)
    }

  }, [edges, onChangedEdges])

  const onConnect: OnConnect = useCallback((connection) => {
    console.log("onConnect", connection)
    // setEdges((eds) => addEdge(connection, eds))
    dispatch(MindMapState.setEdges(addEdge(connection, edges)))
  }, [edges])



  const onGenerateChildrenComplete = useCallback((results: IdeaNodeData[], parentNodeId: string) => {

    console.log("onGenerateChildrenComplete", { results, parentNodeId })
    const nodesToAdd: IdeaNodeWrapperData[] = []
    const edgesToAdd: Edge[] = []
    for (const result of results) {
      const updates = buildChildNodeAndEdge(parentNodeId, result)
      nodesToAdd.push(updates.node)
      edgesToAdd.push(updates.edge)
    }

    const updatedNodes = nodes.concat(nodesToAdd)
    const updatedEdges = edges.concat(edgesToAdd)
    console.log("Adding these things ", { nodesToAdd, edgesToAdd, updatedNodes, updatedEdges })

    // dispatch(MindMapState.setNodes(updatedNodes))
    // dispatch(MindMapState.setEdges(updatedEdges))

    console.log("autoArrangeLayout from: onGenerateChildrenComplete")
    autoArrangeLayout(
      updatedNodes,
      updatedEdges,
      direction,
      nodesToAdd.map(n => n.id),
    )

    dispatch(MindMapState.setStatus({ isLoading: false, messageToUser: "" }))
  }, [nodes, edges, autoArrangeLayout, direction])


  const onBeforeDelete: OnBeforeDelete = useCallback(async (thingsToDelete) => {
    // OnBeforeDelete<NodeType, EdgeType>
    const nodeToDelete = thingsToDelete.nodes?.[0]
    if (!nodeToDelete) {
      return false
    }

    const toReturn = new Promise<boolean>((resolve, reject) => {
      const docId = (nodeToDelete.data as IdeaNodeData).docId
      if (!docId) {
        // No docId, so just delete the node
        resolve(true)
        return
      }

      const docTitle = nodeToDelete.data.text
      showConfirmDialogCommandDefinition.triggerCommand?.({
        dialogBodyText: `Are you sure you want to delete ${docTitle}?`,
        confirmButtonLabel: "Delete",
        heading: "Delete note",
        onConfirmSuccess: () => {
          console.log("User confirmed delete")
          resolve(true)
        },
        onCancel: () => {
          console.log("User cancelled delete")
          resolve(false)
        },
        isDestructiveAction: true,
      })
    })
    return toReturn
  }, [])


  const {
    createChildNodes,
    askAiForChildNodeSuggestions,
  } = useCreateChildNodes({
    appServices,
    getContextForAi,
    onGenerateChildrenComplete,
    toObject,
  })


  const addNewNode = useCallback((options: NewNodeConfig) => {

    createChildNodes(options, {
      shouldGetFromAi: false,
    })

    onAddNew(options)
  }, [onAddNew, createChildNodes])


  const generateSuggestedChildNodes = useCallback(async (parentNode: Node) => {

    const docId = (parentNode.data as IdeaNodeData).docId
    if (!docId) {
      console.warn("No docId found on parentNode. Will not generate suggestions", { parentNode })
      return
    }
    const suggestedResults = await askAiForChildNodeSuggestions(parentNode)
    console.log("suggestedResults", { suggestedResults })
    const nodesToAdd: SuggestedIdeaNodeData[] = suggestedResults.map(data => {
      const id = generateNodeId()
      return {
        id,
        parentDocId: docId,
        data,
      }
    })
    dispatch(MindMapSuggestionsState.addSuggestions(nodesToAdd))

    onGenerateChildrenComplete(suggestedResults, parentNode.id)

  }, [askAiForChildNodeSuggestions, onGenerateChildrenComplete])


  const generateNodes = useCallback((options: NewNodeConfig) => {

    createChildNodes(options, {
      shouldGetFromAi: true,
    })

  }, [createChildNodes])


  const getParentNodes = useCallback((childNodeId: string) => {
    const parentEdges = edges.filter(e => e.target === childNodeId)
    return parentEdges.map(e => nodes.find(n => n.id === e.source))
      .filter(p => p !== undefined)

  }, [nodes, edges])


  const acceptSuggestedNode = useCallback((node: Node) => {

    const parentNode = getParentNodes(node.id)[0]
    const parentDocId = (parentNode?.data as IdeaNodeData)?.docId
    mindMapNodeSuggestionAcceptCommandDefinition.triggerCommand?.({
      node,
      parentDocId,
      onCompleted: (docId: string) => {
        updateNodeData(node.id, {
          docId,
        })
      }
    })
  }, [getParentNodes, updateNodeData])


  const rejectSuggestedNode = useCallback((node: Node) => {
    const toRemove = MindMapState.getNodeAndItsEdges(getCurrentAppState(), node.id)
    if (!toRemove) {
      console.warn("rejectSuggestedNode: Node not found", { node })
      return
    }
    deleteElements({
      nodes: [toRemove?.node],
      edges: toRemove?.edges,
    })
  }, [deleteElements])


  const navigateToDocument = useCallback((docId: string) => {
    navigateToCommandDefinition.triggerCommand?.({
      urlOfPage: getUrlForDocument(docId),
      nameOfPage: "",
    })
  }, [])


  // const onInit = useCallback((instance: ReactFlowInstance<IdeaNodeWrapperData, Edge>) => {
  //   console.log("onInit", { instance })
  // }, [])

  const [hasInitialized, setHasInitialized] = useState(false)
  useEffect(() => {
    // Important: do not persist here via `autoArrangeLayout(... shouldPersist: true)`
    // Or it may cause an infinite loop
    console.log("autoArrangeLayout from: useEffect")
    autoArrangeLayout(nodes, edges, direction, undefined, false)
      .then(() => {
        setHasInitialized(true)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction])


  return {
    onTagSelected,
    addNewNode,
    generateNodes,
    generateSuggestedChildNodes,
    acceptSuggestedNode,
    rejectSuggestedNode,
    navigateToDocument,
    nodes,
    edges,
    direction,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onBeforeDelete,
    isLoading,
    messageToUser,
    hasInitialized,
  }

}


export type MindMapServices = ReturnType<typeof useRegisterMindMapContext>


export type GenerateChildrenOptions = {
  shouldGetFromAi?: boolean,
  tags?: Tag[],
}
