import { Button, IconButton } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { ReactNode, useCallback } from "react"
import { deleteDocCommandDefinition } from "../../components/commands/commandDefinitions/document/deleteDoc"
import { VerdiIconDelete } from "../../components/icons/VerdiIcons"
import { DocumentSchema } from "@verdi/shared-constants"


type Props = {
  buttonTitle?: string
  documentId: string,
  documentTitle: string,
  documentType: DocumentSchema.DocumentType,
  redirectUrl: string,
  opportunityId?: string,
  isMainDocForAnOpportunity: boolean,
}

/** 
 * Handles confirmation, deletion of the document, and redirecting 
*/
const DeleteDocumentButton = ({
  buttonTitle,
  documentId,
  documentTitle,
  documentType,
  redirectUrl,
  opportunityId,
  isMainDocForAnOpportunity,
}: Props) => {


  const onDeleteButtonClicked = useCallback(() => {
    console.log("DeleteDocumentButton: onDeleteButtonClicked()")
    const dialogBodyHtml: ReactNode = renderDialogBody(isMainDocForAnOpportunity, documentTitle)

    deleteDocCommandDefinition.triggerCommand?.({
      dialogBodyHtml,
      docId: documentId,
      docTitle: documentTitle,
      docType: documentType,
      redirectAfterDelete: true,
    })

  }, [documentId, documentTitle, isMainDocForAnOpportunity])


  return (
    <>
      {buttonTitle &&
        <Button
          aria-label='Delete document'
          onClick={onDeleteButtonClicked}
          variant='ghost'
          opacity='0.4'
          _hover={{ opacity: 1, color: "red" }}
          title="Delete document"
          leftIcon={<VerdiIconDelete />}>
          {buttonTitle}
        </Button>
      }
      {!buttonTitle &&
        <IconButton
          aria-label='Delete document'
          onClick={onDeleteButtonClicked}
          variant='ghost'
          opacity='0.4'
          _hover={{ opacity: 1, color: "red" }}
          title="Delete document" icon={<VerdiIconDelete />} />
      }
    </>
  )

}

export default DeleteDocumentButton


const renderDialogBody = (
  isMainDocForAnOpportunity: boolean,
  documentTitle: string | undefined,
) => {
  return (
    <div>
      <div>
        Are you sure you want to delete this {isMainDocForAnOpportunity ? "opportunity" : "document"}?
      </div>

      {documentTitle &&
        <div css={css`
                  font-weight: bold;
                `}>
          "{documentTitle}"
        </div>
      }

      {isMainDocForAnOpportunity &&
        <div>
          All child documents will be deleted as well.
        </div>
      }

      <div>
        You can't undo this action.
      </div>

    </div>
  )
}
