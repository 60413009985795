import { EditorState } from "prosemirror-state";


/** Determines the position of where the next root node should be inserted */
export const getNextRootNodeInsertPosition = (
  state: EditorState,
  position: number
) => {

  let nextRootNodePos = -1
  state.doc.forEach((node, offset, index) => {
    // The first root node after the current position is the one we want
    if (nextRootNodePos === -1 && offset > position) {
      nextRootNodePos = offset
    }
    /** Note: did not find a good way to 
     * continue / break out of the loop early
     * once we find what we need. */
  })

  // Handle if the position is at the end of the document
  if (nextRootNodePos === -1) {
    nextRootNodePos = state.doc.content.size
  }

  return nextRootNodePos;
}
