import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { DocIdeasState, DocumentIdea, DocumentIdea_TempIDPrefix } from "../../../../../state/docIdeasSlice"
import { dispatch, getCurrentAppState } from "../../../../../state/store"
import { makeQuestionsAiCall } from "../../../../../ai/prompts/ideas/questionsAiCall"
import { uniqueId } from "lodash"
import { makeReviewAsAudienceAiCall } from "../../../../../ai/prompts/docDrafter/reviewAsAudienceAiCall"
import { DocMetadataState } from "../../../../../state/docMetadataSlice"


const cmdType = GlobalCommandType.getDocIdeasFromAiCoach


export const getDocIdeasFromAiCoachCommandDefinition: GlobalCommandDefinition<GetDocIdeasFromAiCoachCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: GetDocIdeasFromAiCoachCommandArgs) => {
    const title = "Get ideas from AI Coach"
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: GetDocIdeasFromAiCoachCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("getDocIdeasFromAiCoach()")

    const { mode } = args
    const currentDocSummary = DocMetadataState.getSummary(getCurrentAppState())
    const documentId = currentDocSummary?.documentId
    if (!documentId) {
      onProcessingComplete(false)
      return
    }

    dispatch(DocIdeasState.setIsLoadingIdeas(true))

    const existingForThisDoc = DocIdeasState.getIdeasForDoc(getCurrentAppState(), documentId)
    if (mode === "loadFromCacheIfExists"
      && existingForThisDoc.some(idea => !idea.status)) {
      onProcessingComplete(false)
      return
    }

    const promises: Promise<any>[] = []
    const newIdeas: DocumentIdea[] = []

    promises.push(makeQuestionsAiCall(services.getContextForAi, [])
      .then((questionsResults) => {
        questionsResults.forEach((kvp, i) => {
          const question = {
            id: uniqueId(DocumentIdea_TempIDPrefix),
            title: kvp.key,
            question: kvp.value,
            answer: "",
            docId: documentId || "",
          }
          newIdeas.push(question)
        })
      }))

    if (currentDocSummary?.lengthOfBody && currentDocSummary.lengthOfBody > 100) {
      // Load inline suggestions (that will highlight parts of the document body text)
      const limitResultsTo = 4
      promises.push(makeReviewAsAudienceAiCall(services.getContextForAi, limitResultsTo)
        .then((commandArgs) => {
          for (const arg of commandArgs) {
            const question = arg.attributes.suggestionText
            if (!question) continue
            newIdeas.push({
              id: uniqueId(DocumentIdea_TempIDPrefix),
              title: "",
              question,
              answer: "",
              docId: documentId || "",
              markArgs: arg,
            })
          }
        }
        ))
    }

    await Promise.allSettled(promises)

    dispatch(DocIdeasState.setAllIdeasForDoc({ docId: documentId || "", ideas: newIdeas }))
    dispatch(DocIdeasState.setIsLoadingIdeas(false))

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: GetDocIdeasFromAiCoachCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type GetDocIdeasFromAiCoachCommandArgs = {
  mode: "loadFromCacheIfExists" | "alwaysHitTheApi",
}

