import { useMemo } from "react"
import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { setDocFrameworkShowOptionsCommandDefinition } from "../../commandDefinitions/framework/setDocFrameworkShowOptions"
import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails"
import { DocumentSchema } from "@verdi/shared-constants"


export const useFrameworkSuggestionCommands = (
  document: BasicDocumentDetails,
  hasFramework: boolean,
) => {

  const missingFrameworkCommandGroups = useMemo<VerdiCommandGroup[]>(() => {

    const commands: VerdiCommand[] = []
    const showAddFrameworkCommand = getAddFrameworkCommandIfMissing(document, hasFramework)
    if (showAddFrameworkCommand) {
      commands.push(showAddFrameworkCommand)
    }

    if (commands.length === 0) {
      return []
    }

    const group: VerdiCommandGroup = {
      title: "Framework",
      commands,
      hideTitle: true,
    }

    return [group]
  }, [hasFramework, document])


  return {
    missingFrameworkCommandGroups,
  }
}

export const getAddFrameworkCommandIfMissing = (
  document: BasicDocumentDetails,
  hasFramework: boolean,
) => {
  if (!hasFramework) {
    const showAddFrameworkCommand = setDocFrameworkShowOptionsCommandDefinition.buildMenuItem({
      documentId: document.id,
      isForOpportunity: document.type === DocumentSchema.DocumentType.opportunity,
      shouldAutoApplyToDocBody: true,
    })
    return showAddFrameworkCommand
  }
  return undefined
}
