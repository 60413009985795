import { IconButton, IconButtonProps, useColorModeValue } from "@chakra-ui/react"
import { VerdiIconDelete } from "../icons/VerdiIcons"


export const IconButtonRemove = (props: IconButtonProps) => {

  const isLightMode = useColorModeValue(true, false)


  return (
    <IconButton
      colorScheme="red"
      size="sm"
      variant="ghost"
      icon={<VerdiIconDelete />}
      {...props}
    />
  )

}
