import { DocumentContextForAi } from "../coach/AiCoachPrompts";
import { conceptsAsPromptContext } from "../prompts/asPromptContext";


export const buildDocContextForPrompt = (
  documentInfo: DocumentContextForAi,
  ignoreCurrentInsertPos: boolean = true, // when false, this may result in the document body text NOT being sent to the AI
) => {
  const maxBefore = 4000;
  const { body, bodyBeforeSelection, bodyAfterSelection, title, description, relatedDocs, id: docId } =
    documentInfo;
  let docConcepts = conceptsAsPromptContext(relatedDocs, docId)
  docConcepts = docConcepts.length > 0 ? `\n\nAdditional concepts to consider.${docConcepts}` : ""

  const rawBody = ignoreCurrentInsertPos
    ? body // + docConcepts // TODO: Figure out how to add this without generations being in that same format of "key: value"
    : bodyBeforeSelection;
  const bodyToInclude =
    rawBody.length > maxBefore
      ? rawBody.substring(rawBody.length - maxBefore)
      : rawBody;

  let suffixAfterSelection = "";
  if (!ignoreCurrentInsertPos) {
    const remainingLength = Math.max(maxBefore - bodyToInclude.length, 500); // include at least 500 chars
    suffixAfterSelection =
      bodyAfterSelection.length > remainingLength
        ? bodyAfterSelection.substring(0, remainingLength)
        : bodyAfterSelection;
  }

  return {
    detailsForPrompt: `${title ? `Document Title: "${title}"` : ""}
${description ? `Document description: "${description}"` : ""}
${bodyToInclude ? `Document body: ${bodyToInclude}` : ""}`,
    suffix: suffixAfterSelection,
  };
};
