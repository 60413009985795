import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SuggestionForDocument } from '../../screens/document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/useWhatsNextSuggester'


interface InternalState {
  // items: SuggestionForDocument[]
  futureSectionTitles: string[]
  isGenerating: boolean,
}

const initialState: InternalState = {
  // items: [],
  futureSectionTitles: [],
  isGenerating: false,
}

export const suggestionsForDocSlice = createSlice({
  name: 'suggestionsForDoc',
  initialState,
  reducers: {
    // setAll: (state, action: PayloadAction<SuggestionForDocument[]>) => {
    //   state.items = action.payload
    // },
    setFutureSectionTitles: (state, action: PayloadAction<string[]>) => {
      state.futureSectionTitles = action.payload
    },
    removeFutureSectionTitle: (state, action: PayloadAction<string>) => {
      state.futureSectionTitles = state.futureSectionTitles.filter(t => t !== action.payload)
    },
    setIsGenerating: (state, action: PayloadAction<boolean>) => {
      state.isGenerating = action.payload
    }
  },
  selectors: {
    // selectAll: (state) => state.items,
    getFutureSectionTitles: (state) => state.futureSectionTitles,
    getIsGenerating: (state) => state.isGenerating,
  }
})
export const SuggestionsForDocState = {
  ...suggestionsForDocSlice.actions,
  ...suggestionsForDocSlice.selectors,
}

export default suggestionsForDocSlice.reducer
