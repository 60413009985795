import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { ShowMiniCommandMenuCommandArgs, showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu"
import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails"
import { getSuggestedActionCommandsForAssumptions } from "../../../../ai/suggestions/getSuggestedActions"


const cmdType = GlobalCommandType.showAddAction

export const showAddActionCommandDefinition: GlobalCommandDefinition<ShowAddActionCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowAddActionCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Add new action ...',
        description: '',
        searchName: 'add new action',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowAddActionCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { getDocumentContextForAi } = services
    const docContext = getDocumentContextForAi()
    if (!docContext) {
      console.warn("showAddAction: cannot find docContext.")
      return
    }

    const cmState: ShowMiniCommandMenuCommandArgs = {
      commands: [],
      title: "Add new action",
      searchPlaceholderText: "Describe the action to take",
      rectOfAnchorElement: args.rect,
      shouldAdjustRectForScrollOffset: true,
    }

    // Show loading state of command menu
    // IDEA: Pass in the promise, so that it can cancel it?
    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      ...cmState,
      loadingText: "Identifying actions...",
      commands: [],
      loadCommands: async () => { return await getSuggestedActionCommandsForAssumptions(() => { }, docContext, 10, false) as VerdiCommand[] }
    })

    onProcessingComplete(true)
  },
  triggerCommand: (args: ShowAddActionCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type ShowAddActionCommandArgs = {
  parentDoc: BasicDocumentDetails
  rect?: DOMRect
}
