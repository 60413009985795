

export type DocumentIdeaDto = DocumentIdeaMutableFields & {
  /** Id in Hex string format */
  id: string,
  docId: string,
}

export type DocumentIdeaMutableFields = {
  title?: string,
  question?: string,
  answer?: string,
  status?: DocumentIdeaStatus,
}

export enum DocumentIdeaStatus {
  viewed = "viewed",
  executed = "executed",
  dismissed = "dismissed",
}

export type DocumentIdeaCreateRequestBody = Omit<DocumentIdeaDto, "id">
export type DocumentIdeaUpdateRequestBody = DocumentIdeaDto
