import { VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { formatNodeAsSectionCommandDefinition } from "../../commandDefinitions/documentBody/formatNodeAsSection"
import { formatNodeAsTaskCheckboxCommandDefinition } from "../../commandDefinitions/documentBody/formatNodeAsTaskCheckbox"
import { formatNodeAsTextCommandDefinition } from "../../commandDefinitions/documentBody/formatNodeAsText"


export const getCmdsForBlock = () => {

  const group: VerdiCommandGroup = {
    title: 'Format as',
    commands: [
      formatNodeAsSectionCommandDefinition.buildMenuItem({}),
      formatNodeAsTextCommandDefinition.buildMenuItem({}),
      formatNodeAsTaskCheckboxCommandDefinition.buildMenuItem({}),
      // formatNodeAsQuestionCommandDefinition.buildMenuItem({}),
    ],
  }

  // if (blockContext.parentSectionInfo) {
  //   commandsToShow.push(fillInSectionCommandDefinition.buildMenuItem({}))
  // }

  // commandsToShow.push(blockContext.aiPromptId
  //   ? continueWritingFromTemplatedAiPromptCommandDefinition.buildMenuItem({})
  //   : continueWritingCommandDefinition.buildMenuItem({}))


  return [group]
}
