import { useAppServices } from "../../components/appServices/useAppServices"
import { addOpportunityShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/opportunity/addOpportunityShowOptions"
import { useAppSelector } from "../../state/storeHooks"
import { OnboardingStatusListItem } from "./OnboardingStatusListItem"


export const OnboardingStatusList = () => {


  const status = useAppSelector(state => state.onboardingStatus)
  const { router } = useAppServices()


  return (
    <div>
      <h2>Onboarding progress</h2>

      {status.currentOrg.hasCreatedACompanyProfile &&
        <div>Company profile yes</div>
      }
      {status.currentUser.hasLoaded &&
        <div>
          <OnboardingStatusListItem
            title="Review your user profile"
            isComplete={Boolean(status.currentUser.userProfilePageAt)}
            onClick={() => router.router.push("/userProfile")}
          />

          <OnboardingStatusListItem
            title="Review your company profile"
            isComplete={Boolean(status.currentUser.companyPageAt)}
            onClick={() => router.router.push("/organization/context")}
          />

          <OnboardingStatusListItem
            title="Create an Opportunity"
            isComplete={Boolean(status.currentUser.createdOpportunityAt)}
            onClick={() => addOpportunityShowOptionsCommandDefinition.triggerCommand?.({
              isOnboarding: true,
            })}
          />

          <OnboardingStatusListItem
            title="Create an Assumption"
            isComplete={Boolean(status.currentUser.createdAssumptionAt)}
            moreDetailsNode={<div>
              <p>First, navigate to an opportunity.</p>
              <p>
                Then, on the right side panel click on the "Risk Score."
              </p>
            </div>}
          />

          {/* <OnboardingStatusListItem
            title="Create an Action"
            isComplete={Boolean(status.currentUser.createdActionAt)}
            moreDetailsNode={
              <div>
                <p>First, navigate to an opportunity.</p>
                <p>Then, on the right side panel there is an option to add an action.</p>
              </div>}
          /> */}
        </div>
      }
    </div>
  )
}

