import { DomPositionRect } from "../../../screens/document/tiptapEditor/utils/getCurrentBlockContext"


export const positionTheCommandMenu = (
  commandMenuRect: { width: number, height: number },
  rectOfAnchorElement: DomPositionRect | undefined,
  shouldAdjustRectForScrollOffset: boolean,
) => {

  if (!rectOfAnchorElement) {
    return positionTheCommandMenuInCenterOfScreen(commandMenuRect)
  }

  return positionTheCommandMenuSoThatItFitsOnTheScreen(commandMenuRect, rectOfAnchorElement, shouldAdjustRectForScrollOffset)
}

//** Attempts to position the command menu so that it stays on the screen */
const positionTheCommandMenuSoThatItFitsOnTheScreen = (
  commandMenuRect: { width: number, height: number },
  rect: DomPositionRect,
  shouldAdjustRectForScrollOffset: boolean,

) => {

  const scrollY = shouldAdjustRectForScrollOffset ? window.scrollY : 0
  const scrollX = shouldAdjustRectForScrollOffset ? window.scrollX : 0

  const commandMenuPosition = {
    left: rect.left - scrollX,
    top: rect.top - scrollY + rect.height + 4,
  }

  // console.log("THE RECT OF The core", { rect, commandMenuPosition, commandMenuRect, scrollX, scrollY, shouldAdjustRectForScrollOffset })

  // if Command menu will display before the end of the screen then display it on top of the rect instead
  if (commandMenuPosition.top + commandMenuRect.height > window.innerHeight + scrollY) {
    commandMenuPosition.top = (rect.top + scrollY) - (commandMenuRect.height) - 4
  }

  // if Command menu will display off the right of the screen then anchor it to the right of the rect
  if (commandMenuPosition.left + commandMenuRect.width > window.innerWidth + scrollX) {
    commandMenuPosition.left = (rect.right + scrollX) - (commandMenuRect.width) - 4
  }
  if (commandMenuPosition.left < 0) {
    commandMenuPosition.left = 0
  }
  if (commandMenuPosition.top < 0) {
    commandMenuPosition.top = 0
  }
  return commandMenuPosition
}

const positionTheCommandMenuInCenterOfScreen = (
  commandMenuRect: { width: number, height: number },
) => {

  const commandMenuPosition = {
    left: (window.innerWidth / 2) - (commandMenuRect.width / 2),
    top: (window.innerHeight / 2) - (commandMenuRect.height / 2),
  }
  return commandMenuPosition


}
