import { IconButton, IconButtonProps, useColorModeValue } from "@chakra-ui/react"


export const IconButtonSecondary = (props: IconButtonProps) => {

  const isLightMode = useColorModeValue(true, false)


  return (
    <IconButton
      colorScheme="verdiButtonSecondary"
      color={isLightMode ? "#6903D8" : "white"}
      border={isLightMode ? "1px solid #99999944" : "1px solid #FFFFFF22"}
      size="sm"
      {...props}
    />
  )

}
