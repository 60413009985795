import { useMemo } from "react";
import { css } from "@emotion/react";
import { useColorModeValue } from "@chakra-ui/react";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { DocumentSchema } from "@verdi/shared-constants";
import { useAppServices } from "../../../../../../components/appServices/useAppServices";
import { selectedNodeStyles } from "../../../../../../components/ColorModeValues";
import "./DocReferenceInlineComponent.css";
import { DocumentIcon } from "../../../../../../components/icons/DocumentIcon";

type Props = NodeViewProps;

/** Renders a Reference (Hyperlink) to another document, in an editor */
export const DocReferenceInlineComponent = (props: Props) => {

  const {
    menuStructureProvider,
  } = useAppServices()
  // IDEA: ^^ Pull from redux store instead?

  const selectedNodeStyle = useColorModeValue(selectedNodeStyles.light, selectedNodeStyles.dark)
  const { docId, textToDisplay, } = props.node.attrs as DocumentSchema.NodeExtensions.DocReferenceInlineNodeAttributeValues

  /** The document this link points to */
  const referencedDoc = useMemo(() => {
    if (!docId) return undefined
    return menuStructureProvider.getMenuItem(docId)
  }, [docId, menuStructureProvider])


  return (
    <NodeViewWrapper
      as="span"
      data-doc-id={props.node.attrs.docId}
      className="doc-reference-inline"
    >

      {referencedDoc ?
        <span>
          <DocumentIcon
            boxSize={3}
            marginRight={1}
            marginTop={-1}
            opacity={0.5} />
          <span>
            {referencedDoc.title || "Untitled"}
          </span>
        </span>
        :
        <small>
          Broken link
        </small>
      }

      {/* <NodeViewContent as="span" className="content" onKeyPress={() => console.log("changed")} /> */}

    </NodeViewWrapper>
  );
};
