import { Button, Input } from "@chakra-ui/react"
import { useState } from "react"


const apiDomain = process.env.REACT_APP_API_DOMAIN

type Props = {
  initialId: string
}
export const LookupById = ({ initialId }: Props) => {

  const [rawLookupById, setRawLookupById] = useState(initialId)
  const [results, setResults] = useState("")

  const doLookup = async () => {
    const results = await askApiToDoLookup()
    setResults(JSON.stringify(results))
    console.log(results)
  }

  const askApiToDoLookup = async () => {
    const response = await (await fetch(
      `${apiDomain}/admin/LookupById`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        rawStringId: rawLookupById
      })
    })).json()
    return response
  }

  return (
    <div>
      <h1>
        Lookup by Id
      </h1>
      <div>
        <Input
          value={rawLookupById}
          onChange={(evt) => setRawLookupById(evt.target.value)}
          placeholder='Enter a base 64 id here'
          size='sm'
          backgroundColor="white"
        />
        <Button
          onClick={doLookup}
          colorScheme="orange"
          size="sm"
        >Find Info
        </Button>
      </div>
      <div>
        {results}
      </div>
    </div>
  )
}
