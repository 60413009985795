import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ApiTypes } from '@verdi/shared-constants'


interface IDocBodySnapshotsState {
  snapshots: ApiTypes.DocumentSnapshotResponseBody[]
}
const initialState: IDocBodySnapshotsState = {
  snapshots: [],
}


/** IMPORTANT: Snapshots stored here are currently only intended to be used for AI context purposes.
 * 
 *  These snapshots CANNOT be used by Prose-mirror for rendering the document body in the UI,
 *  since we do not include prosemirror markup in them.  */
export const docBodySnapshotsSlice = createSlice({
  name: 'docBodySnapshots',
  initialState,
  reducers: {
    addOrUpdateMany: (state, action: PayloadAction<ApiTypes.DocumentSnapshotResponseBody[]>) => {
      action.payload.forEach(s => {
        const index = state.snapshots.findIndex(s2 => s2.documentId === s.documentId)
        if (index === -1) {
          state.snapshots.push(s)
        } else {
          state.snapshots[index] = s
        }
      })
    },
    addOrUpdate: (state, action: PayloadAction<ApiTypes.DocumentSnapshotResponseBody>) => {
      const index = state.snapshots.findIndex(s => s.documentId === action.payload.documentId)
      if (index === -1) {
        state.snapshots.push(action.payload)
      } else {
        state.snapshots[index] = action.payload
      }

    },
    remove: (state, action: PayloadAction<{ id: string }>) => {
      state.snapshots = state.snapshots.filter((i) => i.documentId !== action.payload.id)
    },
  },
  selectors: {
    getAll: (state) => state.snapshots,
    getByDocId: (state, docId: string) => state.snapshots.find(s => s.documentId === docId),
  }
})


export const DocBodySnapshotsState = {
  ...docBodySnapshotsSlice.actions,
  ...docBodySnapshotsSlice.selectors,
}

// export const selectCount = (state: RootState) => state.docBodySnapshots.items.length

export default docBodySnapshotsSlice.reducer
