import { mergeAttributes, Node } from '@tiptap/core'
import { VerdiNodeTypes } from './VerdiNodeTypes'


type Config = {
  addNodeView?: NonNullable<Parameters<typeof Node.create>[0]>['addNodeView']
}

export type DocReferenceInlineNodeAttributeValues = {
  docId: string,
  textToDisplay: string,
}

const name = VerdiNodeTypes.docReferenceInline

/** A hyperlink to another document, aka Internal Reference */
export const DocReferenceInlineNode = (config?: Config) => Node.create({
  blockName: name,
  name,
  atom: true, // allows up/down arrows to select this node (with no directly editable prosemirror content)
  group: 'inline',
  inline: true,
  selectable: true,
  // content: "(text|docReference)*",
  // content: 'title contentList?',
  // isBlock: true,
  // draggable: true,

  addAttributes() {
    return {
      docId: { required: false, keepOnSplit: false },
      textToDisplay: { required: false, keepOnSplit: false },
    }
  },

  parseHTML() {
    return [
      { tag: 'doc-reference-inline' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['doc-reference-inline', mergeAttributes(HTMLAttributes)]
  },

  // renderHTML({ HTMLAttributes }) {

  //   const element = document.createElement("doc-reference");
  //   element.classList.add("placeholder-inline");
  //   element.innerText = HTMLAttributes.text;

  //   return element;
  // },

  addNodeView: config?.addNodeView,
})
