import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { OpenMenuOptions } from "../../../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"
import { hideDocCommandMenuCommandDefinition } from "./hideDocCommandMenu"


const cmdType = GlobalCommandType.showDocEditorCommandMenu

/** Opens the doc editor command menu, optionally based on conditions  */
export const showDocEditorCommandMenuCommandDefinition: GlobalCommandDefinition<ShowDocEditorCommandMenuCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowDocEditorCommandMenuCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Open the command menu',
        searchName: 'open the command menu',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowDocEditorCommandMenuCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    console.log("showDocEditorCommandMenuCommandDefinition.processCommand()", { args })

    if (args.onlyShowIfCurrentLineIsBlank) {
      const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
      if (!currentBlockContext) {
        onProcessingComplete(false)
        console.warn('showDocEditorCommandMenuCommandDefinition: No currentBlockContext found in servicesForCurrentDoc')
        return
      }
      const currentLineText = currentBlockContext.currentLineText
      if (currentLineText && currentLineText.trim().length > 0) {
        hideDocCommandMenuCommandDefinition.triggerCommand?.({})
        onProcessingComplete(false)
        console.warn('showDocEditorCommandMenuCommandDefinition: Line is not blank ', { currentLineText })
        return
      }
    }

    const cmWasOpened = services.servicesForCurrentDoc?.showDocEditorCommandMenuIfAvailable(args.docCommandMenuOptions) || false

    onProcessingComplete(cmWasOpened)
  },
  triggerCommand: (args?: ShowDocEditorCommandMenuCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type ShowDocEditorCommandMenuCommandArgs = {
  /** When true, shows the CM if current line is blank, otherwise hides the CM.   */
  onlyShowIfCurrentLineIsBlank: boolean
  docCommandMenuOptions?: OpenMenuOptions
}


