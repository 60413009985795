
export type OnboardingStep = {
  id: OnboardingStepIds
  title: string
  description: string
}


export enum OnboardingStepIds {
  Welcome = 0,
  userInfo = 1,
  CompanyInfo = 2,
  OpportunityInfo = 3,
}

/** TODO: Decide if we need this vs just hard coding in HTML */
export const AllOnboardingSteps: OnboardingStep[] = [
  {
    id: OnboardingStepIds.Welcome,
    title: "Getting started",
    description: " ",
  },
  {
    id: OnboardingStepIds.userInfo,
    title: "User Profile",
    description: "Please tell me a little about yourself.",
  },
  {
    id: OnboardingStepIds.CompanyInfo,
    title: "Company Profile",
    description: "Tell Verdi about your company so every suggestion can have roots in what you actually do.",
  },
  {
    id: OnboardingStepIds.OpportunityInfo,
    title: "Start an Opportunity",
    description: "Opportunities are the foundational building blocks in Verdi.",
  },

]

export const totalOnboardingSteps = AllOnboardingSteps.length
