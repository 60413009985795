import { Button, InputGroup, InputRightElement, Spinner, Text, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { AutoResizingInputText } from "./AutoResizingInputText"
import { mediaQuerySmallWidth } from "../pageLayout/styleHelpers"


export const formInputLabelWidth = 120


type Props = {
  label: string
  value: string
  onChange: (value: string) => void
  bottomText?: string
  onSubmit?: () => void
  isSubmitting?: boolean
  onFocus?: () => void
  onBlur?: () => void
  placeholder?: string
}
export const FormInputText = ({
  label,
  value,
  onChange,
  bottomText,
  onSubmit,
  onFocus,
  onBlur,
  isSubmitting,
  placeholder,
}: Props) => {

  const isLightMode = useColorModeValue(true, false)


  return (

    <div css={css`
      display: flex;
      gap: 0;
      margin-bottom: 10px;
      @media ${mediaQuerySmallWidth} {
        flex-direction: column;
      }
    `}>
      <Text
        css={css`
          text-transform: uppercase;
          font-size: 0.8rem;
          line-height: 1.1;
          width: ${formInputLabelWidth}px;
          height: 32px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          opacity: 0.6;
          padding-top: 6px;
          padding-right: 10px;
          
          @media ${mediaQuerySmallWidth} {
            justify-content: flex-start;
            width: 100%;
          }
        `}
      >
        {label}
      </Text>
      <div css={css`
        width: 100%; 
        margin-bottom: 5px;
      `}>
        <div css={css`
          display: flex;
          flex-direction: row;

        `}>
          <InputGroup>
            <AutoResizingInputText
              value={value}
              onChange={(e) => onChange(e.target.value)}
              size="sm"
              borderRadius={8}
              width="100%"
              backgroundColor={isLightMode ? "white" : "black"}
              borderColor={isLightMode ? "gray.300" : "#52555E"}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={placeholder}
            />
            {isSubmitting &&
              <InputRightElement>
                <Spinner
                  size="xs"
                  opacity={0.3}
                  marginTop={-2} />
              </InputRightElement>
            }
          </InputGroup>

          {onSubmit &&
            <Button
              colorScheme="verdiButtonPrimary"
              color="white"
              size="sm"
              marginLeft={2}
              onClick={onSubmit}
              isLoading={isSubmitting}
            >Submit</Button>
          }

        </div>
        {bottomText &&
          <Text
            fontSize="small"
            opacity={0.6}
            marginTop={1}
          >
            {bottomText}
          </Text>
        }
      </div>

    </div>

  )

}

