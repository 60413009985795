import { Accordion, AccordionButton, AccordionItem, AccordionPanel, useColorModeValue } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useCallback, useEffect, useState } from "react"
import { SideMenuButton } from "./SideMenuButton"
import { Menu } from "@verdi/shared-constants"
import { SideMenuSectionAddButton } from "./SideMenuSectionAddButton"
import { useColor } from "../../utility-hooks/useColor"
import { VerdiIconChevronDown, VerdiIconChevronRight } from "../../components/icons/VerdiIcons"


export const startingMenuDepth = 1
export type FolderType = "opportunity" | "quickNote" | "anything" | "recentDocs"


type Props = {
  menuItem: Menu.MenuItemData
  folderType: FolderType
  onBeforeNavigate: () => void
  childReactNode?: React.ReactNode
  isExpandedInitially: boolean
  onAddButtonClick: (menuItem: Menu.MenuItemData) => void
  onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  addingInProgress: boolean
  menuDepth: number
  activeMenuIds: string[]
  landmarkId?: string,
}

/** The main nestable menu component */
export const SideMenuSection = ({
  menuItem,
  folderType,
  onBeforeNavigate,
  childReactNode,
  isExpandedInitially,
  onAddButtonClick,
  onAddOpportunityButtonClick,
  addingInProgress,
  menuDepth,
  activeMenuIds,
  landmarkId,
}: Props) => {

  const isLightMode = useColorModeValue(true, false)
  const menuFontColorActive = useColorModeValue('black', 'white')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false)

  useEffect(() => {
    const currentMenuItemIsInPath = activeMenuIds.includes(menuItem.id)
    const childrenAreInPath = menuItem.children.findIndex(i => activeMenuIds.includes(i.id)) > -1
    setIsExpanded(currentMenuItemIsInPath || childrenAreInPath)
    setIsHighlighted(activeMenuIds[0] === menuItem.id)

  }, [activeMenuIds, menuItem.id, menuItem.children, setIsHighlighted])


  const toggleMenuItem = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const selectedBgColor = useColor("itemSelectedBg")


  if (menuDepth > Menu.maxNestableLevels) {
    return null
  }

  const menuItemTitle = menuItem.title || "Untitled"



  return (
    <nav
      css={css`
      margin-left: 5px;
      width: calc(100% - 5px);
      display: flex;
      flex-direction: column;
      padding-left: 3px;
      ${menuDepth > 1 ? `border-left: 1px solid ${isLightMode ? "#EEE" : "#222"};` : ``} 
    `}>

      <Accordion allowMultiple
        marginBottom={0}
        index={[isExpanded ? 0 : -1]}
      >

        <AccordionItem
          border='none'
          width="100%">

          <div css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              &:hover > div, &:hover > button, &:hover svg {
                opacity: 1;
              }
              border-radius: 5px;
              ${isHighlighted ? `
                background-color: ${selectedBgColor};
              ` : ``}
            `}>


            <AccordionButton
              _expanded={{ color: menuFontColorActive }}
              onClick={(e) => toggleMenuItem()}
              paddingLeft={0}
              css={css`
                width:${menuItem.url.length > 0 ? "16px" : "100%"};
              `}
            >
              <div css={css`
                width: 24px;
              `}>
                {menuItem.children.length > 0 &&
                  <>
                    {isExpanded ?
                      <VerdiIconChevronDown opacity={0.5} />
                      :
                      <VerdiIconChevronRight opacity={0.5} />
                    }
                  </>
                }
              </div>
              {menuItem.url.length === 0 &&
                <div css={css`
                    width: 100%;
                    font-size: 0.9em;
                    text-align: left;
                  `}>
                  {menuItemTitle}
                  <small css={css`opacity: 0.5;`}>
                    &nbsp;{menuItem.children.length}
                  </small>
                </div>
              }

            </AccordionButton>

            {menuItem.url.length > 0 &&
              <SideMenuButton
                landmarkId={landmarkId}
                isExpanded={isExpanded}
                isSelected={isExpanded}
                isVisibleToOthers={menuItem.isVisibleToOrg}
                title={menuItemTitle}
                url={menuItem.url}
                onClick={onBeforeNavigate}
                childCount={menuItem.children.length}
              />
            }


            {/* <small css={css`font-size: xx-small;`}>
              {menuItem.type}
            </small> */}

            {folderType !== "recentDocs" &&
              <SideMenuSectionAddButton
                menuItem={menuItem}
                menuDepth={menuDepth}
                folderType={folderType}
                isExpanded={isExpanded}
                addingInProgress={addingInProgress}
                onAddOpportunityButtonClick={onAddOpportunityButtonClick}
                onAddQuickNoteButtonClick={onAddButtonClick}
              />
            }

          </div>

          {/* Children */}
          <AccordionPanel
            paddingInline={0}
            paddingBottom={0}
            marginLeft={0}>

            {childReactNode !== undefined && childReactNode}

            {menuItem.children && menuItem.children.map((child, index) =>
              <div key={index}>
                <SideMenuSection
                  key={index}
                  menuItem={child}
                  activeMenuIds={activeMenuIds}
                  isExpandedInitially={activeMenuIds.includes(child.id)}
                  folderType={folderType} // TODO: figure out if we do not need this
                  onBeforeNavigate={onBeforeNavigate}
                  onAddButtonClick={onAddButtonClick}
                  onAddOpportunityButtonClick={onAddOpportunityButtonClick}
                  addingInProgress={addingInProgress}
                  menuDepth={menuDepth + 1}
                />
              </div>
            )}
          </AccordionPanel>
        </AccordionItem>


      </Accordion>

    </nav >
  )
}
