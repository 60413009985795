/**
 * @generated SignedSource<<244fc51ce84a58a9cbe9ce705b4a5c7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ChatMessageRoleType = "assistant" | "function" | "system" | "user" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useChatHistory_query$data = {
  readonly chatMessageConnection: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: any;
        readonly dynamicJsonRawString: string | null;
        readonly id: string;
        readonly message: string;
        readonly role: ChatMessageRoleType;
      };
    }>;
  };
  readonly " $fragmentType": "useChatHistory_query";
};
export type useChatHistory_query$key = {
  readonly " $data"?: useChatHistory_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useChatHistory_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "chatMessageConnection"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "before"
    },
    {
      "kind": "RootArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "last",
        "cursor": "before",
        "direction": "backward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": null,
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./useChatHistoryQuery_PaginationQuery.graphql')
    }
  },
  "name": "useChatHistory_query",
  "selections": [
    {
      "alias": "chatMessageConnection",
      "args": [
        {
          "kind": "Literal",
          "name": "sortOrder",
          "value": [
            {
              "fieldName": "createdAt",
              "order": "ASC"
            }
          ]
        }
      ],
      "concreteType": "QueryChatMessageConnection",
      "kind": "LinkedField",
      "name": "__useChatHistoryQuery_chatMessageConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QueryChatMessageConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatMessage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dynamicJsonRawString",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "message",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "__useChatHistoryQuery_chatMessageConnection_connection(sortOrder:[{\"fieldName\":\"createdAt\",\"order\":\"ASC\"}])"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "5c8333dfe6706fee3921957705c12255";

export default node;
