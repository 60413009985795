import { css } from "@emotion/react"
import { mediaQuerySmallWidth } from "../pageLayout/styleHelpers"
import LoadingSpinner from "../LoadingSpinner"
import { ComboBoxController } from "../commands/miniCommandMenu/comboBox/useComboBoxController"
import { VerdiCommand } from "../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { useEffect } from "react"
import { ComboBox } from "../commands/miniCommandMenu/comboBox/ComboBox"
import { useColor } from "../../utility-hooks/useColor"


const maxWidth = 500
const sideMargin = 4


type Props = {
  comboBoxState: ComboBoxController
  isLoading: boolean
  suggestionNames: string[]
  selectedSuggestionName?: string
  loadingMessage?: string
  shouldFloat: boolean
}

/** Facilitates showing AI suggestions via a comboBox. Bring your own input text */
export const AiSuggestionButtonList = ({
  comboBoxState,
  isLoading,
  suggestionNames,
  selectedSuggestionName,
  loadingMessage,
  shouldFloat,
}: Props) => {


  useEffect(() => {
    const commands = suggestionNames.map(suggestionName => {
      return {
        name: suggestionName,
        searchName: suggestionName.toLowerCase(),
        iconType: "aiSuggestion",
      } as VerdiCommand
    })
    comboBoxState.setCommandsInGroups([{
      title: "Suggestions",
      commands,
    }])
    // eslint-disable-next-line
  }, [suggestionNames])


  const bgColor = useColor("surfaceBg")


  return (
    <div css={css`
      width: calc(100% - ${sideMargin * 2}px);
      max-width: ${maxWidth}px;
      position: relative;
      background-color: ${bgColor};
      padding: 0;
      margin: 0 ${sideMargin}px;
    `}>
      <div css={css`
        ${shouldFloat && css`    
          position: absolute;
          z-index: 1;
        `}
      `}>
        {isLoading &&
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: start;
              align-items: center;
              flex-wrap: wrap;
              font-size: small;
              padding: 4px;
              background-color: ${bgColor};
              border: 1px solid #99999922;
              border-radius: 0 0 8px 8px;
          `}>
            <LoadingSpinner
              label={loadingMessage}
              size="xs" />
          </div>
        }
        <div
          css={css`
          width: 100%;
          display: flex;
          flex-direction:column;
          /* grid-template-columns: repeat(2, 1fr); */
          gap: 8px;
          justify-content: space-between;
          @media ${mediaQuerySmallWidth} {
            flex-direction: column;
            grid-template-columns: repeat(1, 1fr); 
          }
      `}>

          {!isLoading && suggestionNames.length > 0 &&
            <div css={css`
            background-color: ${bgColor};
            border: 1px solid #99999922;
            padding: 0 10px 10px;
            border-radius: 0 0 8px 8px;
            box-shadow: var(--box-shadow-drop-down);
          `}>
              <ComboBox
                comboBoxState={comboBoxState}
                hasExternalInput={true}
                maxWidth={maxWidth}
              />
            </div>
          }

        </div>
      </div>
    </div>
  )
}
