

export type KanbanColumnOption = {
  id: OpportunityStatusType,
  title: string,
  visibility: "always-visible" | "hide-by-default",
  tooltip?: string,
}


/** Status of an opportunity
 *  
 * These numerical values are stored in the DB. 
 * If you change them here, update corresponding DEV/PROD data
 * 
 * Eventually we may allow users to define custom statuses with numerical values between these values.
 * */
export enum OpportunityStatusType {
  Inactive = 0,
  Explore = 20,
  Learn = 40,
  Build = 60,
  Yield = 80,
  Complete = 100,
}

export const OpportunityStatusTitleMap: Record<OpportunityStatusType, string> = {
  [OpportunityStatusType.Inactive]: "Inactive",
  [OpportunityStatusType.Explore]: "Explore",
  [OpportunityStatusType.Learn]: "Learn",
  [OpportunityStatusType.Build]: "Build",
  [OpportunityStatusType.Yield]: "Yield",
  [OpportunityStatusType.Complete]: "Complete",

}

export const kanbanColumnOptionsForOpportunities: KanbanColumnOption[] = [
  {
    id: OpportunityStatusType.Inactive,
    title: OpportunityStatusTitleMap[OpportunityStatusType.Inactive],
    tooltip: "Not currently being worked on.",
    visibility: "hide-by-default",
  },
  {
    id: OpportunityStatusType.Explore,
    title: OpportunityStatusTitleMap[OpportunityStatusType.Explore],
    tooltip: "Exploring multiple possibilities of potential opportunities.",
    visibility: "always-visible",
  },
  {
    id: OpportunityStatusType.Learn,
    title: OpportunityStatusTitleMap[OpportunityStatusType.Learn],
    tooltip: "Test assumptions and validate your understanding of the opportunity.",
    visibility: "always-visible",
  },
  {
    id: OpportunityStatusType.Build,
    title: OpportunityStatusTitleMap[OpportunityStatusType.Build],
    tooltip: "Test assumptions and validate your understanding of the opportunity.",
    visibility: "always-visible",
  },
  {
    id: OpportunityStatusType.Yield,
    title: OpportunityStatusTitleMap[OpportunityStatusType.Yield],
    tooltip: "Make decisions based on validated learning.",
    visibility: "always-visible",
  },
  // {
  //   id: OpportunityStatusType.Complete,
  //   title: "Complete",
  //   tooltip: "Done for now. May revisit later.",
  // },
]
