import { DocumentSchema } from "@verdi/shared-constants"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsDocumentOutline } from "../../utils/cleanupAiOutput"
import { makeDocReferencePlaceholdersAiCall } from "./DocReferencePlaceholdersAiCall"
import { DocumentOutlineEntry } from "../../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson"


export type MakeCustomPromptOnDocAiCallArgs = {
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>
  rawPrompt: string
  promptOptions: CustomPromptAiCallOptions
}


/** Generates a full document outline */
export const makeCustomPromptOnDocAiCall = async ({
  getContextForAi,
  rawPrompt,
  promptOptions,
}: MakeCustomPromptOnDocAiCallArgs) => {

  const allContext: AllContextForAi = await getContextForAi({
    includeContext: {
      document: {
        bodyAs: "indented-markdown-with-caret-position",
      }
    }
  })

  const docContext = allContext.currentDoc
  if (!docContext) {
    console.warn("makeCustomPromptOnDocAiCall: No currentDoc context found")
    return []
  }

  const currentPlaceholderAttrs = docContext.currentPlaceholderAttrs

  const prompt = currentPlaceholderAttrs
    ? getPromptForPlaceholder(rawPrompt, currentPlaceholderAttrs)
    : getPrompt(rawPrompt, allContext)

  console.log("makeCustomPromptOnDocAiCall", { prompt })

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
  })

  const rawText = response?.aiResponseJson.content as string || ""
  let docOutline: DocumentOutlineEntry[]
  if (promptOptions.shouldAddNewDocPlaceholders) {
    const phrasesToLink = await makeDocReferencePlaceholdersAiCall(getContextForAi, rawText)
    console.log("makeCustomPromptOnDocAiCall", { phrasesToLink })
    docOutline = applyPlaceholdersToDocOutline(rawText, phrasesToLink)
  } else {
    docOutline = cleanupAiOutputAsDocumentOutline(rawText)
  }

  console.log("makeCustomPromptOnDocAiCall", { response, rawText, docOutline })

  return docOutline
}



const getPrompt = (rawPrompt: string, allContext: AllContextForAi) => {

  const selectedText = allContext.currentDoc?.selectedText

  return `
THE CURRENTLY SELECTED TEXT: "${selectedText}"

INSTRUCTIONS: ${rawPrompt}

RESPONSE FORMAT RULES:
${getResponseFormatRulesForDocBody()}
    `
}



const getPromptForPlaceholder = (
  rawPrompt: string, // the user could override this
  currentPlaceholderAttrs: DocumentSchema.NodeExtensions.PlaceholderInlineNodeAttributes,
) => {

  const placeholderText = currentPlaceholderAttrs.text // What the user actually sees in the document
  const maxLines = currentPlaceholderAttrs.maxLines
  const subInstructions = currentPlaceholderAttrs.instructions

  console.log("getPromptForPlaceholder", { placeholderText, maxLines, subInstructions, rawPrompt, currentPlaceholderAttrs })

  return `INSTRUCTIONS:
Given the current placeholder [[ ${placeholderText} ]] return text that should replace this placeholder. 
${subInstructions ? `make sure the text you generate is in line with the following idea: ${subInstructions}` : ""}

Be sure to include things related to ${rawPrompt}

RESPONSE FORMAT RULES:
Return your response with no preamble, only include the string literal that you would add to the document.
${getResponseFormatRulesForDocBody()}
${maxLines ? `The response text should be no more than ${maxLines} lines long.` : "Return only a single line."}

EXAMPLE 1:
IF the placeholder is: [[ people like to eat pizza by themselves because ]]
THEN a valid response could be:
They do not like it when others watch them eat.
They do not like to share their pizza.
They like to eat in peace.

Example 2:
IF the placeholder is: [[ related documents ]]
THEN a valid response could be:
Objectives in detail
Technical Planning document
Frequently Asked Questions

Example 3:
IF the placeholder is: [[ List of the activities and tasks to validate this assumption ]]
THEN a valid response could be:
[ ] Identify key questions
[ ] Draft an Interview guide
[ ] Reach out and schedule interviews
[ ] Conduct interviews and take notes
[ ] Synthesize notes and identify patterns
[ ] Re-assess assumptions based on findings

`

}



/** Instructs the AI the various was to return document body markup, 
 *  to fully utilize our doc editor schema */
const getResponseFormatRulesForDocBody = () => {
  const prompt = `Return your response with no preamble.

If any of the lines are to-dos, action items or tasks then prepend the line with '[ ]' to indicate they are check boxes.
`

}

/** Other formatting markup to consider adding here:
 *  - Dashes for nested lists
 *  # for section headings
 *  [[ for placeholders ]]
 *  [[ for advanced placeholders with instructions | maxLines=0 | instructions=do something]]
 *  placeholder to create a docReference ???
 */



/** Parses the newly generated body text and attempts to add docReference placeholders
 *  To encourage the user to "go deeper" on certain topics 
 *  By linking to other documents or adding new child documents
 */
const applyPlaceholdersToDocOutline = (rawText: string, phrasesToLink: string[]) => {
  // const newDocOutline = docOutline.replace(/\[\[([^\]]*)\]\]/g, (match, p1) => {
  //   return `[[ ${p1} | behaviors=LinkToRelatedDoc]]`
  // })
  for (const phrase of phrasesToLink) {
    rawText = rawText.replace(phrase, `[[ ${phrase} | behaviors=LinkToRelatedDoc ]]`)
  }

  const docOutline = cleanupAiOutputAsDocumentOutline(rawText)
  return docOutline
}

export type CustomPromptAiCallOptions = {
  /** When true, will scan the generated output and inject "create new doc" placeholders where applicable */
  shouldAddNewDocPlaceholders?: boolean
}
