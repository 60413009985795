import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { showMiniCommandMenuCommandDefinition } from "../utils/showMiniCommandMenu"
import { FrameworksState } from "../../../../state/frameworksSlice"
import { getCurrentAppState } from "../../../../state/store"
import { addDocWithFrameworkCommandDefinition } from "./addDocWithFramework"


const cmdType = GlobalCommandType.addDocShowOptions

export const addDocShowOptionsCommandDefinition: GlobalCommandDefinition<addDocShowOptionsCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: addDocShowOptionsCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Add new note ...',
        description: '',
        searchName: 'add new document note',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "add",
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: addDocShowOptionsCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const frameworksApplicableToDocs = FrameworksState.getAllFrameworksByKind(getCurrentAppState(), false)

    const blankDocCommand = addDocWithFrameworkCommandDefinition.buildMenuItem({
      docTitle: undefined,
      cmdTitle: "-- Blank Note --",
      id: "addDocWithFramework-blank",
    })

    const frameworkCommands = frameworksApplicableToDocs.map((framework) =>
      addDocWithFrameworkCommandDefinition.buildMenuItem({
        docTitle: framework.title,
        docDescription: framework.description,
        framework,
        id: "addDocWithFramework-" + framework.id,
        parentDocId: args.parentDocId, // Ensures it gets added to correct parent
      })
    ) as VerdiCommand[]


    showMiniCommandMenuCommandDefinition.triggerCommand?.({
      commands: [blankDocCommand, ...frameworkCommands],
      title: "Add Note",
      searchPlaceholderText: "search for a framework",
      rectOfAnchorElement: args.rect,
      shouldAdjustRectForScrollOffset: false,
    })
    onProcessingComplete(true)
  },
  triggerCommand: (args: addDocShowOptionsCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type addDocShowOptionsCommandArgs = {
  parentDocId: string
  rect?: DOMRect
}
