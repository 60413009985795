import {
  ContentNodeWithPos,
  findParentNodeClosestToPos,
} from "prosemirror-utils";
import { EditorView } from "prosemirror-view";
import { getRangeForInsertingChildren } from "./getRangeForInsertingChildren";
import { AttributeNames, ProsemirrorNodes } from "@verdi/shared-constants";
import VeNode from "../VeNode";

/** Finds the first node at the given position (or parent)
 *  with the specified attribute name
 * */
export const findAttributeOnNodeTree = (
  view: EditorView,
  pos: number,
  attributeName: AttributeNames.KnowTypes | "any"
) => {
  const doc = view.state.doc;
  const resolvedPos = doc.resolve(pos);
  let currentNode: ContentNodeWithPos | undefined;

  const aiPromptNode = findParentNodeClosestToPos(resolvedPos, (node) => {
    return node.attrs[attributeName]?.length;
  });
  if (aiPromptNode) {
    currentNode = aiPromptNode; // Use node with aiPromptId if it exists
  }

  if (!currentNode) {
    const currentVeNode = VeNode.fromResolvedPosition(
      resolvedPos
    )?.findNestableParent(view.state);
    currentNode = currentVeNode?._node;
  }

  if (!currentNode) {
    return {
      attributeValue: "",
      nodeWithAttribute: undefined,
      insertRange: {
        from: 0,
        to: 0,
      },
    };
  }

  const nodeType = currentNode.node.type
    .name as ProsemirrorNodes.NestableBlockType;

  // Check if current node has it
  let attributeValue = aiPromptNode?.node.attrs[attributeName];
  // console.log("Node:   ", currentNode, ", att value = ", attributeValue)

  // console.log("Using currentNode's value!")

  const range = getRangeForInsertingChildren(currentNode, "replaceAllChildren");
  // const rangeReplace = getRangeForInsertingChildren(currentNode, "replaceAllChildren")
  // const rangeFirst = getRangeForInsertingChildren(currentNode, "insertAsFirstChild")
  // const rangeLast = getRangeForInsertingChildren(currentNode, "insertAsLastChild")

  // const hasNoContentListChildren = rangeReplace.from === rangeReplace.to
  // if (hasNoContentListChildren) {

  // }

  // console.log(`
  //     before  = ${resolvedPos.before()}
  //     , start = ${resolvedPos.start()}
  //     , after = ${resolvedPos.after()}
  //     , end   = ${resolvedPos.end()}
  //     range = ${JSON.stringify(range)}
  //     ${Math.random() * 1000}

  //     rangeReplace = ${JSON.stringify(rangeReplace)}
  //     rangeFirst = ${JSON.stringify(rangeFirst)}
  //     rangeLast = ${JSON.stringify(rangeLast)}
  //     currentNode = ${currentNode.node.type.name}
  //   `)

  return {
    attributeValue,
    nodeWithAttribute: currentNode,
    insertRange: range,
    nearestNestableNodeType: nodeType,
  };

  const endOfDoc = doc.content.size;
  return {
    attributeValue: "",
    nodeWithAttribute: undefined,
    insertRange: {
      from: endOfDoc,
      to: endOfDoc,
    },
  };
};
