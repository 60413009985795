import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


const maxFailedAttempts = 3
interface InternalState {
  titles: string[]
  descriptions: string[]
  isLoading: boolean
  failedAttemptCount: number
}

const initialState: InternalState = {
  titles: [],
  descriptions: [],
  isLoading: false,
  failedAttemptCount: 0,
}

export const suggestionsForDocTitleSlice = createSlice({
  name: 'suggestionsForDocTitle',
  initialState,
  reducers: {
    reset: () => initialState,
    setTitles: (state, action: PayloadAction<string[]>) => {
      state.titles = action.payload
    },
    removeTitle: (state, action: PayloadAction<string>) => {
      state.titles = state.titles.filter(t => t !== action.payload)
    },
    setDescriptions: (state, action: PayloadAction<string[]>) => {
      state.descriptions = action.payload
    },
    removeDescription: (state, action: PayloadAction<string>) => {
      state.descriptions = state.descriptions.filter(t => t !== action.payload)
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    resetFailedAttemptCount: (state) => {
      state.failedAttemptCount = 0
    },
    incrementFailedAttemptCount: (state) => {
      state.failedAttemptCount++
    },
  },
  selectors: {
    getTitles: (state) => state.titles,
    getDescriptions: (state) => state.descriptions,
    getIsLoading: (state) => state.isLoading,
    getFailedTooManyTimes: (state) => state.failedAttemptCount >= maxFailedAttempts,
  }
})
export const SuggestionsForDocTitleState = {
  ...suggestionsForDocTitleSlice.actions,
  ...suggestionsForDocTitleSlice.selectors,
  maxFailedAttempts,
}

export default suggestionsForDocTitleSlice.reducer
