import { Data, KeyValuePair } from "@verdi/shared-constants"
import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { CreateOpportunityResults } from "../../../../screens/opportunities/useOpportunityProvider"
import { OpportunityStatusType } from "../../../kanban/KanbanColumnOptions"
import { getTemplateFromFramework } from "../document/addDocWithFramework"
import { DocumentFrameworksProvider } from "../../../../screens/documentFrameworks/DocumentFrameworksProvider"


const cmdType = GlobalCommandType.addOpportunity


export const addOpportunityCommandDefinition: GlobalCommandDefinition<AddOpportunityCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AddOpportunityCommandArgs) => {

    const { currentDocId, conceptsToUse, prefix, newOpportunity } = args
    const { title, description, } = newOpportunity

    const toReturn =
      {
        globalCommandType: cmdType,
        name: (prefix ? prefix + " " : "") + title,
        tooltip: description,
        runCommand: () => {
          const args = {
            newOpportunity,
            currentDocId,
            conceptsToUse,
          } as AddOpportunityCommandArgs

          triggerGlobalCommand(cmdType, args)

        },
        searchName: (prefix ? `add action ` : "") + title?.toLocaleLowerCase() || "",

      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: AddOpportunityCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const { currentDocId, newOpportunity, framework, onCreated } = args
    const { createOpportunity } = services.opportunityProvider

    let initialStepJson: string | undefined
    if (framework) {
      if (!DocumentFrameworksProvider) {
        console.error("Document Frameworks Provider not available")
        services.toast.showError("Could not apply framework to new opportunity.")
        return
      }
      initialStepJson = getTemplateFromFramework(framework)?.initialStepJson || undefined
    }

    const afterOpportunityIsCreated = async (results: CreateOpportunityResults) => {
      const { newMainDocId } = results
      if (newMainDocId && framework) {
        await DocumentFrameworksProvider.createDocumentFramework(newMainDocId, framework)
      }
      onCreated?.(newMainDocId)
    }

    createOpportunity(
      {
        title: newOpportunity.title,
        description: newOpportunity.description,
        parentDocId: currentDocId,
        initialStepJson: newOpportunity.initialStepJson || initialStepJson,
        status: newOpportunity.status,
      },
      afterOpportunityIsCreated,
    )

    onProcessingComplete(true)
  },
  triggerCommand: (args: AddOpportunityCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export const addOpportunityPrefix = "+ Action:"

export type AddOpportunityCommandArgs = {
  currentDocId: string | undefined
  newOpportunity: {
    title: string | undefined
    description?: string
    initialStepJson?: string
    status?: OpportunityStatusType
  }
  conceptsToUse: KeyValuePair[]
  onCreated?: (newOpportunityMainDocId: string | undefined) => void,
  prefix?: string,
  framework: Data.FrameworkModel | undefined,
}
