import { css } from "@emotion/react"
import { OpportunityRisk } from "./OpportunityRiskBadge"
import { opportunityRiskColorGreen, opportunityRiskColorRed, opportunityRiskColorYellow } from "./OpportunityRiskBar"
import { useColor } from "../../../utility-hooks/useColor"


type Props = {
  opportunityRisk?: OpportunityRisk,
  size?: "xs" | "lg",
}
/** Displays Risk in a circle icon, kind of like a pie chart */
export const OpportunityRiskIcon = ({
  opportunityRisk,
  size = "xs",
}: Props) => {

  const wh = size === "xs" ? 16 : 64
  const bgColor = useColor("surfaceBg")

  // if (!opportunityRisk || opportunityRisk.totalCount < 1) {
  //   return (
  //     <div>
  //       :(
  //     </div>
  //   )
  // }

  // const displayValues = getDisplayValuesForColors(opportunityRisk)
  // console.log("displayValues = ", displayValues)

  return (
    <div>
      <div css={css`
        width: ${wh}px;
        height: ${wh}px;
        background-image: ${getConicGradient(opportunityRisk)};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        & > .donut {
          clip-path: circle(30% at 50% 50%);
          border: 1px dashed brown;
          width: 100%;
          height: 100%;
          background-color: ${bgColor};
      }
      `}>
        <span className="donut"></span>
      </div>

      {/* <div css={css`

        --gap:2px;
        --size:${wh / 2}px;

        height: ${wh}px;
        width: ${wh}px;
        position:relative;
        display:inline-block;
        overflow:hidden;

      & > * {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        border:var(--size) solid var(--color,red);
        border-radius:50%;
        clip-path: polygon(
          calc(50% + var(--gap)/2) 50%, 
          calc(50% + var(--gap)/2) 0%, 
          100% 0%,
          100% calc(78.665% - var(--gap)/2),
          50% calc(50% - var(--gap)/2)); 
      }
      & > .red {
        transform:rotate(${displayValues.red.rotationDegrees}deg);
        --color:red;
      }
      & > .green {
        transform:rotate(${displayValues.green.rotationDegrees}deg);
        --color:blue;
      }
      & > .yellow {
        transform:rotate(${displayValues.yellow.rotationDegrees}deg);
        --color:orange;
      }
      & > .donut {
          clip-path: circle(30% at 50% 50%);
          border: 1px dashed brown;
          width: 100%;
          height: 100%;
          background-color: white;
      }

      `}>

        <span className="red"></span>
        <span className="green"></span>
        <span className="yellow"> </span>
        <span className="donut"></span>

      </div> */}
    </div>
  )
}


// const getDisplayValuesForColors = (opportunityRisk?: OpportunityRisk): DisplayValues => {

//   if (!opportunityRisk) {
//     return {
//       red: {
//         percentage: "0%",
//         rotationDegrees: 0,
//       },
//       yellow: {
//         percentage: "0%",
//         rotationDegrees: 0,
//       },
//       green: {
//         percentage: "0%",
//         rotationDegrees: 0,
//       },
//     }
//   }

//   const { redCount, yellowCount, greenCount, totalCount } = opportunityRisk
//   const redPercent = redCount / totalCount * 100
//   const yellowPercent = yellowCount / totalCount * 100
//   const greenPercent = greenCount / totalCount * 100

//   const redRotation = redPercent / 100 * 360
//   const yellowRotation = yellowPercent / 100 * 360 + redRotation
//   const greenRotation = greenPercent / 100 * 360 + yellowRotation

//   return {
//     red: {
//       percentage: `${redPercent}%`,
//       rotationDegrees: redRotation,
//     },
//     yellow: {
//       percentage: `${yellowPercent}%`,
//       rotationDegrees: yellowRotation,
//     },
//     green: {
//       percentage: `${greenPercent}%`,
//       rotationDegrees: greenRotation,
//     },
//   }


// }
// type DisplayValues = {
//   red: ColorValues,
//   yellow: ColorValues,
//   green: ColorValues,
// }
// type ColorValues = {
//   percentage: string,
//   rotationDegrees: number,
// }






const getConicGradient = (opportunityRisk?: OpportunityRisk) => {

  if (!opportunityRisk || opportunityRisk.totalCount < 1) {
    return `conic-gradient(#99999966 0%, #99999966 100%)`
  }

  const { redCount, yellowCount, greenCount, totalCount } = opportunityRisk
  const redPercent = redCount / totalCount * 100
  const yellowPercent = yellowCount / totalCount * 100 + redPercent
  const greenPercent = greenCount / totalCount * 100 + yellowPercent
  const redGap = redCount > 0 ? 3 : 0
  const yellowGap = yellowCount > 0 ? 3 : 0
  const greenGap = greenCount > 0 ? 3 : 0

  return `conic-gradient(
    ${red} ${0 + redGap}%, ${red} ${redPercent}% , 
    transparent ${redPercent}%, transparent ${redGap + redPercent}%,
    ${yellow} ${redPercent}%, ${yellow} ${yellowPercent}%, 
    transparent ${yellowPercent}%, transparent ${yellowPercent + yellowGap}%,
    ${green} ${yellowPercent}%, ${green} ${greenPercent - greenGap}%,
    transparent ${greenPercent - greenGap}%, transparent 100%
    )
    `
}

const red = opportunityRiskColorRed
const yellow = opportunityRiskColorYellow
const green = opportunityRiskColorGreen
