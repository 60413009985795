import { css } from "@emotion/react"
import { DocumentDetailsPanel } from "../../screens/documentDetails/DocumentDetailsPanel"
import { AppServices } from "../appServices/useRegisterAppServices"
import { ChatView } from "../chat/ChatView"
import { useMemo } from "react"
import { SubSectionWrapper } from "../../screens/opportunityDocument/subMenu/SubSectionWrapper"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { useAppSelector } from "../../state/storeHooks"
import { DocumentsState } from "../../state/DocumentsSlice"
import { BasicDocumentDetails } from "../../screens/document/BasicDocumentDetails"


type Props = {
  appServices: AppServices
}
export const RightSidePanel = ({
  appServices
}: Props) => {

  const {
    pageLayoutState,
    aiChatBridge,
    menuStructureProvider,
  } = appServices


  const currentDocId = useAppSelector(DocumentsState.getCurrentDocId)


  const currentDoc = useMemo(() => {
    if (!currentDocId) return undefined
    const doc = menuStructureProvider.getMenuItem(currentDocId) as BasicDocumentDetails
    return doc
  }, [currentDocId, menuStructureProvider])

  const { experimentalEnabled } = useFeatureFlags()


  if (pageLayoutState.rightSideState === "fullscreen") {
    return (
      <ChatView
        aiChatBridge={aiChatBridge}
        pageLayoutState={pageLayoutState}
        shouldShowGettingStartedOnFirstLoad={true}
      />
    )
  }


  return (
    <div css={css`
      height: calc(100vh - 80px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
    `}>

      <div css={css`
        display: flex;
        flex-direction: column;
      `}>


        {currentDoc &&
          <DocumentDetailsPanel
            document={currentDoc}
            sharedServicesProvider={appServices}
          />
        }

      </div>

      <div>

        {experimentalEnabled && currentDoc?.type &&
          <div css={css`
          margin-right: 20px;
          opacity: 0.1;
          font-weight: bold;
          text-align:right;
          font-size: small;
          `}>This page is a {currentDoc?.type}
          </div>
        }

        <SubSectionWrapper
          title="Chat"
        >
          <ChatView
            aiChatBridge={aiChatBridge}
            pageLayoutState={pageLayoutState}
            shouldShowGettingStartedOnFirstLoad={false}
          />
        </SubSectionWrapper>

      </div>

    </div>
  )
}
