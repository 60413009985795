import { useRef, useState } from "react"
import { css } from "@emotion/react"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react"


type Props = {
  title: string
  titleButton?: React.ReactNode
  children: React.ReactNode
  shouldHideBorder?: boolean
  toolTipTitle?: string
  initialAccordionState?: "expanded" | "collapsed"
}
export const SubSectionWrapper = ({
  title,
  titleButton,
  children,
  shouldHideBorder,
  toolTipTitle,
  initialAccordionState,
}: Props) => {


  const buttonRef = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState(initialAccordionState === "expanded" ? true : false)


  return (
    <div css={css`
      border-top: ${shouldHideBorder ? "none" : "1px solid #99999933"};
    `}>

      <Accordion allowMultiple
        index={[isExpanded ? 0 : -1]}
        css={css`
          border-radius: 10px;
          margin-bottom: ${isExpanded ? 24 : 0}px;
        `}
      >
        <AccordionItem
          border="none">
          <h2 css={css`
                display: flex;
                direction: row;
              `}>
            <AccordionButton
              title={toolTipTitle}
              onClick={() => setIsExpanded(!isExpanded)}
              css={css`
                padding: 8px;
                font-size: 0.9rem;
                display:flex;
                justify-content: space-between;
                align-items: center;
              `}
            >

              <div>
                <AccordionIcon opacity={0.4} />

                <span css={css`
                  opacity: ${isExpanded ? 0.4 : 0.7};
                  font-weight: var(--chakra-fontWeights-semibold);;
                `}>
                  {title}
                </span>
              </div>


            </AccordionButton>

            {titleButton &&
              <div
                css={css`
                  position: relative;
               `}>
                <div
                  onClick={(evt) => evt.stopPropagation()}
                  css={css`
                    position: absolute;
                    right: 0;
                    &:hover {
                      background-color: #99999933;
                    }
                  `}>
                  {titleButton}
                </div>
              </div>
            }

          </h2>
          <AccordionPanel
            css={css`
              padding: 0 8px 4px;
              min-height: 20px;
              margin-left: -8px;
          `}>

            {children}

          </AccordionPanel>
        </AccordionItem>
      </Accordion>

    </div>
  )
}
