import { ApiTypes, Data } from "@verdi/shared-constants"
import { makeDeleteRequest, makeGetRequestJson, makePostRequestJson } from "../../utility-hooks/fetchUtils"
import { useCallback, useState } from "react"
import { useThrottle } from "../../utility-hooks/useThrottle"
import { DeRiskingStepOption } from "./DeRiskingStepOptions"


export const useDeRiskingStepsProvider = () => {


  const [deRiskingSteps, setDeRiskingSteps] = useState<Data.DeRiskingStepDto[]>([])


  const loadAll = useCallback(async () => {
    const response = await makeGetRequestJson("deRiskingSteps")
    const deRiskingSteps = response as Data.DeRiskingStepDto[]
    console.log("DeRiskingStepsProvider.loadDeRiskingSteps", deRiskingSteps)
    setDeRiskingSteps(deRiskingSteps)
  }, [])


  const create = useCallback(async (toAdd: DeRiskingStepOption, opportunityId?: string) => {
    const body: ApiTypes.DeRiskingStepUpdateRequestBody = {
      fields: {
        name: toAdd.title,
      },
      mainDocTitle: toAdd.title,
      mainDocDescription: toAdd.description,
      mainDocType: toAdd.documentTypeToCreate,
      // mainDocInitialStepJson: toAdd.initialStepJson,
      opportunityIdsToAdd: opportunityId ? [opportunityId] : undefined
    }
    const response = await makePostRequestJson("deRiskingSteps", body)
    const newlyCreated = response as Data.DeRiskingStepDto
    setDeRiskingSteps(prev => [...prev, newlyCreated])
    return newlyCreated
  }, [deRiskingSteps])


  const updateField = useCallback(async (modelId: string, field: keyof Data.DeRiskingStepDto, value: any) => {

    setDeRiskingSteps(prev => {
      const index = deRiskingSteps.findIndex(a => a.id === modelId)
      return [
        ...prev.slice(0, index),
        { ...prev[index], [field]: value },
        ...prev.slice(index + 1)
      ]
    })

    return await updateFieldDebounced(modelId, field, value)

  }, [deRiskingSteps])

  const updateFieldDebounced = useThrottle(async (modelId: string, field: keyof Data.DeRiskingStepDto, value: any) => {
    const body: ApiTypes.DeRiskingStepUpdateRequestBody = {
      fields: { [field]: value },
    }
    const response = await makePostRequestJson(`deRiskingSteps/${modelId}`, body)
    return response
  }, 1000)


  const remove = useCallback(async (modelToDelete: Data.DeRiskingStepDto) => {

    const idToDelete = modelToDelete.id
    setDeRiskingSteps(prev => {
      const index = deRiskingSteps.findIndex(a => a.id === idToDelete)
      return [
        ...prev.slice(0, index),
        ...prev.slice(index + 1)
      ]
    })

    return await makeDeleteRequest(`deRiskingSteps/${idToDelete}`)
  }, [deRiskingSteps])


  return {
    deRiskingSteps,
    loadAll,
    create,
    updateField,
    remove
  }

}

export type DeRiskingStepsProvider = ReturnType<typeof useDeRiskingStepsProvider>

