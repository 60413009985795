import { useCallback } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Stack,
  Box,
  useColorModeValue,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import get from "lodash/get";

import { sendPasswordResetRequest } from "../network";
import {
  documentBackgroundColors,
  menuBackgroundColors,
} from "../../components/ColorModeValues";
import { VerdiLogo } from "../../components/logo/VerdiLogo";
import { VerdiIconEmail } from "../../components/icons/VerdiIcons";

type ForgotPasswordValues = {
  email: string;
  success: boolean;
};

function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    setError,
    setValue,
    getValues,
  } = useForm<ForgotPasswordValues>();

  const handleResetPress = useCallback(
    async (state: ForgotPasswordValues) => {
      try {
        await sendPasswordResetRequest(state.email);
        setValue("success", true);
      } catch (err: any) {
        setError("root", { type: "string", message: err.message });
      }
    },
    [setValue, setError]
  );

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor={useColorModeValue(
        menuBackgroundColors.light,
        menuBackgroundColors.dark
      )}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "375px" }}>
          <Stack
            spacing={4}
            p="1rem"
            backgroundColor={useColorModeValue(
              documentBackgroundColors.light,
              documentBackgroundColors.dark
            )}
            boxShadow="sm"
            borderRadius="lg"
            py="25"
            alignItems="center"
          >
            <div
              style={{
                margin: `12px`,
                maxWidth: 275,
              }}
            >
              <VerdiLogo />
            </div>

            <div style={{ maxWidth: 300 }}>
              <Text textAlign="center">
                Forgot your password? No problem! Give us your email address to
                reset your password.
              </Text>
            </div>

            <FormControl w="100%">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={
                    <VerdiIconEmail
                      color={useColorModeValue("gray.400", "gray.500")}
                    />
                  }
                />
                <Input
                  id="email"
                  variant={`outline`}
                  placeholder="Email Address"
                  disabled={getValues("success") || isSubmitting}
                  {...register("email", { required: "true" })}
                />
              </InputGroup>
            </FormControl>

            {get(errors, "root.message") && (
              <Alert status="error">{get(errors, "root.message")}</Alert>
            )}

            {getValues("success") && (
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="200px"
              >
                <AlertIcon boxSize="40px" mr={0} />

                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Request success!
                </AlertTitle>

                <AlertDescription maxWidth="sm">
                  Request to reset password submitted. Check your email for a
                  password reset link
                </AlertDescription>
              </Alert>
            )}

            <Button
              width="100%"
              onClick={handleSubmit(handleResetPress)}
              isLoading={isSubmitting}
              disabled={getValues("success") || isSubmitting || !isValid}
              variant="solid"
              color="#FFFFFF"
              background="#0079FF"
              _hover={{
                background: "#62B7FF",
              }}
            >
              Reset Password
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default ForgotPassword;
