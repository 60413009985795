import { Data, DocumentSchema } from "@verdi/shared-constants";
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";
import { applyFrameworkToDocBodyCommandDefinition } from "./applyFrameworkToDocBody";
import { DocumentFrameworksProvider } from "../../../../screens/documentFrameworks/DocumentFrameworksProvider";


const cmdType = GlobalCommandType.setDocFramework


export const setDocFrameworkCommandDefinition: GlobalCommandDefinition<SetDocFrameworkArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetDocFrameworkArgs) => {
    const title = args.framework.title
    const toReturn = {
      globalCommandType: cmdType,
      name: title,
      description: args.framework.description,
      searchName: title.toLowerCase(),
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
      iconType: args.isCurrentValue ? "currentValue" : undefined,
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetDocFrameworkArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const currentDoc = services.menuStructureProvider.getMenuItem(args.documentId)
    if (!currentDoc) {
      console.warn("setDocFramework: Could not find current document")
      onProcessingComplete(false)
      return
    }

    await DocumentFrameworksProvider.setFrameworkForDocument(args.documentId, args.framework)
    const newDocType = getNewDocType(currentDoc.type, args.framework)
    if (newDocType) {
      await services.documentRelationsProvider.updateDocType(args.documentId, newDocType)
    }

    if (args.shouldAutoApplyToDocBody) {
      applyFrameworkToDocBodyCommandDefinition.triggerCommand?.({})
    }

    /** TODO: If auto adding title when no doc title, 
     *  we need to address the title input text not updating (in DocumentSettingsEditor) */
    // if (!currentDoc.title) {
    //   services.documentRelationsProvider.updateDocTitle(args.documentId, args.framework.title)
    // }

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: SetDocFrameworkArgs,
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetDocFrameworkArgs = {
  documentId: string
  framework: Data.FrameworkModel
  /** Set to true when this item is the currently selected framework */
  shouldAutoApplyToDocBody: boolean
  isCurrentValue?: boolean
}


const getNewDocType = (currentDocType: DocumentSchema.DocumentType, framework: Data.FrameworkModel) => {
  if (currentDocType === DocumentSchema.DocumentType.opportunity) {
    // WARNING: If we remove this, we will need to address bugs and errors anywhere there is logic like:
    // if (doc.type === DocumentType.opportunity) load from the Opportunity DB table.
    return undefined
  }

  const newDocType = framework.docTemplates.filter(t => !t.isExample)[0].docType
  if (!newDocType || newDocType === currentDocType) {
    return undefined
  }
  return newDocType
}
