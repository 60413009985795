import { Edge } from "@xyflow/react";
import { IdeaNodeWrapperData } from "./nodeTypes/idea/IdeaNode";
import { NodeTypeIds, WrappedGraphNode } from "./nodeTypes/NodeTypes";


export const initialNodesSampleData: WrappedGraphNode[] = [
  {
    id: '1',
    type: NodeTypeIds.idea,
    data: { text: '', placeholderText: "Add an outcome ...", tags: [{ title: "Outcome", tagType: "Outcome" }] },
    position: { x: 0, y: 0 },
  } as IdeaNodeWrapperData,
  {
    id: '21',
    type: NodeTypeIds.idea,
    data: { text: "", placeholderText: 'Add a Problem ...', tags: [{ title: "Problem", tagType: "Problem" }] },
    position: { x: 0, y: 0 },
  } as IdeaNodeWrapperData,
  {
    id: '22',
    type: NodeTypeIds.idea,
    data: { text: "", placeholderText: 'Add a Problem ...', tags: [{ title: "Problem", tagType: "Problem" }] },
    position: { x: 0, y: 0 },
  } as IdeaNodeWrapperData,
  {
    id: '31',
    type: NodeTypeIds.idea,
    data: { text: "", placeholderText: 'Add a Solution ...', tags: [{ title: "Solution", tagType: "Solution" }] },
    position: { x: 0, y: 0 },
  } as IdeaNodeWrapperData,
  {
    id: '32',
    type: NodeTypeIds.idea,
    data: { text: "", placeholderText: 'Add a Solution ...', tags: [{ title: "Solution", tagType: "Solution" }] },
    position: { x: 0, y: 0 },
  } as IdeaNodeWrapperData,
  // {
  //   id: 'p1',
  //   type: NodeTypeIds.placeholder,
  //   data: { placeholderText: 'Add ...' },
  //   position: { x: 0, y: 0 },
  // } as PlaceholderWrapperData,

];

// Cloud Services Expansion in Emerging Markets
// Expand cloud services offerings to cater to specific industry verticals\nEnhance data security features for compliance with emerging market regulations\nIntroduce cost-effective cloud solutions tailored for small and medium enterprises\nImplement AI-driven customization options for cloud services"

export const initialEdgesSampleData: Edge[] = [

  { id: 'e-1-21', source: '1', target: '21', sourceHandle: "parent", targetHandle: "child" },
  { id: 'e-1-22', source: '1', target: '22', sourceHandle: "parent", targetHandle: "child" },

  { id: 'e-22-31', source: '22', target: '31', sourceHandle: "parent", targetHandle: "child" },
  { id: 'e-22-32', source: '22', target: '32', sourceHandle: "parent", targetHandle: "child" },

  // { id: 'e-1-p1', source: '1', target: 'p1' }, // This is messed up


  // { id: 'e12', source: '1', target: '2', sourceHandle: "parent", targetHandle: "child" },
  // { id: 'e22a', source: '2', target: '2a', sourceHandle: "parent", targetHandle: "child" },

  // { id: 'e13', source: '1', target: '3', animated: true },
  // { id: 'e22b', source: '2', target: '2b', animated: true },
  // { id: 'e22c', source: '2', target: '2c', animated: true },
  // { id: 'e2c2d', source: '2c', target: '2d', animated: true },
];
