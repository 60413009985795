
import { useEffect, useState } from 'react';
import { SideMenuSection, startingMenuDepth } from './SideMenuSection';
import { DocumentSchema, Menu } from "@verdi/shared-constants";
import { allOpportunitiesRoutePath } from '../../routes';


export const ROOT_OPPORTUNITY_MENU_ID = "opportunities"

type Props = {
  onBeforeNavigate: () => void
  onAddOpportunityButtonClick: (menuItem: Menu.MenuItemData) => void
  opportunities: Menu.MenuItemData[]
  activeMenuIds: string[]
}
export const SideMenuSectionForOpportunitiesV2 = ({
  onBeforeNavigate,
  onAddOpportunityButtonClick,
  opportunities,
  activeMenuIds,
}: Props) => {

  const [topLevelItem, setTopLevelItem] = useState<Menu.MenuItemData>()
  useEffect(() => {

    const rootItem: Menu.MenuItemData = {
      children: opportunities,
      id: ROOT_OPPORTUNITY_MENU_ID,
      title: "Opportunities",
      type: DocumentSchema.DocumentType.document,
      isVisibleToOrg: true,
      url: allOpportunitiesRoutePath,
      createdAt: new Date(),
    }

    setTopLevelItem(rootItem)
  }, [opportunities])


  if (!topLevelItem) return null
  return (

    <SideMenuSection
      menuItem={topLevelItem}
      menuDepth={startingMenuDepth}
      activeMenuIds={activeMenuIds}
      folderType="opportunity"
      onBeforeNavigate={onBeforeNavigate}
      isExpandedInitially={window.location.pathname.includes("/opportunity")}
      onAddButtonClick={() => { }}
      onAddOpportunityButtonClick={onAddOpportunityButtonClick}
      addingInProgress={false}
      landmarkId="SideMenuOpportunities"
    />

  )
}
