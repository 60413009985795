import { useEffect } from "react";
import { PageLayoutSplitViewWrapper } from "../../../components/pageLayout/pageLayoutSplitViewWrapper";
import { SuspenseErrorBoundary } from "../../../components/SuspenseErrorBoundary";
import { SideMenu } from "../../opportunityDocument/SideMenu";
import { CompanyContextEditor } from "./CompanyContextEditor";
import { useAppServices } from "../../../components/appServices/useAppServices";
import { PageHeading } from "../../../components/pageLayout/PageHeading";
import { MainViewWrapper } from "../../../components/pageLayout/MainViewWrapper";
import { VerdiIconCompanyProfile } from "../../../components/icons/VerdiIcons";


export const CompanyContextPage = () => {


  const appServices = useAppServices()
  const {
    pageLayoutState,
    menuStructureProvider,
    opportunityProvider,
    onboardingManager,
  } = appServices


  useEffect(() => {
    pageLayoutState.setRightSideState("hidden")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    onboardingManager.detectOnboardingState()
  }, [onboardingManager])


  return (
    <div>
      <PageLayoutSplitViewWrapper
        appServices={appServices}
        mainMenu={
          <SuspenseErrorBoundary>
            <SideMenu
              onBeforeNavigate={pageLayoutState.onBeforeNavigate}
              menuStructureProvider={menuStructureProvider}
              opportunityProvider={opportunityProvider}
            />
          </SuspenseErrorBoundary>

        }
        mainContent={

          <MainViewWrapper maxWidth={800}>

            <PageHeading
              title="Company Profile"
              description="Foundational context about your company to fuel better results from your AI coach and collaborator."
              iconLeft={<VerdiIconCompanyProfile />}
            />

            <CompanyContextEditor
              onboardingManager={onboardingManager}
            />


          </MainViewWrapper>

        } />

    </div >
  )
}

