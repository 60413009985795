import { useEffect, useState } from "react"
import { CurrentBlockContext } from "../../screens/document/tiptapEditor/utils/getCurrentBlockContext"
import { css } from "@emotion/react"
import { Spinner, useColorModeValue } from "@chakra-ui/react"
import { CommandMenuBackgroundColors, selectedNodeStyles } from "../ColorModeValues"
import { UseAiCoach } from "../../ai/coach/useAiCoach"
import { MinimizedFloater } from "./MinimizedFloater"
import { useKeyDownHandler } from "./useKeyDownHandler"
import { CommandMenuRef, CommandMenuWrapper } from "../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"
import { Editor } from "@tiptap/react"
import { mediaQuerySmallWidth } from "../pageLayout/styleHelpers"
import { EditorView } from "@tiptap/pm/view"
import { useAppSelector } from "../../state/storeHooks"
import { PageLayoutState } from "../pageLayout/usePageLayoutState"
import { leftMenuWidth } from "../pageLayout/pageLayoutSplitViewWrapper"
import { DocumentSchema } from "@verdi/shared-constants"


const hiddenTop = -100
const leftOffset = 34
const topOffset = -50

type Props = {
  documentId: string
  currentBlockContext?: CurrentBlockContext
  aiCoach: UseAiCoach,
  editor?: Editor | null,
  commandMenuRef: React.MutableRefObject<CommandMenuRef | undefined>,
  commandMenuOnKeyDown?: (event: KeyboardEvent, view: EditorView) => boolean
  isLockedForAiEditing: boolean
  editorAreaHasFocus: boolean
  pageLayoutState: PageLayoutState
  mouseIsDown: boolean
}
export const FloatingBlockContext = ({
  documentId,
  currentBlockContext,
  aiCoach,
  editor,
  commandMenuRef,
  commandMenuOnKeyDown,
  isLockedForAiEditing,
  editorAreaHasFocus,
  pageLayoutState,
  mouseIsDown,
}: Props) => {

  const [isVisible, setIsVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)

  const [top, setTop] = useState(hiddenTop)
  const [left, setLeft] = useState(0)
  // const [right, setRight] = useState(80)

  const colorMode = useColorModeValue("light", "dark")
  const backgroundColor = useColorModeValue(CommandMenuBackgroundColors.light, "black")
  const selectedNodeStyle = useColorModeValue(selectedNodeStyles.light, selectedNodeStyles.dark)
  const hasAiPrompt = Boolean(currentBlockContext?.aiPromptId)



  useEffect(() => {

    // console.log("Floating currentBlockContext = ", currentBlockContext)
    const domPos = currentBlockContext?.domPositionBlock
    if (!domPos) {
      setIsVisible(false)
      setTop(hiddenTop)
      setLeft(0)
      return
    }

    const isCheckBox = currentBlockContext?.nearestNestableNodeType === DocumentSchema.VerdiNodeTypes.taskCheckbox

    let newTop = domPos.top ?
      domPos.top + topOffset
      : hiddenTop
    if (isCheckBox) {
      newTop -= 1
    }
    setTop(newTop)

    const leftMenuIsPushing = pageLayoutState.menuIsExpanded && !pageLayoutState.screenWidthIsSmall
    let newLeft = (domPos.left || 0) - leftOffset - (leftMenuIsPushing ? leftMenuWidth : 0)

    if (isCheckBox) {
      newLeft -= 16
    }
    setLeft(newLeft)

    setIsVisible(true)


  }, [currentBlockContext?.domPositionBlock, pageLayoutState.menuIsExpanded, pageLayoutState.screenWidthIsSmall])


  useKeyDownHandler((keyboardEvent) => {
    if (keyboardEvent.key === "Escape") {
      // dismiss this menu
      setIsExpanded(false)
      commandMenuRef.current?.closeCommandMenu()
    }
    // This was causing 2 key events to fire, which would skip items in the command menu
    // const wasHandled = commandMenuOnKeyDown?.(keyboardEvent)
    // if (wasHandled) {
    //   keyboardEvent.preventDefault() // stops page from scrolling
    // }
  })

  const [commandMenuIsOpen, setCommandMenuIsOpen] = useState(false)
  useEffect(() => {
    setCommandMenuIsOpen(commandMenuRef.current?.menuIsOpen || false)
  }, [commandMenuRef])


  const [thisComponentHasFocus, setThisComponentHasFocus] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setThisComponentHasFocus(editorAreaHasFocus)
    }, 1)
  }, [editorAreaHasFocus])



  const isDoingAiOnDoc = useAppSelector(s => s.loadingStatus.doingAiOnDoc)
  const shouldShowLoadingSpinner = isDoingAiOnDoc.isInProgress || isLockedForAiEditing

  const isSection = currentBlockContext?.nearestNestableNodeType === "section"


  return (
    <div>

      {isVisible && currentBlockContext &&

        /** Outer container */
        <div css={css`
          position: absolute;
          top: ${top}px;
          left: ${left}px;
          right: 40px;
          @media ${mediaQuerySmallWidth} {
            right: 0;
          }
          border: 1px solid transparent;
          border-radius: 4px;
          pointer-events: none; 
          width: ${currentBlockContext.domPositionBlock?.width ? `${currentBlockContext.domPositionBlock?.width + 60}px` : "100%"};
          max-width: 1130px;
          background-color: ${editorAreaHasFocus ? selectedNodeStyle.bg : "transparent"};
          border-color: transparent;
          ${isExpanded ? `
          border-top: none;
          // box-shadow: 1px 1px 8px 4px rgba(0,0,0,0.05);
          ` : ""};
          height: ${currentBlockContext.domPositionBlock?.height}px;
          /* opacity: ${thisComponentHasFocus ? 1 : 0.5};
          transition-duration: 300ms;
          transition-property: opacity; */
        `}>


          {/* left side small button */}
          <div css={css`
            position: relative;
            top: ${isSection ? 6 : 4}px;
            left: -5px;
          `}>
            <div css={css`
              width: 30px;
              height: 25px;
              pointer-events: auto; 
              display: flex;
              justify-content: end;
            `}>
              {shouldShowLoadingSpinner &&
                <div css={css`
                  position: absolute;
                  top: 1px;
                  left: 8px;
                `}>

                  <Spinner
                    size='sm'
                    opacity={0.5}
                    color="green" />

                  {isDoingAiOnDoc.message &&
                    <div css={css`
                      position: absolute;
                      top: -28px;
                      width: 200px;
                      font-size: small;
                      font-style: italic;
                      pointer-events: none;
                    `}>
                      <div css={css`
                        background-color: ${backgroundColor};
                        width: fit-content;
                        padding: 0 4px;
                        border-radius: 4px;
                      `}>
                        <span css={css`
                          opacity: 0.5;
                        `}>
                          {isDoingAiOnDoc.message}
                        </span>
                      </div>
                    </div>
                  }
                </div>
              }
              {!shouldShowLoadingSpinner &&
                <MinimizedFloater
                  isExpanded={false}
                  toggleCommandMenu={(targetElement) => {
                    commandMenuRef.current?.toggleCommandMenu({ targetElement, setFocusToTarget: "editor" })
                  }}
                />
              }
            </div>
          </div>

        </div>

      }


      <CommandMenuWrapper
        ref={ref => commandMenuRef.current = ref || undefined}
        editor={editor}
        aiCoach={aiCoach}
        currentBlockContext={currentBlockContext}
        mouseIsDown={mouseIsDown}
        onOpenCommandMenu={() => {
          setIsExpanded(true)
        }}
        onCloseCommandMenu={() => {
          setIsExpanded(false)
        }}
      />

    </div>
  )
}
