import { AiPromptIDE, samplePlaceholderValues } from "./AiPromptIDE";
// @ts-ignore
import * as Handlebars from "handlebars/dist/handlebars";
import { useEffect, useState } from "react";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { Alert, Button } from "@chakra-ui/react";
import graphql from "babel-plugin-relay/macro";
import { AiPromptPreview_otherBlockLevelPrompts$key } from "./__generated__/AiPromptPreview_otherBlockLevelPrompts.graphql";
import { useFragment } from "react-relay";
import { css } from "@emotion/react";
import { getListOfAllBuiltInPlaceholders } from "@verdi/shared-constants";

const HelpsValueString = `
Valid Template Values:
${getListOfAllBuiltInPlaceholders()
  .map((p) => `{{ ${p} }}`)
  .join("\n")}
`;

const otherBlockLevelPromptsfragmentQL = graphql`
  fragment AiPromptPreview_otherBlockLevelPrompts on DocumentAiPrompt
  @relay(plural: true) {
    dontDo
  }
`;

type Props = {
  templateText: string;
  documentLevelContextTemplateText?: string;
  otherBlockLevelPrompts: AiPromptPreview_otherBlockLevelPrompts$key;
};

const ThrowableAiPromptPreview = ({
  templateText,
  documentLevelContextTemplateText,
  otherBlockLevelPrompts: otherBlockLevelPromptsKey,
}: Props) => {
  const [outputBody, setOutputBody] = useState<string>("");
  const [error, setError] = useState<string>();
  const otherBlockLevelPrompts = useFragment(
    otherBlockLevelPromptsfragmentQL,
    otherBlockLevelPromptsKey
  );
  const dontDos = otherBlockLevelPrompts
    ?.map((prompt) => prompt.dontDo)
    .filter((dd) => dd);
  const [showHelps, setShowHelps] = useState(false);

  useEffect(() => {
    if (!templateText.length) {
      setOutputBody("");
    }
    (async () => {
      try {
        let documentLevelContext = {};
        if (documentLevelContextTemplateText) {
          const documentLevelTemplate = await Handlebars.compile(
            documentLevelContextTemplateText
          );
          const documentLevelOutput = await documentLevelTemplate(
            samplePlaceholderValues
          );
          documentLevelContext = { documentLevelContext: documentLevelOutput };
        }
        const blockLevelTemplate = await Handlebars.compile(templateText);
        // console.log({ samplePlaceholderValues })
        const blockLevelOutput = await blockLevelTemplate({
          ...samplePlaceholderValues,
          ...documentLevelContext,
          dontDos,
        });
        setOutputBody(blockLevelOutput);
        setError(undefined);
      } catch (error: any) {
        console.warn(error);
        setError(error.toString());
      }
    })();
  }, [templateText, setOutputBody, documentLevelContextTemplateText]);

  if (error) {
    return (
      <Alert status="error" m={25}>
        {error}
      </Alert>
    );
  }

  return (
    // <div css={css`
    //   position: relative;

    // `}>
    <>
      <Button
        css={css`
          top: 0px;
          right: 0px;
          position: absolute;
        `}
        onClick={() => setShowHelps((helps) => !helps)}
      >
        {showHelps ? `Show Preview` : `Show Help`}
      </Button>
      <AiPromptIDE
        value={showHelps ? HelpsValueString : outputBody}
        readOnly={true}
      />
    </>
    // </div>
  );
};

export const AiPromptPreview = (props: Props) => {
  return (
    <ErrorBoundary>
      <ThrowableAiPromptPreview {...props} />
    </ErrorBoundary>
  );
};
