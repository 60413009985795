/**
 * @generated SignedSource<<54aa4ebbd12493aa7593e123f5d019e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChatMessageRoleType = "assistant" | "function" | "system" | "user" | "%future added value";
export type CreateChatMessageInput = {
  dynamicJson?: string | null;
  message: string;
  role: ChatMessageRoleType;
};
export type useCreateChatMessageMutation$variables = {
  input: CreateChatMessageInput;
  updateConnectionIds: ReadonlyArray<string>;
};
export type useCreateChatMessageMutation$data = {
  readonly createChatMessage: {
    readonly createdAt: any;
    readonly dynamicJsonRawString: string | null;
    readonly id: string;
    readonly message: string;
    readonly role: ChatMessageRoleType;
  };
};
export type useCreateChatMessageMutation = {
  response: useCreateChatMessageMutation$data;
  variables: useCreateChatMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updateConnectionIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "ChatMessage",
  "kind": "LinkedField",
  "name": "createChatMessage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dynamicJsonRawString",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateChatMessageMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateChatMessageMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createChatMessage",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "updateConnectionIds"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "ChatMessageEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1abc3880103884a5e91e7afbe21d9a87",
    "id": null,
    "metadata": {},
    "name": "useCreateChatMessageMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateChatMessageMutation(\n  $input: CreateChatMessageInput!\n) {\n  createChatMessage(input: $input) {\n    id\n    role\n    message\n    createdAt\n    dynamicJsonRawString\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e910763efcc280ac4bf12822d663a19";

export default node;
