import { useCallback, useState } from "react"
import { ShowConfirmDialogCommandArgs } from "../commandDefinitions/utils/showConfirmDialog"
import { useDisclosure } from "@chakra-ui/react"


/** Handles state of Global Confirm Dialog */
export const useGlobalConfirmDialog = () => {


  const disclosure = useDisclosure()
  const [dialogArgs, setDialogArgs] = useState<ShowConfirmDialogCommandArgs>()
  const showConfirmDialog = useCallback((args: ShowConfirmDialogCommandArgs) => {
    console.log("useGlobalConfirmDialog: showConfirmDialog() ", { args })
    setDialogArgs(args)
    disclosure.onOpen()
  }, [disclosure])
  const hideConfirmDialog = useCallback(() => {
    console.log("useGlobalConfirmDialog: hideConfirmDialog()")
    disclosure.onClose()
    setDialogArgs(undefined)
  }, [disclosure])


  return {
    showConfirmDialog,
    hideConfirmDialog,
    dialogArgs,
    disclosure,
  }
}

export type GlobalConfirmDialogProvider = ReturnType<typeof useGlobalConfirmDialog>
