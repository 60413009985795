import { IconButton, IconButtonProps, useColorModeValue } from "@chakra-ui/react"


export const IconButtonTertiary = (props: IconButtonProps) => {

  const isLightMode = useColorModeValue(true, false)


  return (
    <IconButton
      colorScheme="verdiButtonTertiary"
      color={isLightMode ? "#222222" : "white"}
      backgroundColor="transparent"
      size="sm"
      cursor={"pointer"}
      {...props}
    />
  )

}
