import { useCallback, useState } from "react"
import { Data, DocumentSchema, KeyValuePair } from "@verdi/shared-constants"
import { useAppServices } from "../../../components/appServices/useAppServices"


/** Adds concepts to a given document */
export const useAddConceptsToDoc = () => {

  const { documentRelationsProvider } = useAppServices()

  const [isAddingConcepts, setIsAddingConcepts] = useState(false)
  const addConceptsToDocument = useCallback((documentId: string, concepts: KeyValuePair[], onAllComplete: () => void) => {

    setIsAddingConcepts(true)
    const totalPending = concepts.length
    let completeCount = 0
    const areWeThereYet = () => {
      if (completeCount < totalPending) return
      setIsAddingConcepts(false)
      onAllComplete()
    }

    concepts.forEach(a => {

      documentRelationsProvider.createNewDocWithRelation({
        currentDocId: documentId,
        direction: "currentDocIsTo",
        newDocTitle: a.key,
        newDocType: DocumentSchema.DocumentType.concept,
        newDocDescription: a.value,
        onError: () => {
          completeCount++
          areWeThereYet()
        },
        onComplete: () => {
          completeCount++
          areWeThereYet()
        },
        parentDocId: documentId,
        relationType: Data.DocumentRelationType.conceptOf,
      })
    })
  }, [documentRelationsProvider, setIsAddingConcepts])


  return {
    addConceptsToDocument,
    isAddingConcepts,
  }
}
