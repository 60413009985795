import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


interface AudiencePerspectivesState {
  currentAudience: Audience | undefined,
}
const initialState: AudiencePerspectivesState = {
  currentAudience: undefined,
}

export const audiencePerspectivesSlice = createSlice({
  name: 'audiencePerspectives',
  initialState,
  reducers: {
    setCurrentAudience: (state, action: PayloadAction<Audience | undefined>) => {
      state.currentAudience = action.payload
    },
  },
  selectors: {
    getCurrentAudience: (state) => state.currentAudience,
  }
})

export const AudiencePerspectivesState = {
  ...audiencePerspectivesSlice.actions,
  ...audiencePerspectivesSlice.selectors,
}

export default audiencePerspectivesSlice.reducer

export type Audience = {
  title: string,
  typicalQuestionsToAsk: string[]
}

export const allAudiences: Audience[] = [
  {
    title: "Product Managers",
    typicalQuestionsToAsk: [
      "What unmet needs exist in the market that our product can address?",
      "How do our key features compare to those of our competitors?",
      "What is our product's unique value proposition?",
      "What features are we deprioritizing in order to support the current focus?",
      "What other ways can we solve this problem that we haven't thought of yet?",
      "What other ways can we implement this solution that might be more efficient to build?",
      "Which customer segment should we prioritize in our next product update?",
      "How do we balance new feature development with technical debt?",
      "What are the biggest technical challenges in our product development process?",
      "How can we improve the user interface to enhance customer satisfaction?",
      "What metrics will best indicate our product's success in the market?",
      "How can we optimize our pricing strategy to maximize revenue while remaining competitive?",
      "How should we prioritize feature requests from different stakeholders?",
      "What potential risks could impact our product roadmap, and how can we mitigate them?",
      "Are there any regulatory changes that could affect our product's development?",
      "How can we foster a culture of innovation within the product team?",
      "What is our strategy for maintaining and growing our market share?",
      "How can we improve our customer support processes to increase customer loyalty?",
      "How do market trends influence our product strategy and roadmap?",
      "What partnerships or integrations could enhance our product's value?",
      "How can we better communicate our product's benefits to potential customers?",
      "Can we identify any opportunities for cost savings in our product development process?",
      "How should we adapt our product strategy in response to feedback from user testing or beta trials?",
    ]
  },
  {
    title: "Design",
    typicalQuestionsToAsk: [
      "How can we improve the user experience to make our product more intuitive?",
      "What design trends can we incorporate to ensure our product feels current and relevant?",
      "How can we ensure our design elements consistently reflect our brand's identity?",
      "In what ways can we balance aesthetic appeal with the functionality of our design?",
      "How can we design the user interface to simplify user tasks and enhance usability?",
      "What typography and color scheme will best convey the intended mood and message?",
      "Are there new design tools or software that can improve our workflow and output?",
      "How should we incorporate user feedback into the next iteration of our design?",
      "Can we develop a more comprehensive design system to streamline our processes?",
      "How can we better facilitate collaboration between the design team and other departments?",
      "What steps can we take to ensure our designs are accessible to all user groups?",
      "How can we use visual hierarchy to guide users more effectively through our product?",
      "What insights can we gain from design research to inform our design strategy?",
      "How do we manage our project timelines effectively to meet tight deadlines?",
      "What prototyping methods should we use to test our design concepts efficiently?",
      "How does the design cater to different user personas and their needs?",
      "What impact does our design have on the overall user satisfaction and engagement?",
      "How can we better communicate the value of good design to stakeholders?",
      "What are the challenges of integrating our design with the technical architecture?",
      "How can we continuously improve our design process to keep up with changing user expectations?",
    ]
  },
  {
    title: "Engineering",
    typicalQuestionsToAsk: [
      "Is the current system architecture scalable enough to support our future growth plans?",
      "How can we optimize the performance of our application to handle increased user loads?",
      "Are there any coding practices we need to update to improve our code quality?",
      "What security measures do we need to implement to protect user data and prevent breaches?",
      "How will the new features integrate with our existing systems without causing disruptions?",
      "What automated testing framework can we implement to ensure our product is bug-free?",
      "Which new technologies or tools should we adopt to stay ahead in our industry?",
      "How can we apply agile practices more effectively to improve our project management?",
      "Do we need to upgrade our infrastructure to handle our projected traffic volumes?",
      "What are the most promising areas of research that could benefit our next project?",
      "How can we better collect and integrate user feedback into the development process?",
      "What challenges do we face in ensuring cross-platform compatibility, and how can we address them?",
      "How can we optimize our development process to reduce time-to-market for new features?",
      "Are we allocating our budget effectively to get the best return on our technology investments?",
      "What steps can we take to ensure our product remains competitive in the market?",
      "How do we maintain a balance between introducing new features and maintaining legacy systems?",
      "What is the impact of current technology trends on our engineering practices?",
      "How can we improve collaboration between engineering and other departments like design and product management?",
      "What are the potential risks in our current project, and how can we mitigate them?",
      "How can we foster a culture of continuous learning and improvement within the engineering team?",
    ]
  },
  {
    title: "Executive Leadership",
    typicalQuestionsToAsk: [
      "How does our strategic plan align with the long-term vision of the company?",
      "Does this initiative help us meet our financial targets?",
      "What can we do to drive growth?",
      "How can we improve our operational processes to achieve greater efficiency?",
      "What is our competitive advantage in the current market landscape?",
      "What are the key risks facing our business, and how are we addressing them?",
      "How can we enhance our organizational culture to improve employee engagement?",
      "What leadership development programs do we have in place to build our future leaders?",
      "How are we fostering innovation within the company?",
      "How are we / this initiative adaptable to market changes?",
      "How will we effectively manage our relationships with key stakeholders?",
      "Do our governance and compliance frameworks meet the industry standards?",
      "What initiatives are we implementing to promote sustainability and social responsibility?",
      "How are we leveraging technology to drive digital transformation?",
      "What strategies are in place to ensure we are providing excellent customer service?",
      "How are we managing our brand reputation to reflect our company values?",
      "How do global economic trends impact our strategic decisions and market position?",
      "What can we do to increase market share in our key markets?",
      "How are we preparing for potential disruptions in our industry?",
      "What metrics are we using to measure our success and areas for improvement?",
      "How can we more effectively communicate our company's achievements and challenges?",
      "What new business opportunities or markets should we consider to diversify our portfolio?",
    ]
  },
  {
    title: "Competitors",
    typicalQuestionsToAsk: [
      "What new products or services are our competitors planning to launch?",
      "How are our competitors' products/services different from ours in terms of features and quality?",
      "What marketing strategies are our competitors using, and how effective are they?",
      "How do our competitors price their products, and what does that say about their market strategy?",
      "What are the strengths and weaknesses of our competitors’ sales approaches?",
      "How do our competitors' customer service and support compare to ours?",
      "What technological innovations are our competitors adopting, and how are these impacting their business?",
      "How does our market share compare to our competitors' in key segments?",
      "What types of promotional activities are our competitors engaging in?",
      "What are the key factors driving the success of our top competitors?",
      "How are competitors optimizing their supply chain and logistics?",
      "What financial trends can be observed in our competitors' performance?",
      "What strategic alliances or partnerships have our competitors formed recently?",
      "How are our competitors dealing with regulatory changes in our industry?",
      "What talent acquisition or retention strategies are our competitors implementing?",
      "What customer segments are our competitors focusing on, and why?",
      "How are our competitors utilizing digital marketing and social media?",
      "What global markets are our competitors targeting, and what has been their approach?",
      "Are there any signs of financial distress or robust health in our competitors' reports?",
      "What can we learn from the failures and successes of our competitors in recent times?",
    ]
  },
  {
    title: "Customers",
    typicalQuestionsToAsk: [
      "What features does this product have that differentiate it from competitors?",
      "Is the price of this product reflective of its value and quality?",
      "How long can I expect this product to last under regular use?",
      "What do other customers say about their experience with this product?",
      "Can I trust this brand based on its reputation and customer reviews?",
      "What kind of customer service and support does the company offer if I encounter issues?",
      "How does this product compare to similar options in the market?",
      "Are there any discounts or promotions currently available for this product?",
      "What is the return policy, and under what conditions can I return the product?",
      "Are there any known safety concerns or recalls for this product?",
      "Can I customize this product to fit my specific needs or preferences?",
      "How long will it take to receive the product, and what are the shipping costs?",
      "What payment options are available, and is there an option for installment payments?",
      "How does the company demonstrate its commitment to sustainability and ethical practices?",
      "Is there a loyalty program, and what benefits does it offer to regular customers?",
      "What warranty is provided with the product, and what does it cover?",
      "How user-friendly is the product, especially for someone not tech-savvy?",
      "Are there any additional costs, like maintenance or subscription fees, associated with this product?",
      "How does the company handle data privacy and security for its customers?",
      "Is there a trial period to test the product before committing to the full purchase?",
    ]
  },
  {
    title: "Employees",
    typicalQuestionsToAsk: [
      "What are the primary responsibilities of my position, and how will my performance be measured?",
      "What opportunities are there for career advancement and professional development here?",
      "Can you describe the company culture and how it influences the workplace environment?",
      "How does the company handle salary reviews and ensure pay equity?",
      "What benefits and perks does the company offer to employees?",
      "What are the company’s policies on work-life balance, and can I work remotely?",
      "Are there training programs or educational opportunities available to employees?",
      "How often are performance reviews conducted, and what does the process entail?",
      "What is the nature of team dynamics and collaboration within the company?",
      "How would you describe the management style of the leadership team?",
      "How does my role contribute to the company's overall goals and strategy?",
      "What measures are in place to ensure job security and company stability?",
      "How does the company recognize and reward employee achievements and contributions?",
      "What initiatives does the company have to promote diversity and inclusion in the workplace?",
      "What health and safety protocols does the company follow to protect its employees?",
      "Are there opportunities for mentorship or coaching within the company?",
      "How does the company support employee mental health and well-being?",
      "What is the company’s policy on employee feedback and open communication?",
      "How does the company stay competitive within the industry in terms of employee benefits?",
      "Is there a clear pathway for employees to express concerns or suggestions for improvement?",
    ]
  },
  {
    title: "Investors",
    typicalQuestionsToAsk: [
      "What is the current financial health of the company, and what are its growth prospects?",
      "How does the company differentiate itself from competitors in the market?",
      "What are the main sources of revenue, and how sustainable are they?",
      "What are the company’s short-term and long-term growth strategies?",
      "Can you provide details about the experience and background of the management team?",
      "What are the major risks facing the business, and how are they being managed?",
      "What are the terms of the investment, and how is the company currently valued?",
      "How does the company navigate the regulatory environment in its industry?",
      "What is the market demand for the company’s product or service, and how is it validated?",
      "How does the company innovate and stay ahead technologically?",
      "Who are the core customers, and how stable is the customer base?",
      "Can you outline the company’s financial projections and key milestones for the next few years?",
      "What are the potential exit strategies for investors, and what is the expected ROI timeline?",
      "How does the company ensure good corporate governance and ethical business practices?",
      "What are the company's practices regarding sustainability and environmental impact?",
      "How is the company positioned in terms of market share and industry leadership?",
      "What partnerships or collaborations does the company have, and how do they enhance its value?",
      "How does the company plan to use the invested capital?",
      "Are there any pending litigations or legal challenges the company is facing?",
      "How does the company measure and report on its performance to investors?",
    ]
  },
  {
    title: "Marketing",
    typicalQuestionsToAsk: [
      "How can we segment our target market to tailor our marketing efforts more effectively?",
      "What is our brand's current reputation in the market, and how can we improve it?",
      "Which marketing channels have provided the best return on investment (ROI) in the past year?",
      "How do our product features compare to those of our main competitors?",
      "What pricing model will make us competitive yet ensure a healthy profit margin?",
      "How can we leverage social media to increase brand awareness and customer engagement?",
      "What are the key performance indicators (KPIs) for our latest marketing campaign?",
      "How can content marketing be used to build thought leadership in our industry?",
      "What customer pain points can our new product line address?",
      "How can we use customer feedback to improve our product/service offerings?",
      "Which digital marketing trends should we prioritize in the next fiscal year?",
      "How can we improve the customer journey to increase conversions and sales?",
      "What are the most effective ways to utilize email marketing in our overall strategy?",
      "How does our marketing budget compare with industry benchmarks?",
      "Can we identify any untapped markets or niches that we can target?",
      "How do we measure the lifetime value of a customer, and why is it important for our strategy?",
      "What are the legal considerations we need to be aware of in our advertising campaigns?",
      "How can we integrate artificial intelligence into our marketing efforts?",
      "What strategies can we implement to increase customer loyalty and retention?",
      "How can we adapt our marketing strategy to respond to unexpected market changes or crises?",
    ]
  },
  {
    title: "Media",
    typicalQuestionsToAsk: [
      "What are the key details of the latest breaking news story?",
      "How will this event impact the local community or the larger public?",
      "Can we confirm the source of this information, and is it credible?",
      "What is the historical background or context behind this issue or event?",
      "Can we get a statement or interview from the authorities or experts involved?",
      "What hidden aspects or underlying issues can we uncover through investigative journalism?",
      "How should we frame our editorial stance on this matter based on the facts available?",
      "What trends are emerging in this sector, and what can we predict for the future?",
      "Are there any ethical concerns or legal implications in reporting this story?",
      "What topics or stories are currently engaging our audience the most?",
      "How can we use photos, videos, or infographics to better convey this story?",
      "How do we report on conflicts or crises while ensuring accuracy and sensitivity?",
      "What procedures do we have in place for fact-checking and verifying the information?",
      "How does this cultural event or societal issue affect the community, and why is it newsworthy?",
      "What are the latest technological innovations, and how are they affecting our industry or society?",
      "What are the economic or political ramifications of this news event?",
      "How can we ensure our reporting is inclusive and represents diverse perspectives?",
      "What are the challenges in covering this story, and how can we address them?",
      "How can we leverage social media to increase the reach and impact of our reporting?",
      "What are the long-term implications of this story, and how can we monitor its development?",
    ]
  },
  {
    title: "Partners",
    typicalQuestionsToAsk: [
      "How does this partnership align with our strategic objectives and those of our partner?",
      "What are the main value propositions of this partnership for each party?",
      "Who will be responsible for what aspects of the partnership?",
      "What are the financial commitments and expected revenue outcomes for both parties?",
      "What risks are involved, and how will we collaboratively manage them?",
      "How will our operations need to adapt to integrate effectively with our partner?",
      "What metrics will define the success of this partnership?",
      "Are there any legal or regulatory concerns that need to be addressed in our agreement?",
      "How will we communicate and report progress and issues throughout the partnership?",
      "How will this partnership enhance our competitive position in the market?",
      "What opportunities for innovation or growth could arise from this partnership?",
      "How compatible are our organizational cultures, and how will we manage our relationship?",
      "What is the long-term vision for our partnership, and where do we see it going?",
      "What is our strategy for eventually exiting the partnership if necessary?",
      "How will the partnership affect our customers and other key stakeholders?",
      "Can we leverage this partnership to enter new markets or segments?",
      "What are the contingency plans if the partnership does not meet our expectations?",
      "How will we handle intellectual property and data sharing between partners?",
      "What are the potential impacts on our employees and how will we address them?",
      "How can we ensure a balanced and fair collaboration that benefits both parties?",
    ]
  },
  {
    title: "Sales",
    typicalQuestionsToAsk: [
      "What are the key needs and pain points of our target customers?",
      "How does our product/service address the specific challenges of our prospects?",
      "What are the sales targets for this quarter?",
      "What are the latest trends in our industry, and how do they impact our sales approach?",
      "How do our competitors’ offerings compare to ours, and how can we differentiate?",
      "What sales strategies have been most effective for our team, and why?",
      "How can we build stronger relationships with our key clients and prospects?",
      "What is our pricing strategy, and how flexible are we in negotiations?",
      "What tools or software do we use to manage sales activities more efficiently?",
      "How are we effectively manageing our sales pipeline to ensure a steady flow of business?",
      "What metrics are we focusing on to improve sales performance?",
      "What training programs or materials are available to help the team develop sales skills?",
      "What customer feedback do we have that we can use to improve sales pitches?",
      "How can sales work more effectively with the marketing team to generate quality leads?",
      "What are the key terms and conditions in our sales contracts that we should be aware of?",
      "What are some different sales approaches we can use to cater to different customer segments?",
      "What are the common objections we face, and how can I effectively overcome them?",
      "What social media strategies can we use to enhance sales efforts and build relationships?",
      "What strategies will we employ to upsell or cross-sell to existing customers?",
      "How can we ensure customer satisfaction and loyalty after closing a sale?",
    ]
  },
  {
    title: "Finance",
    typicalQuestionsToAsk: [
      "How does the current financial performance compare to our budget and forecasts?",
      "What strategies can we implement to improve our cash flow management?",
      "Are there areas in our budget where we can reduce costs without impacting operations?",
      "How should we allocate our capital to support growth and maximize returns?",
      "What are the primary financial risks facing our organization, and how can we mitigate them?",
      "Are we in compliance with all relevant financial regulations and standards?",
      "How can we improve the accuracy and insightfulness of our financial reporting?",
      "What tax strategies should we consider to minimize our liabilities and maximize efficiency?",
      "How do our debt levels compare with industry benchmarks, and is our financing strategy sustainable?",
      "What impact will recent economic trends have on our business and financial planning?",
      "How effective are our internal controls in preventing financial errors and fraud?",
      "Should we consider any mergers or acquisitions to enhance our market position and financial strength?",
      "How can we better communicate our financial strategies and results to stakeholders?",
      "What improvements can we make in our financial systems to increase efficiency and provide better data?",
      "How does our financial planning account for potential market or industry shifts?",
      "What measures are in place to ensure the integrity and accuracy of our financial data?",
      "How do global financial trends influence our investment and financing decisions?",
      "Are there any upcoming changes in financial regulations that we need to prepare for?",
      "How can we use financial data analytics to better inform our strategic decisions?",
      "What are the long-term financial implications of our current strategic initiatives?",
    ]
  },
  {
    title: "Legal",
    typicalQuestionsToAsk: [
      "Are we in compliance with the latest industry regulations and laws?",
      "How do we manage and negotiate contracts to protect our interests effectively?",
      "What are the key legal risks our organization faces, and how can we mitigate them?",
      "How do we ensure our intellectual property is adequately protected?",
      "What strategies should we adopt to handle disputes and potential litigation?",
      "Are our corporate governance practices aligned with legal requirements and best practices?",
      "How do we navigate employment law issues to minimize legal risk?",
      "Are our data privacy policies sufficient to comply with current regulations?",
      "What legal considerations must we take into account in our merger or acquisition plans?",
      "How do our policies need to evolve to reflect changes in law and regulatory requirements?",
      "Are we promoting and maintaining high ethical standards and corporate responsibility?",
      "What legal training do our employees need to comply with our industry regulations?",
      "How do we handle legal issues in international markets and manage cross-border legal risks?",
      "What are our obligations under environmental law, and how do we ensure compliance?",
      "How can we better integrate legal considerations into our business decision-making process?",
      "What are the consequences of non-compliance with specific legal standards for our organization?",
      "How should we revise our contracts to include new legal requirements or standards?",
      "What are the legal implications of new business strategies that we are considering?",
      "How do we maintain legal documentation and records to ensure they are audit-ready?",
      "What steps can we take to enhance our legal department’s efficiency and effectiveness?",
    ]
  },
  {
    title: "Support",
    typicalQuestionsToAsk: [
      "What are the most common issues our customers face, and how can we resolve them efficiently?",
      "How can I deepen my product knowledge to provide better support?",
      "What strategies can we use to improve customer satisfaction ratings?",
      "How can I improve my communication skills to handle difficult customer interactions?",
      "In what ways can we optimize our support processes for faster resolution times?",
      "What additional training do I need to address the changing needs of our customers?",
      "How do I effectively troubleshoot and solve technical issues customers are experiencing?",
      "What is the procedure for escalating a customer issue that I cannot resolve?",
      "How can we better collaborate with the product team to provide feedback and improve customer experience?",
      "Are we fully utilizing our customer support tools to their maximum potential?",
      "Are there any new policies or procedures I need to be aware of in customer support?",
      "How can we build stronger relationships with our customers through support interactions?",
      "Are we meeting our SLAs, and how can we improve our performance?",
      "How should we manage customer support across different communication channels?",
      "What insights can we gather from our support data to help reduce future issues?",
      "How can I ensure that customer queries are resolved in a timely and satisfactory manner?",
      "What role does customer support play in the overall customer journey and experience?",
      "How can we use customer feedback to drive improvements in our products or services?",
      "What are the best practices for managing high volumes of customer inquiries?",
      "How can we better anticipate customer needs to proactively address potential issues?",
    ]
  },

]

