import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItem } from "../../utils/cleanupAiOutput"


/** Returns a list of potential answers to a specific question  */
export const makeAnswersAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  question: string,
  amountToReturn = 4,
) => {

  const allContext: AllContextForAi = await getContextForAi()

  const prompt = getPrompt(question, amountToReturn)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
    openAIParams: {
      n: 2,
    }
  })

  // const rawString = response?.aiResponseJson.content as string || ""
  const rawChoices = response?.aiResponseJson.contentOptions as string[] || ""
  console.log("===> 2. makeAnswersAiCall: ", { prompt, rawChoices })
  const rawString = rawChoices[0] || ""
  let rawAnswers = rawString.split("\n").map(l => cleanupAiOutputItem(l)).filter(l => l.trim().length > 0)
  // console.log("makeAssumptionAnswersAiCall: ", { prompt, rawAnswers, response })

  if (rawAnswers.length > amountToReturn) {
    rawAnswers = rawAnswers.slice(0, amountToReturn)
  }

  return rawAnswers
}


export const getPrompt = (question: string, amountOfAnswersToReturn: number) => {
  return `
  You are an expert product manager and are answering questions about an opportunity. 

  INSTRUCTIONS:
  Suggest ${amountOfAnswersToReturn} very concise, specific answers to the following question: 
  ${question}

Give direct answers to the question being asked.
Partial sentence answers are great, keep the answers as concise as possible. 
Each answer should be specific only to the single question asked above.
Return only the answers in your output, each answer on its own line. DO NOT number them or include any dashes or other formatting characters, similar to the example outputs listed below.
DO NOT include any title, introduction, or preamble in your response.

EXAMPLE 1:
Given an empty document with the title: "Streamline onboarding"
Given the following question:
"What outcomes are we targeting?"
A valid response could be:
"Reduce friction in the onboarding process\nAccelerate time to value\nGuide users to the most valuable features"

EXAMPLE 2:
Given a fairly robust, well-structured document with the title: "Personalization PRD"
Given the following question: "Why do you think personalization will increase user engagement?"
A valid response could be:
"Familiar content catches users attention more than generic content\nPersonalization increases user's psychological connection to the product'\Personalized recommendations are more actionable"

EXAMPLE 3:
Given a partially complete document with less structured content and the title: "Research plan"
Given the following question: "What is the fastest path to testing the core hypothosis?"
A valid response could be:
"Interview prospective buyers to understand how they solve the problem today\nReview product analytics to discover patterns in user behavior\nCreate a prototype of the new feature and test it with the participants"

  `
}
