import { css } from "@emotion/react"
import { ButtonPrimary } from "../../components/buttons/ButtonPrimary"
import { DeRiskingStepOption } from "../deRiskingSteps/DeRiskingStepOptions"


type Props = {
  step: DeRiskingStepOption
  onAddClick?: (step: DeRiskingStepOption) => void
}
export const ActionListItem = ({
  step,
  onAddClick,
}: Props) => {


  return (
    <div css={css`
      border: 1px solid #99999933;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      opacity: 0.8;
      :hover {
        opacity: 1;
        border-color: #99999966;
      }
    `}>
      <div>
        <h3 css={css`font-weight: bold;`}>
          {step.title}
        </h3>
        <p>
          {step.description}
        </p>
      </div>
      {onAddClick &&
        <div>
          <ButtonPrimary
            label="Use"
            onClick={() => onAddClick(step)}
          />
        </div>
      }
    </div>
  )
}
