import { useCallback, useEffect } from "react"
import { OpportunitiesState, OpportunitiesViewMode } from "../../state/opportunitiesSlice"
import { useAppSelector } from "../../state/storeHooks"
import { dispatch } from "../../state/store"
import { css } from "@emotion/react"
import { useFeatureFlags } from "../../utility-hooks/useFeatureFlags"
import { IconButtonTertiary } from "../buttons/IconButtonTertiary"
import { VerdiIconViewModeKanban, VerdiIconViewModeList, VerdiIconViewModeMindMap } from "../icons/VerdiIcons"


export const KanbanViewToggle = () => {


  const viewMode = useAppSelector(OpportunitiesState.getOpportunitiesViewMode)
  const { experimentalEnabled } = useFeatureFlags()
  const onModeClick = useCallback((mode: OpportunitiesViewMode) => {
    dispatch(OpportunitiesState.setOpportunitiesViewMode(mode))
  }, [])


  useEffect(() => {
    const fromLocalStorage = OpportunitiesState.getViewModeFromLocalStorage()
    if (fromLocalStorage && Object.values(OpportunitiesViewMode).includes(fromLocalStorage)) {
      dispatch(OpportunitiesState.setOpportunitiesViewMode(fromLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  if (!experimentalEnabled) {
    return null
  }

  return (
    <div css={css`
      font-size: 1rem;
      opacity: 0.7;
      `}
    >
      <IconButtonTertiary
        aria-label="Kanban"
        title="Kanban"
        icon={<VerdiIconViewModeKanban />}
        onClick={() => onModeClick(OpportunitiesViewMode.Kanban)}
        backgroundColor={viewMode === OpportunitiesViewMode.Kanban ? "var(--item-selected-bg)" : undefined}
      />
      <IconButtonTertiary
        aria-label="List"
        title="List"
        icon={<VerdiIconViewModeList />}
        onClick={() => onModeClick(OpportunitiesViewMode.List)}
        backgroundColor={viewMode === OpportunitiesViewMode.List ? "var(--item-selected-bg)" : undefined}
      />
      <IconButtonTertiary
        aria-label="Mind Map"
        title="Mind Map"
        icon={<VerdiIconViewModeMindMap />}
        onClick={() => onModeClick(OpportunitiesViewMode.MindMap)}
        backgroundColor={viewMode === OpportunitiesViewMode.MindMap ? "var(--item-selected-bg)" : undefined}
      />
    </div>
  )

}
