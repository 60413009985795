import { css } from "@emotion/react"
import { Data } from "@verdi/shared-constants"
import { VerdiLogoIcon } from "../../../components/logo/VerdiLogoIcon"
import { useColor } from "../../../utility-hooks/useColor"
import { VerdiIconCheck } from "../../../components/icons/VerdiIcons"
import { useMemo } from "react"
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary"
import { mediaQuerySmallWidth } from "../../../components/pageLayout/styleHelpers"


type Props = {
  onButtonClick: () => void
  planOption: Data.OrganizationPlanOption.OrganizationPlanOptionModel
  isCurrentPlan?: boolean
  isDisabled: boolean
  isLoading: boolean
  isSpecialOffer?: boolean,
  crossedOutPrice?: string,
  trialStatusMessage?: string
  renewalStatusMessage?: string
  sideContent?: React.ReactNode
}
export const OrgPlanOptionBox = ({
  onButtonClick,
  planOption,
  isCurrentPlan,
  isDisabled,
  isLoading,
  crossedOutPrice,
  isSpecialOffer,
  renewalStatusMessage,
  trialStatusMessage,
  sideContent,
}: Props) => {


  const details = useMemo(() => {
    if (!planOption) return null

    const isMonthly = Boolean(planOption.interval === "month")

    const details = {
      intervalTitle: isMonthly ? "Monthly" : "Annual",
      displayPrice: planOption.priceInCents ? `$${planOption.priceInCents / 100}` : "Free",
      priceSubTitle: isMonthly ? "per user billed monthly" : "per user billed annually",
      benefits: ["Advanced Context", "Guided Validation", "Dynamic Frameworks", "Assisted Drafting"],
      isForDebugOnly: Boolean(planOption.priceInCents === 50)
    }
    return details
  }, [planOption])


  const specialOfferBg = useColor("itemActiveBg")
  const bgColor = useColor("surfaceBg")
  const borderColor = useColor("surfaceBorder")


  if (!details) return null


  const {
    benefits,
    displayPrice,
    intervalTitle,
    priceSubTitle,
    isForDebugOnly,
  } = details


  return (
    <div css={css`
      border: 1px solid ${borderColor};
      border-radius: 8px;
      background-color: ${bgColor};
      padding: 20px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      ${isForDebugOnly ? `
        opacity: 0.5;
        border-style: dashed;
      
      ` : ""};
      @container mainStage ${mediaQuerySmallWidth} {
          flex-direction: column;
        }
    `}>

      <div css={css`
        display: flex;
        flex-direction: column;
        flex: 1 1 0px;
      `}>

        <div css={css`
          font-size: 36px;
          font-weight: bold;
        `}>
          <span>
            {intervalTitle}
          </span>

          {isSpecialOffer &&
            <div css={css`
              /* position: absolute;
              top: -20px;
              left: -24px; */
              font-size: 12px;
              background-color: ${specialOfferBg};
              color: white;
              padding: 4px 8px;
              border-radius: 4px;
              display: flex;
              flex-direction: row;
              align-items: center;
              width: fit-content;
              float: right;
          `}>

              <div css={css`
                width: 30px;
                margin-left: -22px;
              `}>
                <VerdiLogoIcon />
              </div>

              <div>

                <span>
                  Special Offer
                  <br />
                  For a Limited Time
                </span>

              </div>

            </div>
          }
        </div>

        <div css={css`
          display: flex;
          flex-direction: row;
          font-size: 48px;
          font-weight: bold;
          margin-top: -10px;
        `}>

          <div css={css`
            text-decoration: line-through;
            opacity: 0.3;
            margin-right: 8px;
          `}>
            {crossedOutPrice}
          </div>

          <div>
            {displayPrice}
          </div>

        </div>

        <small css={css`
          opacity: 0.5;
          margin-bottom: 20px;
        `}>
          {priceSubTitle}
        </small>


        <div css={css`
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}>
          <strong>
            For small teams
          </strong>

          {details.benefits.map((benefit, key) =>
            <div key={key}>
              <VerdiIconCheck
                marginRight={2}
              />
              {benefit}
            </div>
          )}
        </div>

        {!isCurrentPlan &&
          <ButtonPrimary
            label="Let's Go"
            rightIcon={<VerdiIconCheck />}
            onClick={onButtonClick}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
        }

      </div>

      {sideContent &&
        <div css={css`
          margin: 10px 0;
          flex: 1 1 0px;
          display: flex;
          flex-direction: column;
          border-left: 1px solid ${borderColor};
          padding: 20px;
        `}>
          {sideContent}
        </div>
      }

    </div>
  )
}
