import { useCallback, useEffect, useMemo, useState } from "react"
import { css } from "@emotion/react"
import { useRouter } from "found"
import { Alert, AlertIcon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch } from "@chakra-ui/react"
import { DocumentSchema } from "@verdi/shared-constants"
import { ButtonPrimary } from "../../../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../../../components/buttons/ButtonSecondary"
import { allOpportunitiesRoutePath, getUrlForDocument } from "../../../../routes"
import { noneMenuItem } from "../../../../components/commands/miniCommandMenu/NoneMenuItem"
import { OpportunityTitleInput } from "../../../opportunities/OpportunityTitleInput"
import { SectionWrapper } from "../../../../components/pageLayout/SectionWrapper"
import { SelectCommandDropdown } from "../../../../components/commands/miniCommandMenu/SelectCommandDropdown"
import { SelectFrameworksDropdown } from "../../../documentFrameworks/SelectFrameworksDropdown"
import { useAppServices } from "../../../../components/appServices/useAppServices"
import { VerdiCommand } from "../../../document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { useAddNewOpportunity } from "./useAddNewOpportunity"
import { OpportunityStatusEditor } from "../../../opportunities/detailsPanel/OpportunityStatusEditor"
import { OpportunityStatusType } from "../../../../components/kanban/KanbanColumnOptions"
import { AddOpportunityShowOptionsCommandArgs } from "../../../../components/commands/commandDefinitions/opportunity/addOpportunityShowOptions"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import { VerdiIconOpportunity, VerdiIconViewModeMindMap } from "../../../../components/icons/VerdiIcons"
import { ButtonTertiary } from "../../../../components/buttons/ButtonTertiary"
import { startNewMindMapCommandDefinition } from "../../../../components/commands/commandDefinitions/mindMap/startNewMindMap"


/** Shows options for adding an opportunity */
export const AddNewOpportunityGlobalModal = () => {

  const router = useRouter()
  const { addNewOpportunityGlobalProvider, menuStructureProvider, toast } = useAppServices()
  const { disclosure, modalArgs } = addNewOpportunityGlobalProvider
  const [isAutoAdding, setIsAutoAdding] = useState(false)


  const onCreateComplete = useCallback((opportunityDocId: string) => {
    disclosure.onClose()
    setIsAutoAdding(false)

    if (window.location.pathname.includes(allOpportunitiesRoutePath)
      && !modalArgs?.shouldAutoAddWithDefaults) {

      showCreatedToastWithLink(opportunityDocId)
      return;
    }

    const url = getUrlForDocument(opportunityDocId)
    router.router.push(url)
  }, [disclosure, router, setIsAutoAdding])

  const showCreatedToastWithLink = useCallback((opportunityDocId: string) => {
    const url = getUrlForDocument(opportunityDocId)
    toast.showToastWithHtml({
      titleHtml: <div>Opportunity Created!</div>,
      descriptionHtml: <a href={url}>Click here to view it</a>,
      options: {
        duration: 10000,
      }
    })
  }, [toast])

  const {
    createNewOpportunity,
    setOpportunityDescription,
    setParentMenuItem,
    setSelectedFramework,
    isCreatingOpportunity,
    opportunityDescription,
    parentMenuItem,
    selectedFramework,
    selectedStatus,
    setSelectedStatus,
  } = useAddNewOpportunity({
    onCreateComplete,
    onError: toast.showError,
  })


  const [isShowingMoreOptions, setIsShowingMoreOptions] = useState(false)

  const commandsToSelectParentOpportunity = useMemo((): VerdiCommand[] => {
    const opportunityCommands = menuStructureProvider.menuItemsStructured.flatMenuItems
      .filter(doc => doc.type === DocumentSchema.DocumentType.opportunity && !!doc.title)
      .sort((a, b) => a.title.localeCompare(b.title))
      .map(menuItem => {
        return {
          id: menuItem.id,
          name: menuItem.title || "",
          searchName: menuItem.title || "",
        } as VerdiCommand
      })

    return [
      noneMenuItem,
      ...opportunityCommands
    ]
  }, [menuStructureProvider.menuItemsStructured.flatMenuItems])



  const onStatusSelected = useCallback((command: VerdiCommand) => {
    console.log("onStatusSelected", { command })
    const newValue = Number(command.id) as OpportunityStatusType
    setSelectedStatus(newValue)
  }, [])


  const applyArgs = useCallback((args: AddOpportunityShowOptionsCommandArgs | undefined) => {
    setIsShowingMoreOptions(false)
    if (!args) {
      setParentMenuItem(undefined)
      setSelectedStatus(OpportunityStatusType.Explore)
      setIsAutoAdding(false)
      return
    }

    if (args.status) {
      setSelectedStatus(args.status)
    }

    if (!parentMenuItem && args.parentDocId) {
      const parent = menuStructureProvider.getMenuItem(args.parentDocId)
      if (parent) {
        console.log("auto-setting parentMenuItem", { parent })
        setParentMenuItem(parent)
      }
    }

    if (args.shouldAutoAddWithDefaults) {
      setIsAutoAdding(true)
      createNewOpportunity()
    }
  }, [menuStructureProvider, parentMenuItem, setParentMenuItem, setSelectedStatus])


  const [isMappingOut, setIsMappingOut] = useState(false)
  const onMapItOutClicked = useCallback(() => {
    setIsMappingOut(true)
    startNewMindMapCommandDefinition.triggerCommand?.({
      description: opportunityDescription,
      onCompleted: () => {
        setIsMappingOut(false)
        disclosure.onClose()
      }
    })
  }, [setIsMappingOut, opportunityDescription, disclosure.onClose])


  useEffect(() => {
    applyArgs(modalArgs)
  }, [modalArgs])


  return (
    <div>
      <Modal
        onClose={disclosure.onClose}
        isOpen={disclosure.isOpen}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>

          <ModalCloseButton
            onClick={disclosure.onClose}
          />

          {!isAutoAdding &&

            <ModalHeader>
              <h2 css={css`
                display: flex;
                flex-direction: row;
                opacity: 0.01;
                width: calc( 100% - 50px);
              `}>
                <span css={css`
                  opacity: 0.5;
                  margin-right: 4px;
                  margin-top: -2px;
                `}>
                  <VerdiIconOpportunity boxSize={6} />
                </span>
                Add new Opportunity
              </h2>
            </ModalHeader>
          }
          <ModalBody>


            {isAutoAdding &&
              <div css={css`
                min-height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}>
                <LoadingSpinner
                  label="Creating Opportunity ..."
                  size="lg"
                />
              </div>
            }


            {!isAutoAdding &&
              <>
                <SectionWrapper>
                  {modalArgs?.isOnboarding &&
                    <Alert
                      size="xs"
                      marginBottom={4}
                    >
                      <AlertIcon />
                      <div css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                      `}>
                        <p>
                          Opportunities are how we start to organize thoughts in Verdi.
                        </p>
                        <p>
                          They can be used to represent a problem, a solution, or a project that you are working on.
                        </p>
                      </div>
                    </Alert>
                  }

                  {disclosure.isOpen &&
                    <div css={css`
                      min-height: 220px;
                    `}>
                      <OpportunityTitleInput
                        isDisabled={isCreatingOpportunity || isMappingOut}
                        title={opportunityDescription}
                        onTitleChange={setOpportunityDescription}
                      />
                    </div>
                  }

                </SectionWrapper>

                {!isShowingMoreOptions &&
                  <div>
                    <ButtonTertiary
                      label="Show More Options ..."
                      size="xs"
                      opacity={0.5}
                      onClick={() => setIsShowingMoreOptions(true)}
                    />
                  </div>
                }
                {isShowingMoreOptions &&

                  <div css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: start;
                  gap: 4px;
                  flex-wrap: wrap;
                `}>

                    {commandsToSelectParentOpportunity.length > 1 &&
                      <div>
                        <SelectCommandDropdown
                          isDisabled={isCreatingOpportunity}
                          size="xs"
                          label={parentMenuItem?.title
                            ? `Parent Opportunity: ${parentMenuItem.title}`
                            : "No Parent Opportunity"}
                          commandOptions={commandsToSelectParentOpportunity}
                          onItemSelected={(command) => {
                            console.log("onItemSelected", { command })
                            const menuItem = menuStructureProvider.menuItemsStructured.flatMenuItems.find(item => item.id === command.id)
                            setParentMenuItem(menuItem || undefined)
                          }}
                          searchPlaceholderText="choose an opportunity"
                        />
                      </div>
                    }

                    <OpportunityStatusEditor
                      status={selectedStatus}
                      onItemSelected={onStatusSelected}
                    />

                    <SelectFrameworksDropdown
                      isDisabled={false}
                      onItemAdded={(framework) => {
                        console.log("onItemAdded", { framework })
                        setSelectedFramework(framework)
                      }}
                      onItemRemoved={(framework) => setSelectedFramework(undefined)}
                      selectedFramework={selectedFramework}
                      shouldAutoLabel={true}
                      isForOpportunity={true}
                    />

                  </div>
                }

              </>
            }
          </ModalBody>

          {!isAutoAdding &&
            <ModalFooter>
              <ButtonTertiary
                label="Map it out"
                leftIcon={<VerdiIconViewModeMindMap />}
                onClick={onMapItOutClicked}
                isDisabled={opportunityDescription.length === 0 || isCreatingOpportunity}
                isLoading={isCreatingOpportunity || isMappingOut}
                opacity={0.5}
              />
              <ButtonSecondary
                label="Cancel"
                onClick={disclosure.onClose}
                marginRight={2}
              />
              <ButtonPrimary
                label="Create"
                onClick={createNewOpportunity}
                isDisabled={opportunityDescription.length === 0 || isCreatingOpportunity}
                isLoading={isCreatingOpportunity || isMappingOut}
              />
            </ModalFooter>
          }

        </ModalContent>
      </Modal>


    </div>
  )
}
