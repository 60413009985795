import { useCallback } from "react";
import { useRouter } from "found";

import { ChatRoleType } from "@verdi/shared-constants";

import { getUrlForDocument } from "../../routes";
import { useNewQuickNoteCreator } from "../../screens/documents/add/useNewQuickNoteCreator";
import { buildFirstDocStepFromRawJsonOutline, buildFirstDocStepFromRawMarkdown } from "../../screens/document/tiptapEditor/utils/buildFirstDocStepFromJson";
import posthog from "../../helpers/posthog";

import { GlobalCommandType } from "../commands/GlobalCommandType";

import { setFocusToChatInput } from "./UserInputForChat";
import { ChatHistoryProvider } from "./useChatHistory";
import { UseAiChatBridge } from "./useAiChatBridge";
import { addOpportunityCommandDefinition } from "../commands/commandDefinitions/opportunity/addOpportunity";

export type ChatButtonClickArgs = {
  buttonText: string;
  commandType?: GlobalCommandType;
  functionArguments?: object;
};

type Props = {
  chatHistoryProvider: ChatHistoryProvider;
  aiChatBridge: UseAiChatBridge;
};
export const useChatButtonProcessor = ({
  chatHistoryProvider,
  aiChatBridge,
}: Props) => {
  const router = useRouter();
  const noteCreator = useNewQuickNoteCreator({
    shouldShowChatOnRedirect: false,
  });

  const opportunityId = router.match.params["opportunityId"];

  const startNewChatWithMessage = useCallback(
    (message: string) => {
      aiChatBridge.triggerNewChatSession({
        content: message,
        role: ChatRoleType.assistant,
        createdAt: new Date(),
      });
      chatHistoryProvider.clearCurrentSessionMessages();
      setFocusToChatInput();
    },
    [
      aiChatBridge,
      chatHistoryProvider,
    ]
  );

  const processChatButtonClick = useCallback((args: ChatButtonClickArgs) => {

    const { buttonText, commandType, functionArguments } = args;

    posthog.capture('chatButtonClick', { buttonText, commandType, flows: ['chat'] });

    console.log(
      "processChatButtonClick ",
      { commandType },
      buttonText,
      functionArguments
    );

    if (commandType === GlobalCommandType.createQuickNote) {
      const { title, initialStepJsonString } =
        extractDocumentContentFromArgs(functionArguments);
      console.log("will now create quick note ", {
        commandType,
        functionArguments,
        initialStepJsonString,
      });

      console.log("opportunityId ", opportunityId);
      if (opportunityId) {
        noteCreator.createNewDocWithinOpportunity(
          opportunityId,
          title,
          initialStepJsonString
        );
      } else {
        noteCreator.createNewQuickNote(title, initialStepJsonString);
      }
    }

    if (commandType === GlobalCommandType.createOpportunity) {
      const { title, initialStepJsonString: initialStepJson } = extractDocumentContentFromArgs(functionArguments);
      console.log("will now create quick note ", {
        commandType,
        functionArguments,
        initialStepJson,
      });

      addOpportunityCommandDefinition.triggerCommand?.({
        newOpportunity: {
          title: title || "Untitled Opportunity",
          initialStepJson,
        },
        currentDocId: undefined,
        conceptsToUse: [],
        framework: undefined,
        onCreated: (newDocId?: string) => {
          console.log("usechatbutton processor: onOpptyCreated ", { newDocId });
          const url = getUrlForDocument(newDocId || "") + "?chatIsVisible=true";
          router.router.push(url);
        }
      })

    }

    if (commandType === GlobalCommandType.exploreIdeaWithAi) {
      startNewChatWithMessage("Please tell me more about your idea ...");
    }

    if (commandType === GlobalCommandType.workOnGoal) {
      startNewChatWithMessage(
        "Please tell me more about the goal you are trying to accomplish ..."
      );
    }
  }, [opportunityId, startNewChatWithMessage, noteCreator, router]
  );

  return {
    processChatButtonClick,
  };
};
export type ChatButtonProcessor = ReturnType<typeof useChatButtonProcessor>;

const extractDocumentContentFromArgs = (
  functionArguments: object | undefined
) => {
  if (!functionArguments) {
    return {
      title: undefined,
      initialStepJsonString: undefined,
    };
  }
  const { title, bodyJson, bodyMarkdown, } = functionArguments as {
    title?: string;
    bodyJson?: string;
    bodyMarkdown?: string;
  };

  let initialStepJsonString
  if (bodyMarkdown) {
    initialStepJsonString = buildFirstDocStepFromRawMarkdown(bodyMarkdown)
  } else if (bodyJson) {
    initialStepJsonString = buildFirstDocStepFromRawJsonOutline(bodyJson)
  }

  return {
    title,
    initialStepJsonString,
  };
};
