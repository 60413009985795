import { SelectCommandDropdown } from "../../components/commands/miniCommandMenu/SelectCommandDropdown"
import { VerdiCommand } from "../document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { useCallback, useMemo } from "react"
import { useAppSelector } from "../../state/storeHooks"
import { noneMenuItem } from "../../components/commands/miniCommandMenu/NoneMenuItem"
import { Data } from "@verdi/shared-constants"


type Props = {
  isDisabled: boolean
  selectedFramework: Data.FrameworkModel | undefined
  onItemAdded?: (framework: Data.FrameworkModel) => void
  onItemRemoved?: (framework: Data.FrameworkModel) => void
  /** Adds labels when true */
  shouldAutoLabel: boolean
  isForOpportunity: boolean
}
/** Allows selecting frameworks from a list, 
 *  WITHOUT immediately Persisting to the DB.
 * 
 *  If looking to add/remove frameworks to an existing document, 
 *  check out `<DocumentFrameworkEditor />` 
 */
export const SelectFrameworksDropdown = ({
  isDisabled,
  selectedFramework,
  onItemAdded,
  onItemRemoved,
  shouldAutoLabel,
  isForOpportunity,
}: Props) => {


  const allFrameworks = useAppSelector(state => state.frameworks.frameworks)
  const allFrameworksCommands = useMemo<VerdiCommand[]>(() => {
    const toReturn = allFrameworks
      .filter(f => f.isForOpportunity === isForOpportunity)
      .map(f => {
        const command: VerdiCommand = {
          id: f.id,
          name: f.title,
          description: f.description,
          searchName: f.title.toLowerCase(),
          // isSelected: selectedFrameworks.some(sf => sf.id === f.id),
        }
        return command
      })
    return [noneMenuItem, ...toReturn]
  }, [allFrameworks])


  const label = useMemo(() => {
    if (!selectedFramework) {
      return shouldAutoLabel ? "No Framework Selected" : "none"
    }
    return shouldAutoLabel ? `Framework: ${selectedFramework.title}` : selectedFramework.title
  }, [selectedFramework])


  const onItemSelected = useCallback((command: VerdiCommand) => {
    console.log("onItemSelected", { command, selectedFramework })

    // Remove if needed
    if (selectedFramework
      && (selectedFramework.id !== command.id || command.id === noneMenuItem.id)) {
      console.log("do removed")
      onItemRemoved?.(selectedFramework)
    }

    // Selected the current one
    if (selectedFramework?.id === command.id) {
      console.log("Chose the one that is already selected")
      return
    }

    // Add the new one
    const framework = allFrameworks.find(f => f.id === command.id)
    if (framework) {
      onItemAdded?.(framework)
    }

  }, [allFrameworks, selectedFramework, onItemAdded, onItemRemoved])


  if (!allFrameworks) {
    console.warn("No frameworks found")
    return null
  }


  return (
    <div>
      <SelectCommandDropdown
        isDisabled={isDisabled}
        size="xs"
        label={label}
        commandOptions={allFrameworksCommands}
        onItemSelected={onItemSelected}
        searchPlaceholderText="choose a framework"
        maxWidth={200}
      />
    </div>
  )
}
