import {
  Checkbox,
  Heading,
  Input,
  Select,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import graphql from "babel-plugin-relay/macro";
import { DocumentEditor } from "../document/DocumentEditor";
import { useLazyLoadQuery } from "react-relay";
import { useUpdateDocumentTemplateInfo } from "./useUpdateDocumentTemplateInfo";
import { useCallback, useState } from "react";
import { useThrottle } from "../../utility-hooks/useThrottle";
import { DocumentTemplateEditor_Query, DocumentType } from "./__generated__/DocumentTemplateEditor_Query.graphql";
import { DocumentSchema } from "@verdi/shared-constants";
import VeNode from "../document/tiptapEditor/VeNode";
import {
  AIPromptMenuSelection,
  EditAiPromptOptionsMenu,
} from "./aiPromptForm/EditAiPromptOptionsMenu";
import { EditDocumentContextTemplate } from "./aiPromptForm/EditDocumentContextTemplate";
import { AiPromptList } from "./AiPromptList";
import { CurrentBlockContext } from "../document/tiptapEditor/utils/getCurrentBlockContext";
import { PageLayoutState } from "../../components/pageLayout/usePageLayoutState";
import { useAppServices } from "../../components/appServices/useAppServices";


const queryQL = graphql`
  query DocumentTemplateEditor_Query($documentId: ID!) {
    node(id: $documentId) {
      ... on Document {
        id
        title
        type
        ...EditAiPromptOptionsMenu_document
        templateInfo {
          id
          title
          isPublished
        }
        ...AiPromptList_document
        ...EditDocumentContextTemplate_document
      }
    }
  }
`;
const yOffsetOfThisComponentOnTheDOM = 70;
const documentTypes = DocumentSchema.getAllDocumentTypes();

type TemplateInfo = {
  documentTemplateInfoId: string;
  title: string;
  isPublished: boolean;
  documentType: DocumentType;
};

type Props = {
  pageLayoutState: PageLayoutState;
  documentId: string;
};
export function DocumentTemplateEditor({ pageLayoutState, documentId }: Props) {
  const [aiOptionsMenuSelection, setAIOptionsMenuSelection] =
    useState<AIPromptMenuSelection>();
  const query = useLazyLoadQuery<DocumentTemplateEditor_Query>(queryQL, {
    documentId: documentId,
  });

  const document = query.node;
  if (!document)
    throw Error("No document found with that ID for this template!");

  const [input, setInput] = useState<TemplateInfo>({
    documentTemplateInfoId: document?.templateInfo?.id || "",
    title: document?.templateInfo?.title || "",
    isPublished: document?.templateInfo?.isPublished || false,
    documentType: (document?.type as DocumentType) || "",
  });

  const debouncedSave = useThrottle((changes: TemplateInfo) =>
    saveTemplate(changes)
  );
  const [saveTemplate, isSavingTemplate] = useUpdateDocumentTemplateInfo();
  const saveTemplateChanges = useCallback(
    async (changes: TemplateInfo) => {
      setInput(changes);
      debouncedSave(changes);
    },
    [setInput, debouncedSave]
  );

  const sharedServicesProvider = useAppServices()


  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <Stack
        spacing={4}
        css={css`
          width: 100%;
        `}
      >
        <Heading
          as="h1"
          opacity={0.3}
          css={css`
            margin-top: -55px;
          `}
        >
          Template Editor
        </Heading>

        <Stack
          css={css`
            -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.1);
          `}
        >
          <DocumentEditor
            key={documentId}
            pageLayoutState={pageLayoutState}
            documentId={documentId}
            sharedServicesProvider={sharedServicesProvider}
            onCurrentBlockContextChange={(context: CurrentBlockContext) => {
              console.log("onCurrentBlockContextChange ", context);

              if (!context?.node || !context.domPositionBlock || !context.view) {
                setAIOptionsMenuSelection(undefined);
                return;
              }

              const selection = context.view.state.selection;
              if (!selection.empty) return;
              const theNode = VeNode.fromResolvedPosition(
                selection.$anchor
              )?.findNestableParent(context.view.state);
              if (!theNode) {
                setAIOptionsMenuSelection(undefined);
                return;
              } else {
                setAIOptionsMenuSelection({
                  yPos:
                    context.domPositionBlock?.top - yOffsetOfThisComponentOnTheDOM,
                  node: theNode,
                  editorView: context.view,
                });
              }
            }}
          />
        </Stack>
      </Stack>

      <div
        css={css`
          padding: 20px;
          display: flex;
          flex-direction: column;
          min-width: 220px;
          max-width: 320px;
          position: relative;
          flex: 1;
          height: 100vh;
          gap: 16px;
          margin-top: -5px;
        `}
      >
        <EditAiPromptOptionsMenu
          selection={aiOptionsMenuSelection}
          document={document}
        />

        <Heading as="h2" size="md">
          Template Info
        </Heading>

        <label>
          Template Title
          <Input
            value={input.title}
            onChange={(e) => {
              saveTemplateChanges({ ...input, title: e.target.value });
            }}
          ></Input>
        </label>

        <label>
          <Checkbox
            isChecked={input.isPublished}
            onChange={(e) => {
              saveTemplateChanges({ ...input, isPublished: e.target.checked });
            }}
            title="If check, end users can see this template"
          >
            Is Published
          </Checkbox>
        </label>

        <label>
          Document Type
          <Select
            value={input.documentType}
            onChange={(e) => {
              saveTemplateChanges({
                ...input,
                documentType: e.target.value as DocumentType,
              });
            }}
          >
            {documentTypes.map((docType, index) => (
              <option key={index}>{docType}</option>
            ))}
          </Select>
          <EditDocumentContextTemplate document={document} />
        </label>
        <AiPromptList document={document} />
      </div>
    </div>
  );
}
