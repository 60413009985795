import { ApiTypes, Data } from "@verdi/shared-constants"
import { makeGetRequestJson, makePostRequestJson } from "../../utility-hooks/fetchUtils"
import { dispatch, getCurrentAppState } from "../../state/store"
import { documentFrameworkState } from "../../state/documentFrameworksSlice"
import { FrameworksState } from "../../state/frameworksSlice"


let loadAllPromise: Promise<any>

/** returns the single promise that loads all frameworks (and docFrameworks) one time */
const ensureAllAreLoaded = async () => {

  if (loadAllPromise) {
    return loadAllPromise
  }

  loadAllPromise = new Promise<void>(async (resolve, reject) => {

    const loadFrameworksPromise = new Promise<void>(async (resolveFrameworks) => {
      const frameworksRaw: Data.FrameworkModel[] = await makeGetRequestJson("frameworks")
      const sorted = frameworksRaw.sort((a, b) => a.title.localeCompare(b.title))
      dispatch(FrameworksState.setAllFrameworks(sorted))
      resolveFrameworks()
    })

    const loadDocFrameworksPromise = new Promise<void>(async (resolveDocFrameworks) => {
      const docFrameworksRaw = await makeGetRequestJson("documentFrameworks")
      dispatch(documentFrameworkState.setAllDocumentFrameworks(docFrameworksRaw))
      resolveDocFrameworks()
    })

    Promise.allSettled([loadFrameworksPromise, loadDocFrameworksPromise])
      .finally(() => {
        resolve()
      })
  })
}

/** Sets the given document's framework (DocumentFramework), both the DB and local state.
 * 
 * TODO: When we are ready to handle multiple, move away from this and use `create()` and `remove()` */
export const setFrameworkForDocument = async (documentId: string, framework: Data.FrameworkModel) => {
  const body: ApiTypes.DocumentFrameworkAddRequestBody = {
    documentId,
    frameworkId: framework.id,
  }
  const response: Data.DocumentFrameworkDto = await makePostRequestJson("documentFrameworkReplace", body)
  dispatch(documentFrameworkState.removeAllForDocument({ documentId }))
  dispatch(documentFrameworkState.addDocumentFramework(response))

  // TODO: Sort out current opportunity vs document
  dispatch(documentFrameworkState.setCurrentDocumentFramework(response))
}

const createDocumentFramework = async (documentId: string, framework: Data.FrameworkModel) => {
  const body: ApiTypes.DocumentFrameworkAddRequestBody = {
    documentId,
    frameworkId: framework.id,
  }
  const response: Data.DocumentFrameworkDto = await makePostRequestJson("documentFrameworkAdd", body)
  dispatch(documentFrameworkState.addDocumentFramework(response))
}


const removeDocumentFramework = async (documentFrameworkId: string) => {
  const body: ApiTypes.DocumentFrameworkRemoveRequestBody = {
    documentFrameworkId,
  }
  try {
    await makePostRequestJson("documentFrameworkRemove", body)
  } catch { }
  dispatch(documentFrameworkState.removeDocumentFramework({
    documentFrameworkId,
  }))

}

const getFrameworkQuestionsForDoc = async (documentId: string) => {
  await ensureAllAreLoaded() // Only once we have initially loaded frameworks

  const docFramework = getCurrentAppState().documentFrameworks.allDocumentFrameworks.find(df => df.documentId === documentId)
  if (docFramework?.frameworkId) {
    const framework = getCurrentAppState().frameworks.frameworks.find(f => f.id === docFramework.frameworkId)
    if (framework) {
      return framework.questions
    }
  }
  return []
}


export const DocumentFrameworksProvider = {
  ensureAllAreLoaded,
  getFrameworkQuestionsForDoc,
  setFrameworkForDocument,
  createDocumentFramework,
  removeDocumentFramework,
}


