
/** Throws an error if placeholder name contains invalid characters */
export const validatePlaceholderName = (placeholderName: string) => {

  if (!placeholderName) {
    throw new Error("Placeholder name cannot be empty")
  }

  if (placeholderName.length < 3) {
    throw new Error("Placeholder must be at least 3 characters long.")
  }

  const startsWithRegex = /^[a-zA-Z_$]/
  const containsRegex = /[^a-zA-Z0-9_$]/g

  if (containsRegex.test(placeholderName)) {
    throw new Error("Placeholder name can only contain letters, numbers and underscores.")
  }

  if (!startsWithRegex.test(placeholderName)) {
    throw new Error('Invalid input: property name must start with a letter, underscore, or $');
  }

}
