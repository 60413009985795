import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"
import { Editor } from "@tiptap/core"


const cmdType = GlobalCommandType.moveSelectionTo
const { PlaceholderInline } = DocumentSchema.NodeExtensions.VerdiNodeTypes
const { findPlaceholderNodes } = DocumentSchema.NodeExtensions.PlaceholderInlineNodeUtils

/** Moves the current caret position in the document to the specified place */
export const moveSelectionToCommandDefinition: GlobalCommandDefinition<MoveSelectionToCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: MoveSelectionToCommandArgs) => {
    const title = "Move selection to ..."
    const toReturn =
      {
        globalCommandType: cmdType,
        name: title,
        searchName: title.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: MoveSelectionToCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const editor = services.servicesForCurrentDoc?.getEditor()
    if (!editor) {
      onProcessingComplete(false)
      console.warn('moveSelectionTo: No editor found in servicesForCurrentDoc')
      return
    }

    if (args.nodeType === PlaceholderInline) {
      const placeholders = findPlaceholderNodes(
        editor.state.doc,
        args.textToFind
      )
      console.log("moveSelectionTo: found placeholders", { placeholders })
      if (placeholders.length > 0) {
        const from = placeholders[0].pos
        editor.commands.setTextSelection({ from, to: from + 1 })
        onProcessingComplete(true)
        return
      }
    }

    onProcessingComplete(true)
  },
  triggerCommand: (args: MoveSelectionToCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type MoveSelectionToCommandArgs = {
  textToFind: string
  nodeType: DocumentSchema.NodeExtensions.VerdiNodeTypes
}


export const moveSelectionTo = (editor: Editor, args: MoveSelectionToCommandArgs) => {
  if (args.nodeType === PlaceholderInline) {
    const placeholders = findPlaceholderNodes(
      editor.state.doc,
      args.textToFind
    )
    if (placeholders.length > 0) {
      const from = placeholders[0].pos
      editor.commands.setTextSelection({ from, to: from + 1 })
      return true
    }
  }
  return false
}
