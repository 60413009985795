import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputItem } from "../../utils/cleanupAiOutput"


/** Returns a list of potential answers to a specific question (Assumption) */
export const makeAssumptionAnswersAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  question: string,
  amountToReturn = 4,
) => {

  const allContext: AllContextForAi = await getContextForAi()

  const prompt = getPrompt(question, amountToReturn)

  const response = await makeAiChatRequest({
    prompt,
    messages: allContext.promptMessages,
  })

  const rawString = response?.aiResponseJson.content as string || ""
  let rawAnswers = rawString.split("\n").map(l => cleanupAiOutputItem(l)).filter(l => l.trim().length > 0)
  // console.log("makeAssumptionAnswersAiCall: ", { prompt, rawAnswers, response })

  if (rawAnswers.length > amountToReturn) {
    rawAnswers = rawAnswers.slice(0, amountToReturn)
  }

  return rawAnswers
}


export const getPrompt = (question: string, amountOfAnswersToReturn: number) => {
  return `
  You are a product expert and are answering questions about an opportunity. Given the following context:

  INSTRUCTIONS:
  Suggest ${amountOfAnswersToReturn} very concise, specific answers to the following question: 
  ${question}

  Partial sentence answers are great, keep the answers as concise as possible. 
  Each answer should be specific only to the single question asked above.
  Only return the answers in your output, each answer on its own line.

  Examples:
  Reduction in onboarding time by at least 50%
  Accelerated onboarding time for new users
  Through in-app surveys, utilized to identify pain points and areas of improvement
  `
}
