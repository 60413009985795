import { DocumentSchema } from "@verdi/shared-constants";


export type DeRiskingStepOption = {
  id: string,
  title: string,
  description: string,
  documentTypeToCreate: DocumentSchema.DocumentType,
  suggestedActionType: SuggestedActionType,
}

export enum SuggestedActionType {
  Interview = "Interview",
  DeskResearch = "DeskResearch",
  UserTesting = "UserTesting",
  Survey = "Survey",
  Experiment = "Experiment",
  Other = "Other",
}


export const DeRiskingStepOptions: DeRiskingStepOption[] = [
  {
    id: "hardcoded-ConductInterviews",
    title: "Conduct Interviews",
    description: "Prepare interview questions to ask your users, or an expert.",
    documentTypeToCreate: DocumentSchema.DocumentType.interviewGuide,
    suggestedActionType: SuggestedActionType.Interview,
  },
  {
    id: "hardcoded-SendOutASurvey",
    title: "Send out a Survey",
    description: "Prepare Survey questions to send to users.",
    documentTypeToCreate: DocumentSchema.DocumentType.document,
    suggestedActionType: SuggestedActionType.Survey,
  },
  {
    id: "hardcoded-DeskResearch",
    title: "Desk Research",
    description: "Compile notes while researching online.",
    documentTypeToCreate: DocumentSchema.DocumentType.document,
    suggestedActionType: SuggestedActionType.DeskResearch,
  },
]
