import { useLazyLoadQuery } from "react-relay";
import { PageLayoutSplitViewWrapper } from "../../components/pageLayout/pageLayoutSplitViewWrapper";
import { DocumentTemplateEditor } from "./DocumentTemplateEditor";
import { DocumentTemplateSideMenu } from "./DocumentTemplateSideMenu";
import { DocumentTemplatePageQuery } from "./__generated__/DocumentTemplatePageQuery.graphql";
import graphql from "babel-plugin-relay/macro"
import { SuspenseErrorBoundary } from "../../components/SuspenseErrorBoundary";
import { usePageLayoutState } from "../../components/pageLayout/usePageLayoutState";
import { useAppServices } from "../../components/appServices/useAppServices";


const queryQL = graphql`
  query DocumentTemplatePageQuery(
    $first: Int!
    $after: String
  ) {
    ...DocumentTemplateSideMenu_query
  }
`


type Props = {
  documentId?: string
}

export function DocumentTemplatePage(props: Props) {


  const appServices = useAppServices()
  const { router } = appServices
  const documentId = props.documentId || router.match.params['documentId']

  const query = useLazyLoadQuery<DocumentTemplatePageQuery>(queryQL, {
    first: 100,
  })
  const pageLayoutState = usePageLayoutState()


  return (
    <PageLayoutSplitViewWrapper
      appServices={appServices}
      mainMenu={

        <div>
          <DocumentTemplateSideMenu query={query} currentDocumentId={documentId} />
        </div>

      }
      mainContent={

        documentId ?
          <SuspenseErrorBoundary>
            <DocumentTemplateEditor
              pageLayoutState={pageLayoutState}
              documentId={documentId}
            />
          </SuspenseErrorBoundary>
          :
          <div>Please select an option</div>

      } />
  )
}
