import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { clipText } from "../../utils/clipText"


const cmdType = GlobalCommandType.moveDocToParent

/** Moves a given childDoc to a parentDoc. Related to `showMoveDocToParent` */
export const moveDocToParentCommandDefinition: GlobalCommandDefinition<MoveDocToParentCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: MoveDocToParentCommandArgs) => {

    const { childDocId, newParentDocId, newParentTitle } = args
    const id = `moveDocToParent-${childDocId}-${newParentDocId}`
    const name = clipText(newParentTitle, 50) || ""

    const toReturn =
      {
        id,
        globalCommandType: cmdType,
        name,
        searchName: name.toLowerCase(),
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: MoveDocToParentCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const docRelationsProvider = services.documentRelationsProvider
    if (!docRelationsProvider) {
      console.warn("moveDocToParent: Could not find docRelationsProvider")
      return
    }
    const { childDocId, newParentDocId, onCompleted } = args

    await docRelationsProvider.moveDocToParent(childDocId, newParentDocId)

    services.toast.showSuccess("Note moved")

    onCompleted()
    onProcessingComplete(true)

  },
  triggerCommand: (args: MoveDocToParentCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type MoveDocToParentCommandArgs = {
  childDocId: string
  newParentDocId: string
  newParentTitle: string
  onCompleted: () => void
}
