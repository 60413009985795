import { Data, DocumentSchema, Menu } from "@verdi/shared-constants"


/** Finds the closest opportunity that the given doc is a part of, which may be itself */
export const getNearestOpportunity = (
  currentDoc: Menu.MenuItemData,
  getMenuItem: (id: string) => Menu.MenuItemData | undefined,
) => {

  if (currentDoc.type === DocumentSchema.DocumentType.opportunity) {
    return currentDoc
  }

  // recursively traverse up the tree until we find an opportunity
  return getParentOpportunity(currentDoc, 0, getMenuItem)
}


const getParentOpportunity = (
  doc: Menu.MenuItemData,
  recursionCount = 0,
  getMenuItem: (id: string) => Menu.MenuItemData | undefined,
): Menu.MenuItemData => {

  if (recursionCount > Data.Menu.maxNestableLevels
    || !doc.parentDocId) {
    return doc
  }

  const parent = getMenuItem(doc.parentDocId)
  if (!parent) {
    return doc
  }

  if (parent.type === DocumentSchema.DocumentType.opportunity) {
    return parent
  }

  return getParentOpportunity(parent, recursionCount + 1, getMenuItem)


}
