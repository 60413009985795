import { useCallback, useMemo, useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { useRouter } from "found";
import {
  AbsoluteCenter,
  Alert,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaBuilding } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { css } from "@emotion/react";

import { Data, ApiTypes } from "@verdi/shared-constants";

import {
  menuBackgroundColors,
  documentBackgroundColors,
} from "../components/ColorModeValues";
import { VerdiLogo } from "../components/logo/VerdiLogo";
import { useUrlParams } from "../utility-hooks/useUrlParams";
import posthog, { posthogCallback } from "../helpers/posthog";

import {
  checkLoginStatus,
  register,
  login,
} from "./network";
import LoginWithGoogle from "./LoginWithGoogle";
import { VerdiIconEmail, VerdiIconIsNotVisible, VerdiIconIsVisible, VerdiIconLock } from "../components/icons/VerdiIcons";

type RegisterCred = {
  username: string;
  password: string;
  name: string;
  orgInviteToken?: string;
}

const Register = () => {
  const { router } = useRouter();
  const [error, setError] = useState<string>();

  const flows = useMemo(() => {
    return ['register']
  }, [])

  const { orgInviteToken, organizationName: orgNameFromUrl, invitedEmailAddress } = useUrlParams()
  const [registerCreds, setRegisterCreds] = useState<RegisterCred>({
    username: invitedEmailAddress || "",
    password: ``,
    name: ``,
    orgInviteToken: orgInviteToken || "",
  });


  const [orgName, setOrgName] = useState<string>(orgNameFromUrl || "")
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const environment = useRelayEnvironment();
  const iconColor = useColorModeValue("gray.400", "gray.500");

  const handleLoginPress = useCallback(() => {
    window.location.href = 'login';
  }, []);


  const onSuccess = async () => {
    await checkLoginStatus(environment);
    router.replace(`/onboarding`);
  };

  const isReadyForPasswordAuth =
    registerCreds.username?.length && registerCreds.password?.length;

  const attemptRegistration = async () => {
    if (!isReadyForPasswordAuth) {
      return;
    }
    setLoggingIn(true);
    try {
      const toPost: ApiTypes.UserRegistrationRequestBody = {
        username: registerCreds.username,
        name: registerCreds.name,
        password: registerCreds.password,
      }

      if (registerCreds.orgInviteToken) {
        toPost.orgInviteToken = registerCreds.orgInviteToken
      } else {
        toPost.newOrgName = orgName
      }

      await register(toPost)
      posthog.capture('registerSuccess', { flows: ['register'] });
      await attemptLogin();
    } catch (error: any) {
      posthog.capture('registerFail', { flows: ['register'], error: error?.message });
      setError(error?.message);
    } finally {
      setLoggingIn(false);
    }
  };

  const attemptLogin = async () => {
    posthog.capture('registerLogin', { flows: ['register'] });
    if (!isReadyForPasswordAuth) {
      return;
    }
    setLoggingIn(true);

    try {
      const { jwt, error } = await login(
        registerCreds.username,
        registerCreds.password
      );
      if (jwt) {
        posthog.capture('registerLoginSuccess', { flows: ['register'] });
        await onSuccess();
      } else {
        posthog.capture('registerLoginFail', { error: error, flows: ['register'] });
        setError(error?.message || error);
      }
    } catch (error: any) {
      posthog.capture('registerLoginFail', { error: error, flows: ['register'] });
      setError(error.message);
    } finally {
      setLoggingIn(false);
    }
  };

  const dialogBgColor = useColorModeValue(
    documentBackgroundColors.light,
    documentBackgroundColors.dark
  )


  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      backgroundColor={useColorModeValue(
        menuBackgroundColors.light,
        menuBackgroundColors.dark
      )}
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box minW={{ base: "90%", md: "375px" }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor={dialogBgColor}
              boxShadow="sm"
              borderRadius="lg"
              py="25"
            >
              <div
                style={{
                  margin: `12px`,
                  marginLeft: `33px`,
                  maxWidth: 275,
                }}
              >
                <VerdiLogo />
              </div>
              <div css={css`
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 12px;
              
              `}>
                <p>
                  Try Verdi free for 30 days.
                </p>
                <p>
                  No credit card required.
                </p>
                <p>
                  <small>
                    Enter your details to get started ...
                  </small>
                </p>
              </div>
              {error && <Alert status="error">{error}</Alert>}
              <FormControl w="100%">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<VerdiIconEmail color={iconColor} />}
                  />
                  <Input
                    id="usernameemail"
                    variant={`outline`}
                    value={registerCreds.username}
                    onFocus={posthogCallback(undefined, 'fieldFocus', { field: 'username', flows: ['register'] })}
                    onChange={(e) =>
                      setRegisterCreds({
                        ...registerCreds,
                        username: e.target.value,
                      })
                    }
                    onBlur={(e) => {
                      if (!registerCreds.orgInviteToken && !orgName) {
                        const organization =
                          Data.EmailDomains.getUserCompanyDomainForEmail(
                            e.target.value
                          );
                        setOrgName(organization?.split(".")[0] || "")
                      }
                    }}
                    placeholder="Email Address"
                    disabled={Boolean(registerCreds.orgInviteToken)}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<VerdiIconLock color={iconColor} />}
                  />
                  <Input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    variant={`outline`}
                    value={registerCreds.password}
                    onFocus={posthogCallback(undefined, 'fieldFocus', { field: 'password', flows: ['register'] })}
                    onChange={(e) =>
                      setRegisterCreds({
                        ...registerCreds,
                        password: e.target.value,
                      })
                    }
                  />
                  <InputRightElement>
                    <Button
                      h="1.75rem"
                      variant="link"
                      onClick={() => setShowPassword(!showPassword)}
                      color={useColorModeValue("gray.400", "gray.500")}
                    >
                      {showPassword ? <VerdiIconIsNotVisible /> : <VerdiIconIsVisible />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={BsFillPersonFill} color={iconColor} />}
                  />
                  <Input
                    id="name"
                    placeholder="Your Name"
                    variant={`outline`}
                    value={registerCreds.name}
                    onFocus={posthogCallback(undefined, 'fieldFocus', { field: 'name', flows: ['register'] })}
                    onChange={(e) =>
                      setRegisterCreds({
                        ...registerCreds,
                        name: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              </FormControl>
              <FormControl w="100%">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={FaBuilding} color={iconColor} />}
                  />
                  <Input
                    id="organization"
                    variant={`outline`}
                    value={orgName}
                    onFocus={posthogCallback(undefined, 'fieldFocus', { field: 'org', flows: ['register'] })}
                    onChange={(e) => {
                      setOrgName(e.target.value)
                    }}
                    placeholder="Organization"
                    disabled={Boolean(registerCreds.orgInviteToken)}
                  />
                </InputGroup>
              </FormControl>

              <Button
                width="100%"
                onClick={posthogCallback(attemptRegistration, 'register', { flows: ['register'] })}
                isLoading={loggingIn}
                disabled={!isReadyForPasswordAuth || loggingIn}
                variant="solid"
                color="#FFFFFF"
                colorScheme="verdiButtonPrimary"
              >
                Register
              </Button>

              <Box position='relative' padding='4'>
                <Divider />
                <AbsoluteCenter bg={dialogBgColor} px='4'>
                  <span css={css`opacity: 0.5;`}>
                    OR
                  </span>
                </AbsoluteCenter>
              </Box>

              <LoginWithGoogle
                setError={setError}
                newOrgName={orgName}
                orgInviteToken={registerCreds.orgInviteToken}
                flows={flows}
              />

            </Stack>
          </form>
        </Box>
      </Stack>
      <Box>
        Already have an account? <Button variant="link" onClick={posthogCallback(handleLoginPress, 'toLogin', { flows: ['register'] })} colorScheme="verdiButtonPrimary">Login</Button>
      </Box>
    </Flex>
  );
};

export default Register;
