import { makeGetRequestJson } from "../../utility-hooks/fetchUtils"
import { ApiTypes } from "@verdi/shared-constants"
import { DocBodySnapshotsState } from "../docBodySnapshotsSlice"
import { dispatch, getCurrentAppState } from "../store"


/** Gets a document's body. Hits the API if it does not have it already in local state. */
export const getDocBodySnapshotFromCache = async (docId: string) => {

  const existingSnapshot = DocBodySnapshotsState.getByDocId(getCurrentAppState(), docId)
  if (existingSnapshot) {
    return existingSnapshot
  }

  const fromApi = await makeGetRequestJson(`documents/${docId}/LatestSnapshot`)
  const response = fromApi as ApiTypes.DocumentSnapshotResponseBody
  dispatch(DocBodySnapshotsState.addOrUpdate(response))

  return response
}
