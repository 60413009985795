import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"


const cmdType = GlobalCommandType.showAddDocModal

export const showAddDocModalCommandDefinition: GlobalCommandDefinition<ShowAddDocModalCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowAddDocModalCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Add new note ...',
        description: '',
        searchName: 'add new document note',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: ShowAddDocModalCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    services.addNewDocGlobalProvider.openAddNewDocModal(args, "Add Note")
    onProcessingComplete(true)
  },
  triggerCommand: (args: ShowAddDocModalCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

type ShowAddDocModalCommandArgs = {
  parentDocId: string
}
