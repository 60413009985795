import { useCallback, useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import { useAppServices } from '../../components/appServices/useAppServices'
import { OnboardingPageHeader } from './OnboardingPageHeader'
import { OnboardingProgress } from './OnboardingProgress'
import { OnboardingStepCompanyInfo } from './steps/OnboardingStepCompanyInfo'
import { OnboardingStepIds } from './OnboardingStep'
import { OnboardingStepOpportunity } from './steps/OnboardingStepOpportunity'
import { OnboardingStepUserInfo } from './steps/OnboardingStepUserInfo'
import { OnboardingStepWelcome } from './steps/OnboardingStepWelcome'
import { useAddNewOpportunity } from '../documents/add/globalModal/useAddNewOpportunity'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useKeyDownHandler } from '../../components/floatingBlockContext/useKeyDownHandler'
import { navigateToHome } from '../../components/commands/commandDefinitions/navigateTo'
import { getUrlForDocument } from '../../routes'


export function OnboardingPage() {

  const sharedServicesProvider = useAppServices()
  const {
    onboardingManager,
    loggedInUserProvider,
    router,
    toast,
  } = sharedServicesProvider

  const {
    currentStepIndex,
    resetSteps,
  } = onboardingManager

  const {
    setOpportunityDescription,
    opportunityDescription,
    createNewOpportunity,
    isCreatingOpportunity,
  } = useAddNewOpportunity({
    onCreateComplete(opportunityDocId) {
      console.log("onCreateComplete", { opportunityDocId })
      setOpportunityDescription("")
      resetSteps()
      router.router.push(getUrlForDocument(opportunityDocId))
    },
    onError: (errorMessage) => {
      toast.showError(errorMessage)
    },
  })

  const onLastStepComplete = useCallback(async () => {
    console.log("onLastStepComplete()")

    if (Boolean(opportunityDescription)) {
      await createNewOpportunity()
      return
    }

    resetSteps()
    navigateToHome()

  }, [createNewOpportunity, opportunityDescription, resetSteps])


  useEffect(() => {
    onboardingManager.setLocallyThatUserWasShownOnboarding()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useKeyDownHandler((event) => {
    if (event.key === "Escape") {
      navigateToHome()
    }
  })

  const opportunityDescriptionInputRef = useRef<HTMLTextAreaElement>(null)
  const setFocusToOpportunityDescription = useCallback(() => {
    opportunityDescriptionInputRef.current?.focus()
  }, [opportunityDescriptionInputRef])


  return (
    <div>
      <div css={css`
        margin:auto;
        overflow-y: auto;
        height: calc(100vh - 120px);
        @media (max-height: ${mediaSmallHeight}px) {
          height: calc(100vh - 56px);
        }
        @media (min-height: ${mediaTallHeight}px) {
          height: calc(100vh - 250px);
        }
      `}>

        <OnboardingPageHeader
          router={router}
        />

        <div
          css={css`
            max-width: ${maxInnerWidth}px;
            margin: auto;
            margin-bottom: 60px;
            padding: 20px;
            @media (min-height: ${mediaSmallHeight}px) {
              margin-top: 100px;
            }
          `}>

          {isCreatingOpportunity &&
            <div css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              height: calc(100vh - 300px);
            `}>
              <LoadingSpinner
                label="Creating your opportunity"
              />
            </div>
          }

          {!isCreatingOpportunity &&
            <>
              {currentStepIndex === OnboardingStepIds.Welcome &&
                <OnboardingStepWelcome />
              }

              {currentStepIndex === OnboardingStepIds.userInfo &&
                <OnboardingStepUserInfo
                  loggedInUserProvider={loggedInUserProvider}
                  onboardingManager={onboardingManager}
                />
              }

              {currentStepIndex === OnboardingStepIds.CompanyInfo &&
                <OnboardingStepCompanyInfo
                  onboardingManager={onboardingManager}
                />
              }

              {currentStepIndex === OnboardingStepIds.OpportunityInfo &&
                <OnboardingStepOpportunity
                  opportunityDescription={opportunityDescription}
                  setOpportunityDescription={setOpportunityDescription}
                  opportunityDescriptionInputRef={opportunityDescriptionInputRef}
                />
              }
            </>
          }
        </div>
      </div>

      <OnboardingProgress
        onboardingManager={onboardingManager}
        maxInnerWidth={maxInnerWidth}
        mediaSmallHeight={mediaSmallHeight}
        mediaTallHeight={mediaTallHeight}
        onLastStepComplete={onLastStepComplete}
        lastStepIsValid={Boolean(opportunityDescription)}
        isLoading={isCreatingOpportunity}
        hasOpportunityDescription={Boolean(opportunityDescription)}
        setFocusToOpportunityDescription={setFocusToOpportunityDescription}
      />

    </div>
  )
}


const mediaSmallHeight = 800
const mediaTallHeight = 1000
const maxInnerWidth = 800
