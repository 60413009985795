import { addSectionWithAiCommandDefinition } from "../../components/commands/commandDefinitions/documentBody/addSectionWithAi"
import { SuggestionForDocument } from "../../screens/document/tiptapEditor/commandMenu/commands/whatsNextSuggestions/useWhatsNextSuggester"
import { getCurrentAppState } from "../../state/store"
import { SuggestionsForDocState } from "../../state/suggestions/suggestionsForDocSlice"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { AllContextForAi } from "../promptUtils/useAllContextForAi"
import { makeNextSectionTitleAiCall } from "./makeNextSectionTitleAiCall"


export const getNextSectionTitleCommands = async (
  contextForAi: AllContextForAi,
  onCommandCompleted: (id: string, sectionTitleToRemove: string) => void,
  isForOpportunity: boolean,
) => {

  const maxSectionsInADoc = 10 // TODO: Pull from the framework ???
  const existingSectionTitles = contextForAi.currentDoc?.sectionTitles || []

  if (existingSectionTitles.length >= maxSectionsInADoc) {
    return []
  }

  const currentlySuggestedTitles = SuggestionsForDocState.getFutureSectionTitles(getCurrentAppState())
  const titlesToNotInclude = [...existingSectionTitles, ...currentlySuggestedTitles]

  let addSectionCommands: SuggestionForDocument[] = []

  if (isForOpportunity
    && !hasAnOutcome(contextForAi.currentDoc)
    && !titlesToNotInclude.some(c => c.toLowerCase().includes("outcome"))
  ) {
    const id = "add-outcome-mechanically-added"
    const sectionTitle = "Outcome"
    const command = addSectionWithAiCommandDefinition.buildMenuItem({
      id,
      sectionTitle,
      onCompleted: async () => {
        onCommandCompleted(id, sectionTitle)
      },
      isSuggestionFromAi: true,
    })
    const addOutcomeSectionCommand = {
      command,
      sectionTitle,
    } as SuggestionForDocument

    addSectionCommands = [addOutcomeSectionCommand, ...addSectionCommands]
  }


  const amountToReturn = maxSectionsInADoc - existingSectionTitles.length

  const rawLines = await makeNextSectionTitleAiCall(
    contextForAi,
    titlesToNotInclude,
    isForOpportunity,
    amountToReturn,
  )


  const commands: SuggestionForDocument[] = rawLines.map(line => {

    const sectionTitle = line
    const id = `${sectionTitle.toLowerCase()}-section-for-opp`

    const command = addSectionWithAiCommandDefinition.buildMenuItem({
      id,
      sectionTitle,
      onCompleted: async () => {
        onCommandCompleted(id, sectionTitle)
      },
      isSuggestionFromAi: true,
    })

    return {
      command,
      sectionTitle: sectionTitle,
    } as SuggestionForDocument

  })

  return commands
}


const hasAnOutcome = (docContext?: DocumentContextForAi) => {
  // TODO: Should we check just in the section headings?
  // What about similar words? like: goals, objectives, target, desired results, etc?
  // potential use case for client size AI via Compromise.js
  return docContext?.body.toLowerCase().includes("outcome")
}
