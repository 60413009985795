import {
  ContentNodeWithPos,
  findParentNodeClosestToPos,
  findChildren,
} from "prosemirror-utils";
import {
  ResolvedPos,
  Node,
  NodeType,
  DOMSerializer,
  Fragment,
  Slice,
} from "prosemirror-model";
import { EditorState, NodeSelection } from "prosemirror-state";
import { DocumentSchema, ProsemirrorNodes } from "@verdi/shared-constants";

import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import some from 'lodash/some';
import last from 'lodash/last';

const TAB_CHAR = "  ";

export default class VeNode {
  static NESTABLE_TYPES: ProsemirrorNodes.NestableBlockType[] = [
    "question",
    "section",
    "freeText",
    DocumentSchema.NodeExtensions.VerdiNodeTypes.taskCheckbox,
  ];

  _node: ContentNodeWithPos;

  constructor(node: ContentNodeWithPos) {
    this._node = node;
  }

  static fromPosition(state: EditorState, pos: number): VeNode | undefined {
    const resolvedPosition = state.doc.resolve(pos);

    return VeNode.fromResolvedPosition(resolvedPosition);
  }

  static fromResolvedPosition(pos: ResolvedPos): VeNode | undefined {
    const contentNode = findParentNodeClosestToPos(pos, () => true);

    if (!contentNode) {
      return undefined;
    }

    return new VeNode(contentNode);
  }

  node(): Node {
    return this._node.node;
  }

  pos(): number {
    return this._node.pos;
  }

  type(): NodeType {
    return this._node.node.type;
  }

  textContent(): string {
    return this._node.node.textContent;
  }

  childCount(): number {
    return this._node.node.childCount;
  }

  size(): number {
    return this._node.node.nodeSize;
  }

  content(): Fragment {
    return this._node.node.content
  }

  bounds() {
    const { pos, node } = this._node;


    return { start: pos, end: pos + node.nodeSize };
  }

  title(state: EditorState): VeNode {
    const [title] = this.findChildrenWithType(state, ["title"]);

    if (!title) {
      throw new Error("for some reason this node does not have a title");
    }

    return title;
  }

  contentList(state: EditorState): VeNode | undefined {
    const [contentList] = this.findChildrenWithType(state, ["contentList"]);

    return contentList;
  }

  index(state: EditorState) {
    return this.resolvePosition(state).index();
  }

  selection(state: EditorState): NodeSelection {
    return NodeSelection.create(state.doc, this.pos());
  }

  resolvePosition(state: EditorState): ResolvedPos {
    return state.doc.resolve(this._node.pos);
  }

  findParent(state: EditorState): VeNode | undefined {
    const parent = findParentNodeClosestToPos(
      this.resolvePosition(state),
      () => true
    );

    if (!parent) {
      return undefined;
    }

    return new VeNode(parent);
  }

  findParentWithType(state: EditorState, types: String[]): VeNode | undefined {
    const parent = findParentNodeClosestToPos(
      this.resolvePosition(state),
      (node: Node) => {
        return types.includes(node.type.name);
      }
    );

    if (!parent) {
      return undefined;
    }

    return new VeNode(parent);
  }

  findNestableParent(state: EditorState): VeNode | undefined {
    return this.findParentWithType(state, VeNode.NESTABLE_TYPES);
  }

  findChildren(state: EditorState): VeNode[] {
    const children = findChildren(this._node.node, () => true, false);

    return children.reduce((prev: VeNode[], child) => {
      const node = VeNode.fromPosition(state, this.pos() + child.pos + 2);

      if (node) {
        return [...prev, node];
      }

      return prev;
    }, []);
  }

  findChildrenWithType(state: EditorState, types: String[]): VeNode[] {
    const children = findChildren(
      this._node.node,
      (node) => types.includes(node.type.name),
      false
    );

    return children.reduce((prev: VeNode[], child) => {
      const node = VeNode.fromPosition(state, this.pos() + child.pos + 2);

      if (node) {
        return [...prev, node];
      }

      return prev;
    }, []);
  }

  findNestableChildren(state: EditorState): VeNode[] {
    let node = this as VeNode;

    if (node.type().name !== 'contentList') {
      ([node] = node.findChildrenWithType(state, ['contentList']));
    }

    if (!node) {
      return [];
    }

    return node.findChildrenWithType(state, VeNode.NESTABLE_TYPES);
  }

  findPreviousNestableSibling(state: EditorState) {
    const endOfPreviousSibling = this.bounds().start - 1;

    if (endOfPreviousSibling < 0) {
      return undefined;
    }

    const previousNode = VeNode.fromPosition(state, endOfPreviousSibling);

    if (!previousNode || this.isNestableChildOf(state, previousNode)) {
      return undefined;
    }

    return previousNode;
  }

  findNextSibling(state: EditorState) {
    const parent = this.findParent(state);

    if (parent && parent.childCount() < 2) {
      return undefined;
    }

    return VeNode.fromPosition(state, this.bounds().end + 1);
  }

  findNextNestableSibling(state: EditorState) {
    const startOfNextSibling = this.bounds().end + 1;

    const { doc } = state;

    if (startOfNextSibling > doc.content.size) {
      return undefined;
    }

    const nextNode = VeNode.fromPosition(state, startOfNextSibling);

    if (!nextNode || this.isNestableChildOf(state, nextNode)) {
      return undefined;
    }

    return nextNode;
  }

  findAncestors(state: EditorState): VeNode[] {
    const parent = this.findParent(state);

    if (!parent) {
      return [];
    }

    return [parent, ...parent.findAncestors(state)];
  }

  findNestableAncestors(state: EditorState): VeNode[] {
    const parent = this.findNestableParent(state);

    if (!parent) {
      return [];
    }

    return [parent, ...parent.findNestableAncestors(state)];
  }

  findCommonAncestor(state: EditorState, node: VeNode): VeNode | undefined {
    const thisAncestors = [...this.findAncestors(state), this];
    const thatAncestors = [...node.findAncestors(state), node];

    for (
      let thatPointer = 0;
      thatPointer < thatAncestors.length;
      thatPointer += 1
    ) {
      for (
        let thisPointer = 0;
        thisPointer < thisAncestors.length;
        thisPointer += 1
      ) {
        const { [thisPointer]: thisAncestor } = thisAncestors;
        const { [thatPointer]: thatAncestor } = thatAncestors;

        if (isEqual(thisAncestor.bounds(), thatAncestor.bounds())) {
          return thatAncestor;
        }
      }
    }

    return undefined;
  }

  findPreviousNestableNode(state: EditorState): VeNode | undefined {
    const sibling = this.findPreviousNestableSibling(state);

    if (sibling) {
      return sibling;
    }

    const parent = this.findNestableParent(state);

    if (parent) {
      return parent;
    }
  }

  findNestableNodeInPreviousPosition(state: EditorState): VeNode | undefined {
    const previousNestable = this.findPreviousNestableNode(state);

    if (!previousNestable) {
      return;
    }

    let node = previousNestable;

    while (true) {
      const children = node.findNestableChildren(state);

      if (children.length < 1) {
        if (node.isEqualTo(this)) {
          return previousNestable;
        }

        return node;
      }

      node = last(children) as VeNode;
    }
  }

  isEqualTo(node: VeNode | undefined): boolean {
    if (isUndefined(node)) {
      return false;
    }

    return isEqual(this.bounds(), node.bounds());
  }

  isAncestorOf(node: VeNode): boolean {
    const thisBounds = this.bounds();
    const thatBounds = node.bounds();

    return (
      thisBounds.start < thatBounds.start && thisBounds.end > thatBounds.end
    );
  }

  isDescendantOf(node: VeNode): boolean {
    const thisBounds = this.bounds();
    const thatBounds = node.bounds();

    return (
      thatBounds.start < thisBounds.start && thatBounds.end > thisBounds.end
    );
  }

  isParentOf(state: EditorState, node: VeNode): boolean {
    const parent = node.findParent(state);

    return !!parent && parent.isEqualTo(this);
  }

  isNestableParentOf(state: EditorState, node: VeNode): boolean {
    const parent = node.findNestableParent(state);

    return !!parent && parent.isEqualTo(this);
  }

  isChildOf(state: EditorState, node: VeNode): boolean {
    const parent = this.findParent(state);

    return !!parent && parent.isEqualTo(node);
  }

  isNestableChildOf(state: EditorState, node: VeNode): boolean {
    const parent = this.findNestableParent(state);

    return !!parent && parent.isEqualTo(node);
  }

  isSiblingOf(state: EditorState, node: VeNode): boolean {
    const thisParent = this.findParent(state);
    const thatParent = node.findParent(state);

    if (isUndefined(thisParent) && isUndefined(thatParent)) {
      return true;
    }

    if (some([thisParent, thatParent], isUndefined)) {
      return false;
    }

    return (thisParent as VeNode).isEqualTo(thatParent);
  }

  isNestableSiblingOf(state: EditorState, node: VeNode): boolean {
    const thisParent = this.findNestableParent(state);
    const thatParent = node.findNestableParent(state);

    if (isUndefined(thisParent) || isUndefined(thatParent)) {
      return false;
    }

    return thisParent.isEqualTo(thatParent);
  }

  toHtml(state: EditorState) {
    return DOMSerializer.fromSchema(state.schema).serializeNode(
      this._node.node
    );
  }

  private _toString(node: Node, level: number = 0): string {
    const { name } = node.type;

    const tabSpace = Array(level).fill(TAB_CHAR).join("");

    const children: Node[] = [];

    if (!node.isLeaf) {
      node.content.forEach((node: Node) => {
        children.push(node);
      });
    }

    return [
      `${tabSpace}<${name}>`,
      ...((node.isLeaf
        ? [`${tabSpace}${TAB_CHAR}${node.textContent}`]
        : [
          children
            .map((child) => this._toString(child, level + 1))
            .join("\n"),
        ]) as string[]),
      `${tabSpace}</${name}>`,
    ].join("\n");
  }

  toString(): string {
    return this._toString(this._node.node);
  }

  slice(openLeft: number, openRight: number): Slice {
    const fragment = Fragment.from(this._node.node);

    return new Slice(fragment, openLeft, openRight);
  }
}
