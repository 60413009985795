import { Resolver } from 'found-relay';
import { RelayEnvironmentProvider } from 'react-relay';
import './App.css';
import { environment } from './auth/relayEnvironment';
import { ChakraProvider } from '@chakra-ui/react'
import { Router } from './routes';
import { verdiChakraTheme } from './chakraTheme';
import { SuspenseErrorBoundary } from './components/SuspenseErrorBoundary';
import { AppLoadingScreen } from './screens/initialLoading/AppLoadingScreen';
import { Provider } from 'react-redux'
import { store } from './state/store';
import { LightDarkModeClassNameProvider } from './utility-hooks/LightDarkModeClassNameProvider';


const resolver = new Resolver(environment)

function App() {


  return (
    <ChakraProvider theme={verdiChakraTheme}>
      <SuspenseErrorBoundary fallback={<AppLoadingScreen />}>
        <LightDarkModeClassNameProvider>
          <Provider store={store}>
            <RelayEnvironmentProvider environment={environment}>
              { /*  @ts-ignore */}
              <Router resolver={resolver} />
            </RelayEnvironmentProvider>
          </Provider>
        </LightDarkModeClassNameProvider>
      </SuspenseErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
