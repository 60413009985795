/**
 * @generated SignedSource<<758da19f26b0546e9c87464673d52cc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAddNewDocumentFields$data = {
  readonly description: string | null;
  readonly id: string;
  readonly opportunities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"OpportunityDocumentSideMenu_opportunity">;
  }>;
  readonly parentDoc: {
    readonly id: string;
  } | null;
  readonly templateInfo: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DocumentTemplateSideMenuItem_documentTemplate">;
  } | null;
  readonly title: string | null;
  readonly type: DocumentType;
};
export type useAddNewDocumentFields$key = {
  readonly " $data"?: useAddNewDocumentFields$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAddNewDocumentFields">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "updateDocumentTemplateConnectionIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "useAddNewDocumentFields",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "parentDoc",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Opportunity",
      "kind": "LinkedField",
      "name": "opportunities",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OpportunityDocumentSideMenu_opportunity"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplateInfo",
      "kind": "LinkedField",
      "name": "templateInfo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DocumentTemplateSideMenuItem_documentTemplate"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};
})();

(node as any).hash = "9134095c328f67c1e2bbb85710a7a0eb";

export default node;
