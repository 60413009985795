import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { makeAiChatRequest } from "../requests/AiApiRequests"
import { FrameworksState } from "../../state/frameworksSlice"
import { getCurrentAppState } from "../../state/store"
import { Data } from "@verdi/shared-constants"
import { getRelatedDocTitlesForPrompt } from "../promptUtils/getRelatedDocTitlesForPrompt"
import { AllContextForAi } from "../promptUtils/useAllContextForAi"


export const makeSuggestChildDocsAiCall = async (
  contextForAi: AllContextForAi,
) => {

  const { currentDoc } = contextForAi
  if (!currentDoc) {
    console.warn("makeSuggestChildDocsAiCall: no doc context")
    return []
  }

  const frameworks = FrameworksState.getAllFrameworksByKind(getCurrentAppState(), false)
  const frameworksToChooseFrom: FrameworkOption[] = []
  // const docRelations = DocRelationsState.getAll(getCurrentAppState())
  //   .filter(r => r.fromDocId === currentDoc.id)
  const childDocTitles = currentDoc.childDocs.map(c => c.title)
  const relatedDocTitles = currentDoc?.relatedDocs ? currentDoc?.relatedDocs.map(r => r.fromDoc.title) : []
  const existingDocTitles = [...childDocTitles, ...relatedDocTitles]
  frameworks.forEach((f, index) => {
    if (existingDocTitles.includes(f.title)) return // Skip any that are already a child
    frameworksToChooseFrom.push({
      itemNumber: `${index + 1}`,
      framework: f,
    })
  })


  const prompt = getPrompt(currentDoc, frameworksToChooseFrom)

  try {
    const response = await makeAiChatRequest({
      prompt,
      messages: [],
    })

    const rawText = response?.aiResponseJson.content as string || ""
    const lines = rawText.split("\n")
    const justNumbers = lines.map(l => l.split(".")[0].trim())
    const toReturn: Data.FrameworkModel[] = []
    for (const num of justNumbers) {
      const match = frameworksToChooseFrom.find(f => f.itemNumber === num)

      // The AI will sometimes still suggest titles of existing child docs, so we need to filter those out.
      if (match && !existingDocTitles.includes(match.framework.title)) {
        toReturn.push(match.framework)
      }
    }

    return toReturn

  } catch (error) {
    console.error("makeSuggestChildDocsAiCall: error = ", error)
    return []
  }

}

type FrameworkOption = {
  itemNumber: string // To reduce tokens sent to the AI, just number them 1,2,3,etc.
  framework: Data.FrameworkModel
}

export const getPrompt = (
  docContext: DocumentContextForAi,
  frameworksToChooseFrom: FrameworkOption[],
  suggestionsCount: number = 5,
) => {

  const enchiridionList = frameworksToChooseFrom.map(f => `${f.itemNumber}. ${f.framework.title}`).join("\n")
  const relatedDocTitles = getRelatedDocTitlesForPrompt(docContext.relatedDocs)

  const prompt = `Given the following context:

### START CONTEXT
The current document is titled: ${docContext.title}

${relatedDocTitles
      ? "Context: Titles of documents currently related to the current document: " + relatedDocTitles
      : ""}

Context: "List of templates" you can suggest creating: this is the list of frameworks from the Enchiridion
${enchiridionList}

### END CONTEXT
  
INSTRUCTIONS:
Return the top ${suggestionsCount} suggestions from the "List of templates" that are most likely to be relevant to the user's current context.
In the response do not include any preamble, additional text or anything else other than the list of suggestions.
In the response only include the suggestions themselves, one per line, in the same format as the "List of templates" above.
Order the suggestions by most relevant to least relevant.

Example 1:
If the suggestions are:
44. SWOT Analysis
56. Jobs to be Done
12. Campaign Brief
19. Customer Journey Map
104. User Interview - Usability Testing
Then the response should be:
44
56
12
19
104

EXAMPLE 2:
If the suggestions are:
207. Brand Strategy
47. Product Launch Plan
63. Sales Enablement Plan
3. Customer Support and Service Strategy
100. Digital Marketing Strategy - Email
Then the response should be:
207
47
63
3
100
  `
  return prompt
}

