import { ExpandRecursively } from "../bonusGenerics/expand";
import { AttributionMark } from "../documentSchema/marks/attribution";
import { type as KnowledgeGraphMarkType } from "../documentSchema/marks/KnowledgeGraph.mark";
import { PlaceholderInlineMarkType as PlaceholderInlineMark } from "../documentSchema/marks/PlaceholderInline.mark";
import { type as AiSuggestionMark } from "../documentSchema/marks/AiSuggestion.mark";
import { LinePart, splitLineByPlaceholders } from "./splitLineByPlaceholders";
import { DocReferenceInlineNodeAttributeValues, PlaceholderInlineNodeAttributes, PlaceholderInlineNodeUtils, VerdiNodeTypes } from "../documentSchema/nodeExtensions";
import { DocumentSchema } from "..";


export { insertBlockOfType } from "./insertBlockOfType";
export { splitLineByPlaceholders } from "./splitLineByPlaceholders";

export type Attributes = {
  id?: string;
  originalBlockId?: string;
  sourceBlockId?: string;
  transcriptTimestamp?: string;
};

export type Mark = AttributionMark | KnowledgeGraphMarkType | AiSuggestionMark | PlaceholderInlineMark;

export type Paragraph = {
  type: "paragraph";
  content?:
  | [
    {
      type: "text";
      text: string;
    }
  ]
  | [];
} & ExpandRecursively<Attributes>;

export type Title = {
  type: "title";
  content?: TitleContent[];
} & ExpandRecursively<Attributes>;

export type TitleContent = {
  type: "text" | "placeholderInline" | "docReferenceInline";
  text: string;
  attrs?: PlaceholderInlineNodeAttributes;
  marks?: Mark[];
}

export type ContentList = {
  type: "contentList";
  content: NestableBlock[];
};

export type NestableBlockContent = [Title] | [Title, ContentList];

export type Section = {
  type: "section";
  attrs?: Attributes;
  content: NestableBlockContent;
} & ExpandRecursively<Attributes>;

export type Question = {
  type: "question";
  attrs?: Attributes;
  content: NestableBlockContent;
} & ExpandRecursively<Attributes>;

export type FreeText = {
  type: "freeText";
  attrs?: Attributes;
  content: NestableBlockContent;
} & ExpandRecursively<Attributes>;

export type TaskCheckbox = {
  type: VerdiNodeTypes.taskCheckbox;
  attrs?: Attributes;
  content: NestableBlockContent;
} & ExpandRecursively<Attributes>;

export type NestableBlock = FreeText | Section | Question | TaskCheckbox;
export type NestableBlockType = NestableBlock["type"];
export type Block = NestableBlock | ContentList;
export type BlockType = Block["type"];
export type TipTapDocument = {
  content: NestableBlock[] | undefined;
};

export const isNestableType = (type: Block["type"] | "doc") => {
  switch (type) {
    case "question":
    case "section":
    case "freeText":
      return true;
    // case 'paragraph':
    case "doc":
    case "contentList":
      return false;
    default:
      throw new Error(`Type ${type} not degined as nestable or not yet`);
  }
};

export const makeParagraphBlock = (text: string): Paragraph => {
  return {
    type: "paragraph",
    content: !text?.length
      ? []
      : [
        {
          type: "text",
          text: text,
        },
      ],
  };
};
export const makeTitleBlock = (text: string, marks: Mark[] = []): Title => {
  return {
    type: "title",
    content: !text?.length
      ? []
      : [
        {
          type: "text",
          text: text,
          marks: marks,
        },
      ],
  };
};

/** Adds a title block and includes any placeholder marks when content contains placeholders (a.k.a `[[` + `]]`) */
const makeTitleBlockFromLineParts = (lineParts: LinePart[]): Title => {


  const placeholderIsAtTheEnd = lineParts[lineParts.length - 1]?.isPlaceholder
  // If placeholder is last then add a blank space afterwards, 
  // to ensure you can press enter at end of line to create a new line, or continue current line.
  // A likely better solution would be handled within the Placeholder itself.
  const allLineParts = placeholderIsAtTheEnd ? [...lineParts, { text: " ", isPlaceholder: false }] : lineParts

  return {
    type: "title",
    content: getTitleContentFromLineParts(allLineParts),
  };
};


const getTitleContentFromLineParts = (allLineParts: LinePart[]): TitleContent[] => {
  return allLineParts.map((linePart) => {

    // const marks = linePart.isPlaceholder ? [VerdiMarks.createNewPlaceholderInlineMark({
    //   commands: [],
    //   placeholderText: "",
    //   suggestionText: "",
    // })] : undefined

    if (linePart.isPlaceholder) {
      return {
        type: "placeholderInline",
        text: "",
        attrs: PlaceholderInlineNodeUtils.parsePlaceholderAttributesFromMarkup(linePart.text.trim()),
        // marks,
      }
    }

    return {
      type: "text",
      text: linePart.text,
    }
  })
}

/** Create title block content, which may include freeText and placeholder nodes.
 *  
 *  This does NOT handle adding them into a ContentList */
export const makeTitleBlockContent = (rawText: string): TitleContent[] => {
  const lineParts = splitLineByPlaceholders(rawText)
  return getTitleContentFromLineParts(lineParts)
}

export const makeContentList = (children: NestableBlock[]): ContentList => ({
  type: "contentList",
  content: children,
});
export const makeContentListWithAtLeastOneChild = (
  children: NestableBlock[]
): ContentList => ({
  type: "contentList",
  content: children,
});


/** Streamlined function to create Nodes that automatically adds placeholder marks where needed */
export const makeBlockOfType = (
  type: "section" | "freeText" | DocumentSchema.VerdiNodeTypes.taskCheckbox,
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
): Section | FreeText | TaskCheckbox => {

  const lineParts = splitLineByPlaceholders(text)

  const contentList = children && children?.length > 0 ? makeContentList(children) : undefined;

  const content: NestableBlockContent = contentList?.content.length
    ? [makeTitleBlockFromLineParts(lineParts), contentList]
    : [makeTitleBlockFromLineParts(lineParts)];

  // console.log("MB ", { type, text, children, lineParts, contentList, content })

  return {
    type,
    attrs: attributes,
    content,
  };
};

export const makeFreeTextBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
  marks: Mark[] = []
): FreeText => {
  const contentList = children?.length ? makeContentList(children) : undefined;
  const titleBlock = makeTitleBlock(text, marks);
  const content: NestableBlockContent = contentList
    ? [titleBlock, contentList]
    : [titleBlock];
  return {
    type: "freeText",
    attrs: attributes,
    content,
  };
};

export const makeSectionBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
  marks: Mark[] = []
): Section => {
  const contentList = children?.length ? makeContentList(children) : undefined;
  const content: NestableBlockContent = contentList
    ? [makeTitleBlock(text, marks), contentList]
    : [makeTitleBlock(text, marks)];
  return {
    type: "section",
    attrs: attributes,
    content,
  };
};

export const makeQuestionBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: Attributes,
  marks: Mark[] = []
): Question => {
  const contentList = children?.length ? makeContentList(children) : undefined;
  const content: NestableBlockContent = contentList
    ? [makeTitleBlock(text, marks), contentList]
    : [makeTitleBlock(text, marks)];
  return {
    type: "question",
    attrs: attributes,
    content,
  };
};

export const makeTaskCheckboxBlock = (
  text: string,
  children?: NestableBlock[],
  attributes?: any,
  marks: Mark[] = []
): TaskCheckbox => {

  return makeBlockOfType(DocumentSchema.VerdiNodeTypes.taskCheckbox, text, children, attributes) as TaskCheckbox
};

export const makeInlineSnippet = (
  text: string,
  attrs: { snippetId: string; placeholderKey: string }
) => {
  return {
    type: "inlineSnippet",
    content: [
      {
        type: "text",
        text: text,
      },
    ],
    attrs: { ...attrs, textValue: text },
  };
};

export const makeSnippetSection = (
  text: string,
  attrs: { snippetId: string; placeholderKey: string },
  marks: Mark[] = [],
  children?: NestableBlock[]
) => {
  const contentList = children?.length ? makeContentList(children) : undefined;
  const content: NestableBlockContent = contentList
    ? [makeTitleBlock(text, marks), contentList]
    : [makeTitleBlock(text, marks)];

  return {
    type: "snippetSection",
    content,
    attrs: { ...attrs, textValue: text },
  };
};

export const makeDocReferenceInlineSection = (
  attrs: DocReferenceInlineNodeAttributeValues,
) => {

  return {
    type: VerdiNodeTypes.docReferenceInline,
    attrs: { ...attrs },
  };
};

export const makePlaceholderInlineSection = (
  text: string,
  attrs: PlaceholderInlineNodeAttributes,
  marks: Mark[] = [],
  children?: NestableBlock[]
) => {

  const content = [
    {
      type: "text",
      text: text,
      marks: marks,
    },
  ]
  // const contentList = children?.length ? makeContentList(children) : undefined;
  // const content: NestableBlockContent = contentList
  //   ? [makeTitleBlock(text, marks), contentList]
  //   : [makeTitleBlock(text, marks)];

  return {
    type: VerdiNodeTypes.PlaceholderInline,
    // content,
    attrs: { ...attrs, textValue: text },
  };
};
