import { Node } from "@tiptap/pm/model";
import { DocumentSchema } from "@verdi/shared-constants";
import { DocumentTaskModel } from "../../../../state/documentTasksSlice";


/** Returns an abstracted view of all special nodes in the given document, such as section titles. */
export const getDocSummaryOfNodes = (rootDocNote: Node) => {

  const toReturn: DocSummaryOfNodes = {
    sectionTitles: [],
    tasks: [],
    placeholders: [],
  }

  rootDocNote.descendants((node) => {
    switch (node.type.name) {

      case "section": {
        const title = getTitleOfBlock(node)
        if (title) {
          toReturn.sectionTitles.push(title)
        }
        break;
      }

      case DocumentSchema.VerdiNodeTypes.taskCheckbox: {
        const title = getTitleOfBlock(node)
        const idInDoc = node.attrs.id
        if (title) {
          toReturn.tasks.push({
            titlePlainText: title,
            idInDoc,
          })
        }
        break;
      }

      case DocumentSchema.VerdiNodeTypes.PlaceholderInline: {
        const placeholder = DocumentSchema.NodeExtensions.PlaceholderInlineNodeUtils.parseAsSummary(node)
        if (placeholder) {
          toReturn.placeholders.push(placeholder)
        }
        break;
      }
    }

    return true // .descendants() - Returning false will limit it only to the root level sections
  })



  return toReturn
}

/** Gets details about the closest parent that is of node type "section", if it exists */
export const getSectionTitles = (rootDocNote: Node) => {

  const toReturn: string[] = []
  const sectionNodes: Node[] = []

  let count = 0
  rootDocNote.descendants((node) => {
    const isASection = node.type.name === "section"
    if (isASection) {
      sectionNodes.push(node)
      const title = getTitleOfBlock(node)
      if (title) toReturn.push(title)
    }

    count++
    return true // Returning false will limit it only to the root level sections
  })

  // console.info("getSectionTitles: count", { count, sectionNodes, toReturn })

  return toReturn
}

// export const getTaskCheckboxes = (rootDocNote: Node) => {

//   const toReturn: Partial<DocumentTaskModel>[] = []
//   const taskNodes: Node[] = []

//   rootDocNote.descendants((node) => {
//     const isATask = node.type.name === DocumentSchema.VerdiNodeTypes.taskCheckbox
//     if (isATask) {
//       taskNodes.push(node)
//       const title = getTitleOfBlock(node)
//       const idInDoc = node.attrs.id
//       if (title) {
//         toReturn.push({
//           titlePlainText: title,
//           idInDoc,
//         })
//       }
//     }

//     return true // Returning false will limit it only to the root level sections
//   })

//   return toReturn
// }


export const getTitleOfBlock = (sectionNode: Node) => {
  const titleNode = sectionNode.content.childCount > 0 ? sectionNode.content.firstChild : undefined
  const title = titleNode ? titleNode.textContent : ""
  return title
}


/** Abstracted view of all special nodes in a document, without the prose-mirror markup */
export type DocSummaryOfNodes = {
  placeholders: DocumentSchema.NodeExtensions.PlaceholderSummary[]
  sectionTitles: string[]
  tasks: Partial<DocumentTaskModel>[]
}
