import { useCallback, useEffect, useState } from "react"
import { css } from "@emotion/react"
import { Alert, AlertIcon, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { dispatch } from "../../../../state/store"
import { ButtonPrimary } from "../../../../components/buttons/ButtonPrimary"
import { useAppServices } from "../../../../components/appServices/useAppServices"
import { useAppSelector } from "../../../../state/storeHooks"
import { AiContextConfigState } from "../../../../state/aiContextConfigSlice"
import { AllContextForAi } from "../../../../ai/promptUtils/useAllContextForAi"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import { useColor } from "../../../../utility-hooks/useColor"
import { getUrlFromCompany } from "../../../../routes"


/** Shows options for adding an opportunity */
export const AiContextGlobalModal = () => {

  const isOpen = useAppSelector(AiContextConfigState.getIsShowingModal)
  const isLoading = useAppSelector(AiContextConfigState.getIsLoading)
  const {
    getContextForAi,
  } = useAppServices()

  const onCloseClick = useCallback(() => {
    dispatch(AiContextConfigState.setIsShowingModal(false))
  }, [])

  const [allContext, setAllContext] = useState<AllContextForAi | undefined>()

  const loadAllContext = useCallback(async () => {
    if (isLoading) {
      return
    }
    dispatch(AiContextConfigState.setIsLoading(true))
    const context = await getContextForAi()
    setAllContext(context)
    dispatch(AiContextConfigState.setIsLoading(false))

  }, [getContextForAi, isLoading])

  useEffect(() => {
    if (isOpen) {
      loadAllContext()
    }

  }, [isOpen])

  const bgColor = useColor("surfaceBg")
  const hasEnoughCompanyContext = getHasEnoughCompanyContext(allContext?.companyContext)


  if (!isOpen) {
    return null
  }


  return (
    <div>
      <Modal
        onClose={onCloseClick}
        isOpen={true}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>

          <ModalCloseButton
            onClick={onCloseClick}
          />

          <ModalHeader>
            <h2>
              Context
            </h2>
          </ModalHeader>
          <ModalBody>

            <p css={css`
              margin-bottom: 20px;
              opacity: 0.7;
            `}>
              Verdi considers this context when making suggestions and generating.
            </p>

            <div css={css`
              margin: 0;
              max-height: calc(100vh - 300px);
              overflow-y: auto;
            `}>
              {isLoading &&
                <div css={css`
                padding: 40px;
              `}>
                  <LoadingSpinner
                    label="Loading context..."
                    size="lg"
                  />
                </div>
              }
              {!isLoading && !hasEnoughCompanyContext &&
                <div>
                  <Alert
                    status="warning"
                    size="xs"
                    marginBottom={5}
                  >
                    <AlertIcon />
                    Add more
                    <Link
                      padding={1}
                      textDecoration="underline"
                      href={getUrlFromCompany()}>
                      Company Context
                    </Link>
                    to improve results.
                  </Alert>
                </div>
              }
              {!isLoading && allContext?.promptMessages.map((msg, key) =>
                <ContextWrapper key={key}
                  bgColor={bgColor}
                >
                  {msg.content}
                </ContextWrapper>
              )}
            </div>

          </ModalBody>

          <ModalFooter>
            <ButtonPrimary
              label="close"
              onClick={onCloseClick}
            />
          </ModalFooter>

        </ModalContent>
      </Modal>


    </div>
  )
}


type ContextWrapperProps = {
  bgColor: string,
  children: React.ReactNode
}

const ContextWrapper = ({
  bgColor,
  children,
}: ContextWrapperProps) => {

  return (
    <div
      css={css`
      padding: 10px 20px;
      margin: 0 10px 40px 0;
      white-space: pre-wrap;
      background-color: ${bgColor};
      border: 1px solid #99999933;
      border-radius: 10px;
    `}>
      {children}
    </div>
  )
}

export const getHasEnoughCompanyContext = (companyContext: string | undefined) => {
  if (!companyContext) return false
  return companyContext.length > 100
}

