import { GlobalCommandType } from "../../../GlobalCommandType"
import { triggerGlobalCommand } from "../../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../../GlobalCommandDefinition"
import { AppServices } from "../../../../appServices/useRegisterAppServices"
import { dispatch } from "../../../../../state/store"
import { loadingStatusState } from "../../../../../state/loadingStatusSlice"
import { makeInlineDocSuggestionsAiCall } from "../../../../../ai/prompts/docDrafter/InlineDocSuggestionsAiCall"
import { docBodyMarkCreateCommandDefinition } from "./docBodyMarkCreate"
import { docBodyMarkClearAllCommandDefinition } from "./docBodyMarkClearAll"


const cmdType = GlobalCommandType.inlineDocSuggestionsGenerate


export const inlineDocSuggestionsGenerateCommandDefinition: GlobalCommandDefinition<InlineDocSuggestionsGenerateCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: InlineDocSuggestionsGenerateCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: `Show me my gaps`,
        description: '',
        searchName: 'show me my gaps',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: InlineDocSuggestionsGenerateCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Reviewing document ...",
    }))

    // For now, clear all existing suggestions (faster to test as well)
    docBodyMarkClearAllCommandDefinition.triggerCommand?.({})

    const companyContextString = await services.companyContextProvider.getCompanyContextForPrompt()
    const opportunityContext = await services.getOpportunityContextForAi()
    const docContext = services.getDocumentContextForAi()

    const commandArgs = await makeInlineDocSuggestionsAiCall(services.getContextForAi)

    for (const arg of commandArgs) {
      docBodyMarkCreateCommandDefinition.triggerCommand?.(arg)
    }

    console.log("inlineDocSuggestionsGenerate ", { commandArgs, companyContextString, opportunityContext, docContext, })

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
      message: "",
    }))

    services.toast.showSuccess("Finished reviewing the document")

    onProcessingComplete(true)
  },
  triggerCommand: (
    args: InlineDocSuggestionsGenerateCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type InlineDocSuggestionsGenerateCommandArgs = {

}
