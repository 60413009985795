import { ReactNode, useCallback, useEffect } from "react"
import { AutoResizingInputText } from "../../components/forms/AutoResizingInputText"
import { AiSuggestionButtonList } from "../../components/aiComponents/AiSuggestionButtonList"
import { useAppServices } from "../../components/appServices/useAppServices"
import { getSuggestedOpportunitiesAiCall } from "../../ai/prompts/opportunity/getSuggestedOpportunitiesAiCall"
import { useAppSelector } from "../../state/storeHooks"
import { SuggestionsForDocTitleState } from "../../state/suggestions/suggestionsForDocTitleSlice"
import { dispatch } from "../../state/store"
import { useComboBoxController } from "../../components/commands/miniCommandMenu/comboBox/useComboBoxController"
import { css } from "@emotion/react"
import { DefinitionTipForUser } from "../../components/DefinitionTipForUser"
import { PageHeading } from "../../components/pageLayout/PageHeading"
import { VerdiIconOpportunity } from "../../components/icons/VerdiIcons"


type Props = {
  title: string
  onTitleChange: (updatedTitle: string) => void
  isDisabled: boolean
  opportunityDescriptionInputRef?: React.RefObject<HTMLTextAreaElement>
  children?: ReactNode
}
/** Includes AI suggestions */
export const OpportunityTitleInput = ({
  title,
  onTitleChange,
  isDisabled,
  opportunityDescriptionInputRef,
  children,
}: Props) => {

  const { getContextForAi } = useAppServices()

  const suggestedTitles = useAppSelector(SuggestionsForDocTitleState.getDescriptions)
  const suggestedTitlesLoading = useAppSelector(SuggestionsForDocTitleState.getIsLoading)


  useEffect(() => {
    if (suggestedTitles.length === 0) {
      loadSuggestedTitles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadSuggestedTitles = useCallback(async () => {
    dispatch(SuggestionsForDocTitleState.setIsLoading(true))
    const suggestedTitles = await getSuggestedOpportunitiesAiCall(getContextForAi)
    dispatch(SuggestionsForDocTitleState.setDescriptions(suggestedTitles))
    dispatch(SuggestionsForDocTitleState.setIsLoading(false))
  }, [getContextForAi])


  const comboBoxState = useComboBoxController({
    onClose: () => { },
    onRunCommand: (command) => { onTitleChange(command.name) },
    mode: "show-all-no-filter",
  })

  const onChange = useCallback((newValue: string) => {
    onTitleChange(newValue)
    comboBoxState.setSearchText(newValue)
  }, [comboBoxState, onTitleChange])


  return (
    <div css={css`
      width: 100%;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: start;
    `}>

      <PageHeading
        title="Create an Opportunity"
        description="Describe a problem, outcome, or idea you want to explore."
        iconLeft={<VerdiIconOpportunity />}
        titleSize="xl"
      />
      {/* <h2>
        What
        &nbsp;
        <DefinitionTipForUser
          word="Opportunity"
          definition="a problem, solution or idea to be explored at the intersection of customer and business value"
          pronunciation="op-or-chew-ni-tee"
          wordType="noun"
        />
        &nbsp;
        do you want to draft?
      </h2> */}

      <div css={css`
          max-width: 480px;
          min-height: 40px;
          width: 100%;
        `}>

        <AutoResizingInputText
          ref={opportunityDescriptionInputRef}
          isDisabled={isDisabled}
          value={title}
          placeholder="Briefly describe the opportunity..."
          onChange={(evt) => onChange(evt.target.value)}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              evt.preventDefault()
            }
            comboBoxState.handleOnKeyDown(evt as any)
          }}
        />

        {title.length === 0 &&
          <AiSuggestionButtonList
            comboBoxState={comboBoxState}
            isLoading={suggestedTitlesLoading}
            suggestionNames={suggestedTitles}
            selectedSuggestionName={title}
            shouldFloat={false}
          />
        }

        {children}

      </div>

    </div>
  )
}
