import { ResolvedPos } from "@tiptap/pm/model"
import { CurrentBlockContext } from "./getCurrentBlockContext"
import { NodeWithPos, findChildrenByMark } from "prosemirror-utils"
import { EditorState } from "prosemirror-state"
import { Editor } from "@tiptap/core"


/** Returns mark details when the current caret position is on a mark  */
export const findCurrentMarkDetails = (editor: Editor, currentBlockContext: CurrentBlockContext) => {

  const { resolvedPos } = currentBlockContext
  if (!resolvedPos) {
    console.warn("findCurrentMark: No resolvedPos found")
    return undefined
  }

  return findCurrentMarkDetailsFromResolvedPos(editor.state, resolvedPos)
}

export const findCurrentMarkDetailsFromResolvedPos = (state: EditorState, resolvedPos: ResolvedPos | undefined): MarkResolved | undefined => {

  if (!resolvedPos) {
    return undefined
  }

  // should be inside a mark, since we trigger off of an onClick on a mark
  const currentClickPos = state.selection.$from.pos

  const currentBlock = resolvedPos.node(resolvedPos.depth)
  const childrenWithMark = [
    ...findChildrenByMark(currentBlock, state.schema.marks.AiEdited),
    ...findChildrenByMark(currentBlock, state.schema.marks.AiSuggestion),
    ...findChildrenByMark(currentBlock, state.schema.marks.PlaceholderInline),
  ]
  const startPos = resolvedPos.start(resolvedPos.depth)
  for (const child of childrenWithMark) {
    // Find the specific mark that was clicked on, based on current caret position
    const from = startPos + child.pos
    const to = from + child.node.nodeSize
    const isCurrentMark = from <= currentClickPos && currentClickPos <= to
    if (isCurrentMark) {
      return {
        node: child,
        from,
        to,
      }
    }
  }

  return undefined
}

export type MarkResolved = {
  node: NodeWithPos;
  from: number;
  to: number;
}
