import { KeyValuePair } from "@verdi/shared-constants"
import { useState, useCallback } from "react"
import { useActionDrafter } from "../useActionDrafter"


type Props = {
  getPromptWithContext: () => Promise<string>,
  isContinuing: boolean

}
/** Shows an editable list of concepts to the user */
export const useConceptFramer = ({
  getPromptWithContext,
  isContinuing,
}: Props) => {

  const {
    generateConcepts,
    isGettingAnswers,
    isGettingQuestions,
  } = useActionDrafter()

  const [answerSets, setAnswerSets] = useState<KeyValuePair[][]>([])
  const [currentAnswerSetIndex, setCurrentAnswerSetIndex] = useState(0)


  const startTheProcess = useCallback(async () => {
    console.log("starting the process ")

    if (isContinuing || isGettingAnswers || isGettingQuestions) {
      console.warn("already framing concepts")
      return
    }

    // TODO: stop any existing process ???

    const fullPrompt = await getPromptWithContext()
    const answerSets = await generateConcepts(fullPrompt)
    setAnswerSets(answerSets)
    setCurrentAnswerSetIndex(0)

  }, [generateConcepts, getPromptWithContext])


  const showNextSetOfAnswers = useCallback(() => {
    const newIndex = currentAnswerSetIndex + 1
    if (answerSets.length > newIndex) {
      setCurrentAnswerSetIndex(newIndex)
    } else {
      setCurrentAnswerSetIndex(0)
    }
  }, [answerSets.length, currentAnswerSetIndex])


  const removeKeyValuePair = useCallback((index: number) => {
    answerSets[currentAnswerSetIndex].splice(index, 1)
    setAnswerSets([...answerSets])
  }, [answerSets, currentAnswerSetIndex])

  const updateTitle = useCallback((index: number, updatedTitle: string,) => {
    const existing = answerSets[currentAnswerSetIndex][index]
    answerSets[currentAnswerSetIndex][index] = { ...existing, key: updatedTitle }
    setAnswerSets([...answerSets])
  }, [answerSets, currentAnswerSetIndex])

  const updateDescription = useCallback((index: number, updatedDescription: string,) => {
    const existing = answerSets[currentAnswerSetIndex][index]
    answerSets[currentAnswerSetIndex][index] = { ...existing, value: updatedDescription }
    setAnswerSets([...answerSets])
  }, [answerSets, currentAnswerSetIndex])


  return {
    startTheProcess,
    showNextSetOfAnswers,
    removeKeyValuePair,
    updateTitle,
    updateDescription,
    answerSets,
    currentAnswerSetIndex,
    isGettingQuestions,
    isGettingAnswers,
  }

}

export type UseContentFramer = ReturnType<typeof useConceptFramer>
