import { ApiTypes, ChatRoleType } from "@verdi/shared-constants"
import { AssumptionSummary } from "../../../screens/assumptions/useAssumptionsProvider"
import { AiContextConfig, AllContextForAi } from "../../promptUtils/useAllContextForAi"
import { makeAiChatRequest } from "../../requests/AiApiRequests"
import { cleanupAiOutputAsKeyValuePairs } from "../../utils/cleanupAiOutput"


/** Returns a list of questions to be used to further flush out thinking */
export const makeQuestionsAiCall = async (
  getContextForAi: (optionArgs?: AiContextConfig | undefined) => Promise<AllContextForAi>,
  suggestedQuestionsPending: string[],
) => {

  const allContext: AllContextForAi = await getContextForAi()
  const suggestionsPending: AssumptionSummary[] = suggestedQuestionsPending.map(q => ({ question: q, answer: "" }))

  const messageForAllAssumptions = buildMessageForCurrentQuestions(suggestionsPending)

  const prompt = getPrompt()

  const response = await makeAiChatRequest({
    prompt,
    messages: [...allContext.promptMessages, ...messageForAllAssumptions],
    openAIParams: {
      n: 2,
    }
  })

  // const rawString = response?.aiResponseJson.content as string || ""
  const rawChoices = response?.aiResponseJson.contentOptions as string[] || ""
  console.log("===> makeQuestionsAiCall: ", { prompt, rawChoices })
  const rawString = rawChoices[0] || ""
  const keyValuePairs = cleanupAiOutputAsKeyValuePairs(rawString, "|")
  // let rawQuestions = rawString.split("\n").map(l => cleanupAiOutputItem(l)).filter(l => l.trim().length > 0)
  // console.log("makeAssumptionQuestionsAiCall: ", { prompt, rawAssumptions, response })

  return keyValuePairs
}


export const getPrompt = () => {

  const prompt = `You are an expert product leader. 
A product manager is exploring a potential product opportunity (a problem to be solved or idea to be vetted). 
Your job is to ask questions and make suggestions related to this document 
in order to help the product manager clarify and pressure test their thinking.
    
INSTRUCTIONS:
Focus on the specific opportunity context.
Return 1 critical question based on the context. If appropriate, return 2 questions, or maximum of 3.
These questions should be formulaic, in that they encompass the critical aspects of 
the opportunity to guide in determining if the opportunity should be discarded or not.
You prefer questions that are open-ended over questions that can be answered with a yes/no.
You prefer questions that lead to specific changes in the document over questions 
that are more evaluative or reflective (e.g., you would prefer "how would you make the 
market definition more precise" over "how well defined is the target market").
Return only the questions in your output, each question on its own line. 

RESPONSE FORMATTING INSTRUCTIONS:
DO NOT number them or include any dashes or other formatting characters.
DO NOT include any title, introduction, or preamble in your response.
Each question should be a single line and each line must conform to the following format:
summarized concept | the actual question text


questions should be as concise as possible. 
For example, if you have a question: 
"What are the current challenges for customers that are using the system?", you can simplify it to "Current challenges?".

Example questions to consider (though not all at once):
Define the problem | What problem is this idea solving?
Measure success | How will we measure success?
Target customer | Who is the target customer?
Alternative solutions | How are these target customers currently solving this problem?
Marketing | How will this opportunity be marketed?
Customer needs | How might you address the customer's needs?
Risks | Which are the greatest risks?
Capabilities | Which capabilities are required?
Timing | Why is now the right time to pursue this?
Brand fit | How would this align with our brand?

Where appropriate, consider more probing questions, to help refine and pressure test the opportunity. 
For example (probing within the customer segment as an example):
Core sufferers | Which customers feel the pain most accutely?
Who would pay? | Which customers (within the target segment) are most likely to pay for a solution?
Early adopters | Who are the most likely early adopters?
Differentiation | How is this solution differentiated from other solutions in the market?
Early adopter musts-haves | What would make this product a must-have for early adopters?
Less interested customers | Which potential customers might be less interested?
Reasons not to buy | Why might some interested customers not purchase this product?

EXAMPLE RESPONSE 1: 
Given an empty opportunity document with the title: "Streamline onboarding"
A valid response could be:
Current challenges | What are the current challenges?

EXAMPLE RESPONSE 2:
Given a fairly robust, well-structured document with the title "Personalization PRD"
Given a recently added section of the document citing increased user engagement as one of the target outcomes
A valid response could be:
Personalization | Why will personalization increase user engagement?
Increase user engagement | What would have to be true for personalization to increase user engagement?

EXAMPLE RESPONSE 3:
Given a partially complete document with less structured content and the title: "Research plan"
A valid response could be:
Key questions | What are the key questions to answer?
Who has insight | Who might already have helpful insight into these questions?
Fast answers | What is the fastest way to get preliminary answers?

    `

  return prompt
}


export const buildMessageForCurrentQuestions = (allAssumptions: AssumptionSummary[]) => {

  if (allAssumptions.length === 0) return []

  const questionsAndAnswers = allAssumptions.map(a => {
    return `${a.question} ${a.answer}`
  })

  const content = `WE HAVE ALREADY ASKED THESE QUESTIONS. DO NOT REPEAT THEM OR VARIATIONS OF THEM:
${questionsAndAnswers.join('\n')}
`
  const message: ApiTypes.RunAiChatRequestMessage = {
    content,
    role: ChatRoleType.system
  }
  return [message]
}
