/**
 * @generated SignedSource<<ef7f0f7369cf35209116d82f2b4ce0b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentType = "assumption" | "concept" | "document" | "faq" | "guide" | "initiative" | "insight" | "interview" | "interviewGuide" | "interviewNotes" | "opportunity" | "outcome" | "prd" | "pressRelease" | "quickNote" | "research" | "researchInitiative" | "%future added value";
export type DocumentTemplateEditor_Query$variables = {
  documentId: string;
};
export type DocumentTemplateEditor_Query$data = {
  readonly node: {
    readonly id?: string;
    readonly templateInfo?: {
      readonly id: string;
      readonly isPublished: boolean;
      readonly title: string | null;
    } | null;
    readonly title?: string | null;
    readonly type?: DocumentType;
    readonly " $fragmentSpreads": FragmentRefs<"AiPromptList_document" | "EditAiPromptOptionsMenu_document" | "EditDocumentContextTemplate_document">;
  } | null;
};
export type DocumentTemplateEditor_Query = {
  response: DocumentTemplateEditor_Query$data;
  variables: DocumentTemplateEditor_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "documentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DocumentTemplateInfo",
  "kind": "LinkedField",
  "name": "templateInfo",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPublished",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentTemplateEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditAiPromptOptionsMenu_document"
              },
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AiPromptList_document"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditDocumentContextTemplate_document"
              }
            ],
            "type": "Document",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentTemplateEditor_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DocumentAiPrompt",
                "kind": "LinkedField",
                "name": "aiPrompts",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDocContext",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "promptText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dontDo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notesForAdmin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OpenAIParams",
                    "kind": "LinkedField",
                    "name": "openApiParams",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "best_of",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "echo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "frequency_penalty",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "logprobs",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "max_tokens",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "model",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "n",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "presence_penalty",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stop",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "suffix",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "temperature",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "top_p",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "instructionsForUser",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "type": "Document",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "690690ad269b04a9793f130739bf0ae6",
    "id": null,
    "metadata": {},
    "name": "DocumentTemplateEditor_Query",
    "operationKind": "query",
    "text": "query DocumentTemplateEditor_Query(\n  $documentId: ID!\n) {\n  node(id: $documentId) {\n    __typename\n    ... on Document {\n      id\n      title\n      type\n      ...EditAiPromptOptionsMenu_document\n      templateInfo {\n        id\n        title\n        isPublished\n      }\n      ...AiPromptList_document\n      ...EditDocumentContextTemplate_document\n    }\n    id\n  }\n}\n\nfragment AiPromptFormModal_documentLevelContextAiPrompt on DocumentAiPrompt {\n  id\n  promptText\n  dontDo\n  notesForAdmin\n  openApiParams {\n    best_of\n    echo\n    frequency_penalty\n    logprobs\n    max_tokens\n    model\n    n\n    presence_penalty\n    stop\n    suffix\n    temperature\n    top_p\n  }\n}\n\nfragment AiPromptList_document on Document {\n  aiPrompts {\n    id\n    promptText\n    dontDo\n    instructionsForUser\n  }\n}\n\nfragment AiPromptPreview_otherBlockLevelPrompts on DocumentAiPrompt {\n  dontDo\n}\n\nfragment EditAiPromptOptionsMenu_document on Document {\n  id\n  title\n  aiPrompts {\n    id\n    isDocContext\n    ...useUpdateAiPromptMutation_documentAiPrompt\n    ...AiPromptFormModal_documentLevelContextAiPrompt\n    ...AiPromptPreview_otherBlockLevelPrompts\n  }\n}\n\nfragment EditDocumentContextTemplate_document on Document {\n  id\n  title\n  aiPrompts {\n    isDocContext\n    ...useUpdateAiPromptMutation_documentAiPrompt\n    ...AiPromptPreview_otherBlockLevelPrompts\n    id\n  }\n}\n\nfragment useUpdateAiPromptMutation_documentAiPrompt on DocumentAiPrompt {\n  id\n  promptText\n  dontDo\n  notesForAdmin\n  openApiParams {\n    best_of\n    echo\n    frequency_penalty\n    logprobs\n    max_tokens\n    model\n    n\n    presence_penalty\n    stop\n    suffix\n    temperature\n    top_p\n  }\n}\n"
  }
};
})();

(node as any).hash = "96d7d70f47d22c56ac2d33e32fbbd40b";

export default node;
