import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { uniqueId } from "lodash"


const cmdType = GlobalCommandType.loadingCommandPlaceholder

/** Acts as a placeholder while a command loads. 
 * 
 *  Should be replaced by a real command once loading is complete.
 * 
 *  Adding this in a CM can help reduce shifting of commands in the menu when loading completes.
 */
export const loadingCommandPlaceholderCommandDefinition: GlobalCommandDefinition<LoadingCommandPlaceholderCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: LoadingCommandPlaceholderCommandArgs) => {

    const id = uniqueId("loadingCommandPlaceholder-") // To ensure react updates
    const toReturn =
      {
        id,
        globalCommandType: cmdType,
        isLoading: true,
        name: args.loadingMessage || "...",
        searchName: "",
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "loading",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: LoadingCommandPlaceholderCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    // No logic needed here, as it's just a placeholder

    onProcessingComplete(true)
  },
  triggerCommand: (args: LoadingCommandPlaceholderCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type LoadingCommandPlaceholderCommandArgs = {
  loadingMessage?: string
}
