import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.showCustomAIPrompt

export const showCustomAIPromptCommandDefinition: GlobalCommandDefinition<ShowCustomAIPromptCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ShowCustomAIPromptCommandArgs) => {

    const toReturn =
      {
        globalCommandType: cmdType,
        name: args.title ? args.title : 'Run custom AI Prompt ...',
        searchName: args.title ? args.title.toLowerCase() : 'run custom ai prompt ...',
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
        iconType: "aiSuggestion",
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ShowCustomAIPromptCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {
    const currentBlockContext = services.servicesForCurrentDoc?.aiTipTapBridge.getCurrentBlockContext()
    if (currentBlockContext && !args.rectOfAnchorElement) {
      args.rectOfAnchorElement = currentBlockContext.domElement?.getBoundingClientRect()
      console.log("Auto-setting rectOfAnchorElement", args.rectOfAnchorElement)
    }

    services.customAIPromptModalProvider.openCustomPrompt(args)

    onProcessingComplete(true)
  },
  triggerCommand: (args: ShowCustomAIPromptCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type ShowCustomAIPromptCommandArgs = {
  /** The GetBoundingRect() of a target element to visually anchor the CM next to */
  rectOfAnchorElement?: DOMRect
  title?: string
  subTitle?: string
}
