import { useCallback, useMemo } from "react"
import { useAppSelector } from "../../state/storeHooks"
import { Data } from "@verdi/shared-constants"
import { SelectCommandDropdown } from "../../components/commands/miniCommandMenu/SelectCommandDropdown"
import { setDocFrameworkShowOptionsCommandDefinition } from "../../components/commands/commandDefinitions/framework/setDocFrameworkShowOptions"


type Props = {
  documentId: string,
  isForOpportunity: boolean,
  landmarkId?: string,
}
/** Allows selecting frameworks from a list and immediately persists them in the DB
 *  as DocumentFrameworks, by utilizing the related "show options" command definition */
export const DocumentFrameworkEditor = ({
  documentId,
  isForOpportunity,
  landmarkId,
}: Props) => {

  const allFrameworks = useAppSelector(state => state.frameworks.frameworks)
  const currentDocumentFramework = useAppSelector(state => state.documentFrameworks.allDocumentFrameworks
    .find(df => df.documentId === documentId))
  //  const documentFrameworks = useAppSelector(state => state.documentFrameworks.documentFrameworks)

  const selectedFramework = useMemo<Data.FrameworkModel | undefined>(() => {
    if (!currentDocumentFramework?.frameworkId) {
      return undefined
    }
    return allFrameworks.find(f => f.id === currentDocumentFramework.frameworkId)
  }, [currentDocumentFramework, allFrameworks])


  const onDropDownOpen = useCallback((rect: DOMRect) => {
    setDocFrameworkShowOptionsCommandDefinition.triggerCommand?.({
      documentId,
      isForOpportunity,
      rect,
      shouldAutoApplyToDocBody: true,
    })
  }, [documentId, setDocFrameworkShowOptionsCommandDefinition.triggerCommand])


  return (
    <SelectCommandDropdown
      data-landmark={landmarkId}
      size="xs"
      label={selectedFramework ? selectedFramework.title : "None"}
      searchPlaceholderText="choose a framework"
      commandOptions={onDropDownOpen}
      onItemSelected={(command) => console.log("doc framework item selected ", { command })}
      maxWidth={150}
      title={selectedFramework?.whenToUse}
    />
  )
}
