/**
 * @generated SignedSource<<b62bd8ed74786352b1fffe6453ad8044>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useLoggedInUserProviderQuery$variables = {};
export type useLoggedInUserProviderQuery$data = {
  readonly loggedInUser: {
    readonly email: string;
    readonly id: string;
    readonly isAdmin: boolean;
    readonly name: string | null;
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly shouldIncludeInReports: boolean;
    };
    readonly responsibleFor: string | null;
    readonly roleTitle: string | null;
  } | null;
};
export type useLoggedInUserProviderQuery = {
  response: useLoggedInUserProviderQuery$data;
  variables: useLoggedInUserProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "loggedInUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAdmin",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shouldIncludeInReports",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roleTitle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "responsibleFor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoggedInUserProviderQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useLoggedInUserProviderQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "42d3b991fe806f8ca4a94b627b3a8e23",
    "id": null,
    "metadata": {},
    "name": "useLoggedInUserProviderQuery",
    "operationKind": "query",
    "text": "query useLoggedInUserProviderQuery {\n  loggedInUser {\n    id\n    email\n    name\n    isAdmin\n    organization {\n      id\n      name\n      shouldIncludeInReports\n    }\n    roleTitle\n    responsibleFor\n  }\n}\n"
  }
};
})();

(node as any).hash = "6609953c0ef0ad62a1509790223efce4";

export default node;
