import { IconButton } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { AiPersonaSelector } from "./AiPersonaSelector"
import { AiPersona } from "./AiPersona"
import { VerdiIconChatHistory } from "../icons/VerdiIcons"


type Props = {
  loadPastMessages: () => void,
  hasPastMessagesToLoad: boolean,
  isLoadingMoreChatHistory: boolean,
  aiPersona: AiPersona,
  onAiPersonaChange: (aiPersona: AiPersona) => void,
  isCompactMode: boolean,
}
export const ChatViewSettings = ({
  hasPastMessagesToLoad,
  isLoadingMoreChatHistory,
  loadPastMessages,
  aiPersona,
  onAiPersonaChange,
  isCompactMode,
}: Props) => {


  return (
    <div css={css`
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;
      position: relative;
      margin-bottom: 10px;
    `}>

      <AiPersonaSelector
        aiPersona={aiPersona}
        setAiPersona={(persona) => onAiPersonaChange(persona)}
        isCompactMode={isCompactMode}
        rightSideButtons={
          hasPastMessagesToLoad &&
          <div>
            <IconButton
              aria-label="Load past messages"
              title="Load past messages"
              icon={<VerdiIconChatHistory />}
              isLoading={isLoadingMoreChatHistory}
              size="xs"
              variant="ghost"
              justifyContent={"flex-start"}
              colorScheme="verdiButtonPrimary"
              width={30}
              height={30}
              paddingLeft={2}
              marginLeft={1}
              onClick={() => loadPastMessages()}
            />
          </div>
        }
      />

      {
      }

    </div>

  )
}

