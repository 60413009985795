import { useCallback, useState } from "react"
import { Data, Menu } from "@verdi/shared-constants"
import { addOpportunityCommandDefinition } from "../../../../components/commands/commandDefinitions/opportunity/addOpportunity"
import { OpportunityStatusType } from "../../../../components/kanban/KanbanColumnOptions"
import { generateTitleForDocument } from "../../../../ai/docDrafter/opportunities/generateTitlesForDocument"


type Props = {
  onCreateComplete: (opportunityDocId: string) => void
  onError: (errorMessage: string) => void
}
/** Creates an opportunity, providing state for all the options. */
export const useAddNewOpportunity = ({
  onCreateComplete,
  onError,
}: Props) => {


  const [isCreatingOpportunity, setIsCreatingOpportunity] = useState(false)
  const [parentMenuItem, setParentMenuItem] = useState<Menu.MenuItemData | undefined>(undefined)
  const [opportunityDescription, setOpportunityDescription] = useState("")
  const [selectedFramework, setSelectedFramework] = useState<Data.FrameworkModel | undefined>()
  const [selectedStatus, setSelectedStatus] = useState<OpportunityStatusType>(OpportunityStatusType.Explore)


  const onCreateReturn = useCallback((opportunityDocId: string | undefined) => {
    console.log("onCreateReturn", { opportunityDocId })
    if (!opportunityDocId) {
      onError("Error creating opportunity")
      return
    }
    setOpportunityDescription("")
    setIsCreatingOpportunity(false)
    onCreateComplete(opportunityDocId)
  }, [setIsCreatingOpportunity, setOpportunityDescription, onCreateComplete, onError])


  const createNewOpportunity = useCallback(async () => {
    setIsCreatingOpportunity(true)

    // let title = opportunityDescription
    // let description
    // if (opportunityDescription.length > 50) {
    //   title = await generateTitleForDocument(opportunityDescription)
    //   if (!title) { // Sometimes the AI returns nothing
    //     title = opportunityDescription.substring(0, 50)
    //   }
    //   description = opportunityDescription
    // }

    // let initialStepJson: string | undefined = undefined
    // if (description && description.length > 0) {
    //   const initialSteps = buildFirstDocStepFromOutline([{
    //     type: "text",
    //     content: description,
    //   }])
    //   initialStepJson = JSON.stringify(initialSteps)
    // }

    const potentialTitles = await generateTitleForDocument(opportunityDescription)
    const title = potentialTitles[0] || undefined

    addOpportunityCommandDefinition.triggerCommand?.({
      currentDocId: parentMenuItem?.id || undefined,
      conceptsToUse: [],
      newOpportunity: {
        title,
        description: opportunityDescription,
        initialStepJson: undefined,
        status: selectedStatus,
      },
      framework: selectedFramework || undefined,
      onCreated: onCreateReturn
    })

  }, [opportunityDescription, parentMenuItem, onCreateReturn, setIsCreatingOpportunity, selectedFramework, selectedStatus, onError])


  return {
    createNewOpportunity,
    isCreatingOpportunity,
    parentMenuItem,
    setParentMenuItem,
    opportunityDescription,
    setOpportunityDescription,
    selectedFramework,
    setSelectedFramework,
    selectedStatus,
    setSelectedStatus,
  }
}

export type AddNewOpportunityHook = ReturnType<typeof useAddNewOpportunity>
