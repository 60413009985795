import { IconProps } from "@chakra-ui/react"
import { css } from "@emotion/react"


type Props = IconProps & {
  icon: React.ReactNode
}
/** Wraps an icon with uniform styling. Great for Titles of pages and modals. */
export const IconWrapper = ({
  icon,
}: Props) => {

  return (
    <span css={css`
      opacity: 0.5;
      margin-right: 4px;
    `}>
      {icon}
    </span>
  )
}
