import { useColorModeValue } from "@chakra-ui/react";
import { css } from "@emotion/react"
import { documentBackgroundColors, menuBorderColors } from "../ColorModeValues";
import { mediaQuerySmallWidth } from "./styleHelpers";


type Props = {
  children: React.ReactNode
  maxWidth?: number
  doNotAddMarginToBottom?: boolean
}
export const MainViewWrapper = ({
  children,
  maxWidth = 1200,
  doNotAddMarginToBottom,
}: Props) => {


  const documentBackground = useColorModeValue(documentBackgroundColors.light, documentBackgroundColors.dark);
  const menuBorderColor = useColorModeValue(menuBorderColors.light, menuBorderColors.dark)


  return (
    <div css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: ${maxWidth}px;
        margin-bottom: ${doNotAddMarginToBottom ? 0 : 60}px;
      `}>

      <div css={css`
        background: ${documentBackground}; 
        width: 100%;
        padding: 20px 30px; // 20px 40px 20px;
        margin-bottom: ${doNotAddMarginToBottom ? 0 : 40}px;
        min-height: 400px;
        @media (${mediaQuerySmallWidth}) {
              padding: 20px 15px;
            }
      `}>
        {children}
      </div>
    </div>
  )
}
