import { Icon } from "@chakra-ui/react"


export const DocumentIcon = (props: any) => {

  return (
    <Icon viewBox="0 0 213 264" {...props}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213 264">
        <rect x="12" y="12" width="189" height="240" rx="27.9" fill="#fff" />
        <path d="M173.59,24a15.91,15.91,0,0,1,15.89,15.9V224.1A15.91,15.91,0,0,1,173.59,240H40.38a15.92,15.92,0,0,1-15.9-15.9V39.9A15.92,15.92,0,0,1,40.38,24H173.59m0-24H40.38A39.9,39.9,0,0,0,.48,39.9V224.1A39.9,39.9,0,0,0,40.38,264H173.59a39.89,39.89,0,0,0,39.89-39.9V39.9A39.89,39.89,0,0,0,173.59,0Z" transform="translate(-0.48)" />
        <line x1="63.69" y1="193" x2="143.52" y2="193" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="24" />
        <line x1="63.69" y1="142" x2="149.31" y2="142" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="24" />
        <line x1="63.69" y1="91" x2="133.52" y2="91" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="24" /></svg>
    </Icon>
  )
}

export const DocumentIconAdd = (props: any) => {

  return (
    <Icon viewBox="0 0 213 264" {...props}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213 264"><path d="M173.6,24a15.91,15.91,0,0,1,15.9,15.9V224.1A15.91,15.91,0,0,1,173.6,240H40.4a15.91,15.91,0,0,1-15.9-15.9V39.9A15.91,15.91,0,0,1,40.4,24H173.6m0-24H40.4A39.9,39.9,0,0,0,.5,39.9V224.1A39.9,39.9,0,0,0,40.4,264H173.6a39.9,39.9,0,0,0,39.9-39.9V39.9A39.9,39.9,0,0,0,173.6,0Z" transform="translate(-0.5)" />
        <line x1="63.69" y1="132" x2="149.31" y2="132" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" />
        <line x1="106.5" y1="174.81" x2="106.5" y2="89.19" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30" /></svg>
    </Icon>
  )
}


