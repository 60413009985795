import { findParentNodeClosestToPos } from "prosemirror-utils"
import { ResolvedPos, Node } from "prosemirror-model"
import { DocumentSchema } from "@verdi/shared-constants"

// Adding types here will ensure things like pressing enter will work as expected
const nestables = [
  'question',
  'section',
  'freeText',
  DocumentSchema.VerdiNodeTypes.taskCheckbox,
]

export const isNestableNodePredicate = (node: Node) => {
  const isNestableNode = nestables.includes(node.type.name)
  return isNestableNode
}

export const findParentNestableNodeClosestToPos = (pos: ResolvedPos) => {
  const parent = findParentNodeClosestToPos(pos, isNestableNodePredicate)
  return parent
}
