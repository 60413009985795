import { memo } from 'react';
import { Handle, Position, NodeProps, Node } from '@xyflow/react';
import { css } from '@emotion/react';
import usePlaceholderClick from './usePlaceholderClick';


export type PlaceholderWrapperData = Node & {
  type: "placeholder"
  data: { placeholderText: string }
}



const PlaceholderNode = ({ id, data }: NodeProps) => {
  // see the hook implementation for details of the click handler
  // calling onClick turns this node and the connecting edge into a workflow node
  const onClick = usePlaceholderClick(id);


  return (
    <div onClick={onClick}
      title="click to add a node"
      css={css`
      border: 1px solid #0984e3;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
      padding: 12px;
      width: 160px;
      text-align: center;
      font-weight: bold;
      color: #0984e3;
      cursor: pointer;
      line-height: 1.2;
      &:hover {
        background: radial-gradient(
          circle at 18.7% 37.8%,
          rgb(252, 252, 252) 0%,
          rgb(239, 241, 243) 90%
        );
      }
    `}>
      {(data as any).label}
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
        css={css`
          visibility: hidden;
        `}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        css={css`
        visibility: hidden;
      `}
      />
    </div>
  );
};

export default memo(PlaceholderNode);
