import { Link, Text } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useRouter } from "found"
import { useColor } from "../../utility-hooks/useColor"
import { VerdiIconIsNotVisible } from "../../components/icons/VerdiIcons"


type Props = {
  url: string,
  title: string
  isExpanded: boolean
  isSelected: boolean
  isVisibleToOthers: boolean
  childCount?: number
  onClick: () => void
  shouldIndent?: boolean
  landmarkId?: string,
}

const getCss = (isExpanded: boolean, isSelected: boolean, selectedBgColor: string, shouldIndent?: boolean) => css`
  overflow: hidden; 
  text-overflow: ellipsis;
  flex: 1;
  font-weight: normal;
  font-size: 0.9em;
  align-items: center;
  justify-content: left;
  display: flex;
  /* opacity: ${isExpanded ? "1" : "0.5"}; */
  /* ${isSelected ? `background-color: ${selectedBgColor};` : ""} */
  line-height: 1.3;
  padding: 6px 4px;
  ${shouldIndent ? `padding-left: 18px;` : ""}
  border-radius: 5px;
  :hover {
    text-decoration: none;
    color: inherit;
    opacity: 0.9;
  }
`

export const SideMenuButton = ({
  url,
  title,
  isExpanded,
  isSelected,
  isVisibleToOthers,
  onClick,
  childCount,
  shouldIndent,
  landmarkId,
}: Props) => {

  const router = useRouter()
  const selectedBgColor = useColor("itemSelectedBg")


  return (
    <Link
      data-landmark={landmarkId}
      href={url}
      onClick={(evt) => {
        evt.preventDefault()
        onClick?.()
        router.router.push(url)
      }}
      css={getCss(isExpanded, isSelected, selectedBgColor, shouldIndent)}>

      <Text
        title={title}
        css={css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          /* :hover {
            text-overflow: unset;
            white-space: unset;
            overflow: visible;
          } */
      `}>
        {title}
      </Text>

      {childCount ?
        <small css={css`opacity: 0.3; margin-left: 2px; font-size: xx-small;`}>
          &nbsp;{childCount}
        </small>
        : null
      }
      {!isVisibleToOthers &&
        <span css={css`
          margin-left: 6px;
          opacity: 0.3;
        `}>
          <VerdiIconIsNotVisible marginRight={2} marginLeft={2} />
        </span>
      }

    </Link>
  )
}

export const SideMenuButtonNoLink = ({ title, isExpanded, isSelected, isVisibleToOthers, childCount, shouldIndent }: Props) => {

  const selectedBgColor = useColor("itemSelectedBg")


  return (
    <div
      css={getCss(isExpanded, isSelected, selectedBgColor, shouldIndent)}>

      {!isVisibleToOthers &&
        <VerdiIconIsNotVisible marginRight={2} />
      }
      <Text noOfLines={1}>
        {title} bah
        {childCount ?
          <small css={css`opacity: 0.3; margin-left: 2px; font-size: xx-small;`}>
            &nbsp;{childCount}
          </small>
          : null
        }
      </Text>

    </div>
  )
}
