import { GlobalCommandType } from "./GlobalCommandType"


/** The main key used for setting up event listeners for any global commands */
export const GLOBAL_COMMAND_KEY = "verdiGlobalCommandInvoked"


/** ALL GLOBAL COMMANDS SHOULD BE TRIGGERED VIA THIS FUNCTION.
 * 
 *  Sends a global command to the Global Command Processor. */
export const triggerGlobalCommand = (command: GlobalCommandType, commandArgs?: any) => {

  const commandName = command.toString()
  const event = new CustomEvent(GLOBAL_COMMAND_KEY, {
    detail: {
      commandType: commandName,
      commandArgs,
    }
  })

  window.dispatchEvent(event)

}
