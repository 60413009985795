import { ApiTypes, Data } from "@verdi/shared-constants"
import { useCallback, useEffect, useMemo, useState } from "react"
import { makeGetRequestJson, makePostRequestJson } from "../../../utility-hooks/fetchUtils"
import { useAppSelector } from "../../../state/storeHooks"
import { dispatch } from "../../../state/store"
import { getCurrentOrgPlan, getCurrentOrgPlanHasLoaded, getPaymentDetailsHaveLoaded, getPaymentLink, getPlanOptions, getStripeSubscriptionRaw, setPaymentDetailsHaveLoaded, setPaymentLink, setPlanOptions, setStripeSubscriptionRaw } from "../../../state/PlanSlice"
import { format, formatDistance } from "date-fns"
import { useAppServices } from "../../../components/appServices/useAppServices"
import { css } from "@emotion/react"
import { useFeatureFlags } from "../../../utility-hooks/useFeatureFlags"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { OrgPlanOptionBox } from "./OrgPlanOptionBox"
import { mediaQuerySmallWidth } from "../../../components/pageLayout/styleHelpers"


type Props = {
  orgId: string
}
export const OrgPlanEditor = ({
  orgId
}: Props) => {

  const { toast } = useAppServices()
  const { experimentalEnabled } = useFeatureFlags()

  const plan = useAppSelector(getCurrentOrgPlan)
  const hasLoadedPlan = useAppSelector(getCurrentOrgPlanHasLoaded)

  const paymentLink = useAppSelector(getPaymentLink)
  const planOptions = useAppSelector(getPlanOptions)
  const stripeSubscriptionRaw = useAppSelector(getStripeSubscriptionRaw)
  const hasLoadedPaymentDetails = useAppSelector(getPaymentDetailsHaveLoaded)

  const loadAll = useCallback(async () => {
    console.log("loading plan details")

    Promise.allSettled([
      makeGetRequestJson("MyStripePaymentLink"),
      makeGetRequestJson("MyStripePaymentLink/subscription"),
      makeGetRequestJson("OrganizationPlanOptions"),
    ]).then((results) => {

      const [paymentLinkResults, subscriptionResults, planOptionsResults] = results
      console.log("results", results)

      const paymentLink = (paymentLinkResults as any).value as Data.StripePaymentLink.StripePaymentLinkModel
      dispatch(setPaymentLink(paymentLink))

      const subscription = (subscriptionResults as any).value as Data.StripeShared.StripeSubscriptionRaw
      if (subscription && !(subscription as any).error) {
        dispatch(setStripeSubscriptionRaw(subscription))
      }

      const allPlanOptions = (planOptionsResults as any).value as Data.OrganizationPlanOption.OrganizationPlanOptionModel[]
      const filteredPlanOptions = experimentalEnabled ? allPlanOptions : allPlanOptions.filter(o => o.isActive)
      const sortedPlanOptions = filteredPlanOptions.sort((a, b) => a.interval === "month" ? 1 : -1) // make year show first
      dispatch(setPlanOptions(sortedPlanOptions))

      dispatch(setPaymentDetailsHaveLoaded(true))
    })

    console.log("planOptions", { planOptions })

  }, [planOptions, experimentalEnabled])


  useEffect(() => {
    loadAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId])


  const freeTrialExpiresAtText = useMemo<StatusMessageParts | undefined>(() => {
    if (!plan?.freeTrialExpiresAt) return undefined
    const freeTrialDate = new Date(plan?.freeTrialExpiresAt)
    const hasExpired = freeTrialDate.getTime() < (new Date()).getTime()
    const daysAgoMessage = formatDistance(freeTrialDate, new Date(), { addSuffix: true })

    if (hasExpired) {
      return undefined
    }
    return {
      statusText: hasExpired ? "Your trial has expired" : "Your trial expires",
      daysUntilText: daysAgoMessage,
      dateText: format(freeTrialDate, "MMMM do, yyyy")
    }
  }, [plan?.freeTrialExpiresAt])


  const planRenewsAtText = useMemo<StatusMessageParts | undefined>(() => {
    if (!plan?.paidPlanExpiresAt) return undefined
    const paidPlanExpiresAtDate = new Date(plan?.paidPlanExpiresAt)
    const hasExpired = paidPlanExpiresAtDate.getTime() < (new Date()).getTime()
    const daysAgoMessage = formatDistance(paidPlanExpiresAtDate, new Date(), { addSuffix: true })
    return {
      statusText: hasExpired ? "Your plan expired" : "Your plan renews",
      daysUntilText: daysAgoMessage,
      dateText: format(paidPlanExpiresAtDate, "MMMM do, yyyy")
    }
  }, [plan?.paidPlanExpiresAt])


  const hasActivePaidPlan = useMemo(() => {
    if (!plan?.paidPlanExpiresAt) return false
    const paidPlanExpiresAtDate = new Date(plan?.paidPlanExpiresAt)
    const hasExpired = paidPlanExpiresAtDate.getTime() < (new Date()).getTime()
    return !hasExpired
  }, [plan?.paidPlanExpiresAt])


  const [isProcessing, setIsProcessing] = useState(false)
  const [planToPurchase, setPlanToPurchase] = useState<Data.OrganizationPlanOption.OrganizationPlanOptionModel | undefined>(undefined)
  const planOptionClicked = useCallback(async (planOption: Data.OrganizationPlanOption.OrganizationPlanOptionModel) => {
    setIsProcessing(true)
    setPlanToPurchase(planOption)
    console.log("planOptionClicked", { planOption })

    try {
      const body: ApiTypes.MyStripePaymentLinkRequestBody = {
        stripePriceId: planOption.stripePriceId,
      }

      const results: Data.StripePaymentLink.StripePaymentLinkModel = await makePostRequestJson("MyStripePaymentLink", body)
      console.log("Make payment link results = ", { results })
      // Redirect to the payment link
      if (!results.stripePaymentLinkUrl) {
        throw new Error("No payment link url returned")
      }

      window.location.href = results.stripePaymentLinkUrl

    } catch (err) {
      console.error(err)
      toast.showError("Failed to generate a payment link.")
    }

    setIsProcessing(false)
    setPlanToPurchase(undefined)
  }, [setPlanToPurchase, setIsProcessing, toast])


  const currentPlan = useMemo<Data.OrganizationPlanOption.OrganizationPlanOptionModel | undefined>(() => {
    if (!stripeSubscriptionRaw) return undefined
    console.log("THE stripeSubscriptionRaw DUDE = ", { stripeSubscriptionRaw })

    const plan = stripeSubscriptionRaw?.items?.data[0]?.plan
    if (!plan) {
      return undefined
    }

    const toReturn: Data.OrganizationPlanOption.OrganizationPlanOptionModel = {
      title: "Current Plan",
      interval: plan.interval,
      isActive: plan.active,
      orgPlanType: "",
      priceInCents: plan.amount,
      stripePriceId: "" // TODO: In the future connect this with the renewal button?
    }
    return toReturn
  }, [stripeSubscriptionRaw])


  if (!hasLoadedPlan || !hasLoadedPaymentDetails) {
    return (
      <div css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
      `}>
        <LoadingSpinner size="sm" />
      </div>
    )
  }


  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      gap: 20px;
    `}>
      {currentPlan &&
        <div>
          <h2>
            Current Plan
          </h2>
          <OrgPlanOptionBox
            planOption={currentPlan}
            onButtonClick={() => console.log("Do nothing")}
            isDisabled={isProcessing}
            isLoading={false}
            isCurrentPlan={true}
            sideContent={
              <div css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                & > div {
                  display: flex;
                  flex-direction: column;
                  & > span:nth-of-type(2) {
                    font-weight: bold;
                  }
                  & > span:nth-of-type(3) {
                    font-style: italic;
                  }
                }
              `}>
                <div>
                  <span>Plan status </span>
                  <span>{plan?.isActive ? "Active" : "Inactive"}</span>
                </div>

                <div>
                  <span>Number of seats </span>
                  <span>{plan?.numberOfPaidSeats || 0}</span>
                </div>

                {freeTrialExpiresAtText &&
                  <div>
                    <span>{freeTrialExpiresAtText.statusText}</span>
                    <span>{freeTrialExpiresAtText.daysUntilText}</span>
                    <span>on {freeTrialExpiresAtText.dateText}</span>
                  </div>
                }

                {planRenewsAtText &&
                  <div>
                    <span>{planRenewsAtText.statusText}</span>
                    <span>{planRenewsAtText.daysUntilText}</span>
                    <span>on {planRenewsAtText.dateText}</span>
                  </div>
                }

              </div>
            } />
        </div>
      }

      {!currentPlan &&
        <div>
          <p>
            You are not currently on a paid plan.
          </p>
        </div>
      }


      {(!hasActivePaidPlan || experimentalEnabled || true) && planOptions &&

        <div>
          <h2>
            Plan Options
          </h2>

          <div css={css`
            display: flex;
            gap: 8px;
            @container mainStage ${mediaQuerySmallWidth} {
              flex-direction: column;
            }
            & > div {
              flex: 1;
            }
          `}>

            {planOptions.map((planOption, key) =>
              <OrgPlanOptionBox
                key={key}
                planOption={planOption}
                onButtonClick={() => planOptionClicked(planOption)}
                isDisabled={isProcessing}
                isLoading={isProcessing && planToPurchase?.orgPlanType === planOption.orgPlanType}
                isSpecialOffer={Boolean(planOption.interval !== "month")}
                crossedOutPrice={Boolean(planOption.interval !== "month") ? "$249" : undefined}
              />
            )}
          </div>

        </div>
      }

    </div>
  )
}


type StatusMessageParts = {
  statusText: string
  daysUntilText: string
  dateText: string
}
