// @ts-nocheck
import {
  Extension,
  findChildren,
  combineTransactionSteps,
  getChangedRanges,
  findChildrenInRange,
} from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Slice, Fragment } from "@tiptap/pm/model";
import { v4 } from "uuid";
import { UniqueIDOptions } from './unique-id'


/**
 *  NOTE: We had an active TipTapPro subscription at one point, 
 *  Might consider reactivating to get the latest version of this extension.
 * 
 * This file is include here to help think through how we can add and manage
 *  unique IDs for each element. 
 *
 *  Things to account for:
 *  On creation do we add a unique ID to each element?
 *  when we split a node, we would need to generate a new ID for the new one created
 *  How could we sync this to external data stored in the relational DB?
 *  How will this play nicely with doc collaboration across clients
 */

// @ts-ignore
function u(t) {
  return (function (t, e = JSON.stringify) {
    const n = {};
    return t.filter((t) => {
      const r = e(t);
      return !Object.prototype.hasOwnProperty.call(n, r) && (n[r] = !0);
    });
  })(t.filter((e, n) => t.indexOf(e) !== n));
}
// @ts-ignore
const uniqueIdExtension = Extension.create({
  name: "uniqueID",
  priority: 1e4,
  addOptions: () => ({
    attributeName: "id",
    types: [],
    generateID: () => v4(),
    filterTransaction: null,
  }),
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          [this.options.attributeName]: {
            default: null,
            parseHTML: (t) =>
              t.getAttribute(`data-${this.options.attributeName}`),
            renderHTML: (t) =>
              t[this.options.attributeName]
                ? {
                  [`data-${this.options.attributeName}`]:
                    t[this.options.attributeName],
                }
                : {},
          },
        },
      },
    ];
  },
  onCreate() {
    if (
      this.editor.extensionManager.extensions.find(
        (t) => "collaboration" === t.name
      )
    )
      return;
    const { view: t, state: n } = this.editor,
      { tr: r, doc: o } = n,
      { types: i, attributeName: a, generateID: s } = this.options;
    findChildren(
      o,
      (t) => i.includes(t.type.name) && null === t.attrs[a]
    ).forEach(({ node: t, pos: e }) => {
      r.setNodeMarkup(e, void 0, {
        ...t.attrs,
        [a]: s(),
      });
    }),
      r.setMeta("addToHistory", !1),
      t.dispatch(r);
  },
  addProseMirrorPlugins() {
    let t = null,
      e = !1;
    return [
      new Plugin({
        key: new PluginKey("uniqueID"),
        appendTransaction: (transactions, oldState, newState) => {
          const a =
            transactions.some((t) => t.docChanged) &&
            !oldState.doc.eq(newState.doc),
            s =
              this.options.filterTransaction &&
              transactions.some((tran) => {
                var e, n;
                return !(null === (n = (e = this.options).filterTransaction) ||
                  void 0 === n
                  ? void 0
                  : n.call(e, tran));
              });

          if (!a || s) return;
          const { tr: d } = newState,
            { types: p, attributeName: l, generateID: c } = this.options,
            m = combineTransactionSteps(oldState.doc, transactions),
            { mapping: f } = m;

          return (
            getChangedRanges(m).forEach(({ newRange: t }) => {
              const e = findChildrenInRange(newState.doc, t, (t) =>
                p.includes(t.type.name)
              ),
                n = e
                  .map(({ node: t }) => t.attrs[l])
                  .filter((t) => null !== t);

              e.forEach(({ node: t, pos: r }, o) => {
                var i;
                const a =
                  null === (i = d.doc.nodeAt(r)) || void 0 === i
                    ? void 0
                    : i.attrs[l];
                if (null === a)
                  return void d.setNodeMarkup(r, void 0, {
                    ...t.attrs,
                    [l]: c(),
                  });
                const s = e[o + 1];
                if (s && 0 === t.content.size) {
                  d.setNodeMarkup(s.pos, void 0, { ...s.node.attrs, [l]: a }),
                    (n[o + 1] = a);
                  const e = c();
                  return (
                    d.setNodeMarkup(r, void 0, {
                      ...t.attrs,
                      [l]: e,
                    }),
                    (n[o] = e),
                    d
                  );
                }
                const p = u(n),
                  { deleted: m } = f.invert().mapResult(r);
                m &&
                  p.includes(a) &&
                  d.setNodeMarkup(r, void 0, {
                    ...t.attrs,
                    [l]: c(),
                  });
              });
            }),
            d.steps.length ? d : void 0
          );
        },
        view(e) {
          const n = (n) => {
            var r;
            t = (
              null === (r = e.dom.parentElement) || void 0 === r
                ? void 0
                : r.contains(n.target)
            )
              ? e.dom.parentElement
              : null;
          };
          return (
            window.addEventListener("dragstart", n),
            {
              destroy() {
                window.removeEventListener("dragstart", n);
              },
            }
          );
        },
        props: {
          handleDOMEvents: {
            drop: (n, r) => {
              var o;
              return (
                (t === n.dom.parentElement &&
                  "copy" !==
                  (null === (o = r.dataTransfer) || void 0 === o
                    ? void 0
                    : o.effectAllowed)) ||
                ((t = null), (e = !0)),
                !1
              );
            },
            paste: () => ((e = !0), !1),
          },
          transformPasted: (t) => {
            if (!e) return t;
            const { types: n, attributeName: r } = this.options,
              o = (t) => {
                const e = [];
                return (
                  t.forEach((t) => {
                    if (t.isText) return void e.push(t);
                    if (!n.includes(t.type.name))
                      return void e.push(t.copy(o(t.content)));
                    const item = t.type.create(
                      {
                        ...t.attrs,
                        [r]: null,
                      },
                      o(t.content),
                      t.marks
                    );
                    e.push(item);
                  }),
                  Fragment.from(e)
                );
              };
            return (e = !1), new Slice(o(t.content), t.openStart, t.openEnd);
          },
        },
      }),
    ];
  },
});
// export { uniqueIdExtension as UniqueID, uniqueIdExtension as default };

export const UniqueID = uniqueIdExtension as Extension<UniqueIDOptions, any>;
