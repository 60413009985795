import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { DocumentSchema } from "@verdi/shared-constants"


const cmdType = GlobalCommandType.continueWritingFromTemplatedAiPrompt

/** The original way to trigger AI command prompts to edit the document.
 * 
 * This way will likely be phased out at some point.
 */
export const continueWritingFromTemplatedAiPromptCommandDefinition: GlobalCommandDefinition<ContinueWritingFromTemplatedAiPromptCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: ContinueWritingFromTemplatedAiPromptCommandArgs) => {

    const toReturn =
      {
        globalCommandType: cmdType,
        name: 'Generate',
        description: 'Replace existing',
        searchName: 'continue writing',
        keyboardShortcutMac: ["cmd", "enter"],
        keyboardShortcutWin: ["ctrl", "enter"],
        maxNestingLevel: DocumentSchema.GLOBAL_MAX_NESTING_LEVEL + 1,
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: ContinueWritingFromTemplatedAiPromptCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    if (!services.servicesForCurrentDoc) {
      console.error("Could not find servicesForCurrentDoc")
      return
    }
    const { aiTipTapBridge } = services.servicesForCurrentDoc
    const context = aiTipTapBridge.getDocumentContextForAi()

    if (context.aiPromptId && context.aiPromptInsertRange) {
      console.log("Has aiPromptId")
      services.aiSubscription.requestDocumentEditWithAiPromptId({
        currentAiPromptId: context.aiPromptId,
        currentAiPromptInsertRange: context.aiPromptInsertRange,
        documentVersion: context.documentVersion,
      })
      onProcessingComplete(true)
    } else {
      console.warn("Missing either the aiPromptId or aiPromptInsertRange")
      onProcessingComplete(false)
    }
  },
}

type ContinueWritingFromTemplatedAiPromptCommandArgs = {
}
