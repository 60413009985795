import { css } from "@emotion/react"
import { Editor } from "@tiptap/core"
import { ReactNode } from "react"
import { useColor } from "../../../../../utility-hooks/useColor"

export type SectionRef = {
  length: number,
  isLoading: boolean,
  insertElement: (editor: Editor) => void
}

export type SectionProps = {
  search: string
  selectedIndex: number
  editor: Editor
}


type Props = {
  title: string
  hideTitle?: boolean
  description?: string
  children?: ReactNode | ReactNode[]
  indexInDom?: number
}

export const CommandMenuSection = ({
  title,
  hideTitle,
  description,
  children,
  indexInDom,
}: Props) => {


  const borderColor = useColor("surfaceBorder")

  return (
    <ul
      css={css`
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding: 0 0 4px 0;
      `}
      contentEditable={false}
    >
      {!hideTitle &&
        <div css={css`
          margin-bottom: 2px;
          border-top: ${indexInDom === 0 ? "none" : `1px solid ${borderColor}`} ;
        `}>
          <h6 css={css`
            opacity: 0.4;
            font-size: 0.7em;
            font-weight: bold;
            text-transform: none;
          `}>
            {title}
          </h6>
        </div>
      }

      {description &&
        <p>{description}</p>
      }

      <div css={css`
        margin-left: -10px;
      `}>
        {children}
      </div>
    </ul>
  )
}
