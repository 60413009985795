import { GlobalCommandType } from "../../GlobalCommandType"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { dispatch } from "../../../../state/store"
import { loadingStatusState } from "../../../../state/loadingStatusSlice"
import { makeAutoDraftFullDocAiCall } from "../../../../ai/prompts/docDrafter/AutoDraftFullDocAiCall"
import { InsertPosition } from "../../../../ai/documents/InsertPosition"
import { writeToDocBodyCommandDefinition } from "./writeToDocBody"


const cmdType = GlobalCommandType.autoDraftFullDoc


/** Attempts to auto-draft the entire document
 *  
 *  TODO: Consider merging `applyFrameworkToDocBody` with this command
 */
export const autoDraftFullDocCommandDefinition: GlobalCommandDefinition<AutoDraftFullDocCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: AutoDraftFullDocCommandArgs) => {
    const toReturn =
      {
        globalCommandType: cmdType,
        name: `Auto-draft entire document`,
        description: '',
        searchName: 'auto draft entire document',
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },
      } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: AutoDraftFullDocCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: true,
      message: "Drafting document ...",
    }))

    const documentOutline = await makeAutoDraftFullDocAiCall(services.getContextForAi)
    console.log("autoDraftFullDoc ", { documentOutline })

    dispatch(loadingStatusState.setDoingAiOnDoc({
      isInProgress: false,
      message: "",
    }))

    writeToDocBodyCommandDefinition.triggerCommand?.({
      documentOutline,
      insertPosition: InsertPosition.startOfDocument,
    })

    args.onComplete?.()
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: AutoDraftFullDocCommandArgs
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type AutoDraftFullDocCommandArgs = {
  shouldReplaceAll?: Boolean
  onComplete?: () => void
}
