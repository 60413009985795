import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"
import { Data } from "@verdi/shared-constants"
import { useEffect, useState } from "react"


type Props = {
  currentDocId: string,
  flatMenuItems: Data.Menu.MenuItemData[]
  shouldShowCurrentDoc?: boolean
}
export const DocBreadcrumb = ({
  currentDocId,
  flatMenuItems,
  shouldShowCurrentDoc,
}: Props) => {

  const [currentDoc, setCurrentDoc] = useState<Data.Menu.MenuItemData | null>(null)
  const [parents, setParents] = useState<Data.Menu.MenuItemData[]>([])
  useEffect(() => {
    if (!currentDocId || !flatMenuItems) {
      return
    }

    const doc = flatMenuItems.find(item => item.id === currentDocId)
    if (doc) {
      setCurrentDoc(doc)
      const parents = Data.Menu.getMenuItemHierarchy(doc, flatMenuItems)
      setParents(parents.reverse())
    }

  }, [currentDocId, flatMenuItems, setCurrentDoc, setParents])


  if (!currentDoc || (!shouldShowCurrentDoc && parents.length === 0)) {
    return <div></div>
  }


  return (
    <div>
      <Breadcrumb

        flexWrap="wrap" // NOT getting applied to generated <ol> ... issue with Chakra?
        fontSize="xs"
        paddingBottom={2}
        marginLeft={-5}
        opacity={0.5}
      >

        {parents.map((parent, index) =>
          <BreadcrumbItem
            key={parent.id}
          >
            <BreadcrumbLink
              margin-bottom={4}
              href={parent.url}
            >
              {parent.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        {shouldShowCurrentDoc && currentDoc &&
          <BreadcrumbItem
            key={"currentDoc"}
          >
            <BreadcrumbLink
              margin-bottom={4}
              href={currentDoc.url}
            >
              {currentDoc.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        }

        {/* <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            fontWeight="bold"
          >
            <DocumentReference
              title={currentDoc.title}
              subTitle="(current)"
            />
          </BreadcrumbLink>
        </BreadcrumbItem> */}

      </Breadcrumb>
    </div>

  )
}
