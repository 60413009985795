import { DocumentContextForAi } from "../../coach/AiCoachPrompts"
import { buildDocContextForPrompt } from "../../promptUtils/buildDocContextForPrompt"


export const getFillInSectionPrompt = (docContext: DocumentContextForAi, companyContext: string, futureSectionTitles: string[]) => {

  const docContextString = buildDocContextForPrompt(docContext, false).detailsForPrompt
  const sectionTitle = docContext.parentSectionInfo?.title

  const prompt = `Given the following context:
  ###
  COMPANY CONTEXT:
  ${companyContext}

  DOCUMENT CONTEXT:
  ${docContextString}

  ${futureSectionTitles.length > 0 ?
      `FUTURE SECTION TITLES (that will be written after this command is completed):\n${futureSectionTitles.join("\n")}
      
      You will have the chance to write content for all the other FUTURE SECTION TITLES listed, 
      so for now, only focus on the one titled ${sectionTitle}.
      ` : ""
    }

  ###

  INSTRUCTIONS: With the given document, write a very concise section for the section titled "${sectionTitle}". 
  Where appropriate, insert placeholders "[]" for numbers, names, or other data that you are not confident in and would like the user to determine.
  Format the content in a quickly digestible way for that section.

  Do not include a title to your response. Be concise. Refer to the examples below.

  EXAMPLE:
  We plan to promote our offering to this target market through a comprehensive mix of digital and traditional marketing channels. This includes leveraging 
  our social media networks, paid search and display advertising, email marketing, content syndication, influencer and referral programs, 
  targeted digital advertising, and targeted public relations. Additionally, our local presence in the top [Number] markets will allow us to better 
  reach businesses in those areas using local media, offline advertising, and targeted virtual events.

  EXAMPLE:
  We will foster strong relationships with our users by providing customer support and service that is tailored to each business's specific needs. 
  We have multiple channels available that customers can use, including 24/7 phone support, email customer service, 
  and access to comprehensive documentation. We are committed to quickly responding to any of our customers' inquiries and providing personalized solutions. 
  In addition, regular surveys and feedback loops help us to ensure customer satisfaction and offer products and services that better meet customer needs.
  `

  return prompt
}
