import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand";
import { TagsChangeSet, TagsProvider } from "../../../../state/TagsProvider";
import { Tag, TagsState } from "../../../../state/TagsSlice";
import { DocTagsState } from "../../../../state/docTagsSlice";
import { getCurrentAppState } from "../../../../state/store";
import { AppServices } from "../../../appServices/useRegisterAppServices";
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition";
import { GlobalCommandType } from "../../GlobalCommandType";
import { triggerGlobalCommand } from "../../triggerGlobalCommand";


const cmdType = GlobalCommandType.setTags


/** Sets the tags associated with a document, aka the `DocumentTags`
 * 
 *  In the future this could be expanded to allow setting tags on other objects
 */
export const setTagsCommandDefinition: GlobalCommandDefinition<SetTagsArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: SetTagsArgs) => {
    const title = args.tag.title
    const toReturn = {
      globalCommandType: cmdType,
      name: title,
      searchName: title.toLowerCase(),
      runCommand: () => {
        triggerGlobalCommand(cmdType, args)
      },
      iconType: args.isCurrentTag ? "currentValue" : undefined,
    } as VerdiCommand

    return toReturn
  },
  processCommand: async (
    args: SetTagsArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {


    const shouldRemoveCurrentTag = args.isCurrentTag
    const tagsToAdd: Tag[] = shouldRemoveCurrentTag ? [] : [args.tag]
    const tagsToRemove: Tag[] = shouldRemoveCurrentTag ? [args.tag] : []

    const docId = args.documentId
    if (docId) {
      const currentDoc = services.menuStructureProvider.getMenuItem(docId)
      if (!currentDoc) {
        console.warn("setTags: Could not find document to set tag")
        onProcessingComplete(false)
        return
      }

      const existingTagsOnThisDoc = DocTagsState.getAllForDoc(getCurrentAppState(), docId)
      const tagIdsToRemove = existingTagsOnThisDoc.map(dt => dt.tagId).filter(id => id !== args.tag.id)
      const allTags = TagsState.getAll(getCurrentAppState())
      const removeTheseTags = allTags.filter(t => t.id && tagIdsToRemove.includes(t.id))
      tagsToRemove.push(...removeTheseTags)

      await TagsProvider.processDocTagChanges(docId, {
        tagsToAdd,
        tagsToRemove,
      })

    }

    args.onTagSelected?.({
      tagsToAdd,
      tagsToRemove,
    })
    onProcessingComplete(true)
  },
  triggerCommand: (
    args: SetTagsArgs,
  ) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type SetTagsArgs = {
  tag: Tag
  /** If specified, will apply the tag to the given document */
  documentId?: string
  onTagSelected?: (tagChanges: TagsChangeSet) => void
  isCurrentTag: boolean
}
