/** Pulls all the query string params from the URL, into a convenient object */
export const useUrlParams = () => {
  const params = new URLSearchParams(window.location.search)
  const object = Object.fromEntries(params)
  return Object.keys(object).reduce((acc, key) => {
    return {
      ...acc,
      [key]: object[key],
    }
  }, {} as Record<string, any>)
}
