import { useCallback } from "react"
import { OpportunityContextForAi } from "../../screens/opportunities/useOpportunityProvider"
import { DocumentContextForAi } from "../coach/AiCoachPrompts"
import { DocContextIncludeArgs } from "../documents/useAiTipTapBridge"
import { ChatRoleType, ApiTypes } from "@verdi/shared-constants"
import { buildOpportunityContextForPrompt } from "./buildOpportunityContextForPrompt"
import { buildDocContextForPrompt } from "./buildDocContextForPrompt"
import { buildDocFrameworkContextForPrompt } from "./buildDocFrameworkContextForPrompt"


type Props = {
  getDocumentContextForAi: (include?: DocContextIncludeArgs) => DocumentContextForAi | undefined,
  getOpportunityContextForAi: () => Promise<OpportunityContextForAi | undefined>
  getCompanyContextForAi: () => Promise<string>
}
// IDEA: Just always keep this stuff updated in redux store, for retrieval without hooks ???
// Try to not use hooks if possible


/** Top level hook that facilitates retrieving various context to be used in any AI prompts */
export const useAllContextForAi = ({
  getDocumentContextForAi,
  getOpportunityContextForAi,
  getCompanyContextForAi,
}: Props) => {


  /** Centralized place to build any context for AI prompts 
   * 
   *  TODO: Eventually move this over to using pure redux state, 
   *  so we can call it from anywhere. We would have to move all sub functions as well, like getDocContextForAi.
  */
  const getContextForAi = useCallback(async (optionArgs?: AiContextConfig) => {

    const options = mergeOptionArgs(optionArgs)

    const currentDoc = options.includeContext?.document?.include ? getDocumentContextForAi(options.includeContext?.document) : undefined
    const currentOpportunity = options.includeContext?.opportunity?.include ? await getOpportunityContextForAi() : undefined
    const companyContext = options.includeContext?.company?.include ? await getCompanyContextForAi() : undefined // getCurrentAppState().companyContext.companyFields

    // Build out the prompt messages
    const messages: ApiTypes.RunAiChatRequestMessage[] = []
    // messages.push({ content: "You are verdi, an AI tool for product managers", role: ChatRoleType.system })
    // IDEA: Inject personas here?

    if (options.includeMessages?.company && companyContext) {
      messages.push({ content: "COMPANY CONTEXT: " + companyContext, role: ChatRoleType.system })
    }

    if (options.includeMessages?.opportunity && currentOpportunity) {
      const currentDocIsTheOpportunity = currentDoc?.id === currentOpportunity?.opportunityMainDocId
      const opportunityString = buildOpportunityContextForPrompt(currentOpportunity, !currentDocIsTheOpportunity)
      messages.push({ content: "OPPORTUNITY CONTEXT: (this is the root context for the current document) " + opportunityString, role: ChatRoleType.system })
    }

    if (options.includeMessages?.documentFramework && currentDoc?.framework) {
      const frameworkMessage = buildDocFrameworkContextForPrompt(currentDoc.framework)
      messages.push({ content: "CURRENT DOCUMENT'S FRAMEWORK CONTEXT: " + frameworkMessage, role: ChatRoleType.system })
    }

    const docOptions = options.includeContext?.document
    if (docOptions && currentDoc) {

      // TODO: wrangle up all the other "Build doc context" functions into this file / folder
      // Find the markdown one and the other ones
      const shouldUseMarkdown = docOptions.bodyAs === "indented-markdown" || docOptions.bodyAs === "indented-markdown-with-caret-position"
      const shouldIncludePlaceholder = docOptions.bodyAs === "indented-markdown-with-caret-position"
      const docString = shouldUseMarkdown
        ? currentDoc.bodyPartsAsMarkdown?.allLines
        : buildDocContextForPrompt(currentDoc, true).detailsForPrompt
      messages.push({
        content: "CURRENT DOCUMENT'S CONTEXT:"
          + (currentDoc.title ? "\nTitle: " + currentDoc.title : "")
          + (docString ? "\nDocument Body:\n" + docString : "")
        , role: ChatRoleType.system
      })
    }

    const allContextToReturn = {
      currentDoc,
      currentOpportunity,
      companyContext,
      promptMessages: messages
    } as AllContextForAi

    console.log("getContextForAi:", { allContextToReturn })

    return allContextToReturn
  }, [getDocumentContextForAi, getOpportunityContextForAi, getCompanyContextForAi])


  return {
    getContextForAi
  }

}


export type AllContextForAi = {
  promptMessages: ApiTypes.RunAiChatRequestMessage[],
  currentDoc: DocumentContextForAi | undefined
  currentOpportunity: OpportunityContextForAi | undefined
  companyContext: string
}

/** Configure all the things that should be included for context to the AI */
export type AiContextConfig = {
  includeContext?: {
    document?: DocContextIncludeArgs & {
      include?: boolean
      ignoreCurrentInsertPos?: boolean
    }
    opportunity?: {
      include?: boolean
      // IDEA: 
    }
    company?: {
      include?: boolean
    },
  }
  includeMessages?: {
    company?: boolean
    opportunity?: boolean
    documentFramework?: boolean
    document?: boolean
  }

}

/** Most AI Calls will use these defaults */
const defaultAiContextConfig: AiContextConfig = {
  includeContext: {
    company: { include: true },
    opportunity: { include: true },
    document: {
      include: true,
      ignoreCurrentInsertPos: true,
      bodyAs: "indented-markdown",
      sectionTitles: false,
    },
  },
  includeMessages: {
    company: true,
    opportunity: true,
    documentFramework: true,
    document: true
  }
}


/** Applies any specified properties on top of the default options */
const mergeOptionArgs = (optionArgs?: AiContextConfig) => {

  if (!optionArgs) return defaultAiContextConfig

  const mergedOptions: AiContextConfig = {
    ...defaultAiContextConfig,
    includeContext: {
      document: {
        ...defaultAiContextConfig.includeContext?.document,
        ...optionArgs?.includeContext?.document
      },
      company: {
        ...defaultAiContextConfig.includeContext?.company,
        ...optionArgs?.includeContext?.company
      },
      opportunity: {
        ...defaultAiContextConfig.includeContext?.opportunity,
        ...optionArgs?.includeContext?.opportunity
      }
    },
    includeMessages: {
      ...defaultAiContextConfig.includeMessages,
      ...optionArgs?.includeMessages,
    }
  }

  return mergedOptions
}
