import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../../utility-hooks/useErrorHandledMutation';
import { UseMutationConfig } from "react-relay";
import { MenuStructureForAllDocsProvider } from '../../document/organize/useGetMenuStructureForAllDocs';
import { CreateDocumentInput, useAddNewDocumentAppendMutation, useAddNewDocumentAppendMutation$data } from './__generated__/useAddNewDocumentAppendMutation.graphql';
import { useAddNewDocumentPrependMutation } from './__generated__/useAddNewDocumentPrependMutation.graphql';


graphql`
  fragment useAddNewDocumentFields on Document @relay(mask: false){
    id
    title 
    description 
    type
    parentDoc {
      id
    }
    opportunities{
      ...OpportunityDocumentSideMenu_opportunity
    }
    templateInfo @appendNode(connections: $updateDocumentTemplateConnectionIds, edgeTypeName: "QueryDocumentTemplateEdge") {
      id
      ...DocumentTemplateSideMenuItem_documentTemplate
    }
  }
`



const prependMutationQL = graphql`
  mutation useAddNewDocumentPrependMutation (
    $input: CreateDocumentInput!
    $updateConnectionIds: [ID!]!
    $updateDocumentTemplateConnectionIds: [ID!]!
  ) {
   createDocument(input: $input)
    @prependNode(connections: $updateConnectionIds, edgeTypeName: "DocumentEdge")
   {
    id
     ...useAddNewDocumentFields @relay(mask: false)
   }    
  }
`
const appendMutationQL = graphql`
  mutation useAddNewDocumentAppendMutation (
    $input: CreateDocumentInput!
    $updateConnectionIds: [ID!]!
    $updateDocumentTemplateConnectionIds: [ID!]!
  ) {
   createDocument(input: $input)
    @appendNode(connections: $updateConnectionIds, edgeTypeName: "DocumentEdge")
   {
     id
     ...useAddNewDocumentFields @relay(mask: false)
   }    
  }
`

// onCreated?: (document: useAddNewDocumentAppendMutation$data['createDocument']) => void

export type OnNewDocumentMutationCompleted = UseMutationConfig<useAddNewDocumentAppendMutation | useAddNewDocumentPrependMutation>['onCompleted']

export function useNewDocumentMutation(
  navigateOnSave = true,
  updateConnectionIds: string[] = [],
  onCompleted?: OnNewDocumentMutationCompleted,
  updateDocumentTemplateConnectionIds: string[] = [],
) {

  const [prependCommit, prependIsSaving] = useErrorHandledMutation<useAddNewDocumentAppendMutation>(prependMutationQL)
  const [appendCommit, appendIsSaving] = useErrorHandledMutation<useAddNewDocumentPrependMutation>(appendMutationQL)
  const isSaving = prependIsSaving || appendIsSaving

  const createDocument = useCallback((
    input: CreateDocumentInput,
    menuStructureProvider: MenuStructureForAllDocsProvider,
    onCreateCompleted?: (response: useAddNewDocumentAppendMutation$data) => void
  ) => {
    console.log("createDocument INPUT = ", input)
    const commit = input.type !== "quickNote" ? appendCommit : prependCommit
    commit({
      variables: {
        input,
        updateConnectionIds,
        updateDocumentTemplateConnectionIds,
      },
      onCompleted: (response, errors) => {
        const docId = response.createDocument?.id
        console.info("useNewDocumentMutation onCompleted", response)
        if (docId) {
          console.info("Calling onDocumentCreated: menuStructureProvider = ", menuStructureProvider)
          menuStructureProvider?.onDocumentCreated(docId)
            .then(() => {
              console.info("Did the local memory thing. Now calling onCompleted")
              if (onCreateCompleted) {
                onCreateCompleted?.(response)
              } else {
                onCompleted?.(response, errors)
              }
              if (response?.createDocument?.id && navigateOnSave) {
                window.location.href = `document/${response.createDocument.id}`
              }
            }).catch((error: any) => {
              console.error("Failed to update local memory", error)
            }).finally(() => {
              console.log("finally")
            })
        }
      }
    })
  }, [
    updateConnectionIds,
    prependCommit,
    appendCommit
  ])
  return [createDocument, isSaving] as const
}

export type UseAddNewDocumentMutation = ReturnType<typeof useNewDocumentMutation>[0]
