import { Mark as PmMark } from "prosemirror-model";
import { Mark, MarkConfig } from "@tiptap/core";
import { AiSuggestionMarkAttributes } from "./AiSuggestion.mark";
import { VerdiMarkType } from "./VerdiMarksCommon";
import { DocumentSchema } from "../..";


const KEY = VerdiMarkType.PlaceholderInline;

export type PlaceholderInlineMarkType = {
  type: typeof KEY;
  attrs: AiSuggestionMarkAttributes;
};

export type PlaceholderInlineMarkAttributes = AiSuggestionMarkAttributes & {
  placeholderText: string;
}

const attributes: PlaceholderInlineMarkAttributes = {
  placeholderText: "",
  suggestionText: "",
  commands: [],
};


const config: MarkConfig = {
  name: KEY,
  addAttributes() {
    return attributes;
  },
  renderHTML(props) {

    const element = document.createElement("span");

    element.style.backgroundColor = "rgba(160, 201, 255, 0.3)";
    element.style.padding = "2px";
    element.style.borderRadius = "4px";
    element.setAttribute("ve-mark-type", KEY);
    // If using mark watcher, pack the attributes here, for later unpacking
    element.setAttribute("ve-mark-attrs", JSON.stringify(props.mark.attrs)); // Good for debugging

    element.addEventListener("click", (event: MouseEvent) => {
      if (!event.target || !window) return

      const attrs = props.mark.attrs
      const newEvent = new CustomEvent(MARK_CLICKED_EVENT_KEY, {
        detail: {
          rect: (event.target as HTMLElement).getBoundingClientRect(),
          attributes: attrs,
          markType: KEY,
        }
      })

      window.dispatchEvent(newEvent)
    })

    return element;
  },
};

export const MARK_CLICKED_EVENT_KEY = "MARK_CLICKED_EVENT_KEY"

export const PlaceholderInlineMarkInstance = Mark.create(config);

export const createNewPlaceholderInlineMark = (attributes: PlaceholderInlineMarkAttributes): PlaceholderInlineMarkType => {
  const markJson: PlaceholderInlineMarkType = {
    type: KEY,
    attrs: attributes,
  } as const;

  return markJson
}
