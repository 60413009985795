import { useColorModeValue } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { SuspenseErrorBoundary } from "../SuspenseErrorBoundary";
import { menuBorderColors } from "../ColorModeValues";
import { UserInfoBadge } from "../UserInfoBadge";
import { TopLeftMenu } from "./TopLeftMenu";
import { LightDismissalOverlay } from "./LightDismissalOverlay";
import { mediaQuerySmallWidth } from "./styleHelpers";
import { ToggleChatButton } from "./ToggleChatButton";
import { useEffect } from "react";
import { StartOnboardingButton } from "../../screens/onboarding/StartOnboardingButton";
import { RightSidePanel } from "./RightSidePanel";
import { AppServices } from "../appServices/useRegisterAppServices";
import { useColor } from "../../utility-hooks/useColor";
import { NagUserAboutPlan } from "../../screens/organization/plansAndBilling/NagUserAboutPlan";


export const leftMenuWidth = 280
export const topBarHeight = 50
const chatWidth = 280


type Props = {
  mainContent: React.ReactNode
  mainMenu: React.ReactNode
  rightSideContent?: React.ReactNode
  appServices: AppServices
  rightSideInitialState?: "hidden" | "visible" | "fullscreen"
  preventScrollAtTopLevel?: boolean
}

export function PageLayoutSplitViewWrapper({
  mainContent,
  mainMenu,
  rightSideContent,
  appServices,
  rightSideInitialState,
  preventScrollAtTopLevel,
}: Props) {

  const menuBackgroundColor = useColor("surfaceBg")
  const borderColor = useColorModeValue(menuBorderColors.light, menuBorderColors.dark)
  const rightSideOverlayBackgroundColor = menuBackgroundColor //useColorModeValue("#F9F9F9", "#000000EE")

  const {
    menuIsExpanded,
    rightSideState,
    screenWidthIsSmall,
    setMenuIsExpanded,
    setRightSideState,
    dismissBothMenus,
  } = appServices.pageLayoutState

  useEffect(() => {
    if (rightSideInitialState && !screenWidthIsSmall) {
      setRightSideState(rightSideInitialState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightSideInitialState])


  return (
    <div css={css`
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        background-color: ${menuBackgroundColor};
      `}>

      {/* Top Bar */}

      <div css={css`
        height: ${topBarHeight}px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        position: relative;
        border-bottom: 1px solid ${borderColor};
        width: ${menuIsExpanded && !screenWidthIsSmall ? `calc(100% - ${leftMenuWidth}px)` : "100%"};
        margin-left: ${(menuIsExpanded && !screenWidthIsSmall) ? `${leftMenuWidth}px` : "0"};
        @media ${mediaQuerySmallWidth} {
            z-index: 1;
            width: 100%;
          }
      `}>

        <div css={css`
          width: 150px;
        `}>
          {!menuIsExpanded &&
            <TopLeftMenu
              router={appServices.router}
              menuIsExpanded={false}
              toggleMenuExpand={() => setMenuIsExpanded(!menuIsExpanded)}
            />
          }
        </div>

        <NagUserAboutPlan />

        <div css={css`
          right: 5px;
          display: flex;
        `}>

          {/* hide this for now, with the mighty power of opacity! */}
          <div css={css`opacity: 0.2;`}>
            <StartOnboardingButton />
          </div>

          {/* <NewQuickNoteButton displayAs="iconOnly" /> */}

          <ToggleChatButton
            isShowingChat={rightSideState !== "hidden"}
            toggleChat={() => {
              if (rightSideState === "hidden") {
                setRightSideState(rightSideInitialState === "fullscreen" ? "fullscreen" : "visible")
              } else {
                setRightSideState("hidden")
              }
            }} />

          <UserInfoBadge />

        </div>

      </div>


      {/* Left side nav */}

      {menuIsExpanded &&
        <div css={css`
          height: calc(100vh - ${screenWidthIsSmall ? "10px" : "0px"});
          min-height: 300px;
          width: ${leftMenuWidth}px;
          position: fixed;
          top: 0;
          border-right: 1px solid ${borderColor};
          @media ${mediaQuerySmallWidth} {
            z-index: 1;
            width: 100%;
          }
        `}>

          {screenWidthIsSmall &&
            <LightDismissalOverlay onClick={() => dismissBothMenus()} />
          }

          <div css={css`
          padding: 8px 11px;
          min-width: 200px;
          `}>
            <TopLeftMenu
              menuIsExpanded={true}
              toggleMenuExpand={() => setMenuIsExpanded(!menuIsExpanded)}
              router={appServices.router}
            />
          </div>

          <div css={css`
            background-color: ${menuBackgroundColor};
             @media ${mediaQuerySmallWidth} {
              position: fixed;
              padding: 11px;
              border-radius: 10px;
              margin-left: 10px;
              box-shadow: var(--chakra-shadows-dark-lg);
            }
          `}>
            <div css={css`
              width: ${leftMenuWidth}px;
              height: calc(100vh - 120px);
              overflow-y: auto;
              padding-top: 10px;
              @media ${mediaQuerySmallWidth} {
                height: calc(100vh - 200px);
              }
              animation: 0.5s slideIn;
              @keyframes slideIn {
                0% { opacity: 0; }
                100% { opacity: 1; }
              }
            `}>

              <SuspenseErrorBoundary>
                {mainMenu}
              </SuspenseErrorBoundary>

            </div>
          </div>

          {/* <div css={css`
              height: 50px;
            `}>
            {
              // Menu footer
              <div></div>
            }
          </div> */}
        </div>
      }


      <div css={css`
        width: 100%;  
        display: flex;
          flex-direction: row;
          padding: 0;
        `}>


        {/* main content */}
        <div css={css`
          width: calc(100% - ${rightSideState === "visible" && !screenWidthIsSmall ? `${chatWidth}px` : "0px"} );
          min-width: 200px;
          display: flex;
          flex-direction: column;
          padding: 0; /** <- Changing this may effect floating block context positioning */
          ${preventScrollAtTopLevel ? "height: calc(100vh - 100px);" : ""}
        `}>

          <div css={css`
            container: mainStage / inline-size; /** Allows for better media query control. use via: @container mainStage ${mediaQuerySmallWidth} { ... } */
            text-align: left;
            min-height: 90%;
            display: flex; 
            flex-direction: column;
            padding: 0;
            margin-top: 0;
            margin-left: ${(menuIsExpanded && !screenWidthIsSmall) ? "280px" : "0"};
          `}>
            <SuspenseErrorBoundary>
              <div css={css`
                margin-top: 0;
                width: 100%;
              `}>
                {mainContent}
              </div>
            </SuspenseErrorBoundary>
          </div>

          <footer css={css`
            margin-top: 40px;
          `}>
          </footer>
        </div>

        {screenWidthIsSmall && rightSideState === "visible" &&
          <LightDismissalOverlay onClick={() => dismissBothMenus()} />
        }

        <>
          {/* Right Side Chat */}


          <div css={css`
            height: calc(100vh - 60px);
            background-color: ${rightSideOverlayBackgroundColor};
            border-left: ${rightSideState === "fullscreen" ? "none" : `1px solid ${borderColor}`};
            display: flex;
            justify-content: center;
            align-items: baseline;
            padding-top: 0;
            max-width: 1200px;
            /* margin-top: 100px; // TODO JUST FOR NOW */
            ${rightSideState === "fullscreen" ? `
              position: fixed;
              width: ${screenWidthIsSmall ? "100%" : `calc(100vw - ${menuIsExpanded ? `${chatWidth}px` : "20px"} - 30px)`};
              left: ${menuIsExpanded ? `${chatWidth + 10}px` : "20px"};
            ` : ""}
            ${rightSideState === "hidden" ? `
              position: inherit;
              display: none;
              width: 0;
              ` : ""}
            ${rightSideState === "visible" ? `
              position: inherit;
              width: ${chatWidth + 20}px;
            ` : ""}
            ${screenWidthIsSmall ? `
              border-radius: 10px;
              margin-right: 10px;
            ` : ""}
            position: fixed;
            right: 0;
            top: 50px;
          `}>
            <div css={css`
              opacity: 1;
              flex: 1;
              width: 100%;
              height: 100%;
              max-width: 800px;
            `}>
              {rightSideContent ||
                <RightSidePanel
                  appServices={appServices}
                />
              }
            </div>
          </div>


        </>


      </div>

    </div>
  )
}

