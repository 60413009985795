import { Extension, NodeRange } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Node } from 'prosemirror-model'
import { DocumentSchema } from '@verdi/shared-constants'

/**
 * Helper for iterating through the nodes in a document that changed compared 
 * to the given previous document. Useful for avoiding duplicate work on each transaction.
 * Source: https://gist.github.com/BrianHung/3b5f9b2fe97a353150324a19250a5257
 */
export function changedDescendants(old: Node, cur: Node, offset: number,
  f: (node: Node, pos: number) => void) {
  let oldSize = old.childCount, curSize = cur.childCount
  outer: for (let i = 0, j = 0; i < curSize; i++) {
    let child = cur.child(i)
    for (let scan = j, e = Math.min(oldSize, i + 3); scan < e; scan++) {
      if (old.child(scan) == child) {
        j = scan + 1
        offset += child.nodeSize
        continue outer
      }
    }
    f(child, offset)
    if (j < oldSize && old.child(j).sameMarkup(child))
      changedDescendants(old.child(j), child, offset + 1, f);
    else
      child.nodesBetween(0, child.content.size, f, offset + 1);
    offset += child.nodeSize;
  }
}

const DOM_TO_DATA_DEPTH_RATIO = 2

export const NestingLimit = Extension.create({
  name: 'NestingLimit',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('NestingLimit'),
        filterTransaction: (tr, state) => {
          // TODO: get this working!!!
          // this is causing all kinds of issues right now
          return true
          let transactionIsValid = true
          changedDescendants(state.doc, tr.doc, 0, (node, pos) => {
            try {
              const resolvedPos = tr.doc.resolve(pos)
              const depth = resolvedPos.depth
              if (depth / DOM_TO_DATA_DEPTH_RATIO > DocumentSchema.GLOBAL_MAX_NESTING_LEVEL) {
                transactionIsValid = false
              }
            } finally { }
          })
          return transactionIsValid
        },
      }),
    ]
  },
})
