import { Modal, ModalContent, ModalOverlay, Textarea } from "@chakra-ui/react"
import { css } from "@emotion/react"
import { useAppServices } from "../../appServices/useAppServices"
import { useCallback, useMemo, useState } from "react"
import { positionTheCommandMenu } from "../miniCommandMenu/positionTheCommandMenu"
import { useKeyDownHandler } from "../../floatingBlockContext/useKeyDownHandler"
import { useColor } from "../../../utility-hooks/useColor"
import { ButtonPrimary } from "../../buttons/ButtonPrimary"
import { ButtonSecondary } from "../../buttons/ButtonSecondary"
import { useCommandMenuWH } from "../miniCommandMenu/useCommandMenuWH"
import { CustomPromptAiCallOptions } from "../../../ai/prompts/docDrafter/CustomPromptOnDocAiCall"
import { VerdiIconAiSuggestions } from "../../icons/VerdiIcons"
import { IconWrapper } from "../../icons/IconWrapper"


/** Allows adding a new document via the Enchiridion, from anywhere in the app */
export const CustomAIPromptModal = () => {

  const { customAIPromptModalProvider } = useAppServices()
  const {
    customPromptArgs: args,
    closeCustomPrompt,
    submitCustomPrompt,
    customPromptIsOpen,
  } = customAIPromptModalProvider


  const [promptText, setPromptText] = useState('')


  const handleClose = useCallback(() => {
    setPromptText('')
    closeCustomPrompt()
  }, [closeCustomPrompt, setPromptText])


  const handleSubmit = useCallback(() => {
    const promptOptions: CustomPromptAiCallOptions = {
      shouldAddNewDocPlaceholders: false,
    }
    submitCustomPrompt(promptText, promptOptions)
    handleClose()
  }, [promptText, submitCustomPrompt, handleClose])

  const menuBgColor = useColor("surfaceBg")
  const borderColor = useColor("surfaceBorder")


  useKeyDownHandler((keyboardEvent) => {
    if (keyboardEvent.key === "Escape") {
      handleClose()
    }
  })

  const commandMenuWH = useCommandMenuWH()

  const position = useMemo(() => {
    const rect = args?.rectOfAnchorElement
    return positionTheCommandMenu(commandMenuWH, rect, false)
  }, [args?.rectOfAnchorElement, commandMenuWH])


  if (!args) return null


  return (
    <Modal
      onClose={() => handleClose()}
      isOpen={customPromptIsOpen}
    >
      <ModalOverlay />
      <ModalContent
        css={css`
          position: absolute;
          top: ${position.top}px;
          left: ${position.left}px;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: ${commandMenuWH.width}px;
          max-height: ${commandMenuWH.height}px;
          background-color: ${menuBgColor};
          padding: 8px;
          border: 1px solid ${borderColor};
          border-radius: 6px;
          overflow-y: hidden;
          box-shadow: 1px 1px 8px 4px rgba(0,0,0,0.1);
          margin-top: 0px;
        `}
        onClick={(evt) => evt.stopPropagation()}
      >


        {args.title &&
          <h2 css={css`
            padding-bottom: 8px;
            font-size: 1em;
            font-weight: bold;
            text-overflow: ellipsis;
          `}>
            <IconWrapper
              icon={<VerdiIconAiSuggestions />}
            />
            <span >
              {args.title}
            </span>
          </h2>
        }

        {args.subTitle &&
          <div css={css`
            padding: 0 12px 8px;
            font-size: 0.8em;
          `}>
            {args.subTitle}
          </div>
        }
        <Textarea
          resize="none"
          minHeight="unset"
          overflowY="auto"
          width="100%"
          placeholder="Write a custom prompt for verdi..."
          rows={5}
          value={promptText}
          onChange={(event) => { setPromptText(event.currentTarget.value) }}
        />
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: end;
            margin-top: 8px;
            gap: 8px;
          `}
        >
          <ButtonSecondary
            label={"Cancel"}
            onClick={handleClose}
          />
          <ButtonPrimary
            label={"Submit"}
            onClick={handleSubmit}
          />
        </div>
      </ModalContent>
    </Modal>
  )
}
