import { DocumentSchema } from "@verdi/shared-constants"
import { useEffect } from "react"
import { OpenMenuOptions } from "../../screens/document/tiptapEditor/commandMenu/CommandMenuWrapper"
import { MarkResolved } from "../../screens/document/tiptapEditor/utils/findCurrentMarkDetails"
import { CommandListLimitTo } from "../commands/commandMenuData/CommandListLimitTo"
import { loadCurrentMarkSuggestionsCommandDefinition } from "../commands/commandDefinitions/documentBody/inlineDocSuggestions/loadCurrentMarkSuggestions"
import { docBodyMarkClearCurrentCommandDefinition } from "../commands/commandDefinitions/documentBody/inlineDocSuggestions/docBodyMarkClearCurrent"


// IDEA: Make this NOT a hook, and have a single place to add the event listeners
type Props = {
  openMenu: (options: OpenMenuOptions) => void
  currentMark: MarkResolved | undefined
}
/** Handles extracting commands and positioning the CM when caret is on a node with a mark */
export const useHandleMarkClicked = ({
  openMenu,
  currentMark,
}: Props) => {

  // const [currentMarkDomRect, setCurrentMarkDomRect] = useState<DomPositionRect | undefined>(undefined)

  const handleMarkClicked = async (event: Event) => {
    const eventDetails = (event as CustomEvent).detail
    const rect = eventDetails?.rect as DOMRect
    const markType = eventDetails?.markType as DocumentSchema.VerdiMarks.VerdiMarkType
    const attributes = eventDetails?.attributes as DocumentSchema.VerdiMarks.AiSuggestionMarkAttributes
    const textWithinMark = currentMark?.node.node.text || ""

    console.log("useCurrentMarkState: handleMarkClicked", { eventDetails, currentMark, textWithinMark, markType, attributes, rect })

    if (!rect || !attributes) {
      console.warn("Did not receive rect or attributes", { event })
      return
    }


    // if (markType === DocumentSchema.VerdiMarks.VerdiMarkType.PlaceholderInline
    //   && currentMark
    //   && editor?.state.selection.empty) {

    //   // NOTE: This may trigger the selection command menu to open
    //   editor.view.dispatch(editor.state.tr.setSelection(TextSelection.create(editor.state.doc, currentMark.from, currentMark.to)))
    //   // NOTE: We prevent _this_ current function from opening the menu; 
    //   // however, There may be logic elsewhere that opens the menu on ANY text selection 
    //   // Such as within CommandMenuWrapper.tsx, entirePlaceholderIsSelected 
    //   shouldOpenMenu = false
    // }

    if (markType === DocumentSchema.VerdiMarks.VerdiMarkType.AiEdited) {
      docBodyMarkClearCurrentCommandDefinition.triggerCommand?.({})
      return
    }

    if (markType === DocumentSchema.VerdiMarks.VerdiMarkType.AiSuggestion) {
      loadCurrentMarkSuggestionsCommandDefinition.triggerCommand?.({
        commandTitle: "",
        lifecycleState: "load-new",
        shouldAbortIfAlreadyLoaded: true,
      })
    }

    openMenu({ limitTo: CommandListLimitTo.showLocalOnly })
  }


  useEffect(() => {
    const markClickedEventKey = DocumentSchema.VerdiMarks.MARK_CLICKED_EVENT_KEY
    window.addEventListener(markClickedEventKey, handleMarkClicked)
    return () => {
      window.removeEventListener(markClickedEventKey, handleMarkClicked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

}
