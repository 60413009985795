import { VerdiCommand, VerdiCommandGroup } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { autoDraftFullDocConfirmCommandDefinition } from "../../commandDefinitions/documentBody/autoDraftFullDocConfirm"
import { getAddFrameworkCommandIfMissing } from "./useFrameworkSuggestionCommands"
import { BasicDocumentDetails } from "../../../../screens/document/BasicDocumentDetails"


type Props = {
  document: BasicDocumentDetails
  docIsEmpty: boolean
  hasFramework: boolean
}

export const getCmdsForBlankDoc = ({
  document,
  docIsEmpty,
  hasFramework,
}: Props): VerdiCommandGroup[] => {

  if (!docIsEmpty) return []

  const commands: VerdiCommand[] = []
  const showAddFrameworkCommand = getAddFrameworkCommandIfMissing(document, hasFramework)
  if (showAddFrameworkCommand) {
    commands.push(showAddFrameworkCommand)
  }
  const autoDraftCmd = autoDraftFullDocConfirmCommandDefinition.buildMenuItem({})
  commands.push(autoDraftCmd)

  return [
    {
      title: "Let's get started ...",
      commands,
    }
  ]
}
