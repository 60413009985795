import { Data } from "@verdi/shared-constants"
import { makeGetRequestJson, makePostRequestJson } from "../utility-hooks/fetchUtils"
import { dispatch } from "./store"
import { DocTagsState } from "./docTagsSlice"
import { Tag, TagsState } from "./TagsSlice"


const loadAllTagsForOrg = async () => {
  const results = await makeGetRequestJson("documentTagsForOrg") as Data.DocumentTags.DocumentTagsForOrgResponseBody

  dispatch(DocTagsState.setAll(results.docTags))
  dispatch(TagsState.setAllIncludeDefaults(results.tags))
}


const addDocTag = async (docId: string, tag: Tag) => {
  console.log("addDocTag", docId, tag)

  let tagId = tag.id
  if (!tagId) {
    // Try to match existing tag based on title???

    // ensure tag is created in the DB
    const newTag = await addTag(tag)
    tagId = newTag.id
  }

  const body: Data.DocumentTags.DocumentTagDto = {
    docId,
    tagId
  }
  dispatch(DocTagsState.add(body))
  await makePostRequestJson(`documentTagAdd`, body)
}


const removeDocTag = async (docId: string, tagId: string) => {
  const body: Data.DocumentTags.DocumentTagDto = {
    docId,
    tagId
  }
  dispatch(DocTagsState.remove(body))
  await makePostRequestJson(`documentTagRemove`, body)
}


/** Convenience function that sorts out what things need to be done when DocumentTags change */
const processDocTagChanges = async (docId: string, tagChanges: TagsChangeSet) => {
  console.log("processDocTagChanges", docId, tagChanges)
  const { tagsToAdd, tagsToRemove } = tagChanges

  if (tagsToRemove) {
    for (const tag of tagsToRemove) {
      const tagId = tag.id
      if (!tagId) {
        console.warn("processDocTagChanges: removeDocTag: tagId is null", { tagId })
        return
      }
      removeDocTag(docId, tagId)
    }
  }

  if (tagsToAdd) {
    for (const tag of tagsToAdd) {
      await addDocTag(docId, tag)
    }
  }
}


const addTag = async (tag: Tag) => {
  const body: Data.Tags.addTagIfNotExistsRequestBody = {
    title: tag.title
  }
  const results: Data.Tags.TagDto = await makePostRequestJson("AddTagIfNotExists", body)
  dispatch(TagsState.addTag(results))
  return results
}

const removeTag = async (tag: Tag, hasConfirmed: boolean) => {
  if (!hasConfirmed) return
  const tagId = tag.id
  if (!tagId) {
    console.warn("removeTag: tagId is null. Did not delete", { tag })
    return
  }
  await makePostRequestJson(`RemoveTag`, tag)
  dispatch(TagsState.removeTag({ tagId: tagId }))
  dispatch(DocTagsState.removeAllForTag({ tagId }))
}


export const TagsProvider = {
  loadAllTagsForOrg,
  addDocTag,
  removeDocTag,
  addTag,
  /** Removes a Tag and potentially all it's related M:M data (aka DocumentTags) */
  removeTag,
  processDocTagChanges,
}


export type TagsChangeSet = {
  tagsToAdd?: Tag[]
  tagsToRemove?: Tag[]
}
