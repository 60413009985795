import {
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import { useRouter } from 'found';
import { useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react'
import { getUrlForDocument } from '../../../routes';
import { ButtonPrimary } from '../../../components/buttons/ButtonPrimary';
import { ButtonSecondary } from '../../../components/buttons/ButtonSecondary';
import { useAppServices } from '../../../components/appServices/useAppServices';
import { Data, DocumentSchema } from '@verdi/shared-constants';
import { VerdiIconAdd } from '../../../components/icons/VerdiIcons';
import { IconButtonTertiary } from '../../../components/buttons/IconButtonTertiary';


type Props = {
  opportunityId: string
  guideId: string
  guideTitle: string
  shouldNavigateToNewDoc: boolean
  addButtonText?: string
}
export function NewInterviewNotesModal({
  guideId,
  guideTitle,
  opportunityId,
  shouldNavigateToNewDoc,
  addButtonText,
}: Props) {

  const appServices = useAppServices()

  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const modalBackground = useColorModeValue('#FFF', '#181818');

  const onCompleted = (newDocId: string) => {
    if (!newDocId) {
      return
    }

    if (shouldNavigateToNewDoc) {
      const url = getUrlForDocument(newDocId, opportunityId)
      router.router.push(url)
    }
    onClose()
    setIsSaving(false)
  }

  const [isSaving, setIsSaving] = useState(false)
  const createInterviewNotesDocument = () => {
    setIsSaving(true)
    appServices.documentRelationsProvider.createNewDocWithRelation({
      currentDocId: guideId,
      direction: "currentDocIsTo",
      newDocTitle: title,
      relationType: Data.DocumentRelationType.actionOf,
      newDocType: DocumentSchema.DocumentType.interviewNotes,
      parentDocId: guideId,
      opportunityId: opportunityId || undefined,
      createFromDocId: guideId,
      newDocDescription: description,
      onError: (errorMessage) => {
        console.error("createInterviewNotesDocument.onError", errorMessage)
        setIsSaving(false)
      },
      onComplete: onCompleted,
    })
  }


  return (
    <>
      {addButtonText ?
        <IconButtonTertiary
          aria-label={addButtonText}
          onClick={onOpen}
          icon={<VerdiIconAdd />}
        />
        :

        <IconButton
          aria-label="Add new Interview Notes"
          title="Add Interview"
          variant="ghost"
          size="sm"
          icon={<VerdiIconAdd />}
          onClick={onOpen}
        />
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent background={modalBackground}>
          <ModalHeader>
            <h2>
              Add Interview Notes
            </h2>
            {/* <small css={css`
              opacity: 0.5;
            `}>
              {guideTitle}
            </small> */}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={3}>

              <Input
                value={title}
                onChange={(evt) => setTitle(evt.target.value)}
                placeholder='Name this interview'
              />

              {/* <Textarea
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
                placeholder='Optionally describe who you will be interviewing'
                size='lg'
                height={200}
              /> */}

            </Stack>
          </ModalBody>

          <ModalFooter>

            <ButtonSecondary
              label="Cancel"
              marginRight={3}
              onClick={onClose}
            />

            <ButtonPrimary
              label="Save"
              isLoading={isSaving}
              onClick={createInterviewNotesDocument}
              disabled={!title.length}
            />

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
