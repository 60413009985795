import graphql from 'babel-plugin-relay/macro'
import { useCallback } from 'react';
import { useErrorHandledMutation } from '../../utility-hooks/useErrorHandledMutation';
import { useUpdateDocumentMutation } from './__generated__/useUpdateDocumentMutation.graphql';
import { MenuStructureForAllDocsProvider } from './organize/useGetMenuStructureForAllDocs';
import { DocumentSchema } from '@verdi/shared-constants';


const mutationQL = graphql`
  mutation useUpdateDocumentMutation (
    $input: UpdateDocumentInput!
  ) @raw_response_type {
    updateDocument(input: $input){
      id
      title
      description
      isVisibleToOrg
      type
      parentDoc {
        id
      }
    }
  }
`
export type DocumentMutableFields = {
  title?: string | undefined
  description?: string | undefined
  isVisibleToOrg?: boolean
  parentDocId?: string | null
  type?: DocumentSchema.DocumentType
}

type Props = {
  menuStructureProvider: MenuStructureForAllDocsProvider
}
export function useUpdateDocument({
  menuStructureProvider,
}: Props) {

  const [saveDocument, isSavingDocument] = useErrorHandledMutation<useUpdateDocumentMutation>(mutationQL)
  const updateDocument = useCallback((
    documentId: string,
    fields: DocumentMutableFields,
    // onCompleted?: () => void,
  ) => {

    // Eagerly update local state
    menuStructureProvider.onDocumentUpdated(documentId, fields)

    // Save to the DB
    saveDocument({
      variables: {
        input: {
          ...fields,
          documentId,
        },
      },
      // onCompleted: (response, errors) => {
      //   console.log("useUpdateDocumentMutation: onCompleted ", { response, appServices })
      //   // const docId = response.updateDocument?.id
      //   // if (docId) {
      //   //   const { id, title, description, isVisibleToOrg } = response.updateDocument
      //   //   const fields: documentUpdateFields = {
      //   //     title: title || undefined,
      //   //     description: description || undefined,
      //   //     isVisibleToOrg: isVisibleToOrg || undefined,
      //   //   }
      //   //   // if (onCompleted) {
      //   //   //   onCompleted?.()
      //   //   // }

      //   // }
      // }
    })
  }, [saveDocument, menuStructureProvider])

  return {
    updateDocument,
    isUpdatingDoc: isSavingDocument,
  } as const
}


export type documentUpdateFields = {
  title?: string
  description?: string
  isVisibleToOrg?: boolean
}
