import { Fragment, useCallback, useEffect, useState } from "react"
import { AdminReportsProvider } from "./useAdminReportsProvider"
import { css } from "@emotion/react"
import { Button, Table, Tbody, Td, Textarea, Tr } from "@chakra-ui/react"
import { ButtonPrimary } from "../../../components/buttons/ButtonPrimary"
import { ButtonSecondary } from "../../../components/buttons/ButtonSecondary"
import { VerdiIconRefresh } from "../../../components/icons/VerdiIcons"
import { AiRequestDebugger } from "./AiRequestDebugger"


type Props = {
  adminReportsProvider: AdminReportsProvider
}
export const AiGenerationLogEntriesReport = ({ adminReportsProvider }: Props) => {

  const [aiGenLogEntries, setAiGenLogEntries] = useState<AiLogEntry[]>([])
  useEffect(() => {
    getAiGenLogEntries()
  }, [])

  const getAiGenLogEntries = useCallback(async (amountToLoad: number = 10) => {
    const response = await adminReportsProvider.getRecentAiGenerationLogEntriesReport(amountToLoad) as RawEntry[]
    const mapped = response.map(fromDto)
    setAiGenLogEntries(mapped)
  }, [adminReportsProvider.getRecentAiGenerationLogEntriesReport, setAiGenLogEntries])


  return (
    <div css={css`
      font-size: small;
    `}>
      <div css={css`
        margin-top: 20px;
      `}>
        <ButtonPrimary
          label="refresh"
          onClick={() => getAiGenLogEntries()}
          isLoading={adminReportsProvider.isGettingLogEntries}
          leftIcon={<VerdiIconRefresh />}
          marginRight={2}
        />
        <ButtonSecondary
          label="Load 100"
          onClick={() => getAiGenLogEntries(100)}
          isLoading={adminReportsProvider.isGettingLogEntries}
          marginRight={2}
        />
      </div>
      <Table
        opacity={adminReportsProvider.isGettingLogEntries ? 0.2 : 1}
        css={css`
          margin-top: 20px;
          tr > td {
            padding: 2px;
            vertical-align: top;
          }
          textarea {
            font-size: small;
            min-height: 100px;
            min-width: 200px;
          }
      `}>
        <Tbody>
          {aiGenLogEntries.map((e, key) =>
            <Fragment key={key}>
              <Tr>
                <Td width={300}>
                  <Table marginBottom={10}>
                    <Tbody>
                      <Tr><Td><strong>model Name: </strong></Td><Td><span>{e.modelName}</span></Td></Tr>
                      <Tr><Td><strong>created At: </strong></Td><Td><span>{e.createdAt}</span></Td></Tr>
                      <Tr><Td><strong>updated At: </strong></Td><Td><span>{e.updatedAt}</span></Td></Tr>
                      <Tr><Td><strong>http Status: </strong></Td><Td><span>{e.httpResponseStatusCode}</span></Td></Tr>
                      <Tr><Td><strong>ellapsed Time: </strong></Td><Td><span>{e.ellapsedTime}</span></Td></Tr>
                      <Tr>
                        <Td color={e.requestSizeColor}>
                          <strong> Request Size: </strong>
                        </Td>
                        <Td color={e.requestSizeColor}>
                          <span>{e.requestSize}</span>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td></Td>
                        <Td>
                          <Button
                            colorScheme={e.error ? "red" : "green"}
                            size="xs"
                            onClick={() => {
                              console.info("More details = ", {
                                requestJson: e.requestJson,
                                responseData: e.httpResponseDataJson,
                                headers: e.httpResponseHeadersJson,
                                error: e.error,
                              })
                            }}>
                            Log more details
                          </Button>

                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Td>

                <Td width={300}>
                  <strong>Prompt / Message</strong> <br />
                  <Textarea defaultValue={e.prompt} />
                </Td>

                <Td width={300}>
                  <strong>AI Response</strong> <br />
                  <Textarea defaultValue={e.response} />
                </Td>

              </Tr>
              <Tr>

                <Td colSpan={4}>
                  <AiRequestDebugger
                    entry={e}
                  />
                </Td>

              </Tr>
            </Fragment>
          )
          }
        </Tbody>
      </Table>
    </div>
  )

}

const fromDto = (rawEntry: RawEntry) => {
  const toReturn: AiLogEntry = {
    createdAt: rawEntry.createdAt,
    error: rawEntry.error,
    httpResponseDataText: rawEntry.httpResponseData ? rawEntry.httpResponseData.toString() : undefined,
    httpResponseDataJson: rawEntry.httpResponseData ? JSON.parse(rawEntry.httpResponseData) : undefined,
    httpResponseHeadersJson: rawEntry.httpResponseHeadersJson ? JSON.parse(rawEntry.httpResponseHeadersJson) : undefined,
    httpResponseStatusCode: rawEntry.httpResponseStatusCode ? parseInt(rawEntry.httpResponseStatusCode) : undefined,
    modelName: rawEntry.modelName,
    prompt: rawEntry.prompt,
    requestJson: rawEntry.requestJson ? JSON.parse(rawEntry.requestJson) : undefined,
    response: rawEntry.response,
    updatedAt: rawEntry.updatedAt,

    ellapsedTime: rawEntry.createdAt && rawEntry.updatedAt ? `${new Date(rawEntry.updatedAt).getTime() - new Date(rawEntry.createdAt).getTime()} ms` : undefined,
    requestSize: rawEntry.requestJson ? JSON.stringify(rawEntry.requestJson).length : undefined,
  }
  if (toReturn.requestSize) {
    toReturn.requestSizeColor = getRequestSizeColor(toReturn.requestSize)
  }

  return toReturn
}

type RawEntry = {
  createdAt?: string,
  error?: string,
  httpResponseData?: any,
  httpResponseHeadersJson?: string,
  httpResponseStatusCode?: string,
  modelName?: string,
  prompt?: string,
  requestJson?: string,
  response?: string,
  updatedAt?: string,
}

export type AiLogEntry = {
  createdAt?: string,
  error?: string,
  httpResponseDataText: string,
  httpResponseDataJson: string,
  httpResponseHeadersJson?: any,
  httpResponseStatusCode?: number,
  modelName?: string,
  prompt?: string,
  requestJson?: any,
  response?: string,
  updatedAt?: string,

  ellapsedTime?: string,
  requestSize?: number,
  requestSizeColor?: string,
}


const maxCharLengthForGpt3 = 12000
const maxCharLengthForGpt3_16k = 49000
const maxCharLengthForGpt4 = 24000
const getRequestSizeColor = (requestSize: number) => {
  if (requestSize > maxCharLengthForGpt3_16k) {
    return "red"
  } else if (requestSize > maxCharLengthForGpt4) {
    return "orange"
  } else if (requestSize > maxCharLengthForGpt3) {
    return "brown"
  }
  return "green"
}
