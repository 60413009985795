import { AddNewDocGlobalModal } from "../../../screens/documents/add/globalModal/AddNewDocGlobalModal"
import { useGlobalShortcutKeyListener } from "../keyboardShortcuts/useGlobalShortcutKeyListener"
import { MiniCommandMenuModal } from "../miniCommandMenu/MiniCommandMenuModal"
import { useGlobalCommandProcessor } from "./useGlobalCommandProcessor"
import { GlobalConfirmDialog } from "./GlobalConfirmDialog"
import { AddNewOpportunityGlobalModal } from "../../../screens/documents/add/globalModal/AddNewOpportunityGlobalModal"
import { CustomAIPromptModal } from "../customPrompt/CustomAIPromptModal"
import { AiContextGlobalModal } from "../../../screens/documents/add/globalModal/AiContextGlobalModal"


/** Holds the HTML / Rendered UI side for processing global commands */
export const GlobalCommandsContainer = () => {


  // Fire up ye old command processor!
  useGlobalCommandProcessor()
  useGlobalShortcutKeyListener()


  // This list is for components that are only needed when a global command is triggered.
  // For example, the AddNewDocGlobalModal is only needed when the user triggers the "Add New Note" command.
  // OPEN QUESTION: Will this section grow?

  return (
    <div>
      <AddNewDocGlobalModal />
      <AddNewOpportunityGlobalModal />
      <AiContextGlobalModal />
      <MiniCommandMenuModal />
      <GlobalConfirmDialog />
      <CustomAIPromptModal />
    </div>
  )
}
