import { css } from '@emotion/react';
import graphql from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { GuideListItem } from './GuideListItem';
import { GuideConnection_query$key } from './__generated__/GuideConnection_query.graphql';
import { NewDocumentButton } from '../add/NewDocumentButton';


const fragmentQL = graphql`
  fragment GuideConnection_query on Query {
    guideConnection: documentConnection(
      first: 100
      type: guide
    ){
      __id
      edges{
        node{
          id
          ...GuideListItem_document
        }
      }
    }
  }
`

type Props = { query: GuideConnection_query$key; };
export function GuideConnection({ query: queryKey }: Props) {
  const { guideConnection } = useFragment(fragmentQL, queryKey)
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
      `}
    >
      {/* <NewDocumentButton
        type={`interviewGuide`}
        buttonText={`Start a new guide`}
      />
      {
        guideConnection.edges.map(({ node: document }) =>
          <GuideListItem
            connectionIds={[guideConnection.__id]}
            key={document.id}
            document={document}
          />
        )
      } */}
    </div>
  )
}
