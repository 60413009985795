import { GlobalCommandType } from "../../GlobalCommandType"
import { VerdiCommand } from "../../../../screens/document/tiptapEditor/commandMenu/commands/VerdiCommand"
import { GlobalCommandDefinition } from "../../GlobalCommandDefinition"
import { AppServices } from "../../../appServices/useRegisterAppServices"
import { showConfirmDialogCommandDefinition } from "../utils/showConfirmDialog"
import { ReactNode } from "react"
import { triggerGlobalCommand } from "../../triggerGlobalCommand"
import { DocumentSchema } from "@verdi/shared-constants"
import { deleteDocNoConfirmCommandDefinition } from "./deleteDocNoConfirm"


const cmdType = GlobalCommandType.deleteDoc


export const deleteDocCommandDefinition: GlobalCommandDefinition<DeleteDocCommandArgs> = {
  globalCommandType: cmdType,
  buildMenuItem: (args: DeleteDocCommandArgs) => {

    const toReturn =
      {
        globalCommandType: cmdType,
        name: "Delete this note ...",
        searchName: "delete this note",
        runCommand: () => {
          triggerGlobalCommand(cmdType, args)
        },

      } as VerdiCommand

    return toReturn
  },
  processCommand: (
    args: DeleteDocCommandArgs,
    services: AppServices,
    onProcessingComplete: (wasSuccessful: boolean) => void,
  ) => {

    const docTitle = args.docTitle ? `"${args.docTitle}"` : "this note"
    showConfirmDialogCommandDefinition.triggerCommand?.({
      dialogBodyText: `Are you sure you want to delete ${docTitle}?`,
      dialogBodyHtml: args.dialogBodyHtml,
      confirmButtonLabel: "Delete",
      heading: "Delete note",
      onConfirmSuccess: () => {
        deleteDocNoConfirmCommandDefinition.triggerCommand?.(args)
      },
      isDestructiveAction: true,
      onCancel: args.onCancel,
    })

  },
  triggerCommand: (args: DeleteDocCommandArgs) => {
    triggerGlobalCommand(cmdType, args)
  }
}

export type DeleteDocCommandArgs = {
  docId: string
  docTitle: string | undefined
  docType?: DocumentSchema.DocumentType,
  dialogBodyHtml?: ReactNode
  onDeleted?: (deletedDocId: string) => void,
  onCancel?: () => void,
  redirectAfterDelete: boolean,
}
